import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import FormForceBookModal from "./plannings/modals/FormForceBookModal";
import { Tooltip, Modal, App, Checkbox, Form, Button } from "antd";
import dayjs from "dayjs";
import {
  getRdvApiAuth,
  viewTransform,
  resetCalendarApiDefaultSettings,
  onClickExportICS,
  onClickExportPDF,
  onClickExportXLS,
  isBackgroundEvent,
  getAmoNb,
  handleColorsLocalStorage,
  sleep,
} from "../utils";
import axios from "axios";
import { ColorFactory } from "antd/es/color-picker/color";
import ExportModal from "./ExportModal";
import { defaultColors } from "./plannings/planningData";
// import { FaHandPaper } from "react-icons/fa";
dayjs.locale("fr");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const AgendaReadOnly = ({
  eventSources,
  setEventSources,
  value,
  setValue,
  events,
  setFmtEvents,
  planning,
  setPlanning,
  fetchPlanningAndFmtEvents,
  notBookedList,
  amos,
  config,
  setConfig,
  showFreeMeetings,
  setShowFreeMeetings,
  customFormatEvents,
}) => {
  const { message } = App.useApp();
  const [showModal, setShowModal] = useState(false);
  const [moveButtonTitle, setMoveButtonTitle] = useState("Déplacer");
  const [showMoveConfirmModal, setShowMoveConfirmModal] = useState(false);
  const [showMoveFreeMeetingConfirmModal, setShowMoveFreeMeetingConfirmModal] =
    useState(false);
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const [showDelFreeMeetingConfirmModal, setShowDelFreeMeetingConfirmModal] =
    useState(false);
  const [delSelected, setDelSelected] = useState(undefined);
  const [moveSelected, setMoveSelected] = useState(undefined);
  const [isMoveLoading, setIsMoveLoading] = useState(false);
  const [isMoveFreeMeetingLoading, setIsMoveFreeMeetingLoading] =
    useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);

  const [isExportICSLoading, setIsExportICSLoading] = useState(false);
  const [isExportPDFLoading, setIsExportPDFLoading] = useState(false);
  const [isExportXLSLoading, setIsExportXLSLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [isFreeMeetingModal, setIsFreeMeetingModal] = useState(false);
  const [isSingleAmo, setIsSingleAmo] = useState(false);
  const [viewType, setViewType] = useState("Semaine");
  const [recentColors, setRecentColors] = useState([]);

  const [isSwitchAmoButtonClicked, setIsSwitchAmoButtonClicked] =
    useState(false);
  const [tmpSwitch, setTmpSwitch] = useState([]);
  const calendarRef = useRef();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const amoNb = getAmoNb(JSON.parse(planning.grid));

  const handleCancel = () => {
    setShowExportOptions(false);
  };

  const onOk = async (vals) => {
    setConfirmLoading(true);
    if (!isFreeMeetingModal) {
      let sd = new Date(vals.selectedDate).setSeconds(0);
      try {
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/book-force/" + planning._id,
          {
            selected: vals.selected,
            rdvDate: parseInt(new Date(sd).getTime() / 1000),
            isMailConfirmation: vals.isMailConfirmation,
            selectedAmo: vals.selectedAmo,
          },
          getRdvApiAuth()
        );
        // Goto Rdv day to show rdv
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(new Date(vals.selectedDate));
        await fetchPlanningAndFmtEvents(config, showFreeMeetings);
        if (vals.isMailConfirmation) {
          message.success("Rendez-vous confirmé, mail de confirmation envoyé.");
        } else {
          message.success(
            "Rendez-vous confirmé, aucun mail de confirmation envoyé"
          );
        }
      } catch (e) {
        console.log(e);
        message.error(
          "Problème lors du placement du rendez-vous. Assurez vous que le rendez-vous tienne dans le planning."
        );
      }
    } else {
      // Free Meeting Booking
      try {
        handleColorsLocalStorage(vals);
        let tmp = new Date(
          new Date(vals.freeMeetingDate).setSeconds(0)
        ).setMilliseconds(0);
        let newFreeMeetings = [...planning.freeMeetings];
        let newFreeMeeting = {
          name: vals.freeMeetingName,
          start: new Date(parseInt(new Date(tmp).getTime())),
          end: new Date(parseInt(new Date(tmp).getTime()) + vals.duree),
          color:
            vals.color instanceof ColorFactory
              ? `#${vals.color.toHex()}`
              : vals.color,
        };
        newFreeMeetings.push(newFreeMeeting);

        let res = await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
          { freeMeetings: newFreeMeetings },
          getRdvApiAuth()
        );
        // Goto Rdv day to show rdv
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(new Date(vals.freeMeetingDate));

        let newEventSources = [...eventSources];

        newEventSources.push({
          events: [
            {
              ...newFreeMeeting,
              booked: newFreeMeeting.name,
              title: `*${newFreeMeeting.name}*`,
            },
          ],
          color: newFreeMeeting.colors,
          startStr: "fm",
        });

        let updatedPlanning = {
          ...planning,
          freeMeetings: res.data.planning.freeMeetings,
        };
        setPlanning(updatedPlanning);
        let f = customFormatEvents(
          updatedPlanning,
          config,
          showFreeMeetings // NEW !!
        );

        setFmtEvents(f[0]);
        setEventSources(f[1]);

        message.success("RDV Libre créé");
      } catch (e) {
        console.log(e);
        message.error("Problème lors du placement du RDV Libre.");
      }

      let rc = localStorage.getItem("recentColors");

      if (rc !== null) {
        setRecentColors(JSON.parse(rc));
      }

      form.setFieldsValue({
        selectedDate: dayjs(planning.planningStartTime).second(0),
        freeMeetingDate: dayjs(planning.planningStartTime).second(0),
        isMailConfirmation: true,
        color: defaultColors[0],
      });
    }
    setConfirmLoading(false);
    setIsFreeMeetingModal(false);
    form.resetFields();
  };

  const onConfirmMove = async () => {
    setIsMoveLoading(true);
    let calendarApi = calendarRef.current.getApi();
    let info = moveSelected;

    try {
      // Delete request to cancel Rdv on backend
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/unbook/" + info.id,
        {}
      );
    } catch (e) {
      setIsMoveLoading(false);
      console.log(e);
      message.error(
        `Erreur lors du déplacement du rendez-vous du lot ${info.title})`
      );
    }

    try {
      let info = calendarApi.getEventById(moveSelected.id);
      // Axios call to add Rdv
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/book-force/" + planning._id,
        {
          selected: info.id,
          rdvDate: parseInt(
            new Date(info.start.setSeconds(0)).getTime() / 1000
          ),
          isMailConfirmation: true,
        },
        getRdvApiAuth()
      );
    } catch (e) {
      setIsMoveLoading(false);
      console.log(e);
      message.error(
        `Erreur lors du déplacement du rendez-vous du lot ${info.title}). Il à été supprimé mais n'a pas pu être mis à l'endroit demandé`
      );
    }
    setIsMoveLoading(false);
    resetCalendarApiDefaultSettings(calendarApi);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    message.success("Rendez-vous correctement déplacé");
    // await fetchPlanningAndFmtEvents();
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setShowMoveConfirmModal(false);
  };

  const onConfirmMoveFreeMeeting = async () => {
    setIsMoveFreeMeetingLoading(true);
    let calendarApi = calendarRef.current.getApi();
    let newEvent = calendarApi.getEventById(moveSelected.id);
    try {
      // console.log(`delSelected : ${JSON.stringify(delSelected)}`);
      // let info = calendarApi.getEventById(delSelected);
      // console.log(`info : ${JSON.stringify(info.event)}`);
      let newFreeMeetings = [
        ...planning.freeMeetings.filter((_) => _._id !== moveSelected.id),
      ];
      newFreeMeetings.push({
        name: newEvent.extendedProps.name,
        start: new Date(
          parseInt(new Date(newEvent.start.setSeconds(0)).getTime())
        ),
        end: new Date(parseInt(new Date(newEvent.end.setSeconds(0)).getTime())),
        color: newEvent.backgroundColor,
      });

      // console.log(`newFreeMeetings : ${JSON.stringify(newFreeMeetings)}`);
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { freeMeetings: newFreeMeetings },
        getRdvApiAuth()
      );
      // await fetchPlanningAndFmtEvents();
    } catch (e) {
      console.log(e);
      message.error("Problème lors du déplacement du RDV Libre.");
    }

    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    resetCalendarApiDefaultSettings(calendarApi);
    message.success("RDV Libre correctement déplacé");
    // await fetchPlanningAndFmtEvents();
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setIsMoveFreeMeetingLoading(false);
    setShowMoveFreeMeetingConfirmModal(false);
    setShowFreeMeetings(true);
  };

  const onCancelMove = async () => {
    let calendarApi = calendarRef.current.getApi();
    resetCalendarApiDefaultSettings(calendarApi);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    message.success("Déplacement du rendez-vous annulé");
    // Pas terrible de tout refetch mais bon ..
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    // await fetchPlanningAndFmtEvents();
    setShowMoveConfirmModal(false);
  };

  const onCancelMoveFreeMeeting = async () => {
    let calendarApi = calendarRef.current.getApi();
    resetCalendarApiDefaultSettings(calendarApi);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    message.success("Déplacement du rendez-vous annulé");
    // Pas terrible de tout refetch mais bon ..
    // await fetchPlanningAndFmtEvents();
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setShowMoveFreeMeetingConfirmModal(false);
  };

  const onConfirmDel = async () => {
    let calendarApi = calendarRef.current.getApi();
    try {
      let info = calendarApi.getEventById(delSelected);
      // Delete request to cancel Rdv on backend
      // TODO : Check return for specific error msg ?
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/unbook/" + info.id,
        form2.getFieldsValue()
      );
      await fetchPlanningAndFmtEvents(config, showFreeMeetings);
      message.success("Rendez-vous annulé");
    } catch (e) {
      message.error("Erreur lors de la suppression du rendez-vous.");
    }
    calendarApi.setOption("eventClick", false);
    setDelSelected(undefined);
    setShowDelConfirmModal(false);
  };

  const onCancelDel = () => {
    let calendarApi = calendarRef.current.getApi();
    message.success("Suppression du rendez-vous annulée");
    calendarApi.setOption("eventClick", false);
    setDelSelected(undefined);
    setShowDelConfirmModal(false);
  };

  const onConfirmDelFreeMeeting = async () => {
    let calendarApi = calendarRef.current.getApi();
    try {
      let newFreeMeetings = [
        ...planning.freeMeetings.filter((_) => _._id !== delSelected),
      ];

      // console.log(`APRES newFreeMeetings : ${JSON.stringify(newFreeMeetings)}`);
      let res = await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { freeMeetings: newFreeMeetings },
        getRdvApiAuth()
      );
      // await fetchPlanningAndFmtEvents();
      let updatedPlanning = {
        ...planning,
        freeMeetings: res.data.planning.freeMeetings,
      };
      setPlanning(updatedPlanning);
      let f = customFormatEvents(
        updatedPlanning,
        config,
        showFreeMeetings // NEW !!
      );

      setFmtEvents(f[0]);
      setEventSources(f[1]);

      message.success("RDV Libre supprimé");
    } catch (e) {
      console.log(e);
      message.error("Problème lors de la suppression du RDV Libre.");
    }

    calendarApi.setOption("eventClick", false);
    setDelSelected(undefined);
    setShowDelFreeMeetingConfirmModal(false);
  };

  const onCancelDelFreeMeeting = () => {
    let calendarApi = calendarRef.current.getApi();
    message.success("Suppression du RDV Libre annulée");
    calendarApi.setOption("eventClick", false);
    setDelSelected(undefined);
    setShowDelFreeMeetingConfirmModal(false);
  };

  let startDate = undefined;
  if (eventSources[0].events.length !== 0) {
    startDate = moment(
      new Date(Math.min(...eventSources[0].events.map((x) => x.start)))
    ).format("yyyy-MM-DD");
  } else if (events.length > 0) {
    startDate = moment(
      new Date(Math.min(...events.map((x) => x.start)))
    ).format("yyyy-MM-DD");
  } else {
    startDate = moment(new Date(planning.planningStartTime)).format(
      "yyyy-MM-DD"
    );
  }

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <div>&nbsp;</div>}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    let rc = localStorage.getItem("recentColors");
    if (rc !== null) setRecentColors(JSON.parse(rc));
  }, []);

  return (
    <div>
      <div>
        <FormForceBookModal
          showModal={showModal}
          setShowModal={setShowModal}
          onOk={onOk}
          form={form}
          notBookedList={notBookedList}
          amos={amos}
          planning={planning}
          fetchPlanningAndFmtEvent={fetchPlanningAndFmtEvents}
          isFreeMeetingModal={isFreeMeetingModal}
          setIsFreeMeetingModal={setIsFreeMeetingModal}
          confirmLoading={confirmLoading}
          recentColors={recentColors}
          setRecentColors={setRecentColors}
        />

        <Modal
          open={showMoveConfirmModal}
          icon={<ExclamationCircleOutlined />}
          title="Vous souhaitez déplacer un rendez-vous dans l'agenda"
          footer={[
            <Button key="nonmove" onClick={onCancelMove}>
              Non
            </Button>,
            <Button
              key="ouimove"
              type="primary"
              loading={isMoveLoading}
              onClick={onConfirmMove}
            >
              Oui
            </Button>,
          ]}
        >
          <br />
          La personne pour laquelle vous prenez rendez-vous recevra
          automatiquement un mail de confirmation avec l'heure et la date du
          rendez-vous. <br /> <br />
          Confirmez-vous cette action ?
        </Modal>

        <Modal
          open={showMoveFreeMeetingConfirmModal}
          icon={<ExclamationCircleOutlined />}
          title="Vous souahitez déplacer un RDV Libre dans l'agenda"
          footer={[
            <Button key="nonmove" onClick={onCancelMoveFreeMeeting}>
              Non
            </Button>,
            <Button
              key="ouimove"
              type="primary"
              loading={isMoveFreeMeetingLoading}
              onClick={onConfirmMoveFreeMeeting}
            >
              Oui
            </Button>,
          ]}
        >
          <br />
          Confirmez-vous cette action ?
        </Modal>

        <Modal
          open={showDelConfirmModal}
          icon={<ExclamationCircleOutlined />}
          onOk={onConfirmDel}
          onCancel={onCancelDel}
          okText={"Oui"}
          cancelText={"Non"}
          title="Vous souhaitez supprimer un rendez-vous de l'agenda"
        >
          <br />
          La personne pour laquelle vous annulez rendez-vous recevra
          automatiquement un mail la notifiant de l'annulation si vous
          choisissez de cocher l'option.
          <br />
          <br />
          <Form
            form={form2}
            initialValues={{
              isMailConfirmationDel: true,
            }}
          >
            <Form.Item name="isMailConfirmationDel" valuePropName="checked">
              <Checkbox>Mail d'annulation</Checkbox>
            </Form.Item>
          </Form>
          Confirmez-vous cette action ?
        </Modal>
        <Modal
          open={showDelFreeMeetingConfirmModal}
          title="Vous souhaitez supprimer un free meeting de l'agenda"
          icon={<ExclamationCircleOutlined />}
          onOk={onConfirmDelFreeMeeting}
          onCancel={onCancelDelFreeMeeting}
          okText={"Oui"}
          cancelText={"Non"}
        >
          <br />
          Ce RDV Libre sera supprimé du planning.
          <br />
          <br />
          Confirmez-vous cette action ?
        </Modal>
        <div className="customTopButton">
          <div id="amoAgendasReadOnly">
            <div className="customCalendarEvent">
              <FullCalendar
                ref={calendarRef}
                snapDuration={planning.pas === 900 ? "00:15:00" : "00:30:00"}
                locale={frLocale}
                eventOrder="amo"
                editable={false}
                eventContent={(arg) => {
                  return (
                    <Tooltip
                      title={arg.event.extendedProps.description}
                      placement="right"
                      // arrow
                    >
                      {renderInnerContent(arg)}
                    </Tooltip>
                  );
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                initialDate={startDate}
                slotMinTime="07:00:00"
                slotMaxTime="20:00:00"
                nowIndicator={true}
                height="auto"
                allDaySlot={false}
                datesSet={(dateInfo) => {
                  if (calendarRef.current !== undefined) {
                    let calendarApi = calendarRef.current.getApi();
                    setViewType(viewTransform[calendarApi.view.type]);
                  }
                }}
                customButtons={{
                  lotView: {
                    text: "Lot",
                    click: async (info) => {
                      setConfig("lotTypo");
                    },
                  },
                  nameView: {
                    text: "Nom",
                    click: async (info) => {
                      setConfig("nameTypo");
                    },
                  },
                  nameLotView: {
                    text: "Nom + Lot",
                    click: async (info) => {
                      setConfig("nameLotTypo");
                    },
                  },
                  lotNameTypoTelView: {
                    text: "Lot + Nom + Tel",
                    click: async (info) => {
                      setConfig("lotNameTypoTel");
                    },
                  },
                  exportOptionsButton: {
                    text: "Export",
                    click: async () => {
                      setShowExportOptions(true);
                    },
                  },
                  // tousButton: {
                  //   text: "Tous",
                  //   click: function () {
                  //     // console.log(`setting value from button 1`);
                  //     setValue(1);
                  //   },
                  // },
                  // reservesButton: {
                  //   text: "Reservés",
                  //   click: function () {
                  //     setValue(2);
                  //   },
                  // },
                  // disponiblesButton: {
                  //   text: "Disponibles",
                  //   click: function () {
                  //     setValue(3);
                  //   },
                  // },
                  switchAmoButton: {
                    text: isSwitchAmoButtonClicked ? "Terminé" : "Switch AMO",
                    click: async () => {
                      let calendarApi = calendarRef.current.getApi();

                      if (isSwitchAmoButtonClicked) {
                        if (tmpSwitch.length === 0) {
                          setIsSwitchAmoButtonClicked(
                            !isSwitchAmoButtonClicked
                          );
                          return;
                        } else if (
                          tmpSwitch.length === 1 ||
                          tmpSwitch.length > 6
                        ) {
                          await fetchPlanningAndFmtEvents(); // TODO : A retirer, pas besoin de refetcher
                          message.error(
                            "Opération impossible. Veuillez vérifier votre sélection."
                          );
                          setIsSwitchAmoButtonClicked(
                            !isSwitchAmoButtonClicked
                          );

                          return;
                        }
                        // Check if only 2 amo concerned
                        let amoInvolved = Array.from(
                          new Set(tmpSwitch.map((_) => _.amo))
                        );
                        if (amoInvolved.length > 2) {
                          message.error(
                            `Impossible de switcher des rdvs avec plus de deux rdv a la fois. Veuillez déselectionner un/plusieurs rdv.`
                          );
                          return;
                        }
                        setIsSwitchAmoButtonClicked(!isSwitchAmoButtonClicked);

                        try {
                          await axios.post(
                            `${process.env.REACT_APP_RDV_API_URL}/rdv/${planning._id}/switch-amo`,
                            {
                              rdvs: tmpSwitch,
                            },
                            getRdvApiAuth()
                          );
                          // Refetch des events
                          await fetchPlanningAndFmtEvents();
                          message.success("Opération effectuée");
                        } catch (e) {
                          console.log(e);
                          // Refetch des events --> Not Necessary normally, to delete
                          await fetchPlanningAndFmtEvents();
                          message.error(
                            "Impossible de switcher les AMOs. Assurez vous que les rendez-vous selectionnes sont les bons."
                          );
                        }
                        setTmpSwitch([]);
                        // Desactiver la selection
                        calendarApi.setOption("eventClick", false);
                      } else {
                        setIsSwitchAmoButtonClicked(!isSwitchAmoButtonClicked);
                        message.info(
                          "Selectionnez les rendez-vous à intervertir"
                        );
                        calendarApi.setOption("eventClick", async (info) => {
                          setTmpSwitch((prev) => {
                            // console.log(`title : ${info.event.title}`);
                            let fooIdx = prev.findIndex(
                              (_) =>
                                _.booked === info.event.extendedProps.booked
                            );
                            if (
                              fooIdx !== -1 &&
                              info.el.style.backgroundColor ===
                                "rgb(217, 239, 252)"
                            ) {
                              // Rdv deja present
                              // Remettre l'ancien bg color
                              info.el.style.backgroundColor =
                                prev[fooIdx].backgroundColor;
                              // Remettre l'ancien bordercolor
                              info.el.style.borderColor =
                                prev[fooIdx].borderColor;
                              // Remettre l'ancien textColor
                              info.el.children[0].style.color = "white";
                              return [
                                ...prev.filter(
                                  (_) =>
                                    _.booked !== info.event.extendedProps.booked
                                ),
                              ];
                            } else {
                              // Make sure no background event nor free meeting are colorized
                              if (
                                info.event.title === "" ||
                                isBackgroundEvent(info) ||
                                (info.event.title.startsWith("*") &&
                                  info.event.title.endsWith("*"))
                              ) {
                                // S'agit d'un bgevent. Ne rien faire
                                return [...prev];
                              }
                              let newEvent = {
                                description:
                                  info.event.extendedProps.description,
                                amo: info.event.extendedProps.amo,
                                booked: info.event.extendedProps.booked,
                                backgroundColor: info.el.style.backgroundColor,
                                eventTextColor: info.el.style.eventTextColor,
                                borderColor: info.el.style.borderColor,
                              };

                              // Mettre le bg color de selection
                              info.el.children[0].style.color = "#436386";
                              info.el.style.backgroundColor = "#D9EFFC";
                              info.el.style.borderColor = "#D9EFFC";

                              return [...prev, newEvent];
                            }
                          });
                        });
                      }
                    },
                  },
                  forceButton: {
                    text: "+",
                    hint: "Ajouter un RDV",
                    click: function () {
                      setShowModal(true);
                    },
                  },
                  moveButton: {
                    text: moveButtonTitle,
                    click: function () {
                      let calendarApi = calendarRef.current.getApi();
                      if (moveButtonTitle === "Terminé") {
                        resetCalendarApiDefaultSettings(calendarApi);
                        setMoveButtonTitle("Déplacer");
                        // setMoveButtonTitle(<FaHandPaper />);
                      } else {
                        if (moveButtonTitle !== "Terminé") {
                          message.warning(
                            "Sélectionnez un rendez-vous et déplacez le à l'endroit souhaité"
                          );
                          setMoveButtonTitle("Terminé");
                          calendarApi.setOption("editable", true);
                          calendarApi.setOption("eventDrop", (info) => {
                            let count = (info.event.title.match(/\*/g) || [])
                              .length;
                            // console.log(`count : ${count}`);
                            if (
                              count === 2 &&
                              info.event.title.startsWith("*") &&
                              info.event.title.endsWith("*")
                            ) {
                              setShowMoveFreeMeetingConfirmModal(true);
                            } else {
                              setShowMoveConfirmModal(true);
                            }
                          });
                          calendarApi.setOption("eventDragStop", (info) => {
                            setMoveSelected(info.event);
                          });
                        }
                      }
                    },
                  },
                  showFreeMeetingsButton: {
                    text: showFreeMeetings
                      ? "Masquer RDVs Libres"
                      : "Montrer RDVs Libres",
                    hint: "Montrer les RDVs Libres",
                    click: (info) => {
                      let f = customFormatEvents(
                        planning,
                        config,
                        !showFreeMeetings
                      );
                      setFmtEvents(f[0]);
                      setEventSources(f[1]);
                      setShowFreeMeetings(!showFreeMeetings);
                    },
                  },
                  deleteButton: {
                    text: "-",
                    hint: "Supprimer un RDV",
                    click: (info) => {
                      // console.log("Deleting rdv option");
                      let calendarApi = calendarRef.current.getApi();
                      calendarApi.setOption("eventClick", async (info) => {
                        if (
                          !isBackgroundEvent(info) &&
                          !info.event.id.startsWith("background")
                        ) {
                          setDelSelected(info.event.id);
                          let count = (info.event.title.match(/\*/g) || [])
                            .length;
                          // console.log(`count : ${count}`);
                          if (
                            count === 2 &&
                            info.event.title.startsWith("*") &&
                            info.event.title.endsWith("*")
                          ) {
                            setShowDelFreeMeetingConfirmModal(true);
                          } else {
                            setShowDelConfirmModal(true);
                          }
                        }
                      });
                      message.warning(
                        "Veuillez sélectionner le rdv à supprimer"
                      );
                    },
                  },
                  // ajustLengthButton: {
                  //   text: ajustLengthButtonTitle,
                  //   click: function () {
                  //     let calendarApi = calendarRef.current.getApi();
                  //     if (ajustLengthButtonTitle !== "Tester") {
                  //       let beforeRessources = calendarApi.getEvents();
                  //       // console.log(
                  //       //   `RESSOURCES BEFORE : ${JSON.stringify(beforeRessources)}`
                  //       // );
                  //       // console.log(beforeRessources[0].internalEventSource);
                  //       calendarApi.setOption("eventDurationEditable", true);
                  //       calendarApi.setOption("eventStartEditable", true);
                  //       setAjustLengthButtonTitle("Tester");
                  //       // calendarApi.setOption("eventChange", async (info) => {
                  //       //   console.log(`Events HAVE changed !, info : `);
                  //       //   console.log(info.event);
                  //       // });
                  //     } else {
                  //       let afterRessources = calendarApi.getEvents();
                  //       calendarApi.setOption("eventDurationEditable", false);
                  //       calendarApi.setOption("eventStartEditable", false);
                  //       setAjustLengthButtonTitle(originAjustLengthButtonTitle);
                  //       // console.log(
                  //       //   `RESSOURCES APRES : ${JSON.stringify(afterRessources)}`
                  //       // );
                  //       const getFmtRessources = (ress) => {
                  //         let newRess = ress.filter((_) =>
                  //           _.id.startsWith("foreground")
                  //         );
                  //         newRess = newRess.map((_) => {
                  //           // When splitted looks like:
                  //           // ['foreground', 'evt', '0', '0']
                  //           // -> last elt of array = amoNb
                  //           console.log(_.id.split("-")[3]);
                  //           return {
                  //             start: _.start,
                  //             end: _.end,
                  //             amoNb: _.id.split("-")[3],
                  //           };
                  //         });
                  //         return newRess;
                  //       };

                  //       let fmtRess = getFmtRessources(afterRessources);
                  //       // console.log(`fmtRessources ==> ${JSON.stringify(fmtRess)}`);

                  //       // getMaxAmoNb
                  //       let maxAmo = Math.max(
                  //         ...fmtRess.map((e) => parseInt(e.amoNb))
                  //       );

                  //       // getStartEndDate
                  //       let minDate = new Date(
                  //         Math.min(...fmtRess.map((e) => new Date(e.start)))
                  //       );
                  //       let maxDate = new Date(
                  //         Math.max(...fmtRess.map((e) => new Date(e.end)))
                  //       );

                  //       // Days between
                  //       // let days = getDaysBetween(minDate, maxDate);
                  //       // console.log(`${minDate} to ${maxDate}`);
                  //       // console.log(`maxAmo : ${maxAmo}`);
                  //       // console.log(`days before -> ${JSON.stringify(days)}`);
                  //       let newDays = Array.from(
                  //         new Set(
                  //           fmtRess.map((d) => {
                  //             return moment(d.start).format("DD/MM/YYYY");
                  //           })
                  //         )
                  //       );
                  //       // console.log(`days after -> ${newDays}`);
                  //       const startTime = "07:00";
                  //       const endTime = "20:00";
                  //     }
                  //   },
                  // },
                  deleteAmoSlotButton: {
                    text: "-",
                    click: (info) => {
                      // console.log("Deleting AMO slot button");
                      let calendarApi = calendarRef.current.getApi();
                      calendarApi.setOption("eventClick", async (info) => {
                        // console.log(info.event);
                        // console.log(
                        //   `amoNb : ${info.event.extendedProps.amo}, start : ${info.event.start}, end : ${info.event.end}, id: ${info.event.id}`
                        // );
                        let evtToDel = calendarApi.getEventById(info.event.id);
                        evtToDel.remove();
                        calendarApi.setOption("eventClick", false);
                        // setDelSelected(info.event.id);
                        // setShowDelConfirmModal(true);
                      });

                      // message.warning("Veuillez sélectionner le rdv à supprimer");
                    },
                  },
                }}
                headerToolbar={{
                  left: `${
                    amoNb > 1
                      ? "prev,next switchAmoButton showFreeMeetingsButton"
                      : "prev,next showFreeMeetingsButton"
                  }`,
                  // left: "prev,next switchAmoButton showFreeMeetingsButton",
                  center: "title",
                  right: `${
                    value === 2 || value === 4
                      ? "moveButton deleteButton forceButton exportOptionsButton timeGridDay,timeGridWeek,dayGridMonth"
                      : "exportOptionsButton timeGridDay,timeGridWeek,dayGridMonth"
                  }`,
                }}
                displayEventTime={config === "lotNameTypoTel" ? false : true}
                // headerToolbar={{
                //   left: "prev,next reservesButton disponiblesButton",

                //   center: "title",
                //   right: `${
                //     value === 2 || value === 4
                //       ? "moveButton deleteButton forceButton exportOptionsButton timeGridWeek,timeGridDay"
                //       : value === 3 && ajustLengthButtonTitle === "Tester"
                //       ? "deleteAmoSlotButton ajustLengthButton exportOptionsButton timeGridWeek,timeGridDay"
                //       : value === 3 && ajustLengthButtonTitle !== "Tester"
                //       ? "exportOptionsButton timeGridWeek,timeGridDay"
                //       : "exportOptionsButton timeGridWeek,timeGridDay"
                //   }`,
                // }}
                footerToolbar={{
                  left: "lotView,nameView,nameLotView,lotNameTypoTelView",
                  center: "",
                  right: "", //right: "uniformColor",
                }}
                eventSources={eventSources}
              />
            </div>
          </div>
        </div>
      </div>
      <ExportModal
        handleCancel={handleCancel}
        showExportOptions={showExportOptions}
        onClickExportICS={onClickExportICS}
        isExportICSLoading={isExportICSLoading}
        setIsExportICSLoading={setIsExportICSLoading}
        onClickExportPDF={onClickExportPDF}
        isExportPDFLoading={isExportPDFLoading}
        setIsExportPDFLoading={setIsExportPDFLoading}
        onClickExportXLS={onClickExportXLS}
        isExportXLSLoading={isExportXLSLoading}
        setIsExportXLSLoading={setIsExportXLSLoading}
        isSingleAmo={isSingleAmo}
        setIsSingleAmo={setIsSingleAmo}
        plannings={[planning]}
        eventSourcetoSend={eventSources}
        config={config}
        viewType={viewType}
        showFreeMeetings={showFreeMeetings}
        fmtEvents={events}
      />
    </div>
  );
};

export default AgendaReadOnly;
