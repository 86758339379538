import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { getBasicAuthConfig, errorCatcher, areBalisesOk } from "../../utils";
import ContentContainer from "../../components/ContentContainer";
import AmoTemplateTable from "../../components/AmoTemplateTable";
import CustomPageHeader from "../../components/CustomPageHeader";
import Tips from "../../components/plannings/modals/Tips";
import {
  Typography,
  Spin,
  Dropdown,
  Button,
  App,
  Form,
  Space,
  Input,
  Alert,
} from "antd";
import { PlusOutlined, EditOutlined, DownOutlined } from "@ant-design/icons";
import SubContentContainer from "../../components/SubContentContainer";

const { Text } = Typography;

const textTransform = {
  mailInvitation: "mail d'invitation",
  smsInvitation: "sms d'invitation",
  mailConfirmation: "mail de confirmation",
  mailRelance: "mail de relance",
  smsRelance: "sms de relance",
  mailRappel: "mail de rappel",
  smsRappel: "sms de rappel",
};

const AmoTemplates = () => {
  const { role } = useContext(UserContext);
  const { message, modal } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  const DropdownMenu = () => {
    const items = [
      {
        label: <Link to={`/${role}/templates/nouveau`}>Nouveau</Link>,
        key: "1",
      },
    ];

    const menuProps = {
      items,
    };

    return (
      <Dropdown.Button menu={menuProps} icon={<DownOutlined />}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>Modèle</Space>
        </a>
      </Dropdown.Button>
    );
  };

  const updateTemplateField = async (field, templateId) => {
    let value = form.getFieldValue(field);
    try {
      await axios.patch(
        process.env.REACT_APP_API_URL + `/templates/${templateId}`,
        {
          [field]: value,
        },
        getBasicAuthConfig()
      );
      message.success(
        `${
          textTransform[field].charAt(0).toUpperCase() +
          textTransform[field].slice(1)
        } modifié avec succès 🤙`
      );

      // Get authorized amo
      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      setUsers(res2.data);
      // Get authorized templates
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/users/me/templates",
        getBasicAuthConfig()
      );
      setData(formatTable(res.data.templates, res2.data));

      // setData(data.filter((_) => _.key !== record.key));
    } catch (e) {
      console.log(e);
      message.error("Impossible de modifier le template");
    }
  };

  const modify = async (field, templateId, originValue) => {
    form.setFieldValue(field, originValue);
    modal.confirm({
      title: `Vous souhaitez changer le ${textTransform[field]} ?`,
      width: 700,

      content: (
        <Form form={form} layout="vertical" name="form_in_modal">
          <br />
          <Form.Item
            name={field}
            rules={[
              {
                validator: async (_, payload) => {
                  if (!areBalisesOk(payload)) {
                    return Promise.reject(
                      new Error(
                        `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                      )
                    );
                  }
                },
                required: true,
                message:
                  "Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles",
              },
            ]}
          >
            <Input.TextArea rows={18} />
          </Form.Item>
          <Tips />
        </Form>
      ),
      onOk() {
        updateTemplateField(field, templateId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const fmtDesc = (
    mailInvitation,
    smsInvitation,
    mailConfirmation,
    mailRelance,
    smsRelance,
    mailRappel,
    smsRappel,
    templateId
  ) => {
    return (
      <div>
        <Text strong>Mail Invitation : </Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("mailInvitation", templateId, mailInvitation)}
        >
          modifier
        </Button>
        <br />
        {mailInvitation}
        <br />
        <br />
        <Text strong>SMS Invitation :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("smsInvitation", templateId, smsInvitation)}
        >
          modifier
        </Button>
        <br />
        {smsInvitation}
        <br />
        <br />
        <Text strong>Mail Confirmation :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() =>
            modify("mailConfirmation", templateId, mailConfirmation)
          }
        >
          modifier
        </Button>
        <br />
        {mailConfirmation}
        <br />
        <br />
        <Text strong>Mail Relance :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("mailRelance", templateId, mailRelance)}
        >
          modifier
        </Button>
        <br />
        {mailRelance}
        <br />
        <br />
        <Text strong>SMS Relance :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("smsRelance", templateId, smsRelance)}
        >
          modifier
        </Button>
        <br />
        {smsRelance}
        <br />
        <br />
        <Text strong>Mail Rappel :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("mailRappel", templateId, mailRappel)}
        >
          modifier
        </Button>
        <br />
        {mailRappel}
        <br />
        <br />
        <Text strong>SMS Rappel :</Text>
        <Button
          icon={<EditOutlined />}
          danger
          type="link"
          onClick={() => modify("smsRappel", templateId, smsRappel)}
        >
          modifier
        </Button>
        <br />
        {smsRappel}
      </div>
    );
  };

  const formatTable = (input, amos) => {
    return input.map((row) => {
      return {
        key: row._id,
        dataIndex: row._id,
        name: row.name,
        authorized: row.authorized.map((_) => amos.find((a) => a._id === _)),
        description: fmtDesc(
          row.mailInvitation,
          row.smsInvitation,
          row.mailConfirmation,
          row.mailRelance,
          row.smsRelance,
          row.mailRappel,
          row.smsRappel,
          row._id
        ),
      };
    });
  };

  useEffect(() => {
    const newFunc = async () => {
      try {
        // Get authorized amo
        const res2 = await axios.get(
          process.env.REACT_APP_API_URL + "/users/amos",
          getBasicAuthConfig()
        );
        setUsers(res2.data);
        // Get authorized templates
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/users/me/templates",
          getBasicAuthConfig()
        );
        setData(formatTable(res.data.templates, res2.data));
      } catch (e) {
        errorCatcher(e, `Erreur lors du chargement des templates`);
      }
      setIsLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <ContentContainer>
        <div className="loader-spin">
          <Spin />
        </div>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          {/* <CustomFieldTemplateUpdateModal
          show={showModal}
          setShow={setShowModal}
          field={field}
        /> */}
          <CustomPageHeader
            arrow={true}
            // style={{ margin: "0px 16px 0px 16px" }}
            title="Modèles"
            // className="site-page-header"
            avatar={{
              src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
            }}
            onBack={() => window.history.back()}
            extra={[<DropdownMenu key="more" />]}
          ></CustomPageHeader>
          {data.length === 0 && (
            <Alert
              message="Il semblerait que vous n'ayez aucun modèle."
              description="Créez-en un nouveau ou demander à vos collaborateurs de vous autoriser à visionner le(s) leur(s)"
              type="warning"
              showIcon
              closable
            />
          )}
          <p></p>Les modèles de mails/sms
          d'invitation/confirmation/rappel/relance sont réutilisables pour
          toutes vos opérations.
          <p />
          <p>
            Réutilisez-les sur plusieurs plannings afin de gagner du temps !{" "}
          </p>
          <p>
            Autorisez vos collaborateurs à accéder aux modèles que vous avez
            créés.{" "}
          </p>
          <br />
          <AmoTemplateTable data={data} setData={setData} users={users} />
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default AmoTemplates;
