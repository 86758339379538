import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  App,
  Checkbox,
} from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { nanoid } from "nanoid";
import { getBasicAuthConfig } from "../../utils";

const { Option } = Select;

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  entities,
  hasLoaded,
}) => {
  const [form] = Form.useForm();

  const onAddUser = async () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("La validation des champs a échoué:", info);
      });
  };

  return (
    <Modal
      open={visible}
      title="Créer un nouvel utilisateur"
      okText="Créer"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={onAddUser}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
          sendEmailCredentials: true,
          password: nanoid(10),
        }}
      >
        <Form.Item name="gender" label="Titre">
          <Select>
            <Option value="Monsieur">Monsieur</Option>
            <Option value="Madame">Madame</Option>
            <Option value="Autre">Autre</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="firstname"
          label="Prénom"
          rules={[
            {
              required: true,
              message: "Veuillez saisir votre prénom",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastname"
          label="Nom"
          rules={[
            {
              required: true,
              message: "Veuillez saisir votre nom",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="enterprise"
          label="Entreprise/Promoteur"
          rules={[
            {
              required: true,
              message: "Veuillez saisir le nom de votre entreprise",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="username"
          label={
            <span>
              Nom d'utilisateur&nbsp;
              <Tooltip title="Celui qui apparaît dans votre barre de navigation">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un nom d'utilisateur",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "L'email saisi n'est pas valide",
            },
            {
              required: true,
              message: "Veuillez renseigner votre adresse email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Mot de passe"
          rules={[
            {
              required: true,
              message: "Veuillez saisir un mot de passe",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="select"
          label="Select"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner un role !",
            },
          ]}
        >
          <Select placeholder="Sélectionnez un role">
            <Option value="anonymousUser">Invité</Option>
            <Option value="customer">Acquéreur</Option>
            <Option value="promoter">Promoteur</Option>
            <Option value="amo">AMO</Option>
            <Option value="moderator">Moderateur</Option>
            <Option value="administrator">Administrateur</Option>
          </Select>
        </Form.Item>

        {hasLoaded && (
          <Form.Item
            name="invoiceentity"
            label="Entité de facturation"
            hasFeedback
          >
            <Select
              placeholder="Sélectionnez une entité de facturation"
              showSearch
              optionFilterProp="value"
            >
              {entities.map((entity) => (
                <Option
                  key={entity._id}
                  name={entity.raisonsociale}
                  value={entity._id}
                >
                  {entity.raisonsociale}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="sendEmailCredentials"
          valuePropName="checked"
          wrapperCol={{
            span: 16,
          }}
        >
          <Checkbox>Envoyer les identifiants par mail</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddUser = ({ data, setData, fetchUsers, hasLoaded, entities }) => {
  const { message } = App.useApp();
  const [visible, setVisible] = useState(false);

  const onCreate = async (values) => {
    try {
      // console.log("Received values of form: ", values);
      const user = {
        role: values.select,
        invoiceEntity: values.invoiceentity,
        ...values,
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/user",
        user,
        getBasicAuthConfig()
      );
      // console.log(res.data);
      message.success(`Nouvel utilisateur ${values.email} créé avec succès.`);
      setVisible(false);
      fetchUsers();
    } catch (e) {
      message.error(
        `Une erreur est apparue lors de la création de l'utilisateur. Veuillez contacter votre administrateur.`
      );
    }
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        <PlusOutlined />
        Ajouter
      </Button>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
        hasLoaded={hasLoaded}
        entities={entities}
      />
    </div>
  );
};

export default AddUser;
