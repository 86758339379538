import React from "react";

import {
  DesktopOutlined,
  HomeOutlined,
  ApartmentOutlined,
  TableOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  ThunderboltOutlined,
  CalendarOutlined,
  IdcardOutlined,
  ReconciliationOutlined,
  FundViewOutlined,
  LockOutlined,
  TeamOutlined,
  EuroOutlined,
  WalletOutlined,
  RiseOutlined,
} from "@ant-design/icons";

export const LeftMenuData = [
  {
    name: "home",
    label: "Accueil",
    icon: <HomeOutlined />,
    path: "/accueil-administrateur",
    key: "sub1",
  },
  {
    name: "program",
    label: "Programmes",
    icon: <ApartmentOutlined />,
    path: "/admin/programmes",
    key: "sub2",
  },
  {
    name: "plannings",
    label: "Plannings",
    icon: <CalendarOutlined />,
    path: "/admin/plannings",
    key: "sub3",
  },
  {
    name: "currentProgram",
    label: undefined,
    icon: <ThunderboltOutlined />,
    items: [
      {
        name: "program-index",
        label: "Aperçu",
        icon: <DesktopOutlined />,
        path: "#top",
        key: "1",
      },
      {
        name: "program-clients",
        label: "Clients",
        icon: <TableOutlined />,
        path: "/clients",
        key: "2",
      },
      {
        name: "program-messages",
        label: "Messages",
        icon: <TableOutlined />,
        path: "/messages",
        key: "3",
      },
      {
        name: "program-edit",
        label: "Plannings",
        icon: <CalendarOutlined />,
        path: "/plannings",
        key: "4",
      },
      {
        name: "program-visits",
        label: "Visites 3D",
        icon: <VideoCameraOutlined />,
        path: "/visites-3d",
        key: "5",
      },
      {
        name: "program-settings",
        label: "Paramètres",
        icon: <SettingOutlined />,
        path: "/parametres",
        key: "6",
      },
    ],
    key: "sub4",
  },
  {
    name: "visites-dashboard",
    label: "Visites",
    icon: <VideoCameraOutlined />,
    path: "/admin/visites",
    key: "sub5",
  },
  // {
  //   name: "matterport-dashboard",
  //   label: "Matterport",
  //   icon: <SettingOutlined />,
  //   path: "/matterport",
  //   key: "sub6",
  // },
  {
    name: "users",
    label: "Comptes",
    icon: <IdcardOutlined />,
    path: "/admin/users",
    key: "sub6",
  },
  {
    name: "logs",
    label: "Logs",
    icon: <FundViewOutlined />,
    items: [
      {
        name: "logs-rdvs",
        label: "Rendez-vous",
        icon: <IdcardOutlined />,
        path: "/admin/logs/rdvs",
        key: "1",
      },
      {
        name: "logs-visites",
        label: "Visites",
        icon: <VideoCameraOutlined />,
        path: "/admin/logs/visites",
        key: "2",
      },
      {
        name: "logs-trends",
        label: "Tendances",
        icon: <RiseOutlined />,
        path: "/admin/logs/trends",
        key: "3",
      },
    ],
    key: "sub7",
  },
  {
    name: "permissions",
    label: "Permissions",
    icon: <LockOutlined />,
    items: [
      {
        name: "users-permissions",
        label: "Utilisateurs",
        icon: <TeamOutlined />,
        path: "/admin/permissions/users",
        key: "1",
      },
      {
        name: "programs-permissions",
        label: "Programmes",
        icon: <ApartmentOutlined />,
        path: "/admin/permissions/programs",
        key: "2",
      },
    ],
    key: "sub8",
  },
  {
    name: "facturation",
    label: "Facturation",
    icon: <EuroOutlined />,
    items: [
      {
        name: "entities",
        label: "Entités",
        icon: <WalletOutlined />,
        path: "/admin/facturation/entities",
        key: "1",
      },
      {
        name: "entities-plannings",
        label: "Plannings",
        icon: <ReconciliationOutlined />,
        path: "/admin/facturation/plannings",
        key: "2",
      },
    ],
    key: "sub9",
  },
];
