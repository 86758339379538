import React from "react";
import { Modal, Space, Button, Checkbox, Form, Input } from "antd";

const ExportModal = ({
  handleCancel,
  showExportOptions,
  onClickExportICS,
  isExportICSLoading,
  setIsExportICSLoading,
  onClickExportPDF,
  isExportPDFLoading,
  setIsExportPDFLoading,
  onClickExportXLS,
  isExportXLSLoading,
  setIsExportXLSLoading,
  isSingleAmo,
  setIsSingleAmo,
  plannings,
  eventSourcetoSend,
  config,
  viewType,
  showFreeMeetings,
  fmtEvents,
}) => {
  const onChange = (e) => {
    setIsSingleAmo(e.target.checked);
  };
  // console.log(`eventSourcetoSend`);
  // console.log(eventSourcetoSend);
  return (
    <Modal
      title="Veuillez sélectionner le format d'export"
      onCancel={handleCancel}
      open={showExportOptions}
      footer={[
        <Button key="back" onClick={handleCancel} type="primary">
          Annuler
        </Button>,
      ]}
    >
      <br />
      <Space direction="horizontal">
        <Button
          onClick={async () => {
            setIsExportICSLoading(true);
            await onClickExportICS(
              plannings,
              isSingleAmo,
              eventSourcetoSend,
              fmtEvents
            );
            setIsExportICSLoading(false);
          }}
          loading={isExportICSLoading}
          type="primary"
        >
          ICS
        </Button>
        <Button
          onClick={async () => {
            setIsExportPDFLoading(true);
            await onClickExportPDF(
              plannings,
              config,
              viewType,
              showFreeMeetings,
              isSingleAmo
            );
            setIsExportPDFLoading(false);
          }}
          loading={isExportPDFLoading}
          type="primary"
        >
          PDF
        </Button>
        <Button
          onClick={async () => {
            setIsExportXLSLoading(true);
            await onClickExportXLS(plannings);
            setIsExportXLSLoading(false);
          }}
          loading={isExportXLSLoading}
          type="primary"
        >
          XLS
        </Button>
        <Checkbox onChange={onChange} checked={isSingleAmo}>
          Plannings individuels
        </Checkbox>
      </Space>
    </Modal>
  );
};

export default ExportModal;
