import React from "react";
import { Empty } from "antd";
import { Column } from "@ant-design/charts";

const SimulationChart = ({ data }) => {
  // console.log(data);
  var config = {
    data: data,
    isStack: true,
    xField: "x",
    yField: "choix",
    xAxis: {
      label: {
        formatter: (v) => parseInt(v) + 1,
      },
    },
    seriesField: "type",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    meta: {
      x: {
        alias: "XX",
      },
      type: {
        alias: "Durée",
      },
      choix: {
        alias: "Créneaux horaires proposés",
      },
    },
    color: ["#436386", "#d4dfc7", "#96c0b7", "#b7695d"],
    // color: ["#048B9A", "#fef6c9", "#96c0b7", "#d4dfc7", "#878e88", "#725752"],
  };

  if (data.length === 0) {
    return (
      <div>
        <Empty description={<span>Aucune donnée pour le moment.</span>}></Empty>
      </div>
    );
  } else {
    return (
      <div>
        <Column {...config} />
      </div>
    );
  }
};

export default SimulationChart;
