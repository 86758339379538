import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Tag,
  Space,
  DatePicker,
  TimePicker,
  Typography,
  Select,
  Checkbox,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { FiSunrise, FiSunset } from "react-icons/fi";
import { MinusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  getDaysBetween,
  capitalizeFirstLetter,
  disabledDateTime,
} from "../../utils";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import { bankHolidays } from "./planningData";

dayjs.locale("fr");
const { Text } = Typography;

const SelectDays = ({ events, setEvents, selected, form }) => {
  const { RangePicker } = DatePicker;
  // const { RangePicker: MinutesPicker } = TimePicker;
  const { Option } = Select;

  const formRef = useRef();
  const format = "HH:mm";
  const titleFormat = "ddd DD MMM";
  const initialStartTime = "08:30";
  const initialEndTime = "17:00";
  const initialStartAmo = "1";

  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [startAmo, setStartAmo] = useState(initialStartAmo);
  const [timePerDay, setTimePerDay] = useState(0);
  const [neededTime, setNeededTime] = useState(0);
  const [isWeekend, setIsWeekend] = useState(false);

  let initVals = [];
  // console.log(events);
  if (events && events.length !== 0) {
    // TODO : Multiple render should NOT be the case
    // console.log(`INIT EVENTS`);

    const newFormat = "DD/MM/YYYY"; // + format;
    initVals = events.map((event, key) => {
      // console.log(`new Date(event.start) : ${new Date(event.start)}`);
      // console.log(
      //   `JJ : ${JSON.stringify([dayjs(event.start), dayjs(event.end)])}`
      // );
      const dateToCompare = moment(event.start).format("YYYY-MM-DD");
      const foundKey = Object.keys(bankHolidays).find(
        (key) => key === dateToCompare
      );
      return {
        timepicker: [dayjs(event.start), dayjs(event.end)],
        // timepicker: [
        //   dayjs(new Date(event.start), format),
        //   dayjs(new Date(event.end), format),
        // ],
        nbAmo: event.nbAmo.toString(),
        title:
          capitalizeFirstLetter(
            moment(event.start).format(titleFormat).toString()
          ) + (foundKey ? ` (férié)` : ""),
        // title: capitalizeFirstLetter(
        //   dayjs(event.start).format(titleFormat).toString()
        // ),
        // date: moment(new Date(event.start)).format(newFormat),
        date: dayjs(new Date(event.start)).format(newFormat),
      };
    });
    // console.log(`INIT VALS : `);
    // console.log(initVals);
  }

  const onChange = (e) => {
    setIsWeekend(e.target.checked);
  };

  const updateBookingDeadLine = (updatedEvents) => {
    // seven days in milliseconds : 604800000
    // start booking deadline 7 days before first day of planning
    if (updatedEvents.length > 0) {
      form.setFieldsValue({
        // bookingDeadline: moment(updatedEvents[0].start - 604800000),
        bookingDeadline: dayjs(updatedEvents[0].start - 604800000),
      });
    }
  };

  const computeEvt = (dates) => {
    // console.log(`Compute()`);
    const newFormat = "DD/MM/YYYY" + format;
    let updatedEvents = [];
    dates.forEach((item) => {
      if (JSON.stringify(item) !== "{}") {
        // console.log("pass pour : " + JSON.stringify(item));
        updatedEvents.push({
          // start: moment(
          //   item.date + item["timepicker"][0].format("HH:mm"),
          //   newFormat
          // )
          //   .toDate()
          //   .getTime(),
          // end: moment(
          //   item.date + item["timepicker"][1].format("HH:mm"),
          //   newFormat
          // )
          //   .toDate()
          //   .getTime(),
          start: dayjs(
            item.date + item["timepicker"][0].format("HH:mm"),
            newFormat
          )
            .toDate()
            .getTime(),
          end: dayjs(
            item.date + item["timepicker"][1].format("HH:mm"),
            newFormat
          )
            .toDate()
            .getTime(),
          nbAmo: parseInt(item.nbAmo[0]),
        });
      }
    });
    // console.log(updatedEvents);
    setEvents(updatedEvents);
    updateBookingDeadLine(updatedEvents);
  };

  const updateSubForm = (start, end) => {
    // console.log(`HOURS updateSubForm()`);
    let datesArray = formRef.current.getFieldsValue()["dayschedule"];

    // console.log(`dayschedule (datesArray): ${datesArray}`);

    let updatedFields = [];
    datesArray.forEach((formItem) => {
      let formUpdatedItem = formItem;
      formUpdatedItem["timepicker"] = [start, end];
      updatedFields.push(formUpdatedItem);
    });
    formRef.current.setFieldsValue({ dayschedule: updatedFields });
    computeEvt(datesArray);
  };

  const updateAmoSubForm = (value) => {
    // console.log(`AMOS updateSubForm()`);
    let datesArray = formRef.current.getFieldsValue()["dayschedule"];

    let updatedFields = [];
    datesArray.forEach((formItem) => {
      let formUpdatedItem = formItem;
      formUpdatedItem["nbAmo"] = value;
      updatedFields.push(formUpdatedItem);
    });
    formRef.current.setFieldsValue({ dayschedule: updatedFields });
    computeEvt(datesArray);
  };

  const onSelectHours = (value) => {
    // console.log(`onSelectHours() - value : ${JSON.stringify(value)}`);

    let formData = undefined;
    let start = undefined;
    let end = undefined;
    if (value !== null) {
      formData = formRef.current.getFieldsValue();
      start = dayjs(
        new Date(value[0]).toLocaleTimeString().slice(0, 5),
        format
      );
      end = dayjs(new Date(value[1]).toLocaleTimeString().slice(0, 5), format);
      // start = moment(
      //   new Date(value[0]).toLocaleTimeString().slice(0, 5),
      //   format
      // );
      // end = moment(new Date(value[1]).toLocaleTimeString().slice(0, 5), format);
      setStartTime(dayjs(start).format("HH:mm"));
      setEndTime(dayjs(end).format("HH:mm"));

      // TO BEGIN
      // formRef.current.setFieldsValue()["dayschedule"];
    }

    if (value !== null && JSON.stringify(formData) !== "{}") {
      updateSubForm(start, end);
    }
  };

  const onSelectAmo = (value) => {
    setStartAmo(value);
    let formData = formRef.current.getFieldsValue();
    if (value !== null && JSON.stringify(formData) !== "{}") {
      updateAmoSubForm(value);
    }
  };
  // console.log(`TOBI`);
  useEffect(() => {
    // console.log(`AUTRE`);
    let vals = form.getFieldValue("startEndLunch");
    let dateLunchStartTime = vals[0];
    let dateLunchEndTime = vals[1];
    // console.log(
    //   `dateLunchStartTime : ${dateLunchStartTime} / dateLunchEndTime : ${dateLunchEndTime}`
    // );
    let duration = moment.duration(dateLunchEndTime.diff(dateLunchStartTime));
    // console.log(`duration : ${duration}`);
    let lunchHours = duration.asHours();
    // console.log(`lunchHours -> ${lunchHours}`);
    // console.log(`startTime: ${startTime} / endTime : ${endTime}`);

    let unitHour = 0;
    unitHour =
      (new Date(moment(endTime, "HH:mm")).getTime() -
        new Date(moment(startTime, "HH:mm")).getTime()) *
      2.77778e-7;
    setTimePerDay((unitHour - lunchHours) * startAmo);
  }, [startAmo, startTime, endTime]);

  useEffect(() => {
    // console.log(`useEffect() SelectDays.js`);
    setNeededTime(
      selected
        .map((x) => x.duree / 1000)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) *
        1000 *
        2.77778e-7
    );
  }, [selected]);

  // console.log(`OK`);
  return (
    <div>
      {events && events.length !== 0 && (
        <div>
          <Text strong>Période d'éclairement :</Text>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FiSunrise />
          &nbsp;&nbsp;
          {`${moment(
            getSunrise(48.856614, 2.3522219, new Date(events[0].start))
          ).format("HH:mm")}`}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <FiSunset />
          &nbsp;&nbsp;
          {`${moment(
            getSunset(48.856614, 2.3522219, new Date(events[0].start))
          ).format("HH:mm")}`}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {`(${moment(new Date(events[0].start)).format("DD-MM-YYYY")})`}
          <br />
          <br />
        </div>
      )}
      <Form ref={formRef} name="dynamic_form_nest_item" autoComplete="off">
        <Form.List name="dayschedule" initialValue={initVals}>
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Space>
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "12px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Début/Fin de Journée</center>
                      </Text>

                      <TimePicker.RangePicker
                        format={format}
                        disabledTime={disabledDateTime}
                        hideDisabledOptions={true}
                        minuteStep={30}
                        // defaultValue={[
                        //   moment(startTime, format),
                        //   moment(endTime, format),
                        // ]}
                        defaultValue={[
                          dayjs(startTime, format),
                          dayjs(endTime, format),
                        ]}
                        // initialValue={[
                        //   dayjs(new Date(moment(startTime, "HH:mm")).getTime()),
                        //   dayjs(new Date(moment(endTime, "HH:mm")).getTime()),
                        // ]}
                        onChange={onSelectHours}
                      />
                    </Space>
                  </div>
                  &nbsp;&nbsp;
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "16px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Effectifs</center>
                      </Text>
                      <Select
                        placeholder="Nb AMO"
                        style={{
                          width: 100,
                        }}
                        onChange={onSelectAmo}
                        defaultValue={"1 pers"}
                      >
                        <Option value="1">1 pers</Option>
                        <Option value="2">2 pers</Option>
                        <Option value="3">3 pers</Option>
                        <Option value="4">4 pers</Option>
                        <Option value="5">5 pers</Option>
                        <Option value="6">6 pers</Option>
                        <Option value="7">7 pers</Option>
                        <Option value="8">8 pers</Option>
                        <Option value="9">9 pers</Option>
                        <Option value="10">10 pers</Option>
                      </Select>
                    </Space>
                  </div>
                  &nbsp;&nbsp;
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "12px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Début / Fin</center>
                      </Text>
                      <RangePicker
                        // locale={frFR}
                        // defaultValue={[]}
                        // events === undefined || events.length === 0
                        //   ? []
                        //   : [
                        //       moment(new Date(events[0].start)),
                        //       moment(new Date(events[events.length - 1].end)),
                        //     ]
                        // }
                        onChange={(value) => {
                          // console.log(
                          //   `DAY RANGE CHANGE, value : ${JSON.stringify(value)}`
                          // );
                          remove(fields.map((x) => x.name));
                          if (value !== null) {
                            const tmpDates = getDaysBetween(
                              new Date(value[0]),
                              new Date(value[1]),
                              isWeekend
                            );
                            // console.log(`tmpDates : ${JSON.stringify(tmpDates)}`);
                            tmpDates.forEach((x) => {
                              const newFormat = "DD/MM/YYYY" + format;
                              const dateToCompare = moment(
                                x,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD");
                              const foundKey = Object.keys(bankHolidays).find(
                                (key) => key === dateToCompare
                              );

                              // console.log(x);
                              add({
                                date: x,
                                title: capitalizeFirstLetter(
                                  moment(x, newFormat)
                                    .format(titleFormat)
                                    .toString() + (foundKey ? ` (férié)` : "")
                                ),
                                nbAmo: startAmo,
                                // timepicker: [
                                //   moment(x + startTime, newFormat),
                                //   moment(x + endTime, newFormat),
                                // ],
                                timepicker: [
                                  dayjs(x + startTime, newFormat),
                                  dayjs(x + endTime, newFormat),
                                ],
                              });
                            });
                          }

                          let datesArray =
                            formRef.current.getFieldsValue()["dayschedule"];
                          // console.log(
                          //   `datesArray --> ${JSON.stringify(datesArray)}`
                          // );
                          const newFormat = "DD/MM/YYYY" + format;
                          let updatedEvents = [];
                          datesArray.forEach((item) => {
                            // console.log(`item -> ${JSON.stringify(item)}`);
                            updatedEvents.push({
                              start: dayjs(item.date + startTime, newFormat)
                                .toDate()
                                .getTime(),
                              end: dayjs(item.date + endTime, newFormat)
                                .toDate()
                                .getTime(),
                              // start: dayjs(item.date + startTime).format(newFormat),
                              // .toDate()
                              // .getTime(),
                              // end: dayjs(item.date + endTime).format(newFormat),
                              // .toDate()
                              // .getTime(),
                              nbAmo: startAmo,
                            });
                          });
                          // console.log(`updatedEvents :`);
                          // console.log(updatedEvents);
                          setEvents(updatedEvents);
                          updateBookingDeadLine(updatedEvents);
                        }}
                      />
                    </Space>
                  </div>
                  {isWeekend}
                  <Checkbox onChange={onChange}>Weekend(s) inclu(s)</Checkbox>
                </Space>
              </Form.Item>

              {selected.length > 0 && (
                <div>
                  {(events === undefined || events.length === 0) && (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Vous avez besoin de : &nbsp;
                      {Math.round(neededTime / timePerDay) > 1
                        ? `${(neededTime / timePerDay).toFixed(1)} jours`
                        : `${(neededTime / timePerDay).toFixed(1)} jour`}
                    </Tag>
                  )}
                </div>
              )}
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name, "title"]}>
                    <Input
                      style={{
                        background: "white",
                        border: "none",
                        padding: 0,
                        color: "#436386",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "timepicker"]}
                    rules={[
                      {
                        required: true,
                        message: "Veuiller renseigner les horaires",
                      },
                    ]}
                  >
                    <TimePicker.RangePicker
                      format={format}
                      disabledTime={disabledDateTime}
                      hideDisabledOptions={true}
                      minuteStep={30}
                      onChange={(value) => {
                        console.log(`value : ${JSON.stringify(value)}`);
                        computeEvt(
                          formRef.current.getFieldsValue()["dayschedule"]
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "nbAmo"]}
                    rules={[
                      {
                        required: true,
                        message: "Renseigner AMO s.v.p.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Nb AMO"
                      style={{
                        width: 100,
                      }}
                      onChange={(value) => {
                        computeEvt(
                          formRef.current.getFieldsValue()["dayschedule"]
                        );
                      }}
                    >
                      <Option value="1">1 pers</Option>
                      <Option value="2">2 pers</Option>
                      <Option value="3">3 pers</Option>
                      <Option value="4">4 pers</Option>
                      <Option value="5">5 pers</Option>
                      <Option value="6">6 pers</Option>
                      <Option value="7">7 pers</Option>
                      <Option value="8">8 pers</Option>
                      <Option value="9">9 pers</Option>
                      <Option value="10">10 pers</Option>
                    </Select>
                  </Form.Item>

                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                      computeEvt(
                        formRef.current.getFieldsValue()["dayschedule"]
                      );
                    }}
                  />
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default SelectDays;
