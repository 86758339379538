const moment = require("moment");
const dayjs = require("dayjs");
const { Link } = require("react-router-dom");
const axios = require("axios");
const momentTz = require("moment-timezone");
const { message } = require("antd");
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
const ics = require("ics");
const { LinkedList } = require("./LinkedList");
const { defaultColors } = require("./../src/components/plannings/planningData");
// const { isNextSValid, getGridFromAmosList } = require("./../rdv-api/src/utils");
// const { getGridFromAmosList } = require("./../rdv-api/src/services");

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const startDay = "06:00";
const endDay = "20:00";
const startShift = "08:30";
const endShift = "18:30";
const startLunch = "12:30";
const endLunch = "13:30";

const isNextSValid = (nextS) => {
  // console.log(`nextS => ${JSON.stringify(nextS)}`);
  let isValid = (x) => x.length > 0;
  if (
    JSON.stringify(nextS) !== "{}" &&
    Object.keys(nextS).every((_) => isValid(nextS[_]))
  ) {
    return true;
  } else {
    return false;
  }
};

const printGrid = (_shifts) => {
  console.log(
    `\n\n################################################### G.R.I.D ###################################################`
  );
  for (let shift = 0; shift < _shifts.length; shift++) {
    console.log(
      `\n----------\nShift ${shift} (Amo(s): ${_shifts[shift].length})\n`
    );
    for (let amo = 0; amo < _shifts[shift].length; amo++) {
      console.log(`\nAMO no ${amo}\n`);
      if (_shifts[shift][amo] != null && _shifts[shift][amo] !== []) {
        for (let slot = 0; slot < _shifts[shift][amo].length; slot++) {
          console.log(
            `[${shift}][${amo}][${slot}]  ***********  ${moment
              .utc(_shifts[shift][amo][slot].start)
              .format("DD/MM HH:mm")}  -  ${moment
              .utc(_shifts[shift][amo][slot].end)
              .format("DD/MM HH:mm")}    :    ${
              _shifts[shift][amo][slot].booked
            }`
          );
        }
      }
    }
  }
  console.log(
    `\n\n###############################################################################################################\n\n`
  );
};

const isBackgroundEvent = (info) => {
  return (
    info.event.display !== undefined && info.event.display === "background"
  );
};

const getGridFromAmosList = (amosList) => {
  let amoNb = amosList.length;
  let ptr = amosList[0].head;
  let idx = 0;
  // New consolidated Link List to get Events
  let newLinkedList = new LinkedList(
    0,
    amosList[0].planningStart,
    amosList[0].planningEnd,
    amosList[0].pas
  );
  // Take the largest availabilities possible
  // by consolidating all AMOs
  while (ptr !== null) {
    let aggregatedAvailabilities = [];
    if (
      ptr.data.booked === false ||
      (!ptr.data.booked.startsWith("@") && ptr.data.booked.includes("@"))
    ) {
      aggregatedAvailabilities.push(false);
    } else {
      aggregatedAvailabilities.push(true);
    }
    for (let a = 1; a < amoNb; a++) {
      let node = amosList[a].getNodeAt(idx);
      if (
        node.data.booked === false ||
        (!node.data.booked.startsWith("@") && node.data.booked.includes("@"))
      ) {
        aggregatedAvailabilities.push(false);
      } else {
        aggregatedAvailabilities.push(true);
      }
    }
    var result = aggregatedAvailabilities.reduce((a, b) => a && b);

    newLinkedList.add({
      start: idx * amosList[0].pas * 1000,
      end: (idx + 1) * amosList[0].pas * 1000,
      booked: result === false ? false : "@unavailable",
    });

    ptr = ptr.next;
    idx++;
  }
  ptr = newLinkedList.head;
  idx = 0;
  let grid = [];
  let shift = 0;
  while (ptr !== null) {
    if (ptr.data.booked === false) {
      for (let a = 0; a < amoNb; a++) {
        let p = amosList[a].getNodeAt(idx);
        if (grid[shift] === undefined) {
          grid[shift] = [];
        }
        if (grid[shift][a] === undefined) {
          grid[shift][a] = [];
        }
        if (
          p.data.booked === false ||
          (p.data.booked.includes("@") && !p.data.booked.startsWith("@"))
        ) {
          grid[shift][a].push({
            start: amosList[0].planningStart + p.data.start,
            end: amosList[0].planningStart + p.data.end,
            booked: p.data.booked,
          });
        }
      }
      if (ptr.next === null || ptr.next.data.booked !== false) shift++;
    }

    ptr = ptr.next;
    idx++;
  }
  return grid;
};

// const calculateConflicts = (originPeople, people) => {
//   console.log(`------ --- --- --> ${JSON.stringify(people)}`);
//   let score = 0;
//   let ppl = [...people];
//   originPeople.forEach((op) => {
//     let pplIdx = ppl.indexOf(op);
//     if (pplIdx !== -1) {
//       ppl.splice(pplIdx, 1);
//       score += 1;
//     }
//   });
//   return originPeople.length - score;
// };
// const isNextSValid = (nextS, people) => {
//   let setPeople = Array.from(new Set([...people]));
//   let keys = Object.keys(nextS);
//   // console.log(`nextS => ${JSON.stringify(nextS)}`);
//   console.log(`People : ${JSON.stringify(people)}`);
//   let isValid = (x) => x.length > 0;
//   // let isValid2 = (x) => setPeople.includes(x);
//   for (let p = 0; p < setPeople.length; p++) {
//     if (!keys.includes(setPeople[p].toString())) {
//       console.log(
//         `FALSE FOR - setPeople[p] : ${setPeople[p]} - keys : ${JSON.stringify(
//           keys
//         )}`
//       );
//       return false;
//     } else {
//       if (
//         keys[p].length < people.filter((_) => _.toString() === keys[p]).length
//       ) {
//         return false;
//       }
//     }
//   }
//   if (JSON.stringify(nextS) !== "{}" && keys.every((_) => isValid(nextS[_]))) {
//     return true;
//   } else {
//     return false;
//   }
// };
const getPlacedPeople = (amosList) => {
  let people = [];

  for (let amo = 0; amo < amosList.length; amo++) {
    let ptr = amosList[amo].head;
    let start = undefined;
    let counter = 1;
    while (ptr !== null) {
      if (
        ptr.data.booked !== undefined &&
        ptr.data.booked !== false &&
        !ptr.data.booked.includes("@")
      ) {
        if (ptr.data.booked === ptr.next.data.booked) {
          if (start === undefined) {
            start = ptr.data.start;
          }
          counter++;
        } else {
          people.push(counter);
          counter = 1;
          start = ptr.data.start;
        }
      }
      ptr = ptr.next;
    }
  }

  return people;
};
const getPlanningStart = (grid) => {
  let tmin = 164724300000000;
  if (!grid) {
    return tmin;
  } else {
    for (let shift = 0; shift < grid.length; shift++) {
      for (let amo = 0; amo < grid[shift].length; amo++) {
        if (grid[shift][amo] != null && grid[shift][amo] !== []) {
          for (let slot = 0; slot < grid[shift][amo].length; slot++) {
            if (grid[shift][amo][slot].start < tmin) {
              tmin = grid[shift][amo][slot].end;
            }
          }
        }
      }
    }
    return tmin;
  }
};
const getPlanningEnd = (grid) => {
  let tmax = 0;
  if (!grid) {
    return tmax;
  } else {
    for (let shift = 0; shift < grid.length; shift++) {
      for (let amo = 0; amo < grid[shift].length; amo++) {
        if (grid[shift][amo] != null && grid[shift][amo] !== []) {
          for (let slot = 0; slot < grid[shift][amo].length; slot++) {
            if (grid[shift][amo][slot].end > tmax) {
              tmax = grid[shift][amo][slot].end;
            }
          }
        }
      }
    }
    return tmax;
  }
};
const reducer = (accumulator, currentValue) => accumulator + currentValue;
const isBooked = (
  start,
  end,
  startDayHours,
  endDayHours,
  startDayMinutes,
  endDayMinutes,
  startLunchHours,
  startLunchMinutes,
  endLunchHours,
  endLunchMinutes,
  startShiftHours,
  startShiftMinutes,
  endShiftHours,
  endShiftMinutes
) => {
  // @Break
  if (
    moment.utc(start) <
      moment(start)
        .set("hour", startDayHours)
        .set("minutes", startDayMinutes)
        .utc() ||
    moment.utc(start) >=
      moment(start).set("hour", endDayHours).set("minutes", endDayMinutes).utc()
  ) {
    return "@break";
  }

  // @Lunch
  if (
    moment.utc(start) >
      moment(start)
        .set("hour", startLunchHours)
        .set("minutes", startLunchMinutes)
        .utc() &&
    moment.utc(start) <=
      moment(start)
        .set("hour", endLunchHours)
        .set("minutes", endLunchMinutes)
        .utc()
  ) {
    return "@lunch";
  }

  // @Shift
  if (
    moment.utc(start) <
      moment(start)
        .set("hour", startShiftHours)
        .set("minutes", startShiftMinutes)
        .utc() ||
    moment.utc(start) >=
      moment(start)
        .set("hour", endShiftHours)
        .set("minutes", endShiftMinutes)
        .utc()
  ) {
    return "@shift";
  }

  return "@unavailable";
};
const mapList = (
  grid,
  pas,
  startDay,
  endDay,
  startLunch,
  endLunch,
  startShift,
  endShift
) => {
  let nbOfAmos = getAmoNb(grid);
  let planningStart = getPlanningStart(grid);
  let planningEnd = getPlanningEnd(grid);

  let startDayHours = startDay.split(":")[0];
  if (startDayHours.startsWith(`0`)) {
    startDayHours = startDayHours[1];
  }

  let startDayMinutes = startDay.split(":")[1];
  if (startDayMinutes.startsWith(`0`)) {
    startDayMinutes = startDayMinutes[1];
  }

  let endDayHours = endDay.split(":")[0];
  if (endDayHours.startsWith(`0`)) {
    endDayHours = endDayHours[1];
  }

  let endDayMinutes = endDay.split(":")[1];
  if (endDayMinutes.startsWith(`0`)) {
    endDayMinutes = endDayMinutes[1];
  }

  let startLunchHours = startLunch.split(":")[0];
  if (startLunchHours.startsWith(`0`)) {
    startLunchHours = startLunchHours[1];
  }
  let startLunchMinutes = startDay.split(":")[1];
  if (startLunchMinutes.startsWith(`0`)) {
    startLunchMinutes = startLunchMinutes[1];
  }

  let endLunchHours = endLunch.split(":")[0];
  if (endLunchHours.startsWith(`0`)) {
    endLunchHours = endLunchHours[1];
  }
  let endLunchMinutes = startDay.split(":")[1];
  if (endLunchMinutes.startsWith(`0`)) {
    endLunchMinutes = endLunchMinutes[1];
  }

  let startShiftHours = startShift.split(":")[0];
  if (startShiftHours.startsWith(`0`)) {
    startShiftHours = startShiftHours[1];
  }
  let startShiftMinutes = startShift.split(":")[1];
  if (startShiftMinutes.startsWith(`0`)) {
    startShiftMinutes = startShiftMinutes[1];
  }

  let endShiftHours = endShift.split(":")[0];
  if (endShiftHours.startsWith(`0`)) {
    endShiftHours = endShiftHours[1];
  }
  let endShiftMinutes = startShift.split(":")[1];
  if (endShiftMinutes.startsWith(`0`)) {
    endShiftMinutes = endShiftMinutes[1];
  }

  let amosList = [];

  // console.log(
  //   `\nmapList() : ${nbOfAmos} amos, planningStart: ${planningStart}, planningEnd: ${planningEnd}, nbSlots = ${
  //     (planningEnd - planningStart) / (pas * 1000)
  //   }`
  // );

  // Start of planning must be end of shifts
  planningStart = moment(planningStart)
    .set("hour", startDayHours)
    .set("minutes", startDayMinutes)
    .utc();

  // End of planning must be end of shifts
  planningEnd = moment(planningEnd)
    .set("hour", endDayHours)
    .set("minutes", endDayMinutes)
    .utc();

  // Creation des listes chainees, une pour chaque AMO
  for (let i = 0; i < nbOfAmos; i++) {
    let newLinkedList = new LinkedList(i, planningStart, planningEnd, pas);
    let linkedListSize = (planningEnd - planningStart) / (pas * 1000);

    // Remplissage de la data avec start, end et booked

    for (let j = 0; j < linkedListSize; j++) {
      let booked = isBooked(
        planningStart + j * pas * 1000,
        planningStart + (j + 1) * pas * 1000,
        startDayHours,
        endDayHours,
        startDayMinutes,
        endDayMinutes,
        startLunchHours,
        startLunchMinutes,
        endLunchHours,
        endLunchMinutes,
        startShiftHours,
        startShiftMinutes,
        endShiftHours,
        endShiftMinutes
      );
      newLinkedList.add({
        start: j * pas * 1000,
        end: (j + 1) * pas * 1000,
        booked: booked,
      });
    }

    amosList.push(newLinkedList);
  }

  // Remplissage des contraintes de chaque AMO
  for (let shift = 0; shift < grid.length; shift++) {
    for (let amo = 0; amo < grid[shift].length; amo++) {
      if (grid[shift][amo] != null && grid[shift][amo] !== []) {
        for (let slot = 0; slot < grid[shift][amo].length; slot++) {
          let node = amosList[amo].getNodeFromStart(
            planningStart,
            grid[shift][amo][slot].start
          );
          // NEW
          if (node === undefined || node === null) {
            break;
          }
          // console.log(`node : ${JSON.stringify(node)}`);
          if (grid[shift][amo][slot].booked !== false) {
            node.data.booked = grid[shift][amo][slot].booked;
          } else {
            node.data.booked = false;
          }
        }
      }
    }
  }
  return amosList;
};
const getAvailables = (grid) => {
  let nbBooked = 0;
  let nbNotBooked = 0;
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid[i].length; j++) {
      for (let k = 0; k < grid[i][j].length; k++) {
        if (grid[i][j][k].booked !== false) {
          nbBooked += 1;
        } else {
          nbNotBooked += 1;
        }
      }
    }
  }
  return [nbBooked, nbNotBooked];
};
const dummyFill = (pt, amoNb, people, alc) => {
  // printLst(alc);
  // console.log("3");
  if (people.length === 0) {
    // console.log(`\n\n------------\nSOLUTIOIN OK:\n`);
    // printLst(alc);
    // printAvailabilities(getAvailabilities(alc));
    // console.log(`\n--------------------------------\n\n`);
    return alc;
  }
  // console.log(`pt -> pt : ${JSON.stringify(pt)}`);
  if (pt === null) {
    if (amoNb === alc.length - 1) {
      return null;
    } else {
      amoNb++;
      pt = alc[amoNb].head;
    }
  }

  let available = 0;
  let pto = pt;
  while (pto.next !== null) {
    if (pto.next.data.start !== pto.data.end) {
      // console.log(`ARRIVE ----------------------->`);
      break;
      // WTF ?
    } else {
      if (pto.data.booked === false) {
        available += 1;
      } else {
        break;
      }
    }
    pto = pto.next;
  }

  // console.log(`available -> ${available}`);
  if (available === 0) {
    return dummyFill(pt.next, amoNb, people, alc);
  }

  let peopleSet = Array.from(new Set([...people])).sort(function (a, b) {
    return b - a;
  });

  for (let i = 0; i < peopleSet.length; i++) {
    if (peopleSet[i] > available) {
      continue;
    } else {
      let pc = [...people];
      let pplIdx = pc.indexOf(peopleSet[i]);
      pc.splice(pplIdx, 1);

      // let alc2 = getAmosListCopy(alc);
      // let nodeIdx = alc[amoNb].indexOf(pt.data);
      // let nc = alc2[amoNb].getNodeAt(nodeIdx);
      // fillRdv(rdvLength, nc);

      let newPtr = fillRdv(peopleSet[i], pt);
      // console.log(`pc ->>>>> ${pc.length} (${JSON.stringify(pc)})`);
      // this.data.people = pc;

      return dummyFill(newPtr, amoNb, pc, alc);
    }
  }
  // console.log(`fuck`);
  return dummyFill(pt.next, amoNb, [...people], alc);
  // return null;
};
const printLst = (alc) => {
  for (let amo = 0; amo < alc.length; amo++) {
    alc[amo].printList();
  }
};
const printAvailabilities = (S) => {
  let typos = Object.keys(S);
  // console.log(`typos -> ${JSON.stringify(typos)}`);
  for (let typo = 0; typo < typos.length; typo++) {
    for (let slot = 0; slot < S[typos[typo]].length; slot++) {
      console.log(
        `[${typos[typo]}] ${moment
          .utc(S[typos[typo]][slot].start)
          .format("DD/MM HH:mm")}  -  ${moment
          .utc(S[typos[typo]][slot].end)
          .format("DD/MM HH:mm")}    :    ${
          S[typos[typo]][slot].booked
        } (amo : ${S[typos[typo]][slot].amoNb})`
      );
    }
  }
};
const mergeSolutions = (S1, S2) => {
  // console.log(`\n\n***************************************\n\nS1:`);
  // printSolutionsNumber(S1);
  // console.log(`---`);
  // printSolutionsNumber(S2);

  for (const [key, value] of Object.entries(S2)) {
    if (S1[key] === undefined) S1[key] = [];
    for (let v = 0; v < value.length; v++) {
      if (
        S1[key].find(
          (_) => _.start === value[v].start && _.amoNb === value[v].amoNb
        ) === undefined
      ) {
        S1[key].push(value[v]);
      }
    }
  }
  // console.log(`----result:`);
  // printSolutionsNumber(S1);

  return S1;
};
const getAmosListCopy = (amosList) => {
  console.log(`amosList[0].pas : ${amosList[0].pas}`);
  let alc = [];
  for (let amo = 0; amo < amosList.length; amo++) {
    let planningStart = amosList[amo].planningStart;
    let planningEnd = amosList[amo].planningEnd;
    let newLinkedList = new LinkedList(
      amo,
      planningStart,
      planningEnd,
      // this.pas,
      amosList[0].pas // NEW 12/12/23 !!!
    );
    let ptr = amosList[amo].head;
    while (ptr !== null) {
      newLinkedList.add({
        start: ptr.data.start,
        end: ptr.data.end,
        booked: ptr.data.booked,
      });
      ptr = ptr.next;
    }
    alc.push(newLinkedList);
  }
  return alc;
};
const fillRdv = (rdvLength, pt, name = false) => {
  if (!name) {
    name = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
  }

  while (42) {
    if (pt === null) {
      throw new Error(`Should never be null when filling Rdv`);
    }
    if (pt.data.booked !== false) {
      throw new Error(`Is writing but should not`);
    }
    pt.data.booked = `${name}`;
    rdvLength -= 1;
    if (rdvLength === 0) {
      break;
    }
    pt = pt.next;
  }
  return pt;
};
const getAvailabilities = (alc) => {
  let S = {};

  for (let amo = 0; amo < alc.length; amo++) {
    let ptr = alc[amo].head;
    let planningStart = alc[amo].planningStart;
    let oldStart = ptr.data.start;
    let booked = ptr.data.booked;
    let counter = 0;

    while (ptr !== null) {
      // console.log(JSON.stringify(ptr.data));
      if (booked !== ptr.data.booked) {
        oldStart = ptr.data.start;
      }
      if (ptr.data.booked && !ptr.data.booked.includes("@")) {
        let newName = ptr.data.booked;

        while (ptr !== null && newName === ptr.data.booked) {
          counter++;
          ptr = ptr.next;
        }
        // console.log(`nouveau : ${newName}, duree : ${counter}`);
        let sol = {
          start: planningStart + oldStart,
          amoNb: amo,
          length: counter,
          booked: false,
        };
        if (S[counter]) {
          S[counter].push(sol);
        } else {
          S[counter] = [sol];
        }
        booked = ptr.data.booked;
        oldStart = ptr.data.start;
        counter = 0;
        continue;
      } else {
        counter = 0;
      }

      ptr = ptr.next;
    }
  }
  // console.log(S);
  return S;
};
const printSolutionsNumber = (S) => {
  console.log(`\n\n`);
  let keys = Object.keys(S);
  for (let i = 0; i < keys.length; i++) {
    console.log(`Length : ${keys[i]} : ${S[keys[i]].length}`);
  }
};
const giveAnswers = (S, rdvLength, people, initialAmosList, pas) => {
  for (let amo = 0; amo < initialAmosList.length; amo++) {
    let ptr = initialAmosList[amo].head;
    let nodeCounter = 0;
    while (ptr !== null && ptr !== undefined) {
      if (ptr.data.booked === false) {
        // console.log(
        //   `> Test place ${rdvLength} pour amo ${amo} a ${ptr.data.start}`
        // );
        if (
          fit(ptr, rdvLength, pas) &&
          !isAlreadyFoundSolution(
            S,
            rdvLength,
            initialAmosList[amo].planningStart + ptr.data.start,
            amo
          )
        ) {
          // console.log(`Rdv ${rdvLength} DOES FIT`);
          let alc = getAmosListCopy(initialAmosList);
          let nc = alc[amo].getNodeAt(nodeCounter);
          fillRdv(rdvLength, nc);
          let pc = [];
          for (var i = 0; i < people.length; i++) pc[i] = people[i];
          let pplIdx = pc.indexOf(rdvLength);
          pc.splice(pplIdx, 1);

          // printLst(alc);
          let S1 = {};
          // S1 = this.ftRec(0, alc, alc[0].head, [...people]);
          // console.log(
          //   `> Place ${rdvLength} pour amo ${amo} a ${
          //     ptr.data.start
          //   } (people : ${JSON.stringify(pc)})`
          // );
          S1 = dummyFill(alc[0].head, 0, [...pc], alc);
          // console.log(S1);
          // if (S1 !== null) {
          //   // console.log(`S null`);
          //   // S = mergeSolutions(S, getAvailabilities(S1));
          //   S1 = {};
          //   S1[rdvLength] = [
          //     {
          //       start: initialAmosList[amo].planningStart + nc.data.start,
          //       amoNb: amo,
          //       length: rdvLength,
          //       booked: false,
          //     },
          //   ];
          //   S = mergeSolutions(S, S1);
          // }

          if (S1 === null || S1 === undefined) {
            // console.log(
            //   `PAS DE SOLUTION ******** (people : ${JSON.stringify(people)})`
            // );
          } else {
            // console.log(`SOLUTIONNNNNN`);
            // printLst(S1);
            // console.log(
            //   "-------------------------------------------------------------\n\n"
            // );
            // return getAvailabilities(S1);
            S = mergeSolutions(S, getAvailabilities(S1));

            S = mergeSolutions(S, {
              [rdvLength]: [
                {
                  start: initialAmosList[amo].planningStart + nc.data.start,
                  amoNb: amo,
                  length: rdvLength,
                  booked: false,
                },
              ],
            });
          }
        } else {
          // console.log(`Rdv ${rdvLength} DOES NOT FIT`);
          // printAvailabilities(getAvailabilities(S1));
        }
        // throw new Error(`STOP`);
      }

      ptr = ptr.next;
      nodeCounter += 1;
    }
  }
  // console.log(`COUNTER : ${counter}`);
  // console.log(JSON.stringify(S));
  // console.log(`\n---------------------\nFINAL : \n\n`);
  // printAvailabilities(S);
  // console.log("\n---------------\n\n");

  return sortAvailabilities(S);
};
const sortAvailabilities = (S) => {
  let newS = {};
  for (const [key, value] of Object.entries(S)) {
    newS[key] = value.sort(function (a, b) {
      return a.start - b.start;
    });
  }
  return newS;
};
const fit = (pt, rdvLength, pas) => {
  let opt = pt;
  let counter = 0;
  while (opt !== null && counter < rdvLength) {
    if (
      (opt.data.end - opt.data.start) / (pas * 1000) === 1 &&
      opt.data.booked === false
    ) {
      counter++;
    } else {
      break;
    }
    opt = opt.next;
  }
  if (counter === rdvLength) {
    return true;
  }
  return false;
};
const isAlreadyFoundSolution = (S1, rdvLength, start, amo) => {
  if (S1[rdvLength] === undefined) {
    return false;
  }
  if (
    S1[rdvLength].find((_) => _.start === start && _.amoNb === amo) !==
    undefined
  ) {
    // console.log(`\n--------------------> FOUND SOLUTIOIN !!!!!\n`);
    return true;
  }

  return false;
};
const solveRdv = (initialAmosList, people, pas) => {
  let S = {};

  // printLst(initialAmosList);
  // Get all solutions for all rdv lengths
  let S1 = undefined;
  let setAllLengths = Array.from(new Set([...people])).sort(function (a, b) {
    return b - a;
  });
  // console.log(`giveAnswer() `);
  for (let x = 0; x < setAllLengths.length; x++) {
    S1 = [];
    S1 = giveAnswers(S, setAllLengths[x], people, initialAmosList, pas);
    // console.log(JSON.stringify(S1));
    // console.log(S1);
    if (S1 !== null) {
      S = mergeSolutions(S, S1);
      // console.log(JSON.stringify(S));
    }
    // S1[setAllLengths[x]] = S;
  }
  // console.log(`fin giveAnswer()`);
  // if (S == null) {
  //   console.log(`S is null`);
  // } else {
  //   printSolutionsNumber(S);
  // }

  return S;
};
const isAuthTokenExpired = (token) => {
  // JWT token contains: 1. Header 2. Payload 3. Signature (dot separated)
  try {
    let jsonPayload = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= jsonPayload.exp * 1000 ? true : false;
  } catch (e) {
    console.log(e);
    return false;
  }
};
const getBasicAuthConfig = () => {
  const token = localStorage.getItem("authToken");
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};
const getVisitesApiAuth = (params) => {
  var headers = {
    Authorization: "Basic " + process.env.REACT_APP_VR_API_SECRET_KEY,
  };
  return {
    params,
    headers,
  };
};
const getRdvApiAuth = () => {
  var headers = {
    Authorization: "Basic " + process.env.REACT_APP_RDV_API_SECRET_KEY,
  };
  return {
    headers,
  };
};
const addDays = (day, nb) => {
  var dat = new Date(day.valueOf());
  dat.setDate(dat.getDate() + nb);
  return dat;
};
const getDates = (startDate, stopDate, weekend = true) => {
  var dateArray = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    // console.log(`currentDate : ${JSON.stringify(currentDate)}`);
    if (!weekend && currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      dateArray.push(moment(currentDate).format("DD/MM/YYYY"));
    } else if (weekend) {
      dateArray.push(moment(currentDate).format("DD/MM/YYYY"));
    }
    currentDate = addDays(currentDate, 1);
  }
  // console.log(`RESULT : dateåArray : ${JSON.stringify(dateArray)}`);
  return dateArray;
};
const getDaysBetween = (startDate, stopDate, weekend = true) => {
  return getDates(startDate, stopDate, weekend);
};
const getAmoFromAmoList = (amoId, amoList) => {
  let amo = {};
  amoList.forEach((x) => {
    if (x._id === amoId) {
      amo = x;
    }
  });
  return amo;
};
const getInitials = (firstname, lastname) => {
  let initials = "";
  let firstNameSplit = firstname.split(" ");
  let lastNameSplit = lastname.split(" ");
  firstNameSplit.forEach((_) => (initials += `${_[0]}`));
  lastNameSplit.forEach((_) => (initials += `${_[0]}`));
  initials = initials.trim();
  return initials;
};
const rand = function () {
  // taken from https://stackoverflow.com/questions/8532406/create-a-random-token-in-javascript-based-on-user-details
  return Math.random().toString(36).substr(2); // remove `0.`
};
const generateToken = function () {
  return rand() + rand(); // to make it longer
};
const getFormatedClients = (clients, programId) => {
  /*
  This functions get the return of client/housings mongoDB join
  And makes sur to recopy user for each housing in order to
  show every housing with user info on every table line
  */
  let formatedClients = [];
  clients.forEach((cli) => {
    // let usr = cli;
    let hc = [];
    for (var i = 0; i < cli.housings.length; i++) {
      if (cli.housings[i].program === programId) {
        hc.push(cli.housings[i]);
      }
    }
    delete cli.housings;
    hc.forEach((h, idx) => {
      let final = {
        ...cli,
        ...hc[idx],
        _id: cli._id, // NEW === GOOD (for localStorage replace) !!
      };
      formatedClients.push(final);
    });
  });
  return formatedClients; //uniqFmtClients;
};
const getAmoNb = (s) => {
  let max = 0;
  s.forEach((shift) => {
    if (shift.length >= max) {
      max = shift.length;
    }
  });
  return max;
};
const getPlanningsAmoNb = (plannings) => {
  let maxAmoNb = 0;
  let bigMax = 0;
  if (plannings[0].templateType === "promoterImpose") {
    // console.log(plannings[0].broadcastList);
    // console.log(`planning events`);
    plannings.forEach((p) => {
      // console.log(p.events);
      let eventsCopy = [...p.events];
      p.events.forEach((event, idx) => {
        let max = 0;
        for (let x = idx + 1; x < eventsCopy.length; x++) {
          // console.log(`idx start : idx + 1 (${JSON.stringify(event)})`);
          // console.log(`dayjs(event.start) < dayjs(eventsCopy[x].start) &&
          // dayjs(event.end) > dayjs(eventsCopy[x].start)`);
          // console.log(`${dayjs(event.start)} <= ${dayjs(eventsCopy[x].start)} &&
          // ${dayjs(event.end)} > ${dayjs(eventsCopy[x].start)}`);
          // console.log(
          //   dayjs(event.start) <= dayjs(eventsCopy[x].start) &&
          //     dayjs(event.end) > dayjs(eventsCopy[x].start)
          // );
          if (
            (dayjs(event.start) <= dayjs(eventsCopy[x].start) &&
              dayjs(event.end) >= dayjs(eventsCopy[x].start)) ||
            (dayjs(event.start) >= dayjs(eventsCopy[x].start) &&
              dayjs(event.end) <= dayjs(eventsCopy[x].end)) ||
            (dayjs(event.start) <= dayjs(eventsCopy[x].start) &&
              dayjs(event.end) >= dayjs(eventsCopy[x].end))
          ) {
            // console.log(`OK`);
            max += 1;
          }
        }
        if (max > bigMax) bigMax = max;
      });
    });
    // console.log(`bigMax : ${bigMax}`);
    maxAmoNb = bigMax;
  } else {
    plannings.forEach((p) => {
      let maxGrid = getAmoNb(JSON.parse(p.grid));
      if (maxGrid > maxAmoNb) {
        maxAmoNb = maxGrid;
      }
    });
  }
  // console.log(`maxAmoNb ::::::::::::   ${maxAmoNb}`);
  return maxAmoNb;
};
const getAmoAgendas = (S, pas, amoNb = null) => {
  //console.log(`amoNb : ${amoNb}`);
  // Find the maximum number of Amo
  let amoNumber = 0;
  for (let s = 0; s < S.length; s++) {
    let an = getAmoNb(S[s]);
    if (an > amoNumber) {
      amoNumber = an;
    }
  }

  // For each of these amo, fill out schedule
  let amoSlots = [];
  for (let t = 0; t < amoNumber; t++) {
    amoSlots.push([]);
  }

  for (let amo = 0; amo < amoNumber; amo++) {
    // let sol = 0;
    if (amoNb === null) {
      for (let s = 0; s < S.length; s++) {
        let currentVal = "";
        let idx = 0;
        const amoMeeting = {
          start: 0,
          end: 0,
          booked: false,
        };
        for (let shift = 0; shift < S[s].length; shift++) {
          if (S[s][shift] && S[s][shift][amo] && S[s][shift][amo][0]) {
            currentVal = S[s][shift][amo][0].booked;
            idx = 0;
            amoMeeting.start = S[s][shift][amo][0].start;
            amoMeeting.booked = S[s][shift][amo][0].booked;
            for (let slot = 0; slot < S[s][shift][amo].length; slot++) {
              if (S[s][shift][amo][slot].booked !== currentVal) {
                amoMeeting.end = S[s][shift][amo][slot].start;
                amoSlots[amo].push({
                  start: amoMeeting.start,
                  end: amoMeeting.end,
                  booked: currentVal,
                  length: idx,
                });
                currentVal = S[s][shift][amo][slot].booked;
                idx = 0;
                amoMeeting.start = S[s][shift][amo][slot].start;
                amoMeeting.booked = S[s][shift][amo][slot].booked;
              }
              idx += 1;
            }

            amoMeeting.end = S[s][shift][amo][S[s][shift][amo].length - 1].end;
            amoSlots[amo].push({
              start: amoMeeting.start,
              end: amoMeeting.end,
              booked: currentVal,
              length: idx,
            });
          }
        }
      }
    } else if (amoNb === amo) {
      for (let s = 0; s < S.length; s++) {
        let currentVal = "";
        let idx = 0;
        const amoMeeting = {
          start: 0,
          end: 0,
          booked: false,
        };
        for (let shift = 0; shift < S[s].length; shift++) {
          if (S[s][shift] && S[s][shift][amo] && S[s][shift][amo][0]) {
            currentVal = S[s][shift][amo][0].booked;
            idx = 0;
            amoMeeting.start = S[s][shift][amo][0].start;
            amoMeeting.booked = S[s][shift][amo][0].booked;
            for (let slot = 0; slot < S[s][shift][amo].length; slot++) {
              if (S[s][shift][amo][slot].booked !== currentVal) {
                amoMeeting.end = S[s][shift][amo][slot].start;
                amoSlots[amo].push({
                  start: amoMeeting.start,
                  end: amoMeeting.end,
                  booked: currentVal,
                  length: idx,
                });
                currentVal = S[s][shift][amo][slot].booked;
                idx = 0;
                amoMeeting.start = S[s][shift][amo][slot].start;
                amoMeeting.booked = S[s][shift][amo][slot].booked;
              }
              idx += 1;
            }

            amoMeeting.end = S[s][shift][amo][S[s][shift][amo].length - 1].end;
            amoSlots[amo].push({
              start: amoMeeting.start,
              end: amoMeeting.end,
              booked: currentVal,
              length: idx,
            });
          }
        }
      }
    }
  }
  return amoSlots;
};
const getEventsWithLunchBreak = (data) => {
  let newEvents = [];
  for (let x = 0; x < data.events.length; x++) {
    console.log(data.events[x]);
    let start1 = moment(data.events[x].start);
    //console.log(`start1 => ${start1}`)
    let end1 = momentTz(data.events[x].start)
      .tz("Europe/Paris")
      .set({
        h: data.lunchStartTime.split(":")[0],
        m: data.lunchStartTime.split(":")[1],
      });
    // console.log(`end1 => ${end1}`);
    let start2 = momentTz(data.events[x].start)
      .tz("Europe/Paris")
      .set({
        h: data.lunchEndTime.split(":")[0],
        m: data.lunchEndTime.split(":")[1],
      });
    let end2 = moment(data.events[x].end);

    if (end1 > start1) {
      newEvents.push({
        start: new Date(start1).getTime(),
        end: new Date(end1).getTime(),
        nbAmo: data.events[x].nbAmo,
      });
    }

    if (end2 > start2) {
      newEvents.push({
        start: new Date(start2).getTime(),
        end: new Date(end2).getTime(),
        nbAmo: data.events[x].nbAmo,
      });
    }
  }
  return newEvents;
};
const getEventsWithLunchBreakNoAvailables = (data) => {
  // console.log(data.grid);

  let startDay = "06:00";
  let endDay = "20:00";
  let startShift = "08:30";
  let endShift = "18:30";
  let startLunch = "12:30";
  let endLunch = "13:30";

  let initialAmosList = mapList(
    JSON.parse(data.grid),
    data.pas,
    startDay,
    endDay,
    startLunch,
    endLunch,
    startShift,
    endShift
  );

  const getAvailabilities = (alc) => {
    let S = [];

    for (let amo = 0; amo < alc.length; amo++) {
      let ptr = alc[amo].head;
      let planningStart = alc[amo].planningStart;
      let oldStart = ptr.data.start;
      let booked = ptr.data.booked;

      while (ptr !== null) {
        if (booked !== ptr.data.booked) {
          oldStart = ptr.data.start;
        }
        if (!ptr.data.booked || !ptr.data.booked.startsWith("@")) {
          let newName = ptr.data.booked;

          while (ptr !== null && newName === ptr.data.booked) {
            ptr = ptr.next;
          }
          if (ptr !== null) {
            let sol = {
              start: planningStart + oldStart,
              end: planningStart + ptr.data.start,
              amoNb: amo,
              booked: false,
            };
            S.push(sol);
            booked = ptr.data.booked;
            oldStart = ptr.data.start;
          }

          continue;
        }

        ptr = ptr.next;
      }
    }
    return S;
  };
  return getAvailabilities(initialAmosList);
};
const getBookedDates = (amoAgendas) => {
  let bookedDates = {};
  for (let amo = 0; amo < amoAgendas.length; amo++) {
    for (let shift = 0; shift < amoAgendas[amo].length; shift++) {
      if (amoAgendas[amo][shift].booked !== false) {
        bookedDates[amoAgendas[amo][shift].booked] =
          amoAgendas[amo][shift].start / 1000;
      }
    }
  }
  return bookedDates;
};
const getOnlyBookedMails = (amoAgendas) => {
  let bookedDates = [];
  for (let amo = 0; amo < amoAgendas.length; amo++) {
    for (let shift = 0; shift < amoAgendas[amo].length; shift++) {
      if (amoAgendas[amo][shift].booked !== false) {
        bookedDates.push(amoAgendas[amo][shift].booked);
      }
    }
  }
  return bookedDates;
};
const closeWindow = () => {
  window.close();
};
const fromMsToHuman = (millisec) => {
  // tkane from https://living-sun.com/fr/javascript/392904-how-to-convert-time-milliseconds-to-hours-min-sec-format-in-javascript-javascript-datetime-milliseconds.html
  var seconds = (millisec / 1000).toFixed(0);
  var minutes = Math.floor(seconds / 60);
  var hours = "";

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : "0" + hours;
    minutes = minutes - hours * 60;
    minutes = minutes >= 10 ? minutes : "0" + minutes;
  }
  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  if (hours != "") {
    return hours + ":" + minutes; // + ":" + seconds;
  }
  return minutes + ":" + seconds;
};
const copyGrid = (inputGrid) => {
  let outputGrid = [];

  for (let i = 0; i < inputGrid.length; i++) {
    let firstDim = [];
    for (let j = 0; j < inputGrid[i].length; j++) {
      let secondDim = [];
      for (let k = 0; k < inputGrid[i][j].length; k++) {
        let n = Object.assign({}, inputGrid[i][j][k]);
        secondDim.push(n);
      }
      firstDim.push(secondDim);
    }
    outputGrid.push(firstDim);
  }
  return outputGrid;
};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const getPlanningMondays = (start, end) => {
  const getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };
  let mondays = getDates(new Date(start), new Date(end)).map((_) => {
    return getMonday(moment(_, "DD/MM/YYYY"));
  });
  let uniqueMondays = mondays.filter(
    (date, i, self) =>
      self.findIndex((d) => d.getTime() === date.getTime()) === i
  );
  return uniqueMondays;
};
const getTypologies = (tmpClients) =>
  tmpClients
    .map((x) => x.typologie.toLowerCase())
    .reduce(function (acc, curr) {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    }, {});
const getEtages = (tmpClients) =>
  tmpClients
    .map((x) => x.etage.toLowerCase())
    .reduce(function (acc, curr) {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    }, {});
const getBatiments = (tmpClients) =>
  tmpClients
    .map((x) => x.batiment.toLowerCase())
    .reduce(function (acc, curr) {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    }, {});
const phaseTransform = {
  choices: "Choix",
  partitions: "Cloisons",
  predelivery: "Pré-livraison",
  delivery: "Livraison",
};

const viewTransform = {
  timeGridDay: "Jour",
  timeGridWeek: "Semaine",
  dayGridMonth: "Mois",
};

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = {
    Sheets: { clients: ws },
    SheetNames: ["clients"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
const formatXLSCells = (selected) => {
  let fmtSelected = [];
  selected.forEach((x) => {
    let row = {};
    row["Lot"] = x.lotcomm;
    row["Batiment"] = x.batiment;
    row["Etage"] = x.etage;
    row["Typologie"] = x.typologie;
    row["Nom"] = x.nom;
    row["Email Principal"] = x.email;
    row["Tél Monsieur"] = x.telephonemonsieur;
    row["Mail Monsieur"] = x.mailmonsieur;
    row["Tél Madame"] = x.telephonemadame;
    row["Mail Madame"] = x.mailmadame;
    row["Status"] = x.booked;
    row["Durée RDV"] = fromMsToHuman(x.rdvLength);
    row["Date RDV"] =
      x.dateBooked === "-" ? "-" : new Date(x.dateBooked * 1000);
    row["Heure RDV"] =
      x.dateBooked === "-"
        ? "-"
        : moment(new Date(x.dateBooked * 1000)).format("HH:mm");
    row["Lien prise RDV"] = process.env.REACT_APP_SITE + "/entrevue/" + x.uuid;
    row["Intéressé Visite Virtuelle"] = x.isInterestedVr ? "OUI" : "NON";
    row["Pointure 1"] = x.shoeSize;
    row["Pointure 2"] = x.shoeSize2;
    row["Message Acquéreur"] = x.note;
    row["Observations"] = x.observations;
    if (x.visitors.length > 0) {
      x.visitors.forEach((visitor, idx) => {
        row[`Accompagnant ${idx + 1}`] = `${visitor.nom} ${visitor.prenom} `;
        if (visitor.role) {
          row[`Accompagnant ${idx + 1}`] += ` (${visitor.role})`;
        }
        if (visitor.email) {
          row[`Accompagnant ${idx + 1}`] += ` (${visitor.email})`;
        }
        if (visitor.shoeSize) {
          row[`Accompagnant ${idx + 1}`] += ` Pointure: ${visitor.shoeSize}`;
        }
      });
    }
    row["Relances mail"] = x.nbRelancesMail;
    row["Dates Relances Mail"] = x.datesRelancesMail;
    row["Relances SMS"] = x.nbRelancesSMS;
    row["Dates Relances SMS"] = x.datesRelancesSMS;

    // row["Rappels Mail"] = x.nbRappelsMail;
    // row["Rappels SMS"] = x.nbRappelsSMS;

    fmtSelected.push(row);
  });
  fmtSelected.sort(function (a, b) {
    return new Date(a["Date RDV"]) - new Date(b["Date RDV"]);
  });
  return fmtSelected;
};

const formatProgramXLSCells = (selected) => {
  let fmtSelected = [];
  selected.forEach((x) => {
    let row = {};
    row["Lot"] = x.lotcomm;
    row["Batiment"] = x.batiment;
    row["Etage"] = x.etage;
    row["Typologie"] = x.typologie;
    row["Nom"] = x.nom;
    row["Email Principal"] = x.email;
    row["Tél Monsieur"] = x.telephonemonsieur;
    row["Mail Monsieur"] = x.mailmonsieur;
    row["Tél Madame"] = x.telephonemadame;
    row["Mail Madame"] = x.mailmadame;
    fmtSelected.push(row);
  });
  return fmtSelected;
};

const formatXLSCellsImpose = (selected) => {
  let fmtSelected = [];
  selected.forEach((x) => {
    let row = {};
    row["Lot"] = x.lotcomm;
    row["Batiment"] = x.batiment;
    row["Etage"] = x.etage;
    row["Typologie"] = x.typologie;
    row["Nom"] = x.nom;
    row["Email Principal"] = x.email;
    row["Tél Monsieur"] = x.telephonemonsieur;
    row["Mail Monsieur"] = x.mailmonsieur;
    row["Tél Madame"] = x.telephonemadame;
    row["Mail Madame"] = x.mailmadame;
    row["A répondu"] = x.isConfirmed ? "OUI" : "NON";
    row["Réponse"] = x.booked;
    row["Durée RDV"] = fromMsToHuman(x.rdvLength);
    row["Date RDV"] =
      x.dateBooked === "-" ? "-" : new Date(x.dateBooked * 1000);
    row["Heure RDV"] =
      x.dateBooked === "-"
        ? "-"
        : moment(new Date(x.dateBooked * 1000)).format("HH:mm");
    row["Lien prise RDV"] = process.env.REACT_APP_SITE + "/entrevue/" + x.uuid;
    row["Intéressé Visite Virtuelle"] = x.isInterestedVr ? "OUI" : "NON";
    row["Pointure"] = x.shoeSize;
    row["Message Acquéreur"] = x.note;
    row["Observations"] = x.observations;
    if (x.visitors.length > 0) {
      x.visitors.forEach((visitor, idx) => {
        row[`Accompagnant ${idx + 1}`] = `${visitor.nom} ${visitor.prenom} `;
        if (visitor.role) {
          row[`Accompagnant ${idx + 1}`] += ` (${visitor.role})`;
        }
        if (visitor.email) {
          row[`Accompagnant ${idx + 1}`] += ` (${visitor.email})`;
        }
        if (visitor.shoeSize) {
          row[`Accompagnant ${idx + 1}`] += ` Pointure: ${visitor.shoeSize}`;
        }
      });
    }
    row["Relances mail"] = x.nbRelancesMail;
    row["Dates Relances Mail"] = x.datesRelancesMail;
    row["Relances SMS"] = x.nbRelancesSMS;
    row["Dates Relances SMS"] = x.datesRelancesSMS;

    // row["Rappels Mail"] = x.nbRappelsMail;
    // row["Rappels SMS"] = x.nbRappelsSMS;

    fmtSelected.push(row);
  });
  fmtSelected.sort(function (a, b) {
    return new Date(a["Date RDV"]) - new Date(b["Date RDV"]);
  });
  return fmtSelected;
};
const getStatus = (x) => {
  if (x.forced !== null) {
    return "réservé";
  } else if (!x.isActive) {
    return "inactif";
  } else if (x.isBooked) {
    return "réservé";
  } else if (!x.isAvailable) {
    return "indisponible";
  } else {
    return "en attente";
  }
};
const getStatusImpose = (x) => {
  if (x.isConfirmed && !x.isAvailable) {
    return "indisponible";
  } else if (!x.isConfirmed && !x.isActive) {
    return "inactif";
  } else if (x.isConfirmed && x.isAvailable) {
    return "présent";
  } else {
    return "en attente";
  }
};
const filterByKeys = (tmpClients, key) => {
  let newSet = Array.from(new Set(tmpClients.map((x) => x[key])));
  let S = {};
  newSet.forEach((s) => {
    S[s] = tmpClients.filter((x) => {
      return x[key] === s;
    });
  });
  return S;
};
const addMilliseconds = (date, milliseconds) => {
  const result = new Date(date);
  result.setMilliseconds(result.getMilliseconds() + milliseconds);
  return result;
};

const getStrimPhone = (nb) => {
  let fmtd = nb.replace(/\s/g, "");
  if (!fmtd.startsWith("0")) {
    fmtd = "0" + fmtd;
  }
  return fmtd;
};

// const formatEventsMultiImpose = (planning, flag = "lotTypo") => {
//   let eventSourcestoSend = [];
//   // let colors = [
//   //   "#436386", // bleu
//   //   "#b7695d", // rouille
//   //   "#96c0b7",
//   //   "#d4dfc7",
//   // ];

//   let fmtEvents = [];

//   let events = planning.broadcastList.map((rdv, idx2) => {
//     let title = "";
//     let title2 = "";
//     let description = "";

//     if (flag === "nameTypo") {
//       title += purify(rdv.guest.nom) + " " + rdv.guest.typologie.toUpperCase();
//       title2 += purify(rdv.guest.nom) + " " + rdv.guest.typologie.toUpperCase();
//     } else if (flag === "lotTypo") {
//       title += rdv.guest.lotcomm + " " + rdv.guest.typologie.toUpperCase();
//       title2 += rdv.guest.lotcomm + " " + rdv.guest.typologie.toUpperCase();
//     } else if (flag === "nameLot") {
//       title +=
//         purify(rdv.guest.nom) +
//         " " +
//         rdv.guest.lotcomm.toUpperCase() +
//         " " +
//         rdv.guest.typologie.toUpperCase();
//       title2 +=
//         purify(rdv.guest.nom) +
//         " " +
//         rdv.guest.lotcomm.toUpperCase() +
//         " " +
//         rdv.guest.typologie.toUpperCase();
//     }

//     if (rdv.guest.telephonemonsieur || rdv.guest.telephonemadame) {
//       description += `\n\nTél :`;
//     }

//     if (rdv.guest.telephonemonsieur) {
//       description += "\n" + getStrimPhone(rdv.guest.telephonemonsieur);
//     }
//     if (rdv.guest.telephonemadame) {
//       description += "\n" + getStrimPhone(rdv.guest.telephonemadame);
//     }
//     if (rdv.visitors.length > 0) {
//       if (rdv.visitors.length === 1) {
//         description += "\n\nVisiteur :\n";
//       } else if (rdv.visitors.length > 1) {
//         description += "\n\nVisiteurs :\n";
//       }
//       rdv.visitors.forEach((visitor) => {
//         if (visitor !== null) {
//           description += `- ${visitor.nom} ${visitor.prenom}`;
//           if (visitor.shoeSize) {
//             description += ` (Pointure : ${visitor.shoeSize})`;
//           }
//           if (visitor.role) {
//             description += ` (${visitor.role})`;
//           }
//           if (visitor.email) {
//             description += ` (${visitor.email})`;
//           }
//           description += "\n";
//         }
//       });
//     }

//     if (rdv.observations !== "") {
//       description += `\n\nObservations :\n${rdv.observations}`;
//     }

//     fmtEvents.push({
//       start: rdv.start,
//       end: rdv.end,
//       title: title2,
//       description,
//       amo: 0,
//       location:
//         planning.address.numero +
//         " " +
//         planning.address.rue +
//         " " +
//         planning.address.codepostal +
//         " " +
//         planning.address.ville,
//       // isConfirmed: evt.isConfirmed,
//       // isAvailable: evt.isAvailable,
//     });

//     // console.log(evt);
//     // console.log(usr);
//     return {
//       id: rdv._id,
//       title,
//       description,
//       amo: 0,
//       start: rdv.start,
//       end: rdv.end,
//       booked: rdv.booked,
//       isConfirmed: rdv.isConfirmed,
//       isAvailable: rdv.isAvailable,
//       planningId: planning._id,
//     };
//   });

//   // console.log("all EVTS");
//   // console.log(events);
//   // Not confirmed
//   eventSourcestoSend.push({
//     events: events.filter((x) => !x.isConfirmed),
//     color: "#D2D2D2",
//   });

//   // // Confirmed
//   eventSourcestoSend.push({
//     events: events.filter((x) => x.isConfirmed && x.isAvailable),
//     color: "#436386",
//   });

//   // // // Not Available
//   eventSourcestoSend.push({
//     events: events.filter((x) => x.isConfirmed && !x.isAvailable),
//     color: "#ED554A",
//   });

//   return [fmtEvents, eventSourcestoSend];
// };

const getNbOverloap = (evt, allEvts) => {
  let over = 0;
  allEvts.forEach((e) => {
    // if (
    //   (dayjs(evt.start) <= dayjs(e.start) &&
    //     dayjs(evt.end) <= dayjs(e.end) &&
    //     dayjs(evt.end) >= dayjs(e.start)) ||
    //   (dayjs(evt.start) >= dayjs(e.start) && dayjs(evt.end) <= dayjs(e.end)) ||
    //   (dayjs(evt.start) >= dayjs(e.start) && dayjs(evt.end) <= dayjs(e.end)) ||
    //   (dayjs(e.start) >= dayjs(evt.start) && dayjs(e.end) <= dayjs(evt.end)) ||
    //   (dayjs(evt.start) <= dayjs(e.start) && dayjs(evt.end) >= dayjs(e.end))
    // )
    // a = evt
    // b = e
    if (dayjs(evt.start) < dayjs(e.end) && dayjs(e.start) < dayjs(evt.end))
      over += 1; // a in b
  });
  return over;
};

const assignAmo = (appointments) => {
  let tmpApts = [...appointments];
  let newAppointements = [];

  for (let x = 0; x < tmpApts.length; x++) {
    let evt = appointments.pop(x);
    // console.log(evt);
    let restEvts = [...appointments];
    // console.log(`getNbOverlap -> ${getNbOverloap(evt, restEvts)}`);

    newAppointements.push({
      ...evt,
      amo: getNbOverloap(evt, restEvts),
    });
  }
  return newAppointements;
};

// const switchAmo = (planning, rdvs) => {
//   console.log("switchAmoRdv()");
//   let amoNumbers = Array.from(new Set(rdvs.map((_) => _.amo)));
//   console.log(`amoNumbers ; ${JSON.stringify(amoNumbers)}`);

//   let amo1Nb = amoNumbers[0];
//   let amo2Nb = amoNumbers[1];

//   let ialc = mapList(
//     JSON.parse(planning.grid),
//     planning.pas,
//     startDay,
//     endDay,
//     startLunch,
//     endLunch,
//     startShift,
//     endShift
//   );

//   printLst(ialc);
//   return;

//   let rdvsAmo1 = rdvs.filter((_) => _.amo === amo1Nb);
//   let rdvsAmo2 = rdvs.filter((_) => _.amo === amo2Nb);

//   // Find index rdv1
//   // console.log(ialc[amo1Nb].indexFromBooked(rdvsAmo1[0].booked));
//   // console.log(ialc[amo1Nb].lengthOfRdvFromBooked(rdvsAmo1[0].booked));

//   let dataAmo1 = [];
//   let dataAmo2 = [];

//   rdvsAmo1.forEach((_) => {
//     dataAmo1.push(ialc[amo1Nb].getRdvsFromBooked(_.booked));
//   });

//   rdvsAmo2.forEach((_) => {
//     dataAmo2.push(ialc[amo2Nb].getRdvsFromBooked(_.booked));
//   });

//   // console.log(dataAmo1);
//   // console.log(dataAmo2);

//   // retirer rdv amo1 : mettre a false

//   dataAmo1.forEach((_) => {
//     for (let x = 0; x < _.length; x++) {
//       let idx = ialc[amo1Nb].indexOf(_[x]);
//       ialc[amo1Nb].getNodeAt(idx).data.booked = false;
//     }
//   });

//   dataAmo2.forEach((_) => {
//     for (let x = 0; x < _.length; x++) {
//       let idx = ialc[amo2Nb].indexOf(_[x]);
//       ialc[amo2Nb].getNodeAt(idx).data.booked = false;
//     }
//   });

//   // printLst(ialc);

//   // Mettre les rdv1 dds 2 et rdv2 ds 1
//   // Au moindre probleme de placement, retourner false

//   let res = true;
//   dataAmo1.forEach((_) => {
//     for (let x = 0; x < _.length; x++) {
//       let idx = ialc[amo2Nb].indexOf(_[x]);
//       let node = ialc[amo2Nb].getNodeAt(idx);
//       if (node.data.booked !== false) {
//         console.log(`PAS BON I`);
//         res = false;
//       }
//       node.data.booked = _[x].booked;
//     }
//   });

//   dataAmo2.forEach((_) => {
//     for (let x = 0; x < _.length; x++) {
//       let idx = ialc[amo1Nb].indexOf(_[x]);
//       let node = ialc[amo1Nb].getNodeAt(idx);
//       if (node.data.booked !== false) {
//         console.log(`PAS BON II`);
//         res = false;
//       }
//       node.data.booked = _[x].booked;
//     }
//   });

//   if (res !== false) {
//     // Rdvs échangés OK, calculer le reste
//     let nextS = solveRdv(ialc, planning.people, planning.pas);
//     let areValidAvailabilities = isNextSValid(nextS);
//     if (areValidAvailabilities || planning.people.length === 0) {
//       let newGrid = getGridFromAmosList(ialc);

//       planning.grid = JSON.stringify(newGrid);
//       planning.availabilities = JSON.stringify(nextS);
//       planning.planningStartTime = getPlanningStart(newGrid);
//       planning.planningEndTime = getPlanningEnd(newGrid);
//       return planning;
//     } else {
//       console.log(`NE DEVRAIT PAS PASSER LA NORMALEMENT`);
//       return false;
//     }
//   }

//   // // Retirer les rdvs de amo1Nb dans grid1
//   // console.log(`amo1Nb : \n`);
//   // rdvsAmo1.forEach((_) => {
//   //   for (let shift = 0; shift < grid1.length; shift++) {
//   //     if (grid[shift][amo1Nb] != null && grid[shift][amo1Nb].length > 0) {
//   //       for (let slot = 0; slot < grid[shift][amo1Nb].length; slot++) {
//   //         if (grid[shift][amo1Nb][slot].booked === _.booked) {
//   //           console.log(`retire grid1 : ${_.booked}`);
//   //           grid[shift][amo1Nb][slot] = false;
//   //         }
//   //       }
//   //     }
//   //   }
//   // });

//   // // Retirer les rdvs de amo2Nb dans grid2
//   // console.log(`\namo2Nb : \n`);
//   // rdvsAmo2.forEach((_) => {
//   //   for (let shift = 0; shift < grid2.length; shift++) {
//   //     if (grid[shift][amo2Nb] != null && grid[shift][amo2Nb].length > 0) {
//   //       for (let slot = 0; slot < grid[shift][amo2Nb].length; slot++) {
//   //         if (grid[shift][amo2Nb][slot].booked === _.booked) {
//   //           console.log(`retire grid2 : ${_.booked}`);
//   //           grid[shift][amo2Nb][slot] = false;
//   //         }
//   //       }
//   //     }
//   //   }
//   // });

//   return false;
// };

const formatEventsImpose = (
  planning,
  flag = "lotTypo",
  showFreeMeetings = false,
  amoNb = null,
  bankHolidays = null
) => {
  let eventSourcestoSend = [];
  // let colors = [
  //   "#436386", // bleu
  //   "#b7695d", // rouille
  //   "#96c0b7",
  //   "#d4dfc7",
  // ];

  let fmtEvents = [];
  let newAppointements = planning.broadcastList.filter((_) => _.isActive);
  if (amoNb !== null) {
    newAppointements = assignAmo(newAppointements).filter(
      (_) => parseInt(_.amo) === amoNb
    );
  }

  // console.log(`----------------------------------`);

  // let events = planning.broadcastList
  //   .filter((_) => _.isActive && _.amo === amoNb)
  let events = newAppointements.map((rdv, idx2) => {
    let title = getFormatedRdvTitle(
      rdv.guest.nom,
      rdv.guest.lotcomm,
      rdv.guest.typologie,
      rdv.guest.telephonemonsieur,
      rdv.guest.telephonemadame,
      flag
    );
    let title2 = title;
    let description = "";

    if (rdv.guest.telephonemonsieur || rdv.guest.telephonemadame) {
      description += `\n\nTél :`;
    }

    if (rdv.guest.telephonemonsieur) {
      description += "\n" + getStrimPhone(rdv.guest.telephonemonsieur);
    }
    if (rdv.guest.telephonemadame) {
      description += "\n" + getStrimPhone(rdv.guest.telephonemadame);
    }
    if (rdv.visitors.length > 0) {
      if (rdv.visitors.length === 1) {
        description += "\n\nVisiteur :\n";
      } else if (rdv.visitors.length > 1) {
        description += "\n\nVisiteurs :\n";
      }
      rdv.visitors.forEach((visitor) => {
        if (visitor !== null) {
          description += `- ${visitor.nom} ${visitor.prenom}`;
          if (visitor.shoeSize) {
            description += ` (Pointure : ${visitor.shoeSize})`;
          }
          if (visitor.role) {
            description += ` (${visitor.role})`;
          }
          if (visitor.email) {
            description += ` (${visitor.email})`;
          }
          description += "\n";
        }
      });
    }

    if (rdv.observations !== "") {
      description += `\n\nObservations :\n${rdv.observations}`;
    }

    fmtEvents.push({
      start: rdv.start,
      end: rdv.end,
      title: title2,
      description,
      amo: 0,
      location:
        planning.address.numero +
        " " +
        planning.address.rue +
        " " +
        planning.address.codepostal +
        " " +
        planning.address.ville,
    });

    return {
      id: rdv._id,
      title,
      description,
      amo: 0,
      start: rdv.start,
      end: rdv.end,
      booked: rdv.booked,
      isConfirmed: rdv.isConfirmed,
      isAvailable: rdv.isAvailable,
      planningId: planning._id,
    };
  });

  // console.log("all EVTS");
  // console.log(events);

  // Not confirmed
  eventSourcestoSend.push({
    events: events.filter((x) => !x.isConfirmed),
    color: "#D2D2D2",
  });

  // // Confirmed
  eventSourcestoSend.push({
    events: events.filter((x) => x.isConfirmed && x.isAvailable),
    color: "#436386",
  });

  // // // Not Available
  eventSourcestoSend.push({
    events: events.filter((x) => x.isConfirmed && !x.isAvailable),
    color: "#ED554A",
  });

  // // Si jours férier, ajouter les
  if (bankHolidays !== null) {
    // console.log(`adding bank holidays ....`);
    // console.log(JSON.stringify(bankHolidays));
    eventSourcestoSend = eventSourcestoSend.concat({
      events: bankHolidays,
    });
  }

  if (showFreeMeetings) {
    planning.freeMeetings.map((freeMeeting) => {
      let tmp = freeMeeting._id;
      let newFreeMeeting = {
        ...freeMeeting,
        booked: freeMeeting.name,
        title: `*${freeMeeting.name}*`,
        id: tmp,
      };
      eventSourcestoSend.push({
        events: [newFreeMeeting],
        color: freeMeeting.colors,
        startStr: "fm",
      });
    });
  }

  return [fmtEvents, eventSourcestoSend];
};

const purify = (input) => {
  let tmp = input.split(" ");
  let cleaned = [];
  tmp.forEach((element) => {
    if (element.toUpperCase() === element && !element.includes(".")) {
      cleaned.push(element);
    }
  });
  return cleaned.join(" ");
};

const getFormatedPhone = (telephonemonsieur, telephonemadame) => {
  let phone = "";
  if (telephonemonsieur !== undefined && telephonemonsieur !== "") {
    phone += ` ${telephonemonsieur}`;
  }
  if (telephonemadame !== undefined && telephonemadame !== "") {
    phone += ` ${telephonemadame}`;
  }
  return phone.trim();
};

const getFormatedRdvTitle = (
  nom,
  lotcomm,
  typologie,
  telephonemonsieur,
  telephonemadame,
  flag
) => {
  let title = "";
  if (flag === "nameTypo") {
    title += purify(nom) + " " + typologie.toUpperCase();
  } else if (flag === "lot") {
    title += lotcomm;
  } else if (flag === "typo") {
    title += typologie.toUpperCase();
  } else if (flag === "name") {
    title += purify(nom);
  } else if (flag === "lotTypo") {
    title += lotcomm + " " + typologie.toUpperCase();
  } else if (flag === "lotName") {
    title += lotcomm + " " + purify(nom);
  } else if (flag === "typoLot") {
    title += typologie.toUpperCase() + " " + lotcomm;
  } else if (flag === "nameLot") {
    title += purify(nom) + " " + lotcomm.toUpperCase();
  } else if (flag === "nameLotTypo") {
    title +=
      purify(nom) + " " + lotcomm.toUpperCase() + " " + typologie.toUpperCase();
  } else if (flag === "typoNameLot") {
    title +=
      typologie.toUpperCase() + purify(nom) + " " + lotcomm.toUpperCase();
  } else if (flag === "nameTypo") {
    title += purify(nom) + " " + typologie.toUpperCase();
  } else if (flag === "typoName") {
    title += typologie.toUpperCase() + " " + purify(nom);
  } else if (flag === "nameTypoLot") {
    title +=
      purify(nom) + " " + typologie.toUpperCase() + " " + lotcomm.toUpperCase();
  } else if (flag === "lotNameTypo") {
    title +=
      lotcomm.toUpperCase() + " " + purify(nom) + " " + typologie.toUpperCase();
  } else if (flag === "lotTypoName") {
    title +=
      lotcomm.toUpperCase() + " " + typologie.toUpperCase() + " " + purify(nom);
  } else if (flag === "lotNameTypoTel") {
    title +=
      lotcomm.toUpperCase() +
      " " +
      purify(nom) +
      " " +
      typologie.toUpperCase() +
      "\n" +
      getFormatedPhone(telephonemonsieur, telephonemadame);
  }
  return title;
};

const getUsrFromMail = (planning, mail) => {
  if (mail && mail !== undefined && mail !== "@indisponible") {
    return planning.broadcastList.find(
      (x) => x.guest.email === mail || x.guest.mailmonsieur === mail
    );
  } else {
    return undefined;
  }
};

const formatEvents = (
  planning,
  flag = "lotTypo",
  showFreeMeetings = false,
  amoNb = null,
  bankHolidays
) => {
  let eventSourcestoSend = [];
  let colors = [
    "#436386", // bleu
    "#b7695d", // rouille
    "#96c0b7",
    "#d4dfc7",
  ];
  // let forcedRdv = [];

  // if (value !== 3) {
  let forcedRdv = planning.broadcastList
    .filter((x) => x.forced !== null)
    .map((x) => {
      return {
        start: new Date(x.end).getTime(),
        end: new Date(x.end).getTime() + x.length,
        booked: x.guest.email,
        length: x.length / (planning.pas * 1000),
        forced: x.forced,
      };
    });
  // }

  let fmtEvents = [];
  let amoAgendas3 = getAmoAgendas(
    [JSON.parse(planning.grid)],
    planning.pas,
    amoNb
  );

  // console.log(`forcedRdv = ${JSON.stringify(forcedRdv)}`);
  if (forcedRdv.length > 0) {
    amoAgendas3 = amoAgendas3.concat([forcedRdv]);
  }
  //console.log(amoAgendas3);

  amoAgendas3.map((solvedEvent, idx, arr) => {
    // console.log(`RDVI : ${JSON.stringify(solvedEvent)}`);
    // Reserves
    // value === 2
    let tmp = solvedEvent.filter(
      (x) => x.booked !== false && x.booked !== "@indisponible"
    );

    let events = tmp.map((evt, idx2) => {
      //if (parseInt(evt.amoNb) === amoNb) {}
      let usr = getUsrFromMail(planning, evt.booked);
      // console.log(usr);

      let title = getFormatedRdvTitle(
        usr.guest.nom,
        usr.guest.lotcomm,
        usr.guest.typologie,
        usr.guest.telephonemonsieur,
        usr.guest.telephonemadame,
        flag
      );
      let title2 = title;

      let amo = idx;

      let description = "";

      if (usr.guest.telephonemonsieur || usr.guest.telephonemadame) {
        description += `\n\nTél :`;
      }

      if (usr.guest.telephonemonsieur) {
        description += "\n" + getStrimPhone(usr.guest.telephonemonsieur);
      }
      if (usr.guest.telephonemadame) {
        description += "\n" + getStrimPhone(usr.guest.telephonemadame);
      }

      if (
        (usr.shoeSize && usr.shoeSize !== "inconnue") ||
        (usr.shoeSize2 && usr.shoeSize2 !== "inconnue")
      ) {
        description += `\n\nPointure 1 : ${usr.shoeSize} | Pointure 2 : ${usr.shoeSize2}`;
      }

      if (usr.visitors.length > 0) {
        if (usr.visitors.length === 1) {
          description += "\n\nVisiteur :\n";
        } else if (usr.visitors.length > 1) {
          description += "\n\nVisiteurs :\n";
        }
        usr.visitors.forEach((visitor) => {
          if (visitor !== null) {
            description += `- ${visitor.nom} ${visitor.prenom}`;
            if (visitor.shoeSize) {
              description += ` (Pointure : ${visitor.shoeSize})`;
            }
            if (visitor.role) {
              description += ` (${visitor.role})`;
            }
            if (visitor.email) {
              description += ` (${visitor.email})`;
            }
            description += "\n";
          }
        });
      }

      if (usr.observations !== "") {
        description += `\n\nObservations :\n${usr.observations}`;
      }
      // console.log(evt);

      fmtEvents.push({
        start: evt.start,
        end: evt.end,
        title: title2,
        description,
        amo,
        location:
          planning.address.numero +
          " " +
          planning.address.rue +
          " " +
          planning.address.codepostal +
          " " +
          planning.address.ville,
      });
      // }
      //  else {
      //   if (Object.keys(amos).length > 0 && planning.areAmosAssigned) {
      //     title += `${amos[idx].initials}`;
      //   }
      // }

      return {
        id:
          usr === undefined || usr.guest === undefined
            ? `foreground-evt-${idx2}-${amo}`
            : usr.guest.rdv,
        title,
        // description,
        amo,
        start: evt.start,
        end: evt.end,
        booked: evt.booked,
        planningId: planning._id,
      };
    });
    // console.log(events);
    eventSourcestoSend.push({
      events: events,
      color: colors[idx],
    });
  });

  // Put background events (planning boundaries)
  let evts = getEventsWithLunchBreakNoAvailables(planning).map((e, idx) => {
    return {
      id: `background-evt${idx}`,
      start: e.start,
      end: e.end,
    };
  });
  eventSourcestoSend.push({
    events: evts,
    color: "#C7D0DB", // Needed to make opacity
    display: "background",
  });

  // Take into account bank holidays
  // console.log("bankHolidays : ");
  // console.log(bankHolidays);
  if (bankHolidays !== null) {
    // console.log(`pass BO`);
    eventSourcestoSend.push({ events: bankHolidays, display: "background" });
  }

  if (showFreeMeetings) {
    planning.freeMeetings.map((freeMeeting) => {
      // console.log(freeMeeting);
      // delete freeMeeting._id;
      let newFreeMeeting = {
        ...freeMeeting,
        start: new Date(freeMeeting.start).getTime(),
        end: new Date(freeMeeting.end).getTime(),
        booked: freeMeeting.name,
        title: `*${freeMeeting.name}*`,
        planningId: planning._id, // Important for multiple planning, necessity !
        id: freeMeeting._id,
      };
      // console.log(`newfreeMeeting : ${JSON.stringify(newFreeMeeting)}`);
      eventSourcestoSend[0].events.push(newFreeMeeting);
      fmtEvents.push({
        title: newFreeMeeting.title,
        // id: `foreground-evt-${newFreeMeeting.id}`,
        start: newFreeMeeting.start,
        end: newFreeMeeting.end,
        // planningId: newFreeMeeting.planningId,
        // planningId: planning._id,
        // booked: `${newFreeMeeting.title}@gmail.com`,
      });
    });
  }
  // if (amoAgendas3.length !== 0) {
  //   const onlyBooked = getOnlyBookedMails(amoAgendas3);
  //   const notBookedList = planning.broadcastList.filter((rdv) => {
  //     if (!onlyBooked.includes(rdv.guest.email) && rdv.forced === null) {
  //       return rdv;
  //     }
  //   });
  //   setNotBookedList(notBookedList);
  // }
  // console.log(fmtEvents);
  // console.log(eventSourcestoSend);

  // console.log(`fmtEvents`);
  // console.log(fmtEvents);
  // console.log(`eventSourcesToSend : `);
  // console.log(eventSourcestoSend);

  return [fmtEvents, eventSourcestoSend];
};

const getUserLabel = (amo) => {
  return `${amo?.lastname} ${amo?.firstname} (${amo?.enterprise} - ${amo?.role})`;
};

const errorCatcher = (e, text) => {
  if (e.response) {
    let responseStatus = e.response.status;
    if (responseStatus.toString() === "498") {
      message.error(`${e.response.data.error}`);
      const myFunction = () => {
        setTimeout(redirectLogin, 2000);
      };
      const redirectLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SITE}/login`);
      };
      myFunction();
    } else if (responseStatus.toString() === "401") {
      message.error(`${e.response.data.error}`);
    }
    //  else { // TODO
    //   console.log(`AUTRE ERREUR, CODE : ${responseStatus}`);
    // }
  } else {
    message.error(text);
    console.log(e);
  }
};

const cleanLocalStorage = () => {
  localStorage.removeItem("currentProgram");
  localStorage.removeItem("currentProgramId");
  localStorage.removeItem("currentPlanning");
  localStorage.removeItem("currentPlanningId");
};

const tags = [
  "<DEBUT_VISITE>",
  "<ETAGE>",
  "<NOM>",
  "<TYPE_VISITE>",
  "<DEBUT_VISITE>",
  "<LIEN_RDV>",
  "<LIEN_PLAN>",
  "<PROMOTEUR>",
  "<ADDRESSE>",
  "<ADRESSE>",
  "<FIN_PRISE_RDV>",
  "<LOTCOMM>",
  "<CONTACT_NOM>",
  "<CONTACT_TITRE>",
  "<CONTACT_TELEPHONE>",
  "<CONTACT_MAIL>",
  "<CONTACT_PRENOM>",
  "<SIGNATURE>",
  "<NOM_PROGRAMME>",
  "<VILLE_PROGRAMME>",
  "<CODEPOSTAL_PROGRAMME>",
];

const areBalisesOk = (payload) => {
  if (!payload) return false;
  tags.forEach((tag) => {
    payload = payload.replaceAll(tag, "");
  });
  let count1 = (payload.match(/</g) || []).length;
  if (count1 !== 0) return false;
  let count2 = (payload.match(/>/g) || []).length;
  if (count2 !== 0) return false;
  return true;
};

const customTags = {
  "<CONTACT_TITRE>": "contactTitre",
  "<CONTACT_NOM>": "contactNom",
  "<CONTACT_PRENOM>": "contactPrenom",
  "<CONTACT_MAIL>": "contactMail",
  "<CONTACT_TELEPHONE>": "contactTelephone",
  "<SIGNATURE>": "signature",
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const isFieldOk = (field, payload, balises) => {
  let isOk = true;
  // Verification des balises utilisees par rapport au field
  let val = getKeyByValue(customTags, field);
  balises.forEach((balise) => {
    if (val === undefined || val === balise) {
      if (payload === "" || payload === undefined) {
        isOk = false;
      }
    }
  });
  return isOk;
};

const hasFieldToBeProvided = (field, usedBalises) => {
  let isOk = false;
  // Verification des balises utilisees par rapport au field
  let val = getKeyByValue(customTags, field);
  usedBalises.forEach((balise) => {
    if (val === undefined || val === balise) {
      isOk = true;
    }
  });
  return isOk;
};

const getMissingBalises = (obj, payload) => {
  // Decouverte de toutes les balises
  let balises = payload.match(/<(.+?)>/g) || [];
  balises = Array.from(new Set(balises));

  let missings = [];
  // Verification des balises utilisees
  balises.forEach((balise) => {
    if (Object.keys(customTags).includes(balise)) {
      if (
        !(customTags[balise] in obj) ||
        obj[customTags[balise]] === "" ||
        obj[customTags[balise]] === undefined
      ) {
        missings.push(balise);
      }
    }
  });
  return missings;
};

const roundToTwo = (num) => {
  // taken from : https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  return +(Math.round(num + "e+2") + "e-2");
};

// const areBalisesOkkk = (obj) => {
//   let items = [
//     "mailrappelmessage",
//     "smsrappelmessage",
//     "smsrelancemessage",
//     "mailrelancemessage",
//     "mailconfirmationmessage",
//     "mailinvitationmessage",
//   ];

//   // Decouverte de toutes les balises
//   let balises = [];
//   items.forEach((item) => {
//     let tmp = obj[item].match(/<(.+?)>/g) || [];
//     balises = balises.concat(tmp);
//   });
//   balises = Array.from(new Set(balises));
//   console.log(`all BALISES UTILISEES : ${balises}`);

//   let isOk = true;
//   // Verification des balises utilisees
//   balises.forEach((balise) => {
//     if (!tags.includes(balise)) {
//       isOk = false;
//     }
//     if (Object.keys(customTags).includes(balise)) {
//       // console.log(
//       //   `verification : ${balise} (customTags[balise] in obj : ${
//       //     customTags[balise] in obj
//       //   }) (obj[customTags[balise]] === "" : ${
//       //     obj[customTags[balise]] === ""
//       //   }) (obj[customTags[balise]] : ${JSON.stringify(
//       //     obj[customTags[balise]]
//       //   )}) (customTags[balise] : ${customTags[balise]})`
//       // );
//       if (
//         !(customTags[balise] in obj) ||
//         obj[customTags[balise]] === "" ||
//         obj[customTags[balise]] === undefined
//       ) {
//         // console.log(`NNNNON`);
//         console.log(`pas OK pour : ${customTags[balise]}`);
//         isOk = false;
//       }
//     }
//   });
//   return isOk;
// };

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const calendarTags = ["<NOM>", "<LOT>", "<TYPOLOGIE>", "<TELEPHONE>"];

const areCalendarBalisesOk = (payload) => {
  if (!payload) return false;
  calendarTags.forEach((tag) => {
    payload = payload.replaceAll(tag, "");
  });
  let count1 = (payload.match(/</g) || []).length;
  if (count1 !== 0) return false;
  let count2 = (payload.match(/>/g) || []).length;
  if (count2 !== 0) return false;
  return true;
};

const isSiretNumberOk = (obj) => {
  let result = obj.match(/^[0-9]{14}$/g);
  if (result === null) {
    return false;
  }
  return true;
};

const showAllFreeMeetings = (planning, eventSources) => {
  // console.log(`shozAllFreeMeetings()`);
  let newEventSources = [...eventSources];
  planning.freeMeetings.map((freeMeeting) => {
    newEventSources.push({
      events: [
        {
          ...freeMeeting,
          booked: freeMeeting.name,
          title: `*${freeMeeting.name}*`,
        },
      ],
      color: freeMeeting.colors,
      startStr: "fm",
    });
  });
  return newEventSources;
};

const unShowAllFreeMeetings = (eventSources) => {
  let newEventSources = eventSources.filter((eventSource) => {
    return !eventSource["startStr"] || eventSource.startStr !== "fm";
  });
  return newEventSources;
};

const exportPDF = async (plannings, viewType, isFreeMeeting, amoNb = null) => {
  // console.log(`received AmoNB in export PDF : ${amoNb}`);
  try {
    let pUuids = undefined;
    if (plannings.length === 1) {
      pUuids = plannings[0].uuid;
    } else {
      let planningsIds = plannings.map((_) => _.uuid);
      pUuids = planningsIds.join("&");
    }
    pUuids += `--lotTypo`; // PUBLIC CONFIG
    pUuids += `--${viewType}`; // NEW

    //Take into account free meetings to include in request
    if (isFreeMeeting) {
      pUuids += "--showFM";
      // console.log(`attention checked ! ---> ${pUuids}`);
    }

    //Take into account amo single
    if (amoNb !== null) {
      pUuids += `--${amoNb}amoNb`;
    }

    const res = await axios.get(
      `${process.env.REACT_APP_RDV_API_URL}/rdv/${pUuids}/print`,
      { responseType: "arraybuffer" }
    );
    const pdfData = new Blob([res.data]);
    FileSaver.saveAs(
      pdfData,
      `[${plannings[0].promoter.toUpperCase()}] Planning ${
        plannings[0].projectName
      } (consolidé).pdf`
    ); // + fileExtension);
  } catch (e) {
    console.log(e);
    message.error(`Erreur lors de l'export PDF du planning`);
  }
};

// const exportICS = async (plannings, eventSourcetoSend, fmtEvents) => {
//   console.log(fmtEvents);

//   let blobEvents = [];
//   eventSourcetoSend.forEach((amoEvt) => {
//     if (amoEvt.events !== []) {
//       amoEvt.events.map((e) => {
//         let looked = fmtEvents.find(
//           (elt) => elt.title === e.title && elt.start === e.start
//         );
//         if (looked !== undefined) {
//           blobEvents.push(looked);
//         } else {
//           if (e.title && e.title !== "") blobEvents.push(e);
//         }
//       });
//     }
//   });

//   const { error, value } = ics.createEvents(
//     blobEvents.map((_) => {
//       console.log(`______${JSON.stringify(_)}`);
//       return {
//         start: moment(new Date(_.start))
//           .format("YYYY-M-D-H-m")
//           .split("-")
//           .map((_) => parseInt(_)),
//         end: moment(new Date(_.end))
//           .format("YYYY-M-D-H-m")
//           .split("-")
//           .map((_) => parseInt(_)),
//         title: _.title,
//         description: _.description,
//         location: _.location,
//       };
//     })
//   );
//   if (error) {
//     console.log(error);
//     return;
//   }

//   var blob = new Blob([value], { type: "text/calendar" });
//   FileSaver.saveAs(
//     blob,
//     `Planning global ${localStorage.getItem("currentProgram")} (${
//       phaseTransform[plannings[0].phase]
//     }).ics`
//   );
// };

const onClickExportProgramXLS = async (clients) => {
  try {
    let dataSource = [];
    clients.forEach((client) => {
      dataSource.push({
        lotcomm: client.lotcomm,
        nom: client.firstname,
        email: client.email,
        mailmonsieur: client.mailmonsieur,
        mailmadame: client.mailmadame,
        telephonemonsieur: client.telephonemonsieur,
        telephonemadame: client.telephonemadame,
        typologie: client.typologie,
        etage: client.etage,
        batiment: client.batiment,
      });
    });

    exportToCSV(formatProgramXLSCells(dataSource), `Export Programme`);
  } catch (e) {
    console.log(e);
    message.error(`Erreur lors de l'export XLS du planning`);
  }
};

const onClickExportPDF = async (
  plannings,
  config,
  viewType,
  showFreeMeetings,
  isSingleAmo
) => {
  try {
    let amoMax = getPlanningsAmoNb(plannings);
    let planningsIds = plannings.map((_) => _.uuid);
    let pUuids = undefined;
    pUuids = planningsIds.join("-");
    pUuids += `--${config}`;
    pUuids += `--${viewType}`; // NEW

    //Take into account free meetings to include in request
    if (showFreeMeetings) {
      pUuids += "--showFM";
    }

    let res = undefined;
    let pdfData = undefined;
    if (isSingleAmo) {
      const promises = [];
      for (let amo = 0; amo <= amoMax; amo++) {
        promises.push(
          await axios.get(
            `${process.env.REACT_APP_RDV_API_URL}/rdv/${pUuids}--${amo}amoNb/print`,
            { responseType: "arraybuffer" }
          )
        );
      }

      res = await Promise.all(promises);

      res.forEach((r, idx) => {
        const pdfData = new Blob([r.data], {
          type: "application/octet-stream",
        });
        setTimeout(
          () => {
            FileSaver.saveAs(
              pdfData,
              `[${plannings[0].promoter.toUpperCase()}] Planning ${
                plannings[0].projectName
              } (AMO ${idx + 1}).pdf`
            );
          },
          idx === 0 ? 0 : 500
        );
      });
    } else {
      res = await axios.get(
        `${process.env.REACT_APP_RDV_API_URL}/rdv/${pUuids}/print`,
        { responseType: "arraybuffer" }
      );
      pdfData = new Blob([res.data]);
      FileSaver.saveAs(
        pdfData,
        `[${plannings[0].promoter.toUpperCase()}] Planning ${
          plannings[0].projectName
        }.pdf`
      );
    }
  } catch (e) {
    console.log(e);
    message.error(`Erreur lors de l'export PDF du planning`);
  }
};
const onClickExportICS = async (
  plannings,
  isSingleAmo,
  eventSourcetoSend,
  fmtEvents
) => {
  let amoMax = getPlanningsAmoNb(plannings);
  // console.log(`amoMax : ${amoMax}`);
  let blobEvents = [];
  eventSourcetoSend.forEach((amoEvt) => {
    // console.log(amoEvt);
    // Do not take bank holidays
    // if ("color" in amoEvt)
    if (
      ("display" in amoEvt &&
        amoEvt.display !== "background" &&
        "color" in amoEvt) ||
      (!("display" in amoEvt) && "color" in amoEvt)
    ) {
      // NEW
      amoEvt.events.forEach((e) => {
        let looked = fmtEvents.find(
          (elt) => elt.title === e.title && elt.start === e.start
        );
        if (looked) {
          blobEvents.push(looked);
        } else if (e.title && e.title !== "") {
          blobEvents.push(e);
        }
      });
    }
  });

  if (!isSingleAmo) {
    try {
      const { error, value } = await ics.createEvents(
        blobEvents.map((event) => ({
          start: moment(new Date(event.start))
            .format("YYYY-M-D-H-m")
            .split("-")
            .map(Number),
          end: moment(new Date(event.end))
            .format("YYYY-M-D-H-m")
            .split("-")
            .map(Number),
          title: event.title,
          description: event.description,
          location: event.location,
        }))
      );

      if (error) {
        console.error(error);
        return;
      }

      var blob = new Blob([value], { type: "text/calendar" });
      FileSaver.saveAs(
        blob,
        `Planning global ${plannings[0].projectName} (${
          phaseTransform[plannings[0].phase]
        }).ics`
      );
    } catch (error) {
      console.error("Error creating global ICS:", error);
    }
  } else {
    // console.log(`MULTIPLE AMO ICS EXPORT`);
    for (let amo = 0; amo < amoMax; amo++) {
      const filteredEvents = blobEvents.filter(
        (event) => parseInt(event.amo) === amo
      );
      if (filteredEvents.length > 0) {
        try {
          const { error, value } = await ics.createEvents(
            filteredEvents.map((event) => ({
              start: moment(new Date(event.start))
                .format("YYYY-M-D-H-m")
                .split("-")
                .map(Number),
              end: moment(new Date(event.end))
                .format("YYYY-M-D-H-m")
                .split("-")
                .map(Number),
              title: event.title,
              description: event.description,
              location: event.location,
            }))
          );

          if (error) {
            console.error(`Error for AMO ${amo}:`, error);
            continue;
          }

          var blob = new Blob([value], { type: "text/calendar" });
          FileSaver.saveAs(
            blob,
            `Planning AMO ${amo + 1} ${localStorage.getItem(
              "currentProgram"
            )} (${phaseTransform[plannings[0].phase]}).ics`
          );
        } catch (error) {
          console.error(`Error processing AMO ${amo}:`, error);
          continue;
        }
      }
    }
  }
};
const onClickExportXLS = async (plannings) => {
  try {
    let bigDataSource = [];

    plannings.forEach((planning) => {
      let filled = getBookedDates(
        getAmoAgendas([JSON.parse(planning.grid)], planning.pas)
      );

      let dataSource = planning.broadcastList.map((x) => ({
        uuid: x.uuid,
        email: x.guest.email,
        nom: x.guest.nom,
        telephonemonsieur: x.guest.telephonemonsieur,
        telephonemadame: x.guest.telephonemadame,
        mailmonsieur: x.guest.mailmonsieur,
        mailmadame: x.guest.mailmadame,
        lotcomm: x.guest.lotcomm,
        typologie: x.guest.typologie,
        etage: x.guest.etage,
        batiment: x.guest.batiment,
        rdvLength: x.length,
        relancesMail: x.relancesMail,
        relancesSMS: x.relancesSMS,
        rappelsMail: x.rappelsMail,
        rappelsSMS: x.rappelsSMS,
        isActive: x.isActive,
        isInterestedVr: x.isInterestedVr,
        visitors: x.visitors,
        shoeSize: x.shoeSize,
        shoeSize2: x.shoeSize2,
        isAvailable: x.isAvailable,
        observations: x.observations === "" ? "-" : x.observations,
        booked: getStatus(x),
        note: x.note === "" ? "-" : x.note,
        nbRelancesMail:
          x.relancesMail.length === 0 ? "-" : x.relancesMail.length,
        datesRelancesMail:
          x.relancesMail.length === 0
            ? "-"
            : x.relancesMail
                .map(
                  (x) =>
                    `${momentTz(x).tz("Europe/Paris").format("D MMM HH:mm")}`
                )
                .join(" "),
        // nbRappelsMail: x.rappelsMail.length,
        nbRelancesSMS: x.relancesSMS.length === 0 ? "-" : x.relancesSMS.length,
        datesRelancesSMS:
          x.relancesSMS.length === 0
            ? "-"
            : x.relancesSMS
                .map(
                  (x) =>
                    `${momentTz(x).tz("Europe/Paris").format("D MMM HH:mm")}`
                )
                .join(" "),
        // nbRappelsSMS: x.rappelsSMS.length,
        dateBooked:
          x.forced !== null
            ? new Date(x.start).getTime() / 1000
            : x.isBooked //onlyMails.includes(x.guest.email)
            ? JSON.stringify(filled[x.guest.email])
            : "-",
        dateBooked2:
          x.forced !== null
            ? momentTz(x.start).tz("Europe/Paris").format("D MMM")
            : x.isBooked //onlyMails.includes(x.guest.email)
            ? moment.unix(filled[x.guest.email]).format("D MMM")
            : "-",
      }));

      bigDataSource = bigDataSource.concat(dataSource);
    });

    exportToCSV(
      formatXLSCells(bigDataSource),
      `Planning consolidé ${plannings[0].projectName}`
    );
  } catch (e) {
    console.log(e);
    message.error(`Erreur lors de l'export XLS du planning`);
  }
};

const onClickExportXLSImpose = (plannings) => {
  console.log("TIP TOP");
  try {
    let bigDataSource = [];

    plannings.forEach((planning) => {
      let dataSource = planning.broadcastList.map((x) => ({
        uuid: x.uuid,
        email: x.guest.email,
        nom: x.guest.nom,
        telephonemonsieur: x.guest.telephonemonsieur,
        telephonemadame: x.guest.telephonemadame,
        mailmonsieur: x.guest.mailmonsieur,
        mailmadame: x.guest.mailmadame,
        lotcomm: x.guest.lotcomm,
        typologie: x.guest.typologie,
        etage: x.guest.etage,
        batiment: x.guest.batiment,
        rdvLength: x.length,
        relancesMail: x.relancesMail,
        relancesSMS: x.relancesSMS,
        rappelsMail: x.rappelsMail,
        rappelsSMS: x.rappelsSMS,
        isActive: x.isActive,
        isInterestedVr: x.isInterestedVr,
        visitors: x.visitors,
        shoeSize: x.shoeSize,
        isAvailable: x.isAvailable,
        isConfirmed: x.isConfirmed,
        observations: x.observations === "" ? "-" : x.observations,
        booked: getStatusImpose(x),
        note: x.note === "" ? "-" : x.note,
        nbRelancesMail:
          x.relancesMail.length === 0 ? "-" : x.relancesMail.length,
        datesRelancesMail:
          x.relancesMail.length === 0
            ? "-"
            : x.relancesMail
                .map(
                  (x) =>
                    `${momentTz(x).tz("Europe/Paris").format("D MMM HH:mm")}`
                )
                .join(" "),
        // nbRappelsMail: x.rappelsMail.length,
        nbRelancesSMS: x.relancesSMS.length === 0 ? "-" : x.relancesSMS.length,
        datesRelancesSMS:
          x.relancesSMS.length === 0
            ? "-"
            : x.relancesSMS
                .map(
                  (x) =>
                    `${momentTz(x).tz("Europe/Paris").format("D MMM HH:mm")}`
                )
                .join(" "),
        // nbRappelsSMS: x.rappelsSMS.length,
        dateBooked: new Date(x.start).getTime() / 1000,
        dateBooked2: momentTz(x.start).tz("Europe/Paris").format("D MMM"),
      }));
      bigDataSource = bigDataSource.concat(dataSource);
    });

    exportToCSV(
      formatXLSCellsImpose(bigDataSource),
      `${localStorage.getItem("currentPlanning")}`
    );
  } catch (e) {
    console.log(e);
    message.error(`Erreur lors de l'export XLS du planning`);
  }
};

const handleColorsLocalStorage = (vals) => {
  let selectedColor = undefined;
  if (isString(vals.color)) {
    selectedColor = vals.color;
  } else {
    selectedColor = `#${vals.color.toHex()}`;
  }

  let recentColors = localStorage.getItem("recentColors");
  if (recentColors !== null) recentColors = JSON.parse(recentColors);

  const isColorInSuggested = defaultColors.includes(selectedColor);
  let isColorInRecent = false;

  if (recentColors !== null)
    isColorInRecent = recentColors.includes(selectedColor);

  if (!isColorInSuggested && !isColorInRecent) {
    if (recentColors === null) {
      // Premiere fois qu'on enregistre une couleur dans le localStorage
      let newRecentColors = JSON.stringify([selectedColor]);
      localStorage.setItem("recentColors", newRecentColors);
    } else {
      // Pas la premiere fois, ajouter a l'existant
      recentColors.push(selectedColor);
      localStorage.setItem("recentColors", JSON.stringify(recentColors));
    }
  }
};

function itemRender(route, params, items, paths, item) {
  const last = items.indexOf(item) === items.length - 1;
  return last ? (
    <span>{item.title}</span>
  ) : (
    <Link to={paths.join("/")}>{item.title}</Link>
  );
}

const disabledDateTime = () => {
  return {
    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
    // disabledMinutes: () => this.range(30, 60),
    // disabledSeconds: () => [55, 56],
  };
};

const showSmallLengths = (cl) => {
  let answer = false;
  cl.some((client) => {
    if (client.duree <= 2700000) {
      answer = true;
    }
  });
  return answer;
};

const resetCalendarApiDefaultSettings = (calendarApi) => {
  calendarApi.setOption("editable", false);
  calendarApi.setOption("eventDrop", false);
  calendarApi.setOption("eventDragStop", false);
  calendarApi.setOption("eventClick", false);
};

const renderHuman = (duree) => {
  duree = duree / (1800 * 1000) / 2;
  if (duree < 1) {
    return `${dayjs.duration(duree, "hours").as("minutes")} min`;
  } else {
    return `${dayjs.duration(duree, "hours").as("hours")} h`;
  }
};

function isString(value) {
  return typeof value === "string" || value instanceof String;
}

const juridicalForms = [
  "Association",
  "Auto-entrepreneur",
  "Coopérative",
  "CUMA",
  "EARL",
  "EI",
  "EIRL",
  "EURL",
  "GAEC",
  "GEIE",
  "GFA",
  "GIE",
  "Groupement",
  "Indivision",
  "Micro-Entreprise",
  "SARL",
  "SA",
  "SAS",
  "SASU",
  "SC",
  "SCA",
  "SCCV",
  "SCEA",
  "SCEV",
  "SCI",
  "SCIC",
  "SCM",
  "SCL",
  "SCOP",
  "SCP",
  "SCS",
  "SDF",
  "SEL",
  "SELAFA",
  "SELARL",
  "SELAS",
  "SELCA",
  "SEM",
  "SEML",
  "SEP",
  "SICA",
  "SNC",
  "SPFPLA",
  "STEF",
];

export {
  isAuthTokenExpired,
  getBasicAuthConfig,
  getDaysBetween,
  getVisitesApiAuth,
  getRdvApiAuth,
  getAmoFromAmoList,
  generateToken,
  getFormatedClients,
  getOnlyBookedMails,
  getBookedDates,
  getAmoAgendas,
  getAmoNb,
  closeWindow,
  fromMsToHuman,
  getEventsWithLunchBreak,
  getEventsWithLunchBreakNoAvailables,
  copyGrid,
  sleep,
  getInitials,
  phaseTransform,
  getTypologies,
  getDates,
  getPlanningMondays,
  formatXLSCells,
  formatXLSCellsImpose,
  exportToCSV,
  getStatus,
  getPlacedPeople,
  getPlanningStart,
  getPlanningEnd,
  reducer,
  isBooked,
  mapList,
  getAvailables,
  dummyFill,
  printLst,
  printAvailabilities,
  mergeSolutions,
  getAmosListCopy,
  fillRdv,
  getAvailabilities,
  printSolutionsNumber,
  giveAnswers,
  solveRdv,
  getEtages,
  getBatiments,
  filterByKeys,
  getStatusImpose,
  addMilliseconds,
  formatEventsImpose,
  formatEvents,
  getUserLabel,
  errorCatcher,
  cleanLocalStorage,
  tags,
  // areBalisesOkkk,
  customTags,
  isFieldOk,
  getKeyByValue,
  hasFieldToBeProvided,
  areBalisesOk,
  getMissingBalises,
  capitalizeFirstLetter,
  roundToTwo,
  viewTransform,
  areCalendarBalisesOk,
  isSiretNumberOk,
  juridicalForms,
  getFormatedPhone,
  purify,
  getFormatedRdvTitle,
  getUsrFromMail,
  getStrimPhone,
  showAllFreeMeetings,
  unShowAllFreeMeetings,
  exportPDF,
  // exportICS,
  itemRender,
  disabledDateTime,
  resetCalendarApiDefaultSettings,
  renderHuman,
  getPlanningsAmoNb,
  onClickExportXLS,
  onClickExportXLSImpose,
  onClickExportPDF,
  onClickExportICS,
  // switchAmo,
  onClickExportProgramXLS,
  isBackgroundEvent,
  showSmallLengths,
  isString,
  handleColorsLocalStorage,
};
