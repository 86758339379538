import { Empty, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentContainer from "../../../components/ContentContainer";
import SubContentContainer from "../../../components/SubContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";
import { getBasicAuthConfig, errorCatcher } from "../../../utils";
import axios from "axios";

const { Title } = Typography;

const Messages = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  let location = useLocation();
  let programId = location.pathname.split("/")[2];

  const fetchProgramTickets = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/programs/` + programId + `/tickets`,
        getBasicAuthConfig()
      );
      setTickets(res.data.tickets);
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement des tickets`);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchProgramTickets();
      setIsLoading(false);
    };
    newFunc();
  }, []);

  if (tickets.length === 0) {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader title="Messages acquéreurs"></CustomPageHeader>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Pas de messages</span>}
          />
        </SubContentContainer>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader title="Messages acquéreurs"></CustomPageHeader>
          {!isLoading && JSON.stringify(tickets)}
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default Messages;
