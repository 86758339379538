import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { LogoutOutlined } from "@ant-design/icons";

// components
import PromoterLayoutMenu from "../components/promoter/menu/LayoutMenu";
import CustomerLayoutMenu from "../components/customer/menu/LayoutMenu";
import AdministratorLayoutMenu from "../components/admin/menu/LayoutMenu";
import AmoLayoutMenu from "../components/amo/menu/LayoutMenu";

// public pages
import AnonymousVisit from "../pages/publicPages/AnonymousVisit";
import BookRdv from "../pages/publicPages/BookRdv";

// generic pages
import LoginPageWithMessage from "../pages/LoginWithMessage";
import LoginPage from "../pages/Login";
import LogoutPage from "../pages/Logout";
import RegisterPage from "../pages/Register";
import Profile from "../pages/Profile";
import GDPRPage from "../pages/Gdpr";
import HelpPage from "../pages/Help";
import NotFoundPage from "../pages/NotFound";
import PublicHome from "../pages/PublicHome";
import VisitesOpen from "../pages/VisitesOpen";
import InsertTemplate from "../pages/InsertTemplate";
import HelpTemplate from "../pages/HelpTemplate";

// acquereur
import Actualite from "../pages/acquereur/Actualite";
import TMA from "../pages/acquereur/TMA";
import Choix from "../pages/acquereur/Choix";
import Messages from "../pages/acquereur/Messages";
import Documents from "../pages/acquereur/Documents";
import FAQPage from "../pages/acquereur/FAQ";
import Chantier from "../pages/acquereur/Chantier";
import Logements from "../pages/acquereur/Logements";

// promoteur
import Home from "../pages/promoteur/Home";
import ProgramPage from "../pages/promoteur/Program";
import PromoterMessages from "../pages/promoteur/projets/Messages";
import PromoterDashboard from "../pages/promoteur/PromoterDashboard";
import Apercu from "../pages/promoteur/projets/Apercu";
import Clients from "../pages/promoteur/projets/Clients";
import Client from "../pages/promoteur/projets/Client";
import Plannings from "../pages/promoteur/projets/Plannings";
import PlanningsConsolidate from "../pages/promoteur/projets/PlanningsConsolidate";
// import Planning from "../pages/promoteur/projets/Planning";
import PlanningLibre from "../pages/promoteur/projets/PlanningLibre";
import PlanningImpose from "../pages/promoteur/projets/PlanningImpose";
import PlanningReadOnly from "../pages/promoteur/projets/PlanningReadOnly";
import PlanningPrintPdf from "../pages/promoteur/projets/PlanningPrintPdf";
import CreatePlanning from "../pages/promoteur/projets/CreatePlanning";
import CreatePlanningImpose from "../pages/promoteur/projets/CreatePlanningImpose";
import Visites from "../pages/promoteur/projets/Visites";
import Parametres from "../pages/promoteur/projets/Parametres.js";

// administrateur
import HomeAdministrator from "../pages/admin/Home";
import AdminUsers from "../pages/admin/AdminUsers";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminUsersPermissions from "../pages/admin/AdminUsersPermissions";
import AdminProgramsPermissions from "../pages/admin/AdminProgramsPermissions";
import AdminLogsVisites from "../pages/admin/AdminLogsVisites";
import AdminLogsRdvs from "../pages/admin/AdminLogsRdvs";
import AdminLogsTrends from "../pages/admin/AdminLogsTrends";
import AdminPrograms from "../pages/admin/AdminPrograms";
import AdminUserView from "../pages/admin/AdminUserView";
import AdminFacturationEntities from "../pages/admin/AdminFacturationEntities";
import AdminFacturationPlannings from "../pages/admin/AdminFacturationPlannings";
import AdminEntityPage from "../pages/admin/AdminEntityPage";
// import Matterport from "../pages/admin/Matterport";

// amo
import HomeAmo from "../pages/amo/Home";
import AmoPrograms from "../pages/amo/AmoPrograms";
import AmoDashboard from "../pages/amo/AmoDashboard";
import AmoTemplates from "../pages/amo/AmoTemplates";

import { PrivateRoute } from "./PrivateRoute";
import { isAuthTokenExpired } from "../utils";
import { UserContext } from "../context/UserContext";
import { FloatButton, Spin } from "antd";

export const MainRouter = () => {
  const [isLoading, setLoading] = useState(true);
  const { isAuth, setAuth, setRole, role, setUsername } =
    useContext(UserContext);

  useEffect(() => {
    const fetchUsersMe = async (token) => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/users/me",
          config
        );

        setAuth(true);
        setRole(res.data.role);
        setUsername(res.data.username);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    let token = localStorage.getItem("authToken");
    if (token !== "undefined" && token !== null && !isAuthTokenExpired(token)) {
      fetchUsersMe(token);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LogoutSimulation = async () => {
    localStorage.setItem("authToken", localStorage.getItem("adminAuthToken"));
    localStorage.setItem("username", localStorage.getItem("adminUsername"));
    localStorage.removeItem("adminAuthToken");
    localStorage.removeItem("adminUsername");
    setAuth(true);
    setRole("administrator");
    setUsername(localStorage.getItem("adminUsername"));
    if (localStorage.getItem("username") !== null) {
      // redirect to admin/programes
      window.location.href = "/admin/programmes";
    }
  };

  if (isLoading) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else {
    // console.log(`Pass RET`);
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/immersion" component={AnonymousVisit} />
            <Route path="/entrevue/:id" component={BookRdv} />
            <Route
              path="/pview/:planninguuid"
              exact
              component={PlanningReadOnly}
            />
            <Route
              path="/pview/:planninguuid/:amoid"
              exact
              component={PlanningReadOnly}
            />
            <Route
              path="/print/:planninguuid/:viewtype/"
              exact
              component={PlanningPrintPdf}
            />
            <Route
              path="/print/:planninguuid/:viewtype/:amoid"
              exact
              component={PlanningPrintPdf}
            />
            {isAuth && role === "promoter" && (
              <PromoterLayoutMenu>
                <Switch>
                  <Redirect to="/accueil-promoteur" path="/promoter" exact />
                  <Redirect to="/accueil-promoteur" path="/" exact />
                  <PrivateRoute
                    path="/accueil-promoteur"
                    exact
                    component={Home}
                  />
                  <PrivateRoute path="/projets" exact component={ProgramPage} />
                  <Route
                    path="/projets/:id/clients"
                    exact
                    component={Clients}
                  />
                  <Route
                    path="/projets/:id/messages"
                    exact
                    component={PromoterMessages}
                  />
                  <Route
                    path="/projets/:id/clients/:idc"
                    exact
                    component={Client}
                  />
                  <Route
                    path="/projets/:id/plannings"
                    exact
                    component={Plannings}
                  />
                  <Route
                    path="/projets/:id/plannings-consolidate"
                    exact
                    component={PlanningsConsolidate}
                  />
                  <Route
                    path="/projets/:id/plannings/nouveau"
                    exact
                    component={CreatePlanning}
                  />
                  <PrivateRoute
                    path="/promoter/plannings"
                    exact
                    component={PromoterDashboard}
                  />

                  <PrivateRoute
                    path="/promoter/templates"
                    exact
                    component={AmoTemplates}
                  />
                  <PrivateRoute
                    path="/promoter/templates/nouveau"
                    exact
                    component={InsertTemplate}
                  />
                  <PrivateRoute
                    path="/promoter/templates/help"
                    exact
                    component={HelpTemplate}
                  />

                  <Route
                    path="/projets/:id/plannings/nouveau-impose"
                    exact
                    component={CreatePlanningImpose}
                  />
                  <Route
                    path="/projets/:id/plannings-libres/:planningid"
                    exact
                    component={PlanningLibre}
                  />
                  <Route
                    path="/projets/:id/plannings-imposes/:planningid"
                    exact
                    component={PlanningImpose}
                  />
                  <Route
                    path="/projets/:id/parametres"
                    exact
                    component={Parametres}
                  />
                  <Route
                    path="/projets/:id/visites-3d"
                    exact
                    component={Visites}
                  />
                  <Route path="/projets/:id" exact component={Apercu} />

                  <Route path="/logout" exact component={LogoutPage} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/rgpd" exact component={GDPRPage} />
                  <Route path="/aide" exact component={HelpPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </PromoterLayoutMenu>
            )}

            {isAuth && role === "customer" && (
              <CustomerLayoutMenu>
                <Switch>
                  <Redirect to="/accueil-acquereur" path="/" exact />
                  <PrivateRoute
                    path="/accueil-acquereur"
                    exact
                    component={Logements}
                  />
                  <Route path="/logement/:id" exact component={Actualite} />
                  <Route
                    path="/logement/:id/actualite"
                    exact
                    component={Actualite}
                  />
                  <Route path="/logement/:id/tmas" exact component={TMA} />
                  <Route path="/logement/:id/choix" exact component={Choix} />
                  <Route
                    path="/logement/:id/messages"
                    exact
                    component={Messages}
                  />
                  <Route
                    path="/logement/:id/documents"
                    exact
                    component={Documents}
                  />
                  <Route
                    path="/logement/:id/chantier"
                    exact
                    component={Chantier}
                  />
                  <Route
                    path="/logement/:id/foire-aux-questions"
                    exact
                    component={FAQPage}
                  />
                  <Route path="/logout" exact component={LogoutPage} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/rgpd" exact component={GDPRPage} />
                  <Route path="/aide" exact component={HelpPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </CustomerLayoutMenu>
            )}

            {isAuth && role === "administrator" && (
              <AdministratorLayoutMenu>
                <Switch>
                  <Redirect to="/accueil-administrateur" path="/admin" exact />
                  <Redirect
                    to="/accueil-administrateur"
                    path="/admin/logs"
                    exact
                  />
                  <Redirect to="/accueil-administrateur" path="/" exact />
                  <PrivateRoute
                    path="/accueil-administrateur"
                    exact
                    component={HomeAdministrator}
                  />
                  <Route
                    path="/admin/users/:id"
                    exact
                    component={AdminUserView}
                  />
                  <PrivateRoute
                    path="/admin/users"
                    exact
                    component={AdminUsers}
                  />
                  <PrivateRoute
                    path="/admin/permissions/users"
                    exact
                    component={AdminUsersPermissions}
                  />
                  <PrivateRoute
                    path="/admin/permissions/programs"
                    exact
                    component={AdminProgramsPermissions}
                  />
                  <Route
                    path="/admin/facturation/entities/:id"
                    exact
                    component={AdminEntityPage}
                  />
                  <PrivateRoute
                    path="/admin/facturation/entities"
                    exact
                    component={AdminFacturationEntities}
                  />
                  <PrivateRoute
                    path="/admin/facturation/plannings"
                    exact
                    component={AdminFacturationPlannings}
                  />
                  <PrivateRoute
                    path="/admin/plannings"
                    exact
                    component={AdminDashboard}
                  />
                  <PrivateRoute
                    path="/admin/logs/visites"
                    exact
                    component={AdminLogsVisites}
                  />
                  <PrivateRoute
                    path="/admin/logs/rdvs"
                    exact
                    component={AdminLogsRdvs}
                  />
                  <PrivateRoute
                    path="/admin/logs/trends"
                    exact
                    component={AdminLogsTrends}
                  />
                  <Redirect to="/admin/programmes" path="/projets" exact />
                  <PrivateRoute
                    path="/admin/programmes"
                    exact
                    component={AdminPrograms}
                  />
                  <Route path="/projets/:id" exact component={Apercu} />

                  <Route
                    path="/projets/:id/clients"
                    exact
                    component={Clients}
                  />
                  <Route
                    path="/projets/:id/messages"
                    exact
                    component={PromoterMessages}
                  />
                  <Route
                    path="/projets/:id/clients/:idc"
                    exact
                    component={Client}
                  />
                  <Route
                    path="/projets/:id/plannings"
                    exact
                    component={Plannings}
                  />
                  <Route
                    path="/projets/:id/plannings-consolidate"
                    exact
                    component={PlanningsConsolidate}
                  />
                  <Route
                    path="/projets/:id/plannings/nouveau"
                    exact
                    component={CreatePlanning}
                  />
                  <Route
                    path="/projets/:id/plannings/nouveau-impose"
                    exact
                    component={CreatePlanningImpose}
                  />
                  <Route
                    path="/projets/:id/plannings-libres/:planningid"
                    exact
                    component={PlanningLibre}
                  />
                  <Route
                    path="/projets/:id/plannings-imposes/:planningid"
                    exact
                    component={PlanningImpose}
                  />
                  <Route
                    path="/projets/:id/parametres"
                    exact
                    component={Parametres}
                  />
                  <Route
                    path="/projets/:id/visites-3d"
                    exact
                    component={Visites}
                  />

                  <PrivateRoute
                    path="/admin/visites"
                    exact
                    component={VisitesOpen}
                  />

                  {/* <PrivateRoute path="/matterport" exact component={Matterport} /> */}
                  <Route path="/logout" exact component={LogoutPage} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/rgpd" exact component={GDPRPage} />
                  <Route path="/aide" exact component={HelpPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </AdministratorLayoutMenu>
            )}

            {isAuth && role === "amo" && (
              <AmoLayoutMenu>
                <Switch>
                  <Redirect to="/projets" path="/amo" exact />
                  <Redirect to="/projets" path="/" exact />
                  <PrivateRoute path="/accueil-amo" exact component={HomeAmo} />
                  <PrivateRoute path="/projets" exact component={AmoPrograms} />
                  <Route path="/projets/:id" exact component={Apercu} />
                  <Route
                    path="/projets/:id/clients"
                    exact
                    component={Clients}
                  />
                  <Route
                    path="/projets/:id/messages"
                    exact
                    component={PromoterMessages}
                  />
                  <Route
                    path="/projets/:id/clients/:idc"
                    exact
                    component={Client}
                  />
                  <Route
                    path="/projets/:id/plannings"
                    exact
                    component={Plannings}
                  />
                  <Route
                    path="/projets/:id/plannings-consolidate"
                    exact
                    component={PlanningsConsolidate}
                  />
                  <Route
                    path="/projets/:id/plannings/nouveau"
                    exact
                    component={CreatePlanning}
                  />
                  <PrivateRoute
                    path="/amo/plannings"
                    exact
                    component={AmoDashboard}
                  />
                  <PrivateRoute
                    path="/amo/templates"
                    exact
                    component={AmoTemplates}
                  />
                  <PrivateRoute
                    path="/amo/templates/nouveau"
                    exact
                    component={InsertTemplate}
                  />
                  <PrivateRoute
                    path="/amo/templates/help"
                    exact
                    component={HelpTemplate}
                  />
                  <Route
                    path="/projets/:id/plannings/nouveau-impose"
                    exact
                    component={CreatePlanningImpose}
                  />
                  <Route
                    path="/projets/:id/plannings-libres/:planningid"
                    exact
                    component={PlanningLibre}
                  />
                  <Route
                    path="/projets/:id/plannings-imposes/:planningid"
                    exact
                    component={PlanningImpose}
                  />
                  <Route
                    path="/projets/:id/parametres"
                    exact
                    component={Parametres}
                  />
                  <Route
                    path="/projets/:id/visites-3d"
                    exact
                    component={Visites}
                  />

                  <Route path="/logout" exact component={LogoutPage} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/rgpd" exact component={GDPRPage} />
                  <Route path="/aide" exact component={HelpPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </AmoLayoutMenu>
            )}
            <Route path="/login" exact component={LoginPage} />
            {/* <Route path="/register" exact component={RegisterPage} /> */}
            <Route path="/" exact component={PublicHome} />
            {isAuth ? (
              <Route component={NotFoundPage} />
            ) : (
              <Route component={LoginPageWithMessage} />
            )}
          </Switch>
        </Router>
        {localStorage.getItem("adminAuthToken") && (
          <FloatButton
            icon={<LogoutOutlined />}
            type="primary"
            tooltip="Stop simulation"
            onClick={() => {
              LogoutSimulation();
            }}
          />
        )}
      </div>
    );
  }
};
