import React from "react";
import moment from "moment";
import axios from "axios";
import { Row, Col, Typography, Collapse, App } from "antd";
import { SafetyCertificateOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Panel } = Collapse;
moment.locale("fr");

const GDPRBlaBla = ({ rdv }) => {
  const { message } = App.useApp();
  const gdprAskInfo = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + "/gdpr/ask-info", rdv);
      message.success(
        `Un mail vous a été envoyé à l'adresse suivante: ${rdv.guest.email}. Il contient vos données personnelles.`
      );
    } catch (e) {
      console.log(e);
      message.error("Une erreur est apparue lors de la demande");
    }
  };

  // const gdprDelInfo = async () => {
  //   try {
  //     await axios.post(process.env.REACT_APP_API_URL + "/gdpr/del-info", rdv);
  //     message.success(
  //       `Nous avons bien pris en compte votre demande, vous serez très prochainement contacté à l'adresse ${rdv.guest.email} concernant votre demande de suppression de vos données personnelles.`
  //     );
  //   } catch (e) {
  //     console.log(e);
  //     message.error("Une erreur est apparue lors de la demande");
  //   }
  // };

  return (
    <Row>
      <Col xs={0} sm={0} md={4} lg={4} xl={6}></Col>
      <Col xs={22} sm={22} md={16} lg={16} xl={12}>
        <Collapse ghost>
          <Panel
            header={
              <div>
                <Text type="secondary" style={{ textAlign: "center" }}>
                  {" "}
                  <SafetyCertificateOutlined />
                  &nbsp;Protection de vos données : Cliquez pour en savoir plus
                </Text>
              </div>
            }
            key="1"
            showArrow={false}
          >
            <p>
              {" "}
              <Text type="secondary">
                Depuis le 25 mai 2018, toutes les entreprises qui gèrent des
                données à caractère personnel sont soumises à des obligations
                sur la protection des données des utilisateurs. Chez Bridgit,
                cela signifie que vous pouvez à tout moment:{" "}
                <a onClick={gdprAskInfo}>
                  Demander les informations vous concernant
                </a>
                ,{" "}
                <a onClick={gdprAskInfo}>
                  Supprimer les informations vous concernant
                </a>
                , ou encore contacter la personne en charge des données
                personnelles pour faire valoir vos droits à l'adresse suivante :{" "}
              </Text>
              <Text underline>
                <a href="mailto:information@bridg-it.fr">
                  information@bridg-it.fr
                </a>
              </Text>
              <Text type="secondary">.</Text>
            </p>
          </Panel>
        </Collapse>
      </Col>
      <Col xs={0} sm={0} md={4} lg={4} xl={6}></Col>
    </Row>
  );
};

export default GDPRBlaBla;
