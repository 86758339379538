import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Space, Divider, Popconfirm, App } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { getVisitesApiAuth } from "../utils";

const ManagePromoter = ({
  visible,
  setVisible,
  promoterList,
  fetchPromoterList,
  visitList,
}) => {
  const { message } = App.useApp();
  const [linksTo, setLinksTo] = useState([]);

  const removePromoter = async (id) => {
    await axios.delete(
      process.env.REACT_APP_VR_API_URL + "/promoter/" + id,
      getVisitesApiAuth()
    );
    fetchPromoterList();
    message.success("Promoteur supprimé !");
  };

  useEffect(() => {
    // console.log("Loading");
    // TODO: Change below
    let tmp = {};
    promoterList.forEach((promoter) => {
      tmp[promoter._id] = visitList.filter(
        (visit) =>
          visit.promoter === promoter.name ||
          visit.promoter === promoter.internalName
      ).length;
    });
    setLinksTo(tmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {linksTo.length !== 0 && (
        <Modal
          open={visible}
          title="Gestion des promoteurs visites"
          cancelText="Retour"
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <br />
          {promoterList.map((promoter, idx) => (
            <div key={"pmterImg-" + idx}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space direction="horizontal">
                  <img
                    width="64px"
                    height="100%"
                    src={
                      process.env.REACT_APP_VR_API_URL +
                      "/promoter/" +
                      promoter._id +
                      "/logo"
                    }
                    alt="logo"
                  />
                  {promoter.name}
                  {"(utilisé pour " + linksTo[promoter._id] + " visites)"}
                </Space>
                <div>
                  <Popconfirm
                    title="Etes-vous sûr(e) de vouloir supprimer ce promoteur ?"
                    onConfirm={(e) => {
                      removePromoter(promoter._id);
                    }}
                    okText="Oui"
                    onCancel={() => {
                      message.error("Suppression annulée");
                    }}
                    cancelText="Non"
                  >
                    <a>
                      <DeleteOutlined />
                    </a>
                  </Popconfirm>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default ManagePromoter;
