import React from "react";
import {
  Dropdown,
  Button,
  message,
  Space,
  notification,
  Checkbox,
  App,
} from "antd";
import {
  DownOutlined,
  VideoCameraOutlined,
  RocketOutlined,
  ShakeOutlined,
  ExportOutlined,
  NotificationOutlined,
  FormOutlined,
  HistoryOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { formatXLSCells, exportToCSV } from "../../utils";
import axios from "axios";

const PlanningOptions = ({
  selected,
  unSelectedAllRows,
  fetchPlanningAndFmtEvents,
  setShowCustomMail,
  setShowRappelsRelances,
}) => {
  const { message } = App.useApp();
  const closeRelanceMail = () => {
    console.log(
      "NotificationRelanceMail was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  const closeInvitationMail = () => {
    console.log(
      "NotificationInvitationMail was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  const closeRappelMail = () => {
    console.log(
      "NotificationRappelMail was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  const closeCancelSelectedMeetings = () => {
    console.log(
      "closeCancelSelectedMeetings was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  const exportXLS = () => {
    console.log(`need to export : ${JSON.stringify(selected)}`);
    exportToCSV(
      formatXLSCells(selected),
      `${localStorage.getItem("currentPlanning")}`
    );
  };

  const launchInvitationMail = async (invitationMail) => {
    try {
      const fullPath = window.location.pathname.split("/");
      const planningId = fullPath[fullPath.length - 1];

      if (
        selected.some(
          (x) =>
            x.booked === "réservé" ||
            x.booked === "inactif" ||
            x.isInvitationSent
        )
      ) {
        message.error(
          "Veuillez vérifier les personnes sélectionnées pour les invitations. Certaines ont déjà pris rendez-vous, sont inactifs, ou ont déjà reçu une invitation !"
        );
      } else {
        const uuids = selected.map((x) => x.uuid);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planningId + "/inviter",
          { invitationMail, uuids }
        );
        message.success(`${res.data.done} mails d'invitation envoyés`);

        function delay(ms) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }

        async function someFunction() {
          await delay(2000);
          await fetchPlanningAndFmtEvents();
        }
        someFunction();
      }
      unSelectedAllRows();
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est intervenue lors de la tentative d'invitation'."
      );
    }
  };

  const launchCancelSelectedMeetings = async (mailChecked) => {
    try {
      const fullPath = window.location.pathname.split("/");
      const planningId = fullPath[fullPath.length - 1];

      if (
        selected.some(
          (x) => x.booked === "en attente" || x.booked === "inactif"
        )
      ) {
        message.error(
          "Veuillez vérifier les personnes pour lesquelles vous souhaitez annuler les rendez-vous. Certaines n'ont jamais pris rendez-vous, sont inactifs, ou sont indisponibles. Auquel cas aucun rendez-vous n'est à annuler !"
        );
      } else {
        const uuids = selected.map((x) => x.uuid);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            planningId +
            "/cancel-multiple",
          { uuids, mailChecked }
        );
        message.success(`${res.data.done} mails d'annulation envoyés`);

        function delay(ms) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }

        async function someFunction() {
          await delay(2000);
          await fetchPlanningAndFmtEvents();
        }
        someFunction();
      }
      unSelectedAllRows();
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est intervenue lors de la tentative d'annulation des rendez-vous."
      );
    }
  };

  const launchRelanceMail = async (relanceMail, relanceSMS) => {
    try {
      const fullPath = window.location.pathname.split("/");
      const planningId = fullPath[fullPath.length - 1];

      if (
        selected.some((x) => x.booked === "réservé" || x.booked === "inactif")
      ) {
        message.error(
          "Veuillez vérifier les personnes sélectionnées pour les relances. Certaines ont déjà pris rendez-vous ou sont inactifs!"
        );
      } else {
        const uuids = selected.map((x) => x.uuid);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            planningId +
            "/relancer",
          { relanceMail, relanceSMS, uuids }
        );
        message.success(`${res.data.done} mails de relance envoyés`);
      }
      await fetchPlanningAndFmtEvents();
      unSelectedAllRows();
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est intervenue lors de la tentative de relance."
      );
    }
  };

  const launchRappelMail = async (rappelMail, rappelSMS) => {
    try {
      const fullPath = window.location.pathname.split("/");
      const planningId = fullPath[fullPath.length - 1];
      if (
        selected.some(
          (x) => x.booked === "en attente" || x.booked === "inactif"
        )
      ) {
        message.error(
          "Veuillez vérifier les personnes sélectionnées pour les rappels. Certaines n'ont pas encore pris rendez-vous ou sont inactifs!"
        );
      } else {
        const uuids = selected.map((x) => x.uuid);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            planningId +
            "/rappeler",
          { rappelSMS, rappelMail, uuids }
        );
        message.success(`${res.data.done} mails de rappel envoyés`);
      }
      await fetchPlanningAndFmtEvents();
      unSelectedAllRows();
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est intervenue lors de la tentative de rappel."
      );
    }
  };

  const launchVrLinkMail = async () => {
    try {
      const fullPath = window.location.pathname.split("/");
      const planningId = fullPath[fullPath.length - 1];

      if (selected.some((x) => x.vrUuid === "" || !x.vrUuid)) {
        message.error(
          "Veuillez vérifier les personnes sélectionnées pour l'envoi des visites virtuelles. Certaines n'ont pas de lien de visite virtuelle à envoyer!"
        );
      } else {
        const uuids = selected.map((x) => x.uuid);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            planningId +
            "/envoi-vv",
          { uuids }
        );
        message.success(
          `${res.data.done} mails avec les liens de visites virtuelles envoyés`
        );
      }
      await fetchPlanningAndFmtEvents();
      unSelectedAllRows();
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est intervenue lors de la tentative d'envoi des liens des visites virtuelles'."
      );
    }
  };

  const openNotificationInvitationMail = () => {
    var checkInvitationMail = false;
    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Checkbox
          onClick={(e) => {
            checkInvitationMail = e.target.checked;
          }}
        >
          Mail
        </Checkbox>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            launchInvitationMail(checkInvitationMail);
            notification.destroy();
          }}
        >
          Confirmer
        </Button>
      </div>
    );
    notification.open({
      message: "Inviter par mail",
      description: `Cette action aura pour effet d'envoyer directement un email aux ${selected.length} personnes sélectionnées pour les inviter à prendre rendez-vous en ligne. Êtes-vous sûr de vouloir poursuivre cette opération ?`,
      btn,
      key,
      onClose: closeInvitationMail,
    });
  };

  const openNotificationRelanceMail = () => {
    var checkRelanceMail = false;
    var checkRelanceSMS = false;
    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Checkbox
          onClick={(e) => {
            checkRelanceMail = e.target.checked;
          }}
        >
          Mail
        </Checkbox>
        <Checkbox
          onClick={(e) => {
            checkRelanceSMS = e.target.checked;
          }}
        >
          SMS
        </Checkbox>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            launchRelanceMail(checkRelanceMail, checkRelanceSMS);
            notification.destroy();
          }}
        >
          Confirmer
        </Button>
      </div>
    );
    notification.open({
      message: "Relancer par mail",
      description: `Cette action aura pour effet d'envoyer directement un email aux ${selected.length} personnes sélectionnées pour les relancer. Êtes-vous sûr de vouloir poursuivre cette opération ?`,
      btn,
      key,
      onClose: closeRelanceMail,
    });
  };

  const openNotificationRappelMail = () => {
    var checkRappelMail = false;
    var checkRappelSMS = false;
    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Checkbox
          onClick={(e) => {
            checkRappelMail = e.target.checked;
          }}
        >
          Mail
        </Checkbox>
        <Checkbox
          onClick={(e) => {
            checkRappelSMS = e.target.checked;
          }}
        >
          SMS
        </Checkbox>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            launchRappelMail(checkRappelMail, checkRappelSMS);
            notification.destroy();
          }}
        >
          Confirmer
        </Button>
      </div>
    );
    notification.open({
      message: "Rappeler par mail",
      description: `Cette action aura pour effet d'envoyer directement un email aux ${selected.length} personnes sélectionnées pour leur rappeler la date du rendez-vous qu'ils ont pris. Êtes-vous sûr de vouloir poursuivre cette opération ?`,
      btn,
      key,
      onClose: closeRappelMail,
    });
  };

  const openCancelSelectedMeetings = () => {
    let checkCancelSelectedMeetingsMail = false;
    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Checkbox
          onClick={(e) => {
            checkCancelSelectedMeetingsMail = e.target.checked;
          }}
        >
          Mail
        </Checkbox>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            launchCancelSelectedMeetings(checkCancelSelectedMeetingsMail);
            notification.destroy();
          }}
        >
          Confirmer
        </Button>
      </div>
    );
    notification.open({
      message: `Annuler les ${selected.length} rendez-vous sélectionnés`,
      description: `Cette action aura pour effet d'envoyer directement un email aux ${selected.length} personnes sélectionnées pour annuler leurs rendez-vous. Êtes-vous sûr de vouloir poursuivre cette opération ?`,
      btn,
      key,
      onClose: closeCancelSelectedMeetings,
    });
  };

  const DropDownMenuAction = () => {
    let items = [
      {
        label: (
          <a
            onClick={() => {
              openNotificationInvitationMail();
            }}
          >
            Invitation
          </a>
        ),
        key: "1",
        icon: <NotificationOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              openNotificationRelanceMail();
            }}
          >
            Relance
          </a>
        ),
        key: "2",
        icon: <RocketOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              openNotificationRappelMail();
            }}
          >
            Rappel
          </a>
        ),
        key: "3",
        icon: <ShakeOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              setShowCustomMail(true);
            }}
          >
            Mail
          </a>
        ),
        key: "4",
        icon: <FormOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              exportXLS();
            }}
          >
            Export (xls)
          </a>
        ),
        key: "5",
        icon: <ExportOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              launchVrLinkMail();
            }}
          >
            Envoi lien VV
          </a>
        ),
        key: "6",
        icon: <VideoCameraOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              setShowRappelsRelances(true);
            }}
          >
            Notifications
          </a>
        ),
        key: "7",
        icon: <HistoryOutlined />,
      },
      {
        label: (
          <a
            onClick={() => {
              openCancelSelectedMeetings();
            }}
          >
            Annuler Rdv
          </a>
        ),
        key: "8",
        danger: true,
        icon: <CloseOutlined />,
      },
    ];

    return (
      <Dropdown
        menu={{
          items,
        }}
      >
        <Button>
          Action <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  return (
    <Space direction="horizontal">
      <DropDownMenuAction />
      {selected.length > 0 &&
        selected.length <= 1 &&
        selected.length + " personne sélectionnée"}
      {selected.length > 1 && selected.length + " personnes sélectionnées"}
    </Space>
  );
};

export default PlanningOptions;
