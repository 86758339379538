import React, { useState, useEffect } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import { Row, Col, Divider, Spin, Result } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const AgendaCalendar = ({
  viewType,
  plannings,
  evts,
  dispatch,
  showCal,
  setShowCal,
}) => {
  if (!showCal) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else if (evts.eventSources === undefined) {
    return (
      <div>
        <Result
          icon={<EllipsisOutlined />}
          title="Aucun rendez-vous pour l'instant"
          subTitle={
            <div>
              🧘‍♀️ ... Patientez et revenez un peu plus tard ... 🧘‍♂️
              <br />
            </div>
          }
        />
      </div>
    );
  } else {
    return (
      <div>
        {/* {JSON.stringify(evts.eventSources)} */}
        {evts.eventSources.map((evt, idx) => {
          return (
            <div
              key={`md-${idx}`}
              style={{
                pageBreakAfter: "always",
              }}
            >
              {/* {JSON.stringify(evt)} */}
              <div>
                {(evts.eventSources.length > 1 && viewType === "Semaine") ||
                viewType === "Jour" ? (
                  <Divider orientation="right" plain>
                    {`${viewType} ${idx + 1}`}
                  </Divider>
                ) : evts.eventSources.length > 1 && viewType === "Mois" ? (
                  <Divider orientation="right" plain>
                    {`${capitalizeFirstLetter(
                      new Intl.DateTimeFormat("fr-FR", {
                        month: "long",
                      })
                        .format(evts.starts[idx])
                        .toString()
                    )} ${evts.starts[idx].getFullYear()} (${idx + 1})`}
                  </Divider>
                ) : (
                  <div>
                    <br />
                  </div>
                )}

                {/* <Divider orientation="right" plain>
                        {mondays.length > 1 && `Semaine ${idx + 1}`}
                      </Divider> */}
                <Row align="middle">
                  <Col span={24}>
                    <div className="customTopButton">
                      <div className="customCalendarEvent">
                        {viewType !== "Jour" ? (
                          <FullCalendar
                            locale={frLocale}
                            allDaySlot={false}
                            editable={false}
                            headerToolbar={false}
                            plugins={[timeGridPlugin, dayGridPlugin]}
                            initialView={
                              viewType === "Semaine"
                                ? "timeGridWeek"
                                : viewType === "Jour"
                                ? "timeGridDay"
                                : "dayGridMonth"
                            }
                            initialDate={evts.starts[idx]}
                            slotMinTime="07:00:00"
                            slotMaxTime="20:00:00"
                            height="auto"
                            eventSources={evt}
                            nowIndicator={true}
                            weekends={evts.isWeekend}
                            // displayEventTime={false}
                          />
                        ) : (
                          <FullCalendar
                            locale={frLocale}
                            allDaySlot={false}
                            editable={false}
                            headerToolbar={false}
                            plugins={[timeGridPlugin, dayGridPlugin]}
                            initialView={
                              viewType === "Semaine"
                                ? "timeGridWeek"
                                : viewType === "Jour"
                                ? "timeGridDay"
                                : "dayGridMonth"
                            }
                            initialDate={evts.starts[idx]}
                            slotMinTime="07:00:00"
                            slotMaxTime="20:00:00"
                            height="auto"
                            eventSources={evt}
                            nowIndicator={true}
                            dayHeaderContent={(args) => {
                              return capitalizeFirstLetter(
                                moment(args.date)
                                  .format("dddd DD/MM")
                                  .toString()
                              );
                            }}
                            weekends={evts.isWeekend}
                            // displayEventTime={false}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default AgendaCalendar;
