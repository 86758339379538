import React, { useState, useRef } from "react";
import { Table, Space, Button, Input, Typography } from "antd";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const InvoiceEntitiesTable = ({ clients }) => {
  let history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{ width: 110 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput, 100);
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchColumn(dataIndex);
  };

  const columns = [
    {
      title: "Forme",
      dataIndex: "formejuridique",
      key: "formejuridique",
      render: (text, _) => <>{`${text.toUpperCase()}`}</>,
      sorter: (a, b) => a.formejuridique.localeCompare(b.formejuridique),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Raison Sociale",
      dataIndex: "raisonsociale",
      key: "raisonsociale",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.raisonsociale.localeCompare(b.raisonsociale),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("raisonsociale"),
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Promoteur",
      dataIndex: "promoter",
      key: "promoter",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.promoter.localeCompare(b.promoter),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("promoter"),
      ellipsis: true,
      width: "15%",
    },
    {
      title: "No. SIRET",
      dataIndex: "siret",
      key: "siret",
      render: (text, _) => <>{`${text}`}</>,
      ellipsis: true,
      width: "12%",
    },
    {
      title: "Adresse",
      dataIndex: "adresserue",
      key: "adresserue",
      render: (text, _) => (
        <>{`${_.adressenumero} ${_.adresserue} ${_.adressecodepostal} ${_.adresseville}`}</>
      ),
      ellipsis: true,
      width: "31%",
    },
    {
      title: "Utilisé / Crédits",
      dataIndex: "credits",
      key: "credits",
      render: (text, _) => {
        if (_.counter > _.credits) {
          return (
            <center>
              <Text type="warning">
                <ExclamationCircleOutlined />
                &nbsp;{`${_.counter} / ${_.credits}`}
              </Text>
            </center>
          );
        } else {
          return (
            <center>
              <Text type="success">{`${_.counter} / ${_.credits}`}</Text>
            </center>
          );
        }
      },
      ellipsis: true,
      width: "12%",
    },
  ];

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              localStorage.setItem("currentEntity", record.raisonsociale);
              history.push("/admin/facturation/entities/" + record._id);
              // onRowDoubleClick(record);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        columns={columns}
        dataSource={clients}
        rowKey="_id"
        size="small"
        // rowSelection={rowSelection} // JUST REMOVED
      />
    </div>
  );
};

export default InvoiceEntitiesTable;
