const globalTimes = {
  // "15'": 900000,
  "30'": 1800000,
  "45'": 2700000,
  "1h": 3600000,
  "1h30": 5400000,
  "2h": 7200000,
  "2h30": 9000000,
  "3h": 10800000,
  "4h": 14400000,
};

const presetTimes = {
  // 900000: "15'",
  1800000: "30'",
  2700000: "45'",
  3600000: "1h",
  5400000: "1h30",
  7200000: "2h",
  9000000: "2h30",
  10800000: "3h",
  14400000: "4h",
};

const mTimes = {
  custom: {
    // PHASE DE CHOIX
    "1p": 1800000,
    "2p": 1800000,
    "3p": 1800000,
    "4p": 1800000,
    "5p": 1800000,
  },
  choices: {
    // PHASE DE CHOIX
    "1p": 3600000,
    "2p": 3600000,
    "3p": 5400000,
    "4p": 5400000,
    "5p": 5400000,
  },
  partitions: {
    // PHASE CLOISONS
    "1p": 3600000,
    "2p": 3600000,
    "3p": 5400000,
    "4p": 5400000,
    "5p": 7200000,
  },
  predelivery: {
    // PHASE PRE-LIVRAISON
    "1p": 5400000,
    "2p": 5400000,
    "3p": 7200000,
    "4p": 7200000,
    "5p": 9000000,
  },
  delivery: {
    // PHASE LIVRAISON
    "1p": 5400000,
    "2p": 5400000,
    "3p": 7200000,
    "4p": 7200000,
    "5p": 9000000, // Le temps en millisecondes
  },
};

const phaseTransform = {
  choices: "Choix",
  partitions: "Cloisons",
  predelivery: "Pré-livraison",
  delivery: "Livraison",
};

const typoColors = {
  "1p": "green",
  "2p": "geekblue",
  "3p": "orange",
  "4p": "volcano",
  "5p": "red",
};

const originMailRappel = `Bonjour <NOM>,

Ceci est un mail de rappel pour vous dire que vous avez rendez-vous le <DEBUT_VISITE> pour la visite de <TYPE_VISITE> de votre logement.

Pour rappel, l'adresse est la suivante : <ADRESSE>.

Voici un lien vers un plan pour vous y rendre : <LIEN_PLAN>.

Pour toute(s) question(s), vous pouvez joindre <CONTACT_TITRE> <CONTACT_NOM> (<CONTACT_TELEPHONE> - <CONTACT_MAIL>).

Cordialement,

<SIGNATURE>`;

const originMailRelance = `Bonjour <NOM>,

Ceci est un mail de rappel pour que vous preniez rendez-vous en ligne pour la visite de votre logement.

Il s'agit de la visite de <TYPE_VISITE>.

Voici le lien pour la prise de rendez-vous en ligne : <LIEN_RDV>

Nous vous prions de bien vouloir accéder au lien afin de sélectionner vos disponibilités.

Cordialement,

<SIGNATURE>`;

const originSmsRappel = `Bonjour <NOM> (lot: <LOTCOMM>), <PROMOTEUR> vous rappelle que vous avez rendez-vous le <DEBUT_VISITE> pour votre visite de <TYPE_VISITE>. Plan: <LIEN_PLAN>. Contact: <CONTACT_TITRE> <CONTACT_NOM> (<CONTACT_TELEPHONE>)`;
const originSmsRelance = `Bonjour <NOM> (lot: <LOTCOMM>), <PROMOTEUR> vous rappelle de prendre rdv pour votre visite de <TYPE_VISITE> sur: <LIEN_RDV>`;

const originMailInvitation = `Bonjour <NOM>,

Vous êtes invités à prendre rendez-vous en ligne pour une visite de votre logement.

Il s'agit de la visite de <TYPE_VISITE>.

Voici le lien pour la prise de rendez-vous en ligne : <LIEN_RDV>

En cas de question(s), veuillez vous rapprocher de <CONTACT_TITRE> <CONTACT_NOM> (<CONTACT_TELEPHONE> - <CONTACT_MAIL>).

Cordialement,

<SIGNATURE>`;

const originSmsInvitation = `Bonjour <NOM> (lot: <LOTCOMM>), <PROMOTEUR> vous invite à prendre rdv pour votre visite de <TYPE_VISITE> sur: <LIEN_RDV>.`;

const originMailConfirmation = `Bonjour <NOM>,

Ceci est un mail confirmation, vous avez rendez-vous le <DEBUT_VISITE> pour la visite de <TYPE_VISITE> de votre logement.

Pour rappel, l'adresse est la suivante: <ADRESSE>.

Voici un lien vers un plan pour vous y rendre: <LIEN_PLAN>

Si par hasard vous souhaitez changer la date de votre rendez- vous, vous pouvez toujours le faire sur le même lien que vous avez reçu :
<LIEN_RDV>

Cordialement,

<SIGNATURE>`;

const originMailConfirmationImpose = `Bonjour <NOM>,

Nous vous remercions d'avoir confirmé votre rendez-vous du <DEBUT_VISITE> pour la visite de <TYPE_VISITE> de votre logement.

Pour rappel, l'adresse est la suivante: <ADRESSE>.

Voici un lien vers un plan pour vous y rendre: <LIEN_PLAN>

Cordialement,

<SIGNATURE>`;

const originCustomMail = `Bonjour <NOM>,

Ceci est un mail personnalisé.

Cordialement,

<SIGNATURE>`;

const originMailConvocation = `Bonjour <NOM>,

Vous êtes convoqués le <DEBUT_VISITE> pour la visite de <TYPE_VISITE> de votre logement.

Nous vous prions de bien vouloir accéder au lien afin de confirmer votre disponibilité sur le lien suivant : <LIEN_RDV>.

En cas de question(s), veuillez vous rapprocher de <CONTACT_TITRE> <CONTACT_NOM> (<CONTACT_TELEPHONE> - <CONTACT_MAIL>).

Cordialement,

<SIGNATURE>`;

const firstParagraph =
  "Découvrez l'expérience immersible de votre logement en visite virtuelle";

const secondParagraph =
  "Prenez le contrôle ! Nous vous donnons la possibilité de visiter votre logement en réalité virtuelle. Découvrez les espaces, les implantations des appareillages.";

const thirdParagraph =
  "Votre AMO se tient à votre disposition pour répondre à vos questions.";

const bankHolidays = {
  "2028-01-01": "1er janvier",
  "2028-04-17": "Lundi de Pâques",
  "2028-05-01": "1er mai",
  "2028-05-08": "8 mai",
  "2028-05-25": "Ascension",
  "2028-06-05": "Lundi de Pentecôte",
  "2028-07-14": "14 juillet",
  "2028-08-15": "Assomption",
  "2028-11-01": "Toussaint",
  "2028-11-11": "11 novembre",
  "2028-12-25": "Jour de Noël",
  "2027-01-01": "1er janvier",
  "2027-03-29": "Lundi de Pâques",
  "2027-05-01": "1er mai",
  "2027-05-06": "Ascension",
  "2027-05-08": "8 mai",
  "2027-05-17": "Lundi de Pentecôte",
  "2027-07-14": "14 juillet",
  "2027-08-15": "Assomption",
  "2027-11-01": "Toussaint",
  "2027-11-11": "11 novembre",
  "2027-12-25": "Jour de Noël",
  "2026-01-01": "1er janvier",
  "2026-04-06": "Lundi de Pâques",
  "2026-05-01": "1er mai",
  "2026-05-08": "8 mai",
  "2026-05-14": "Ascension",
  "2026-05-25": "Lundi de Pentecôte",
  "2026-07-14": "14 juillet",
  "2026-08-15": "Assomption",
  "2026-11-01": "Toussaint",
  "2026-11-11": "11 novembre",
  "2026-12-25": "Jour de Noël",
  "2025-01-01": "1er janvier",
  "2025-04-21": "Lundi de Pâques",
  "2025-05-01": "1er mai",
  "2025-05-08": "8 mai",
  "2025-05-29": "Ascension",
  "2025-06-09": "Lundi de Pentecôte",
  "2025-07-14": "14 juillet",
  "2025-08-15": "Assomption",
  "2025-11-01": "Toussaint",
  "2025-11-11": "11 novembre",
  "2025-12-25": "Jour de Noël",
  "2024-01-01": "1er janvier",
  "2024-04-01": "Lundi de Pâques",
  "2024-05-01": "1er mai",
  "2024-05-08": "8 mai",
  "2024-05-09": "Ascension",
  "2024-05-20": "Lundi de Pentecôte",
  "2024-07-14": "14 juillet",
  "2024-08-15": "Assomption",
  "2024-11-01": "Toussaint",
  "2024-11-11": "11 novembre",
  "2024-12-25": "Jour de Noël",
};

// const defaultColors = [
//   "#ff7875",
//   "#ff9c6e",
//   "#ffc069",
//   "#ffd666",
//   "#fff566",
//   "#d3f261",
//   "#95de64",
//   "#5cdbd3",
//   "#69b1ff",
//   "#85a5ff",
//   "#b37feb",
//   "#ff85c0",
// ];

const defaultColors = [
  "#6d8294",
  // "#fdf8ea",
  // "#fcf3d2",
  // "#f9eab9",
  // "#f8e4a3",
  "#f5dc8b",
  // "#f4d678",

  // "#fefcd7",
  // "#f3efc4",
  // "#eae2b0",
  // "#e0d39c",
  "#d7c789",
  // "#d7c789",

  // "#fefcd7",
  "#f2f0b9",
  // "#e7e49e",
  // "#f2f0b9",
  // "#f2f0b9",
  // "#f2f0b9",

  // "#f0fde8",
  "#e3f1d9",
  // "#d5e6cb",
  // "#d5e6cb",
  // "#d5e6cb",
  // "#b0c3a2",

  // "#f3e8e4",
  // "#f1dcce",
  // "#efd2b7",
  // "#eec7a1",
  "#eec7a1",
  // "#eec7a1",

  // "#f0c8c5",
  "#ecb9b2",
  // "#e9a99d",
  // "#e79a8b",
  "#e48c77",
  // "#e27e66",

  // "#f4d9d7",
  "#e9c3c1",
  // "#dfb0ad",
  // "#d59b98",
  "#cb8984",
  // "#c2766f",

  // "#f9e8f0",
  // "#e9cbda",
  // "#dab0c5",
  // "#ca95b2",
  "#be7b9f",
  // "#af638b",

  // "#f9eaf0",
  // "#f4d8e5",
  // "#f0c7dd",
  // "#ecb6d3",
  "#e9a5ca",
  // "#e696c1",

  // "#f0e9fc",
  "#e5d7fb",
  // "#d9c5fb",
  // "#cdb5fa",
  "#c4a4f9",
  // "#b995f7",

  // "#f6e5fd",
  // "#ead4f1",
  // "#dcc2e4",
  // "#d2b3da",
  "#c3a2cc",
  // "#b893c2",

  // "#e6fdff",
  "#cff1f1",
  // "#bbe5e5",
  // "#bbe5e5",
  "#a5d8da",
  // "#a5d8da",

  // "#d9e9d5",
  "#c5e0bd",
  // "#b2d8a6",
  // "#a0d092",
  "#8fc77b",
  // "#80c067",

  // "#e3effe",
  "#cde6fd",
  // "#b8ddfc",
  // "#a5d3fb",
  "#91cbfa",
  // "#81c1fa",

  // "#e3ecfd",
  "#c8d7f8",
  // "#aec2f3",
  // "#97b0ee",
  "#7f9ee9",
  // "#688be4",

  // "#eaf2fd",
  "#d9e4f2",
  // "#c9d7e8",
  // "#bbc9e0",
  "#abbcd5",
  // "#9dafcb",

  // "#f2f2f2",
  "#dedede",
  // "#cacaca",
  // "#b5b5b5",
  "#a1a1a1",
  // "#8e8e8e",

  // "#f3e9fd",
  "#d0c6d8",
  // "#ada4b3",
  // "#8b838f",
  "#69646f",
  // "#4a4550",

  // "#dde8f0",
  // "#c0ced8",
  // "#a3b2c0",
  // "#889cab",

  // "#556a7e",

  // "#f3e8e4",
  "#e2d2c8",
  // "#d5bdb0",
  // "#c5aa97",
  "#b7977f",
  // "#aa8369",
];

export {
  globalTimes,
  presetTimes,
  mTimes,
  typoColors,
  phaseTransform,
  originMailRappel,
  originMailRelance,
  originSmsRappel,
  originSmsRelance,
  originMailInvitation,
  originMailConfirmation,
  originMailConfirmationImpose,
  originCustomMail,
  originMailConvocation,
  originSmsInvitation,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  bankHolidays,
  defaultColors,
};
