import React, { useContext } from "react";
import { UserMenuData } from "../../UserMenuData";
import { Row, Dropdown, Typography, Button, Space } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
// import "../../../App.css";

const { Text } = Typography;

const roleTranslate = {
  administrator: "Administrateur",
  anonymousUser: "Anonyme",
  customer: "Acquéreur",
  promoter: "Promoteur",
  amo: "AMO",
  moderator: "Modérateur",
};

const items = UserMenuData.map((item, index) => ({
  label: <Link to={item.path}>{item.label}</Link>,
  key: "user-" + index,
}));

const menuProps = {
  items,
  // onClick: handleMenuClick,
};

const PromoterMenu = () => {
  const { role, username } = useContext(UserContext);
  return (
    <div
      style={{
        margin: "0px 16px 0px 0px",
      }}
    >
      <Row justify="end">
        <Space direction="horizontal">
          <Text>
            <b>{username}</b>
          </Text>
          <Text type="success">&nbsp;({roleTranslate[role]})</Text>
          <Dropdown menu={menuProps} className="dropdown-btn">
            <Button icon={<UserOutlined />}></Button>
          </Dropdown>
        </Space>
      </Row>
    </div>
  );
};

export default PromoterMenu;
