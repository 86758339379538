import React, { useState } from "react";
import { App, Modal, Typography, Space, Form, Input } from "antd";
import axios from "axios";
import { getVisitesApiAuth, getRdvApiAuth } from "../../../utils";

const { Text } = Typography;

const ChangeNameModal = ({
  show,
  setShow,
  planning,
  setPlanning,
  promoterId,
  firstPart,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const updatePlanningName = async () => {
    try {
      const val = form.getFieldsValue();
      const res2 = axios.patch(
        process.env.REACT_APP_VR_API_URL + "/promoters/" + promoterId,
        { internalName: firstPart + val.planningName },
        getVisitesApiAuth()
      );

      const res = axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { name: val.planningName },
        getRdvApiAuth()
      );
      // console.log(`RES -> ${JSON.stringify(res)}`);
      message.success(
        "Mise à jour de la date de fin de prise des rendez-vous effectuée"
      );
      setPlanning({ ...planning, name: val.planningName });
    } catch (e) {
      console.log(e);
      message.error(
        "Erreur lors du changement de date de fin des prises de rendez-vous"
      );
    }
    form.resetFields();
    setShow(false);
  };

  return (
    <Modal
      title="Changement du titre du planning"
      style={{ top: 40 }}
      open={show}
      onOk={updatePlanningName}
      onCancel={() => setShow(false)}
    >
      <Form form={form} initialValues={{ planningName: planning.name }}>
        <br />
        Inscrivez ci-dessous le nouveau titre souhaité :
        <br />
        <br />
        <Form.Item
          name="planningName"
          rules={[
            {
              validator: async (_, payload) => {
                console.log(`payload : ${JSON.stringify(payload)}`);
                if (payload === "") {
                  return Promise.reject(
                    new Error(`Vérifiez le titre du planning SVP.`)
                  );
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeNameModal;
