import React, { useState, useEffect, useRef, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";
import { nanoid } from "nanoid";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "qs";
import {
  App,
  Space,
  Col,
  Row,
  Typography,
  Spin,
  Button,
  Divider,
  Modal,
  Form,
} from "antd";

import {
  getBasicAuthConfig,
  getRdvApiAuth,
  getFormatedClients,
  getVisitesApiAuth,
  getTypologies,
  filterByKeys,
  customTags,
} from "../../../utils";
import {
  mTimes,
  originSmsRappel,
  originSmsRelance,
  originMailRappel,
  originMailRelance,
  originMailConfirmationImpose,
  originMailConvocation,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
} from "../../../components/plannings/planningData";
import calendarEventsReducer, {
  initReducer,
} from "./../../../reducers/calendarevents";
import ContentContainer from "../../../components/ContentContainer";
import { AcquereursImpose } from "../../../components/plannings/AcquereursImpose";
import VisitTimeSelector from "../../../components/plannings/VisitTimeSelector";
// import QuestionOptionsConvocation from "../../../components/plannings/QuestionOptionsConvocation";
import NotificationAmo from "../../../components/plannings/NotificationAmo";
import SelectStartEnd from "../../../components/plannings/SelectStartEnd";
import PlanningImposeCalendar from "../../../components/plannings/PlanningImposeCalendar";
import SelectBasicInfoConvocation from "../../../components/plannings/SelectBasicInfoConvocation";
import PlanningPromoter from "../../../components/plannings/PlanningPromoter";
import PlanningTemplate from "../../../components/plannings/PlanningTemplate";
import Contact from "../../../components/plannings/Contact";
import SubContentContainer from "../../../components/SubContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";

const { Text, Title } = Typography;
dayjs.locale("fr");

const CreatePlanningImpose = () => {
  const { message } = App.useApp();
  let history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const calendarRef = useRef();
  const [clientList, setClientList] = useState([]);
  const [promotername, setPromotername] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [phase, setPhase] = useState("choices");
  const [meetingTimes, setMeetingTimes] = useState(mTimes["choices"]);
  const [events, setEvents] = useState(undefined);
  const [value, setValue] = useState("batiment");
  const initialLunchStartTime = "12:30";
  const initialLunchEndTime = "13:30";
  const [lunchStartTime, setLunchStartTime] = useState(initialLunchStartTime);
  const [lunchEndTime, setLunchEndTime] = useState(initialLunchEndTime);
  const [invoiceEntity, setInvoiceEntity] = useState(undefined);
  const [neededTime, setNeededTime] = useState(0);
  const [notif, setNotif] = useState(false);
  const [wait, setWait] = useState(false);
  const [authorizedAmo, setAuthorizedAmo] = useState([]);
  const [authToken, setAuthToken] = useState(undefined);
  const [colors, setColors] = useState(undefined);
  const [amoList, setAmoList] = useState([]);
  const [notifiedList, setNotifiedList] = useState([]);
  const [selectedAmo, setSelectedAmo] = useState([]);
  const [areAmosAssigned, setAreAmosAssigned] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [typos, setTypos] = useState(filterByKeys(selected, "typologie"));
  const [evts, dispatch] = useReducer(calendarEventsReducer, {}, initReducer);
  const [isCustomDisabled, setIsCustomDisabled] = useState(true);
  const [usedBalises, setUsedBalises] = useState("");
  const [initCalendarEvents, setInitCalendarEvents] = useState([]);
  const [isVerifyModalOpened, setIsVerifiedModalOpen] = useState(false);
  const [filteredClientList, setFilteredClientList] = useState(undefined);
  const [showAll, setShowAll] = useState(false);
  const [messages, setMessages] = useState({
    smsrappelmessage: originSmsRappel,
    smsrelancemessage: originSmsRelance,
    mailrappelmessage: originMailRappel,
    mailrelancemessage: originMailRelance,
    mailconfirmationmessage: originMailConfirmationImpose,
    mailinvitationmessage: originMailConvocation,
  });
  const [isNoMoreRdvToPlace, setIsNoMoreRdvToPlace] = useState(false);
  const programId = location.pathname.split("/")[2];

  // Recuperation d'un eventuel id dans l'URI pour savoir
  // si il s'agit d'un brouillon ou non
  let tmp = qs.parse(location.search, { ignoreQueryPrefix: true }).id;
  let id = undefined;
  if (tmp === undefined || tmp === "") {
    id = undefined;
  } else {
    id = tmp;
  }

  const printListComa = (lst) => {
    let lstLen = lst.length;
    let txt = "";
    lst.forEach((item, idx) => {
      if (idx !== lstLen - 1) {
        txt += `${item},`;
      } else {
        txt += ` ${item}.`;
      }
    });
    return txt;
  };

  const selectedRows = (rows) => {
    setSelectedRowKeys(rows.map((x) => x._id));
    setSelected(rows);
  };

  const onVerifyIsOk = () => {
    setRdv();
    setIsVerifiedModalOpen(false);
  };

  const onVerifyIsNop = () => {
    setIsVerifiedModalOpen(false);
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchProgramClientList();

      let bigStr =
        originMailRelance +
        originMailRappel +
        originSmsRelance +
        originSmsRappel +
        originMailConvocation +
        originMailConfirmationImpose;
      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));

      // Please not that usedBalises are only the one that matters for Contact, not all !
      setUsedBalises(balises);
      setIsLoading(false);
    };

    const newFuncBrouillon = async (id) => {
      await fetchBrouillon(id);
      setIsLoading(false);
    };

    if (id === undefined) {
      newFunc();
    } else {
      newFuncBrouillon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      dispatch({
        type: "MODIFY_LENGTH_MULTIPLE",
        mTimes: meetingTimes,
        calendarRef,
      });
      let clients = [];
      clientList.forEach((_) => {
        clients.push({
          ..._,
          duree: meetingTimes[_.typologie],
        });
      });
      setClientList(clients);
      setFilteredClientList(clients);

      let newSelected = [];
      if (selected.length !== 0) {
        selected.forEach((row) => {
          let newRow = {
            ...row,
            duree: meetingTimes[row.typologie],
          };
          newSelected.push(newRow);
        });
        setSelected(newSelected);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingTimes]);

  useEffect(() => {
    setNeededTime(
      selected
        .map((x) => x.duree / 1000)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) *
        1000 *
        2.77778e-7
    );
    dispatch({
      type: "SELECT_FILTER_KEY",
      value: "batiment", //value === undefined ? "batiment" : value,
    });
    if (evts.notPlaced.length > 0) setIsNoMoreRdvToPlace(false);
  }, [selected]);

  const onChangeRadio = (e) => {
    dispatch({
      type: "SELECT_FILTER_KEY",
      value: e.target.value,
    });
  };

  const fetchProgramClientList = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + programId,
        getBasicAuthConfig()
      );
      setInvoiceEntity(res.data.invoiceEntity);

      let clients = [];
      getFormatedClients(res.data.clients, programId).forEach((_) => {
        clients.push({
          ..._,
          duree: meetingTimes[_.typologie],
        });
      });
      setClientList(clients);
      setFilteredClientList(clients);
      setAuthorizedAmo(res.data.authorized);
      setPromotername(res.data.promotername);
      setAuthToken(res.data.authToken);
      setColors(res.data.colors);
      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      setAmoList(res2.data);
      dispatch({
        type: "SELECT_FILTER_KEY",
        value: "batiment",
      });

      form.setFieldsValue({
        adresse: {
          numero: res.data.adressenumero,
          rue: res.data.adresserue,
          codepostal: res.data.adressecodepostal,
          ville: res.data.adresseville,
          pays: res.data.adressepays,
        },
        promoterUrl: res.data.promoterUrl,
        mailinvitationmessage: originMailConvocation,
        mailconfirmationmessage: originMailConfirmationImpose,
        smsrelancemessage: originSmsRelance,
        smsrappelmessage: originSmsRappel,
        mailrelancemessage: originMailRelance,
        mailrappelmessage: originMailRappel,
        firstParagraph,
        secondParagraph,
        thirdParagraph,
        bookingDeadline: dayjs(
          moment().add(14, "d").minute(0).hours(20).seconds(0)
        ),
      });
    } catch (e) {
      message.error(`Le projet demandé n'existe pas.`);
      console.log(e);
    }
  };

  const fetchBrouillon = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_RDV_API_URL + "/rdv-draft/" + id,
        getRdvApiAuth()
      );

      const planningDraft = response.data.planningDraft;
      form.setFieldsValue({
        mailinvitationmessage: planningDraft.mailinvitationmessage,
        mailconfirmationmessage: planningDraft.mailconfirmationmessage,
        smsrelancemessage: planningDraft.smsrelancemessage,
        smsrappelmessage: planningDraft.smsrappelmessage,
        mailrelancemessage: planningDraft.mailrelancemessage,
        mailrappelmessage: planningDraft.mailrappelmessage,
        rdvName: planningDraft.name,
        adresse: {
          numero: planningDraft.address.numero,
          rue: planningDraft.address.rue,
          codepostal: planningDraft.address.codepostal,
          ville: planningDraft.address.ville,
          pays: planningDraft.address.pays,
        },
        promoterUrl: planningDraft.promoterUrl,
        bookingDeadline: dayjs(planningDraft.bookingDeadline),
        contactNom: planningDraft.contactNom,
        contactPrenom: planningDraft.contactPrenom,
        contactTitre: planningDraft.contactTitre,
        contactMail: planningDraft.contactMail,
        contactTelephone: planningDraft.contactTelephone,
        signature: planningDraft.signature,
        firstParagraph: planningDraft.firstParagraph,
        secondParagraph: planningDraft.secondParagraph,
        thirdParagraph: planningDraft.thirdParagraph,
        colors: planningDraft.projectColors,
      });

      let bigStr =
        planningDraft.mailinvitationmessage +
        planningDraft.mailconfirmationmessage +
        planningDraft.smsrelancemessage +
        planningDraft.smsrappelmessage +
        planningDraft.mailinvitationmessage +
        planningDraft.mailconfirmationmessage;
      // console.log("bigStr");
      // console.log(bigStr);
      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));
      // Please not that usedBalises are only the one that matters for Contact, not all !
      setUsedBalises(balises);

      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + programId,
        getBasicAuthConfig()
      );

      setInvoiceEntity(res.data.invoiceEntity);

      // Temps pre-definis a partir du draft
      setMeetingTimes(planningDraft.mTimes);

      // Ouverture du switch Visites Virtuelles si avait ete modifie
      setIsCustomDisabled(!planningDraft.isVrPromoterChecked);

      // Set de la phase
      setPhase(planningDraft.phase);

      // Set des events
      // Pas beoins de les set, uniquement calendarEvents

      // SelectedAmos
      setSelectedAmo(planningDraft.amoList);

      //set projectColors
      setColors(planningDraft.projectColors);

      setInitCalendarEvents(JSON.parse(planningDraft.calendarEvents));

      // Fichier client normal et duree a partir de clientListLengths
      let clientListLengths = JSON.parse(planningDraft.clientListLengths);
      let clients = [];
      getFormatedClients(res.data.clients, programId).forEach((_) => {
        clients.push({
          ..._,
          duree:
            _._id in clientListLengths
              ? clientListLengths[_._id]
              : mTimes[planningDraft.phase][_.typologie],
        });
      });
      setClientList(clients);
      setFilteredClientList(clients);

      // Set des acquereurs selectionnes
      let sr = clients.filter((clt) =>
        planningDraft.selectedRowKeys.includes(clt._id)
      );

      let toAdd = JSON.parse(planningDraft.calendarEvents).map((_) => _.id);
      let s = sr.map((_) => _._id);
      let p = clients.filter((_) => toAdd.includes(_._id));
      let np = clients.filter(
        (_) => s.includes(_._id) && !toAdd.includes(_._id)
      );

      dispatch({
        type: "INIT_DRAFT",
        placed: p,
        notPlaced: np,
      });
      selectedRows(sr);
      setTypos(getTypologies(sr));
      setSelected(sr);
      // setAuthorizedAmo(res.data.authorized);
      setPromotername(res.data.promotername);
      setAuthToken(res.data.authToken);

      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      setAmoList(res2.data);

      setNotifiedList(
        res2.data
          .filter((x) => res.data.authorized.includes(x._id))
          .map((x) => {
            return {
              firstname: x.firstname,
              lastname: x.lastname,
              email: x.email,
            };
          })
      );
    } catch (e) {
      message.error(`Impossible de charger le brouillon.`);
      console.log(e);
    }
  };

  const setRdv = async () => {
    try {
      let isCreatePromoterError = false;
      let isFormError = false;
      let newPromoterId = undefined;
      if (
        areAmosAssigned &&
        Math.max(...events.map((_) => parseInt(_.nbAmo))) !== selectedAmo.length
      ) {
        message.warning(
          "Veuillez vérifier les informations sur les effectifs."
        );
        return;
      }

      let val = undefined;

      try {
        val = await form.validateFields();
      } catch (e) {
        message.error(
          `Veuillez revoir le formulaire. Des informations sont manquantes.`
        );
        isFormError = true;
      }

      let calendarApi = calendarRef.current.getApi();
      let calendarEvents = calendarApi.getEvents();
      // Retirer les jours feries
      calendarEvents = calendarEvents.filter((_) => _.display !== "background");

      if (!isFormError) {
        try {
          // Create promoter
          let config = getVisitesApiAuth();
          const res = await axios.post(
            process.env.REACT_APP_VR_API_URL + "/promoter/create",
            {
              name: `${promotername}`,
              internalName: `${promotername} - ${localStorage.getItem(
                "currentProgram"
              )} - ${phase} - ${val.rdvName}`,
              firstParagraph: val.firstParagraph,
              secondParagraph: val.secondParagraph,
              thirdParagraph: val.thirdParagraph,
            },
            config
          );
          if (res.data) {
            newPromoterId = res.data._id;
            if (selectedFile !== null && selectedFile !== undefined) {
              const formData = new FormData();
              formData.append("logo", selectedFile, selectedFile.name);
              await axios.post(
                process.env.REACT_APP_VR_API_URL +
                  "/promoters/" +
                  res.data._id +
                  "/logo",
                formData,
                config
              );
            }
          }
        } catch (e) {
          isCreatePromoterError = true;
          // Remove promoter because there has been an error when uploading logo
          // Or could be an error of duplicate promoterName

          if (e.response && e.response.data.code === 11000) {
            message.error(
              `Echec lors de la création du nouveau planning. Le nom existe déjà, veuillez en sélectionner un autre.`
            );
          } else {
            message.error(
              `Echec lors de la création du nouveau planning. (${e})`
            );
            await axios.delete(
              process.env.REACT_APP_VR_API_URL + "/promoter/" + newPromoterId,
              getVisitesApiAuth()
            );
          }
        }

        if (!isCreatePromoterError) {
          try {
            const formatedSelected = [];

            selected.forEach((x) => {
              let mel = undefined;
              if (x.mailmonsieur) {
                mel = x.mailmonsieur;
              } else if (x.mailmadame) {
                mel = x.mailmadame;
              } else {
                mel = x.email;
              }
              let obj = {
                uuid: nanoid(9),
                email: mel,
                nom: x.firstname + " " + x.lastname,
                telephonemonsieur: x.telephonemonsieur,
                telephonemadame: x.telephonemadame,
                mailmonsieur: x.mailmonsieur,
                mailmadame: x.mailmadame,
                lotcomm: x.lotcomm,
                etage: x.etage,
                batiment: x.batiment,
                typologie: x.typologie,
                notifMail: true, //notif.includes("Mail") ? true : false,
                notifSMS: true, //notif.includes("SMS") ? true : false,
                invitationMail: [],
                relancesMail: [],
                relancesSMS: [],
                rappelsMail: [],
                rappelsSMS: [],
                rdvLength: x.duree,
                isActive: true,
                isBooked: true,
                observations: "",
              };
              formatedSelected.push(obj);
            });

            let finalObj = {
              name: val.rdvName,
              // planningType: "Impose",
              uuid: nanoid(9),
              promoter: promotername,
              promoterUrl: val.promoterUrl,
              phase,
              launchDate: val.launchDate,
              authorizeTrous: true,
              bookingDeadline: val.bookingDeadline,
              lunchStartTime: lunchStartTime,
              lunchEndTime: lunchEndTime,
              events: calendarEvents,
              mTimes: meetingTimes,
              mailrappelmessage: messages.mailrappelmessage,
              smsrappelmessage: messages.smsrappelmessage,
              smsrelancemessage: messages.smsrelancemessage,
              mailrelancemessage: messages.mailrelancemessage,
              mailconfirmationmessage: messages.mailconfirmationmessage,
              mailinvitationmessage: messages.mailinvitationmessage,
              contactTitre: val.contactTitre,
              contactNom: val.contactNom,
              contactPrenom: val.contactPrenom,
              contactMail: val.contactMail,
              contactTelephone: val.contactTelephone,
              signature: val.signature,
              templateType: "promoterImpose",
              questions: [], //optionQuestions,
              notifications: {
                Mail: true, //notif.includes("Mail") ? true : false,
                SMS: true, //notif.includes("SMS") ? true : false,
              },
              areAmosAssigned,
              notifiedList: notifiedList,
              amoList: selectedAmo,
              broadcastList: formatedSelected,
              authToken,
              address: val.adresse,
              projectId: programId,
              projectName: localStorage.getItem("currentProgram"),
              invoiceEntity,
              projectColors: colors,
            };

            // console.log(`finalObj`);
            // console.log(finalObj);
            // return;

            // Creation du planning
            await axios.post(
              process.env.REACT_APP_RDV_API_URL + "/rdv-impose/",
              finalObj
            );

            if (id !== undefined) {
              // Destruction du brouillon
              console.log(`Destruction du brouillon`);
              await axios.delete(
                `${process.env.REACT_APP_RDV_API_URL}/rdv-draft/${id}`
              );
            }

            history.push("/projets/" + programId + "/plannings");

            message.success("🚀 Nouveau planning imposé créé avec succès");
          } catch (e) {
            if (e.response && e.response.data.error) {
              if (e.response.data.message.startsWith("E11000")) {
                message.error(
                  `Echec lors de la création du nouveau planning. Le nom existe déjà, veuillez en sélectionner un autre.`
                );
              } else {
                message.error(
                  `Echec lors de la création du nouveau planning. (${e.response.data.message})`
                );
              }
            } else {
              message.error(
                `Echec lors de la création du nouveau planning. (${e})`
              );
            }
            if (
              Object.keys(e.response.data.errors).includes("address.numero")
            ) {
              message.error(
                `Veuillez renseigner un chiffre ou un nombre pour le numero de rue, "${e.response.data.errors["address.numero"].value}" n'est pas valide.`
              );
            } else {
              message.error(`Echec lors de la création du nouveau planning.`);
            }
            setWait(false);
          }
        }
      }
    } catch (e) {
      console.log(e);
      // console.log("END");
      message.error("Veuillez vérifier le formulaire");
    }
  };

  const setBrouillon = async () => {
    let val = await form.getFieldsValue();
    let calendarApi = calendarRef.current.getApi();
    let calendarEvents = calendarApi.getEvents();

    // Retirer les jours feries
    calendarEvents = calendarEvents.filter((_) => _.display !== "background");
    console.log(calendarEvents);

    let clientListLengths = {};
    clientList.forEach((client) => {
      clientListLengths[client._id] = client.duree;
    });
    clientListLengths = JSON.stringify(clientListLengths);

    try {
      let finalObj = {
        name: val.rdvName,
        promoter: promotername,
        promoterUrl: val.promoterUrl,
        phase,
        selectedRowKeys,
        launchDate: val.launchDate,
        bookingDeadline: val.bookingDeadline,
        calendarEvents: JSON.stringify(
          calendarEvents.filter((_) => _.display !== "background")
        ),
        mTimes: meetingTimes,
        mailrappelmessage: messages.mailrappelmessage,
        smsrappelmessage: messages.smsrappelmessage,
        smsrelancemessage: messages.smsrelancemessage,
        mailrelancemessage: messages.mailrelancemessage,
        mailconfirmationmessage: messages.mailconfirmationmessage,
        mailinvitationmessage: messages.mailinvitationmessage,
        contactTitre: val.contactTitre,
        contactNom: val.contactNom,
        contactPrenom: val.contactPrenom,
        contactMail: val.contactMail,
        contactTelephone: val.contactTelephone,
        firstParagraph: val.firstParagraph,
        secondParagraph: val.secondParagraph,
        thirdParagraph: val.thirdParagraph,
        isVrPromoterChecked: !isCustomDisabled,
        signature: val.signature,
        templateType: "promoterImpose",
        amoList: selectedAmo,
        address: val.adresse,
        projectId: programId,
        projectName: localStorage.getItem("currentProgram"),
        projectColors: colors,
        clientListLengths,
      };

      // console.log(`finalObj : \n`);
      // console.log(finalObj);
      // TODO : Possibilite de spread 'val' ci-dessous

      if (id !== undefined) {
        // Si id existe deja -> PATCH avec l'id existant
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv-draft/" + id,
          finalObj
        );
      } else {
        // Si id existe deja -> POST
        await axios.post(
          process.env.REACT_APP_RDV_API_URL + "/rdv-draft/",
          finalObj
        );
      }

      history.push("/projets/" + programId + "/plannings");
      message.success("🧩 Nouveau brouillon sauvegardé");
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        if (e.response.data.message.startsWith("E11000")) {
          message.error(
            `Echec lors de la création du brouillon. Le nom existe déjà, veuillez en sélectionner un autre.`
          );
        } else {
          message.error(
            `Echec lors de la création du brouillon. (${e.response.data.message})`
          );
        }
      } else {
        message.error(`Echec lors de la création du brouillon. (${e})`);
      }
      if (Object.keys(e.response.data.errors).includes("address.numero")) {
        message.error(
          `Veuillez renseigner un chiffre ou un nombre pour le numero de rue, "${e.response.data.errors["address.numero"].value}" n'est pas valide.`
        );
      } else {
        message.error(`Echec lors de la création du brouillon.`);
      }
      setWait(false);
    }
  };

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Création d'un nouveau planning imposé"
          arrow={true}
        ></CustomPageHeader>
        {!isLoading ? (
          <Row>
            <Col span={1}></Col>
            <Col span={23}>
              <VisitTimeSelector
                form={form}
                meetingTimes={meetingTimes}
                setMeetingTimes={setMeetingTimes}
                orgMeetingTimes={mTimes}
                phase={phase}
                setPhase={setPhase}
                planningType="promoterImpose"
                calendarRef={calendarRef}
                dispatch={dispatch}
              />
              <br />
              <Divider dashed />
              <Title level={4}>Acquéreurs</Title>
              <Text>
                Le temps des visites par acquéreur a été pré-rempli dans le
                tableau ci-dessous en fonction de la typologie de l'appartement.
                <br />
                Vous pouvez si besoin modifier la durée de la visite pour chacun
                des acquéreurs ci-dessous.
                <br />
                Enfin, sélectionnez les acquéreurs avec qui vous souhaitez
                prendre rendez-vous.
              </Text>
              <br />
              <br />
              <AcquereursImpose
                className="half-screen"
                clientList={clientList}
                setClientList={setClientList}
                selected={selected}
                setSelected={setSelected}
                typos={typos}
                setTypos={setTypos}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                evts={evts}
                dispatch={dispatch}
                calendarRef={calendarRef}
                filteredClientList={filteredClientList}
                setFilteredClientList={setFilteredClientList}
                showAll={showAll}
                setShowAll={setShowAll}
              />
              <br />
              <Divider dashed />
              <Title level={4}>Calculatrice (facultatif)</Title>
              <Text>Calculez vos besoins en heures.</Text>
              <br />
              <br />
              <SelectStartEnd
                events={events}
                setEvents={setEvents}
                selected={selected}
                lunchStartTime={lunchStartTime}
                lunchEndTime={lunchEndTime}
                setLunchStartTime={setLunchStartTime}
                setLunchEndTime={setLunchEndTime}
                initialLunchStartTime={initialLunchStartTime}
                initialLunchEndTime={initialLunchEndTime}
                neededTime={neededTime}
                setNeededTime={setNeededTime}
              />
              <br />
              <Divider dashed />
              <Title level={4}>Placement des rendez-vous dans l'agenda</Title>
              <Text>
                Veuillez filtrer les rendez-vous que vous souhaitez placer à
                gauche (étage, typologie...) et les glisser dans l'agenda.
                <br />
                Double-cliquez sur un rendez-vous pour annuler son ajout dans le
                calendrier.
              </Text>
              <br />
              {/* {selected.length !== 0 && ( */}
              <PlanningImposeCalendar
                calendarRef={calendarRef}
                selected={selected}
                value={value}
                onChangeRadio={onChangeRadio}
                initCalendarEvents={initCalendarEvents}
                evts={evts}
                dispatch={dispatch}
                isNoMoreRdvToPlace={isNoMoreRdvToPlace}
                setIsNoMoreRdvToPlace={setIsNoMoreRdvToPlace}
                showAll={showAll}
              />
              {/* )} */}
              <br />
              {/* <Divider dashed />
            <Title level={4}>Personnaliser la page de convocation</Title>
            <Text>Séléctionnez ci-dessous les options de convocation :</Text>
            <br />
            <br />
            <QuestionOptionsConvocation
              setOptionQuestions={setOptionQuestions}
            />
            <br />
            <br /> */}
              <Divider dashed />
              <Title level={4}>Effectifs</Title>
              <Text>
                Séléctionnez le(s) responsable(s) qui effectueront les visites.
                <br />
                <Text strong>Les premières personnes sont prioritaires.</Text>
                <br />
                <br />
                <Text italic={true}>
                  Note : Les coordonnées du premier AMO de la liste seront mises
                  par défaut dans les mails/sms de rappels/relances.
                </Text>
              </Text>
              <br />
              <br />
              {!isLoading && (
                <NotificationAmo
                  amoList={amoList}
                  selectedAmo={selectedAmo}
                  setSelectedAmo={setSelectedAmo}
                  areAmosAssigned={areAmosAssigned}
                  form={form}
                  setNotifiedList={setNotifiedList}
                />
              )}
              <br />
              <Divider dashed />
              <Title level={4}>
                Personnalisation des Rappel/Relances Mail et SMS
              </Title>
              <Text>
                Cliquez sur le canal de communication pour lequel vous souhaitez
                changer le message
              </Text>
              <br />
              <br />
              <PlanningTemplate
                form={form}
                setUsedBalises={setUsedBalises}
                planning={{}}
                messages={messages}
                setMessages={setMessages}
                request={false}
              />
              <br />
              <Divider dashed />
              <Title level={4}>Personne contact</Title>
              <Text>Une étoile &nbsp;</Text>
              <Text type="danger">*</Text>
              <Text>
                &nbsp;apparait lorsque vous avez utilisé la balise
                correspondante dans la Personnalisation des Rappel/Relances Mail
                et SMS ci-dessus.
              </Text>
              <br />
              <br />
              {!isLoading && <Contact form={form} usedBalises={usedBalises} />}
              <Divider dashed />
              <Title level={4}>Visites virtuelles</Title>
              {!isLoading && (
                <PlanningPromoter
                  form={form}
                  setSelectedFile={setSelectedFile}
                  isCustomDisabled={isCustomDisabled}
                  setIsCustomDisabled={setIsCustomDisabled}
                />
              )}
              <Divider dashed />
              <Title level={4}>Nom, adresse du point de rendez-vous</Title>
              <Text>
                Sélectionnez un nom ainsi que la date d'envoi souhaitée des
                rendez-vous.
                <br />
                Spécifiez combien de temps avant le début des visites il est
                encore possible de prendre rendez-vous.
              </Text>
              <br />
              <br />
              {!isLoading && (
                <SelectBasicInfoConvocation
                  form={form}
                  notif={notif}
                  setNotif={setNotif}
                />
              )}
              <br />
              <br />
              <br />
              <br />
              <Space direction="horizontal">
                <Button
                  onClick={() => {
                    if (evts.notPlaced.length === 0) {
                      setRdv();
                    } else {
                      setIsVerifiedModalOpen(true);
                    }
                  }}
                >
                  Créer le Planning
                </Button>
                <Button onClick={setBrouillon}>Enregistrer en brouillon</Button>
              </Space>
              <br />
              <br />
              <br />
              <Modal
                title={
                  <div>
                    <ExclamationCircleOutlined />
                    &nbsp; Attention
                  </div>
                }
                // icon={<ExclamationCircleOutlined />}
                open={isVerifyModalOpened}
                onOk={onVerifyIsOk}
                onCancel={onVerifyIsNop}
                okText="Créer le Planning quand même"
                cancelText="Annuler"
              >
                {evts.notPlaced.length === 1
                  ? `Le lot suivant reste à placer :`
                  : `Les lots suivants restent à placer :`}
                {printListComa(evts.notPlaced.map((_) => _.lotcomm))}
                <br />
                Souhaitez-vous malgré tout continuer ?
              </Modal>
            </Col>
            {/* <Col span={4}></Col> */}
          </Row>
        ) : (
          <div className="loader-spin">
            <Spin />
          </div>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default CreatePlanningImpose;
