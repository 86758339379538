import React, { useState, useEffect } from "react";
import { App, Modal, Checkbox, Button, Select, Form } from "antd";
import axios from "axios";
import {
  getBasicAuthConfig,
  customTags,
  getRdvApiAuth,
  errorCatcher,
  getMissingBalises,
} from "../../../utils";
const { Option } = Select;

const ApplyTemplateModal = ({
  planning,
  show,
  setShow,
  form,
  setUsedBalises,
}) => {
  const { message } = App.useApp();
  const [opts, setOpts] = useState(undefined);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(undefined);

  const onChange = (value) => {
    setSelected(value);
  };

  const applyTemplate = async () => {
    try {
      // Get selecterd template
      let selectedTemplate = templates.find((_) => _.name === selected);

      // Need to verify balises used in template are the one availables
      let bigStr =
        selectedTemplate.mailRelance +
        selectedTemplate.mailRappel +
        selectedTemplate.smsRelance +
        selectedTemplate.smsRappel +
        selectedTemplate.mailInvitation +
        selectedTemplate.mailConfirmation;

      let missings = getMissingBalises(planning, bigStr);
      if (missings.length > 0) {
        message.error(
          `Les balises ${missings} sont utilisées dans le modèle que vous avez selectionné. Veuillez changer de modèle ou alors modifier le Contact en renseignant les balises manquantes.`
        );
        return;
      }

      // console.log(`selectedTemplate is : ${JSON.stringify(selectedTemplate)}`);
      let updates = {
        mailinvitationmessage: selectedTemplate.mailInvitation,
        mailconfirmationmessage: selectedTemplate.mailConfirmation,
        smsrelancemessage: selectedTemplate.smsRelance,
        smsrappelmessage: selectedTemplate.smsRappel,
        mailrappelmessage: selectedTemplate.mailRappel,
        mailrelancemessage: selectedTemplate.mailRelance,
      };

      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        updates,
        getRdvApiAuth()
      );

      form.setFieldsValue({
        mailinvitationmessage: selectedTemplate.mailInvitation,
        mailconfirmationmessage: selectedTemplate.mailConfirmation,
        smsrelancemessage: selectedTemplate.smsRelance,
        smsrappelmessage: selectedTemplate.smsRappel,
        mailrelancemessage: selectedTemplate.mailRappel,
        mailrappelmessage: selectedTemplate.mailRelance,
      });

      let newBalises = bigStr.match(/<(.+?)>/g) || [];
      newBalises = newBalises.filter((_) =>
        Object.keys(customTags).includes(_)
      );
      newBalises = Array.from(new Set(newBalises));
      setUsedBalises(newBalises);

      message.success(`Template ${selected} appliqué au planning`);
    } catch (e) {
      errorCatcher(
        e,
        `Erreur lors de l'application du template ${selected} au planning`
      );
    }
    form.resetFields(["selectedTemplate"]);
  };

  useEffect(() => {
    const newFunc = async () => {
      try {
        // Get authorized templates
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/users/me/templates",
          getBasicAuthConfig()
        );
        setTemplates(res.data.templates);
        let tmpOpts = res.data.templates.map((template) => ({
          key: template._id,
          value: template.name,
          label: template.name,
        }));
        setOpts(tmpOpts);
      } catch (e) {
        errorCatcher(e, `Erreur lors du chargement des modèles`);
      }
      setIsLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Appliquer un template au planning"
      style={{ top: 40 }}
      open={show}
      onCancel={() => {
        setShow(false);
        form.resetFields(["selectedTemplate"]);
      }}
      // width={1000}
      footer={[
        <Button
          key="annuler"
          onClick={() => {
            setShow(false);
            form.resetFields(["selectedTemplate"]);
          }}
        >
          Annuler
        </Button>,
        <Button
          key="verifier"
          type="primary"
          onClick={() => {
            applyTemplate();
            setShow(false);
            form.resetFields(["selectedTemplate"]);
          }}
        >
          Appliquer
        </Button>,
      ]}
    >
      {!isLoading && opts !== undefined && (
        <div>
          <p>
            Sélectionnez dans la liste ci-dessous le modèle que vous souhaitez
            appliquer au planning
          </p>
          <br />
          <br />
          <Form form={form}>
            <Form.Item
              name="selectedTemplate"
              label="modèle"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner un modèle !",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Sélectionnez un modèle"
                optionFilterProp="label"
                onChange={onChange}
                options={opts}
              ></Select>
            </Form.Item>
          </Form>
          <br />
        </div>
      )}
    </Modal>
  );
};
export default ApplyTemplateModal;
