import React, { useState } from "react";
import CalendarEventSet from "./CalendarEventSet";
import CalendarEvents from "./CalendarEvents";
import { Row, Col } from "antd";

const PlanningImposeCalendar = ({
  calendarRef,
  selected,
  value,
  onChangeRadio,
  initCalendarEvents,
  evts,
  dispatch,
  isNoMoreRdvToPlace,
  setIsNoMoreRdvToPlace,
  showAll,
}) => {
  const onChange = (checkedValues) => {
    dispatch({
      type: "SELECT_FILTER_VALUES",
      checkList: checkedValues,
    });
  };

  return (
    <div>
      <div className="externalEvent">
        <br />
        <br />
        <Row>
          <Col lg={7} sm={7} md={7}>
            <CalendarEventSet
              value={value}
              onChangeRadio={onChangeRadio}
              selected={selected}
              show={!isNoMoreRdvToPlace}
              onChange={onChange}
              evts={evts}
              dispatch={dispatch}
            />
          </Col>
          <Col lg={16} sm={16} md={16}>
            <CalendarEvents
              setIsNoMoreRdvToPlace={setIsNoMoreRdvToPlace}
              calendarRef={calendarRef}
              initCalendarEvents={initCalendarEvents}
              evts={evts}
              dispatch={dispatch}
              showAll={showAll}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PlanningImposeCalendar;
