/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContentContainer from "../../../components/ContentContainer";
import SubContentContainer from "../../../components/SubContentContainer";
import { phaseTransform } from "../../../components/plannings/planningData";
import {
  getBasicAuthConfig,
  getVisitesApiAuth,
  errorCatcher,
} from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import {
  Dropdown,
  Button,
  List,
  Avatar,
  Popconfirm,
  Progress,
  App,
  Tag,
  Checkbox,
  Empty,
  Typography,
  Card,
  Radio,
  Space,
} from "antd";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  DownOutlined,
  AlignLeftOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import CustomPageHeader from "../../../components/CustomPageHeader";

moment.locale("fr");

const Plannings = () => {
  const { message, modal } = App.useApp();
  const { Text, Link: AntdLink } = Typography;
  let history = useHistory();
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState(undefined);
  const [checked, setChecked] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [data, setData] = useState(undefined);
  const [planningTypes, setPlanningTypes] = useState([]);
  const [displayInColumn, setDisplayInColumn] = useState(true);
  const programId = location.pathname.split("/")[2];

  const showConfirm = () => {
    modal.confirm({
      title: "Adresse du programme non renseignée",
      icon: <ExclamationCircleOutlined />,
      okText: "Oui",
      cancelText: "Non",
      content: (
        <p>
          Souhaitez-vous aller à la page des paramètres et renseigner tout de
          suite cette adresse ?
        </p>
      ),
      onOk() {
        history.push(`/projets/${programId}/parametres`);
      },
      onCancel() {},
    });
  };

  const isInvit = (x) => x.isInvitationSent === true;

  const removePlanning = async (item) => {
    try {
      if (
        item.templateType === "draft" ||
        item.templateType === "draftImpose"
      ) {
        // Delete Planning
        await axios.delete(
          process.env.REACT_APP_RDV_API_URL + "/rdv-draft/" + item._id
        );
        let newData = data.filter((_) => _._id !== item._id);
        setData(newData);
        // let newChecked = checked - 1;
        // console.log(`old : ${checked}, new : ${newChecked}`);
        setChecked(checked.filter((_) => _ !== item._id));
        // setHasChanged(true);
        message.success(`Brouillon ${item.name} supprimé avec succès`);
      } else {
        const promoterName = `${item.promoter} - ${item.projectName} - ${item.phase} - ${item.name}`;
        // console.log(`Promoter name to delete : ${promoterName}`);

        // Delete Planning
        await axios.delete(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + item._id
        );

        // Delete promoter linked to Planning
        await axios.post(
          process.env.REACT_APP_VR_API_URL + "/promoter-dbn",
          { internalName: promoterName },
          getVisitesApiAuth()
        );
        let newData = data.filter((_) => _._id !== item._id);
        setData(newData);
        // setHasChanged(true);
        message.success(`Planning ${item.name} supprimé avec succès`);
      }
    } catch (e) {
      // console.log(`E -> ${e}`);
      if (e.response && e.response.data.error) {
        message.error(e.response.data.message);
      } else {
        console.log("Erreur lors de la suppression du planning : " + e);
      }
    }
  };

  const renderInnerTags = (item) => {
    if (Date.now() > new Date(item.planningEndTime).getTime()) {
      return (
        <Tag icon={<CheckCircleOutlined />} color="default">
          Planning terminé
        </Tag>
      );
    } else if (
      Date.now() <= new Date(item.planningEndTime).getTime() &&
      Date.now() > new Date(item.planningStartTime).getTime()
    ) {
      return (
        <Tag icon={<TeamOutlined />} color="default">
          Visites en cours
        </Tag>
      );
    } else if (
      Date.now() >= new Date(item.bookingDeadline).getTime() &&
      item.templateType !== "draftImpose" &&
      item.templateType !== "draft"
    ) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          Période de réservation terminée
        </Tag>
      );
    } else {
      if (
        item.templateType === "draft" ||
        item.templateType === "draftImpose"
      ) {
        return (
          <Tag icon={<ClockCircleOutlined />} color="orange">
            Brouillon
          </Tag>
        );
      } else {
        if (item.broadcastList.some(isInvit)) {
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              En cours de réservation
            </Tag>
          );
        } else {
          return (
            <Tag icon={<ClockCircleOutlined />} color="error">
              En attente d'envoi invitation
            </Tag>
          );
        }
      }
    }
  };

  const fetchProjectPlannings = async () => {
    // console.log(`fetchProjectPlannings()`);
    try {
      const res = await axios.get(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/project/" +
          programId +
          "/plannings"
      );

      // Fetch Planning Drafts
      const res2 = await axios.get(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/project/" +
          programId +
          "/planning-drafts"
      );

      let ptypes = {};
      res2.data.planningDrafts.forEach((p) => {
        if (p.templateType === "promoter") {
          p["templateType"] = "draft";
          ptypes[p._id] = "promoterDraft";
        } else {
          p["templateType"] = "draftImpose";
          ptypes[p._id] = "promoterImposeDraft";
        }
      });

      res.data.plannings.forEach((p) => {
        ptypes[p._id] = p.templateType;
      });

      // console.log(`ptypes --> ${JSON.stringify(ptypes)}`);

      setPlanningTypes(ptypes);
      setData(
        res.data.plannings
          .concat(res2.data.planningDrafts)
          .map((_) => ({ ..._, title: _.name }))
      );

      const resProgram = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + programId,
        getBasicAuthConfig()
      );
      // console.log(`resProgram.data : ${JSON.stringify(resProgram.data)}`);
      setProgram(resProgram.data);
      if (
        resProgram.data.adressenumero === "" ||
        resProgram.data.adresserue === "" ||
        resProgram.data.adressecodepostal === "" ||
        resProgram.data.adresseville === ""
      ) {
        showConfirm();
      }
      setIsLoading(false);
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement des plannings.`);
    }
  };

  useEffect(() => {
    // let isMounted = true;
    if (isLoading) {
      // && isMounted) {
      fetchProjectPlannings();
    }
    // else if (hasChanged && isMounted) {
    //   setHasChanged(false);
    //   setIsLoading(true);
    // }
    // return () => {
    //   isMounted = false;
    // };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIndeterminate(checked.length && checked.length !== data.length);
      setCheckAll(checked.length === data.length);
    }
  }, [checked]);

  // useEffect(() => {
  //   // fetchProjectPlannings();
  //   // let isMounted = true;
  //   if (isLoading) {
  //     // && isMounted) {
  //     fetchProjectPlannings();
  //   } else if (hasChanged) {
  //     // && isMounted) {
  //     setHasChanged(false);
  //     setIsLoading(true);
  //   }
  //   // return () => {
  //   //   // console.log("Component unmounted");
  //   //   // TODO: Problem with unmounted component here
  //   //   isMounted = false;
  //   // };
  // }, [hasChanged]);

  const onCheckAllChange = (e) => {
    setChecked(e.target.checked ? data.map((item) => item._id) : []);
    setCheckAll(e.target.checked);
  };

  const setCurrentPlanning = (item) => {
    localStorage.setItem("currentPlanning", item.name);
    localStorage.setItem("currentPlanningId", item._id);
  };

  const onConsolidateClick = () => {
    let currentSelectionType = checked.map((_) => planningTypes[_]);
    let setSize = Array.from(new Set(currentSelectionType)).length;

    // Mettre if new set de currentSelectionType size !== 1 .....
    if (setSize !== 1) {
      message.error(`Veuillez selectionner des plannings de meme type `);
      return;
    }

    if (
      currentSelectionType.includes("promoterDraft") ||
      currentSelectionType.includes("promoterImposeDraft")
    ) {
      message.error(`Les brouillons ne peuvent pas être consolidés 🫢`);
      return;
    }

    const params = {
      ids: checked,
    };

    history.push({
      pathname: `/projets/${programId}/plannings-consolidate`,
      search: new URLSearchParams(params).toString(),
    });
  };

  const DropdownMenu = () => {
    let items = [
      {
        label: (
          <Link to={"/projets/" + program._id + "/plannings/nouveau"}>
            Libre
          </Link>
        ),
        key: "1",
      },
      {
        label: (
          <Link to={"/projets/" + program._id + "/plannings/nouveau-impose"}>
            Imposé
          </Link>
        ),
        key: "2",
      },

      // {
      //   label: "Réserves",
      //   key: "3",
      //   danger: true,
      //   disabled: true,
      // },
    ];

    // const menuProps = {
    //   items,
    // };

    return (
      <Dropdown menu={{ items }}>
        <Button icon={<DownOutlined />}>Plannings</Button>
      </Dropdown>
    );
  };

  return (
    <ContentContainer>
      <SubContentContainer>
        {!isLoading && (
          <div>
            <CustomPageHeader
              arrow={true}
              title="Liste des plannings"
              extra={[<DropdownMenu key="more2" />]}
            ></CustomPageHeader>
            {/* <PageHeader
            style={{ margin: "0px 16px 0px 16px" }}
            title="Liste des plannings"
            className="site-page-header"
            extra={[<DropdownMenu key="more2" />]}
          ></PageHeader> */}
            {data.length > 0 && (
              <Checkbox
                style={{ padding: "0px 10px 0px 10px" }}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Sélectionner tout
              </Checkbox>
            )}

            {checked.length > 0 && (
              <Button type="link" onClick={onConsolidateClick}>
                Vue consolidée ({`${checked.length} planning(s)`})
              </Button>
            )}
            <br />
            <br />
            {data.length > 0 ? (
              <Checkbox.Group
                style={{ width: "100%" }}
                value={checked}
                onChange={(checkedValues) => {
                  setChecked(checkedValues);
                }}
              >
                <List
                  // bordered={true}
                  style={{ width: "100%", padding: "0px 10px 0px 10px" }}
                  size="small"
                  itemLayout="horizontal"
                  dataSource={data.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )}
                  renderItem={(item, idx) => (
                    <div>
                      <Card
                        hoverable
                        bordered={false}
                        size="small"
                        key={`card` + item._id}
                        // style={{ marginBottom: "1px" }}
                      >
                        <List.Item
                          style={{
                            padding: "0px 10px 0px 10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            const isDeleteIconClicked =
                              e.target.closest(".anticon-delete") ||
                              e.target.closest(".ant-popover-inner");
                            const isCheckedButtonClicked =
                              e.target.closest(".ant-checkbox");

                            if (
                              !isDeleteIconClicked &&
                              !isCheckedButtonClicked
                            ) {
                              setCurrentPlanning(item);
                              history.push(
                                item.templateType === "promoterImpose"
                                  ? `/projets/${program._id}/plannings-imposes/${item._id}`
                                  : item.templateType === "promoter"
                                  ? `/projets/${program._id}/plannings-libres/${item._id}`
                                  : item.templateType === "draft"
                                  ? `/projets/${program._id}/plannings/nouveau?id=${item._id}`
                                  : `/projets/${program._id}/plannings/nouveau-impose?id=${item._id}`
                              );
                            }
                          }}
                          key={`z` + item._id}
                          extra={[
                            <div key={`convocationLabel` + item._id}>
                              {(item.templateType === "promoterImpose" ||
                                item.templateType === "draftImpose") && (
                                <Tag color="purple">Convocation</Tag>
                              )}
                            </div>,
                            <div key={`counterInvit` + item._id}>
                              {item.broadcastList !== undefined &&
                                item.broadcastList.filter(
                                  (x) => x.isInvitationSent
                                ).length}{" "}
                              /{" "}
                              {item.broadcastList !== undefined &&
                                item.broadcastList.length}
                              &nbsp;&nbsp;
                            </div>,
                            <Popconfirm
                              title="Etes-vous sûr(e) de vouloir supprimer l'intégralité du planning ?"
                              onConfirm={(e) => {
                                // console.log(`item : ${JSON.stringify(item)}`);
                                removePlanning(item);
                              }}
                              okText="Oui"
                              cancelText="Non"
                              key={item._id}
                            >
                              <a>
                                <DeleteOutlined />
                              </a>
                            </Popconfirm>,
                          ]}
                        >
                          <div key={`zwer` + item._id}>
                            <Checkbox value={item._id}></Checkbox>
                          </div>
                          <Avatar
                            src="https://cdn-icons-png.flaticon.com/512/3061/3061341.png"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          />
                          <List.Item.Meta
                            key={`zM` + item._id}
                            // avatar={
                            //   <Avatar src="https://cdn-icons-png.flaticon.com/512/3061/3061341.png" />
                            // }
                            title={
                              <AntdLink>
                                {item.name +
                                  "  (" +
                                  phaseTransform[item.phase] +
                                  ", début: " +
                                  moment(item.planningStartTime).format(
                                    "ddd DD MMM"
                                  ) +
                                  ") "}

                                <Tag>{`${
                                  item.nbInvited !== undefined
                                    ? item.nbInvited
                                    : item.selectedRowKeys.length
                                } invités`}</Tag>

                                {renderInnerTags(item)}
                              </AntdLink>
                            }
                            description={
                              item.templateType === "promoterImpose" ? (
                                <div style={{ width: 170 }}>
                                  <Progress
                                    percent={Math.round(
                                      ((item.nbConfirmed +
                                        item.nbNotAvailable) /
                                        item.nbInvited) *
                                        100
                                    )}
                                    size="small"
                                    status="active"
                                  />
                                </div>
                              ) : (
                                <div style={{ width: 170 }}>
                                  <Progress
                                    percent={Math.round(
                                      ((item.nbBooked + item.nbNotAvailable) /
                                        item.nbInvited) *
                                        100
                                    )}
                                    size="small"
                                    status="active"
                                  />
                                </div>
                              )
                            }
                          />
                        </List.Item>
                      </Card>
                      <br />
                    </div>
                  )}
                />
              </Checkbox.Group>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Aucun planning pour le moment</span>}
              />
            )}
          </div>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Plannings;
