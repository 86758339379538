import React from "react";
import { Button, Form, Input, Upload, Space, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  firstParagraph,
  secondParagraph,
  thirdParagraph,
} from "./planningData";

const CollectionCreateForm = ({
  normFile,
  form,
  isCustomDisabled,
  setIsCustomDisabled,
}) => {
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = () => {
    setIsCustomDisabled(!isCustomDisabled);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Space>
          {isCustomDisabled ? (
            <Switch onChange={onChange} />
          ) : (
            <Switch onChange={onChange} defaultChecked />
          )}
          Personnaliser
        </Space>
        <br />
        <br />
        <Form.Item
          name="upload"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          hidden={isCustomDisabled}
          extra="Choisissez le logo du promoteur de la visite. Par défaut, le logo du programme sera utilisé."
        >
          <Upload name="logo" listType="picture" customRequest={dummyRequest}>
            <Button icon={<UploadOutlined />}>Sélectionner</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="firstParagraph"
          label="Paragraphe 1"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le premier paragraphe",
            },
          ]}
          // initialValue="Découvrez l'expérience immersible de votre logement en
          // visite virtuelle"
          hidden={isCustomDisabled}
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>

        <Form.Item
          name="secondParagraph"
          label="Paragraphe 2"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le second paragraphe",
            },
          ]}
          // initialValue="Prenez le contrôle ! Nous vous donnons la possibilité de
          // visiter votre logement en réalité virtuelle. Découvrez les
          // espaces, les implantations des appareillages."
          hidden={isCustomDisabled}
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>

        <Form.Item
          name="thirdParagraph"
          label="Paragraphe 3"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le troisième paragraphe",
            },
          ]}
          // initialValue="Votre AMO se tient à votre disposition pour répondre à vos questions."
          hidden={isCustomDisabled}
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>
      </Form>
    </div>
  );
};

const PlanningPromoter = ({
  form,
  setSelectedFile,
  isCustomDisabled,
  setIsCustomDisabled,
}) => {
  // const [form] = Form.useForm();

  const normFile = (e) => {
    if (e.fileList[0] !== undefined) {
      setSelectedFile(e.fileList[0].originFileObj);
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      <CollectionCreateForm
        normFile={normFile}
        form={form}
        isCustomDisabled={isCustomDisabled}
        setIsCustomDisabled={setIsCustomDisabled}
      />
    </div>
  );
};

export default PlanningPromoter;
