import React, { useState } from "react";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";
import "dayjs/locale/fr";
import axios from "axios";
import {
  App,
  Table,
  Popconfirm,
  Form,
  Input,
  DatePicker,
  Badge,
  Row,
  Col,
  Statistic,
  Tooltip,
  Button,
  Typography,
  Space,
} from "antd";
import { getVisitesApiAuth } from "../utils";

const { Title } = Typography;
var relativeTime = require("dayjs/plugin/relativeTime");

const VisitTable = ({ visitList, setVisitList, fetchVisitList }) => {
  dayjs.extend(relativeTime);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(0);
  const [editingKey, setEditingKey] = useState("");

  const confirmDel = async (record) => {
    await delRecord(record);
    const dataSource = [...visitList];
    setVisitList(dataSource.filter((item) => item.key !== record.key));
  };

  function cancelDel(record) {
    message.error(`Suppression annulée`);
  }

  const CustomInput = ({ dataIndex, type, title, endDate }) => {
    if (type !== "select") {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <DatePicker value={dayjs(endDate)} />
        </Form.Item>
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            type={inputType}
            endDate={dayjs(record.endDate)}
            dataIndex={dataIndex}
            title={title}
            key={record.key}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      endDate: dayjs(record.endDate),
    });
    setEditingKey(record.key);
  };

  const delRecord = async (record) => {
    try {
      await axios.delete(
        process.env.REACT_APP_VR_API_URL + "/vv/" + record.key,
        getVisitesApiAuth()
      );
      message.success(
        `La visite de l'utilisateur ${record.email} a été supprimée avec succès`
      );
    } catch (e) {
      message.error(
        `Erreur lors de la suppression de la visite de l'utilisateur ${record.email}`
      );
    }
  };

  const cancel = async () => {
    setEditingKey("");
    await form.resetFields();
  };

  const save = async (e, id) => {
    e.preventDefault();
    try {
      const updates = await form.validateFields();
      const newData = [...visitList];
      const index = newData.findIndex((item) => id === item.key);

      newData[index].endDate = updates.endDate;

      let isActive = Date.now() < new Date(newData[index].endDate);
      if (isActive) {
        newData[index].isActive = true;
      } else {
        newData[index].isActive = false;
      }
      setVisitList(newData);
      await axios.patch(
        process.env.REACT_APP_VR_API_URL + "/vv/" + id,
        updates,
        getVisitesApiAuth()
      );
      message.success(
        `Données correctement mises à jour pour la visite ${newData[index].name}`
      );
    } catch (errInfo) {
      console.log(errInfo);
      message.error("Erreur lors de la validation des champs:", errInfo);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let newlySelected = {};
      selectedRows.forEach((row) => {
        if (Object.keys(newlySelected).includes(row.uuid)) {
          newlySelected[row.uuid].push(row.key);
        } else {
          newlySelected[row.uuid] = [row.key];
        }
      });
      setSelected(newlySelected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const removeVvs = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_VR_API_URL + "/vv/delete-multiple",
        selected,
        getVisitesApiAuth()
      );
      console.log(res.data);
      setSelected(0);
      await fetchVisitList();
    } catch (e) {
      console.log(e);
    }
  };

  const writeSelection = () => {
    if (selected === 0) {
      return (
        <div>
          <i>Séléctionner pour afficher les options</i>
        </div>
      );
    } else {
      let nb = Object.values(selected).flat(2).length;
      return (
        <div>
          {nb} visites{" "}
          <Popconfirm
            title={
              "Etes-vous sûr(e) de vouloir supprimer les " +
              nb +
              " éléments séléctionnés ?"
            }
            onConfirm={() => {
              removeVvs();
            }}
            okText="Oui"
            cancelText="Non"
          >
            <a>
              <Button type="link">(Supprimer)</Button>
            </a>
          </Popconfirm>
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (text) => `${text}`,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      editable: false,
      ellipsis: true,
    },
    {
      title: "Début",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => <>{dayjs(new Date(startDate)).fromNow()}</>,
      editable: false,
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      ellipsis: true,
    },
    {
      title: "Fin",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => <>{dayjs(new Date(endDate)).fromNow()}</>,
      editable: true,
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      editable: false,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      render: (email) => {
        let mails = undefined;
        let isMultiple = false;

        if (email !== undefined) {
          if (email.includes(";")) {
            isMultiple = true;
            mails = email.split(";");
          }

          if (!isMultiple) {
            return email;
          } else {
            return (
              <Tooltip
                placement="bottom"
                title={mails.map((_, idx) => (
                  <div key={idx}>
                    {_}
                    <br />
                  </div>
                ))}
              >
                <a>multiples</a>
              </Tooltip>
            );
          }
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Promoteur",
      dataIndex: "promoter",
      key: "promoter",
      editable: false,
      filters: Array.from(new Set(visitList.map((x) => x.promoter))).map(
        (_) => ({
          text: _,
          value: _,
        })
      ),
      onFilter: (value, record) => record.promoter.includes(value),
      sorter: (a, b) => a.promoter.localeCompare(b.promoter),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Nom Matterport",
      dataIndex: "pointer",
      key: "pointer",
      editable: false,
      sorter: (a, b) => a.pointer.localeCompare(b.pointer),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
    },
    {
      title: "Lien",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => (
        <a href={"/immersion/" + text} target="_blank" rel="noreferrer">
          ici
        </a>
      ),
      editable: false,
      ellipsis: true,
    },
    {
      title: "État",
      dataIndex: "isActive",
      key: "isActive",
      editable: false,
      render: (text) =>
        text === true ? (
          <Badge status="success" text="active" />
        ) : (
          <Badge status="error" text="expirée" />
        ),
      sorter: (a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1),
      sortDirections: ["descend", "ascend"],
      filters: Array.from(new Set(visitList.map((x) => x.isActive))).map(
        (_) => ({
          text: _ === false ? "Expiré" : "Active",
          value: _,
        })
      ),
      onFilter: (value, record) => {
        return JSON.stringify(record.isActive).includes(value);
      },
      ellipsis: true,
    },
    {
      title: "Notifié",
      dataIndex: "sendMail",
      key: "sendMail",
      editable: false,
      sorter: (a, b) => (a.sendMail === b.sendMail ? 0 : a.sendMail ? -1 : 1),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
    },
    {
      title: "Vues",
      dataIndex: "counter",
      key: "counter",
      editable: false,
      sorter: (a, b) => a.counter - b.counter,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a
              onClick={(e) => {
                save(e, record.key);
              }}
            >
              Sauver
            </a>
            <Popconfirm title="Souhaitez-vous annuler ?" onConfirm={cancel}>
              <a>Annuler</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a onClick={() => edit(record)} disabled={editingKey !== ""}>
              Editer
            </a>
            <Popconfirm
              title="Etes-vous sûr de vouloir supprimer la visite ?"
              onConfirm={() => confirmDel(record)}
              onCancel={() => cancelDel(record)}
              okText="Oui"
              cancelText="Non"
            >
              <a>Supprimer</a>
            </Popconfirm>
          </Space>
        );
      },
      ellipsis: true,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "endDate" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Row gutter={16}>
        <Col span={3}>
          <Statistic title="Total" value={visitList.length} />
        </Col>
        <Col span={3}>
          <Statistic
            title={
              visitList.filter((_) => _.isActive).length > 1
                ? "Actives"
                : "Active"
            }
            value={visitList.filter((_) => _.isActive).length}
          />
        </Col>
        <Col span={3}>
          <Statistic
            title={
              visitList.filter((_) => !_.isActive).length > 1
                ? "Expirées"
                : "Expirée"
            }
            value={visitList.filter((_) => !_.isActive).length}
          />
        </Col>
      </Row>
      <br />
      <Title level={4}>Liste des visites</Title>
      {writeSelection()}
      <br />
      <Form size="small" form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          bordered
          dataSource={visitList}
          columns={mergedColumns}
          rowKey="key"
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      <br />
      <br />
    </div>
  );
};

export default VisitTable;
