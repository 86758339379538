import React, { useEffect, useState } from "react";
import "dayjs/locale/fr";
import { Button, ColorPicker, ConfigProvider, Divider, Space } from "antd";

const ProjectColorPicker = ({
  presetDefaultColors,
  selectedColor,
  setSelectedColor,
  colorPickerVisible,
  setColorPickerVisible,
}) => {
  const handleColorChange = (index, color) => {
    setSelectedColor(
      `rgb(${color.metaColor.r}, ${color.metaColor.g}, ${color.metaColor.b})`
    );
  };

  return (
    <Space direction="horizontal">
      <ColorPicker
        defaultFormat="rgb"
        value={selectedColor}
        placement="bottomRight"
        // open={colorPickerVisible}
        onOpenChange={(open) => setColorPickerVisible(open)}
        showText={() => <span>Couleur principale</span>}
        onChangeComplete={(color) => handleColorChange(0, color)}
        presets={[
          {
            label: "Couleur Bridgit",
            colors: ["#436386"],
            defaultOpen: true,
          },
          {
            label: "Couleur des créneaux affichés",
            colors: presetDefaultColors.filter(
              (color) => color !== "rgb(67, 99, 134)"
            ),
            defaultOpen: true,
          },
        ]}
      />
      <br />
      <Divider type="vertical" />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: selectedColor,
          },
        }}
      >
        <Space direction="vertical">
          <Space direction="horizontal">
            <Button type="primary">10:00</Button>
            <Button type="primary">15:00</Button>
          </Space>
          <Space direction="horizontal">
            <Button type="primary">10:30</Button>
            <Button type="primary">15:30</Button>
          </Space>
        </Space>
      </ConfigProvider>
    </Space>
  );
};

export default ProjectColorPicker;
