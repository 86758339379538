import { DualAxes } from "@ant-design/charts";
import React, { Component } from "react";

class RdvsLogsHistogram extends Component {
  render() {
    return <DualAxes {...this.props.config} />;
  }
}

export default RdvsLogsHistogram;
