import React, { useContext } from "react";
import { UserMenuData } from "./../../UserMenuData";
import { Space } from "antd";
import { Row, Dropdown, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
// import "../../../App.css";

const { Text } = Typography;

const roleTranslate = {
  administrator: "Administrateur",
  anonymousUser: "Anonyme",
  customer: "Acquéreur",
  promoter: "Promoteur",
  amo: "AMO",
  moderator: "Modérateur",
};

const items = UserMenuData.map((item, index) => ({
  label: <Link to={item.path}>{item.label}</Link>,
  key: "user" + index,
}));

const AdminMenu = () => {
  const { role, username } = useContext(UserContext);
  return (
    <div style={{ marginRight: "16px" }}>
      <Space direction="horizontal">
        <Text>
          <b>{username}</b>
        </Text>
        <Text type="success">({roleTranslate[role]})</Text>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button icon={<UserOutlined />}></Button>
        </Dropdown>
        {/* <Dropdown.Button
          style={{
            margin: "0px 24px 0px 0px",
          }}
          className="dropdown-btn"
          menu={{ items }}
          icon={<UserOutlined />}
        ></Dropdown.Button> */}
      </Space>
    </div>
  );
};

export default AdminMenu;
