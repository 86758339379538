import React, { useState, useEffect, useReducer } from "react";
import {
  EnvironmentOutlined,
  ExportOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Typography,
  Button,
  Layout,
  Collapse,
  Tooltip,
  Segmented,
  Space,
  FloatButton,
  Checkbox,
} from "antd";
import ContentContainer from "./../../components/ContentContainer";
import PublicFooter from "./../../components/public/Footer";
import AgendaCalendar from "./AgendaCalendar";
import { exportPDF } from "../../utils";
import sharedPlanningReducer, {
  initReducer,
} from "./../../reducers/sharedplanning";
import { bankHolidays } from "./planningData";

let fmtBankHolidays = [];
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const { Title, Text } = Typography;

const AgendaReadOnlyPublic = ({ plannings, amoNb }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isExportPDFLoading, setIsExportPDFLoading] = useState(false);
  const [unavailableDetailsText, setUnavailableDetailsText] = useState("");
  const [notBookedDetailsText, setNotBookedDetailsText] = useState("");
  const [vrDetailsText, setVrDetailsText] = useState("");
  const [viewType, setViewType] = useState("Semaine");
  const [evts, dispatch] = useReducer(sharedPlanningReducer, [], initReducer);
  const [showCal, setShowCal] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [checked, setChecked] = useState(false);

  const phaseTransform = {
    choices: "Choix",
    partitions: "Cloisons",
    predelivery: "Pré-livraison",
    delivery: "Livraison",
  };

  // console.log(`AMONB : ${amoNb}`);

  const onChange = (e) => {
    dispatch({
      type: "RESET",
      plannings,
      pas: viewType,
      templateType:
        plannings[0].templateType === "promoter" ? "libre" : "impose",
      showFreeMeetings: e.target.checked,
      amoNb,
      fmtBankHolidays,
    });
    setChecked(e.target.checked);
    setShowCal(false);
  };

  const onClickExportPDF = async (info) => {
    // console.log("export pdff");
    setIsExportPDFLoading(true);
    await exportPDF(plannings, viewType, checked, amoNb);
    setIsExportPDFLoading(false);
  };

  const getTextMessage = (lst) => {
    let text = "";

    if (lst.length === 0) {
      text = "-";
    } else if (lst.length === 1) {
      text = lst[0];
    } else {
      lst.forEach((_, idx) => {
        if (idx === 0) {
          text += `${_}`;
        } else {
          text += `, ${_}`;
        }
      });
    }
    return text;
  };

  useEffect(() => {
    let isImposed = false;
    if (plannings[0].templateType === "promoterImpose") isImposed = true;

    let tmp = [];
    plannings.forEach((planning) => {
      tmp = tmp.concat(
        planning.broadcastList
          .filter((y) => !y.isAvailable)
          .map((_, idx) => _.guest.lotcomm)
      );
    });
    setUnavailableDetailsText(getTextMessage(tmp));

    tmp = [];
    plannings.forEach((planning) => {
      if (isImposed) {
        tmp = tmp.concat(
          planning.broadcastList
            .filter((y) => !y.isConfirmed && y.isActive)
            .map((_, idx) => _.guest.lotcomm)
        );
      } else {
        tmp = tmp.concat(
          planning.broadcastList
            .filter((y) => !y.isBooked && y.isAvailable && y.isActive)
            .map((_, idx) => _.guest.lotcomm)
        );
      }
    });
    setNotBookedDetailsText(getTextMessage(tmp));

    tmp = [];
    plannings.forEach((planning) => {
      tmp = tmp.concat(
        planning.broadcastList
          .filter((y) => y.isInterestedVr && y.isActive)
          .map((_, idx) => _.guest.lotcomm)
      );
    });
    setVrDetailsText(getTextMessage(tmp));
    //console.log(`amoNb : ${amoNb}`);
    // console.log(`RESET`);
    dispatch({
      type: "RESET",
      plannings,
      pas: "Semaine",
      templateType:
        plannings[0].templateType === "promoter" ? "libre" : "impose",
      amoNb,
      fmtBankHolidays,
    });
    setShowCal(true);
    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;
    if (mobileView) {
      setIsMobileView(true);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewChange = (value) => {
    setViewType(value);
    dispatch({
      type: "RESET",
      plannings,
      pas: value,
      templateType:
        plannings[0].templateType === "promoter" ? "libre" : "impose",
      showFreeMeetings: checked,
      amoNb,
      fmtBankHolidays,
    });
    setShowCal(false);
  };

  useEffect(() => {
    if (!showCal && !isLoading) setShowCal(true);
  }, [showCal]);

  const itemsPromoterType = [
    {
      key: "1",
      label: (
        <Text type="secondary" style={{ textAlign: "center" }}>
          {" "}
          {`Taux de réponse : ${Math.round(
            ((plannings.reduce(
              (accumulator, p) => accumulator + p.nbBooked,
              0
            ) +
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbNotAvailable,
                0
              )) /
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbInvited,
                0
              )) *
              100
          )}% | Total invités : ${plannings.reduce(
            (accumulator, p) => accumulator + p.nbInvited,
            0
          )} | Réservé(s) : ${plannings.reduce(
            (accumulator, p) => accumulator + p.nbBooked,
            0
          )} | En attente : ${
            plannings.reduce(
              (accumulator, p) => accumulator + p.nbNotBooked,
              0
            ) -
            plannings.reduce(
              (accumulator, p) => accumulator + p.nbNotAvailable,
              0
            )
          } | Indisponible(s) : ${plannings.reduce(
            (accumulator, p) => accumulator + p.nbNotAvailable,
            0
          )}`}
        </Text>
      ),
      showArrow: false,
      children: (
        <div>
          <Tooltip
            placement="bottom"
            title="Cliquez pour les détails"
          ></Tooltip>
          <p>
            {" "}
            <Text type="secondary">
              Indisponibles : {unavailableDetailsText}
              <br />
              En attente : {notBookedDetailsText}
              <br />
              Visites 3D souhaitées : {vrDetailsText}
            </Text>
          </p>
        </div>
      ),
    },
  ];

  const itemsPromoterImposeType = [
    {
      key: "11",
      label: (
        <Tooltip placement="bottom" title="Cliquez pour les détails">
          <Text type="secondary" style={{ textAlign: "center" }}>
            {" "}
            {`Taux de réponse : ${Math.round(
              (plannings.reduce(
                (accumulator, p) => accumulator + p.nbConfirmed,
                0
              ) /
                plannings.reduce(
                  (accumulator, p) => accumulator + p.nbInvited,
                  0
                )) *
                100
            )}% | Total convoqués : ${plannings.reduce(
              (accumulator, p) => accumulator + p.nbInvited,
              0
            )} | Confirmé(s) : ${
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbConfirmed,
                0
              ) -
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbNotAvailable,
                0
              )
            } | En attente : ${
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbInvited,
                0
              ) -
              plannings.reduce(
                (accumulator, p) => accumulator + p.nbConfirmed,
                0
              )
            } | Indisponible(s) : ${plannings.reduce(
              (accumulator, p) => accumulator + p.nbNotAvailable,
              0
            )}`}
          </Text>
        </Tooltip>
      ),
      showArrow: false,
      children: (
        <p>
          <Text type="secondary">
            Indisponibles : {unavailableDetailsText}
            <br />
            En attente : {notBookedDetailsText}
            <br />
            Visites 3D souhaitées : {vrDetailsText}
          </Text>
        </p>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <br />
        <ContentContainer>
          <div style={{ margin: "0px 20px 20px 20px" }}>
            {!isLoading && (
              <div>
                <br />
                {!isMobileView ? (
                  <Row align="middle">
                    <Col span={4}>
                      <img
                        alt="avatar"
                        src={
                          process.env.REACT_APP_API_URL +
                          "/programs/" +
                          plannings[0].projectId +
                          "/avatar"
                        }
                        style={{ maxWidth: "70px" }}
                      />
                    </Col>
                    <Col span={16}>
                      <center>
                        {plannings.length === 1 ? (
                          <Title level={3}>{`${plannings[0].name} - Visites ${
                            phaseTransform[plannings[0].phase]
                          } (${plannings[0].nbInvited} lots)`}</Title>
                        ) : (
                          <Title level={3}>{`${
                            plannings[0].projectName
                          } - Visites ${
                            phaseTransform[plannings[0].phase]
                          } (${plannings.reduce(
                            (accumulator, p) => accumulator + p.nbInvited,
                            0
                          )} lots)`}</Title>
                        )}

                        <div className="collapseWrapper">
                          {plannings[0].templateType === "promoter" ? (
                            <Collapse
                              items={itemsPromoterType}
                              ghost
                            ></Collapse>
                          ) : (
                            <Collapse
                              items={itemsPromoterImposeType}
                              ghost
                            ></Collapse>
                          )}
                        </div>
                      </center>
                    </Col>
                    <Col span={4} verticalalign="middle">
                      <div
                        style={{
                          verticalAlign: "middle",
                          textAlign: "right",
                          marginRight: "3px",
                        }}
                      >
                        <Space direction="vertical">
                          <Space direction="horizontal">
                            <Checkbox checked={checked} onChange={onChange}>
                              RDVs Libres
                            </Checkbox>
                            <Tooltip
                              placement="leftBottom"
                              title="Cliquez pour exporter en PDF"
                            >
                              <Button
                                onClick={onClickExportPDF}
                                loading={isExportPDFLoading}
                                icon={<ExportOutlined />}
                                type="primary"
                              >
                                PDF
                              </Button>
                            </Tooltip>
                          </Space>
                          <Segmented
                            // size="default"
                            options={["Jour", "Semaine", "Mois"]}
                            onChange={onViewChange}
                            defaultValue="Semaine"
                          />
                        </Space>
                        {/* {planning.address !== undefined &&
                    planning.address.rue !== undefined &&
                    planning.address.rue !== "" ? (
                      <Tooltip
                        placement="leftBottom"
                        title="Cliquez pour exporter en PDF"
                      >
                        <Button
                          onClick={onClickExportPDF}
                          loading={isExportPDFLoading}
                          icon={<ExportOutlined />}
                          type="primary"
                        >
                          PDF
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button type="primary" onClick={closeWindow}>
                        Quitter
                      </Button>
                    )} */}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <Row align="center">
                      <img
                        alt="avatar"
                        src={
                          process.env.REACT_APP_API_URL +
                          "/programs/" +
                          plannings[0].projectId +
                          "/avatar"
                        }
                        style={{ maxWidth: "70px" }}
                      />
                    </Row>
                    <br />
                    <Row align="center">
                      <center>
                        {plannings.length === 1 ? (
                          <Title level={3}>{`${plannings[0].name} - Visites ${
                            phaseTransform[plannings[0].phase]
                          } (${plannings[0].nbInvited} lots)`}</Title>
                        ) : (
                          <Title level={3}>{`${
                            plannings[0].projectName
                          } - Visites ${
                            phaseTransform[plannings[0].phase]
                          } (${plannings.reduce(
                            (accumulator, p) => accumulator + p.nbInvited,
                            0
                          )} lots)`}</Title>
                        )}
                        <div className="collapseWrapper">
                          {plannings[0].templateType === "promoter" ? (
                            <Collapse
                              items={itemsPromoterType}
                              ghost
                            ></Collapse>
                          ) : (
                            <Collapse
                              items={itemsPromoterImposeType}
                              ghost
                            ></Collapse>
                          )}
                        </div>
                      </center>
                    </Row>
                    <Row align="center">
                      <center>
                        <Space direction="vertical">
                          <Space direction="horizontal">
                            <Checkbox checked={checked} onChange={onChange}>
                              RDVs Libres
                            </Checkbox>
                            <Tooltip
                              placement="top"
                              title="Cliquez pour exporter en PDF"
                            >
                              <Button
                                onClick={onClickExportPDF}
                                loading={isExportPDFLoading}
                                icon={<ExportOutlined />}
                                type="primary"
                              >
                                PDF
                              </Button>
                            </Tooltip>
                          </Space>
                          <Segmented
                            // size="default"
                            options={["Jour", "Semaine", "Mois"]}
                            onChange={onViewChange}
                            defaultValue="Semaine"
                          />
                        </Space>{" "}
                      </center>
                    </Row>
                  </div>
                )}
                <AgendaCalendar
                  viewType={viewType}
                  plannings={plannings}
                  evts={evts}
                  dispatch={dispatch}
                  showCal={showCal}
                  setShowCal={setShowCal}
                />
                {/* </div> */}
                <br />
                <Row>
                  <Col span={4} align="middle"></Col>
                  <Col span={16} align="middle">
                    {plannings[0].address !== undefined &&
                    plannings[0].address.rue !== undefined &&
                    plannings[0].address.rue !== "" ? (
                      <Tooltip
                        placement="top"
                        title="Cliquez pour accéder au plan"
                      >
                        <a
                          href={`https://www.google.fr/maps/place/${encodeURI(
                            plannings[0].address.numero
                          )}+${encodeURI(plannings[0].address.rue)}+${encodeURI(
                            plannings[0].address.codepostal
                          )}+${encodeURI(
                            plannings[0].address.ville
                          )}+${encodeURI(plannings[0].address.pays)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <EnvironmentOutlined />
                          {` ${plannings[0].address.numero} ${plannings[0].address.rue} ${plannings[0].address.codepostal} ${plannings[0].address.ville} ${plannings[0].address.pays}`}
                        </a>
                      </Tooltip>
                    ) : (
                      <Text>
                        <EnvironmentOutlined />?
                      </Text>
                    )}
                  </Col>
                  <Col span={4} align="middle"></Col>{" "}
                </Row>
              </div>
            )}
          </div>
        </ContentContainer>
        <PublicFooter />
        {viewType === "Jour" && !isMobileView ? (
          <FloatButton.BackTop
            style={{ marginBottom: "28px", marginRight: "0px" }}
          >
            <div className="backtopDivWrapper">
              <Button icon={<VerticalAlignTopOutlined />}></Button>
            </div>
          </FloatButton.BackTop>
        ) : viewType === "Jour" && isMobileView ? (
          <FloatButton.BackTop
            style={{ marginBottom: "28px", marginRight: "27px" }}
          >
            <div className="backtopDivWrapper">
              <Button icon={<VerticalAlignTopOutlined />}></Button>
            </div>
          </FloatButton.BackTop>
        ) : null}
      </Layout>
    </Layout>
  );
};

export default AgendaReadOnlyPublic;
