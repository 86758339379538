import { filterByKeys, addMilliseconds } from "../utils";

const initReducer = () => {
  let s = {
    placed: [],
    notPlaced: [],
    value: "batiment",
    filterSet: [],
    subFilterEvents: [],
    checkList: [],
  };
  return s;
};

const calendarEventsReducer = (state, action) => {
  let newSubFilterEvents = undefined;
  let tmp = undefined;
  let newNotPlaced = undefined;
  let newPlaced = undefined;
  let calendarApi = undefined;

  switch (action.type) {
    case "RESET":
      return initReducer();

    case "INIT_DRAFT":
      return {
        ...state,
        notPlaced: [...action.notPlaced].sort(function (a, b) {
          return a.lotcomm.localeCompare(b.lotcomm);
        }),
        placed: [...action.placed].sort(function (a, b) {
          return a.lotcomm.localeCompare(b.lotcomm);
        }),
      };

    case "ADD_SELECTED":
      return {
        ...state,
        notPlaced: [...state.notPlaced]
          .concat([...action.item])
          .sort(function (a, b) {
            return a.lotcomm.localeCompare(b.lotcomm);
          }),
      };

    case "REMOVE_SELECTED":
      let lotcommsToRemove = action.item.map((x) => x.lotcomm);
      let tmp = [...state.placed].concat([...state.notPlaced]);
      let areSomeAlreadyPlaced = tmp.filter((_) =>
        lotcommsToRemove.includes(_.lotcomm)
      );
      if (areSomeAlreadyPlaced.length > 0) {
        // If some events are placed, remove them from calendar events
        calendarApi = action.calendarRef.current.getApi();
        areSomeAlreadyPlaced.forEach((_) => {
          let newDeletedEvent = calendarApi.getEventById(_._id);
          if (newDeletedEvent !== null) newDeletedEvent.remove();
        });
        return {
          ...state,
          placed: [...state.placed].filter(
            (_) => !lotcommsToRemove.includes(_.lotcomm)
          ),
          // notPlaced: [],
          notPlaced: [...state.notPlaced]
            .filter((_) => !lotcommsToRemove.includes(_.lotcomm))
            .sort(function (a, b) {
              return a.lotcomm.localeCompare(b.lotcomm);
            }),
          // subFilterEvents: [],
        };
      } else {
        // Tous sont non placés, retirer des non placés
        return {
          ...state,
          notPlaced: [...state.notPlaced]
            .filter((_) => !lotcommsToRemove.includes(_.lotcomm))
            .sort(function (a, b) {
              return a.lotcomm.localeCompare(b.lotcomm);
            }),
        };
      }

    case "MODIFY_LENGTH":
      // Length to modify is in notPlaced
      let foo = [...state.notPlaced].find(
        (x) => x.lotcomm === action.item.lotcomm
      );
      let updated = [...state.notPlaced];
      if (foo !== undefined) {
        for (const obj of updated) {
          // console.log(`obj : ${JSON.stringify(obj)}`);
          if (obj.lotcomm === action.item.lotcomm) {
            obj.duree = action.item.duree;
            break;
          }
        }
        return {
          ...state,
          notPlaced: updated,
        };
      }

      // Length to modify is in placed
      foo = [...state.placed].find((x) => x.lotcomm === action.item.lotcomm);
      // console.log(`FOO : ${JSON.stringify(foo)}`);
      updated = [...state.placed];
      if (foo !== undefined) {
        for (const obj of updated) {
          if (obj.lotcomm === action.item.lotcomm) {
            obj.duree = action.item.duree;
            break;
          }
        }
        // If event is placed, adjust length of it in calendar events
        calendarApi = action.calendarRef.current.getApi();
        // console.log("calendar getEvents");
        // console.log(JSON.stringify(calendarApi.getEvents()));
        let eventToModifyInCalendar = calendarApi.getEventById(foo._id);
        // console.log("found");
        // console.log(eventToModifyInCalendar);
        eventToModifyInCalendar.setEnd(
          addMilliseconds(eventToModifyInCalendar.start, action.item.duree)
        );
        let cn = `fc-event-${action.item.duree / (1800 * 1000)}`;
        if (action.item.duree / (1800 * 1000) === 1.5) cn = `fc-event-15`;

        eventToModifyInCalendar.setProp("classNames", cn);
        return {
          ...state,
          placed: updated,
        };
      }

    case "MODIFY_LENGTH_MULTIPLE":
      newNotPlaced = [...state.notPlaced].map((_) => ({
        ..._,
        duree: action.mTimes[_.typologie],
      }));
      newPlaced = [...state.placed].map((_) => ({
        ..._,
        duree: action.mTimes[_.typologie],
      }));

      if (action.calendarRef.current !== undefined) {
        // If event is placed, adjust length of it in calendar events
        calendarApi = action.calendarRef.current.getApi();

        newPlaced.forEach((placed) => {
          let eventToModifyInCalendar = calendarApi.getEventById(placed._id);
          eventToModifyInCalendar.setEnd(
            addMilliseconds(eventToModifyInCalendar.start, placed.duree)
          );
          let cn = `fc-event-${placed.duree / (1800 * 1000)}`;
          if (placed.duree / (1800 * 1000) === 1.5) cn = `fc-event-15`;
          eventToModifyInCalendar.setProp("classNames", cn);
        });
      }

      return {
        ...state,
        placed: newPlaced,
        notPlaced: newNotPlaced,
      };

    case "REMOVE_EVENT":
      let out = [...state.placed].find(
        (x) => x.lotcomm === action.info.event.extendedProps.lotcomm
      );

      let anewPlaced = [...state.placed].filter(
        (x) => x.lotcomm !== action.info.event.title
      );

      let anewNotPlaced = [...state.notPlaced];
      anewNotPlaced.push(out);
      anewNotPlaced = anewNotPlaced.sort(function (a, b) {
        return a.lotcomm.localeCompare(b.lotcomm);
      });

      let anewFilter = filterByKeys(anewNotPlaced, state.value);

      if (state.checkList.length === 0) {
        newSubFilterEvents = anewNotPlaced;
        var newCheckList = [];
      } else {
        let tmp = anewNotPlaced.filter((s) =>
          state.checkList.includes(s[state.value])
        );
        if (tmp.length !== 0) {
          newSubFilterEvents = tmp;
          var newCheckList = state.checkList;
        } else {
          newSubFilterEvents = anewNotPlaced;
          var newCheckList = [];
        }
      }

      // Suppression du rdv dans le calendrier
      calendarApi = action.calendarRef.current.getApi();
      let rdv = calendarApi.getEventById(action.info.event.id);
      rdv.remove();

      return {
        ...state,
        placed: anewPlaced,
        notPlaced: anewNotPlaced,
        filterSet: Object.keys(anewFilter),
        checkList: newCheckList,
        subFilterEvents: newSubFilterEvents,
      };

    case "ADD_EVENT":
      newPlaced = [...state.placed].concat(
        [...state.notPlaced].filter(
          (x) => x.lotcomm === action.info.event.title
        )
      );
      newNotPlaced = [...state.notPlaced].filter(
        (x) => x.lotcomm !== action.info.event.title
      );
      let newFilter = filterByKeys(newNotPlaced, state.value);

      if (state.checkList.length === 0) {
        newSubFilterEvents = newNotPlaced;
        var newCheckList = [];
      } else {
        let tmp = newNotPlaced.filter((s) =>
          state.checkList.includes(s[state.value])
        );
        if (tmp.length !== 0) {
          newSubFilterEvents = tmp;
          var newCheckList = state.checkList;
        } else {
          newSubFilterEvents = newNotPlaced;
          var newCheckList = [];
        }
      }
      return {
        ...state,
        placed: newPlaced,
        notPlaced: newNotPlaced,
        filterSet: Object.keys(newFilter),
        checkList: newCheckList,
        subFilterEvents: newSubFilterEvents,
      };

    case "SELECT_FILTER_KEY":
      let newFilter2 = filterByKeys(state.notPlaced, action.value);
      return {
        ...state,
        filterSet: Object.keys(newFilter2),
        subFilterEvents: state.notPlaced,
        checkList: [],
        value: action.value,
      };

    case "SELECT_FILTER_VALUES":
      if (action.checkList.length === 0) {
        newSubFilterEvents = state.notPlaced;
      } else {
        let tmp = [...state.notPlaced].filter((s) =>
          action.checkList.includes(s[state.value])
        );
        if (tmp.length !== 0) {
          newSubFilterEvents = tmp;
        } else {
          newSubFilterEvents = [...state.notPlaced];
        }
      }
      return {
        ...state,
        checkList: action.checkList,
        subFilterEvents: newSubFilterEvents,
      };

    case "SORT_ASCENDING_FILTER_VALUES":
      return {
        ...state,
        subFilterEvents: state.subFilterEvents.sort((a, b) =>
          a.lotcomm.localeCompare(b.lotcomm)
        ),
      };

    case "SORT_DESCENDING_FILTER_VALUES":
      return {
        ...state,
        subFilterEvents: state.subFilterEvents.sort((a, b) =>
          b.lotcomm.localeCompare(a.lotcomm)
        ),
      };

    default:
      return state;
  }
};

export { initReducer, calendarEventsReducer as default };
