import { App, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import { getBasicAuthConfig } from "../../utils";
import axios from "axios";

const { Title } = Typography;

const Chantier = () => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState([]);

  const fetchHousing = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/users/me/housings/${localStorage.getItem(
            "currentHousingId"
          )}/program`,
        getBasicAuthConfig()
      );
      console.log(res.data);
      setProgram(res.data);
    } catch (e) {
      console.log(e);
      message.error(`Erreur lors du chargement des documents`);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchHousing();
      setIsLoading(false);
    };
    newFunc();
  }, []);
  return (
    <ContentContainer>
      <Title level={4}>Informations chantier</Title>
      {!isLoading && JSON.stringify(program)}
    </ContentContainer>
  );
};

export default Chantier;
