import React, { useEffect } from "react";
import selectClientTotalTime from "../selectors/clients-total-time";

const ClientListSummary = ({
  clients,
  meetingTimes,
  clientHours,
  setClientHours,
}) => {
  useEffect(() => {
    setClientHours(totalTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  let totalTime = undefined;
  if (clients && clients.length !== 0 && meetingTimes) {
    totalTime = selectClientTotalTime(clients, meetingTimes);
  }

  if (totalTime === 0) {
    totalTime = undefined;
  }

  return (
    <div>
      Nécessaire : {totalTime || "?"} {totalTime && " H"}
    </div>
  );
};

export default ClientListSummary;
