import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const PublicFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        opacity: 0.5,
        fontSize: 13,
        fontFamily:
          "SF Regular, Segoe System UI Regular, Segoe UI Regular, sans-serif",
      }}
    >
      Bridgit ©2024
    </Footer>
  );
};

export default PublicFooter;
