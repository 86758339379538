import React, { useState } from "react";
import { App, Modal, Button, Select, Form } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";
const { Option } = Select;

const RemoveCustomerModal = ({
  show,
  setShow,
  fetchPlanningAndFmtEvents,
  planning,
  isLoading,
  setIsLoading,
}) => {
  const { message } = App.useApp();
  const [selected, setSelected] = useState(undefined);
  const [isVerifLoading, setIsVerifLoading] = useState(false);
  const [form] = Form.useForm();

  const onRemoveCustomer = async (values) => {
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          `/rdv/${planning._id}/remove-customer`,
        {
          rdvId: selected._id,
          guestId: selected.guest._id,
          duree: selected.length / (planning.pas * 1000),
        },
        getRdvApiAuth()
      );
      // console.log(`RES DE REMOVE CUSTOMER -> ${JSON.stringify(res)}`);
      form.resetFields();
      setIsVerifLoading(false);
      setShow(false);
      setSelected(undefined);
      await fetchPlanningAndFmtEvents();
      setIsLoading(true);
      // TODO : Lorsqu'on rouvre ajout personne au planning elle
      // n'apparait pas, besoin de refetcher le planning avec isLoading
      // setIsLoading(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(`${e.response.data.message}`);
      } else {
        message.error(
          `Erreur lors de la suppression de l'acquéreur du planning.`
        );
      }
      form.resetFields();
      setIsVerifLoading(false);
      setShow(false);
      setSelected(undefined);
    }
  };

  const verifBackendAlgo = async () => {
    setIsVerifLoading(true);
    try {
      form
        .validateFields()
        .then((values) => {
          onRemoveCustomer(values);
        })
        .catch((info) => {
          // console.log("La validation des champs a échoué:", info);
          setIsVerifLoading(false);
        });
    } catch (e) {
      console.log(e);
      if (e.response) {
        message.error(`${e.response.data.message}`);
      } else {
        message.error(
          "Erreur lors du test de la suppression de l'acquéreur au planning (2)"
        );
      }
    }
  };

  const onChange = (value) => {
    setSelected(planning.broadcastList.find((_) => _._id === value));
  };

  return (
    <Modal
      title="Suppression d'un acquéreur au planning"
      style={{ top: 40 }}
      open={show}
      onCancel={() => {
        setShow(false);
        form.resetFields();
        setSelected(undefined);
      }}
      width={1000}
      footer={[
        <Button
          key="annuler"
          onClick={() => {
            setShow(false);
            form.resetFields();
            setSelected(undefined);
          }}
        >
          Annuler
        </Button>,
        <Button
          key="verifier"
          type="primary"
          loading={isVerifLoading}
          onClick={verifBackendAlgo}
        >
          Confirmer la suppression
        </Button>,
      ]}
    >
      <div>
        <p>{`${
          planning.broadcastList.filter((_) => !_.isBooked).length
        } acquéreurs en attente ont été trouvés pour le planning ${
          planning.name
        }.`}</p>
        <p>
          Sélectionnez dans la liste ci-dessous l'acquéreur que vous souhaitez
          enlever du planning.
        </p>
        <p>
          Si la personne que vous souhaiter enlever a déjà pris rendez-vous,
          veuillez prélablement l'annuler.
        </p>

        <Form form={form}>
          <Form.Item
            name="selected"
            label="Invité"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner un invité !",
              },
            ]}
          >
            <Select
              placeholder="Sélectionnez un invité"
              onChange={onChange}
              showSearch
              optionFilterProp="label"
            >
              {planning.broadcastList
                .filter((_) => !_.isBooked)
                .map((rdv) => {
                  return (
                    <Option
                      key={rdv._id}
                      value={rdv._id}
                      label={`[${rdv.guest.lotcomm}] ${rdv.guest.nom}`}
                    >
                      {`[${rdv.guest.lotcomm}] ${rdv.guest.nom}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default RemoveCustomerModal;
