import React from "react";
import { Button } from "antd";

const PublicHome = () => {
  const startLogin = () => {
    // console.log("Login please !");
  };
  // const text = <span>information@bridg-it.fr</span>;
  return (
    <div className="landing-layout">
      <div className="landing-layout__box">
        <svg
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="96px"
          viewBox="0 0 84.17 24.82"
        >
          <title>Bridgit</title>
          <path
            className="cls-2"
            d="M264.59,408.59a11.76,11.76,0,0,1,2.54.25,5.42,5.42,0,0,1,2,.8,3.76,3.76,0,0,1,1.27,1.49,5.22,5.22,0,0,1,.45,2.29,4.29,4.29,0,0,1-.66,2.46,4.88,4.88,0,0,1-2,1.61,4.72,4.72,0,0,1,2.69,1.82,6,6,0,0,1,.31,5.68,5,5,0,0,1-1.54,1.76,7.08,7.08,0,0,1-2.21,1,9.92,9.92,0,0,1-2.56.32h-9.44V408.59Zm-.55,7.89a3.11,3.11,0,0,0,1.89-.54,2.08,2.08,0,0,0,.73-1.78,2.32,2.32,0,0,0-.24-1.12,1.73,1.73,0,0,0-.66-.68,2.69,2.69,0,0,0-.94-.34,5.63,5.63,0,0,0-1.1-.1h-4v4.56Zm.25,8.27a5.81,5.81,0,0,0,1.2-.12,2.94,2.94,0,0,0,1-.41,2.12,2.12,0,0,0,.7-.78,2.67,2.67,0,0,0,.26-1.25,2.55,2.55,0,0,0-.85-2.15,3.65,3.65,0,0,0-2.24-.64H259.7v5.35Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M277.64,414v2.62h.05a4.85,4.85,0,0,1,.74-1.21,4.64,4.64,0,0,1,1.07-1,5,5,0,0,1,1.28-.61,4.49,4.49,0,0,1,1.42-.22,3,3,0,0,1,.84.13v3.61c-.18,0-.4-.07-.65-.1s-.5,0-.74,0a4.07,4.07,0,0,0-1.8.36,3.07,3.07,0,0,0-1.19,1A3.89,3.89,0,0,0,278,420a7.63,7.63,0,0,0-.19,1.77v6.36H274V414Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M284.41,411.79v-3.2h3.88v3.2Zm3.88,2.18v14.11h-3.88V414Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M301.09,426.28a3.89,3.89,0,0,1-1.79,1.65,5.91,5.91,0,0,1-2.5.51,6.15,6.15,0,0,1-2.78-.61,5.64,5.64,0,0,1-2-1.67,7.46,7.46,0,0,1-1.17-2.42,9.9,9.9,0,0,1-.4-2.84,9.32,9.32,0,0,1,.4-2.74,7,7,0,0,1,1.17-2.33,5.66,5.66,0,0,1,4.68-2.24,5.6,5.6,0,0,1,2.39.53,4.2,4.2,0,0,1,1.79,1.57H301v-7.1h3.88v19.49h-3.69v-1.8Zm-.16-7a4.18,4.18,0,0,0-.56-1.42,3.28,3.28,0,0,0-1-1,3,3,0,0,0-1.58-.38,3.2,3.2,0,0,0-1.61.38,3,3,0,0,0-1,1,4.43,4.43,0,0,0-.58,1.43,8.4,8.4,0,0,0-.17,1.68,7,7,0,0,0,.19,1.64,4.55,4.55,0,0,0,.61,1.46,3.32,3.32,0,0,0,1.07,1,2.89,2.89,0,0,0,1.54.4,3,3,0,0,0,1.6-.39,2.85,2.85,0,0,0,1-1,4.56,4.56,0,0,0,.55-1.46,8.57,8.57,0,0,0,.16-1.69A8.26,8.26,0,0,0,300.93,419.31Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M320.81,429.12a4.73,4.73,0,0,1-1,2,6,6,0,0,1-2.21,1.59,9.51,9.51,0,0,1-3.88.66,9.24,9.24,0,0,1-2.12-.26,6.54,6.54,0,0,1-2-.81,4.94,4.94,0,0,1-1.48-1.4,4.06,4.06,0,0,1-.66-2.06h3.85a2.2,2.2,0,0,0,1.06,1.51,3.86,3.86,0,0,0,1.83.42,2.8,2.8,0,0,0,2.39-1,3.82,3.82,0,0,0,.72-2.48v-1.86h-.05a3.82,3.82,0,0,1-1.79,1.65,5.75,5.75,0,0,1-2.44.54,6.26,6.26,0,0,1-2.74-.56,5.33,5.33,0,0,1-1.88-1.53,6.45,6.45,0,0,1-1.08-2.27,11.06,11.06,0,0,1-.34-2.74,8.66,8.66,0,0,1,.4-2.64,6.91,6.91,0,0,1,1.16-2.24,5.61,5.61,0,0,1,1.9-1.54,5.69,5.69,0,0,1,2.6-.57,5.45,5.45,0,0,1,2.45.52,4.2,4.2,0,0,1,1.76,1.74h.05V414h3.69v13.21A9.14,9.14,0,0,1,320.81,429.12Zm-5.31-4.69a3,3,0,0,0,1-.88,3.8,3.8,0,0,0,.62-1.24,5.36,5.36,0,0,0,.2-1.46,8,8,0,0,0-.16-1.65,4,4,0,0,0-.55-1.38,2.82,2.82,0,0,0-1-1,3.27,3.27,0,0,0-1.58-.35,2.78,2.78,0,0,0-2.39,1.21,3.77,3.77,0,0,0-.57,1.29,6.18,6.18,0,0,0-.18,1.51,7.76,7.76,0,0,0,.15,1.52,4,4,0,0,0,.52,1.35,2.93,2.93,0,0,0,1,1,2.7,2.7,0,0,0,1.5.38A3.15,3.15,0,0,0,315.5,424.43Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M329,414l-3,14.11h-3.88l3-14.11Zm-3.47-2.18.71-3.2h3.91l-.71,3.2Z"
            transform="translate(-255.42 -408.59)"
          />
          <path
            className="cls-2"
            d="M339.58,414l-.6,2.59h-2.75l-1.45,7a2.35,2.35,0,0,0-.08.4,1.61,1.61,0,0,0,0,.25,1.16,1.16,0,0,0,.12.59.89.89,0,0,0,.32.32.94.94,0,0,0,.42.12H336a10.59,10.59,0,0,0,1.39-.08l-.65,3-1.2.09c-.41,0-.81,0-1.23,0a7.53,7.53,0,0,1-1.35-.12,3.64,3.64,0,0,1-1.21-.44,2.31,2.31,0,0,1-.84-.86,2.61,2.61,0,0,1-.29-1.42,3.58,3.58,0,0,1,.06-.61c0-.23.08-.47.13-.72l1.59-7.57h-2.54l.6-2.59h2.48l.88-4.29h3.87L336.8,414Z"
            transform="translate(-255.42 -408.59)"
          />
        </svg>
        {/* <Tooltip placement="right" title={text}>
          <p>
            🏗️ &nbsp;<i>En développement</i>&nbsp; 🏗️{" "}
          </p>
        </Tooltip> */}
        <a href="/login">
          <Button type="primary" onClick={startLogin}>
            Connexion
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PublicHome;
