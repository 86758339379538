import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  App,
  DatePicker,
  Checkbox,
  Switch,
  Space,
} from "antd";
import axios from "axios";
// import "moment/locale/fr";
import dayjs from "dayjs";
import frFR from "antd/locale/fr_FR";
// import moment from "moment";
import { getVisitesApiAuth } from "../utils";
import validator from "validator";
import { nanoid } from "nanoid";

// moment.locale("fr");
const { Option } = Select;

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  promoterList,
}) => {
  const { message } = App.useApp();
  const [isIdInput, setIsIdInput] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";

  const onChange = () => {
    // console.log("onChange Switch");
    setIsIdInput(!isIdInput);
  };

  const onAddVisit = async () => {
    // console.log(`valid async`);
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        message.error(
          "Formulaire invalide, veuillez vérifier les données entrées"
        );
        console.log("La validation des champs a échoué:", info);
      });
  };

  return (
    <Modal
      open={visible}
      title="Publier une nouvelle visite virtuelle"
      okText="Publier"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={onAddVisit}
    >
      <br />
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal2"
        initialValues={{
          modifier: "public",
          startDate: dayjs(),
          endDate: dayjs().add(7, "days"),
        }}
      >
        <Form.Item
          name="name"
          label="Nom du client"
          rules={[
            {
              required: true,
              message: "Veuillez saisir un nom",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="promoter"
          label="Promoteur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner un promoteur !",
            },
          ]}
        >
          <Select
            placeholder="Sélectionnez un promoteur"
            optionFilterProp="label"
            showSearch
          >
            {promoterList.map((x, idx) => {
              return (
                <Option
                  key={"pt-Id" + idx}
                  value={x._id}
                  label={
                    x.internalName ? `${x.internalName} (${x.name})` : x.name
                  }
                >
                  {x.internalName ? `${x.internalName} (${x.name})` : x.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name="startDate" label="Début">
          <DatePicker format={dateFormat} />
        </Form.Item>

        <Form.Item name="endDate" label="Fin">
          <DatePicker format={dateFormat} />
        </Form.Item>

        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>

        <Form.Item
          name="pointer"
          label="Nom Matterport"
          rules={[
            {
              required: true,
              message: "Veuillez saisir un nom Matterport",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="payload"
          label="Lien"
          rules={[
            {
              validator: async (_, payload) => {
                if (
                  !payload ||
                  !payload.startsWith("https://my.matterport.com/show/?m=")
                ) {
                  return Promise.reject(
                    new Error(`Le lien matterport n'est pas valide.`)
                  );
                }
              },
              required: true,
              message:
                "Veuillez saisir un lien matterport valide (https://my.matterport ...)",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Space>
          <Switch onChange={onChange} />
          Spécifier un id
        </Space>
        <br />
        <br />
        <Form.Item
          name="uuid"
          label="Id unique de la visite"
          initialValue={nanoid(9)}
          rules={[
            {
              required: true,
              message: "Veuillez saisir un id unique",
            },
          ]}
        >
          {isIdInput ? <Input /> : <Input disabled />}
        </Form.Item>
        <Form.Item name="sendMail" valuePropName="checked">
          <Checkbox>Envoyer le lien par mail à la personne</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const isListUnique = (list) => {
  const counts = list
    .map((x) => x.trim())
    .reduce(function (acc, curr) {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    }, {});

  return Object.values(counts).every((val) => val === 1);
};

const areLinksValid = (list) => {
  return list.every((val) =>
    val.trim().startsWith("https://my.matterport.com/show/?m=")
  );
};

const areEmailAdressesValid = (list) => {
  return list.every((val) => validator.isEmail(val.trim()));
};

const AddVisit = ({ visible, setVisible, fetchVisitList, promoterList }) => {
  const { message } = App.useApp();

  const onCreate = async (values) => {
    let areMatterportLinksValid = true;
    let areMatterportLinksUnique = true;
    let areEmailsValid = true;
    let areEmailsUnique = true;

    if (values.payload.includes(";")) {
      areMatterportLinksValid = areLinksValid(values.payload.split(";"));
      areMatterportLinksUnique = isListUnique(values.payload.split(";"));
    }

    if (values.email !== undefined && values.email.includes(";")) {
      areEmailsValid = areEmailAdressesValid(values.email.split(";"));
      areEmailsUnique = isListUnique(values.email.split(";"));
    }

    if (
      !(
        areMatterportLinksValid &&
        areMatterportLinksUnique &&
        areEmailsValid &&
        areEmailsUnique
      )
    ) {
      if (!areMatterportLinksValid) {
        message.error("Les liens Matterport ne sont pas valides");
      }
      if (!areMatterportLinksUnique) {
        message.error("Un même lien Matterport est plusieurs fois présent");
      }
      if (!areEmailsValid) {
        message.error("Les adresses emails ne sont pas valides");
      }
      if (!areEmailsUnique) {
        message.error("Une même adresse mail est plusieurs fois présente");
      }
      setVisible(false);
    } else {
      // console.log("Ok pour le formulaire !!");
      try {
        // console.log("Received values of form: ", values);
        const user = {
          // role: values.select,
          ...values,
          sendMail: values.sendMail === undefined ? false : true,
        };
        // console.log("User before sent = " + JSON.stringify(user));
        await axios.post(
          process.env.REACT_APP_VR_API_URL + "/vv",
          user,
          getVisitesApiAuth()
        );
        // console.log(res.data);
        message.success(
          `Nouvel visite pour l'utilisateur ${values.email} créée avec succès.`
        );
        setVisible(false);
        fetchVisitList();
      } catch (e) {
        message.error(
          `Une erreur est apparue lors de la création de la visite. (${e})`
        );
        console.log(`ERROR : ${JSON.stringify(e)}`);
      }
    }
  };

  return (
    <div>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
        promoterList={promoterList}
      />
    </div>
  );
};

export default AddVisit;
