import React, { useState, useEffect } from "react";
import axios from "axios";
import { getBasicAuthConfig } from "../../../utils";
import { Comment } from "@ant-design/compatible";
import { Collapse, Button, Input, Form, Upload, App, List, Space } from "antd";

import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";

const { Panel } = Collapse;
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "message" : "messages"
    }`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Messages = () => {
  const { message } = App.useApp();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (value, ticketId, nb) => {
    console.log(form.getFieldsValue());

    let field = value[ticketId]["reponse"];
    let data = {};
    data["dragger"] = value[ticketId]["dragger"];

    console.log(`TICKET: ${ticketId}, FIELD : ${field}`);

    if (field === undefined || field === "") {
      message.error("Votre réponse est vide");
      return;
    } else {
      if (!value) {
        return;
      }
      setSubmitting(true);
      setTimeout(async () => {
        setSubmitting(false);
        let tmp = await createUserAnswer(data, ticketId, field);
        let newComments = [...comments];
        newComments[nb].push({
          author: localStorage.getItem("username"),
          avatar:
            "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          content: (
            <div>
              <p>{field}</p>
              {tmp.data.answer.files.length > 0 && (
                <div>
                  {tmp.data.answer.files.map((f, idx) => {
                    return (
                      <div key={"pj-" + idx}>
                        <PaperClipOutlined />
                        <a
                          href={process.env.REACT_APP_API_URL + `/file/${f}`}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          {f}
                        </a>
                        &nbsp;&nbsp;&nbsp;
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ),
          datetime: moment().fromNow(),
        });
        setComments(newComments);
        console.log(`GET FILEDS : ${JSON.stringify(form.getFieldsValue())}`);
        form.setFieldsValue({
          [ticketId]: { reponse: "", dragger: [] },
        });
      }, 1000);
    }
  };

  // const writeAnswer = (ticketId) => {
  //   console.log(`Aked to answer : ${ticketId}`);
  //   setSelected(ticketId);
  //   setAnswerMsgModalVisible(true);
  // };

  // const validateMessages = {
  //   required: "Vous devez remplir un ${label}",
  // };

  const createUserAnswer = async (data, ticketId, message) => {
    try {
      let filesIds = [];
      if (data !== undefined || data.dragger !== []) {
        if (data.dragger && data.dragger.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < data.dragger.length; i++) {
            let selectedFile = data.dragger[i].originFileObj;
            let selectedName = data.dragger[i].name;
            formData.append("uploads", selectedFile, selectedName);
          }
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/users/me/answer/upload-files`,
            formData,
            getBasicAuthConfig()
          );
          filesIds = res.data.filesIds;
        }
      }

      const reqObj = {
        files: filesIds,
        message: message,
        ticket: ticketId,
      };

      console.log(`reqObj ; ${JSON.stringify(reqObj)}`);
      const res2 = await axios.post(
        process.env.REACT_APP_API_URL + `/users/me/answer/create`,
        reqObj,
        getBasicAuthConfig()
      );
      console.log(`RETOUR REQUETE II : ${JSON.stringify(res2)}`);
      return res2;
      // const notifObj = {
      //   receivers: selected,
      //   ticket: res2.data.ticket._id,
      // };

      // const res4 = await axios.post(
      //   process.env.REACT_APP_API_URL + `/notification/create`,
      //   notifObj,
      //   getBasicAuthConfig()
      // );

      // console.log(`Return of Notif create : ${JSON.stringify(res4)}`);

      // message.success("Réponse envoyée !");
      // setAnswerMsgModalVisible(false);
    } catch (e) {
      console.log(`Error : ${e}`);
      // message.error(`Erreur : ${e}`);
      // setAnswerMsgModalVisible(false);
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("okrr");
    }, 0);
  };

  useEffect(() => {
    const newFunc = async (housingId) => {
      // console.log("Enter newFunc()");
      // const res = await axios.get(
      //   process.env.REACT_APP_API_URL +
      //     `/programs/` +
      //     localStorage.getItem("currentProgramId") +
      //     `/housings/` +
      //     housingId +
      //     `/tickets`,
      //   getBasicAuthConfig()
      // );
      // setTickets(res.data.tickets);
      setTickets([]);
      let originComments = [];
      // res.data.tickets.forEach((ticket, i) => {
      //   console.log(
      //     `ticket ${i} : ${JSON.stringify(ticket)} answers (${
      //       ticket.answers.length
      //     }) (${JSON.stringify(ticket.answers)})`
      //   );

      //   originComments[i] = [
      //     {
      //       author: `${
      //         ticket.sender.lastname
      //       } ${ticket.sender.firstname.toUpperCase()}`,
      //       avatar:
      //         "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      //       content: (
      //         <div>
      //           <p>{ticket.message}</p>
      //           {ticket.files.length > 0 && (
      //             <div style={{ display: "inline-block" }}>
      //               {ticket.files.map((f, idx) => {
      //                 return (
      //                   <div key={"pj-" + idx}>
      //                     <PaperClipOutlined />
      //                     <a
      //                       href={
      //                         process.env.REACT_APP_API_URL + `/file/${f._id}`
      //                       }
      //                       download
      //                       target="_blank"
      //                       rel="noreferrer"
      //                     >
      //                       {f.metadata.originalname}
      //                     </a>
      //                     &nbsp;&nbsp;&nbsp;
      //                   </div>
      //                 );
      //               })}
      //             </div>
      //           )}
      //         </div>
      //       ),
      //       datetime: moment(ticket.updatedAt).fromNow(),
      //     },
      //   ];

      //   if (ticket.answers.length > 0) {
      //     // console.log('more than one')

      //     for (let ans = 0; ans < ticket.answers.length; ans++) {
      //       originComments[i].push({
      //         author: `${ticket.answers[ans].sender[0].firstname}`,
      //         avatar:
      //           "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      //         content: (
      //           <div>
      //             <p>{ticket.answers[ans].message}</p>
      //             {ticket.answers[ans].files.length > 0 && (
      //               <div>
      //                 {ticket.answers[ans].files.map((f, idx) => {
      //                   return (
      //                     <div key={"pj-" + idx}>
      //                       <PaperClipOutlined />
      //                       <a
      //                         href={
      //                           process.env.REACT_APP_API_URL + `/file/${f._id}`
      //                         }
      //                         download
      //                         target="_blank"
      //                         rel="noreferrer"
      //                       >
      //                         {f.metadata.originalname}
      //                       </a>
      //                       &nbsp;&nbsp;&nbsp;
      //                     </div>
      //                   );
      //                 })}
      //               </div>
      //             )}
      //           </div>
      //         ),
      //         datetime: moment(ticket.answers[ans].updatedAt).fromNow(),
      //       });
      //     }
      //   }
      // });

      // console.log('ORIGIN COMMENTS')
      // console.log(originComments);
      setComments(originComments);

      // let housingsNotifications = res.data.housing;
    };
    let pathname = window.location.pathname.split("/");
    let pathnameLen = pathname.length;
    let hId = undefined;
    if (window.location.pathname.endsWith("/")) {
      hId = pathname[pathnameLen - 2];
    } else {
      hId = pathname[pathnameLen - 1];
    }
    // setHousingId(hId)
    newFunc(hId);
    setHasLoaded(true);
  }, []);
  const onFinish = (values) => {
    console.log(values);
  };
  // return <div>OKOK</div>;
  return (
    <div>
      {hasLoaded && (
        <div>
          <Collapse accordion>
            {tickets.map((ticket, idx) => {
              return (
                <Panel
                  key={"ticket-" + idx}
                  header={`${ticket.subject} (${
                    ticket.answers.length > 0 ? "pour tous" : "pour vous"
                  })`}
                  extra={
                    <PaperClipOutlined
                    // onClick={(event) => {
                    //   // If you don't want click extra trigger collapse, you can prevent this:
                    //   console.log("clicked !!");
                    //   event.stopPropagation();
                    // }}
                    />
                  }
                >
                  <CommentList comments={comments[idx]} />

                  <Form form={form} onFinish={onFinish}>
                    <Form.Item name={[ticket._id, "reponse"]}>
                      <TextArea rows={4} />
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        verticalAlign: "center",
                      }}
                    >
                      <Space align="center">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            loading={submitting}
                            // value={ticket._id}
                            onClick={(e) =>
                              handleSubmit(
                                form.getFieldsValue(),
                                ticket._id,
                                idx
                              )
                            }
                            type="primary"
                          >
                            Répondre
                          </Button>
                        </Form.Item>
                        <Form.Item
                          name={[ticket._id, "dragger"]}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          style={{ verticalAlign: "center" }}
                        >
                          <Upload
                            name={[ticket._id, "files"]}
                            customRequest={dummyRequest}
                            accept="image/png, image/jpeg, image/jpg"
                          >
                            <Button icon={<UploadOutlined />}></Button>
                          </Upload>
                        </Form.Item>
                      </Space>
                    </div>
                  </Form>
                </Panel>
              );
            })}
          </Collapse>
          <br />
        </div>
      )}
    </div>
  );
};

export default Messages;
