import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import { Tooltip } from "antd";

const AgendaReadOnlyNoOptions = ({
  eventSources,
  showChildrenDrawer,
  simulationTime,
  calendarRef,
}) => {
  // const calendarRef = useRef();

  let startDate = undefined;
  if (eventSources[0].events.length !== 0) {
    startDate = moment(
      new Date(Math.min(...eventSources[0].events.map((x) => x.start)))
    ).format("yyyy-MM-DD");
  } else {
    startDate = Date.now();
  }

  // console.log(`startDate`);
  // console.log(startDate);

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <div>&nbsp;</div>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="customTopButton">
        <div className="customCalendarEvent">
          <FullCalendar
            ref={calendarRef}
            locale={frLocale}
            allDaySlot={false}
            eventContent={(arg) => {
              return (
                <Tooltip
                  title={arg.event.extendedProps.description}
                  // arrow
                >
                  {renderInnerContent(arg)}
                </Tooltip>
              );
            }}
            // viewDidMount={(view) => {
            //   const customTitle = document.querySelector(".fc-toolbar-title");

            //   ReactDOM.render(
            //     <Tag color="success">
            //       Temps de la simulation : {simulationTime} secondes
            //     </Tag>,
            //     customTitle
            //   );
            // }}
            // frLocale has been taken off because otherwise it would show utc times
            // timeZone={frLocale}
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            initialDate={startDate}
            slotMinTime="08:00:00"
            slotMaxTime="20:00:00"
            height="auto"
            customButtons={{
              choixButton: {
                text: "Choix",
                click: function () {
                  showChildrenDrawer();
                },
              },
            }}
            headerToolbar={{
              // left: "prev,next choixButton",
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            selectable={false}
            editable={false}
            eventSources={eventSources}
          />
        </div>
      </div>
    </div>
  );
};

export default AgendaReadOnlyNoOptions;
