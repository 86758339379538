import React from "react";
import { Histogram } from "@ant-design/charts";

const VisitLogsHistogramHours = ({ logs }) => {
  var config = {
    data: logs,
    binField: "value",
    binWidth: 1,
    tooltip: {
      showMarkers: false,
      position: "top",
    },
    // label: {
    // position: "left",
    // style: {
    //   fill: "#FFFFFF",
    //   opacity: 0.6,
    // },
    // },
    interactions: [{ type: "element-highlight" }],
    meta: {
      range: {
        min: 0,
        max: 24,
        tickInterval: 1,
      },
      count: {
        nice: true,
      },
    },
    color: "#436386",
  };

  return (
    <div>
      <Histogram {...config} />
    </div>
  );
};

export default VisitLogsHistogramHours;
