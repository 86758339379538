import React from "react";
import { message, Modal, Form, Input, Select } from "antd";
import axios from "axios";
import {
  getRdvApiAuth,
  hasFieldToBeProvided,
  isFieldOk,
  customTags,
} from "../../../utils";

const { Option } = Select;

const ModifyContactModal = ({
  show,
  setShow,
  planning,
  setPlanning,
  form,
  usedBalises,
  setUsedBalises,
}) => {
  const onCreate = async (values) => {
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        values,
        getRdvApiAuth()
      );

      let bigStr =
        values.contactTitre +
        values.contactNom +
        values.contactPrenom +
        values.contactTelephone +
        values.contactMail +
        values.signature;

      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));

      // Please not that usedBalises are only the one that matters for Contact, not all !
      setUsedBalises(balises);
      setPlanning({ ...planning, ...values });
      setShow(false);
      message.success(
        `Personne Contact correctement mise à jour pour ce planning`
      );
    } catch (e) {
      console.log(e);
    }
  };

  const update = async () => {
    let fieldsArray = [
      "contactTitre",
      "contactNom",
      "contactPrenom",
      "contactTelephone",
      "contactMail",
      "signature",
    ];

    try {
      let values = await form.validateFields(fieldsArray);
      onCreate(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      title="Mise à jour des informations sur la personne de Contact"
      style={{ top: 40 }}
      open={show}
      onOk={update}
      onCancel={() => {
        setShow(false);
        form.setFieldsValue({
          contactTitre: planning.contactTitre,
          contactNom: planning.contactNom,
          contactPrenom: planning.contactPrenom,
          contactTelephone: planning.contactTelephone,
          contactMail: planning.contactMail,
          signature: planning.signature,
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          form={form}
          name="contactTitre"
          label="Titre"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("contactTitre", payload, usedBalises)) {
                  return Promise.reject(new Error(`Ce champ est à remplir !`));
                }
              },
              required: hasFieldToBeProvided("contactTitre", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Select>
            <Option value="Monsieur">Monsieur</Option>
            <Option value="Madame">Madame</Option>
            <Option value="Autre">Autre</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nom"
          name="contactNom"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("contactNom", payload, usedBalises)) {
                  return Promise.reject(new Error(`Ce champ est à remplir !`));
                }
              },
              required: hasFieldToBeProvided("contactNom", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Prénom"
          name="contactPrenom"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("contactPrenom", payload, usedBalises)) {
                  return Promise.reject(new Error(`Ce champ est à remplir !`));
                }
              },
              required: hasFieldToBeProvided("contactPrenom", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="contactMail"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("contactMail", payload, usedBalises)) {
                  return Promise.reject(new Error(`Ce champ est à remplir !`));
                }
              },
              required: hasFieldToBeProvided("contactMail", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Téléphone"
          name="contactTelephone"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("contactTelephone", payload, usedBalises)) {
                  return Promise.reject(
                    new Error(`Ce champ est à remplir !11`)
                  );
                }
              },
              required: hasFieldToBeProvided("contactTelephone", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Signature"
          name="signature"
          rules={[
            {
              validator: async (_, payload) => {
                if (!isFieldOk("signature", payload, usedBalises)) {
                  return Promise.reject(new Error(`Ce champ est à remplir !1`));
                }
              },
              required: hasFieldToBeProvided("signature", usedBalises),
              message: "Ce champ est à remplir !",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModifyContactModal;
