import React, { useEffect } from "react";
import ContentContainer from "../../components/ContentContainer";
import { Typography, Skeleton } from "antd";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const Home = () => {
  useEffect(() => {
    localStorage.removeItem("currentProgram");
    localStorage.removeItem("currentProgramId");
  }, []);
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Page Accueil Promoteur - En développement"
          avatar={{
            src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
          }}
        />
        <Skeleton />
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Home;
