import React, { useState, useEffect } from "react";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import CreateInvoiceEntityModal from "../../components/admin/modals/CreateInvoiceEntityModal";
import { getBasicAuthConfig, errorCatcher, getRdvApiAuth } from "../../utils";
import { Typography, Skeleton, Dropdown, Button } from "antd";
import InvoiceEntitiesTable from "../../components/admin/InvoiceEntitiesTable";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const AdminFacturationEntities = () => {
  const [showCreateEntity, setShowCreateEntity] = useState(false);
  const [clients, setClients] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const DropdownMenu = () => {
    let items = [
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              setShowCreateEntity(true);
            }}
          >
            Entité
          </a>
        ),
        key: "1",
      },
      // {
      //   label: (
      //     <Link to={"/projets/" + program._id + "/plannings/nouveau-impose"}>
      //       Imposé
      //     </Link>
      //   ),
      //   key: "2",
      // },
    ];

    return (
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomRight"
      >
        <Button type="primary" ghost>
          Créer
        </Button>
      </Dropdown>
    );

    return (
      <Dropdown.Button key="more" menu={{ items }}>
        Créer
      </Dropdown.Button>
    );
  };

  useEffect(() => {
    const newFunc = async () => {
      try {
        // Get authorized templates
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/clients",
          getBasicAuthConfig()
        );
        // console.log(res.data);

        const res2 = await axios.get(
          process.env.REACT_APP_RDV_API_URL + "/plannings-invoice",
          getRdvApiAuth
        );
        let entitiesCounter = res2.data.plannings;

        // console.log(entitiesCounter);
        let clts = [];
        res.data.forEach((c) => {
          // console.log(entitiesCounter[c._id]);
          let counter = 0;
          let id = JSON.stringify(c._id);
          // console.log(
          //   `c._id : ${c._id}, entitiesCounter[c._id] : ${entitiesCounter[id]}`
          // );
          if (entitiesCounter[id] !== undefined) {
            counter = parseInt(entitiesCounter[id]);
          }
          clts.push({
            ...c,
            counter,
          });
        });
        setClients(clts);
        setPromoters(
          Array.from(
            new Set(
              clts
                .filter((x) => x.promoter !== undefined && x.promoter !== "")
                .map((_) => _.promoter)
            )
          )
        );
      } catch (e) {
        errorCatcher(e, `Erreur lors du chargement des entités de facturation`);
      }
      // setRefreshTemplates(false);
      // setIsLoading(false);
    };
    newFunc();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoading) {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            title="Facturation - Entitiés"
            extra={[<DropdownMenu key="more" />]}
          />
          <InvoiceEntitiesTable clients={clients} />
          <CreateInvoiceEntityModal
            show={showCreateEntity}
            setShow={setShowCreateEntity}
            clients={clients}
            setClients={setClients}
            promoters={promoters}
          />
        </SubContentContainer>
      </ContentContainer>
    );
  } else {
    return null;
  }
};

export default AdminFacturationEntities;
