/* eslint-disable array-callback-return */
import {
  Col,
  DatePicker,
  Empty,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Typography,
  // TimePicker,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import RdvsLogsHistogram from "../../components/admin/RdvsLogsHistogram";
import { PageHeader } from "@ant-design/pro-layout";
import { getBasicAuthConfig } from "../../utils";

const { Title } = Typography;

const { RangePicker } = DatePicker;
// const { RangePicker } = TimePicker;

const AdminLogsTrends = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]); // les rdvs pour le graphique
  const [rdvsLogs, setRdvsLogs] = useState([]); // tout les rdvs de la base de données avec les dates vides
  // const [rdvsLogsMovingAverage, setRdvsLogsMovingAverage] = useState([]); // tout les rdvs avec le calcul de la moyenne mobile et les dates vides
  // const [typeValue, setTypeValue] = useState("promoter");
  // const [periodValue, setPeriodValue] = useState("day");
  // const [totalMonths, setTotalMonths] = useState(0);
  // const [rangeValue, setRangeValue] = useState([
  //   moment().subtract(1, "year"),
  //   moment(),
  // ]);
  // const [totalRdvsSelected, setTotalRdvsSelected] = useState(0);
  // const [averageOrMedian, setAverageOrMedian] = useState("movingAverage");

  // useEffect(() => {
  //   const myFunction = async () => {
  //     const resLogs = await axios.get(
  //       process.env.REACT_APP_RDV_API_URL + "/rdv/logs",
  //       getBasicAuthConfig()
  //     );

  //     if (resLogs.length === 0 || resLogs.data.logs.length === 0) {
  //       setIsLoading(false);
  //       return;
  //     }

  //     resLogs.data.logs.forEach((element) => {
  //       element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
  //     });

  //     const rdvsMovingAverage = calculateRdvsWithMovingAverage(
  //       resLogs.data.logs
  //     );

  //     const dailyMedian = [];
  //     const weeklyMedian = [];
  //     const monthlyMedian = [];
  //     const trimesterMedian = [];
  //     const semesterMedian = [];
  //     const yearlyMedian = [];

  //     const dailyCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         const day = curr.createdAt.substring(0, 10);
  //         if (acc[day]) {
  //           acc[day] += curr.count;
  //         } else {
  //           acc[day] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("dailyCount :>> ", dailyCount);

  //     const weeklyCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         const week = moment(curr.createdAt, "YYYY-MM-DD").format("YYYY-WW");
  //         if (acc[week]) {
  //           acc[week] += curr.count;
  //         } else {
  //           acc[week] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("weeklyCount :>> ", weeklyCount);

  //     const monthlyCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         const month = curr.createdAt.substring(0, 7);
  //         if (acc[month]) {
  //           acc[month] += curr.count;
  //         } else {
  //           acc[month] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("monthlyCount :>> ", monthlyCount);

  //     const trimesterCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         let trimester;
  //         const month = curr.createdAt.substring(5, 7);
  //         const year = curr.createdAt.substring(0, 4);
  //         if (month >= 1 && month <= 4) {
  //           trimester = year + "-T1";
  //         } else if (month >= 5 && month <= 8) {
  //           trimester = year + "-T2";
  //         } else if (month >= 9 && month <= 12) {
  //           trimester = year + "-T3";
  //         }
  //         if (acc[trimester]) {
  //           acc[trimester] += curr.count;
  //         } else {
  //           acc[trimester] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("trimesterCount :>> ", trimesterCount);

  //     const semesterCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         let semester;
  //         const month = curr.createdAt.substring(5, 7);
  //         const year = curr.createdAt.substring(0, 4);
  //         if (month >= 1 && month <= 6) {
  //           semester = year + "-S1";
  //         } else if (month >= 7 && month <= 12) {
  //           semester = year + "-S2";
  //         }

  //         if (acc[semester]) {
  //           acc[semester] += curr.count;
  //         } else {
  //           acc[semester] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("semesterCount :>> ", semesterCount);

  //     const yearlyCount = Object.entries(
  //       rdvsMovingAverage.reduce((acc, curr) => {
  //         const year = curr.createdAt.substring(0, 4);
  //         if (acc[year]) {
  //           acc[year] += curr.count;
  //         } else {
  //           acc[year] = curr.count;
  //         }
  //         return acc;
  //       }, {})
  //     ).map(([date, count]) => ({ date, count }));
  //     // console.log("yearlyCount :>> ", yearlyCount);

  //     for (let i = 0; i < dailyCount.length; i++) {
  //       const myArray = [];
  //       if (dailyCount[i - 19] === undefined) {
  //         dailyMedian.push({ date: dailyCount[i].date, median: null });
  //       } else {
  //         let j = i - 19;
  //         while (j <= i) {
  //           myArray.push({
  //             date: dailyCount[j].date,
  //             median: dailyCount[j].count,
  //           });
  //           j++;
  //         }
  //         dailyMedian.push({
  //           date: dailyCount[i].date,
  //           median: calculateMedianWithArray(myArray, true),
  //         });
  //       }
  //     }
  //     // console.log("dailyMedian :>> ", dailyMedian);

  //     for (let i = 0; i < weeklyCount.length; i++) {
  //       const myArray = [];
  //       if (weeklyCount[i - 7] === undefined) {
  //         weeklyMedian.push({ date: weeklyCount[i].date, median: null });
  //       } else {
  //         let j = i - 7;
  //         while (j <= i) {
  //           myArray.push({
  //             date: weeklyCount[j].date,
  //             median: weeklyCount[j].count,
  //           });
  //           j++;
  //         }
  //         weeklyMedian.push({
  //           date: weeklyCount[i].date,
  //           median: calculateMedianWithArray(myArray),
  //         });
  //       }
  //     }
  //     // console.log("weeklyMedian :>> ", weeklyMedian);

  //     for (let i = 0; i < monthlyCount.length; i++) {
  //       const myArray = [];
  //       if (monthlyCount[i - 7] === undefined) {
  //         monthlyMedian.push({ date: monthlyCount[i].date, median: null });
  //       } else {
  //         let j = i - 7;
  //         while (j <= i) {
  //           myArray.push({
  //             date: monthlyCount[j].date,
  //             median: monthlyCount[j].count,
  //           });
  //           j++;
  //         }
  //         monthlyMedian.push({
  //           date: monthlyCount[i].date,
  //           median: calculateMedianWithArray(myArray),
  //         });
  //       }
  //     }
  //     // console.log("monthlyMedian :>> ", monthlyMedian);

  //     for (let i = 0; i < trimesterCount.length; i++) {
  //       const myArray = [];
  //       if (trimesterCount[i - 7] === undefined) {
  //         trimesterMedian.push({ date: trimesterCount[i].date, median: null });
  //       } else {
  //         let j = i - 7;
  //         while (j <= i) {
  //           myArray.push({
  //             date: trimesterCount[j].date,
  //             median: trimesterCount[j].count,
  //           });
  //           j++;
  //         }
  //         console.log(
  //           "calculateMedianWithArray2(myArray) :>> ",
  //           calculateMedianWithArray(myArray)
  //         );
  //         trimesterMedian.push({
  //           date: trimesterCount[i].date,
  //           median: calculateMedianWithArray(myArray),
  //         });
  //       }
  //     }
  //     // console.log("trimesterMedian :>> ", trimesterMedian);

  //     for (let i = 0; i < semesterCount.length; i++) {
  //       const myArray = [];
  //       if (semesterCount[i - 7] === undefined) {
  //         semesterMedian.push({ date: semesterCount[i].date, median: null });
  //       } else {
  //         let j = i - 7;
  //         while (j <= i) {
  //           myArray.push({
  //             date: semesterCount[j].date,
  //             median: semesterCount[j].count,
  //           });
  //           j++;
  //         }
  //         semesterMedian.push({
  //           date: semesterCount[i].date,
  //           median: calculateMedianWithArray(myArray),
  //         });
  //       }
  //     }
  //     // console.log("semesterMedian :>> ", semesterMedian);

  //     for (let i = 0; i < yearlyCount.length; i++) {
  //       const myArray = [];
  //       if (yearlyCount[i - 7] === undefined) {
  //         yearlyMedian.push({ date: yearlyCount[i].date, median: null });
  //       } else {
  //         let j = i - 7;
  //         while (j <= i) {
  //           myArray.push({
  //             date: yearlyCount[j].date,
  //             median: yearlyCount[j].count,
  //           });
  //           j++;
  //         }
  //         yearlyMedian.push({
  //           date: yearlyCount[i].date,
  //           median: calculateMedianWithArray(myArray),
  //         });
  //       }
  //     }
  //     // console.log("yearlyMedian :>> ", yearlyMedian);

  //     rdvsMovingAverage.forEach((element) => {
  //       const day = element.createdAt.substring(0, 10);
  //       const week = moment(element.createdAt, "YYYY-MM-DD").format("YYYY-WW");
  //       const month = element.createdAt.substring(0, 7);
  //       const trimester =
  //         element.createdAt.substring(0, 4) +
  //         "-T" +
  //         moment(element.createdAt, "YYYY-MM-DD").quarter();
  //       let semester;
  //       const monthForSemester = element.createdAt.substring(5, 7);
  //       const yearForSemester = element.createdAt.substring(0, 4);
  //       if (monthForSemester >= 1 && monthForSemester <= 6) {
  //         semester = yearForSemester + "-S1";
  //       } else if (monthForSemester >= 7 && monthForSemester <= 12) {
  //         semester = yearForSemester + "-S2";
  //       }
  //       const year = element.createdAt.substring(0, 4);

  //       const dayCount = dailyMedian.find((obj) => obj.date === day);
  //       const weekCount = weeklyMedian.find((obj) => obj.date === week);
  //       const monthCount = monthlyMedian.find((obj) => obj.date === month);
  //       const trimesterCount = trimesterMedian.find(
  //         (obj) => obj.date === trimester
  //       );
  //       const semesterCount = semesterMedian.find(
  //         (obj) => obj.date === semester
  //       );
  //       const yearCount = yearlyMedian.find((obj) => obj.date === year);

  //       element.movingMedianDay = dayCount ? dayCount.median : 0;
  //       element.movingMedianWeek = weekCount ? weekCount.median : 0;
  //       element.movingMedianMonth = monthCount ? monthCount.median : 0;
  //       element.movingMedianTrimester = trimesterCount
  //         ? trimesterCount.median
  //         : 0;
  //       element.movingMedianSemester = semesterCount ? semesterCount.median : 0;
  //       element.movingMedianYear = yearCount ? yearCount.median : 0;
  //     });
  //     // console.log("rdvsMovingAverage :>> ", rdvsMovingAverage);

  //     setRdvsLogsMovingAverage(rdvsMovingAverage);
  //     setRdvsLogs(resLogs.data.logs);
  //     setTotalMonths(getLastXMonths("1", resLogs.data.logs));

  //     updateData(resLogs.data.logs, null, null, null, rdvsMovingAverage);
  //     setIsLoading(false);
  //   };
  //   myFunction();
  // }, []);

  // const calculateRdvsWithMovingAverage = (data) => {
  //   const newData = fillArrayWithEmptyDates(
  //     data[0].createdAt,
  //     data[data.length - 1].createdAt,
  //     data
  //   );
  //   const newDataUniqueDates = newData.reduce((acc, curr) => {
  //     const existingObj = acc.find((obj) => obj.createdAt === curr.createdAt);
  //     if (existingObj) {
  //       existingObj.count += curr.count;
  //     } else {
  //       acc.push({ createdAt: curr.createdAt, count: curr.count });
  //     }
  //     return acc;
  //   }, []);

  //   return calculateMovingAverage(newDataUniqueDates);
  // };

  // const calculateMovingAverage = (data) => {
  //   // Define the window sizes
  //   const dayWindowSize = 20;
  //   const weekWindowSize = 28;
  //   const monthWindowSize = 120;
  //   const trimesterWindowSize = 360;
  //   const semesterWindowSize = 720;
  //   const yearWindowSize = 1460;

  //   // Calculate the moving averages for each data point
  //   let countSumDay = 0;
  //   let countSumWeek = 0;
  //   let countSumMonth = 0;
  //   let countSumTrimester = 0;
  //   let countSumSemester = 0;
  //   let countSumYear = 0;
  //   for (let i = 0; i < data.length; i++) {
  //     // Add the current count to the sum for each window size
  //     const count = data[i].count;
  //     countSumDay += count;
  //     countSumWeek += count;
  //     countSumMonth += count;
  //     countSumTrimester += count;
  //     countSumSemester += count;
  //     countSumYear += count;

  //     // Remove the count from the sum for each window size
  //     if (i >= dayWindowSize) {
  //       countSumDay -= data[i - dayWindowSize].count;
  //     }
  //     if (i >= weekWindowSize) {
  //       countSumWeek -= data[i - weekWindowSize].count;
  //     }
  //     if (i >= monthWindowSize) {
  //       countSumMonth -= data[i - monthWindowSize].count;
  //     }
  //     if (i >= trimesterWindowSize) {
  //       countSumTrimester -= data[i - trimesterWindowSize].count;
  //     }
  //     if (i >= semesterWindowSize) {
  //       countSumSemester -= data[i - semesterWindowSize].count;
  //     }
  //     if (i >= yearWindowSize) {
  //       countSumYear -= data[i - yearWindowSize].count;
  //     }

  //     // Calculate the moving averages
  //     const movingAverageDay =
  //       i < dayWindowSize ? null : countSumDay / dayWindowSize;
  //     const movingAverageWeek =
  //       i < weekWindowSize ? null : countSumWeek / weekWindowSize;
  //     const movingAverageMonth =
  //       i < monthWindowSize ? null : countSumMonth / monthWindowSize;
  //     const movingAverageTrimester =
  //       i < trimesterWindowSize
  //         ? null
  //         : countSumTrimester / trimesterWindowSize;
  //     const movingAverageSemester =
  //       i < semesterWindowSize ? null : countSumSemester / semesterWindowSize;
  //     const movingAverageYear =
  //       i < yearWindowSize ? null : countSumYear / yearWindowSize;

  //     // Store the moving averages in new properties
  //     data[i].movingAverageDay = movingAverageDay;
  //     data[i].movingAverageWeek =
  //       movingAverageWeek !== null ? movingAverageWeek * 7 : null; // Multiply by 7 to get the moving average for a week
  //     data[i].movingAverageMonth =
  //       movingAverageMonth !== null ? movingAverageMonth * 30 : null; // Multiply by 30 to get the moving average for a month
  //     data[i].movingAverageTrimester =
  //       movingAverageTrimester !== null ? movingAverageTrimester * 90 : null; // Multiply by 90 to get the moving average for a trimester
  //     data[i].movingAverageSemester =
  //       movingAverageSemester !== null ? movingAverageSemester * 180 : null; // Multiply by 180 to get the moving average for a semester
  //     data[i].movingAverageYear =
  //       movingAverageYear !== null ? movingAverageYear * 365 : null; // Multiply by 365 to get the moving average for a year
  //   }
  //   return data;
  // };

  // function fillArrayWithEmptyDates(startDate, endDate, array) {
  //   const stopDate = moment(endDate);
  //   let result = [];

  //   // Group the array elements by their creation date
  //   const groupedArray = array.reduce((acc, obj) => {
  //     const date = moment(obj.createdAt).format("YYYY-MM-DD");
  //     if (!acc[date]) acc[date] = [];
  //     acc[date].push({ ...obj, count: obj.count || 1 });
  //     return acc;
  //   }, {});

  //   let currentDate = moment(startDate);
  //   while (currentDate <= stopDate) {
  //     const dateStr = currentDate.format("YYYY-MM-DD");
  //     const objs = groupedArray[dateStr] || [];
  //     if (objs.length > 0) {
  //       result.push(...objs);
  //     } else {
  //       result.push({
  //         _id: "",
  //         nom: "",
  //         promoter: "",
  //         phase: "",
  //         program: "",
  //         count: 0,
  //         createdAt: dateStr,
  //       });
  //     }
  //     currentDate = currentDate.add(1, "days");
  //   }
  //   return result;
  // }

  // const calculateMedianWithArray = (array, isDay) => {
  //   const medians = array.map((item) => {
  //     return item.median;
  //   });
  //   return medians.length < (isDay ? 20 : 8)
  //     ? null
  //     : medians.sort((a, b) => a - b)[Math.floor(medians.length / 2)];
  // };

  // const updateData = (
  //   dataLocal,
  //   typeValueLocal,
  //   periodValueLocal,
  //   rangeValueLocal,
  //   dataMovingAverageLocal
  // ) => {
  //   !dataLocal && (dataLocal = _.cloneDeep(rdvsLogs));
  //   !typeValueLocal && (typeValueLocal = typeValue);
  //   !periodValueLocal && (periodValueLocal = periodValue);
  //   !rangeValueLocal && (rangeValueLocal = rangeValue);
  //   !dataMovingAverageLocal && (dataMovingAverageLocal = rdvsLogsMovingAverage);

  //   //keep only the data between the range
  //   dataLocal = dataLocal.filter(
  //     (element) =>
  //       moment(element.createdAt).isSameOrAfter(rangeValueLocal[0]) &&
  //       moment(element.createdAt).isSameOrBefore(rangeValueLocal[1])
  //   );

  //   dataLocal = dataLocal.map((element) => {
  //     //format the date according to the period
  //     let year = 0;
  //     let month = 0;
  //     switch (periodValueLocal) {
  //       case "day":
  //       default:
  //         element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
  //         break;
  //       case "week":
  //         element.createdAt = moment(element.createdAt).format("YYYY-MM-WW");
  //         break;
  //       case "month":
  //         element.createdAt = moment(element.createdAt).format("YYYY-MM");
  //         break;
  //       case "trimester":
  //         year = moment(element.createdAt, "YYYY-MM-DD").year();
  //         month = moment(element.createdAt, "YYYY-MM-DD").month() + 1;
  //         if (month >= 1 && month <= 4) {
  //           element.createdAt = year + "-T1";
  //         } else if (month >= 5 && month <= 8) {
  //           element.createdAt = year + "-T2";
  //         } else if (month >= 9 && month <= 12) {
  //           element.createdAt = year + "-T3";
  //         }
  //         break;
  //       case "semester":
  //         year = moment(element.createdAt, "YYYY-MM-DD").year();
  //         month = moment(element.createdAt, "YYYY-MM-DD").month() + 1;
  //         if (month >= 1 && month <= 6) {
  //           element.createdAt = year + "-S1";
  //         } else if (month >= 7 && month <= 12) {
  //           element.createdAt = year + "-S2";
  //         }
  //         break;
  //       case "year":
  //         element.createdAt = moment(element.createdAt).format("YYYY");
  //         break;
  //     }
  //     return element;
  //   });

  //   const newData = dataLocal.reduce((acc, cur) => {
  //     //create the data for the graph with the data and the type
  //     if (cur._id !== "") {
  //       const index = acc.findIndex(
  //         (element) =>
  //           element.date === cur.createdAt &&
  //           element.type === cur[typeValueLocal]
  //       );
  //       if (index === -1) {
  //         acc.push({
  //           date: cur.createdAt,
  //           type: cur[typeValueLocal],
  //           count: 1,
  //         });
  //       } else {
  //         acc[index].count++;
  //       }
  //       return acc;
  //     } else return acc;
  //   }, []);

  //   //calcul and display moving average
  //   // console.log("newData :>> ", newData);
  //   // console.log("dataMovingAverageLocal :>> ", dataMovingAverageLocal);
  //   newData.forEach((newDataElement) => {
  //     const averagePeriodArray = [];
  //     dataMovingAverageLocal.find((elem) => {
  //       let dateStart;
  //       let dateEnd;
  //       switch (periodValueLocal) {
  //         case "day":
  //         default:
  //           if (
  //             moment(newDataElement.date, "YYYY-MM-DD").format("YYYY-MM-DD") ===
  //             moment(elem.createdAt, "YYYY-MM-DD").format("YYYY-MM-DD")
  //           ) {
  //             newDataElement.movingAverage = elem.movingAverageDay;
  //             newDataElement.movingMedian = elem.movingMedianDay;
  //           }
  //           break;
  //         case "week":
  //           let dateForWeek =
  //             newDataElement.date.substring(0, 5) +
  //             newDataElement.date.substring(8); // remove the month number cause moment doesn't accept week number with month number
  //           if (newDataElement.date.endsWith("01-52")) {
  //             //give the same value as the week 12-52 because he get confuse with 12-52 and 01-52
  //             dateForWeek =
  //               parseInt(newDataElement.date.substring(0, 4)) - 1 + "-52";
  //           }
  //           dateStart = moment(dateForWeek, "YYYY-WW").startOf("week");
  //           dateEnd = moment(dateForWeek, "YYYY-WW").endOf("week");
  //           if (
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrAfter(dateStart) &&
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrBefore(dateEnd)
  //           ) {
  //             averagePeriodArray.push(elem);
  //             if (
  //               moment(elem.createdAt, "YYYY-MM-DD").format("YYYY-WW") ===
  //               dateForWeek
  //             ) {
  //               newDataElement.movingMedian = elem.movingMedianWeek;
  //             }
  //           }
  //           break;
  //         case "month":
  //           dateStart = moment(newDataElement.date, "YYYY-MM").startOf("month");
  //           dateEnd = moment(newDataElement.date, "YYYY-MM").endOf("month");
  //           if (
  //             //didn't used isBetween because it's not inclusive (ex: 2022-03-01 is not included in 2022-03)
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrAfter(dateStart) &&
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrBefore(dateEnd)
  //           ) {
  //             averagePeriodArray.push(elem);
  //             if (
  //               moment(elem.createdAt, "YYYY-MM-DD").format("YYYY-MM") ===
  //               newDataElement.date
  //             ) {
  //               newDataElement.movingMedian = elem.movingMedianMonth;
  //             }
  //           }
  //           break;
  //         case "trimester":
  //           if (newDataElement.date.endsWith("T1")) {
  //             dateStart = newDataElement.date.substring(0, 4) + "-01-01";
  //             dateEnd = newDataElement.date.substring(0, 4) + "-04-30";
  //           } else if (newDataElement.date.endsWith("T2")) {
  //             dateStart = newDataElement.date.substring(0, 4) + "-05-01";
  //             dateEnd = newDataElement.date.substring(0, 4) + "-08-31";
  //           } else if (newDataElement.date.endsWith("T3")) {
  //             dateStart = newDataElement.date.substring(0, 4) + "-09-01";
  //             dateEnd = newDataElement.date.substring(0, 4) + "-12-31";
  //           }

  //           if (
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrAfter(
  //               moment(dateStart, "YYYY-MM-DD")
  //             ) &&
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrBefore(
  //               moment(dateEnd, "YYYY-MM-DD")
  //             )
  //           ) {
  //             averagePeriodArray.push(elem);
  //             if (elem.createdAt === dateStart) {
  //               newDataElement.movingMedian = elem.movingMedianTrimester;
  //             }
  //           }
  //           break;
  //         case "semester":
  //           if (newDataElement.date.endsWith("S1")) {
  //             dateStart = newDataElement.date.substring(0, 4) + "-01-01";
  //             dateEnd = newDataElement.date.substring(0, 4) + "-06-30";
  //           } else if (newDataElement.date.endsWith("S2")) {
  //             dateStart = newDataElement.date.substring(0, 4) + "-07-01";
  //             dateEnd = newDataElement.date.substring(0, 4) + "-12-31";
  //           }

  //           if (
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrAfter(
  //               moment(dateStart, "YYYY-MM-DD")
  //             ) &&
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrBefore(
  //               moment(dateEnd, "YYYY-MM-DD")
  //             )
  //           ) {
  //             averagePeriodArray.push(elem);
  //             // console.log("elem :>> ", elem);
  //             // console.log("dateStart :>> ", dateStart);
  //             if (elem.createdAt === dateStart) {
  //               newDataElement.movingMedian = elem.movingMedianSemester;
  //             }
  //           }
  //           break;
  //         case "year":
  //           if (
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrAfter(
  //               moment(newDataElement.date, "YYYY")
  //                 .subtract(4, "years")
  //                 .startOf("year")
  //             ) &&
  //             moment(elem.createdAt, "YYYY-MM-DD").isSameOrBefore(
  //               moment(newDataElement.date, "YYYY").endOf("year")
  //             )
  //           ) {
  //             averagePeriodArray.push(elem);
  //             if (
  //               moment(elem.createdAt, "YYYY-MM-DD").format("YYYY") ===
  //               newDataElement.date
  //             ) {
  //               newDataElement.movingMedian = elem.movingMedianYear;
  //             }
  //           }
  //           break;
  //       }
  //     });

  //     switch (periodValueLocal) {
  //       case "day":
  //       default:
  //         break;
  //       case "week":
  //         if (
  //           averagePeriodArray.at(averagePeriodArray.length / 2)
  //             ?.movingAverageWeek !== null
  //         ) {
  //           newDataElement.movingAverage = averagePeriodArray.at(
  //             averagePeriodArray.length / 2
  //           )?.movingAverageWeek;
  //         }
  //         break;
  //       case "month":
  //         newDataElement.movingAverage = averagePeriodArray.at(
  //           averagePeriodArray.length / 2
  //         )?.movingAverageMonth;
  //         break;
  //       case "trimester":
  //         if (
  //           averagePeriodArray.at(averagePeriodArray.length / 2)
  //             ?.movingAverageTrimester !== null
  //         ) {
  //           newDataElement.movingAverage = averagePeriodArray.at(
  //             averagePeriodArray.length / 2
  //           )?.movingAverageTrimester;
  //         }
  //         break;
  //       case "semester":
  //         if (
  //           averagePeriodArray.at(averagePeriodArray.length / 2)
  //             ?.movingAverageSemester !== null
  //         ) {
  //           newDataElement.movingAverage = averagePeriodArray.at(
  //             averagePeriodArray.length / 2
  //           )?.movingAverageSemester;
  //         }
  //         break;
  //       case "year":
  //         if (
  //           averagePeriodArray.at(averagePeriodArray.length / 2)
  //             ?.movingAverageYear !== null
  //         ) {
  //           newDataElement.movingAverage = averagePeriodArray.at(
  //             averagePeriodArray.length / 2
  //           )?.movingAverageYear; //multiply by 365 to have the average for the year
  //         }
  //         break;
  //     }
  //   });

  //   setData(newData);
  //   setTotalRdvsSelected(dataLocal.length);
  // };

  // const onTypeChange = (e) => {
  //   setTypeValue(e.target.value);
  //   updateData(null, e.target.value);
  // };

  // const onPeriodChange = (e) => {
  //   setPeriodValue(e.target.value);
  //   updateData(null, null, e.target.value);
  // };

  // const onRangeChange = (dates, dateStrings) => {
  //   dates = !dates ? [moment().subtract(1, "year"), moment()] : dates; //if no dates, set to 1 year (default value)
  //   setRangeValue(dates);
  //   updateData(null, null, null, dates);
  // };

  // const getLastXMonths = (x, data) => {
  //   return data.filter((element) =>
  //     moment(element.createdAt || element.date).isBetween(
  //       moment().subtract(x, "month"),
  //       moment()
  //     )
  //   ).length;
  // };

  // const config = useMemo(() => {
  //   return {
  //     data: [data, data],
  //     xField: "date",
  //     yField: ["count", averageOrMedian],
  //     slider: {
  //       start: 0,
  //       end: 1,
  //     },
  //     meta: {
  //       time: {
  //         sync: false,
  //       },
  //     },
  //     yAxis: {
  //       count: {
  //         min: 0,
  //         max: (function () {
  //           return data
  //             .reduce((acc, cur) => {
  //               const index = acc.findIndex(
  //                 (element) => element.date === cur.date
  //               );
  //               if (index === -1) {
  //                 acc.push({
  //                   date: cur.date,
  //                   type: cur.type,
  //                   count: cur.count,
  //                 });
  //               } else {
  //                 acc[index].count += cur.count;
  //               }
  //               return acc;
  //             }, [])
  //             .reduce((acc, cur) => {
  //               if (cur.count > acc) {
  //                 acc = cur.count;
  //               }
  //               return acc;
  //             }, 0);
  //         })(),
  //       },
  //       movingAverage: {
  //         // label: {
  //         //  formatter: (val) => `${val}个`, //if need to change the text label
  //         // },
  //         min: 0,
  //         max: (function () {
  //           return data
  //             .reduce((acc, cur) => {
  //               const index = acc.findIndex(
  //                 (element) => element.date === cur.date
  //               );
  //               if (index === -1) {
  //                 acc.push({
  //                   date: cur.date,
  //                   type: cur.type,
  //                   count: cur.count,
  //                 });
  //               } else {
  //                 acc[index].count += cur.count;
  //               }
  //               return acc;
  //             }, [])
  //             .reduce((acc, cur) => {
  //               if (cur.count > acc) {
  //                 acc = cur.count;
  //               }
  //               return acc;
  //             }, 0);
  //         })(),
  //       },
  //       movingMedian: {
  //         min: 0,
  //         max: (function () {
  //           return data
  //             .reduce((acc, cur) => {
  //               const index = acc.findIndex(
  //                 (element) => element.date === cur.date
  //               );
  //               if (index === -1) {
  //                 acc.push({
  //                   date: cur.date,
  //                   type: cur.type,
  //                   count: cur.count,
  //                 });
  //               } else {
  //                 acc[index].count += cur.count;
  //               }
  //               return acc;
  //             }, [])
  //             .reduce((acc, cur) => {
  //               if (cur.count > acc) {
  //                 acc = cur.count;
  //               }
  //               return acc;
  //             }, 0);
  //         })(),
  //       },
  //     },
  //     tooltip: {
  //       customItems: (originalItems) => {
  //         return originalItems;
  //       },
  //     },
  //     geometryOptions: [
  //       {
  //         geometry: "column",
  //         isStack: true,
  //         seriesField: "type",
  //         label: {
  //           position: "middle",
  //         },
  //       },
  //       {
  //         geometry: "line",
  //         smooth: true,
  //       },
  //     ],
  //   };
  // }, [data, averageOrMedian]);

  if (isLoading) {
    return (
      <ContentContainer>
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <Spin size="default" />
        </Row>
      </ContentContainer>
    );
  } else if (rdvsLogs.length === 0) {
    return (
      <ContentContainer>
        <Empty description={<span>Aucune donnée pour le moment.</span>}></Empty>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        Non opérationnel
        {/* <PageHeader
          style={{ margin: "0px 16px 0px 16px" }}
          title="Rendez-vous"
          subTitle="Vues par jour"
          className="site-page-header"
          avatar={{
            src: "https://img.icons8.com/color/48/000000/combo-chart--v1.png",
          }}
        >
          <Row gutter={16}>
            <Col span={3}>
              <Statistic title="Total Rdv" value={rdvsLogs.length} />
            </Col>
            <Col span={3}>
              <Statistic title="Total Selected" value={totalRdvsSelected} />
            </Col>
            <Col span={3}>
              <Statistic
                title={
                  <Space size={2}>
                    Total
                    <Select
                      defaultValue={"1M"}
                      onChange={(value) =>
                        setTotalMonths(
                          getLastXMonths(value, _.cloneDeep(rdvsLogs))
                        )
                      }
                      size="small"
                      options={[
                        {
                          value: "1",
                          label: "1M",
                        },
                        {
                          value: "3",
                          label: "3M",
                        },
                        {
                          value: "6",
                          label: "6M",
                        },
                        {
                          value: "12",
                          label: "12M",
                        },
                      ]}
                    />
                  </Space>
                }
                value={totalMonths}
              />
            </Col>
            <Col span={4}>
              <Radio.Group
                onChange={(e) => setAverageOrMedian(e.target.value)}
                value={averageOrMedian}
              >
                <Radio value={"movingAverage"}>Moyenne mobile</Radio>
                <Radio value={"movingMedian"}>Mediane mobile</Radio>
              </Radio.Group>
            </Col>
            <Col span={4}></Col>
          </Row>
          <br></br>
          <Radio.Group onChange={onTypeChange} value={typeValue}>
            <Radio value={"promoter"}>Promoteur</Radio>
            <Radio value={"program"}>Programme</Radio>
            <Radio value={"planning"}>Planning</Radio>
            <Radio value={"phase"}>Phase</Radio>
            <Radio value={"invoiceentity"}>Facturation</Radio>
          </Radio.Group>
          <br />
          <br />
          <Radio.Group onChange={onPeriodChange} value={periodValue}>
            <Radio value={"day"}>Jour</Radio>
            <Radio value={"week"}>Semaine</Radio>
            <Radio value={"month"}>Mois</Radio>
            <Radio value={"trimester"}>Trimestre</Radio>
            <Radio value={"semester"}>Semestre</Radio>
            <Radio value={"year"}>Année</Radio>
          </Radio.Group>

          <RangePicker
            ranges={{
              // "1 Jour": [moment(), moment()],
              "1 Mois": [moment().subtract(1, "month"), moment()],
              "3 Mois": [moment().subtract(3, "month"), moment()],
              "6 Mois": [moment().subtract(6, "month"), moment()],
              "1 An": [moment().subtract(1, "year"), moment()],
              "2 Ans": [moment().subtract(2, "year"), moment()],
              "3 Ans": [moment().subtract(3, "year"), moment()],
              Max: [moment(rdvsLogs[0].createdAt), moment()],
            }}
            defaultValue={[moment().subtract(1, "year"), moment()]}
            onChange={onRangeChange}
            dateRender={(current) => {
              return (
                <div className="ant-picker-cell-inner">{current.date()}</div>
              );
            }}
          />

          <br></br>
          <br></br>

          {data.length === 0 ? (
            <Empty
              description={<span>Aucune donnée pour cette période.</span>}
            ></Empty>
          ) : (
            <RdvsLogsHistogram config={config} />
          )}
          <br></br>
        </PageHeader> */}
      </ContentContainer>
    );
  }
};

export default AdminLogsTrends;
