import React, { useState, useRef } from "react";
import {
  Table,
  Tooltip,
  Badge,
  Space,
  Typography,
  Tag,
  Input,
  Button,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  MessageOutlined,
  MailOutlined,
  EditOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
  HighlightOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  ToTopOutlined,
  VideoCameraOutlined,
  CloseCircleTwoTone,
  PhoneOutlined,
} from "@ant-design/icons";
import PlanningBookingTableEditModal from "./PlanningBookingTableEditModal";
import PlanningBookingVrDataModal from "./PlanningBookingVrDataModal";
import { getStatusImpose } from "../../utils";
import moment from "moment";
import momentTz from "moment-timezone";
const { Text } = Typography;

const PlanningBookingTableImpose = ({
  planning,
  rowSelection,
  fetchPlanningAndFmtEvents,
  expandedRowKeys,
  setExpandedRowKeys,
  filteredInfo,
  handleChange,
}) => {
  const [usrData, setUsrData] = useState(undefined);
  const [vrData, setVrData] = useState(undefined);
  const [show, setShow] = useState(false);
  const [showVrModal, setShowVrModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const searchInput = useRef(null);

  const updateExpandedRowKeys = ({ record }) => {
    const rowKey = record.uuid;
    const isExpanded = expandedRowKeys.find((key) => key === rowKey);
    let newExpandedRowKeys = [];
    if (isExpanded) {
      newExpandedRowKeys = expandedRowKeys.reduce((acc, key) => {
        if (key !== rowKey) acc.push(key);
        return acc;
      }, []);
    } else {
      newExpandedRowKeys = [...expandedRowKeys, rowKey];
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const onExpand = (expanded, record) => {
    updateExpandedRowKeys({ record });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={(node) => {
          //   setSearchInput(node);
          // }}
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{ width: 110 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput, 100);
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchColumn(dataIndex);
  };

  const openEditModal = (key) => {
    let usr = planning.broadcastList.filter((x) => x.uuid === key)[0];
    setUsrData(usr);
    setShow(true);
  };

  const openVrModal = (record) => {
    setVrData(record);
    setShowVrModal(true);
  };

  const Description = ({ record }) => {
    return (
      <div>
        <Space direction="horizontal">
          {record.isInvitationSent ? (
            <Tooltip
              placement="top"
              title={
                record.invitationMail !== undefined ? (
                  <div>{`Invitation envoyée le ${moment(
                    record.invitationMail[0]
                  ).format("DD/MM")} à ${moment(
                    record.invitationMail[0]
                  ).format("HH:mm")}`}</div>
                ) : (
                  "Invitation envoyée"
                )
              }
            >
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Aucune invitation envoyée">
              <CloseCircleTwoTone twoToneColor="red" />
            </Tooltip>
          )}
          {record.telephonemonsieur && (
            <div>
              <PhoneOutlined />
              &nbsp;M : <a>{record.telephonemonsieur}</a>
            </div>
          )}
          {record.telephonemadame && (
            <div>
              <PhoneOutlined />
              &nbsp;Mme : <a>{record.telephonemadame}</a>
            </div>
          )}
          {record.email && (
            <div>
              <MailOutlined />
              &nbsp; &nbsp;<a href={`mailto:${record.email}`}>{record.email}</a>
            </div>
          )}
          {record.mailmadame && record.mailmadame !== record.email && (
            <div>
              <MailOutlined />
              &nbsp; &nbsp;
              <a href={`mailto:${record.mailmadame}`}>{record.mailmadame}</a>
            </div>
          )}
          {record.mailmonsieur && record.mailmonsieur !== record.email && (
            <div>
              <MailOutlined />
              &nbsp; &nbsp;
              <a href={`mailto:${record.mailmonsieur}`}>
                {record.mailmonsieur}
              </a>
            </div>
          )}
          {record.shoeSize !== "" && record.shoeSize !== "inconnue" && (
            <div>
              <UserOutlined />
              &nbsp; &nbsp;
              <a>Pointure: {record.shoeSize}</a>
            </div>
          )}
          {!planning.questions.includes("Sondage visite virtuelle") &&
          record.isInterestedVr !== true ? (
            <div>
              <VideoCameraOutlined />
              &nbsp;Visite 3D : &nbsp;
              <>-</>
            </div>
          ) : (
            <div>
              <VideoCameraOutlined />
              &nbsp;Visite 3D : &nbsp;
              <>
                {record.isInterestedVr === true ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleOutlined className="site-result-demo-error-icon" />
                )}
              </>
            </div>
          )}

          {record.vrUuid !== null ? (
            <div>
              (
              <a
                href={`/immersion/${record.vrUuid}`}
                target="_blank"
                rel="noreferrer"
              >
                lien
              </a>
              )
            </div>
          ) : (
            <div>
              (
              <Button size="small" onClick={() => openVrModal(record)}>
                <ToTopOutlined />
              </Button>
              )
            </div>
          )}
        </Space>
        {record.observations !== "" && (
          <div>
            <Text code>{record.observations}</Text>
          </div>
        )}
        {record.note !== "" && (
          <div>
            <HighlightOutlined />
            &nbsp; &nbsp;<Text type="danger">Message de l'acquéreur:</Text>{" "}
            {record.note}
          </div>
        )}
        {record.visitors.length > 0 && (
          <div>
            <TeamOutlined /> {record.visitors.length}{" "}
            {record.visitors.length > 1 ? " Visiteurs :" : " Visiteur :"}
            {record.visitors.map((visitor) => {
              return (
                <li key={visitor._id}>
                  {`- ${visitor.nom} ${visitor.prenom} `}
                  {visitor.role && `(${visitor.role})`}{" "}
                  {visitor.email && `${"(" + visitor.email + ")"}`}
                  {visitor.shoeSize && `${" Pointure: " + visitor.shoeSize}`}
                </li>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const dataSource = planning.broadcastList.map((x) => ({
    uuid: x.uuid,
    email: x.guest.email,
    nom: x.guest.nom,
    telephonemonsieur: x.guest.telephonemonsieur,
    telephonemadame: x.guest.telephonemadame,
    mailmonsieur: x.guest.mailmonsieur,
    mailmadame: x.guest.mailmadame,
    lotcomm: x.guest.lotcomm,
    typologie: x.guest.typologie,
    etage: x.guest.etage,
    batiment: x.guest.batiment,
    rdvLength: x.length,
    invitationMail: x.invitationMail,
    relancesMail: x.relancesMail,
    relancesSMS: x.relancesSMS,
    rappelsMail: x.rappelsMail,
    rappelsSMS: x.rappelsSMS,
    isActive: x.isActive,
    isInterestedVr: x.isInterestedVr,
    vrUuid: x.vrUuid,
    visitors: x.visitors,
    shoeSize: x.shoeSize,
    note: x.note,
    isAvailable: x.isAvailable,
    observations: x.observations,
    notifMail: x.notifMail,
    notifSMS: x.notifSMS,
    booked: getStatusImpose(x),
    isConfirmed: x.isConfirmed,
    isInvitationSent: x.isInvitationSent,
    dateBooked: new Date(x.start).getTime() / 1000,
    dateBooked2: momentTz(x.start).tz("Europe/Paris").format("D MMM"),
  }));

  const columns = [
    {
      title: "Lot",
      dataIndex: "lotcomm",
      key: "lotcomm",
      filteredValue: (filteredInfo !== null && filteredInfo.lotcomm) || null,
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.lotcomm.localeCompare(b.lotcomm),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("lotcomm"),
      width: "6%",
    },

    {
      title: "Bat.",
      dataIndex: "batiment",
      key: "batiment",
      filteredValue: (filteredInfo !== null && filteredInfo.batiment) || null,
      filters: Array.from(
        new Set(planning.broadcastList.map((x) => x.guest.batiment))
      ).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => record.batiment.includes(value),
      sorter: (a, b) => a.batiment.localeCompare(b.batiment),
      sortDirections: ["descend", "ascend"],
      width: "6%",
    },
    {
      title: "Etage",
      dataIndex: "etage",
      key: "etage",
      filteredValue: (filteredInfo !== null && filteredInfo.etage) || null,
      filters: Array.from(
        new Set(planning.broadcastList.map((x) => x.guest.etage))
      ).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => record.etage.includes(value),
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.etage.localeCompare(b.etage),
      sortDirections: ["descend", "ascend"],
      width: "7%",
    },
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      filteredValue: (filteredInfo !== null && filteredInfo.nom) || null,
      sorter: (a, b) => a.nom.localeCompare(b.nom),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("nom"),
      width: "16%",
    },
    {
      title: "Lien",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => (
        <a href={"/entrevue/" + text} target="_blank" rel="noreferrer">
          ici
        </a>
      ),
      width: "5%",
    },
    {
      title: "Typo.",
      key: "typologie",
      dataIndex: "typologie",
      filters: [
        {
          text: "1P",
          value: "1p",
        },
        {
          text: "2P",
          value: "2p",
        },
        {
          text: "3P",
          value: "3p",
        },
        {
          text: "4P",
          value: "4p",
        },
        {
          text: "5P",
          value: "5p",
        },
      ],
      width: "5%",
      filteredValue: (filteredInfo !== null && filteredInfo.typologie) || null,
      onFilter: (value, record) =>
        record.typologie.toLowerCase().indexOf(value) === 0,
      render: (typology) => {
        return <div key={typology}>{typology.toUpperCase()}</div>;
      },
    },
    {
      title: "Durée",
      dataIndex: "rdvLength",
      key: "rdvLength",
      render: (text) => (
        <div>
          {moment.utc(text).format("H") + "h" + moment.utc(text).format("mm")}
        </div>
      ),
      filteredValue: (filteredInfo !== null && filteredInfo.rdvLength) || null,
      filters: Array.from(
        new Set(planning.broadcastList.map((x) => x.length))
      ).map((_) => ({
        text: moment.utc(_).format("H") + "h" + moment.utc(_).format("mm"),
        value: _,
      })),
      onFilter: (value, record) => record.rdvLength === value,
      sorter: (a, b) => a.rdvLength - b.rdvLength,
      sortDirections: ["descend", "ascend"],
      width: "9%",
    },
    {
      title: "Relances",
      dataIndex: "relancesMail",
      key: "relancesMail",
      render: (dates, record) => {
        return (
          <div>
            {record.relancesMail.length !== 0 && (
              <Tooltip
                placement="bottom"
                title={record.relancesMail.map((_, idx) => (
                  <div key={idx}>
                    {moment(_).format("ddd DD MMM HH:mm")}
                    <br />
                  </div>
                ))}
              >
                <Tag color="green">
                  <MailOutlined /> ({record.relancesMail.length})
                </Tag>
              </Tooltip>
            )}
            {record.relancesSMS.length !== 0 && (
              <Tooltip
                placement="bottom"
                title={record.relancesSMS.map((_, idx) => (
                  <div key={idx}>
                    {moment(_).format("ddd DD MMM HH:mm")}
                    <br />
                  </div>
                ))}
              >
                <Tag color="red">
                  <MessageOutlined /> ({record.relancesSMS.length})
                </Tag>
              </Tooltip>
            )}
            {record.relancesMail.length === 0 &&
              record.relancesSMS.length === 0 &&
              "-"}
          </div>
        );
      },
      width: "6%",
    },
    {
      title: "Rappels",
      dataIndex: "rappelsMail",
      key: "rappelsMail",
      render: (dates, record) => {
        if (record.notifMail || record.notifSMS) {
          return (
            <div>
              {record.notifMail && (
                <Tooltip
                  placement="bottom"
                  title={
                    record.rappelsMail.length > 0
                      ? record.rappelsMail.map((_, idx) => (
                          <div key={idx}>
                            {moment(_).format("ddd DD MMM HH:mm")}
                            <br />
                          </div>
                        ))
                      : null
                  }
                >
                  <Tag color="green">
                    <MailOutlined /> (A)
                  </Tag>
                </Tooltip>
              )}
              {record.notifSMS && (
                <Tooltip
                  placement="bottom"
                  title={
                    record.rappelsSMS.length > 0
                      ? record.rappelsSMS.map((_, idx) => (
                          <div key={idx}>
                            {moment(_).format("ddd DD MMM HH:mm")}
                            <br />
                          </div>
                        ))
                      : null
                  }
                >
                  <Tag color="red">
                    <MessageOutlined /> (A)
                  </Tag>
                </Tooltip>
              )}
            </div>
          );
        } else {
          return (
            <div>
              {record.rappelsMail.length !== 0 && (
                <Tooltip
                  placement="bottom"
                  title={record.rappelsMail.map((_, idx) => (
                    <div key={idx}>
                      {moment(_).format("ddd DD MMM HH:mm")}
                      <br />
                    </div>
                  ))}
                >
                  <Tag color="green">
                    <MailOutlined /> ({record.rappelsMail.length})
                  </Tag>
                </Tooltip>
              )}
              {record.rappelsSMS.length !== 0 && (
                <Tooltip
                  placement="bottom"
                  title={record.rappelsSMS.map((_, idx) => (
                    <div key={idx}>
                      {moment(_).format("ddd DD MMM HH:mm")}
                      <br />
                    </div>
                  ))}
                >
                  <Tag color="red">
                    <MessageOutlined /> ({record.rappelsSMS.length})
                  </Tag>
                </Tooltip>
              )}
              {record.rappelsMail.length === 0 &&
                record.rappelsSMS.length === 0 &&
                "-"}
            </div>
          );
        }
      },
      width: "6%",
    },
    {
      title: "Réponse",
      dataIndex: "isConfirmed",
      key: "isConfirmed",
      filteredValue:
        (filteredInfo !== null && filteredInfo.isConfirmed) || null,
      filters: Array.from(new Set(dataSource.map((x) => x.isConfirmed))).map(
        (_) => ({
          text: _ === true ? "oui" : "non",
          value: _,
        })
      ),
      onFilter: (value, record) => {
        return record.isConfirmed === value;
      },
      sorter: (a, b) => a.isconfirmed.localeCompare(b.isConfirmed),
      render: (_, record) => {
        if (!record.isConfirmed) {
          return (
            <CloseCircleOutlined className="site-result-demo-error-icon" />
          );
        } else {
          return <CheckCircleTwoTone twoToneColor="#52c41a" />;
        }
      },
      width: "11%",
    },
    {
      title: "Status",
      dataIndex: "booked",
      key: "booked",
      filteredValue: (filteredInfo !== null && filteredInfo.booked) || null,
      filters: Array.from(new Set(dataSource.map((x) => x.booked))).map(
        (_) => ({
          text: _,
          value: _,
        })
      ),
      onFilter: (value, record) => {
        return record.booked === value;
      },
      sorter: (a, b) => a.booked.localeCompare(b.booked),
      render: (_, record) => {
        if (!record.isConfirmed) {
          if (!record.isActive) {
            return <Badge status="default" text="inactif" />;
          } else {
            return (
              <Tooltip
                placement="topLeft"
                title={
                  "rdv le : " +
                  momentTz
                    .unix(record.dateBooked)
                    .tz("Europe/Paris")
                    .format("ddd DD MMM HH:mm")
                }
              >
                <Badge status="warning" text={"en attente"} />
              </Tooltip>
            );
          }
        } else {
          if (record.isAvailable) {
            return (
              <Tooltip
                placement="topLeft"
                title={
                  "rdv le : " +
                  momentTz
                    .unix(record.dateBooked)
                    .tz("Europe/Paris")
                    .format("ddd DD MMM HH:mm")
                }
              >
                <Badge status="success" text={"présent"} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip
                placement="topLeft"
                title={
                  "rdv le : " +
                  momentTz
                    .unix(record.dateBooked)
                    .tz("Europe/Paris")
                    .format("ddd DD MMM HH:mm")
                }
              >
                <Badge status="error" text={"indisponible"} />
              </Tooltip>
            );
          }
        }
      },
      width: "15%",
    },
    {
      title: "Date RDV",
      dataIndex: "dateBooked2",
      key: "dateBooked2",
      render: (_, record) => {
        if (_ !== "-") {
          return (
            <div>
              {momentTz
                .unix(record.dateBooked)
                .tz("Europe/Paris")
                .format("ddd DD MMM")}
            </div>
          );
        } else {
          return "-";
        }
      },
      filteredValue:
        (filteredInfo !== null && filteredInfo.dateBooked2) || null,
      filters: Array.from(new Set(dataSource.map((x) => x.dateBooked2))).map(
        (_) => ({
          text: _,
          value: _,
        })
      ),
      onFilter: (value, record) => {
        return record.dateBooked2.includes(value);
      },
      sorter: (a, b) => {
        if (a.dateBooked2 !== "-" || b.dateBooked2 !== "-") {
          return new Date(a.dateBooked * 1000) - new Date(b.dateBooked * 1000) >
            0
            ? 1
            : -1;
        } else {
          return 1;
        }
      },
      width: "10%",
    },
    {
      title: "",
      key: "uuid",
      dataIndex: "uuid",
      render: (text) => (
        <>
          <Tooltip title="editer">
            <EditOutlined onClick={() => openEditModal(text)} />
          </Tooltip>
        </>
      ),
      width: "3%",
    },
  ];

  return (
    <div>
      {usrData !== undefined && (
        <PlanningBookingTableEditModal
          show={show}
          setShow={setShow}
          usrData={usrData}
          setUsrData={setUsrData}
          fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
          planning={planning}
        />
      )}
      {vrData !== undefined && (
        <PlanningBookingVrDataModal
          show={showVrModal}
          setShow={setShowVrModal}
          usrData={vrData}
          setUsrData={setVrData}
          planning={planning}
          fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
        />
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        showHeader={true}
        rowKey="uuid"
        size="small"
        rowSelection={rowSelection}
        onChange={handleChange}
        filteredInfo={filteredInfo}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          updateExpandedRowKeys: updateExpandedRowKeys,
          expandedRowRender: (record) => <Description record={record} />,
          onExpand: onExpand,
        }}
        pagination={{ defaultPageSize: 500, hideOnSinglePage: true }}
        scroll={{
          y: 480,
        }}
      />
    </div>
  );
};

export default PlanningBookingTableImpose;
