import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import { UserContext } from "../../context/UserContext";
import { getBasicAuthConfig } from "../../utils";
import { Alert, Space, Timeline, Divider } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const Actualite = () => {
  let history = useHistory();
  const { notifications, setNotifications } = useContext(UserContext);
  const [hasLoaded, setHasLoaded] = useState(false);
  // const [housing, setHousing] = useState(undefined);
  const [start, setStart] = useState(undefined);

  const onClose = async (id) => {
    // User clicked on cross, delete notification on db
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL +
          `/users/me/housings/` +
          localStorage.getItem("currentHousingId") +
          `/notifications/` +
          id,
        getBasicAuthConfig()
      );

      // setHasLoaded(false);
      let newNotifications = {
        ...notifications,
        messages: notifications.messages.filter((_) => _._id !== id),
      };
      setNotifications(newNotifications);
      localStorage.setItem("notifications", JSON.stringify(newNotifications));
      // setHasLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      try {
        // console.log("Fetching user ...");
        const res = await axios.get(
          process.env.REACT_APP_API_URL +
            `/users/me/housings/` +
            localStorage.getItem("currentHousingId"),
          getBasicAuthConfig()
        );
        // setHousing(res.data.housing[0]);
        setStart(res.data.housing[0].createdAt);

        // console.log(`REQUEST RETURNS : ${JSON.stringify(res.data)}`);
        // If notifications there are, user updates global
        // state to reflects theses onto interface badges (left menu)
        let housingsNotifications = res.data.housing[0].notifications;
        let msgs = [];
        // console.log(JSON.stringify(housingsNotifications));
        // console.log(
        //   `NOMBRE NOTIFICATIONS : ${
        //     housingsNotifications.length
        //   } (notifications => ${JSON.stringify(housingsNotifications)})`
        // );
        if (housingsNotifications.length !== 0) {
          housingsNotifications.forEach((notif) => {
            if (notif.badgeName === "messages") {
              msgs.push({ ...notif });
            }
          });
        }
        // console.log(
        //   `Setting notifs : ${JSON.stringify({
        //     ...notifications,
        //     messages: msgs,
        //   })}`
        // );
        let newNotifications = { ...notifications, messages: msgs };
        setNotifications(newNotifications);
        localStorage.setItem("notifications", JSON.stringify(newNotifications));
      } catch (e) {
        console.log(e);
        history.push("/accueil-acquereur");
      }

      // setNotifications();
    };
    newFunc();
    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader arrow={true} title="Actualités" subTitle="fil" />
        <Space direction="vertical">
          {hasLoaded &&
            notifications.messages.map((msg, idx) => {
              return (
                <div key={"toki-" + idx}>
                  <Alert
                    message={`Message - Sujet : ${msg.ticket.subject}`}
                    action={
                      msg.ticket.files.length > 0 ? (
                        <div>
                          <PaperClipOutlined />
                          {` (${msg.ticket.files.length})`}
                        </div>
                      ) : (
                        <div></div>
                      )
                    }
                    type="warning"
                    closable
                    onClose={() => {
                      onClose(msg._id);
                    }}
                  />
                </div>
              );
            })}
        </Space>
        <Divider />
        <Timeline mode="left">
          <Timeline.Item
            color="green"
            label={
              moment(start).format("DD MMM YYYY") +
              " à " +
              moment(start).format("HH:mm")
            }
          >
            Création du compte
          </Timeline.Item>
          <Timeline.Item label="2015-09-01 09:12:11">
            Solve initial network problems
          </Timeline.Item>
          <Timeline.Item>Technical testing</Timeline.Item>
          <Timeline.Item label="2015-09-01 09:12:11">
            Network problems being solved
          </Timeline.Item>
        </Timeline>
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Actualite;
