import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import ContentContainer from "../components/ContentContainer";
import { AlertOutlined } from "@ant-design/icons";
import SubContentContainer from "../components/SubContentContainer";
import CustomPageHeader from "../components/CustomPageHeader";

const HelpTemplate = () => {
  const { role } = useContext(UserContext);
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const formRef = useRef();

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title={
            <div>
              <AlertOutlined />
              &nbsp; Comment marchent les modèles ?
            </div>
          }
          arrow={true}
        />
        Ici insérer l'aide
      </SubContentContainer>
    </ContentContainer>
  );
};

export default HelpTemplate;
