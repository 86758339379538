import React, { useEffect, useState } from "react";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";
import { getBasicAuthConfig } from "../../utils";
import { Typography, App } from "antd";
import UsersPermissionsTable from "../../components/admin/UsersPermissionsTable";
// import { PageHeader } from "@ant-design/pro-layout";
import SubContentContainer from "../../components/SubContentContainer";

const { Title } = Typography;

const AdminUsersPermissions = () => {
  const { message } = App.useApp();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const newFunc = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/users/business",
          getBasicAuthConfig()
        );
        setUsers(res.data);
        // console.log(res.data);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        message.error(`Erreur Lors du chargement`);
      }
    };
    newFunc();
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          // style={{ margin: "0px 16px 0px 16px" }}
          // className="site-page-header-responsive"
          // onBack={() => window.history.back()}
          arrow={true}
          title="Page Users Permissions"
        ></CustomPageHeader>
        {!isLoading && (
          <div>
            {/* <Title level={4}>Page Users Permissions</Title>
          <br /> */}

            <UsersPermissionsTable data={users} setData={setUsers} />
          </div>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default AdminUsersPermissions;
