import React from "react";
import { App, Modal, Checkbox, Row } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";

const RappelsRelancesModal = ({
  isNotifMail,
  isNotifSMS,
  setIsNotifMail,
  setIsNotifSMS,
  show,
  setShow,
  selected,
  planning,
  fetchPlanningAndFmtEvents,
}) => {
  const { message } = App.useApp();
  const onUpdate = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          planning._id +
          "/update-notifications",
        {
          notifMail: isNotifMail,
          notifSMS: isNotifSMS,
          uuids: selected.map((x) => x.uuid),
        },
        getRdvApiAuth()
      );
      await fetchPlanningAndFmtEvents();
      setShow(false);
      message.success(`Notifications correctement mises à jour.`);
    } catch (e) {
      console.log(e);
      message.error(
        `Erreur lors de la mise à jour des notifications acquéreur`
      );
    }
  };

  return (
    <Modal
      title="Notifications aux acquéreurs"
      style={{ top: 40 }}
      open={show}
      onOk={onUpdate}
      onCancel={() => setShow(false)}
    >
      <Row>
        <div>
          <Checkbox
            value="notifMail"
            style={{ lineHeight: "32px" }}
            checked={isNotifMail}
            onChange={(e) => {
              setIsNotifMail(e.target.checked);
            }}
          >
            Notification Mail
          </Checkbox>
          <Checkbox
            value="notifSMS"
            style={{ lineHeight: "32px" }}
            checked={isNotifSMS}
            onChange={(e) => {
              setIsNotifSMS(e.target.checked);
            }}
          >
            Notification SMS
          </Checkbox>
          <br />
        </div>
      </Row>
    </Modal>
  );
};

export default RappelsRelancesModal;
