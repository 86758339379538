import React, { useState, useEffect } from "react";
import { App, Modal, Checkbox, Button, Select, Form } from "antd";
import axios from "axios";
import {
  getBasicAuthConfig,
  getFormatedClients,
  getRdvApiAuth,
} from "../../../utils";
const { Option } = Select;

const AddCustomerModal = ({
  show,
  setShow,
  fetchPlanningAndFmtEvents,
  planning,
  isLoading,
  setIsLoading,
}) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [clientList, setClientList] = useState([]);
  const { message } = App.useApp();
  const [selected, setSelected] = useState(undefined);
  const [isVerifLoading, setIsVerifLoading] = useState(false);
  const [out, setOut] = useState(undefined);
  const [form] = Form.useForm();

  // Gerer le pas du planning
  var times = {
    "30'": 1800000,
    "45'": 2700000,
    "1h": 3600000,
    "1h30": 5400000,
    "2h": 7200000,
    "2h30": 9000000,
    "3h": 10800000,
    "4h": 14400000,
  };

  if (planning.pas === 1800) {
    delete times["45'"];
    delete times["30'"];
  }

  const fetchProgramCustomers = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + planning.projectId,
        getBasicAuthConfig()
      );
      let cltList = getFormatedClients(res.data.clients, planning.projectId);
      // setClientList(cltList);
      let planningLotcomms = planning.broadcastList.map((_) => _.guest.lotcomm);
      setOut(cltList.filter((x) => !planningLotcomms.includes(x.lotcomm)));
    } catch (e) {
      console.log(e);
      message.error(
        `Erreur lors du chargement des acquéreurs du programme ${planning.projectId}`
      );
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchProgramCustomers();
      setIsLoading(false);
    };
    if (isLoading) {
      newFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onAddCustomer = async (values) => {
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + `/rdv/${planning._id}/add-customer`,
        {
          user: selected,
          duree: values.duree,
          isMailInvitation: values.isMailInvitation,
        },
        getRdvApiAuth()
      );
      form.resetFields();
      setIsVerifLoading(false);
      setShow(false);
      setSelected(undefined);
      await fetchPlanningAndFmtEvents();
      setIsLoading(true);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(`${e.response.data.message}`);
      } else {
        message.error(`Erreur lors de l'ajout de l'acquéreur au planning.`);
      }
      setIsVerifLoading(false);
    }
  };

  const verifBackendAlgo = async () => {
    setIsVerifLoading(true);
    try {
      form
        .validateFields()
        .then((values) => {
          onAddCustomer(values);
        })
        .catch((info) => {
          // console.log("La validation des champs a échoué:", info);
          setIsVerifLoading(false);
        });
    } catch (e) {
      console.log(e);
      if (e.response) {
        message.error(`${e.response.data.message}`);
      } else {
        message.error(
          "Erreur lors du test de l'ajout de l'acquéreur au planning (2)"
        );
      }
    }
  };

  const onChange = (value) => {
    // console.log(`Value changed to -> ${JSON.stringify(value)}`);
    setSelected(out.find((_) => _._id === value));
  };

  return (
    <Modal
      title="Ajout d'un acquéreur au planning"
      style={{ top: 40 }}
      open={show}
      onCancel={() => {
        setShow(false);
        form.resetFields();
        setSelected(undefined);
      }}
      width={1000}
      footer={[
        <Button
          key="annuler"
          onClick={() => {
            setShow(false);
            form.resetFields();
            setSelected(undefined);
          }}
        >
          Annuler
        </Button>,
        <Button
          key="verifier"
          type="primary"
          loading={isVerifLoading}
          onClick={verifBackendAlgo}
        >
          Confirmer l'ajout
        </Button>,
      ]}
    >
      {!isLoading && out !== undefined && (
        <div>
          <p>{`${out.length} acquéreurs ont été trouvés pour le programme ${planning.projectName}.`}</p>
          <p>
            Sélectionnez dans la liste ci-dessous l'acquéreur que vous
            souhaiteriez ajouter au planning:
          </p>
          <br />
          <br />
          <Form
            form={form}
            initialValues={{
              isMailInvitation: true,
            }}
          >
            <Form.Item
              name="selected"
              label="Invité"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner un invité !",
                },
              ]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="Sélectionnez un invité"
                onChange={onChange}
                optionFilterProp="label"
                // allowClear
              >
                {out.map((housing) => {
                  return (
                    <Option
                      key={housing._id}
                      value={housing._id}
                      label={`[${housing.lotcomm}] ${housing.firstname} ${housing.lastname}`}
                    >
                      {`[${housing.lotcomm}] ${housing.firstname} ${housing.lastname}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="duree" label="Durée" rules={[{ required: true }]}>
              <Select placeholder="Sélectionnez une durée de visite" allowClear>
                {Object.keys(times).map((m, idx) => {
                  return (
                    <Option key={idx} value={times[m]}>
                      {`${m}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="isMailInvitation" valuePropName="checked">
              <Checkbox>Envoi mail invitation</Checkbox>
            </Form.Item>
          </Form>
          {selected !== undefined && (
            <div>
              {`Vous avez sélectionné le lot ${selected.lotcomm} (${selected.firstname} ${selected.lastname}), il s'agit d'un logement type ${selected.typologie}.`}
            </div>
          )}
          <br />
        </div>
      )}
    </Modal>
  );
};

export default AddCustomerModal;
