import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Result, Button, Spin } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import { errorCatcher } from "../utils";

const LogoutPage = () => {
  let history = useHistory();
  const { setAuth, setRole, setUsername } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const deleteTokenBackend = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/users/logout",
        {},
        config
      );
      localStorage.clear();
      setAuth(false);
      setRole("anonymousUser");
      setUsername("Anonymous");
      setIsLoading(false);
      // window.location.reload();
      history.push("/login");
    } catch (e) {
      errorCatcher(e, `Deconnexion interrompue.`);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token !== undefined && token !== null) {
      deleteTokenBackend(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else {
    return (
      <div className="loader-spin-empty-screen">
        <Result
          icon={<SmileOutlined />}
          title="Great, we have done all the operations!"
          extra={<Button type="primary">Next</Button>}
        />
      </div>
    );
  }
};
export default LogoutPage;
