import React from "react";
import { Input, Form, Select } from "antd";

const { Option } = Select;

const titles = [
  "Monsieur et Madame",
  "Madame et Monsieur",
  "Messieurs",
  "Mesdames",
  "Monsieur",
  "Madame",
];

const typologies = ["1p", "2p", "3p", "4p", "5p"];

const AddNewLotForm = ({ form }) => {
  return (
    <div>
      Veuillez remplir les informations nécessaires à l'ajout d'un nouveau lot :
      <br />
      <br />
      <Form form={form} layout="vertical">
        <Form.Item
          name="lotcomm"
          label="No de lot"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un numéro de lot",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: A208" />
        </Form.Item>
        <Form.Item
          name="titre"
          label="Titre"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un titre",
            },
          ]}
          hasFeedback
        >
          <Select placeholder="Sélectionner un titre">
            {titles.map((t, idx) => {
              return (
                <Option value={t} key={`opt-${idx}`}>
                  {t}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nom"
          name="firstname"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le nom",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: DUPONT Michel et Agnès" />
        </Form.Item>
        <Form.Item
          label="Adresse"
          name="adresse"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: 30 avenue de la grand armée" />
        </Form.Item>
        <Form.Item
          label="Code postal"
          name="codepostal"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un code postal",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: 75008" />
        </Form.Item>
        <Form.Item
          label="Ville"
          name="ville"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une ville",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: Paris" />
        </Form.Item>
        <Form.Item
          name="mailmonsieur"
          label="Mail acquéreur"
          required
          rules={[
            {
              type: "email",
              required: true,
              message: "Mail acquéreur non valide !",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Téléphone 1"
          name="telephonemonsieur"
          required
          rules={[
            {
              required: true,
              pattern: new RegExp(
                /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
              ),
              message:
                "Veuillez renseigner au moins un numéro de téléphone (format : 0689... ou +337...)",
            },
          ]}
        >
          <Input placeholder="ex: 067718... ou +33677..." />
        </Form.Item>

        <Form.Item
          name="mailmadame"
          label="Mail co-acquéreur"
          rules={[
            {
              type: "email",
              message: "Mail co-acquéreur non valide !",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Téléphone 2"
          name="telephonemadame"
          rules={[
            {
              pattern: new RegExp(
                /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
              ),
              message:
                "Téléphone non valide (format accepté : 0689... ou +337...)",
            },
          ]}
        >
          <Input placeholder="ex: 067718... ou +33677..." />
        </Form.Item>

        <Form.Item
          name="typologie"
          label="Typologie"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner la typologie",
            },
          ]}
          hasFeedback
        >
          <Select placeholder="Veuillez sélectionner une typologie">
            {typologies.map((t, idx) => {
              return (
                <Option value={t} key={`typ-${idx}`}>
                  {t}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Bâtiment"
          name="batiment"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le numéro du bâtiment",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: A" />
        </Form.Item>

        <Form.Item
          label="Étage"
          name="etage"
          required
          rules={[
            {
              required: true,
              message: "Veuillez renseigner l'étage",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="ex: 0 (pour rez-de-chaussé)" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddNewLotForm;
