import React, { useEffect, useState } from "react";
import ContentContainer from "./../../components/ContentContainer";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";
import PlanningsTable from "../../components/admin/PlanningsTable";
import axios from "axios";
import { getBasicAuthConfig } from "../../utils";
import qs from "qs";
import { App } from "antd";

const AdminDashboard = () => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);

  const handleTableChange = async (pagination, filters, sorter) => {
    try {
      let query = qs.stringify({ params: { pagination, filters, sorter } });
      const res = await axios.get(
        process.env.REACT_APP_RDV_API_URL + `/plannings/q?${query}`,
        getBasicAuthConfig()
      );
      setData(res.data.plannings);
      setPagination({ ...pagination, total: res.data.totalCount });
    } catch (e) {
      console.log(e);
      message.error("Une erreur est survenue lors de la pagination.");
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      setLoading(true);
      try {
        let query = qs.stringify({
          params: {
            pagination: { ...pagination },
            // order: "descend",
            // field: "planningEndTime",
            // columnKey: "planningEndTime",
          },
        });

        const res = await axios.get(
          process.env.REACT_APP_RDV_API_URL + `/plannings/q?${query}`,
          getBasicAuthConfig()
        );
        setData(res.data.plannings);

        setPagination({
          current: 1,
          pageSize: 10,
          total: res.data.totalCount,
        });
      } catch (e) {
        message.error(`Erreur lors du chargement des plannings`);
        console.log(e);
      }
      setIsLoading(false);
      setLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          // style={{ margin: "0px 16px 0px 16px" }}
          title="Plannings"
          className="site-page-header"
          avatar={{
            src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
          }}
        ></CustomPageHeader>
        {!isLoading && (
          <PlanningsTable
            data={data}
            pagination={pagination}
            loading={loading}
            setLoading={setLoading}
            handleTableChange={handleTableChange}
          />
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default AdminDashboard;
