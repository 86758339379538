import React from "react";
import ProgramListItem from "./ProgramListItem";
import { Col, Row } from "antd";

const ProgramList = ({ programsList, removeProgram }) => {
  return (
    <Row gutter={40} style={{ display: "flex", justifyContent: "center" }}>
      {programsList.map((program, index) => {
        return (
          <div key={"l-" + index}>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 2 }}
              xl={{ span: 1 }}
            >
              <ProgramListItem
                key={program._id}
                program={program}
                removeProgram={removeProgram}
              />
            </Col>
          </div>
        );
      })}
    </Row>
  );
};

export default ProgramList;
