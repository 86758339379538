import React, { useState } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { MainRouter } from "./Routers/MainRouter";
import { UserContextProvider } from "./context/UserContext";
import "./styles/styles.scss";

import { ConfigProvider, App } from "antd";
// import frFR from "antd/lib/locale/fr_FR";
import frFR from "antd/locale/fr_FR";
// import frFR from "antd/es/date-picker/locale/fr_FR";

const SubApp = () => {
  const [locale, setLocal] = useState(frFR);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          // colorPrimary: "#00b96b",
          colorPrimary: "#436386", //"#436386",
          colorSuccess: "#436386", //436386
          colorInfo: "#436386",
          colorWarning: "#ff7875",
          controlOutline: "none",
          controlOutlineWidth: 0,
          // colorInfoBg: "#436386", // TODO: Change
          // colorBgContainer: "#f6ffed", // TOP !!
          // algorithm: theme.darkAlgorithm,
        },
      }}
    >
      {" "}
      <App>
        <UserContextProvider>
          <MainRouter />
        </UserContextProvider>
      </App>
    </ConfigProvider>
  );
};

ReactDOM.render(<SubApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
