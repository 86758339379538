import React from "react";
import { message, Modal, Select, Form, Input } from "antd";
const { Option } = Select;

const titles = [
  {
    name: "lotTypo",
    label: "Lot + Typologie",
  },
  {
    name: "lot",
    label: "Lot",
  },
  {
    name: "typo",
    label: "Typologie",
  },
  {
    name: "lotName",
    label: "Lot + Nom",
  },
  {
    name: "nameLotTypo",
    label: "Nom + Lot + Typologie",
  },
  {
    name: "nameTypo",
    label: "Nom + Typologie",
  },
  {
    name: "lotNameTypoTel",
    label: "Lot + Nom + Typologie + Téléphone",
  },
];

const TitlePersonnalizeModal = ({ show, setShow, update, form }) => {
  // const [form] = Form.useForm();

  return (
    <Modal
      title="Personnalisez le titre des rendez-vous"
      style={{ top: 40 }}
      open={show}
      onOk={update}
      onCancel={() => setShow(false)}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ customTitle: "lotTypo" }}
      >
        <Form.Item
          name="customTitle"
          label="Que voulez-vous faire apparaître dans le titre de vos rendez-vous ?"
          rules={[
            {
              required: true,
              message:
                "Veuillez sélectionner les données à afficher dans le titre",
            },
          ]}
        >
          <Select
            optionFilterProp="label"
            // allowClear
          >
            {titles.map((title) => {
              return (
                <Option key={title.name} value={title.name} label={title.label}>
                  {title.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TitlePersonnalizeModal;
