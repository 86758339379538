import moment from "moment";
import { roundToTwo } from "./../utils";

export default (events, lunchStartTime, lunchEndTime) => {
  // return JSON.stringify(events);
  // console.log(
  //   `lunchStartTime : ${lunchStartTime}, lunchEndTime = ${lunchEndTime}`
  // );

  let dateLunchStartTime = moment(lunchStartTime, "HH:mm");
  let dateLunchEndTime = moment(lunchEndTime, "HH:mm");
  // console.log("DATE START = " + JSON.stringify(dateLunchStartTime));
  let duration = moment.duration(dateLunchEndTime.diff(dateLunchStartTime));
  let lunchHours = duration.asHours();
  // console.log("FOUND DURATION ==== " + lunchHours);
  let num = events
    .map((x) => {
      let unitHour = 0;
      unitHour =
        (new Date(x.end).getTime() - new Date(x.start).getTime()) * 2.77778e-7;
      // console.log("UnitHour --> " + unitHour);
      return (unitHour - lunchHours) * x.nbAmo;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  // num = num.toFixed(1);
  // console.log(`original : ${num} (toFixed : ${num.toFixed(1)})`);
  return roundToTwo(num);
};
