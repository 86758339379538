import React from "react";
import ContentContainer from "../components/ContentContainer";
import { Typography } from "antd";
import SubContentContainer from "../components/SubContentContainer";
import CustomPageHeader from "../components/CustomPageHeader";
const { Paragraph, Link, Text } = Typography;

const HelpPage = () => {
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader arrow={true} title="Aide" />
        <Paragraph>
          Toute question est adressable à l'adresse mail suivante:{" "}
          <Link href="information@bridg-it.fr">information@bridg-it.fr</Link>.
        </Paragraph>
        <Paragraph>
          Ou par 📞 au : <Link href="0677403197">06 77 40 31 97</Link>.
        </Paragraph>
        <Paragraph>
          <Text strong>
            Sachez que nous répondons généralement dans un délais très bref.
          </Text>
        </Paragraph>
      </SubContentContainer>
    </ContentContainer>
  );
};

export default HelpPage;
