import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
// import { flexibleCompare } from "@fullcalendar/core";

const NotFoundPage = () => {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Désolé, la page à laquelle vous souhaitez accéder n'exite pas."
        extra={
          <Button type="primary">
            <Link to="/">Retour</Link>
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
