import React, { useState } from "react";
import { Space, Radio, Tag, Switch, Button, Divider, Typography } from "antd";
import { typoColors, globalTimes } from "./planningData";

const { Title, Text } = Typography;

const VisitTimeSelector = ({
  form,
  meetingTimes,
  setMeetingTimes,
  orgMeetingTimes,
  phase,
  setPhase,
  planningType,
  calendarRef,
  dispatch,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showSmallLengths, setShowSmallLengths] = useState(false);
  const typos = Object.entries(meetingTimes);
  const times = Object.entries(globalTimes);

  const onChange = () => {
    setIsDisabled(!isDisabled);
  };

  const onChangeSmallLengths = () => {
    let vals = Object.values(meetingTimes);
    const isSmallLength = (x) => x <= 2700000;

    // Si des petites valeurs sont selectionnées et
    // qu'on ne les voit plus, remettre a la plus petite
    // période visible aka 1h
    if (vals.some(isSmallLength)) {
      let isOneSmallLengthSelected = false;
      let cloneObj = { ...meetingTimes };
      for (const [k, v] of Object.entries(meetingTimes)) {
        if (v <= 2700000) {
          isOneSmallLengthSelected = true;
          cloneObj[k] = 3600000;
        }
      }
      setMeetingTimes(cloneObj);
    }

    setShowSmallLengths(!showSmallLengths);
  };

  const onPhaseChange = (e) => {
    // console.log(`phase change`);
    // console.log(calendarRef);
    if (planningType === "promoterImpose") {
      dispatch({
        type: "MODIFY_LENGTH_MULTIPLE",
        calendarRef,
        mTimes: orgMeetingTimes[e.target.value],
      });
    }
    setPhase(e.target.value);
    setMeetingTimes(orgMeetingTimes[e.target.value]);
  };

  const init = () => {
    setMeetingTimes(orgMeetingTimes[phase]);
  };
  return (
    <div>
      <Title level={4}>Phase</Title>
      <br />

      <Radio.Group
        defaultValue={phase}
        buttonStyle="solid"
        size="medium"
        onChange={onPhaseChange}
      >
        <Radio.Button value="choices">Choix</Radio.Button>
        <Radio.Button value="partitions">Cloisons</Radio.Button>
        <Radio.Button value="predelivery">Pré-livraisons</Radio.Button>
        <Radio.Button value="delivery">Livraisons</Radio.Button>
        {/* <Radio.Button value="custom">Custom</Radio.Button> */}
      </Radio.Group>
      <br />
      <Divider dashed />
      <Title level={4}>Durée des visites</Title>
      <Text>
        Configurer le temps pour effectuer les visites en fonction de la
        typologie du logement (1 pièce, 2 pièces, ..., 5 pièces).
      </Text>
      <br />
      <br />

      <Space>
        <Switch onChange={onChange} />
        Modifier
        <Button type="link" onClick={init}>
          réinitialiser
        </Button>
        <Switch
          size="small"
          onChange={onChangeSmallLengths}
          disabled={!isDisabled}
        />
        Petites durées
      </Space>
      <br />
      <br />
      {typos.map((x, idx) => {
        return (
          <div key={idx + "-" + x}>
            <Tag
              color={typoColors[x[0].toLowerCase()]}
              style={{ fontSize: "16px", padding: "5px" }}
            >
              {x[0].toUpperCase()}
            </Tag>

            <Radio.Group
              defaultValue={x[1]}
              buttonStyle="solid"
              style={{ padding: "5px" }}
            >
              {times.map((y, idx2) => {
                if (parseInt(y[1]) > 2700000 && !showSmallLengths) {
                  return (
                    <Radio.Button
                      key={idx2 + "-" + y}
                      value={y[1]}
                      disabled={!isDisabled}
                      onChange={(e) => {
                        let newMeetingTimes = {
                          ...meetingTimes,
                          [x[0]]: e.target.value,
                        };
                        if (planningType === "promoterImpose") {
                          dispatch({
                            type: "MODIFY_LENGTH_MULTIPLE",
                            calendarRef,
                            mTimes: newMeetingTimes,
                          });
                        }
                        setMeetingTimes(newMeetingTimes);
                      }}
                    >
                      {y[0]}
                    </Radio.Button>
                  );
                } else if (showSmallLengths) {
                  return (
                    <Radio.Button
                      key={idx2 + "-" + y}
                      value={y[1]}
                      disabled={!isDisabled}
                      onChange={(e) => {
                        let newMeetingTimes = {
                          ...meetingTimes,
                          [x[0]]: e.target.value,
                        };
                        if (planningType === "promoterImpose") {
                          dispatch({
                            type: "MODIFY_LENGTH_MULTIPLE",
                            calendarRef,
                            mTimes: newMeetingTimes,
                          });
                        }
                        setMeetingTimes(newMeetingTimes);
                      }}
                    >
                      {y[0]}
                    </Radio.Button>
                  );
                }
              })}
            </Radio.Group>
          </div>
        );
      })}
    </div>
  );
};

export default VisitTimeSelector;
