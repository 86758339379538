import React from "react";
import { message, Modal, Form, Input, Typography } from "antd";
import validator from "validator";
import axios from "axios";
import Tips from "./Tips";
import { getRdvApiAuth, areBalisesOk, getMissingBalises } from "../../../utils";
import { originCustomMail } from "../planningData";

const { Text } = Typography;

const CustomMailModal = ({
  show,
  setShow,
  selected,
  planning,
  fetchPlanningAndFmtEvents,
  unSelectedAllRows,
}) => {
  const [form] = Form.useForm();

  const onCreate = async (values) => {
    try {
      const uuids = selected.map((x) => x.uuid);
      await axios.post(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          planning._id +
          "/mail-personnalise",
        // { customMail: values.customMail, subject: values.subject, uuids },
        { ...values, uuids },
        getRdvApiAuth()
      );
      message.success(`${uuids.length} Mail(s) envoyé(s) !`);
      form.resetFields();
      await fetchPlanningAndFmtEvents();
      unSelectedAllRows();
      setShow(false);
    } catch (e) {
      console.log(e);
    }
  };

  const update = async () => {
    try {
      const values = await form.validateFields(["customMail", "subject", "cc"]);
      onCreate(values);
    } catch (e) {
      console.log(e);
    }
  };

  const areEmailsValid = (list) => {
    if (list === undefined || list === "") return true;
    list = list.split(";");
    return list.every((val) => validator.isEmail(val.trim()));
  };

  return (
    <Modal
      title="Mail aux acquéreurs"
      style={{ top: 40 }}
      open={show}
      onOk={update}
      onCancel={() => setShow(false)}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          customMail: originCustomMail,
          subject: `[${planning.promoter.toUpperCase()}] `,
        }}
      >
        <Text type="danger">*&nbsp;</Text>
        <Text>Sujet :</Text>
        <br />
        <br />
        <Form.Item
          name="subject"
          help={`Commencent toujours par [${planning.promoter.toUpperCase()}]`}
          rules={[
            {
              validator: async (_, payload) => {
                let missings = getMissingBalises(planning, payload);
                if (!areBalisesOk(payload)) {
                  return Promise.reject(
                    new Error(
                      `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                    )
                  );
                } else if (missings.length > 0) {
                  return Promise.reject(
                    new Error(
                      `Balise(s) ${missings} non disponible(s) sur ce planning.`
                    )
                  );
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Text>Envoi du rapport d'envoi groupé à :</Text>
        <br />
        <br />
        <Form.Item
          name="cc"
          help={`Séparer par des ';' si plusieurs
          mails`}
          rules={[
            {
              validator: async (_, payload) => {
                if (!areEmailsValid(payload)) {
                  return Promise.reject(
                    new Error(
                      `Les adresses email indiquées ne sont pas valides.`
                    )
                  );
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <br />
        <Text type="danger">*&nbsp;</Text>
        <Text>Message :</Text>
        <br />
        <br />
        <Form.Item
          name="customMail"
          rules={[
            {
              validator: async (_, payload) => {
                let missings = getMissingBalises(planning, payload);
                if (!areBalisesOk(payload)) {
                  return Promise.reject(
                    new Error(
                      `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                    )
                  );
                } else if (missings.length > 0) {
                  return Promise.reject(
                    new Error(
                      `Balise(s) ${missings} non disponible(s) sur ce planning.`
                    )
                  );
                }
              },
            },
          ]}
        >
          <Input.TextArea rows={18} />
        </Form.Item>
        <Tips />
      </Form>
    </Modal>
  );
};

export default CustomMailModal;
