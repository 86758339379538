import ContentContainer from "./../ContentContainer";
import PublicFooter from "./../public/Footer";
import { ToolOutlined } from "@ant-design/icons";
import { Button, Layout, Result } from "antd";

import { closeWindow } from "./../../utils";

const PlanningNotFound = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <ContentContainer>
          <div>
            <Result
              icon={<ToolOutlined />}
              title="Planning non trouvé"
              subTitle={
                <div>
                  Veuillez contacter la personne qui vous a envoyé ce lien
                  unique.
                  <br />
                  <a href="mailto:information@bridg-it.fr">
                    information@bridg-it.fr
                  </a>
                </div>
              }
              extra={
                <Button type="primary" onClick={closeWindow}>
                  Quitter
                </Button>
              }
            />
          </div>
        </ContentContainer>
        <PublicFooter />
      </Layout>
    </Layout>
  );
};

export default PlanningNotFound;
