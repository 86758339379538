import React from "react";
import { Select, Checkbox, Form, Input, Button, Space, Row, Col } from "antd";
import {
  getAmoFromAmoList,
  isFieldOk,
  customTags,
  getKeyByValue,
  hasFieldToBeProvided,
} from "../../utils";

const { Option } = Select;

const Contact = ({ form, usedBalises }) => {
  return (
    <div>
      <Form form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              form={form}
              name="contactTitre"
              label="Titre"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("contactTitre", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided("contactTitre", usedBalises),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Select>
                <Option value="Monsieur">Monsieur</Option>
                <Option value="Madame">Madame</Option>
                <Option value="Autre">Autre</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={18}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Nom"
              name="contactNom"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("contactNom", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided("contactNom", usedBalises),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Prénom"
              name="contactPrenom"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("contactPrenom", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided("contactPrenom", usedBalises),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Email"
              name="contactMail"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("contactMail", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided("contactMail", usedBalises),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Téléphone"
              name="contactTelephone"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("contactTelephone", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided(
                    "contactTelephone",
                    usedBalises
                  ),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Signature"
              name="signature"
              rules={[
                {
                  validator: async (_, payload) => {
                    if (!isFieldOk("signature", payload, usedBalises)) {
                      return Promise.reject(
                        new Error(`Ce champ est à remplir !`)
                      );
                    }
                  },
                  required: hasFieldToBeProvided("signature", usedBalises),
                  message: "Ce champ est à remplir !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
