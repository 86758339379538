import React, { useState, useEffect, useContext } from "react";
import {
  Descriptions,
  Divider,
  Typography,
  Button,
  Row,
  Col,
  Progress,
  Dropdown,
  Spin,
  Form,
  Tooltip,
  Space,
  Drawer,
  Avatar,
  Tag,
  Table,
  Badge,
  Result,
  App,
  Input,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  PlusSquareTwoTone,
  MinusSquareTwoTone,
  ExclamationCircleOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import ContentContainer from "../../../components/ContentContainer";
import PlanningBookingTableImpose from "../../../components/plannings/PlanningBookingTableImpose";
import PlanningOptions from "../../../components/plannings/PlanningOptions";
import CustomMailModal from "../../../components/plannings/modals/CustomMailModal";
import ModifyContactModal from "../../../components/plannings/modals/ModifyContactModal";
import ModifyAddressModal from "../../../components/plannings/modals/ModifyAddressModal";
import AddCustomerModalImpose from "../../../components/plannings/modals/AddCustomerModalImpose";
import ApplyTemplateModal from "../../../components/plannings/modals/ApplyTemplateModal";
import RemoveCustomerModalImpose from "../../../components/plannings/modals/RemoveCustomerModalImpose";
import selectClientTotalTime from "../../../selectors/clients-total-time";
import RappelsRelancesModal from "../../../components/plannings/modals/RappelsRelancesModal";
import ShowAlertedModal from "../../../components/plannings/modals/ShowAlertedModal";
import ShowShareModal from "../../../components/plannings/modals/ShowShareModal";
import ShowShareSMSModal from "../../../components/plannings/modals/ShowShareSMSModal";
import LimitAnswerModal from "../../../components/plannings/modals/LimitAnswerModal";
import AgendaReadOnlyImpose from "../../../components/AgendaReadOnlyImpose";
import { phaseTransform } from "../../../components/plannings/planningData";
import { UserContext } from "../../../context/UserContext";
import {
  getBasicAuthConfig,
  getInitials,
  getTypologies,
  customTags,
  formatEventsImpose,
  getRdvApiAuth,
  getMissingBalises,
  areBalisesOk,
  getAmoNb,
} from "../../../utils";
import { bankHolidays } from "../../../components/plannings/planningData";
import Tips from "../../../components/plannings/modals/Tips";
import SubContentContainer from "../../../components/SubContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const { Text, Title } = Typography;

const PlanningImpose = () => {
  const { role } = useContext(UserContext);
  const { message, notification, modal } = App.useApp();
  const [planning, setPlanning] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [wait, setWait] = useState(false);
  const [showCustomMail, setShowCustomMail] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showRemoveCustomer, setShowRemoveCustomer] = useState(false);
  const [showModifyContact, setShowModifyContact] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showShareSMS, setShowShareSMS] = useState(false);
  const [showAlerted, setShowAlerted] = useState(false);
  const [showRappelsRelances, setShowRappelsRelances] = useState(false);
  const [limitAnswerModal, setLimitAnswerModal] = useState(false);
  const [modifyAddressModal, setModifyAddressModal] = useState(false);
  const [form] = Form.useForm();

  const [value, setValue] = useState(2);
  const [eventSourcetoSend, setEventSourcetoSend] = useState([]);
  const [fmtEvents, setFmtEvents] = useState(undefined);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [amos, setAmos] = useState({});
  const [address, setAddress] = useState({});
  const [typos, setTypos] = useState(undefined);
  const [dataSourceMiniTable, setDataSourceMiniTable] = useState(undefined);
  const [columnsMiniTable, setColumnsMiniTable] = useState(undefined);
  const [config, setConfig] = useState("lotTypo");

  const [isShowingUnavailables, setIsShowingUnavailables] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [isNotifMail, setIsNotifMail] = useState(false);
  const [isNotifSMS, setIsNotifSMS] = useState(false);
  const [isPlanningTypeOk, setIsPlanningTypeOk] = useState(false);
  const [showApplyTemplate, setShowApplyTemplate] = useState(false);
  const [usedBalises, setUsedBalises] = useState("");

  const [messages, setMessages] = useState({});
  const [showFreeMeetings, setShowFreeMeetings] = useState(true);

  const expandAll = () => {
    setExpandedRowKeys(planning.broadcastList.map((_) => _.uuid));
    setIsAllExpanded(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const openUnavailableCustomers = () => {
    if (!isShowingUnavailables) {
      setIsShowingUnavailables(true);
      setFilteredInfo({
        lotcomm: null,
        batiment: null,
        etage: null,
        nom: null,
        typologie: null,
        isInterestedVr: null,
        booked: ["indisponible"],
        dateBooked2: null,
        rdvLength: null,
      });
      setExpandedRowKeys(
        planning.broadcastList.filter((_) => !_.isAvailable).map((_) => _.uuid)
      );
    } else {
      setIsShowingUnavailables(false);
      setFilteredInfo({
        lotcomm: null,
        batiment: null,
        etage: null,
        nom: null,
        typologie: null,
        isInterestedVr: null,
        booked: null,
        dateBooked2: null,
        rdvLength: null,
      });
      setExpandedRowKeys([]);
    }
  };

  const collapseAll = () => {
    setExpandedRowKeys([]);
    setIsAllExpanded(false);
  };

  const unSelectedAllRows = () => {
    setSelectedRowKeys([]);
    setSelected([]);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const fetchPlanningAndFmtEvents = async (
    flag = "lotTypo",
    showFreeMeetings = false
  ) => {
    const res = await axios.get(
      process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id
    );

    setPlanning(res.data.planning);
    let f = formatEventsImpose(
      res.data.planning,
      flag,
      showFreeMeetings,
      null,
      fmtBankHolidays
    );
    setFmtEvents(f[0]);
    setEventSourcetoSend(f[1]);
  };

  const openReadOnlyView = () => {
    window.open(`/pview/${planning.uuid}`, "_blank");
  };

  useEffect(() => {
    const newFunc = async (planningId) => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planningId
        );
        if (res.data.planning.templateType === "promoterImpose") {
          setIsPlanningTypeOk(true);
        }
        setPlanning(res.data.planning);

        setAddress({
          numero: res.data.planning.address.numero,
          rue: res.data.planning.address.rue,
          codepostal: res.data.planning.address.codepostal,
          ville: res.data.planning.address.ville,
          pays: res.data.planning.address.pays,
        });
        // console.log("newFunc()");
        // console.log(res.data.planning.amoList);
        if (res.data.planning.amoList.length !== 0) {
          // console.log("AVANT");
          const res2 = await axios.get(
            process.env.REACT_APP_API_URL + `/users/amos`,
            // { ids: res.data.planning.amoList },
            getBasicAuthConfig()
          );
          // console.log(res2.data);
          setAmos(
            res2.data
              .filter((_) => res.data.planning.amoList.includes(_._id))
              .map((user) => ({
                id: user._id,
                initials: getInitials(user.lastname, user.firstname),
                firstname: user.firstname,
                lastname: user.lastname,
              }))
          );
        }

        let f = formatEventsImpose(
          res.data.planning,
          "lotTypo",
          showFreeMeetings, // NEW !!
          null,
          fmtBankHolidays
        );

        setFmtEvents(f[0]);
        setEventSourcetoSend(f[1]);

        // Mis a la fin car pas le temps de charger -> pose probleme ?
        form.setFieldsValue({
          mailinvitationmessage: res.data.planning.mailinvitationmessage,
          mailconfirmationmessage: res.data.planning.mailconfirmationmessage,
          smsrelancemessage: res.data.planning.smsrelancemessage,
          smsrappelmessage: res.data.planning.smsrappelmessage,
          mailrelancemessage: res.data.planning.mailrelancemessage,
          mailrappelmessage: res.data.planning.mailrappelmessage,
          contactTitre: res.data.planning.contactTitre,
          contactNom: res.data.planning.contactNom,
          contactPrenom: res.data.planning.contactPrenom,
          contactTelephone: res.data.planning.contactTelephone,
          contactMail: res.data.planning.contactMail,
          signature: res.data.planning.signature,
        });

        let bigStr =
          res.data.planning.mailrelancemessage +
          res.data.planning.mailrappelmessage +
          res.data.planning.smsrelancemessage +
          res.data.planning.smsrappelmessage +
          res.data.planning.mailinvitationmessage +
          res.data.planning.mailconfirmationmessage;
        let balises = bigStr.match(/<(.+?)>/g) || [];
        balises = balises.filter((_) => Object.keys(customTags).includes(_));
        balises = Array.from(new Set(balises));
        // Please not that usedBalises are only the one that matters for Contact, not all !
        setUsedBalises(balises);

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };
    const fullPath = window.location.pathname.split("/");
    const planningId = fullPath[fullPath.length - 1];
    if (isLoading) {
      newFunc(planningId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let f = formatEventsImpose(
        planning,
        config,
        showFreeMeetings,
        null,
        fmtBankHolidays
      );

      setFmtEvents(f[0]);
      setEventSourcetoSend(f[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, config]);

  useEffect(() => {
    if (planning.broadcastList !== undefined) {
      let unsorted = getTypologies(planning.broadcastList.map((x) => x.guest));
      const sorted = Object.keys(unsorted)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unsorted[key];
          return obj;
        }, {});

      setTypos(sorted);

      let sourceMiniTable = {};
      let colsMiniTable = [];

      for (const [index, [key, value]] of Object.entries(
        Object.entries(sorted)
      )) {
        if (index == 0) {
          sourceMiniTable["rowKey"] = key;
        }
        sourceMiniTable[key] = value;
        colsMiniTable.push({
          title: key,
          dataIndex: key,
          key: key,
        });
      }
      setDataSourceMiniTable([sourceMiniTable]);
      setColumnsMiniTable(colsMiniTable);
    }
  }, [planning]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const DropdownMenuEye = (key) => {
    const items = [
      {
        label: (
          <a onClick={openReadOnlyView} icon={<EyeOutlined />}>
            Aperçu
          </a>
        ),
        key: "1",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              setShowShare(true);
            }}
          >
            Envoyer par Mail
          </a>
        ),
        key: "2",
      }, // which is required
      {
        label: (
          <a
            onClick={() => {
              setShowShareSMS(true);
            }}
          >
            Envoyer par SMS
          </a>
        ),
        key: "3",
      },
    ];
    return (
      <Dropdown key={"eye-" + key} menu={{ items }}>
        <Button icon={<EyeOutlined />}></Button>
      </Dropdown>
    );
  };

  const onCreate = async (values, str, request) => {
    try {
      if (request) {
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
          { [str]: values },
          getRdvApiAuth()
        );
      }

      setMessages({
        ...messages,
        [str]: values,
      });

      let bigStr =
        messages.mailrelancemessage +
        messages.mailrappelmessage +
        messages.smsrelancemessage +
        messages.smsrappelmessage +
        messages.mailconfirmationmessage;
      bigStr += values;

      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));

      message.success(`Message modifié avec succès`);
      setUsedBalises(balises);
    } catch (e) {
      console.log(e);
    }
  };

  const generatedContent = (field, title, request) => {
    let before = form.getFieldValue(field);
    modal.confirm({
      icon: null,
      title,
      width: "600px",
      content: (
        <div style={{ width: "550px" }}>
          <Form
            form={form}
            name="form_in_modal2"
            // initialValues={{ mailRelance: messages.mailInvitation }}
          >
            <Text type="danger">*&nbsp;</Text>
            <Text>Message :</Text>
            <br />
            <br />
            <Form.Item
              name={field}
              rules={[
                {
                  validator: async (_, payload) => {
                    let missings = getMissingBalises(planning, payload);
                    if (!areBalisesOk(payload)) {
                      return Promise.reject(
                        new Error(
                          `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                        )
                      );
                    } else if (request && missings.length > 0) {
                      return Promise.reject(
                        new Error(
                          `Balise(s) ${missings} non disponible(s) sur ce planning.`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input.TextArea rows={18} />
            </Form.Item>
            <Tips />
          </Form>
        </div>
      ),
      async onOk() {
        try {
          const values = await form.validateFields([field]);
          await onCreate(values[field], field, request);
        } catch (e) {
          message.error("Veuillez vérifier les données entrées");
          form.setFieldValue(field, before);
        }
      },
      onCancel() {
        form.setFieldValue(field, before);
      },
    });
  };

  const People = ({ amos, events }) => {
    // console.log(JSON.stringify(events));

    function maxOverlap(appointments) {
      // Convertir les chaînes de date en objets Date pour faciliter la comparaison
      appointments.forEach((appt) => {
        appt.start = new Date(appt.start);
        appt.end = new Date(appt.end);
      });

      // Trier les rendez-vous par date de début
      appointments.sort((a, b) => a.start - b.start);

      // Créer des tableaux séparés pour les heures de début et de fin
      let startTimes = appointments.map((a) => a.start);
      let endTimes = appointments.map((a) => a.end);

      let maxOverlap = 0,
        currentOverlap = 0,
        endIndex = 0;

      // Parcourir les heures de début
      for (let startTime of startTimes) {
        currentOverlap++; // Un nouveau rendez-vous commence

        // Vérifier si un rendez-vous se termine avant que le rendez-vous actuel ne commence
        while (endTimes[endIndex] <= startTime) {
          currentOverlap--;
          endIndex++;
        }

        maxOverlap = Math.max(maxOverlap, currentOverlap);
      }

      return maxOverlap;
    }

    let amoNb = maxOverlap(events);
    // console.log(`amoNb : ${amoNb}`);
    // console.log(amos);
    let p = null;
    if (JSON.stringify(amos) === "{}") {
      p = (
        <Avatar
          style={{
            backgroundColor: "#f56a00",
          }}
        >
          {"-"}
        </Avatar>
      );
    } else {
      p = amos.map((amo, idx) => {
        return (
          <Tooltip
            key={`avt-${idx}`}
            title={`${amo.lastname} ${amo.firstname}`}
            placement="top"
          >
            <Avatar
              src={
                process.env.REACT_APP_API_URL +
                "/users/" +
                amos[idx].id +
                "/avatar"
              }
              style={{
                backgroundColor: "#f56a00",
              }}
            >
              {amos[idx].initials}
            </Avatar>
          </Tooltip>
        );
      });

      let stop = amoNb - amos.length;

      p = p.concat(
        Array.apply(null, { length: stop }).map((e, i) => (
          <Tooltip key={`avt2-${i}`} placement="top">
            <Avatar
              style={{
                backgroundColor: "#f56a00",
              }}
            >
              {"-"}
            </Avatar>
          </Tooltip>
        ))
      );
    }

    // p = p.concat(
    //   amoAgendas.map((e, i) => {
    //     if (i < stop)
    //       return (
    //         <Tooltip key={`avt-${i}`} placement="top">
    //           <Avatar
    //             style={{
    //               backgroundColor: "#f56a00",
    //             }}
    //           >
    //             {"-"}
    //           </Avatar>
    //         </Tooltip>
    //       );
    //   })
    // );

    return p;
  };

  const DropdownMenuPersonalize = (key) => {
    const items = [
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailinvitationmessage",
                "Personnalisation du mail d'invitation",
                true
              );
            }}
          >
            Mail Invitation
          </a>
        ),
        key: "11",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailconfirmationmessage",
                "Personnalisation du mail de confirmation",
                true
              );
            }}
          >
            Mail Confirmation
          </a>
        ),
        key: "22",
      }, // which is required
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailrappelmessage",
                "Personnalisation du mail de rappel",
                true
              );
            }}
          >
            Mail Rappel
          </a>
        ),
        key: "33",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailrelancemessage",
                "Personnalisation du mail de relance",
                true
              );
            }}
          >
            Mail Relance
          </a>
        ),
        key: "44",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "smsrappelmessage",
                "Personnalisation du SMS de rappel",
                true
              );
            }}
          >
            SMS Rappel
          </a>
        ),
        key: "55",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "smsrelancemessage",
                "Personnalisation du SMS de relance",
                true
              );
            }}
          >
            SMS Relance
          </a>
        ),
        key: "66",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowApplyTemplate(true);
            }}
          >
            Appliquer template
          </a>
        ),
        icon: <SelectOutlined />,
        key: "77",
      },
    ];
    return (
      <Dropdown menu={{ items }} key={"personalize-" + key}>
        <Button icon={<DownOutlined />}>Personnaliser</Button>
      </Dropdown>
    );
  };

  const DropdownMenuOptions = (key) => {
    const items = [
      {
        label: (
          <a
            onClick={() => {
              setShowAlerted(true);
            }}
          >
            Notifications
          </a>
        ),
        key: "1",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              setShowAddCustomer(true);
            }}
          >
            Ajout Acquéreur
          </a>
        ),
        key: "2",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowRemoveCustomer(true);
            }}
          >
            Suppression Acquéreur
          </a>
        ),
        key: "3",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowModifyContact(true);
            }}
          >
            Modifier le Contact
          </a>
        ),
        key: "4",
      },
    ];
    return (
      <Dropdown menu={{ items }} key={"option-" + key}>
        <Button icon={<DownOutlined />}>Options</Button>
      </Dropdown>
    );
  };

  if (wait || (!isPlanningTypeOk && wait)) {
    return (
      <ContentContainer>
        <div className="loader-spin">
          <Spin tip="Veuillez patienter SVP ..." />
        </div>
      </ContentContainer>
    );
  } else if (!isPlanningTypeOk && !isLoading) {
    return (
      <ContentContainer>
        <Result
          status="404"
          title="404"
          subTitle="Planning imposé non trouvé"
        />
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          {!isLoading && (
            <div>
              <CustomPageHeader
                title={planning.name}
                subTitle={
                  <Tooltip placement="top" title={`${planning.uuid}`}>
                    {`Visite ${phaseTransform[planning.phase]} `}
                    <Tag color="purple">Convocation</Tag>
                  </Tooltip>
                }
                arrow={true}
                extra={[
                  <DropdownMenuEye key="1" />,
                  <Button key="2" onClick={showDrawer}>
                    Calendrier
                  </Button>,
                  <DropdownMenuPersonalize key="3" />,
                  <DropdownMenuOptions key="4" />,
                ]}
              ></CustomPageHeader>

              {/* <div style={{ padding: "0px 24px 0px 24px" }}> */}
              <CustomMailModal
                show={showCustomMail}
                setShow={setShowCustomMail}
                selected={selected}
                planning={planning}
                unSelectedAllRows={unSelectedAllRows}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
              />
              {showApplyTemplate && (
                <ApplyTemplateModal
                  planning={planning}
                  show={showApplyTemplate}
                  setShow={setShowApplyTemplate}
                  form={form}
                  setUsedBalises={setUsedBalises}
                />
              )}

              {/* <CustomTimeModal
                show={showCustomTime}
                setShow={setShowCustomTime}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                planning={planning}
              /> */}
              <ModifyAddressModal
                show={modifyAddressModal}
                setShow={setModifyAddressModal}
                address={address}
                setAddress={setAddress}
                form={form}
              />
              <ModifyContactModal
                show={showModifyContact}
                setShow={setShowModifyContact}
                planning={planning}
                setPlanning={setPlanning}
                form={form}
                usedBalises={usedBalises}
                setUsedBalises={setUsedBalises}
              />
              <LimitAnswerModal
                show={limitAnswerModal}
                setShow={setLimitAnswerModal}
                planning={planning}
                unSelectedAllRows={unSelectedAllRows}
                setPlanning={setPlanning}
              />
              {planning.notifiedList !== undefined && (
                <ShowAlertedModal
                  show={showAlerted}
                  setShow={setShowAlerted}
                  planning={planning}
                  setPlanning={setPlanning}
                />
              )}
              <ShowShareModal
                show={showShare}
                setShow={setShowShare}
                planning={planning}
                setPlanning={setPlanning}
              />
              <ShowShareSMSModal
                show={showShareSMS}
                setShow={setShowShareSMS}
                planning={planning}
                setPlanning={setPlanning}
              />
              <AddCustomerModalImpose
                show={showAddCustomer}
                setShow={setShowAddCustomer}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                planning={planning}
                isLoading={isCustomerLoading}
                setIsLoading={setIsCustomerLoading}
              />
              <RemoveCustomerModalImpose
                show={showRemoveCustomer}
                setShow={setShowRemoveCustomer}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                planning={planning}
                isLoading={isCustomerLoading}
                setIsLoading={setIsCustomerLoading}
              />

              <Row align="middle">
                <Col span={3}>
                  <Space direction="vertical">
                    <Row>
                      <Progress
                        type="circle"
                        percent={Math.round(
                          (planning.nbConfirmed / planning.nbInvited) * 100
                        )}
                        strokeColor="#436386"
                        // width={100}
                      />
                    </Row>
                    <Row align="center">
                      <Tooltip
                        placement="bottom"
                        color="#436386"
                        title={
                          <Table
                            dataSource={dataSourceMiniTable}
                            columns={columnsMiniTable}
                            rowKey="rowKey"
                            size="small"
                            bordered={true}
                            pagination={false}
                          />
                        }
                      >
                        <Button type="dashed" size="small">
                          Typologies
                        </Button>
                      </Tooltip>
                    </Row>
                  </Space>
                </Col>
                <Col span={21}>
                  <Descriptions
                    bordered
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item
                      label={
                        new Date(planning.launchDate).getTime() >
                        new Date().getTime() ? (
                          <Tooltip
                            placement="top"
                            title={`Envoi programmé des invitations le ${moment(
                              planning.launchDate
                            ).format("DD/MM")} à ${moment(
                              planning.launchDate
                            ).format("HH:mm")}`}
                          >
                            <Badge
                              status="processing"
                              color="orange"
                              text="Invités"
                            />
                          </Tooltip>
                        ) : (
                          "Invités"
                        )
                      }
                    >
                      {planning.nbNotAvailable > 1 ? (
                        <div
                          style={{
                            verticalAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          {planning.nbInvited}
                          <Tooltip
                            placement="bottom"
                            title={planning.broadcastList
                              .filter((y) => !y.isAvailable)
                              .map((_, idx) => (
                                <div key={idx}>
                                  {_.guest.nom}
                                  <br />
                                </div>
                              ))}
                          >
                            <Button
                              type="link"
                              onClick={openUnavailableCustomers}
                            >
                              ({planning.nbNotAvailable} indispos)
                            </Button>
                          </Tooltip>
                        </div>
                      ) : planning.nbNotAvailable === 1 ? (
                        <div>
                          <div>
                            {planning.nbInvited}
                            <Tooltip
                              placement="bottom"
                              title={planning.broadcastList
                                .filter((y) => !y.isAvailable)
                                .map((_, idx) => (
                                  <div key={idx}>
                                    {_.guest.nom}
                                    <br />
                                  </div>
                                ))}
                            >
                              <Button
                                type="link"
                                onClick={openUnavailableCustomers}
                              >
                                ({planning.nbNotAvailable} indispo)
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div>{planning.nbInvited}</div>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label="Période">
                      {moment(planning.planningStartTime).format("ddd DD/MM")} -{" "}
                      {moment(planning.planningEndTime).format("ddd DD/MM")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Réponses">
                      {planning.nbConfirmed}

                      {" / "}
                      {planning.nbInvited}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date limite réponses">
                      <Space direction="horizontal">
                        {moment(planning.bookingDeadline).format(
                          "ddd DD MMM YYYY"
                        )}
                        <Button type="link">
                          <Tooltip title="editer">
                            <EditOutlined
                              onClick={() => setLimitAnswerModal(true)}
                            />
                          </Tooltip>
                        </Button>
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Équipe(s)">
                      <People amos={amos} events={planning.events} />
                      {/* {Object.keys(amos).length > 0 && (
                        <div>
                          {amos.map((amo, idx) => {
                            return (
                              <Tooltip
                                key={`avt-${idx}`}
                                title={`${amo.lastname} ${amo.firstname}`}
                                placement="top"
                              >
                                <Avatar
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    "/users/" +
                                    amos[idx].id +
                                    "/avatar"
                                  }
                                  style={{
                                    backgroundColor: "#f56a00",
                                  }}
                                >
                                  {amos[idx].initials}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                          {planning.areAmosAssigned && (
                            <div style={{ display: "inline-block" }}>
                              &nbsp;
                              <Tooltip
                                title="Cela signifie que les acquéreurs ont la possibilité de choisir leur AMO(s)"
                                placement="top"
                              >
                                <Tag
                                  icon={<ExclamationCircleOutlined />}
                                  color="warning"
                                >
                                  Assignés
                                </Tag>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      )} */}
                    </Descriptions.Item>
                    <Descriptions.Item label="Durée totale visites ">
                      {selectClientTotalTime(
                        planning.broadcastList
                          .filter((_) => _.isActive)
                          .map((x) => ({
                            duree: x.length,
                          })),
                        null
                      )}
                      {"h "}
                    </Descriptions.Item>
                    {planning.questions.length > 0 && (
                      <Descriptions.Item label="Question(s)">
                        <Tooltip
                          placement="bottom"
                          title={planning.questions.map((_, idx) => (
                            <div key={`q-${idx}`}>
                              {_}
                              <br />
                            </div>
                          ))}
                        >
                          <Button type="link">
                            {planning.questions.length}
                          </Button>
                        </Tooltip>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Adresse">
                      <Space direction="horizontal">
                        {planning.address.rue !== "" ? (
                          <div>
                            <EnvironmentOutlined />{" "}
                            {`${address.numero} ${address.rue} ${address.codepostal} ${address.ville} ${address.pays}`}
                          </div>
                        ) : (
                          <div>
                            <EnvironmentOutlined /> ?
                          </div>
                        )}
                        <Button type="link">
                          <Tooltip title="editer">
                            <EditOutlined
                              onClick={() => setModifyAddressModal(true)}
                            />
                          </Tooltip>
                        </Button>
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Divider />
              <Title level={5}>
                {!isAllExpanded ? (
                  <Tooltip title="Afficher détails">
                    <PlusSquareTwoTone
                      twoToneColor="#436386"
                      onClick={(e) => expandAll()}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Masquer détails">
                    <MinusSquareTwoTone
                      twoToneColor="#436386"
                      onClick={(e) => collapseAll()}
                    />
                  </Tooltip>
                )}
                &nbsp; Participants
              </Title>
              {selected.length > 0 ? (
                <div>
                  <PlanningOptions
                    selected={selected}
                    unSelectedAllRows={unSelectedAllRows}
                    fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                    setShowCustomMail={setShowCustomMail}
                    setShowRappelsRelances={setShowRappelsRelances}
                  />
                  <RappelsRelancesModal
                    isNotifMail={isNotifMail}
                    isNotifSMS={isNotifSMS}
                    setIsNotifMail={setIsNotifMail}
                    setIsNotifSMS={setIsNotifSMS}
                    show={showRappelsRelances}
                    setShow={setShowRappelsRelances}
                    selected={selected}
                    planning={planning}
                  />
                  <br />
                </div>
              ) : (
                <div>
                  <i>Séléctionner pour afficher les options</i>
                </div>
              )}

              <br />
              <PlanningBookingTableImpose
                planning={planning}
                rowSelection={rowSelection}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                expandedRowKeys={expandedRowKeys}
                setExpandedRowKeys={setExpandedRowKeys}
                filteredInfo={filteredInfo}
                handleChange={handleChange}
              />
            </div>
          )}

          {!isLoading && (
            <div>
              <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                width="80%"
              >
                <AgendaReadOnlyImpose
                  eventSources={eventSourcetoSend}
                  setEventSources={setEventSourcetoSend}
                  value={value}
                  setValue={setValue}
                  events={fmtEvents}
                  setFmtEvents={setFmtEvents}
                  planning={planning}
                  setPlanning={setPlanning}
                  fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                  amos={amos}
                  config={config}
                  setConfig={setConfig}
                  showFreeMeetings={showFreeMeetings}
                  setShowFreeMeetings={setShowFreeMeetings}
                  formatEventsImpose={formatEventsImpose}
                  plannings={[planning]}
                />
              </Drawer>
            </div>
          )}

          {selected.length !== 0 && (
            <RappelsRelancesModal
              isNotifMail={isNotifMail}
              isNotifSMS={isNotifSMS}
              setIsNotifMail={setIsNotifMail}
              setIsNotifSMS={setIsNotifSMS}
              show={showRappelsRelances}
              setShow={setShowRappelsRelances}
              selected={selected}
              planning={planning}
              fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
            />
          )}
          <br />
          <br />
          <br />
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default PlanningImpose;
