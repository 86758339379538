import React, { useContext } from "react";
import { UserMenuData } from "./UserMenuData";
import { Menu } from "antd";
import { Row, Dropdown, Typography } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
// import "../../../App.css";

const { Text } = Typography;

const userMenuItems = (
  <Menu>
    {UserMenuData.map((item, index) => (
      <Menu.Item key={"user" + index} icon={item.icon}>
        <Link to={item.path}>{item.label}</Link>
      </Menu.Item>
    ))}
  </Menu>
);

const CustomerMenu = () => {
  const { username } = useContext(UserContext);
  return (
    <Row justify="end">
      <Text>
        <b>{username}</b>
      </Text>

      <Text type="success">&nbsp;(Acquéreur)</Text>

      <Dropdown.Button
        style={{
          margin: "16px 24px 0px 0px",
        }}
        className="dropdown-btn"
        overlay={userMenuItems}
        icon={<UserOutlined />}
      ></Dropdown.Button>
    </Row>
  );
};

export default CustomerMenu;
