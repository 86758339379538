import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ContentContainer from "../../components/ContentContainer";
import PublicFooter from "../../components/public/Footer";
import PromoterTemplate from "../../components/publicPages/BookRdv/PromoterTemplate";
import PromoterTemplateImpose from "../../components/publicPages/BookRdv/PromoterTemplateImpose";
import { phaseTransform } from "../../components/plannings/planningData";
import {
  closeWindow,
  getRdvApiAuth,
  getInitials,
  getBasicAuthConfig,
} from "../../utils";
import { ToolOutlined } from "@ant-design/icons";
import { Layout, Button, App, Spin, Result, Form, ConfigProvider } from "antd";

moment.locale("fr");

const BookRdv = () => {
  const { message } = App.useApp();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [planning, setPlanning] = useState({});
  const [rdvDate, setRdvDate] = useState("none");
  const [isAvailable, setIsAvailable] = useState(true);
  const [sayIsNotAvailable, setSayIsNotAvailable] = useState(false);
  const [fillForm, setFillForm] = useState(false);
  const [rdv, setRdv] = useState({});
  const [options, setOptions] = useState([]);
  const [dateExpired, setDateExpired] = useState(undefined);
  // const [selected, setSelected] = useState({});
  const [wait, setWait] = useState(false);
  const [isActive, setIsActive] = useState(undefined);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [promoterUrl, setPromoterUrl] = useState(undefined);
  const [optionsPerDays, setOptionsPerDays] = useState({});
  const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
  const [urlLogo, setUrlLogo] = useState(undefined);
  const [isMobileView, setIsMobileView] = useState(false);
  const [nbRemainingVisitors, setNbRemainingVisitors] = useState(undefined);
  const [selectedAmo, setSelectedAmo] = useState(undefined);
  const [amos, setAmos] = useState({});
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
  };

  const cancel = (e) => {
    console.log(e);
    message.error("Action annulée");
  };

  const clickNo = () => {
    setSelectedSlot(null);
    form.resetFields();
    setNbRemainingVisitors(rdv.maxVisitorsNb);
  };

  const clickYes = async () => {
    try {
      await form.validateFields();
      let req = {
        usr: rdv.uuid,
        label: selectedSlot.label,
        slot: selectedSlot.slot,
        email: rdv.guest.email,
        extra: {
          ...form.getFieldsValue(),
          selectedAmo,
        },
      };
      setWait(true);
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/book/" + rdv._id,
        req
      );
      // console.log("ARRIVEE FIN");
      document.location.reload();
    } catch (e) {
      console.log(`e:>>>>`);
      console.log(e);
      if (e.response && e.response.data.error) {
        console.log(e);
        message.error(
          "Il semblerait que le slot sélectionné ne soit plus disponible. La page va se rafraichir, vous pourrez alors réessayer"
        );
      } else if (e.errorFields) {
        message.error(`Veuillez vérifier les informations du formulaire`);
        return;
      } else {
        console.log(e);
        message.error(
          `Erreur lors de la confirmation de l'horaire. La page va se rafraichir, vous pourrez alors réessayer`
        );
      }
      setTimeout(function () {
        document.location.reload();
      }, 3000);
    }
  };

  const fetchRdv = async (uuid) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_RDV_API_URL + "/rdv/usr/" + uuid,
        {}
      );
      setUrlLogo(
        process.env.REACT_APP_API_URL +
          "/programs/" +
          res.data.usrBooking.projectId +
          "/avatar"
      );
      const fetchedRdv = res.data.usrBooking.broadcastList.find(
        (x) => x.uuid === uuid
      );
      console.log(`fetchedRdv : ${JSON.stringify(fetchedRdv)}`);
      setPromoterUrl(res.data.usrBooking.promoterUrl);
      setRdv(fetchedRdv);
      setNbRemainingVisitors(fetchedRdv.maxVisitorsNb);
      setPlanning(res.data.usrBooking);

      if (!fetchedRdv.isAvailable) {
        setIsAvailable(false);
      }
      const isExpired = (d) => {
        return new Date(d) < Date.now();
      };

      // for choosing Amos if AMOs are assigned
      if (
        res.data.usrBooking.amoList.length !== 0 &&
        res.data.usrBooking.areAmosAssigned
      ) {
        const res2 = await axios.post(
          process.env.REACT_APP_API_URL + `/users/amos`,
          { ids: res.data.usrBooking.amoList },
          getBasicAuthConfig()
        );
        setAmos(
          res2.data.map((user) => ({
            id: user._id,
            initials: getInitials(user.firstname, user.lastname),
            firstname: user.firstname,
            lastname: user.lastname,
          }))
        );
      }

      setDateExpired(isExpired(res.data.usrBooking.bookingDeadline));
      setIsActive(fetchedRdv.isActive);

      if (fetchedRdv.isImposed) {
        setRdvDate(new Date(fetchedRdv.start).getTime());
      } else if (fetchedRdv.forced !== null || fetchedRdv.isBooked) {
        // console.log(`pass I`);
        const getUserRdvDate = (email, grid) => {
          for (let shift = 0; shift < grid.length; shift++) {
            for (let amo = 0; amo < grid[shift].length; amo++) {
              if (grid[shift][amo] != null && grid[shift][amo] !== []) {
                for (let slot = 0; slot < grid[shift][amo].length; slot++) {
                  if (grid[shift][amo][slot].booked === email) {
                    return grid[shift][amo][slot].start;
                  }
                }
              }
            }
          }
          return null;
        };
        setIsAlreadyBooked(true);

        if (fetchedRdv.forced !== null) {
          setRdvDate(new Date(fetchedRdv.start).getTime());
        } else {
          setRdvDate(
            getUserRdvDate(
              fetchedRdv.guest.email,
              JSON.parse(res.data.usrBooking.grid)
            )
          );
        }
      } else {
        // console.log(`pass II`);
        setSelectedAmo(res.data.usrBooking.amoList[0]);
      }
      setIsFound(true);
    } catch (e) {
      // console.log(`pass III`);
      console.log(e);
      setIsFound(false);
    }
  };

  useEffect(() => {
    const newFunc = async (uuid) => {
      await fetchRdv(uuid);
      setHasLoaded(true);
    };

    const uri = window.location.pathname;
    const uuid = uri.replace("/entrevue/", "");

    const mql = window.matchMedia("(max-width: 600px)");
    let mobileView = mql.matches;
    if (mobileView) {
      setIsMobileView(true);
    }

    if (uuid !== "") {
      newFunc(uuid);
    }
  }, []);

  const deScheduleRdv = async () => {
    setWait(true);
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/unbook/" + rdv._id,
        {}
      );
      document.location.reload();
    } catch (e) {
      if (e.response && e.response.data.error) {
        message.error(e.response.data.message);
      } else {
        console.log("Erreur lors du deschedule du rdv : " + e);
        message.error(
          "Impossible de supprimer le rendez-vous sélectionné. Veuillez contacter votre AMO."
        );
      }
    }
  };

  const notAvailableRdv = async () => {
    setFillForm(true);
    setSayIsNotAvailable(true);
  };

  const clickNotAvailable = async (values) => {
    // Attention, juste pour PromoterTemplate
    let updates = {
      isAvailable: false,
      uuid: rdv.uuid,
      note: values.note,
      isInterestedVr: values.isInterestedVr,
    };
    await axios.patch(
      process.env.REACT_APP_RDV_API_URL +
        "/rdv/" +
        planning._id +
        "/broadcastlist",
      updates,
      getRdvApiAuth()
    );
    setFillForm(false);
  };

  const clickAvailable = async () => {
    try {
      // Attention, juste pour PromoterTemplate
      let updates = {
        isAvailable: true,
        uuid: rdv.uuid,
        note: "",
      };
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          planning._id +
          "/broadcastlist",
        updates,
        getRdvApiAuth()
      );
      setSayIsNotAvailable(false);
      setSelectedSlot(null);
      setRdv({ ...rdv, isAvailable: true });
    } catch (e) {
      message.error(`Une erreur est apparue.`);
      console.log(e);
    }
  };

  if (wait) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin tip="Veuillez patienter SVP ..." />
      </div>
    );
  } else if (planning.templateType === "promoterImpose") {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: planning.projectColors[0],
            colorPrimaryActive: planning.projectColors[0],
            colorLink: planning.projectColors[0],
          },
        }}
      >
        <PromoterTemplateImpose
          dateExpired={dateExpired}
          hasLoaded={hasLoaded}
          planning={planning}
          rdv={rdv}
          rdvDate={rdvDate}
          urlLogo={urlLogo}
          phaseTransform={phaseTransform}
          isFound={isFound}
          sayIsNotAvailable={sayIsNotAvailable}
          setSayIsNotAvailable={setSayIsNotAvailable}
          closeWindow={closeWindow}
          form={form}
          fillForm={fillForm}
          setFillForm={setFillForm}
          setWait={setWait}
          promoterUrl={promoterUrl}
          isActive={isActive}
        />
      </ConfigProvider>
    );
  } else if (planning.templateType === "promoter") {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: planning.projectColors[0],
            colorPrimaryActive: planning.projectColors[0],
            colorLink: planning.projectColors[0],
          },
        }}
      >
        <PromoterTemplate
          dateExpired={dateExpired}
          hasLoaded={hasLoaded}
          isMobileView={isMobileView}
          isAlreadyBooked={isAlreadyBooked}
          planning={planning}
          rdv={rdv}
          rdvDate={rdvDate}
          urlLogo={urlLogo}
          phaseTransform={phaseTransform}
          isFound={isFound}
          onFinish={onFinish}
          clickYes={clickYes}
          clickNo={clickNo}
          sayIsNotAvailable={sayIsNotAvailable}
          setSayIsNotAvailable={setSayIsNotAvailable}
          selectedSlot={selectedSlot}
          optionsPerDays={optionsPerDays}
          setOptionsPerDays={setOptionsPerDays}
          setOptions={setOptions}
          options={options}
          setSelectedSlot={setSelectedSlot}
          cancel={cancel}
          deScheduleRdv={deScheduleRdv}
          notAvailableRdv={notAvailableRdv}
          closeWindow={closeWindow}
          form={form}
          nbRemainingVisitors={nbRemainingVisitors}
          setNbRemainingVisitors={setNbRemainingVisitors}
          fillForm={fillForm}
          setFillForm={setFillForm}
          clickNotAvailable={clickNotAvailable}
          clickAvailable={clickAvailable}
          amos={amos}
          selectedAmo={selectedAmo}
          setSelectedAmo={setSelectedAmo}
          promoterUrl={promoterUrl}
          isActive={isActive}
        />
      </ConfigProvider>
    );
  } else if (JSON.stringify(planning) === "{}" && hasLoaded) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <div>
              <Result
                icon={<ToolOutlined />}
                title="Rendez-vous non trouvé"
                subTitle={
                  <div>
                    Remarque : Ce message peut parfois apparaître si vous
                    utilisez un VPN.
                    <br />
                    Veuillez dans ce cas essayer avec une connexion internet à
                    domicile ou un partage de connexion depuis votre téléphone.
                  </div>
                }
                extra={
                  <Button type="primary" onClick={closeWindow}>
                    Quitter
                  </Button>
                }
              />
            </div>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else {
    return (
      <div className="loader-spin-empty-screen">
        <Spin tip="Veuillez patienter SVP ..." />
      </div>
    );
  }
};

export default BookRdv;
