import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const breadcrumbNameMap = {
  "/accueil-acquereur": "Accueil",
  "/profile": "Profil",
  // "/logement": "Logement",
};

const breadcrumbNameSubHousingsMap = {
  actualite: "Actualité",
  tmas: "TMA(s)",
  choix: "Choix",
  messages: "Messages",
  documents: "Documents",
  chantier: "Chantier",
  "foire-aux-questions": "Foire aux questions",
};

const getBreadCrumbNameMap = (url) => {
  let tab = url.split("/");

  if (Object.keys(breadcrumbNameMap).includes(url)) {
    return breadcrumbNameMap[url];
  } else if (tab.length < 3) {
    return localStorage.getItem("currentProgram");
  } else {
    if (tab.length === 3) {
      return localStorage.getItem("currentHousing");
    } else {
      return breadcrumbNameSubHousingsMap[tab[3]];
    }
  }
};

const CustomerCustomBreadcrumb = withRouter((props) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return {
      key: url,
      title: <Link to={url}>{getBreadCrumbNameMap(url)}</Link>,
    };
  });
  const breadcrumbItems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
      key: "home",
    },
  ].concat(extraBreadcrumbItems);

  return (
    <div className="breadcrumb-container">
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
});

export default CustomerCustomBreadcrumb;
