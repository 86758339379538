import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getBasicAuthConfig } from "../../utils";
import ContentContainer from "../../components/ContentContainer";
import { App, Typography } from "antd";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const AdminUserView = () => {
  const { message } = App.useApp();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [housings, setHousings] = useState(undefined);
  const [isNotFound, setIsNotFound] = useState(true);

  const fetchUser = async () => {
    let tab = history.location.pathname.split("/");
    let userId = tab[tab.length - 1];
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/admin/users/" + userId,
        getBasicAuthConfig()
      );
      setUser(res.data.user);
      setHousings(res.data.housings);
      setIsNotFound(false);
    } catch (e) {
      console.log(e);
      message.error(`Erreur lors du chargement de l'utilisateur`);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchUser();
      setIsLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        {!isLoading && !isNotFound && (
          <div>
            <CustomPageHeader
              arrow={true}
              title={`Informations sur ${user.firstname}`}
              // avatar={{
              //   src: "https://joeschmoe.io/api/v1/random",
              // }}
            />
            <SubContentContainer>
              <br />
              <Title level={5}>Utilisateur :</Title>
              Nom : {user.firstname}
              <br />
              Prénom : {user.lastname}
              <br />
              Entreprise : {user.enterprise}
              <br />
              Adresse: {`${user.adresse} ${user.codepostal} ${user.ville}`}
              <br />
              Email : {`${user.email}`}
              <br />
              <br />
              <Title level={5}>Appartement(s):</Title>
              {housings.program !== undefined &&
                housings.map((housing, idx) => {
                  return (
                    <div key={`h-${idx}`}>
                      {`- Promoteur : ${housing.program.promotername} (programme: ${housing.program.name}) lot : ${housing.lotcomm} (${housing.typologie})`}
                    </div>
                  );
                })}
            </SubContentContainer>
          </div>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default AdminUserView;
