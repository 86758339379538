import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const breadcrumbNameMap = {
  "/accueil-administrateur": "Administrateur",
  "/admin": "Administrateur",
  "/admin/users": "Comptes",
  "/admin/plannings": "Plannings",
  "/admin/permissions": "Permissions",
  "/admin/facturation": "Facturation",

  // "/amo/templates/help": "Mode d'emploi",
  // "/admin/dashboard": "Dashboard",
  "/admin/logs": "Logs",
  "/profile": "Profil",
  "/admin/programmes": "Programmes",
  "/parametres": "Paramètres",
  "/projets": "Programmes",
  // "/matterport": "Matterport",
  "/admin/visites": "Visites Virtuelles",
};

const breadcrumbNameSubProjetsMap = {
  clients: "Clients",
  messages: "Messages",
  plannings: "Plannings",
  entities: "Entités",
  "plannings-libres": "Plannings",
  "plannings-imposes": "Plannings",
  "visites-3d": "Visites 3D",
  parametres: "Paramètres",
};

// const breadcrumbNameSubLogsMap = {
//   visites: "Visites",
//   rdvs: "Rendez-vous",
// };

const getBreadCrumbNameMap = (url) => {
  let tab = url.split("/");

  // console.log(`tab : ${JSON.stringify(tab)} (len: ${tab.length})`);
  if (url === "/admin/logs/rdvs") {
    return "Rendez-vous";
  } else if (url === "/admin/logs/visites") {
    return "Visites";
  } else if (url === "/admin/logs/trends") {
    return "Tendances";
  } else if (url === "/admin/permissions/users") {
    return "Utilisateurs";
  } else if (url === "/admin/permissions/programs") {
    return "Programmes";
  } else if (tab.length < 3 || Object.keys(breadcrumbNameMap).includes(url)) {
    // console.log("pass 1");
    return breadcrumbNameMap[url];
  } else {
    // console.log("pass 2");
    if (tab.length === 3) {
      return localStorage.getItem("currentProgram");
    } else if (tab.length === 4) {
      return breadcrumbNameSubProjetsMap[tab[3]];
    } else if (tab.length === 5) {
      if (tab.includes("nouveau")) {
        return "Nouveau";
      } else if (tab.includes("nouveau-impose")) {
        return "Nouveau";
      } else if (tab.includes("clients")) {
        return localStorage.getItem("currentUser");
      } else {
        return localStorage.getItem("currentPlanning");
      }
    }
  }
};

const CustomBreadcrumb = withRouter((props) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    let url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (url.includes("plannings-imposes")) {
      url = url.replace("plannings-imposes", "plannings");
    } else if (url.includes("plannings-libres")) {
      url = url.replace("plannings-libres", "plannings");
    }
    return {
      key: url,
      title: <Link to={url}>{getBreadCrumbNameMap(url)}</Link>,
    };
  });

  const breadcrumbItems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
      key: "home",
    },
  ].concat(extraBreadcrumbItems);

  return (
    <div className="breadcrumb-container">
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
});

export default CustomBreadcrumb;
