import React, { useEffect } from "react";
import { Skeleton, Typography } from "antd";
import ContentContainer from "../../../components/ContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";
import { errorCatcher } from "../../../utils";
import SubContentContainer from "../../../components/SubContentContainer";

const { Title } = Typography;

const Apercu = () => {
  // const [clientList, setClientList] = useState([]);

  const fetchProgramClientList = async () => {
    try {
      // console.log("fetch()");
      // const res = await axios.get(
      //   process.env.REACT_APP_API_URL +
      //     "/programs/" +
      //     localStorage.getItem("currentProgramId"),
      //   getBasicAuthConfig()
      // );
      // setClientList(
      //   getFormatedClients(
      //     res.data.clients,
      //     localStorage.getItem("currentProgramId")
      //   )
      // );
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement de la page Aperçu.`);
    }
  };

  useEffect(() => {
    fetchProgramClientList();
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          // style={{ margin: "0px 16px 0px 16px" }}
          title="Page Aperçu - En développement"
          // className="site-page-header"
        ></CustomPageHeader>
        <Skeleton />
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Apercu;
