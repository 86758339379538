import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import hasPermission from "./Roles";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { role } = useContext(UserContext);
  //rest.path:visit -> history.goBack()
  // console.log(rest.path);
  return (
    <Route
      {...rest}
      component={(props) =>
        hasPermission(role, rest.path) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
