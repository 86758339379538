import React from "react";
import dayjs from "dayjs";
import { Space, Button, Typography, Radio, Checkbox } from "antd";
import { renderHuman } from "./../../utils";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const CalendarEventSet = ({
  value,
  onChangeRadio,
  selected,
  show,
  onChange,
  evts,
  dispatch,
}) => {
  const onSortDescendingClick = () => {
    dispatch({
      type: "SORT_DESCENDING_FILTER_VALUES",
    });
  };

  const onSortAscendingClick = () => {
    dispatch({
      type: "SORT_ASCENDING_FILTER_VALUES",
    });
  };

  return (
    <div
      id="external-events"
      style={{
        padding: "10px",
        width: "80%",
        height: "760px",
        maxHeight: "-webkit-fill-available",
        borderRadius: "6px",
      }}
    >
      {show === true ? (
        <div>
          <center>
            <strong>
              <Title level={4} style={{ color: "#436386" }}>
                Filtres
              </Title>
            </strong>
          </center>
          <br />
          <Radio.Group onChange={onChangeRadio} value={evts.value}>
            <Space direction="vertical">
              <Radio value={"batiment"}>Batiment</Radio>
              <Radio value={"etage"}>Etage</Radio>
              <Radio value={"typologie"}>Typologie</Radio>
            </Space>
          </Radio.Group>
          <br />
          <br />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChange}
            options={evts.filterSet}
            value={evts.checkList}
          />
          <br />
          <br />

          {evts.subFilterEvents.length > 0 && (
            <Title level={4}>
              <Button
                // type="primary"
                icon={<SortDescendingOutlined />}
                size="small"
                onClick={onSortDescendingClick}
              />
              &nbsp;
              <Button
                // type="primary"
                icon={<SortAscendingOutlined />}
                size="small"
                onClick={onSortAscendingClick}
              />
            </Title>
          )}

          {evts.subFilterEvents.length > 0 &&
            evts.subFilterEvents.map((s, idx) => {
              let m = Math.floor((s.duree / (1000 * 60)) % 60);
              let h = Math.floor((s.duree / (1000 * 60 * 60)) % 24);
              let hm = `0${h}:${m}`.padEnd(5, 0);
              // console.log(`h:m => ${h}:${m} `);
              // console.log(`0${h}:${m}`.padEnd(5, 0));
              // console.log(`fc-event-${s.duree / (1800 * 1000)}`);
              let cn = `fc-event-${s.duree / (1800 * 1000)}`;
              // console.log(`PASS CN (hm = ${hm})`);
              if (s.duree / (1800 * 1000) === 1.5) {
                // console.log(`PASS CN (hm = ${hm})`);
                cn = `fc-event-15`;
              }

              return (
                <div
                  key={`s-${idx}`}
                  className={cn}
                  title={s.lotcomm}
                  lotcomm={s.lotcomm}
                  email={s.mailmonsieur ? s.mailmonsieur : s.mailmadame}
                  data={s._id}
                  duration={hm}
                  length={`${s.duree / (1800 * 1000)}`}
                >
                  &nbsp;
                  {s.lotcomm} ({renderHuman(s.duree)})
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          <center>
            <Text>
              {" "}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              🙌 Terminé 🙌
            </Text>
          </center>
        </div>
      )}
    </div>
  );
};

export default CalendarEventSet;
