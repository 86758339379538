import React from "react";
import { Form, Input, DatePicker, Space, Switch } from "antd";
import moment from "moment";
import { disabledDateTime } from "../../utils";

const SelectBasicInfoConvocation = ({ form, notif, setNotif }) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  const onChange = (value) => {
    setNotif(value);
  };
  return (
    <div>
      <Space>
        <Switch onChange={onChange} disabled />
        Programmer l'envoi des invitations à une date ultérieure (momentanément
        désactivé)
      </Space>
      <br />
      <br />
      <br />
      <br />
      <Form form={form} {...layout}>
        {notif && (
          <Form.Item
            name="launchDate"
            label="Date d'envoi des rendez-vous"
            rules={[
              {
                type: "object",
                required: true,
                message:
                  "Veuillez séléctionner la date d'envoi des rendez-vous souhaitée",
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
        )}
        <Form.Item
          name="rdvName"
          label="Nom"
          rules={[
            {
              required: true,
              message:
                "Veuillez séléctionner un nom pour retrouver cette prise de rendez-vous",
            },
          ]}
        >
          <Input placeholder="ex : Les Jardins d'Eden - Bât. 3" />
        </Form.Item>

        <Form.Item
          name="promoterUrl"
          label="Lien du promoteur"
          rules={[
            {
              message:
                "L'acquéreur est redirigé vers ce lien lorsqu'il cliquera sur le logo",
            },
          ]}
        >
          <Input placeholder="https://wwww.yahoo.fr" />
        </Form.Item>

        <Form.Item
          label="Adresse du point de rendez-vous"
          required
          tooltip="Par défault, s'agit de l'adresse du programme indiquée dans les paramètres de ce dernier"
        >
          <Space.Compact>
            <Form.Item
              name={["adresse", "numero"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un numéro de rue",
                },
              ]}
            >
              <Input
                style={{ width: "20%", marginBottom: "4px" }}
                placeholder="No"
              />
            </Form.Item>
            <Form.Item
              name={["adresse", "rue"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un nom de rue",
                },
              ]}
            >
              <Input
                style={{
                  width: "80%",
                  marginBottom: "4px",
                }}
                placeholder="rue"
              />
            </Form.Item>
          </Space.Compact>
          <Space.Compact>
            <Form.Item
              name={["adresse", "codepostal"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un code postal",
                },
              ]}
            >
              <Input style={{ width: "20%" }} placeholder="code postal" />
            </Form.Item>
            <Form.Item
              name={["adresse", "ville"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer une ville",
                },
              ]}
            >
              <Input style={{ width: "30%" }} placeholder="ville" />
            </Form.Item>
            <Form.Item name={["adresse", "pays"]} noStyle>
              <Input style={{ width: "50%" }} placeholder="pays" />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        {/* {optionQuestions.includes("Figer les rendez-vous") && ( */}
        <Form.Item
          name="bookingDeadline"
          label="Date limite de réponse"
          rules={[
            {
              type: "object",
              required: true,
              message:
                "Veuillez séléctionner la date limite de réponse souhaitée",
            },
          ]}
        >
          <DatePicker
            disabledTime={disabledDateTime}
            showTime
            hideDisabledOptions={true}
            minuteStep={30}
            format="YYYY-MM-DD HH:mm"
            // onChange={(e) => {
            //   console.log(e.format("mm"));
            //   form.setFieldsValue("bookingDeadline", moment());
            // }}
          />
        </Form.Item>
        {/* )} */}
      </Form>
    </div>
  );
};

export default SelectBasicInfoConvocation;
