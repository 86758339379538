import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const ContentContainer = ({ children }) => {
  return (
    <Content
      // className="site-layout-background"
      style={{
        margin: "0px 16px 0px 16px",
        background: "white",
        // padding: 12,
        minHeight: 480,
        borderRadius: "6px",
      }}
    >
      <br />
      {children}
    </Content>
  );
};

export default ContentContainer;
