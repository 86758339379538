import React, { useState, useEffect } from "react";
import ContentContainer from "./../../components/ContentContainer";
import { App, Row, Col } from "antd";
import axios from "axios";
import moment from "moment";
import { getVisitesApiAuth } from "../../utils";
import VisitLogsHistogram from "../../components/admin/VisitLogsHistogram";
import LogsTable from "../../components/admin/LogsTable";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

// TODO : Import colors from ant design theme custom

const AdminLogsVisites = () => {
  const { message } = App.useApp();
  const [logs, setLogs] = useState([]);
  const [logsTable, setLogsTable] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const fetchLogs = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_VR_API_URL + "/logs",
        getVisitesApiAuth()
      );

      // console.log(`ALL LOGS : ${JSON.stringify(res.data)}`);
      const originData = [];
      for (let i = 0; i < res.data.length; i++) {
        let lenj = res.data[i].vues.length;
        for (let j = 0; j < lenj; j++) {
          originData.push({
            key: res.data[i].vues[j]._id,
            date: res.data[i].vues[j].date,
            ip: res.data[i].vues[j].IPv4,
            city: res.data[i].vues[j].city,
            postal: res.data[i].vues[j].postal,
            country_name: res.data[i].vues[j].country_name,
            state: res.data[i].vues[j].state,
            pointer: res.data[i].pointer,
            name: res.data[i].name,
            promoter: res.data[i].promoter,
            uuid: res.data[i].uuid,
          });
        }
      }
      setLogsTable(originData);

      const originDataFormated = {};
      const lenOriginData = originData.length;

      for (let x = 0; x < lenOriginData; x++) {
        let obj = {
          ip: originData[x].ip,
          city: originData[x].city,
          name: originData[x].name,
          uuid: originData[x].uuid,
          pointer: originData[x].pointer,
          promoter: originData[x].promoter,
        };
        let formatedDate = moment(originData[x].date).format("DD-MM-YYYY");
        if (Object.keys(originDataFormated).includes(formatedDate)) {
          originDataFormated[formatedDate].push(obj);
        } else {
          originDataFormated[formatedDate] = [obj];
        }
      }

      const originDataGraph = [];
      const ks = Object.keys(originDataFormated);
      const lenh = ks.length;

      // const getmm = (x, ks) => {
      //   let currLen = originDataFormated[ks[x]].length;
      //   console.log(currLen);
      //   if (x === 0) return currLen;
      //   if (x === lenh - 1) return currLen;
      //   return (
      //     (originDataFormated[ks[x - 1]].length +
      //       currLen +
      //       originDataFormated[ks[x + 1]].length) /
      //     3
      //   );
      // };

      for (let x = 0; x < lenh; x++) {
        originDataGraph.push({
          date: ks[x],
          counter: originDataFormated[ks[x]].length,
          // mm: getmm(x, ks),
        });
      }
      let orderedDates = [];
      originDataGraph
        .map((_) => _.date)
        .sort(function (a, b) {
          return (
            moment(a, "DD-MM-YYYY").toDate() - moment(b, "DD-MM-YYYY").toDate()
          );
        })
        .forEach(function (key) {
          orderedDates.push(originDataGraph.filter((_) => _.date === key)[0]);
        });
      setLogs(orderedDates);
      setHasLoaded(true);
    } catch (e) {
      console.log(`Error when fetching logs : ${e}`);
      message.error(
        "Vous n'avez pas les permissions pour accéder aux historiques de connexion aux visites"
      );
    }
  };

  useEffect(() => {
    if (hasLoaded === false) {
      fetchLogs();
    }
  }, [hasLoaded]);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Visites Virtuelles"
          subTitle="Vues par jour"
          avatar={{
            src: "https://img.icons8.com/color/48/000000/combo-chart--v1.png",
          }}
        />

        <Row gutter={32}>
          <Col span={24}>{hasLoaded && <VisitLogsHistogram logs={logs} />}</Col>
        </Row>
        <br />
        <br />
        <Row gutter={32}>
          <Col span={24}>
            <LogsTable data={logsTable} setHasLoaded={setHasLoaded} />
          </Col>
        </Row>
      </SubContentContainer>
    </ContentContainer>
  );
};
export default AdminLogsVisites;
