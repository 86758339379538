import React, { useState } from "react";
import { App, Modal, DatePicker, Typography, Space } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";
import moment from "moment";

const { Text } = Typography;

const LimitAnswerModal = ({
  show,
  setShow,
  planning,
  setPlanning,
  unSelectedAllRows,
}) => {
  const { message } = App.useApp();
  const [selectedDate, setSelectedDate] = useState(undefined);

  const updateBookingDeadline = async () => {
    try {
      const res = axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { bookingDeadline: selectedDate },
        getRdvApiAuth()
      );
      // console.log(`RES -> ${JSON.stringify(res)}`);
      message.success(
        "Mise à jour de la date de fin de prise des rendez-vous effectuée"
      );
      setPlanning({ ...planning, bookingDeadline: selectedDate });
      setShow(false);
    } catch (e) {
      console.log(e);
      message.error(
        "Erreur lors du changement de date de fin des prises de rendez-vous"
      );
    }
  };

  const onDatePickerChange = (value) => {
    // console.log(JSON.stringify(value));
    // console.log(JSON.stringify(new Date(value).setSeconds(0)));
    setSelectedDate(new Date(value).setSeconds(0));
  };

  const SelectDate = () => {
    return (
      <div>
        <DatePicker
          showTime
          onChange={onDatePickerChange}
          format="DD-MM-YYYY HH:mm"
          // disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23]}
          disabledTime={disabledDateTime}
          hideDisabledOptions={true}
          minuteStep={15}
          showNow={false}
        />
        {selectedDate !== undefined && (
          <div>
            <br />
            Vous avez sélectionné le : &nbsp;
            <Text mark>{`${moment(selectedDate).format(
              "ddd DD MMM YYYY HH:mm"
            )}`}</Text>
          </div>
        )}
      </div>
    );
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
      // disabledMinutes: () => this.range(30, 60),
      // disabledSeconds: () => [55, 56],
    };
  };

  return (
    <Modal
      title="Changement de date de fin de prise de Rdv"
      style={{ top: 40 }}
      open={show}
      onOk={updateBookingDeadline}
      onCancel={() => setShow(false)}
    >
      <Space direction="vertical">
        <Space direction="horizontal">
          La date actuelle d'arrêt est la suivante:{" "}
          {moment(planning.bookingDeadline).format("ddd DD MMM YYYY")}
        </Space>
        Sélectionnez ci-dessous la nouvelle date souhaitée:
        <SelectDate />
      </Space>
    </Modal>
  );
};

export default LimitAnswerModal;
