import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import { getBasicAuthConfig, areBalisesOk } from "../utils";
import ContentContainer from "../components/ContentContainer";
import SubContentContainer from "../components/SubContentContainer";
import CustomPageHeader from "../components/CustomPageHeader";
import Tips from "./../components/plannings/modals/Tips";
import {
  originMailConfirmation,
  originMailInvitation,
  originMailRappel,
  originMailRelance,
  originSmsInvitation,
  originSmsRappel,
  originSmsRelance,
} from "../components/plannings/planningData";
import { Typography, Steps, Button, App, Input, Form } from "antd";
const { Title } = Typography;
const { TextArea } = Input;

const InsertTemplate = () => {
  const { message } = App.useApp();
  const { role } = useContext(UserContext);
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const formRef = useRef();

  const getRenderedContent = (name, title) => {
    return (
      <>
        <br />
        <Title level={5}>{`${title} :`}</Title>
        <br />
        <Form.Item
          name={`${name}`}
          rules={[
            {
              validator: async (_, payload) => {
                if (!areBalisesOk(payload)) {
                  return Promise.reject(
                    new Error(
                      `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                    )
                  );
                }
              },
              required: true,
              message:
                "Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles",
            },
          ]}
          // help={<Tips />}
        >
          <TextArea rows={18} />
        </Form.Item>
        <Tips />
      </>
    );
  };

  const steps = [
    {
      // title: "1",
      content: getRenderedContent("mailInvitation", "Mail Invitation"),
    },
    {
      // title: "2",
      content: getRenderedContent("smsInvitation", "SMS Invitation"),
    },
    {
      // title: "3",
      content: getRenderedContent("mailConfirmation", "Mail Confirmation"),
    },
    {
      // title: "4",
      content: getRenderedContent("mailRelance", "Mail Relance"),
    },
    {
      // title: "5",
      content: getRenderedContent("smsRelance", "SMS Relance"),
    },
    {
      // title: "6",
      content: getRenderedContent("mailRappel", "Mail Rappel"),
    },
    {
      // title: "7",
      content: getRenderedContent("smsRappel", "SMS Rappel"),
    },
  ];

  const next = async () => {
    await formRef.current
      .validateFields()
      .then((values) => {
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Erreur dans la validation des champs", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const onFinish = async () => {
    try {
      let newTemplate = formRef.current.getFieldsValue(true);
      await axios.post(
        process.env.REACT_APP_API_URL + `/users/me/template/create`,
        newTemplate,
        getBasicAuthConfig()
      );
      message.success("Modèle créé avec succès 🤙");
      history.push(`/${role}/templates`);
    } catch (e) {
      console.log(e);
      message.error(`Erreur lors de la création du modèle`);
    }
  };

  return (
    <ContentContainer>
      <SubContentContainer>
        <Form
          ref={formRef}
          wrapperCol={{
            span: 14,
          }}
          name="insertTemplate"
          initialValues={{
            mailInvitation: originMailInvitation,
            smsInvitation: originSmsInvitation,
            mailConfirmation: originMailConfirmation,
            mailRelance: originMailRelance,
            smsRelance: originSmsRelance,
            mailRappel: originMailRappel,
            smsRappel: originSmsRappel,
          }}
        >
          <CustomPageHeader
            title={
              <Form.Item
                style={{ margin: "0px 16px 0px 16px" }}
                name="name"
                label="Nom du nouveau modèle : "
                rules={[
                  {
                    validator: async (_, payload) => {
                      if (!payload || payload === "") {
                        window.scrollTo(0, 0);
                        message.error("Veuillez indiquer le nom du modèle");
                        return Promise.reject(
                          new Error(`Veuillez indiquer un nom`)
                        );
                      }
                    },
                    required: true,
                    message: "Veuillez indiquer un nom",
                  },
                ]}
              >
                <Input placeholder="Nom" />
              </Form.Item>
            }
            // className="site-page-header"
            avatar={{
              src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
            }}
            // onBack={() => window.history.back()}
            arrow={true}
          ></CustomPageHeader>

          <Steps current={current} items={items} />
          <div className="steps-content">{steps[current].content}</div>
          <br />
          <div className="steps-action">
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Précédent
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Suivant
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => onFinish()}>
                Terminé
              </Button>
            )}
          </div>
        </Form>
      </SubContentContainer>
    </ContentContainer>
  );
};

export default InsertTemplate;
