import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import AddFreeMeeting from "./plannings/freemeeting/modals/AddFreeMeeting";
import { defaultColors } from "./plannings/planningData";
import {
  Tooltip,
  Modal,
  DatePicker,
  App,
  Checkbox,
  Form,
  Button,
  Radio,
} from "antd";
import {
  getRdvApiAuth,
  viewTransform,
  resetCalendarApiDefaultSettings,
  onClickExportICS,
  onClickExportPDF,
  // onClickExportXLS,
  onClickExportXLSImpose,
  isBackgroundEvent,
  handleColorsLocalStorage,
} from "../utils";
import axios from "axios";
import { ColorFactory } from "antd/es/color-picker/color";
import ExportModal from "./ExportModal";
import { bankHolidays } from "./plannings/planningData";
// import { FaHandPaper } from "react-icons/fa";

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const AgendaReadOnlyImpose = ({
  eventSources,
  setEventSources,
  value,
  setValue,
  events,
  setFmtEvents,
  planning,
  setPlanning,
  fetchPlanningAndFmtEvents,
  // notBookedList,
  amos,
  config,
  setConfig,
  showFreeMeetings,
  setShowFreeMeetings,
  formatEventsImpose,
  plannings,
}) => {
  const { message } = App.useApp();
  const [showModal, setShowModal] = useState(false);
  const [moveButtonTitle, setMoveButtonTitle] = useState("Déplacer");
  const [showMoveConfirmModal, setShowMoveConfirmModal] = useState(false);
  const [showMoveFreeMeetingConfirmModal, setShowMoveFreeMeetingConfirmModal] =
    useState(false);
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const [showDelFreeMeetingConfirmModal, setShowDelFreeMeetingConfirmModal] =
    useState(false);
  const [delSelected, setDelSelected] = useState(undefined);
  const [moveSelected, setMoveSelected] = useState(undefined);
  const [isMoveLoading, setIsMoveLoading] = useState(false);
  const [isMoveFreeMeetingLoading, setIsMoveFreeMeetingLoading] =
    useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);

  const [isExportICSLoading, setIsExportICSLoading] = useState(false);
  const [isExportPDFLoading, setIsExportPDFLoading] = useState(false);
  const [isExportXLSLoading, setIsExportXLSLoading] = useState(false);
  const [recentColors, setRecentColors] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [viewType, setViewType] = useState("Semaine");
  const [isSingleAmo, setIsSingleAmo] = useState(false);
  const calendarRef = useRef();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const handleCancel = () => {
    setShowExportOptions(false);
  };

  const onOk = async (vals) => {
    setConfirmLoading(true);
    try {
      handleColorsLocalStorage(vals);
      // Free Meeting Booking ONLY !!!
      let tmp = new Date(
        new Date(vals.freeMeetingDate).setSeconds(0)
      ).setMilliseconds(0);

      let newFreeMeetings = [...planning.freeMeetings];
      let newFreeMeeting = {
        name: vals.freeMeetingName,
        start: new Date(parseInt(new Date(tmp).getTime())),
        end: new Date(parseInt(new Date(tmp).getTime()) + vals.duree),
        color:
          vals.color instanceof ColorFactory
            ? `#${vals.color.toHex()}`
            : vals.color,
      };
      newFreeMeetings.push(newFreeMeeting);

      let res = await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { freeMeetings: newFreeMeetings },
        getRdvApiAuth()
      );
      // Goto Rdv day to show rdv
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(vals.freeMeetingDate));

      let newEventSources = [...eventSources];

      newEventSources.push({
        events: [
          {
            ...newFreeMeeting,
            booked: newFreeMeeting.name,
            title: `*${newFreeMeeting.name}*`,
          },
        ],
        color: newFreeMeeting.colors,
        startStr: "fm",
      });

      let updatedPlanning = {
        ...planning,
        freeMeetings: res.data.planning.freeMeetings,
      };
      setPlanning(updatedPlanning);
      let f = formatEventsImpose(
        updatedPlanning,
        config,
        showFreeMeetings, // NEW !!
        null,
        fmtBankHolidays // NEW !!
      );

      setFmtEvents(f[0]);
      setEventSources(f[1]);

      message.success("RDV Libre créé");
    } catch (e) {
      console.log(e);
      message.error("Problème lors du placement du RDV Libre.");
    }

    let rc = localStorage.getItem("recentColors");

    if (rc !== null) {
      setRecentColors(JSON.parse(rc));
    }

    form.setFieldsValue({
      selectedDate: dayjs(planning.planningStartTime).second(0),
      freeMeetingDate: dayjs(planning.planningStartTime).second(0),
      color: defaultColors[0],
    });

    setConfirmLoading(false);
  };

  const onConfirmMove = async () => {
    try {
      const vals = await form3.validateFields();
      setIsMoveLoading(true);
    } catch (e) {
      message.error(`Veuillez vérifier les champs`);
      // form3.resetFields();
      setIsMoveLoading(false);
      return;
    }

    let calendarApi = calendarRef.current.getApi();
    let info = calendarApi.getEventById(moveSelected.id);

    // Cas du Meeting Normal
    if (info.extendedProps.hasOwnProperty("description")) {
      // console.log(`MEETING NORMAL`);
      // console.log(JSON.stringify(info));

      let formDate = form3.getFieldsValue("selectedDateMove");

      info.setDates(
        new Date(formDate.selectedDateMove),
        new Date(
          formDate.selectedDateMove +
            new Date(moveSelected.end).getTime() -
            new Date(moveSelected.start).getTime()
        )
      );

      try {
        let update = {
          ...form3.getFieldsValue(),
          selected: info.id,
          rdvDate: parseInt(
            new Date(form3.getFieldsValue()["selectedDateMove"]).setSeconds(0) /
              1000
          ),
        };
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/convocation-move/" +
            planning._id,
          update
        );
      } catch (e) {
        setIsMoveLoading(false);
        console.log(e);
        message.error(
          `Erreur lors du déplacement du rendez-vous du lot ${info.title}`
        );
      }
    } else {
      // Cas du Free Meeting
      try {
        let updatedFreeMeeting = planning.freeMeetings.find(
          (_) => _._id == moveSelected.id
        );
        let formDate = form3.getFieldsValue("selectedDateMove");
        updatedFreeMeeting.start = formDate.selectedDateMove.seconds(0);
        updatedFreeMeeting.end = new Date(
          formDate.selectedDateMove.seconds(0) +
            new Date(moveSelected.end).getTime() -
            new Date(moveSelected.start).getTime()
        );

        let newFreeMeetings = [
          ...planning.freeMeetings.filter((_) => _._id !== moveSelected.id),
        ];

        newFreeMeetings.push(updatedFreeMeeting);
        let res = await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
          { freeMeetings: newFreeMeetings },
          getRdvApiAuth()
        );
        // await fetchPlanningAndFmtEvents();
        let updatedPlanning = {
          ...planning,
          freeMeetings: res.data.planning.freeMeetings,
        };
        setPlanning(updatedPlanning);

        let f = formatEventsImpose(
          updatedPlanning,
          config,
          showFreeMeetings, // NEW !!
          null,
          fmtBankHolidays
        );

        setFmtEvents(f[0]);
        setEventSources(f[1]);

        message.success("RDV Libre déplacé");
      } catch (e) {
        console.log(e);
        message.error("Problème lors de la suppression du RDV Libre.");
      }
    }

    setIsMoveLoading(false);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    calendarApi.setOption("editable", false);
    message.success("Rendez-vous correctement déplacé");

    // await fetchPlanningAndFmtEvents();
    calendarApi.gotoDate(new Date(form3.getFieldsValue()["selectedDateMove"]));
    setShowMoveConfirmModal(false);
    form3.resetFields();
  };

  const onConfirmMoveFreeMeeting = async () => {
    setIsMoveFreeMeetingLoading(true);
    let calendarApi = calendarRef.current.getApi();
    let newEvent = calendarApi.getEventById(moveSelected.id);
    try {
      let newFreeMeetings = [
        ...planning.freeMeetings.filter((_) => _._id !== moveSelected.id),
      ];
      newFreeMeetings.push({
        name: newEvent.extendedProps.name,
        start: new Date(
          parseInt(new Date(newEvent.start.setSeconds(0)).getTime())
        ),
        end: new Date(parseInt(new Date(newEvent.end.setSeconds(0)).getTime())),
        color: newEvent.backgroundColor,
      });

      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { freeMeetings: newFreeMeetings },
        getRdvApiAuth()
      );
      // await fetchPlanningAndFmtEvents();
    } catch (e) {
      console.log(e);
      message.error("Problème lors du déplacement du RDV Libre.");
    }

    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    calendarApi.setOption("editable", false);
    message.success("RDV Libre correctement déplacé");
    // await fetchPlanningAndFmtEvents();
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setIsMoveFreeMeetingLoading(false);
    setShowMoveFreeMeetingConfirmModal(false);
    setShowFreeMeetings(true);
  };

  const onCancelMove = async () => {
    let calendarApi = calendarRef.current.getApi();
    resetCalendarApiDefaultSettings(calendarApi);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    message.success("Déplacement du rendez-vous annulé");
    // Pas terrible de tout refetch mais bon ..
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setShowMoveConfirmModal(false);
  };

  const onCancelMoveFreeMeeting = async () => {
    let calendarApi = calendarRef.current.getApi();
    resetCalendarApiDefaultSettings(calendarApi);
    setMoveButtonTitle("Déplacer");
    // setMoveButtonTitle(<FaHandPaper />);
    message.success("Déplacement du rendez-vous annulé");
    // Pas terrible de tout refetch mais bon ..
    await fetchPlanningAndFmtEvents(config, showFreeMeetings);
    setShowMoveFreeMeetingConfirmModal(false);
  };

  const onConfirmDel = async () => {
    let calendarApi = calendarRef.current.getApi();
    try {
      let info = calendarApi.getEventById(delSelected);
      // Delete request to cancel Rdv on backend
      // TODO : Check return for specific error msg ?
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/unbook/" + info.id,
        form2.getFieldsValue()
      );
      await fetchPlanningAndFmtEvents(config, showFreeMeetings);
      message.success("Rendez-vous annulé");
    } catch (e) {
      message.error("Erreur lors de la suppression du rendez-vous.");
    }
    resetCalendarApiDefaultSettings(calendarApi);
    setDelSelected(undefined);
    setShowDelConfirmModal(false);
  };

  const onCancelDel = () => {
    let calendarApi = calendarRef.current.getApi();
    // console.log("Del annulé");
    message.success("Suppression du rendez-vous annulée");
    resetCalendarApiDefaultSettings(calendarApi);
    setDelSelected(undefined);
    setShowDelConfirmModal(false);
  };

  const onConfirmDelFreeMeeting = async () => {
    let calendarApi = calendarRef.current.getApi();
    try {
      let newFreeMeetings = [
        ...planning.freeMeetings.filter((_) => _._id !== delSelected),
      ];

      let res = await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        { freeMeetings: newFreeMeetings },
        getRdvApiAuth()
      );

      let updatedPlanning = {
        ...planning,
        freeMeetings: res.data.planning.freeMeetings,
      };
      setPlanning(updatedPlanning);
      let f = formatEventsImpose(
        updatedPlanning,
        config,
        showFreeMeetings, // NEW !!
        null,
        fmtBankHolidays
      );

      setFmtEvents(f[0]);
      setEventSources(f[1]);

      message.success("RDV Libre supprimé");
    } catch (e) {
      console.log(e);
      message.error("Problème lors de la suppression du RDV Libre.");
    }

    resetCalendarApiDefaultSettings(calendarApi);
    setDelSelected(undefined);
    setShowDelFreeMeetingConfirmModal(false);
  };

  const onCancelDelFreeMeeting = () => {
    let calendarApi = calendarRef.current.getApi();
    // console.log("Del annulé");
    message.success("Suppression du RDV Libre annulée");
    resetCalendarApiDefaultSettings(calendarApi);
    setDelSelected(undefined);
    setShowDelFreeMeetingConfirmModal(false);
  };

  let startDate = moment(
    new Date(Math.min(...events.map((x) => new Date(x.start))))
  ).format("yyyy-MM-DD");

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
      // disabledMinutes: () => this.range(30, 60),
      // disabledSeconds: () => [55, 56],
    };
  };

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <div>&nbsp;</div>}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    let rc = localStorage.getItem("recentColors");
    if (rc !== null) setRecentColors(JSON.parse(rc));
  }, []);

  return (
    <div>
      <div>
        <Modal
          title="Ajout d'un RDV Libre"
          confirmLoading={confirmLoading}
          open={showModal}
          onCancel={() => setShowModal(false)}
          onOk={async () => {
            try {
              const values = await form.validateFields();
              await onOk(values);
              setShowModal(false);
              form.resetFields();
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <Form
            form={form}
            initialValues={{
              freeMeetingDate: dayjs(planning.planningStartTime).second(0),
              isMailConfirmation: true,
              color: "#5CDBD3",
            }}
          >
            <AddFreeMeeting recentColors={recentColors} />
          </Form>
        </Modal>

        <Modal
          open={showMoveConfirmModal}
          icon={<ExclamationCircleOutlined />}
          onCancel={onCancelMove}
          title="Vous souhaitez déplacer une convocation dans l'agenda"
          footer={[
            <Button key="nonmove" onClick={onCancelMove}>
              Non
            </Button>,
            <Button
              key="ouimove"
              type="primary"
              loading={isMoveLoading}
              onClick={onConfirmMove}
            >
              Oui
            </Button>,
          ]}
        >
          <br />
          <Form
            form={form3}
            // initialValues={{ selectedDateMove: selectedDate.start }}
          >
            <Form.Item
              name="selectedDateMove"
              label="Nouvelle date du rendez-vous souhaitée"
              rules={[
                {
                  type: "object",
                  required: true,
                  message:
                    "Veuillez séléctionner la date à laquelle vous souhaiter déplacer le rendez-vous",
                },
              ]}
            >
              <DatePicker
                showTime
                format="DD-MM-YYYY HH:mm"
                // disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23]}
                disabledTime={disabledDateTime}
                hideDisabledOptions={true}
                minuteStep={30}
                showNow={false}
              />
            </Form.Item>
            <Form.Item
              name="confirmMoveOption"
              label="Options"
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner une option",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="validateAndSendConfirm">
                  Valider l'horaire et envoyer un mail de confirmation
                </Radio>
                <Radio value="sendConvocationRequest">
                  Envoyer la demande de confirmation sur l'horaire indiqué
                </Radio>
                <Radio value="justMove">
                  Juste déplacer (aucun mail envoyé)
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={showMoveFreeMeetingConfirmModal}
          icon={<ExclamationCircleOutlined />}
          title="Vous souahitez déplacer un RDV Libre dans l'agenda"
          onCancel={onCancelMoveFreeMeeting}
          footer={[
            <Button key="nonmove" onClick={onCancelMoveFreeMeeting}>
              Non
            </Button>,
            <Button
              key="ouimove"
              type="primary"
              loading={isMoveFreeMeetingLoading}
              onClick={onConfirmMoveFreeMeeting}
            >
              Oui
            </Button>,
          ]}
        >
          <br />
          Confirmez-vous cette action ?
        </Modal>

        <Modal
          open={showDelConfirmModal}
          icon={<ExclamationCircleOutlined />}
          onOk={onConfirmDel}
          onCancel={onCancelDel}
          okText={"Oui"}
          cancelText={"Non"}
        >
          Vous êtes sur le point de supprimer un rendez-vous de l'agenda. <br />
          <br />
          La personne pour laquelle vous annulez rendez-vous recevra
          automatiquement un mail la notifiant de l'annulation si vous
          choisissez de cocher l'option.
          <br />
          <br />
          <Form
            form={form2}
            initialValues={{
              isMailConfirmationDel: true,
            }}
          >
            <Form.Item name="isMailConfirmationDel" valuePropName="checked">
              <Checkbox>Mail d'annulation</Checkbox>
            </Form.Item>
          </Form>
          Confirmez-vous cette action ?
        </Modal>

        <Modal
          open={showDelFreeMeetingConfirmModal}
          icon={<ExclamationCircleOutlined />}
          onOk={onConfirmDelFreeMeeting}
          onCancel={onCancelDelFreeMeeting}
          okText={"Oui"}
          cancelText={"Non"}
        >
          Vous êtes sur le point de supprimer un RDV Libre de l'agenda. <br />
          <br />
          Ce RDV Libre sera supprimé du planning.
          <br />
          <br />
          Confirmez-vous cette action ?
        </Modal>
        <div className="customTopButton">
          <div id="amoAgendasReadOnly">
            <div className="customCalendarEvent">
              <FullCalendar
                ref={calendarRef}
                locale={frLocale}
                editable={false}
                eventContent={(arg) => {
                  return (
                    <Tooltip
                      title={arg.event.extendedProps.description}
                      // arrow
                    >
                      {renderInnerContent(arg)}
                    </Tooltip>
                  );
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                initialDate={startDate}
                slotMinTime="07:00:00"
                slotMaxTime="20:00:00"
                nowIndicator={true}
                height="auto"
                allDaySlot={false}
                datesSet={(dateInfo) => {
                  if (calendarRef.current !== undefined) {
                    let calendarApi = calendarRef.current.getApi();
                    setViewType(viewTransform[calendarApi.view.type]);
                  }
                }}
                customButtons={{
                  lotView: {
                    text: "Lot",
                    click: async (info) => {
                      setConfig("lotTypo");
                    },
                  },
                  nameView: {
                    text: "Nom",
                    click: async (info) => {
                      setConfig("nameTypo");
                    },
                  },
                  nameLotView: {
                    text: "Nom + Lot",
                    click: async (info) => {
                      setConfig("nameLot");
                    },
                  },
                  lotNameTypoTelView: {
                    text: "Lot + Nom + Tel",
                    click: async (info) => {
                      setConfig("lotNameTypoTel");
                    },
                  },
                  exportOptionsButton: {
                    text: "Export",
                    click: async () => {
                      setShowExportOptions(true);
                    },
                  },
                  reservesButton: {
                    text: "Reservés",
                    click: function () {
                      setValue(2);
                    },
                  },
                  disponiblesButton: {
                    text: "Disponibles",
                    click: function () {
                      setValue(3);
                    },
                  },
                  forceButton: {
                    text: "+",
                    hint: "Ajouter un FM",
                    click: function () {
                      setShowModal(true);
                    },
                  },
                  moveButton: {
                    text: moveButtonTitle,
                    click: function () {
                      let calendarApi = calendarRef.current.getApi();
                      if (moveButtonTitle === "Terminé") {
                        calendarApi.setOption("editable", false);
                        calendarApi.setOption("eventDrop", false);
                        calendarApi.setOption("eventDragStop", false);
                        setMoveButtonTitle("Déplacer");
                        // setMoveButtonTitle(<FaHandPaper />);
                      } else {
                        if (moveButtonTitle !== "Terminé") {
                          message.warning(
                            "Sélectionnez un rendez-vous et déplacez le à l'endroit souhaité"
                          );
                          setMoveButtonTitle("Terminé");
                          calendarApi.setOption("editable", true);

                          // Soit on clique et demande de date
                          calendarApi.setOption("eventClick", (info) => {
                            let count = (info.event.title.match(/\*/g) || [])
                              .length;
                            // console.log(`count : ${count}`);
                            if (
                              count === 2 &&
                              info.event.title.startsWith("*") &&
                              info.event.title.endsWith("*")
                            ) {
                              setMoveSelected(info.event);
                              setShowMoveFreeMeetingConfirmModal(true);
                            } else {
                              setMoveSelected(info.event);
                              form3.setFieldsValue({
                                ["selectedDateMove"]: dayjs(
                                  new Date(info.event.start)
                                ),
                              });
                              setShowMoveConfirmModal(true);
                            }
                          });

                          // Soit on deplace sur la meme semaine
                          calendarApi.setOption("eventDrop", (info) => {
                            let count = (info.event.title.match(/\*/g) || [])
                              .length;
                            // console.log(`count : ${count}`);
                            if (
                              count === 2 &&
                              info.event.title.startsWith("*") &&
                              info.event.title.endsWith("*")
                            ) {
                              setMoveSelected(info.event);
                              setShowMoveFreeMeetingConfirmModal(true);
                            } else {
                              setMoveSelected(info.event);
                              form3.setFieldsValue({
                                ["selectedDateMove"]: dayjs(
                                  new Date(info.event.start)
                                ),
                              });
                              setShowMoveConfirmModal(true);
                            }
                          });
                        }
                      }
                    },
                  },
                  showFreeMeetingsButton: {
                    text: showFreeMeetings
                      ? "Masquer RDVs Libres"
                      : "Montrer RDVs Libres",
                    hint: "Afficher les RDV Libres",
                    click: (info) => {
                      let f = formatEventsImpose(
                        planning,
                        config,
                        !showFreeMeetings, // NEW !!
                        null,
                        fmtBankHolidays
                      );

                      setFmtEvents(f[0]);
                      setEventSources(f[1]);
                      setShowFreeMeetings(!showFreeMeetings);
                    },
                  },
                  deleteButton: {
                    text: "-",
                    hint: "Supprimer un RDV",
                    click: (info) => {
                      let calendarApi = calendarRef.current.getApi();
                      calendarApi.setOption("eventClick", async (info) => {
                        if (
                          !isBackgroundEvent(info) &&
                          !info.event.id.startsWith("background")
                        ) {
                          setDelSelected(info.event.id);
                          let count = (info.event.title.match(/\*/g) || [])
                            .length;
                          if (
                            // FREE MEETING
                            count === 2 &&
                            info.event.title.startsWith("*") &&
                            info.event.title.endsWith("*")
                          ) {
                            // setShowModal(true);
                            setShowDelFreeMeetingConfirmModal(true);
                          } else {
                            // NOT FREE MEETING
                            message.info(
                              "Impossible de supprimer autre chose qu'un RDV Libre"
                            );
                          }
                        }
                      });
                      message.warning(
                        "Veuillez sélectionner le rdv à supprimer"
                      );
                    },
                  },
                }}
                headerToolbar={{
                  left: "prev,next showFreeMeetingsButton",
                  center: "title",
                  right: `${
                    value === 2 || value === 4
                      ? "moveButton deleteButton forceButton exportOptionsButton timeGridDay,timeGridWeek,dayGridMonth"
                      : "exportOptionsButton timeGridDay,timeGridWeek,dayGridMonth"
                  }`,
                }}
                footerToolbar={{
                  left: "lotView,nameView,nameLotView,lotNameTypoTelView",
                  center: "",
                  right: "",
                }}
                eventSources={eventSources}
                displayEventTime={config === "lotNameTypoTel" ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
      <ExportModal
        handleCancel={handleCancel}
        showExportOptions={showExportOptions}
        onClickExportICS={onClickExportICS}
        isExportICSLoading={isExportICSLoading}
        setIsExportICSLoading={setIsExportICSLoading}
        onClickExportPDF={onClickExportPDF}
        isExportPDFLoading={isExportPDFLoading}
        setIsExportPDFLoading={setIsExportPDFLoading}
        onClickExportXLS={onClickExportXLSImpose}
        isExportXLSLoading={isExportXLSLoading}
        setIsExportXLSLoading={setIsExportXLSLoading}
        isSingleAmo={isSingleAmo}
        setIsSingleAmo={setIsSingleAmo}
        plannings={plannings}
        eventSourcetoSend={eventSources}
        config={config}
        viewType={viewType}
        showFreeMeetings={showFreeMeetings}
        fmtEvents={events}
      />
    </div>
  );
};

export default AgendaReadOnlyImpose;
