import React from "react";

import {
  LogoutOutlined,
  SettingOutlined,
  IdcardOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

export const UserMenuData = [
  {
    name: "compte",
    label: "Compte",
    icon: <SettingOutlined />,
    path: "/profile",
  },
  {
    name: "rgpd",
    label: "RGPD",
    icon: <IdcardOutlined />,
    path: "/rgpd",
  },
  {
    name: "aide",
    label: "Aide",
    icon: <PhoneOutlined />,
    path: "/aide",
  },
  {
    name: "deconnexion",
    label: "Déconnexion",
    icon: <LogoutOutlined />,
    path: "/logout",
  },
];
