import { roundToTwo } from "./../utils";

export default (clients) => {
  let num =
    clients
      .map((x) => x.duree / 1000)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) *
    1000 *
    2.77778e-7;
  // num = num.toFixed(1);
  // console.log(`original : ${num} (toFixed : ${num.toFixed(1)})`);
  return roundToTwo(num);
};
