import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import ReactDOM from "react-dom";
import { Tooltip, Select, App, Modal } from "antd";
import { getRdvApiAuth, getAmoNb, mapList } from "../utils";
import axios from "axios";

const { Option } = Select;
let startDay = "06:00";
let endDay = "20:00";
let startShift = "08:30";
let endShift = "18:30";
let startLunch = "12:30";
let endLunch = "13:30";
let availableColor = "#d4dfc7";

const AmosAgendas = ({
  eventSources,
  value,
  setValue,
  events,
  planning,
  setPlanning,
  fetchPlanningAndFmtEvents,
  notBookedList,
  amos,
  setShowDrawer,
  eventsTime,
  clientsTime,
  evts,
  dispatch,
}) => {
  const { message } = App.useApp();
  const calendarRef = useRef();
  const [selectedAmo, setSelectedAmo] = useState(0);
  const [neededTime, setNeededTime] = useState(clientsTime);
  const [availableTime, setAvailableTime] = useState(eventsTime);
  const [showAddAmoModal, setShowAddAmoModal] = useState(false);

  const [isTest, setIsTest] = useState(false);
  const [isOk, setIsOk] = useState(undefined);

  const incrementNb = planning.pas === 900 ? 0.25 : 0.5;
  // console.log(evts);

  const handleChange = (value) => {
    setSelectedAmo(parseInt(value));
    let calendarApi = calendarRef.current.getApi();
    let listEvents = calendarApi.getEvents();
    listEvents.forEach((event) => {
      if (event.extendedProps.newlyAdded === true) {
        event.remove();
      }
    });
  };

  let startDate = undefined;
  if (eventSources[0].events.length !== 0) {
    startDate = moment(
      new Date(Math.min(...eventSources[0].events.map((x) => x.start)))
    ).format("yyyy-MM-DD");
  } else if (events.length > 0) {
    startDate = moment(
      new Date(Math.min(...events.map((x) => x.start)))
    ).format("yyyy-MM-DD");
  } else {
    startDate = moment(new Date(planning.planningStartTime)).format(
      "yyyy-MM-DD"
    );
  }

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <div>&nbsp;</div>}
          </div>
        </div>
      </div>
    );
  }
  const renderTitleBar = (at, nt) => {
    const customTitle = document.querySelector("#amoAgendas .fc-toolbar-title");
    // console.log(`customTitle : ${customTitle}`);
    ReactDOM.render(
      `Disponible : ${at}h | Necessaire : ${nt}h | Marge : ${at - nt}h`,
      customTitle
    );

    const topLeftCorner = document.querySelector(
      "#amoAgendas .fc-timegrid-axis-frame"
    );
    ReactDOM.render(
      <Select
        style={{ width: 200 }}
        // popupClassName="my-select-popup"
        // showArrow={false}
        suffixIcon={null}
        onChange={handleChange}
        defaultOpen={true}
        defaultValue={`${amos[0] === undefined ? 0 : amos[0].initials}`}
        // dropdownRender={(menu) => <div>{menu}</div>}
      >
        {[...Array(getAmoNb(JSON.parse(planning.grid))).keys()].map((x) => (
          <Option value={x} key={x}>
            {/* {`${
              amos[x] === undefined
                ? x
                : `${amos[x].lastname} ${amos[x].firstname}`
            }`} */}
            {`${amos[x] === undefined ? x : `${amos[x].initials}`}`}
          </Option>
        ))}
      </Select>,
      topLeftCorner
    );
  };

  useEffect(() => {
    renderTitleBar(availableTime, neededTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customFoobarTitle">
      <div id="amoAgendas">
        <Modal
          title="Ajout d'un AMO"
          open={showAddAmoModal}
          onCancel={() => setShowAddAmoModal(false)}
          onOk={async () => {
            try {
              let res = await axios.patch(
                process.env.REACT_APP_RDV_API_URL +
                  "/rdv/add-amo/" +
                  planning._id,
                {},
                getRdvApiAuth()
              );
              // console.log(`res.data => ${JSON.stringify(res.data)}`);
              setPlanning({ ...planning, grid: res.data.grid });
              dispatch({ type: "ADD_AMO", nbOfAmos: res.data.nbOfAmos });

              const topLeftCorner = document.querySelector(
                "#amoAgendas .fc-timegrid-axis-frame"
              );
              ReactDOM.render(
                <Select
                  style={{ width: 200 }}
                  showArrow={false}
                  onChange={handleChange}
                  defaultOpen={true}
                  defaultValue={`${
                    amos[0] === undefined ? 0 : amos[0].initials
                  }`}
                >
                  {[...Array(res.data.nbOfAmos).keys()].map((x) => (
                    <Option value={x} key={x}>
                      {`${amos[x] === undefined ? x : `${amos[x].initials}`}`}
                    </Option>
                  ))}
                </Select>,
                topLeftCorner
              );

              // console.log(`RES TEST GRID -> ${JSON.stringify(res)}`);
              // await fetchPlanningAndFmtEvents();
              message.success("AMO ajouté avec succès");
            } catch (e) {
              console.log(e);
              if (e.response) {
                message.error(`${e.response.data.message}`);
              } else {
                message.error("Erreur lors l'ajout d'un nouvel AMO");
              }
            }
            setShowAddAmoModal(false);
          }}
        >
          Une fois le nouvel AMO ajouté, vous pourrez lui ajouter les crénaux
          horaires où il est disponible et proposer de nouvelles dates aux
          acquéreurs.
        </Modal>
        <div className="customTopButton">
          <div className="customCalendarEvent">
            <FullCalendar
              ref={calendarRef}
              locale={frLocale}
              editable={false}
              // slotLabelInterval={15}
              displayEventTime={planning.pas === 900 ? true : false}
              eventContent={(arg) => {
                return (
                  <Tooltip title={arg.event.extendedProps.description}>
                    {renderInnerContent(arg)}
                  </Tooltip>
                );
              }}
              dayHeaderFormat={{
                weekday: "short",
                month: "numeric",
                day: "numeric",
                omitCommas: true,
              }}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              slotDuration={planning.pas === 900 ? "00:15:00" : "00:30:00"}
              // slotDuration="00:15:00"
              // slotLabelInterval={planning.pas === 900 ? "00:15:00" : "00:30:00"}
              // slotLabelInterval="00:15:00"
              initialView="timeGridWeek"
              initialDate={startDate}
              slotMinTime="07:00:00"
              slotMaxTime="20:00:00"
              nowIndicator={true}
              height="auto"
              allDaySlot={false}
              dateClick={(info) => {
                if (!isTest) setIsTest(true);
                if (isOk) setIsOk(undefined);
                let start = info.date;
                let end = new Date(info.date.getTime() + 1000 * planning.pas);
                let booked = false;
                let calendarApi = calendarRef.current.getApi();

                calendarApi.addEvent({
                  amo: selectedAmo,
                  start,
                  end,
                  booked,
                  id: start,
                  color: availableColor,
                  newlyAdded: true,
                });

                let newEvt = {
                  amo: selectedAmo.toString(),
                  start: new Date(start).getTime(),
                  end: new Date(end).getTime(),
                  booked,
                };

                // Change Needed Time in Title
                // setAvailableTime(availableTime + 0.5);
                let newA = availableTime + incrementNb;
                setAvailableTime(newA);

                renderTitleBar(newA, neededTime);
                dispatch({ type: "ADD_AMO_EVENT", selectedAmo, event: newEvt });
              }}
              eventClick={(info) => {
                if (!isTest) setIsTest(true);
                if (isOk) setIsOk(undefined);
                // console.log(`click() : ${JSON.stringify(info.event)}`);
                if (info.event.extendedProps.booked !== false) {
                  message.error("Tranche horaire occupée par un rdv");
                } else {
                  let calendarApi = calendarRef.current.getApi();
                  let evtToDel = calendarApi.getEventById(info.event.id);
                  evtToDel.remove();
                  let newEvt = {
                    amo: selectedAmo,
                    start: new Date(info.event.start).getTime(),
                    end: new Date(info.event.end).getTime(),
                    booked: "@unavailable",
                  };

                  // Change Needed Time in Title
                  // console.log(`availableTime : ${availableTime}`);
                  let newA = availableTime - incrementNb;
                  setAvailableTime(newA);

                  renderTitleBar(newA, neededTime);
                  dispatch({
                    type: "REMOVE_AMO_EVENT",
                    selectedAmo,
                    event: newEvt,
                  });
                }
              }}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right:
                  isTest && isOk === undefined
                    ? "cancelModifications checkAlgo addAmoButton timeGridWeek,timeGridDay"
                    : isTest && isOk === true
                    ? "cancelModifications confirmAlgo addAmoButton timeGridWeek,timeGridDay"
                    : "addAmoButton timeGridWeek,timeGridDay",
              }}
              // headerToolbar={false}
              // viewDidMount={(view) => {
              // sleep(5000);
              // renderTitleBar(availableTime, neededTime);
              // }}
              footerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              customButtons={{
                checkAlgo: {
                  text: "TEST",
                  click: async (info) => {
                    let res = await axios.patch(
                      process.env.REACT_APP_RDV_API_URL +
                        "/rdv/test-book-unavailabilities/" +
                        localStorage.getItem("currentPlanningId"),
                      evts,
                      getRdvApiAuth()
                    );
                    if (!res.data.error) {
                      if (res.data.availabilities === undefined) {
                        message.error(
                          "Pas assez de temps pour faire les visites. Veuillez ajouter des disponibilités."
                        );
                      } else {
                        setIsOk(true);
                      }
                    } else {
                      message.error(`Erreur lors de la requete`);
                    }
                  },
                },
                confirmAlgo: {
                  text: "CONFIRMER",
                  click: async (info) => {
                    // console.log(`CONFIRMER()`);
                    try {
                      await axios.patch(
                        process.env.REACT_APP_RDV_API_URL +
                          "/rdv/book-unavailabilities/" +
                          planning._id,
                        evts,
                        getRdvApiAuth()
                      );

                      // console.log(`RES TEST GRID -> ${JSON.stringify(res)}`);
                      await fetchPlanningAndFmtEvents();
                      setIsTest(false);
                      setIsOk(undefined);
                      message.success(
                        "Disponibilités mises à jour avec succès"
                      );
                      setShowDrawer(false);
                      // Remove events in the calendar
                      let calendarApi = calendarRef.current.getApi();
                      let listEvents = calendarApi.getEvents();
                      listEvents.forEach((event) => {
                        if (event.extendedProps.newlyAdded === true) {
                          event.remove();
                        }
                      });
                    } catch (e) {
                      console.log(e);
                      if (e.response) {
                        message.error(`${e.response.data.message}`);
                      } else {
                        message.error(
                          "Erreur lors de la prise en compte des indisponibilités"
                        );
                      }
                    }
                  },
                },
                cancelModifications: {
                  text: "ANNULER",
                  click: async (info) => {
                    // console.log(`CLICK SUR ANNULER`);
                    // console.log(
                    //   `eventsTime : ${eventsTime} | clientsTime : ${clientsTime} | Marge : ${
                    //     eventsTime - clientsTime
                    //   }`
                    // );
                    setIsOk(undefined);
                    setIsTest(false);
                    // setNeededTime(eventsTime);
                    // setAvailableTime(clientsTime);
                    setNeededTime(clientsTime);
                    setAvailableTime(eventsTime);
                    renderTitleBar(eventsTime, neededTime);

                    dispatch({
                      type: "RESET",
                      ial: mapList(
                        JSON.parse(planning.grid),
                        planning.pas,
                        startDay,
                        endDay,
                        startLunch,
                        endLunch,
                        startShift,
                        endShift
                      ),
                      planning,
                    });
                    let calendarApi = calendarRef.current.getApi();
                    let listEvents = calendarApi.getEvents();
                    listEvents.forEach((event) => {
                      if (event.extendedProps.newlyAdded === true) {
                        event.remove();
                      }
                    });
                  },
                },
                addAmoButton: {
                  text: "+",
                  hint: "Ajouter un AMO",
                  click: function () {
                    setShowAddAmoModal(true);
                  },
                },
              }}
              eventSources={evts[selectedAmo]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmosAgendas;
