import { formatEvents, formatEventsImpose } from "./../utils";
import moment from "moment";
// const momentTz = require("moment-timezone");

const addDays = (day, nb) => {
  var dat = new Date(day.valueOf());
  dat.setDate(dat.getDate() + nb);
  return dat;
};
const getDayDates = (startDate, stopDate, weekend = true) => {
  var dateArray = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    if (!weekend && currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      dateArray.push(moment(currentDate));
    } else if (weekend) {
      dateArray.push(moment(currentDate));
    }
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};
// const getWeekDates2 = (startDate, endDate) => {
//   //first the function and variable definitions
//   let dates = [];
//   const addDays = function (days) {
//     var date = new Date(this.valueOf());
//     date.setDate(date.getDate() + days);
//     return date;
//   };
//   //now our Sunday check
//   let currentDate = startDate;

//   // NEW DELETE
//   if (currentDate.getDay() > 1) {
//     // console.log("not a sunday...adjusting");
//     currentDate.setDate(currentDate.getDate() - currentDate.getDay());
//   }

//   while (currentDate <= endDate) {
//     let endWeekDate = addDays.call(currentDate, 6);
//     dates.push({
//       begin: new Date(currentDate.setHours(0, 0, 1)),
//       end: new Date(endWeekDate.setHours(23, 59, 59)),
//     });
//     currentDate = addDays.call(currentDate, 7);
//   }
//   return dates;
// };
const getWeekDates = (startDate, endDate) => {
  // console.log(`ENTREE:\n${momentTz(startDate)} - ${momentTz(endDate)}`);
  const firstDay = startDate.getDay();
  const lastDay = endDate.getDay();
  const firstDayOfFirstWeek = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() - firstDay + (firstDay != 0 ? 1 : -6) // +1 if not sunday, -6 if sunday
  );
  const lastDayOfLastWeek = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate() + (lastDay != 0 ? 7 - lastDay : 0) // +7 if not sunday, 0 if sunday
  );
  const weeks = [];
  let currentWeek = firstDayOfFirstWeek;
  while (currentWeek <= lastDayOfLastWeek) {
    weeks.push({
      begin: currentWeek,
      end: new Date(
        new Date(
          currentWeek.getFullYear(),
          currentWeek.getMonth(),
          currentWeek.getDate() + 6
        ).setHours(23, 59, 59)
      ),
    });
    currentWeek = new Date(
      currentWeek.getFullYear(),
      currentWeek.getMonth(),
      currentWeek.getDate() + 7
    );
  }
  return weeks;
};

const getMonthDates = (startDate, endDate) => {
  // if (endDate.isBefore(startDate)) {
  //   throw "End date must be greated than start date.";
  // }

  if (startDate.getMonth() === endDate.getMonth()) {
    return [startDate];
  } else {
    startDate = moment(startDate);
    endDate = moment(endDate);
    var result = [];
    while (42) {
      if (startDate.isBefore(endDate)) {
        result.push(new Date(startDate));
        startDate.add(1, "month");
      } else {
        result.push(new Date(startDate));
        startDate.add(1, "month");
        break;
      }
    }
    return result;
  }
};

const computeMinMaxDate = (bigFMT) => {
  let eventSources = [];
  let f0s = [];
  bigFMT.forEach((fmt) => {
    // console.log(`fmt :`);
    // console.log(fmt);
    f0s = f0s.concat(fmt[0]);
    f0s = f0s.concat(getFmEvents(fmt[1]));
    eventSources = eventSources.concat(fmt[1]);
  });
  // console.log(`bigFMT :`);
  // console.log(bigFMT);
  // console.log(`eventSources :`);
  // console.log(eventSources);
  // console.log(`f0s :`);
  // console.log(f0s);
  let maxDate = new Date(
    Math.max(
      ...f0s.map((element) => {
        return new Date(element.start).setHours(23, 59, 59);
      })
    )
  );
  let minDate = new Date(
    Math.min(
      ...f0s.map((element) => {
        return new Date(element.start).setHours(0, 0, 1);
      })
    )
  );
  return [minDate, maxDate, eventSources];
};

const getFmEvents = (fmt1) => {
  let fmEvents = [];
  fmt1.forEach((fmt) => {
    if (fmt.hasOwnProperty("startStr") && fmt["startStr"] === "fm") {
      fmEvents = fmEvents.concat(fmt.events);
    }
  });
  return fmEvents;
};

const initReducer = (
  plannings,
  pas,
  templateType,
  flag = "lotTypo",
  showFreeMeetings = false,
  amoNb = null,
  fmtBankHolidays = null
) => {
  //console.log(`initReducer()`);
  //console.log(`amoNb : ${amoNb}`);
  // console.log(`inside REDUCER ()`);
  // console.log(`type : ${templateType}`);
  // Format eventSources for fullCalendar
  // According to plannings

  let bigFMT = [];
  plannings.forEach((planning) => {
    let fmt = undefined;
    if (templateType === "libre") {
      //console.log(`amoNbbbbb : ${amoNb}`);
      fmt = formatEvents(
        planning,
        flag,
        showFreeMeetings,
        amoNb,
        fmtBankHolidays
      );
      // console.log(fmt);
    } else if (templateType === "impose") {
      fmt = formatEventsImpose(
        planning,
        flag,
        showFreeMeetings,
        amoNb,
        fmtBankHolidays
      );
    }
    bigFMT.push(fmt);
  });
  // console.log(`bigFMT.length : ${bigFMT.length}`);
  // console.log(bigFMT);

  let tmp = computeMinMaxDate(bigFMT);
  let minDate = tmp[0];
  let maxDate = tmp[1];
  let eventSources = tmp[2];

  // console.log(`minDate : ${minDate} - maxDate : ${maxDate}`);

  // let monthDates = getMonthDates(minDate, maxDate).map((_) =>
  //   new Date(_).getMonth()
  // );
  // console.log("monthDates");
  // console.log(monthDates);

  const filterEvents = (evts, begin, end) => {
    // Function that returns only evts between start and end
    // console.log(`\nEVENT : ${JSON.stringify(es.events)}`);
    return evts.filter((evt) => {
      // console.log(
      //   `evt.start : ${evt.start}, new Date(begin).getTime() : ${new Date(
      //     begin
      //   )}`
      // );
      // console.log(`evt.allDay : ${JSON.stringify(evt)}`);
      if ("display" in evt && evt.display === "background") {
        return evt;
      } else {
        return (
          new Date(evt.start).getTime() > new Date(begin).getTime() &&
          new Date(evt.end).getTime() < new Date(end).getTime()
        );
      }
    });
  };

  const filterEventsMonth = (evts, month) => {
    // Function that returns only evts between start and end
    return evts.filter((evt) => {
      if ("display" in evt && evt.display === "background") {
        return evt;
      } else {
        return (
          (!evt.id.includes("background") &&
            month === new Date(evt.start).getMonth()) ||
          evt.id.includes("background")
        );
      }
    });
  };

  const getNewEventSources = (evts, minDate, maxDate, pas) => {
    let newEventSources = [];
    if (pas === "Semaine") {
      let weekDates = getWeekDates(minDate, maxDate);
      // console.log(`weekDates semaines : ${weekDates}`);
      weekDates.forEach((wd, idx) => {
        newEventSources[idx] = [];
        evts.forEach((es) => {
          let obj = { events: [], color: es.color };
          if ("display" in es) obj["display"] = es.display;
          // console.log(`\nwd.begin : ${wd.begin}, wd.end : ${wd.end}`);
          // console.log(`es.event -> ${es.event}`);
          let newEvents = filterEvents(es.events, wd.begin, wd.end);
          obj.events = newEvents;
          newEventSources[idx] = newEventSources[idx].concat(obj);
        });
      });
    } else if (pas === "Jour") {
      // console.log(`*** PAS JOUR ***`);
      let dayDates = getDayDates(minDate, maxDate);
      dayDates.forEach((wd, idx) => {
        newEventSources[idx] = [];
        evts.forEach((es) => {
          let obj = { events: [], color: es.color };
          if ("display" in es) obj["display"] = es.display;
          let startDay = new Date(new Date(wd).setHours(0, 0, 1));
          let endDay = new Date(new Date(wd).setHours(23, 59, 59));
          let newEvents = filterEvents(es.events, startDay, endDay);
          obj.events = newEvents;
          newEventSources[idx] = newEventSources[idx].concat(obj);
        });
      });
    } else if (pas === "Mois") {
      // console.log(`*** PAS MOIS ***`);
      let monthDates = getMonthDates(minDate, maxDate).map((_) =>
        new Date(_).getMonth()
      );
      // console.log("MONTH DATES : ");
      // console.log(JSON.stringify(monthDates));
      monthDates.forEach((md, idx) => {
        newEventSources[idx] = [];
        evts.forEach((es) => {
          let obj = { events: [], color: es.color };
          if ("display" in es) obj["display"] = es.display;
          let newEvents = filterEventsMonth(es.events, md);
          obj.events = newEvents;
          newEventSources[idx] = newEventSources[idx].concat(obj);
        });
      });
    }
    // console.log(newEventSources);

    return newEventSources;
  };

  const getIsWeekend = (plannings) => {
    // console.log(`getIsWeekend()`);
    let isWeekend = true;
    let dayNb = undefined;
    plannings.forEach((planning) => {
      // console.log(planning.name);
      let tmpBool = planning.broadcastList
        .filter((_) => _.isBooked)
        .concat(planning.freeMeetings)
        .some((d) => {
          // console.log(`d ---> ${JSON.stringify(d)}`);
          dayNb = new Date(d.start).getDay();
          // console.log(`dayNb => ${dayNb}`);
          if (dayNb === 0 || dayNb === 6) {
            return true;
          } else {
            return false;
          }
        });
      // console.log(`tmpBool : ${JSON.stringify(tmpBool)}`);
      // console.log(`isWeekend : ${isWeekend}, tmpBool : ${tmpBool}`);
      isWeekend = isWeekend && tmpBool;
      // console.log(isWeekend);
    });
    return isWeekend;
  };

  let newEventSources = undefined;
  let starts = undefined;
  let isWeekend = true;

  const isEmptyEvent = (currValue) => currValue.events.length === 0;
  const removeEmptyEvents = (es, st) => {
    // Remove unecessary Events
    let i = es.length;
    while (i--) {
      if (es[i].every(isEmptyEvent)) {
        es.splice(i, 1);
        st.splice(i, 1);
      }
    }
  };

  // Verify computation is not made at reducer initialization
  if (!isNaN(minDate.getTime())) {
    // console.log(`Event sources :::`);
    // console.log(eventSources);
    if (pas === "Semaine") {
      newEventSources = getNewEventSources(eventSources, minDate, maxDate, pas);
      // console.log(`New event sources : `);
      // console.log(newEventSources);
      starts = getWeekDates(minDate, maxDate).map((_) => _.begin);
      //console.log(`starts: ${JSON.stringify(starts)}`);
      isWeekend = getIsWeekend(plannings);
      removeEmptyEvents(newEventSources, starts);
    } else if (pas === "Jour") {
      newEventSources = getNewEventSources(eventSources, minDate, maxDate, pas);
      starts = getDayDates(minDate, maxDate, true).map((_) => new Date(_));
      // console.log(starts);
      removeEmptyEvents(newEventSources, starts);
    } else if (pas === "Mois") {
      newEventSources = getNewEventSources(eventSources, minDate, maxDate, pas);
      starts = getMonthDates(minDate, maxDate, true);
    }
  }
  // console.log(`RETURN`);
  // console.log({
  //   eventSources: newEventSources,
  //   minDate,
  //   maxDate,
  //   starts,
  //   isWeekend,
  // });
  return {
    eventSources: newEventSources,
    minDate,
    maxDate,
    starts,
    isWeekend,
  };
};

const sharedPlanningReducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      // console.log(
      //   `CASE RESET / ShowFM : ${action.showFreeMeetings}, amoNb = ${action.amoNb}`
      // );
      // console.log(`fmtBO`);
      // console.log(action.fmtBankHolidays);
      return initReducer(
        action.plannings,
        action.pas,
        action.templateType,
        action.flag,
        action.showFreeMeetings,
        action.amoNb,
        action.fmtBankHolidays
      );
    default:
      return state;
  }
};

export { initReducer, sharedPlanningReducer as default };
