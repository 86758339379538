import React from "react";
import { Typography } from "antd";
import ContentContainer from "../../../components/ContentContainer";
import PlanningsVrTable from "../../../components/plannings/PlanningsVrTable";
import CustomPageHeader from "../../../components/CustomPageHeader";
import SubContentContainer from "../../../components/SubContentContainer";

const { Title } = Typography;
const Visites = () => {
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Visites Virtuelles"
          arrow={true}
        ></CustomPageHeader>
        <PlanningsVrTable />
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Visites;
