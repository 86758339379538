import React from "react";

import {
  HomeOutlined,
  ThunderboltOutlined,
  AlertOutlined,
  ControlOutlined,
  AppstoreOutlined,
  CommentOutlined,
  FolderOpenOutlined,
  ToolOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

export const LeftMenuData = [
  {
    name: "home",
    label: "Accueil",
    icon: <HomeOutlined />,
    path: "/accueil-acquereur",
    key: "sub1",
  },
  {
    name: "currentHousing",
    label: localStorage.getItem("currentHousing"),
    icon: <ThunderboltOutlined />,
    items: [
      {
        name: "actualite",
        label: "Actualité",
        icon: <AlertOutlined />,
        path: "#top",
        key: "1",
      },
      {
        name: "tmas",
        label: "TMA(s)",
        icon: <ControlOutlined />,
        path: "/tmas",
        key: "2",
      },
      {
        name: "choix",
        label: "Choix",
        icon: <AppstoreOutlined />,
        path: "/choix",
        key: "3",
      },
      {
        name: "messages",
        label: "Messages",
        icon: <CommentOutlined />,
        path: "/messages",
        key: "4",
      },
      {
        name: "documents",
        label: "Documents",
        icon: <FolderOpenOutlined />,
        path: "/documents",
        key: "5",
      },
      {
        name: "chantier",
        label: "Chantier",
        icon: <ToolOutlined />,
        path: "/chantier",
        key: "6",
      },
      {
        name: "faq",
        label: "FAQ",
        icon: <QuestionCircleOutlined />,
        path: "/foire-aux-questions",
        key: "7",
      },
    ],
    key: "sub2",
  },
];
