import React from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import { Row, Col, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { phaseTransform } from "../../utils";

const { Title, Text } = Typography;

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const AgendaCalendarPrint = ({
  viewType,
  plannings,
  evts,
  dispatch,
  showCal,
  setShowCal,
  flag,
}) => {
  return (
    <div>
      {evts.eventSources.map((evt, idx) => {
        return (
          <div
            key={`md-${idx}`}
            style={{
              pageBreakAfter: "always",
            }}
          >
            <div>
              <Row align="middle">
                <Col span={1}></Col>
                <Col span={7}>
                  <img
                    alt="avvatar"
                    src={
                      process.env.REACT_APP_API_URL +
                      "/programs/" +
                      plannings[0].projectId +
                      "/avatar"
                    }
                    style={{ maxWidth: "70px" }}
                  />
                </Col>
                <Col span={8}>
                  <center>
                    {plannings.length === 1 ? (
                      <div>
                        <Title level={5}>{`${plannings[0].name} - Visites ${
                          phaseTransform[plannings[0].phase]
                        }`}</Title>

                        <Text>{`(${plannings[0].nbInvited} lots)`}</Text>
                      </div>
                    ) : (
                      <div>
                        <Title level={5}>{`${
                          plannings[0].projectName
                        } - Visites ${
                          phaseTransform[plannings[0].phase]
                        }`}</Title>

                        <Text>{`(${plannings.reduce(function (accumulator, p) {
                          return accumulator + p.nbInvited;
                        }, 0)} lots)`}</Text>
                      </div>
                    )}
                  </center>
                </Col>
                <Col span={7} verticalalign="middle">
                  <div
                    style={{
                      verticalAlign: "middle",
                      textAlign: "right",
                      marginRight: "3px",
                    }}
                  >
                    {plannings[0].address.rue !== "" ? (
                      <Text>
                        <EnvironmentOutlined />{" "}
                        {`${plannings[0].address.numero} ${plannings[0].address.rue} ${plannings[0].address.codepostal} ${plannings[0].address.ville} ${plannings[0].address.pays}`}
                      </Text>
                    ) : (
                      <Text>
                        <EnvironmentOutlined />?
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={1}></Col>
              </Row>

              <Row align="middle">
                <Col span={1}></Col>
                <Col span={22}>
                  <div></div>
                  <div className="customTopButton">
                    <div className="customCalendarEvent">
                      {viewType !== "Jour" ? (
                        <FullCalendar
                          locale={frLocale}
                          eventOrder="amo"
                          allDaySlot={false}
                          editable={false}
                          headerToolbar={false}
                          plugins={[timeGridPlugin, dayGridPlugin]}
                          initialView={
                            viewType === "Semaine"
                              ? "timeGridWeek"
                              : viewType === "Jour"
                              ? "timeGridDay"
                              : "dayGridMonth"
                          }
                          initialDate={evts.starts[idx]}
                          displayEventTime={
                            flag === "lotNameTypoTel" ? false : true
                          }
                          slotMinTime="07:00:00"
                          slotMaxTime="20:00:00"
                          height="auto"
                          eventSources={evt}
                          nowIndicator={true}
                          weekends={evts.isWeekend}
                        />
                      ) : (
                        <FullCalendar
                          locale={frLocale}
                          allDaySlot={false}
                          editable={false}
                          headerToolbar={false}
                          plugins={[timeGridPlugin, dayGridPlugin]}
                          initialView={
                            viewType === "Semaine"
                              ? "timeGridWeek"
                              : viewType === "Jour"
                              ? "timeGridDay"
                              : "dayGridMonth"
                          }
                          initialDate={evts.starts[idx]}
                          slotMinTime="07:00:00"
                          slotMaxTime="20:00:00"
                          height="auto"
                          eventSources={evt}
                          nowIndicator={true}
                          dayHeaderContent={(args) => {
                            return capitalizeFirstLetter(
                              moment(args.date).format("dddd DD/MM").toString()
                            );
                          }}
                          weekends={evts.isWeekend}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={1}></Col>
              </Row>

              <br />
              <Row align="middle">
                <Col span={1}></Col>
                <Col span={22}>
                  <center>
                    {plannings[0].templateType === "promoter" ? (
                      <Text style={{ textAlign: "center" }}>
                        {" "}
                        {`Taux de réponse : ${Math.round(
                          ((plannings.reduce(
                            (accumulator, p) => accumulator + p.nbBooked,
                            0
                          ) +
                            plannings.reduce(
                              (accumulator, p) =>
                                accumulator + p.nbNotAvailable,
                              0
                            )) /
                            plannings.reduce(
                              (accumulator, p) => accumulator + p.nbInvited,
                              0
                            )) *
                            100
                        )}% | Total invités : ${plannings.reduce(
                          (accumulator, p) => accumulator + p.nbInvited,
                          0
                        )} | Réservé(s) : ${plannings.reduce(
                          (accumulator, p) => accumulator + p.nbBooked,
                          0
                        )} | En attente : ${
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbNotBooked,
                            0
                          ) -
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbNotAvailable,
                            0
                          ) -
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbInactive,
                            0
                          )
                        } | Indisponible(s) : ${plannings.reduce(
                          (accumulator, p) => accumulator + p.nbNotAvailable,
                          0
                        )}`}
                      </Text>
                    ) : (
                      <Text style={{ textAlign: "center" }}>
                        {" "}
                        {`Taux de réponse : ${Math.round(
                          (plannings.reduce(
                            (accumulator, p) => accumulator + p.nbConfirmed,
                            0
                          ) /
                            plannings.reduce(
                              (accumulator, p) => accumulator + p.nbInvited,
                              0
                            )) *
                            100
                        )}% | Total convoqués : ${plannings.reduce(
                          (accumulator, p) => accumulator + p.nbInvited,
                          0
                        )} | Confirmé(s) : ${
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbConfirmed,
                            0
                          ) -
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbNotAvailable,
                            0
                          )
                        } | En attente : ${
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbInvited,
                            0
                          ) -
                          plannings.reduce(
                            (accumulator, p) => accumulator + p.nbConfirmed,
                            0
                          )
                        } | Indisponible(s) : ${plannings.reduce(
                          (accumulator, p) => accumulator + p.nbNotAvailable,
                          0
                        )}`}
                      </Text>
                    )}
                  </center>
                </Col>
                <Col span={1}></Col>
              </Row>

              <Row align="middle">
                <Col span={1}></Col>
                <Col span={22}>
                  <div
                    style={{
                      textAlign: "center",
                      opacity: 0.5,
                      fontSize: 13,
                      fontFamily:
                        "SF Regular, Segoe System UI Regular, Segoe UI Regular, sans-serif",
                    }}
                  >
                    Bridgit ©2024
                  </div>
                </Col>
                <Col span={1}></Col>
              </Row>

              <div
                key={`md-${idx}`}
                style={{
                  pageBreakAfter: "always",
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AgendaCalendarPrint;
