import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  App,
  Empty,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Affix,
  Tooltip,
  Layout,
  Spin,
  Space,
  // Col,
  Checkbox,
  // Row,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import ProgramList from "./ProgramList";
import {
  cleanLocalStorage,
  generateToken,
  getBasicAuthConfig,
} from "../../utils";
import qs from "qs";
import CreateProgramModal from "../../components/plannings/modals/CreateProgramModal";

const { Content } = Layout;

// When acqueror notifs
// const initialNotifications = {
//   actualite: [],
//   tmas: [],
//   choix: [],
//   messages: [],
//   documents: [],
//   chantier: [],
// };

const ProgramPage = () => {
  const { message } = App.useApp();
  const [selectedFile, setSelectedFile] = useState(null);
  const [programsList, setProgramsList] = useState([]);
  const [originPrograms, setOriginPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isArchivedSelected, setIsArchivedSelected] = useState(false);
  const [isActifSelected, setIsActifSelected] = useState(true);
  const [val, setVal] = useState("");
  const [form] = Form.useForm();
  const [selectedColor, setSelectedColor] = useState("rgb(67, 99, 134)");
  const [defaultColors, setDefaultColors] = useState([
    "rgb(67, 99, 134)",
    "rgb(204, 236, 250)",
    "rgb(1, 2, 3)",
  ]);
  const [isColorPickerHidden, setIsColorPickerHidden] = useState(true);

  const normFile = (e) => {
    if (e.fileList[0] !== undefined) {
      setSelectedFile(e.fileList[0].originFileObj);
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const fetchUserProgramsFilter = async (actif, archived) => {
    try {
      let query = qs.stringify({
        params: { isActifSelected: actif, isArchivedSelected: archived },
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me/programs?${query}`,
        getBasicAuthConfig()
      );

      setOriginPrograms(res.data);

      if (val !== "") {
        setProgramsList(
          res.data.filter(
            (program) =>
              program.name.toLowerCase().includes(val) ||
              program.promotername.toLowerCase().includes(val) ||
              program.adresseville.toLowerCase().includes(val)
          )
        );
      } else {
        setProgramsList(res.data);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      message.error(`Une erreur est apparue.`);
    }
  };

  const onArchivedChange = (event) => {
    setIsArchivedSelected(event.target.checked);
    setIsLoading(true);
    fetchUserProgramsFilter(isActifSelected, event.target.checked);
  };

  const onActifChange = (event) => {
    setIsActifSelected(event.target.checked);
    setIsLoading(true);
    fetchUserProgramsFilter(event.target.checked, isArchivedSelected);
  };

  const fetchUserPrograms = async () => {
    let query = qs.stringify({
      params: { isActifSelected, isArchivedSelected },
    });
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/me/programs?${query}`,
      getBasicAuthConfig()
    );
    setProgramsList(res.data);
    setOriginPrograms(res.data);
    setIsLoading(false);
    // When acqueror notif
    // setNotifications(initialNotifications);
  };

  const removeProgram = async (programId) => {
    message.error(`Vous n'avez pas la permission d'effectuer cette opération.`);
    // try {
    //   await axios.delete(
    //     process.env.REACT_APP_API_URL + "/programs/" + programId,
    //     getBasicAuthConfig()
    //   );
    //   fetchUserPrograms();
    //   message.success("Programme supprimé !");
    // } catch (e) {
    //   console.log(e);
    //   message.error(`Erreur lors de la suppression du programme.`);
    // }
  };

  const createProgram = async (values) => {
    // console.log(values);
    const config = getBasicAuthConfig();
    const colorFounded = defaultColors.find((c) => c === selectedColor);
    let localDefaultColors = defaultColors;
    if (!colorFounded) {
      localDefaultColors = [selectedColor, ...defaultColors];
    } else {
      //color is founded so put it in first position to be the main color
      localDefaultColors = defaultColors.filter((c) => c !== selectedColor);
      localDefaultColors = [selectedColor, ...localDefaultColors];
    }
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/program/create",
        {
          name: values.title,
          promotername: values.promotername,
          clients: undefined,
          colors: isColorPickerHidden
            ? ["rgb(67, 99, 134)", ...defaultColors]
            : localDefaultColors,
          authToken: generateToken(),
        },
        config
      );
      if (selectedFile !== null) {
        const formData = new FormData();
        formData.append("avatar", selectedFile, selectedFile.name);
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            res.data._id +
            "/avatar",
          formData,
          config
        );
      }
      setVisible(false);
      fetchUserPrograms();
      message.success(`Programme ${values.title} créé !`);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(
          `Erreur lors de la création du programme (${e.response.data.error})`
        );
        setVisible(false);
      } else {
        message.error(`Erreur lors de la création du programme (${e})`);
        setVisible(false);
      }
      fetchUserPrograms();
    }
  };

  const onSearchChange = (e) => {
    let searchQuery = e.target.value.toLowerCase();
    setVal(e.target.value.toLowerCase());
    // console.log(e.target.value);

    const newPrograms = originPrograms.filter(
      (program) =>
        program.name.toLowerCase().includes(searchQuery) ||
        program.promotername.toLowerCase().includes(searchQuery) ||
        program.adresseville.toLowerCase().includes(searchQuery)
    );
    setProgramsList(newPrograms);
    // console.log(`newPrograms : ${JSON.stringify(newPrograms)}`);
    // searchProgramByText(e, newPrograms);
  };

  useEffect(() => {
    cleanLocalStorage();
    fetchUserPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ margin: "0px 24px 0px 16px" }}>
        <Checkbox onChange={onArchivedChange}>Archivés</Checkbox>
        <Checkbox onChange={onActifChange} defaultChecked={true}>
          Actifs
        </Checkbox>
      </div>

      <Affix
        // offsetTop={72}
        offsetTop={84}
        style={{ position: "absolute", top: 0, right: 16 }}
      >
        <Space direction="horizontal">
          <Tooltip placement="leftTop" title="Créer">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setVisible(true);
              }}
            />
            <CreateProgramModal
              visible={visible}
              onCreate={createProgram}
              setSelectedFile={setSelectedFile}
              onCancel={() => {
                setVisible(false);
              }}
              form={form}
              defaultColors={defaultColors}
              setDefaultColors={setDefaultColors}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              isColorPickerHidden={isColorPickerHidden}
              setIsColorPickerHidden={setIsColorPickerHidden}
            />
          </Tooltip>
          <div>
            <Input placeholder="🔍 Chercher ..." onChange={onSearchChange} />
          </div>
        </Space>
      </Affix>

      <Content
        style={{
          margin: "24px 24px 0px 16px",
          padding: 0,
          // minHeight: 280,
        }}
      >
        {isLoading ? (
          <div className="loader-spin">
            <Spin />
          </div>
        ) : (
          <div>
            {programsList.length === 0 ? (
              <Empty description={<span>Aucun programme pour le moment.</span>}>
                <Tooltip placement="leftTop" title="Créer">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                  <CreateProgramModal
                    visible={visible}
                    onCreate={createProgram}
                    setSelectedFile={setSelectedFile}
                    onCancel={() => {
                      setVisible(false);
                    }}
                    form={form}
                    defaultColors={defaultColors}
                    setDefaultColors={setDefaultColors}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    isColorPickerHidden={isColorPickerHidden}
                    setIsColorPickerHidden={setIsColorPickerHidden}
                  />
                </Tooltip>
              </Empty>
            ) : (
              <div className="site-card-wrapper">
                <ProgramList
                  programsList={programsList}
                  removeProgram={removeProgram}
                />
              </div>
            )}
          </div>
        )}
      </Content>
    </div>
  );
};

export default ProgramPage;
