import React, { useState, useEffect, useReducer } from "react";
import sharedPlanningReducer, {
  initReducer,
} from "./../../reducers/sharedplanning";
import AgendaCalendarPrint from "./AgendaCalendarPrint";
import AgendaCalendarPrintMulti from "./AgendaCalendarPrintMulti";
import { bankHolidays } from "./planningData";

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB", //"#FF5456", "#C0C0C0"
    display: "background",
    allDay: true,
  });
}

const PrintPdfPrivate = ({
  plannings,
  flag,
  viewType,
  isFreeMeeting,
  isMulti,
  amoNb,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [evts, dispatch] = useReducer(sharedPlanningReducer, [], initReducer);
  const [showCal, setShowCal] = useState(false);

  useEffect(() => {
    dispatch({
      type: "RESET",
      plannings,
      pas: viewType,
      templateType:
        plannings[0].templateType === "promoter" ? "libre" : "impose",
      flag,
      showFreeMeetings: isFreeMeeting,
      //isMulti: isMulti,
      amoNb,
      fmtBankHolidays,
    });
    setShowCal(true);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!isLoading && !isMulti && evts.eventSources !== undefined && (
        <div>
          <AgendaCalendarPrint
            viewType={viewType}
            plannings={plannings}
            evts={evts}
            dispatch={dispatch}
            showCal={showCal}
            setShowCal={setShowCal}
            flag={flag}
          />
        </div>
      )}

      {!isLoading && isMulti && evts.eventSources !== undefined && (
        <div>
          <br />
          <br />
          <AgendaCalendarPrintMulti
            viewType={viewType}
            plannings={plannings}
            evts={evts}
            dispatch={dispatch}
            showCal={showCal}
            setShowCal={setShowCal}
            flag={flag}
          />
        </div>
      )}
    </div>
  );
};

export default PrintPdfPrivate;
