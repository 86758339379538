import React, { useEffect } from "react";
import selectEventTotalTime from "../selectors/events-total-time";

const EventListSummary = ({ form, events, setEventHours }) => {
  useEffect(() => {
    // console.log(`EventListSummary useEffect()`);
    setEventHours(totalTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  let totalTime = undefined;
  let vals = form.getFieldValue("startEndLunch");
  if (vals && events && events.length !== 0) {
    totalTime = selectEventTotalTime(
      events,
      new Date(vals[0]).toLocaleTimeString().slice(0, 5),
      new Date(vals[1]).toLocaleTimeString().slice(0, 5)
    );
  }
  return (
    <div>
      Disponible &nbsp;: {totalTime || "?"} {totalTime && " H"}
    </div>
  );
};

export default EventListSummary;
