import React from "react";
import { Select, Checkbox, App } from "antd";
import { getAmoFromAmoList } from "../../utils";

const NotificationAmo = ({
  amoList,
  selectedAmo,
  setSelectedAmo,
  areAmosAssigned,
  form,
  setNotifiedList,
}) => {
  const { message } = App.useApp();
  const { Option } = Select;
  const children = [];

  // const onAmoAssignedChange = (elt) => {
  //   // console.log(`val -> ${JSON.stringify(elt)}`);
  //   if (!events || events.length === 0) {
  //     message.error(
  //       `Veuillez préalablement sélectionner les plages horaires des visites.`
  //     );
  //   } else {
  //     setAreAmosAssigned(elt.target.checked);
  //   }
  // };

  amoList.forEach((amo) => {
    children.push(
      <Option key={amo._id} label={amo.firstname + " " + amo.lastname}>
        {amo.firstname + " " + amo.lastname}
      </Option>
    );
  });

  function handleChange(value) {
    // console.log(`handleChange : ${value}`);
    // TODO change this static sstuff
    setSelectedAmo(value);

    let newNotifiedList = value.map((x) => {
      let a = getAmoFromAmoList(x, amoList); //res2.data.find((_) => _._id === x);
      // if (a !== undefined)
      return {
        gender: a.gender,
        telephone: a.telephonemonsieur,
        firstname: a.firstname,
        lastname: a.lastname,
        email: a.email,
        enterprise: a.enterprise,
      };
    });

    // console.log(`newNotifiedList : ${JSON.stringify(newNotifiedList)}`);
    setNotifiedList(newNotifiedList);

    // let newMailInvitation = undefined;
    // let newSmsRappel = undefined;
    // let newMailRappel = undefined;
    // if (value.length > 0) {
    // Récupère les infos sur l'AMO
    // let sAmoIdx = amoList.findIndex((x) => x._id === value[0]);
    // Met a jour mailInvitation
    // if (
    //   amoList[sAmoIdx].telephonemonsieur === undefined ||
    //   amoList[sAmoIdx].telephonemonsieur === ""
    // ) {
    //   if (mailRespClient !== undefined && mailRespClient !== "") {
    // newMailInvitation = originMailInvitation.replace(
    //   "votre AMO",
    //   `${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} (${mailRespClient})`
    // );
    // newMailRappel = originMailRappel.replace(
    //   "<LIEN_PLAN>.",
    //   `<LIEN_PLAN>.\n\nVotre contact est ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} (${mailRespClient}).`
    // );
    // } else {
    // newMailInvitation = originMailInvitation.replace(
    //   "votre AMO",
    //   `${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname}`
    // );
    // newMailRappel = originMailRappel.replace(
    //   "<LIEN_PLAN>.",
    //   `<LIEN_PLAN>.\n\nPour toute(s) question(s), vous pouvez joindre ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname}.`
    // );
    // }
    // newSmsRappel = `${originSmsRappel} Contact: ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname}`;
    // } else {
    // if (mailRespClient !== undefined && mailRespClient !== "") {
    // newMailInvitation = mailInvitation.replace(
    //   "votre AMO",
    //   `${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} au ${amoList[sAmoIdx].telephonemonsieur} (${mailRespClient})`
    // );
    // newMailRappel = originMailRappel.replace(
    //   "<LIEN_PLAN>.",
    //   `<LIEN_PLAN>.\n\nPour toute(s) question(s), vous pouvez joindre ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} par téléphone au ${amoList[sAmoIdx].telephonemonsieur} ou par mail à l'adresse suivante: ${mailRespClient}.`
    // );
    // } else {
    // newMailInvitation = mailInvitation.replace(
    //   "votre AMO",
    //   `${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} au ${amoList[sAmoIdx].telephonemonsieur}`
    // );
    // newMailRappel = originMailRappel.replace(
    //   "<LIEN_PLAN>.",
    //   `<LIEN_PLAN>.\n\nPour toute(s) question(s), vous pouvez joindre ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} par téléphone au ${amoList[sAmoIdx].telephonemonsieur}.`
    // );
    // }
    // newSmsRappel = `${originSmsRappel} Contact: ${amoList[sAmoIdx].lastname} ${amoList[sAmoIdx].firstname} (${amoList[sAmoIdx].telephonemonsieur})`;
    // }
    // Met a jour mailRappel
    // } else {
    // newMailInvitation = originMailInvitation;
    // newSmsRappel = originSmsRappel;
    // newMailRappel = originMailRappel;
    // }

    if (newNotifiedList.length === 0) {
      form.setFieldsValue({
        contactTitre: undefined,
        contactNom: undefined,
        contactPrenom: undefined,
        contactMail: undefined,
        contactTelephone: undefined,
        signature: undefined,
      });
    } else {
      form.setFieldsValue({
        contactTitre: newNotifiedList[0].gender,
        contactNom: newNotifiedList[0].firstname,
        contactPrenom: newNotifiedList[0].lastname,
        contactMail: newNotifiedList[0].email,
        contactTelephone: newNotifiedList[0].telephone,
        signature: newNotifiedList[0].enterprise,
      });
    }

    // setMailInvitation(newMailInvitation);
    // setSmsRappel(newSmsRappel);
    // setMailRappel(newMailRappel);
  }
  return (
    <div>
      <Select
        mode="multiple"
        size="default"
        placeholder="Sélectionner un/plusieurs AMO(s) pour effectuer les visites"
        optionFilterProp="label"
        allowClear
        // defaultValue={
        //   getAmoFromAmoList(authorizedAmo[0], amoList).firstname +
        //   " " +
        //   getAmoFromAmoList(authorizedAmo[0], amoList).lastname
        // }
        defaultValue={selectedAmo}
        onChange={handleChange}
        style={{ width: "50%" }}
      >
        {children}
      </Select>
      <br />
      <Checkbox
        style={{ marginTop: "10px" }}
        checked={areAmosAssigned}
        // onChange={onAmoAssignedChange}
        disabled
      >
        Proposez le choix des AMO(s) aux acquéreurs (version beta)
      </Checkbox>
      {/* {!areAmosAssigned ? null : Math.max(
          ...events.map((_) => parseInt(_.nbAmo))
        ) !== selectedAmo.length ? (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Nombre d'AMO incorrect par rapport aux contraintes
        </Tag>
      ) : (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Nombre d'AMOs OK
        </Tag>
      )} */}
    </div>
  );
};

export default NotificationAmo;
