import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const breadcrumbNameMap = {
  "/projets": "Programmes",
  "/accueil": "Accueil",
  "/promoter/plannings": "Plannings",
  "/promoter/templates": "Modèles",
  "/promoter/templates/nouveau": "Nouveau",
  "/promoter/templates/help": "Mode d'emploi",
  "/profile": "Profil",
  "/promoter": "Promoteur",
  "/parametres": "Paramètres",
  "/visites": "Visites 3D",
};

const breadcrumbNameSubProjetsMap = {
  clients: "Clients",
  messages: "Messages",
  plannings: "Plannings",
  "plannings-libres": "Plannings",
  "plannings-imposes": "Plannings",
  "plannings-consolidate": "Plannings",
  "visites-3d": "Visites 3D",
  parametres: "Paramètres",
};

const getBreadCrumbNameMap = (url) => {
  let tab = url.split("/");

  if (tab.length < 3 || Object.keys(breadcrumbNameMap).includes(url)) {
    return breadcrumbNameMap[url];
  } else {
    if (tab.length === 3) {
      return localStorage.getItem("currentProgram");
    } else if (tab.length === 4) {
      return breadcrumbNameSubProjetsMap[tab[3]];
    } else if (tab.length === 5) {
      if (tab.includes("nouveau")) {
        return "Nouveau";
      } else if (tab.includes("clients")) {
        return localStorage.getItem("currentUser");
      } else if (tab.includes("nouveau-impose")) {
        return "Nouveau";
      } else {
        return localStorage.getItem("currentPlanning");
      }
    }
  }
};

const CustomBreadcrumb = withRouter((props) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    let url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (url.includes("plannings-imposes")) {
      url = url.replace("plannings-imposes", "plannings");
    } else if (url.includes("plannings-libres")) {
      url = url.replace("plannings-libres", "plannings");
    }
    return {
      key: url,
      title: <Link to={url}>{getBreadCrumbNameMap(url)}</Link>,
    };
  });
  const breadcrumbItems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
      key: "home",
    },
  ].concat(extraBreadcrumbItems);

  return (
    <div className="breadcrumb-container">
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
});

export default CustomBreadcrumb;
