import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  getBasicAuthConfig,
  isSiretNumberOk,
  juridicalForms,
} from "../../utils";
import { Form, Input, Button, Typography, App, Select, Space } from "antd";

const { Option } = Select;
const { Title } = Typography;

const EntityProfile = ({ entity }) => {
  const { message } = App.useApp();
  let history = useHistory();
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { offset: 2, span: 10 },
  };

  const validateMessages = {
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const onFinish = async (values) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/admin/clients/${entity._id}`,
        values,
        getBasicAuthConfig()
      );
      history.push("/admin/facturation/entities/");
      message.success(
        `Informations de l'entité de facturation correctement mises à jour`
      );
    } catch (e) {
      console.log(e);
      message.error(
        `Erreur lors de la mise à jour des informations de l'entité de facturation`
      );
    }
  };

  return (
    <div>
      <div>
        <Title level={4}>
          Mise à jour des informations de l'entité de facturation
        </Title>
        <br />
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{
            formejuridique: entity.formejuridique,
            raisonsociale: entity.raisonsociale,
            siret: entity.siret,
            adressenumero: entity.adressenumero,
            adresserue: entity.adresserue,
            adressecodepostal: entity.adressecodepostal,
            adresseville: entity.adresseville,
            adressepays: entity.adressepays,
            credits: entity.credits,
            promoter: entity.promoter,
          }}
          {...formItemLayout}
        >
          <Form.Item
            label="Forme Juridique"
            name="formejuridique"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer la forme juridique",
              },
            ]}
          >
            <Select showSearch optionFilterProp="value">
              {juridicalForms.map((_) => (
                <Select.Option key={_} value={_.toLocaleLowerCase()}>
                  {_}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Raison Sociale"
            name="raisonsociale"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer la raison sociale",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Promoteur"
            name="promoter"
            rules={[
              {
                required: true,
                message: "Veuillez indiquer le promoteur",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Numéro de SIRET"
            name="siret"
            rules={[
              {
                required: true,
                validator: async (_, payload) => {
                  if (payload === undefined || payload === "") {
                    return Promise.reject(
                      new Error(`Veuillez indiquer un numéro de SIRET`)
                    );
                  } else if (!isSiretNumberOk(payload)) {
                    return Promise.reject(
                      new Error(`Numéro de SIRET incorrect (14 digits)`)
                    );
                  }
                },
                // message: "Veuillez indiquer un numéro de SIRET",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item form={form} label="Adresse principale" required>
            <Space.Compact>
              <Form.Item
                name={"adressenumero"}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez indiquer un numéro de rue",
                  },
                ]}
              >
                <Input
                  style={{ width: "20%", marginBottom: "4px" }}
                  mar
                  placeholder="No"
                />
              </Form.Item>
              <Form.Item
                name={"adresserue"}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez indiquer un nom de rue",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "80%",
                    marginBottom: "4px",
                  }}
                  placeholder="rue"
                />
              </Form.Item>
            </Space.Compact>
            <Space.Compact>
              <Form.Item
                name={"adressecodepostal"}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez indiquer un code postal",
                  },
                ]}
              >
                <Input style={{ width: "20%" }} placeholder="code postal" />
              </Form.Item>
              <Form.Item
                name={"adresseville"}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez indiquer une ville",
                  },
                ]}
              >
                <Input style={{ width: "30%" }} placeholder="ville" />
              </Form.Item>
              <Form.Item
                name={"adressepays"}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez indiquer un pays",
                  },
                ]}
              >
                <Input style={{ width: "50%" }} placeholder="pays" />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
          <Form.Item label="Nombre de crédits" name="credits">
            <Input />
          </Form.Item>
          <Form.Item
          // wrapperCol={{
          //   offset: 8,
          //   span: 16,
          // }}
          >
            <Button type="primary" htmlType="submit">
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EntityProfile;
