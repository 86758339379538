import React from "react";
import { Column } from "@ant-design/charts";

const VisitLogsHistogram = ({ logs }) => {
  var config = {
    data: logs,
    width: 200,
    height: 300,
    xField: "date",
    yField: "counter",
    color: "#436386",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    meta: {
      date: { alias: "connections" },
      counter: { alias: "vues" },
      // mm: { alias: "moyenne mobile 3J" },
    },
    legend: {
      layout: "horizontal",
      position: "right",
    },
  };

  return (
    <div>
      <Column {...config} />
    </div>
  );
};

export default VisitLogsHistogram;
// <Line {...config} />
