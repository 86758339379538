import React from "react";
import { message, Modal, Form, Input, Space } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";

const ModifyAddressModal = ({ show, setShow, address, setAddress, form }) => {
  const onCreate = async (values) => {
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          localStorage.getItem("currentPlanningId"),
        {
          address: {
            numero: values.adresse.numero,
            rue: values.adresse.rue,
            codepostal: values.adresse.codepostal,
            ville: values.adresse.ville,
            pays: values.adresse.pays,
          },
        },
        getRdvApiAuth()
      );

      setShow(false);
      message.success(
        `Point de rendez-vous correctement mis à jour pour ce planning`
      );
    } catch (e) {
      console.log(e);
    }
  };

  const update = () => {
    const values = form.getFieldsValue("adresse");
    onCreate(values);
    setAddress({
      numero: values.adresse.numero,
      rue: values.adresse.rue,
      codepostal: values.adresse.codepostal,
      ville: values.adresse.ville,
      pays: values.adresse.pays,
    });
  };

  return (
    <Modal
      title="Mise à jour de l'adresse du point de rendez-vous"
      style={{ top: 40 }}
      open={show}
      onOk={update}
      onCancel={() => setShow(false)}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          adresse: {
            numero: address.numero,
            rue: address.rue,
            codepostal: address.codepostal,
            ville: address.ville,
            pays: address.pays,
          },
        }}
      >
        <Form.Item
          label="Adresse du point de rendez-vous"
          required
          tooltip="Par défault, s'agit de l'adresse du programme indiquée dans les paramètres de ce dernier"
        >
          <Space.Compact>
            <Form.Item
              name={["adresse", "numero"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un numéro de rue",
                },
              ]}
            >
              <Input
                style={{ width: "20%", marginBottom: "4px" }}
                placeholder="No"
              />
            </Form.Item>
            <Form.Item
              name={["adresse", "rue"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un nom de rue",
                },
              ]}
            >
              <Input
                style={{
                  width: "80%",
                  marginBottom: "4px",
                }}
                placeholder="rue"
              />
            </Form.Item>
          </Space.Compact>
          <Space.Compact>
            <Form.Item
              name={["adresse", "codepostal"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un code postal",
                },
              ]}
            >
              <Input style={{ width: "20%" }} placeholder="code postal" />
            </Form.Item>
            <Form.Item
              name={["adresse", "ville"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer une ville",
                },
              ]}
            >
              <Input style={{ width: "30%" }} placeholder="ville" />
            </Form.Item>
            <Form.Item name={["adresse", "pays"]} noStyle>
              <Input style={{ width: "50%" }} placeholder="pays" />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModifyAddressModal;
