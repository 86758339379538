import React from "react";
import { Select, DatePicker, Checkbox, Form } from "antd";

const { Option } = Select;

const AddForceMeeting = ({
  form,
  planning,
  amos,
  notBookedList,
  disabledDateTime,
}) => {
  return (
    <div>
      <Form.Item
        name="selected"
        label="Invité"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Veuillez sélectionner un invité !",
          },
        ]}
      >
        <Select placeholder="Sélectionnez un invité">
          {notBookedList
            .sort((a, b) => {
              return a.guest.lotcomm.localeCompare(b.guest.lotcomm);
            })
            .map((rdv) => {
              return (
                <Option key={rdv._id} value={rdv._id}>
                  {`[${rdv.guest.lotcomm}] ${rdv.guest.nom}`}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      {planning.areAmosAssigned && Object.keys(amos).length > 0 && (
        <Form.Item
          name="selectedAmo"
          label="AMO assigné"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner un AMO pour la visite",
            },
          ]}
        >
          <Select placeholder="Sélectionnez un AMO">
            {amos.map((amo, idx) => {
              return (
                <Option key={idx} value={idx}>
                  {`${amo.firstname} ${amo.lastname}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="selectedDate"
        label="Date de rendez-vous souhaitée"
        rules={[
          {
            type: "object",
            required: true,
            message:
              "Veuillez séléctionner la date à laquelle vous souhaiter placer l'invité",
          },
        ]}
      >
        <DatePicker
          showTime
          format="DD-MM-YYYY HH:mm"
          // disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23]}
          disabledTime={disabledDateTime}
          hideDisabledOptions={true}
          minuteStep={15}
          showNow={false}
        />
      </Form.Item>
      <Form.Item name="isMailConfirmation" valuePropName="checked">
        <Checkbox>Mail de confirmation</Checkbox>
      </Form.Item>
    </div>
  );
};

export default AddForceMeeting;
