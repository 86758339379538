import React from "react";
import frLocale from "antd/es/date-picker/locale/fr_FR";
import {
  Select,
  DatePicker,
  App,
  Form,
  Input,
  ColorPicker,
  Divider,
} from "antd";
import { defaultColors } from "../../planningData";

const { Option } = Select;

const AddFreeMeeting = ({ recentColors }) => {
  const { message } = App.useApp();
  const times = {
    "30'": 1800000,
    "45'": 2700000,
    "1h": 3600000,
    "1h30": 5400000,
    "2h": 7200000,
    "2h30": 9000000,
    "3h": 10800000,
    "4h": 14400000,
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
    };
  };

  const FormForceBook = () => {
    return (
      <div>
        <br />
        <Form.Item
          name="freeMeetingName"
          label="Nom"
          rules={[
            {
              required: true,
              message: "Veuillez séléctionner un nom pour ce RDV libre",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="freeMeetingDate"
          label="Date"
          rules={[
            {
              type: "object",
              required: true,
              message:
                "Veuillez séléctionner la date à laquelle vous souhaiter placer le RDV Libre",
            },
          ]}
        >
          <DatePicker
            locale={frLocale}
            showTime
            format="DD-MM-YYYY HH:mm"
            disabledTime={disabledDateTime}
            hideDisabledOptions={true}
            minuteStep={15}
            showNow={false}
          />
        </Form.Item>

        <Form.Item name="duree" label="Durée" rules={[{ required: true }]}>
          <Select placeholder="Sélectionnez une durée" allowClear>
            {Object.keys(times).map((m, idx) => {
              return (
                <Option key={idx} value={times[m]}>
                  {`${m}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <hr />

        <Form.Item
          // name="color-picker"
          name="color"
          label="Couleur"
          rules={[
            {
              required: true,
              message: "Veuillez séléctionner une couleur",
            },
          ]}
        >
          <ColorPicker
            styles={{
              popupOverlayInner: {
                width: 468 + 24,
              },
            }}
            format="hex"
            presets={[
              {
                label: "Suggestions",
                colors: defaultColors,
              },
              {
                label: "Personnalisées",
                colors: recentColors,
              },
            ]}
            // onChangeComplete={(color) => {
            //   console.log(`colorPicked : (value : #${color.toHex()})`);
            //   console.log(color);
            // }}
            panelRender={(_, { components: { Picker, Presets } }) => (
              <div
                className="custom-panel"
                style={{
                  display: "flex",
                  width: 468,
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Presets />
                </div>
                <Divider
                  type="vertical"
                  style={{
                    height: "auto",
                  }}
                />
                <div
                  style={{
                    width: 234,
                  }}
                >
                  <Picker />
                </div>
              </div>
            )}
          />
        </Form.Item>

        <hr />
      </div>
    );
  };

  return <FormForceBook />;
};

export default AddFreeMeeting;
