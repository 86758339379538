import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { bankHolidays } from "./planningData";

let fmtBankHollidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHollidays.push({
    title: v,
    start: k,
    color: "#FF5456",
    display: "background",
    allDay: true,
  });
}

const CalendarEvents = ({
  setIsNoMoreRdvToPlace,
  calendarRef,
  initCalendarEvents,
  evts,
  dispatch,
  showAll,
}) => {
  const onDrop = (info) => {
    // info.event.setProp("classNames", "fc-event-8");
    // info.event.setProp(
    //   "classNames",
    //   `.fc-event-1, .fc-event-15, .fc-event-2, .fc-event-3, .fc-event-4, .fc-event-5, .fc-event-6, .fc-event-7, .fc-event-8`
    // );
    dispatch({
      type: "ADD_EVENT",
      info,
    });

    if (evts.notPlaced.length === 1) {
      setIsNoMoreRdvToPlace(true);
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <i>{eventInfo.text}</i>
      </>
    );
  }

  let startDate = undefined;

  if (initCalendarEvents.length !== 0) {
    startDate = moment(
      new Date(Math.min(...initCalendarEvents.map((x) => new Date(x.start))))
    ).format("yyyy-MM-DD");
  } else {
    startDate = moment(new Date()).format("yyyy-MM-DD");
  }

  return (
    <div>
      <div className="demo-app-calendar" id="mycalendartest">
        <div className="customTopButton">
          <div className="customCalendarEvent">
            <FullCalendar
              initialView="timeGridWeek"
              // eventDragMinDistance={1}
              // slotDuration="00:05:00"
              snapDuration={showAll ? "00:15:00" : "00:30:00"}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: null,
              }}
              dayHeaderContent={renderEventContent}
              rerenderDelay={10}
              eventDurationEditable={false}
              editable={true}
              droppable={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              ref={calendarRef}
              initialEvents={initCalendarEvents}
              locale={frLocale}
              allDaySlot={false}
              eventReceive={onDrop}
              height="auto"
              slotMinTime="07:00:00"
              slotMaxTime="20:00:00"
              initialDate={startDate}
              // eventSources={[{ events: fmtBankHollidays }]} // CalendarEvents 121 does not work
              viewDidMount={(view) => {
                let draggableEl = document.getElementById("external-events");
                //changed
                new Draggable(draggableEl, {
                  itemSelector: `.fc-event-1, .fc-event-15, .fc-event-2, .fc-event-3, .fc-event-4, .fc-event-5, .fc-event-6, .fc-event-7, .fc-event-8`,
                  eventData: (eventEl) => {
                    // console.log(eventEl);
                    return {
                      title: eventEl.getAttribute("title"),
                      id: eventEl.getAttribute("data"),
                      duration: eventEl.getAttribute("duration"),
                      lotcomm: eventEl.getAttribute("lotcomm"),
                      email: eventEl.getAttribute("email"),
                      classNames: eventEl.getAttribute("class"),
                      // borderColor: eventEl.getAttribute("class"),
                    };
                  },
                });
              }}
              eventDidMount={function (info) {
                // console.log(`fullCalendar eventDidMount()`);
                let clickCnt = 0;
                let oneClickTimer = undefined;
                info.el.addEventListener("click", function () {
                  clickCnt++;
                  if (clickCnt === 1) {
                    oneClickTimer = setTimeout(function () {
                      clickCnt = 0;
                    }, 400);
                  } else if (clickCnt === 2) {
                    clearTimeout(oneClickTimer);
                    clickCnt = 0;
                    dispatch({
                      type: "REMOVE_EVENT",
                      info,
                      calendarRef,
                    });

                    if (setIsNoMoreRdvToPlace) {
                      setIsNoMoreRdvToPlace(false);
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarEvents;
