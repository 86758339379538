import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
// import { Secret, decode, verify } from "jsonwebtoken";
// import jwt from "jsonwebtoken";
import axios from "axios";
import { getBasicAuthConfig } from "../../utils";
import {
  App,
  Table,
  Select,
  Popconfirm,
  Form,
  Input,
  Space,
  Button,
} from "antd";
import { UserContext } from "../../context/UserContext";

const UsersTable = ({ data, setData, hasLoaded, entities }) => {
  const { message } = App.useApp();
  let history = useHistory();
  const { Option } = Select;
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const { setAuth, setRole, setUsername } = useContext(UserContext);

  const roleTransform = {
    anonymousUser: "Invité",
    customer: "Acquéreur",
    promoter: "Promoteur",
    amo: "AMO",
    moderator: "Modérateur",
    administrator: "Administrateur",
  };

  let entitiesTransform = {};
  entities.forEach((entity) => {
    entitiesTransform[entity._id] = entity.raisonsociale;
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 110 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const requiredFields = ["email"];

  const CustomInput = ({ dataIndex, type, title, entities }) => {
    if (type !== "select" && type !== "selectentity") {
      let isRequiredField = requiredFields.includes(dataIndex);
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: isRequiredField,
              message: `Veuillez remplir ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else if (type === "selectentity") {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Select style={{ width: 120 }}>
            {entities.map((entity) => (
              <Option
                key={entity._id}
                name={entity.raisonsociale}
                value={entity._id}
              >
                {entity.raisonsociale}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Select style={{ width: 120 }}>
            <Option value="anonymousUser">Utilisateur Anonyme</Option>
            <Option value="customer">Acquéreur</Option>
            <Option value="promoter">Promoteur</Option>
            <Option value="amo">AMO</Option>
            <Option value="moderator">Moderateur</Option>
            <Option value="administrator">Administrateur</Option>
          </Select>
        </Form.Item>
      );
    }
  };

  const simulateUser = async (record) => {
    const adminAuthToken = localStorage.getItem("authToken");
    const adminUsername = localStorage.getItem("username");
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/admin/simulate/" + record.key,
        getBasicAuthConfig()
      );

      localStorage.clear();
      localStorage.setItem("adminAuthToken", adminAuthToken);
      localStorage.setItem("adminUsername", adminUsername);
      localStorage.setItem("authToken", res.data.token);
      localStorage.setItem("username", res.data.user.username);
      setAuth(true);
      setRole(res.data.user.role);
      setUsername(res.data.user.username);
      message.success(`👋 Bienvenue ${res.data.user.username}`);
      history.push("/projets");
    } catch (e) {
      console.log(`Error when fetching users list : ${e}`);
      message.error(
        "Vous n'avez pas les permissions pour accéder à la liste des utilisateurs"
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            // val={record.role}
            dataIndex={dataIndex}
            type={inputType}
            title={title}
            entities={entities}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      firstname: "",
      lastname: "",
      enterprise: "",
      email: "",
      mailmonsieur: "",
      mailmadame: "",
      telephonemonsieur: "",
      telephonemadame: "",
      role: record.role,
      invoiceEntity: record.invoiceEntity,
      ...record,
    });
    setEditingKey(record.key);
  };

  const confirmDelete = (record) => {
    delRecord(record);
  };

  const cancelDelete = (e) => {
    console.log(e);
    message.error("Suppression annulée");
  };

  const delRecord = async (record) => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + "/admin/users/" + record.key,
        getBasicAuthConfig()
      );
      message.success(`Utilisateur ${record.email} supprimé`);

      const dataSource = [...data];

      setData(dataSource.filter((item) => item.key !== record.key));
    } catch (e) {
      message.error(
        `Erreur lors de la suppression de l'utilisateur ${record.email}`
      );
      console.log(e);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onRowDoubleClick = (record) => {
    history.push("/admin/users/" + record.key);
  };

  const save = async (key) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      const _id = newData[index].key;
      const updates = await form.validateFields();
      await axios.patch(
        process.env.REACT_APP_API_URL + "/admin/users/" + _id,
        updates,
        getBasicAuthConfig()
      );
      newData[index] = {
        ...newData[index],
        ...updates,
      };
      setData(newData);
      message.success(
        `Données correctement mises à jour pour l'utilisateur ${updates.email}`
      );
      setEditingKey("");
    } catch (errInfo) {
      console.log(errInfo);
      message.error(
        "Erreur lors de la mise à jour de l'utilisateur :",
        errInfo
      );
    }
  };

  const columns = [
    {
      title: "Prénom",
      dataIndex: "firstname",
      key: "firstname",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("firstname"),
      editable: true,
      ellipsis: true,
    },
    {
      title: "Nom",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("lastname"),
      editable: true,
      ellipsis: true,
    },
    {
      title: "Entreprise",
      dataIndex: "enterprise",
      key: "enterprise",
      sorter: (a, b) => a.enterprise.localeCompare(b.enterprise),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("enterprise"),
      editable: true,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      editable: true,
      ...getColumnSearchProps("email"),
      ellipsis: true,
    },
    {
      title: "Mail1",
      dataIndex: "mailmonsieur",
      key: "mailmonsieur",
      editable: true,
      ...getColumnSearchProps("mailmonsieur"),
      ellipsis: true,
    },
    {
      title: "Mail2",
      dataIndex: "mailmadame",
      key: "mailmadame",
      editable: true,
      ...getColumnSearchProps("mailmadame"),
      ellipsis: true,
    },
    {
      title: "telephone1",
      dataIndex: "telephonemonsieur",
      key: "telephonemonsieur",
      editable: true,
      ...getColumnSearchProps("telephonemonsieur"),
      ellipsis: true,
    },
    {
      title: "telephone2",
      dataIndex: "telephonemadame",
      key: "telephonemadame",
      editable: true,
      ...getColumnSearchProps("telephonemadame"),
      ellipsis: true,
    },
    {
      title: "RGPD Ask",
      dataIndex: "gdprAskInfo",
      key: "gdprAskInfo",
      ...getColumnSearchProps("gdprAskInfo"),
      ellipsis: true,
    },
    {
      title: "RGPD Del",
      dataIndex: "gdprDelInfo",
      key: "gdprDelInfo",
      ...getColumnSearchProps("gdprDelInfo"),
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <>{roleTransform[text]}</>,
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ["descend", "ascend"],
      editable: true,
      ellipsis: true,
    },
    {
      title: "Facturation",
      dataIndex: "invoiceEntity",
      key: "invoiceEntity",
      render: (text) => <>{entitiesTransform[text]}</>,
      sortDirections: ["descend", "ascend"],
      editable: true,
      ellipsis: true,
    },
    {
      title: "Simuler",
      dataIndex: "simulate",
      width: "5%",
      render: (_, record) => (
        <Popconfirm
          title="Souhaitez-vous simuler cet utilisateur ?"
          onConfirm={() => {
            simulateUser(record);
          }}
          okText="Oui"
          cancelText="Non"
        >
          <EyeOutlined style={{ display: "block", margin: "auto" }} />
        </Popconfirm>
      ),
      editable: false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a
              onClick={() => {
                save(record.key);
              }}
            >
              Sauver
            </a>
            <Popconfirm title="Souhaitez-vous annuler ?" onConfirm={cancel}>
              <a>Annuler</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a onClick={() => edit(record)} disabled={editingKey !== ""}>
              Editer
            </a>
            <Popconfirm
              title="Etes vous sûr de vouloir supprimer cet utilisateur?"
              onConfirm={() => {
                confirmDelete(record);
              }}
              onCancel={cancelDelete}
              okText="Oui"
              cancelText="Non"
            >
              <a>Suppr</a>
            </Popconfirm>
          </Space>
        );
      },
      ellipsis: true,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "role"
            ? "select"
            : col.dataIndex === "invoiceEntity"
            ? "selectentity"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form size="small" form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        rowKey="key"
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              // console.log(`selected : ${JSON.stringify(record)}`);
              onRowDoubleClick(record);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      />
    </Form>
  );
};

export default UsersTable;
