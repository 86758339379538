import React, { useEffect, useState } from "react";
import ContentContainer from "./../../components/ContentContainer";
import PromoterPlanningsTable from "../../components/promoter/PromoterPlanningsTable";
import axios from "axios";
import {
  getRdvApiAuth,
  getBasicAuthConfig,
  errorCatcher,
  cleanLocalStorage,
} from "../../utils";

import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const PromoterDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [tokens, setTokens] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleTableChange = async (pagination, filters, sorter) => {
    // console.log(`Table Change`);
    let query = {
      params: { pagination, filters, sorter },
      programsTokens: [...tokens],
    };
    const res = await axios.post(
      process.env.REACT_APP_RDV_API_URL + `/pgs`,
      query,
      getRdvApiAuth()
    );
    setData(res.data.plannings);
    setPagination({ ...pagination, total: res.data.totalCount });
  };

  useEffect(() => {
    cleanLocalStorage();
    const newFunc = async () => {
      setLoading(true);
      try {
        // Get authorized / owned
        const res1 = await axios.get(
          process.env.REACT_APP_API_URL + "/promoter/programs-tokens",
          getBasicAuthConfig()
        );
        setTokens(res1.data.map((_) => _.authToken));
        let query = {
          params: {
            pagination: { ...pagination },
            // order: "descend",
            // field: "planningEndTime",
            // columnKey: "planningEndTime",
          },
          programsTokens: res1.data.map((_) => _.authToken),
        };
        // console.log(query);
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL + `/pgs`,
          query,
          getRdvApiAuth()
        );
        let newData = res.data.plannings;
        newData = res.data.plannings.map((_) => ({
          ..._,
          city: _.address.ville,
        }));
        setData(newData);
        // setData(res.data.plannings);
        setPagination({
          current: 1,
          pageSize: 10,
          total: res.data.totalCount,
        });
      } catch (e) {
        errorCatcher(e, `Erreur lors du chargement des plannings`);
      }
      setIsLoading(false);
      setLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Plannings"
          className="site-page-header"
          avatar={{
            src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
          }}
        ></CustomPageHeader>
        {!isLoading && (
          <PromoterPlanningsTable
            data={data}
            pagination={pagination}
            loading={loading}
            setLoading={setLoading}
            handleTableChange={handleTableChange}
            tokens={tokens}
          />
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default PromoterDashboard;
