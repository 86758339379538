import React from "react";
import {
  App,
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  Divider,
  Space,
  Button,
  Tooltip,
} from "antd";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
import axios from "axios";
import { getRdvApiAuth } from "../../utils";

const { Option } = Select;

const PlanningBookingTableEditModal = ({
  usrData,
  setUsrData,
  show,
  setShow,
  fetchPlanningAndFmtEvents,
  planning,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const shoeSizes = ["inconnue", 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45];
  const update = async () => {
    try {
      let updates = { ...form.getFieldsValue(), oldUuid: usrData.uuid };
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          localStorage.getItem("currentPlanningId") +
          "/broadcastlist",
        updates,
        getRdvApiAuth()
      );

      await fetchPlanningAndFmtEvents();
      message.success(
        `Utilisateur correctement mis à jour pour ce planning 👍`
      );
    } catch (e) {
      if (e.response && e.response.data.error) {
        message.error(`${e.response.data.message}`);
      } else {
        message.error(
          `Vous essayez de rendre indisponible un rendez-vous qui à été pris, veuillez le faire en supprimant ce dernier par le calendrier.`
        );
      }
    }

    setShow(false);
    setUsrData(undefined);
  };
  return (
    <div>
      <Modal
        title={`Mise à jour des informations du lot ${usrData.guest.lotcomm}`}
        style={{ top: 40 }}
        open={show}
        onOk={update}
        onCancel={() => {
          setShow(false);
          setUsrData(undefined);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            nom: usrData.guest.nom,
            mailmonsieur: usrData.guest.mailmonsieur,
            telephonemonsieur: usrData.guest.telephonemonsieur,
            mailmadame: usrData.guest.mailmadame,
            telephonemadame: usrData.guest.telephonemadame,
            isActive: usrData.isActive,
            isInterestedVr: usrData.isInterestedVr,
            isAvailable: usrData.isAvailable,
            observations: usrData.observations,
            visitors: usrData.visitors,
            shoeSize: usrData.shoeSize,
            shoeSize2: usrData.shoeSize2,
            vrUuid: usrData.vrUuid,
            uuid: usrData.uuid,
            notifMail: usrData.notifMail,
            notifSMS: usrData.notifSMS,
            isConfirmed: usrData.isConfirmed,
          }}
        >
          <Form.Item
            name="nom"
            label="Nom complet"
            tooltip="Avec le nom des co-acquéreurs le cas échéant"
            rules={[{ required: true, message: "Veuillez indiquer un nom" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mailmonsieur"
            label="E-mail Monsieur"
            rules={[
              {
                type: "email",
                message: "Email invalide",
              },
              {
                message: "Veuillez rentrer une adresse mail",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="telephonemonsieur"
            label="Téléphone Monsieur"
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
                ),
                message:
                  "Veuillez renseigner au moins un numéro de téléphone (format : 0689... ou +337...)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mailmadame"
            label="E-mail Madame"
            rules={[
              {
                type: "email",
                message: "Email invalide",
              },
              {
                message: "Veuillez rentrer une adresse mail",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="telephonemadame"
            label="Téléphone Madame"
            rules={[
              {
                pattern: new RegExp(
                  /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
                ),
                message:
                  "Veuillez renseigner au moins un numéro de téléphone (format : 0689... ou +337...)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="observations" label="Observations">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item name="vrUuid" label="Uuid visite virtuelle">
            <Input disabled />
          </Form.Item>

          <Form.Item name="uuid" label="Uuid page prise de rendez-vous">
            <Input />
          </Form.Item>

          {/* <Form.Item name="isActive" valuePropName="checked">
            <Checkbox>Actif</Checkbox>
          </Form.Item> */}

          {(!usrData.isBooked && planning.templateType === "promoter") ||
          (!usrData.isConfirmed &&
            planning.templateType === "promoterImpose") ? (
            <Form.Item name="isActive" valuePropName="checked">
              <Checkbox>Actif</Checkbox>
            </Form.Item>
          ) : (
            <Tooltip
              placement="topLeft"
              title="Si le rendez-vous a deja été pris, il faut d'abord l'annuler : Calendrier -> '-'"
            >
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox disabled>Actif</Checkbox>
              </Form.Item>
            </Tooltip>
          )}

          <Form.Item name="isConfirmed" valuePropName="checked">
            <Checkbox>Confirmé </Checkbox>
          </Form.Item>

          <Form.Item name="isInterestedVr" valuePropName="checked">
            <Checkbox>Visite Virtuelle</Checkbox>
          </Form.Item>

          {planning.questions.includes("Demande taille de chaussures") && (
            <div>
              <Form.Item name="shoeSize" label="Pointure">
                <Select placeholder="Sélectionner">
                  {shoeSizes.map((shoeSize, idx) => {
                    return (
                      <Option key={idx + "-1-" + shoeSize} value={shoeSize}>
                        {shoeSize}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="shoeSize2" label="Pointure">
                <Select placeholder="Sélectionner">
                  {shoeSizes.map((shoeSize, idx) => {
                    return (
                      <Option key={idx + "-2-" + shoeSize} value={shoeSize}>
                        {shoeSize}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}

          {(planning.templateType === "promoter" &&
            usrData.start === null &&
            usrData.end === null) ||
          (planning.templateType === "promoterImpose" &&
            usrData.isConfirmed) ? (
            <Form.Item name="isAvailable" valuePropName="checked">
              <Checkbox>Disponible</Checkbox>
            </Form.Item>
          ) : (
            <Tooltip
              placement="topLeft"
              title="Si le rendez-vous a deja ete pris, il faut d'abord l'annuler : Calendrier -> '-'"
            >
              <Form.Item name="isAvailable" valuePropName="checked">
                <Checkbox disabled>Disponible</Checkbox>
              </Form.Item>
            </Tooltip>
          )}

          <Form.Item name="notifMail" valuePropName="checked">
            <Checkbox>Notification Automatique Mail</Checkbox>
          </Form.Item>

          <Form.Item name="notifSMS" valuePropName="checked">
            <Checkbox>Notification Automatique SMS</Checkbox>
          </Form.Item>

          {planning.questions.includes("Accompagnants") && (
            <Form.List
              name="visitors"
              initialValues={{
                visitors: usrData.visitors,
              }}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, idxx) => (
                    <div key={idxx}>
                      <Divider>Accompagnant {parseInt(idxx) + 1}</Divider>
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                        direction="vertical"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "role"]}
                          fieldKey={[fieldKey, "role"]}
                        >
                          <Select placeholder="Nature">
                            <Option key="cuisiniste" value="cuisiniste">
                              Cuisiniste
                            </Option>
                            <Option key="architecte" value="architecte">
                              Architecte
                            </Option>
                            <Option key="familleami" value="familleami">
                              Ami/Famille
                            </Option>
                            <Option key="autre" value="autre">
                              Autre
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "nom"]}
                          fieldKey={[fieldKey, "nom"]}
                          rules={[
                            {
                              required: true,
                              message: "Nom manquant",
                            },
                          ]}
                        >
                          <Input placeholder="Nom" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "prenom"]}
                          fieldKey={[fieldKey, "prenom"]}
                          rules={[
                            {
                              required: true,
                              message: "Prénom manquant",
                            },
                          ]}
                        >
                          <Input placeholder="Prénom" />
                        </Form.Item>

                        {planning.questions.includes(
                          "Demande taille de chaussures"
                        ) && (
                          <Form.Item
                            {...restField}
                            name={[name, "shoeSize"]}
                            fieldKey={[fieldKey, "shoeSize"]}
                          >
                            <Select placeholder="Pointure">
                              {shoeSizes.map((shoeSize, idx) => {
                                return (
                                  <Option
                                    key={idx + "-" + shoeSize}
                                    value={shoeSize}
                                  >
                                    {shoeSize}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )}

                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          fieldKey={[fieldKey, "email"]}
                          extra="Dans le cas d'un cuisiniste, architecte merci d'indiquer le
                                              mail"
                        >
                          <Input placeholder="Email (optionnel)" />
                        </Form.Item>
                        <Button
                          danger
                          icon={<StopOutlined />}
                          onClick={() => {
                            remove(name);
                          }}
                        >
                          Supprimer accompagnant
                        </Button>
                        <br />
                      </Space>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Ajouter un accompagnant
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default PlanningBookingTableEditModal;
