import React from "react";

import {
  // DesktopOutlined,
  // HomeOutlined,
  ApartmentOutlined,
  TableOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  ThunderboltOutlined,
  CalendarOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

export const LeftMenuData = [
  // {
  //   name: "home",
  //   label: "Accueil",
  //   icon: <HomeOutlined />,
  //   path: "/accueil-promoteur",
  //   key: "sub1",
  // },
  {
    name: "program",
    label: "Programmes",
    icon: <ApartmentOutlined />,
    path: "/projets",
    key: "sub2",
  },
  {
    name: "plannings",
    label: "Plannings",
    icon: <CalendarOutlined />,
    path: "/promoter/plannings",
    key: "sub3",
  },
  {
    name: "currentProgram",
    label: undefined,
    icon: <ThunderboltOutlined />,
    items: [
      // {
      //   name: "program-index",
      //   label: "Aperçu",
      //   icon: <DesktopOutlined />,
      //   path: "#top",
      //   key: "1",
      // },
      {
        name: "program-clients",
        label: "Clients",
        icon: <TableOutlined />,
        path: "/clients",
        key: "2",
      },
      // {
      //   name: "program-messages",
      //   label: "Messages",
      //   icon: <TableOutlined />,
      //   path: "/messages",
      //   key: "3",
      // },
      {
        name: "program-edit",
        label: "Plannings",
        icon: <CalendarOutlined />,
        path: "/plannings",
        key: "4",
      },
      // {
      //   name: "program-edit-list",
      //   label: "QuickLook",
      //   icon: <CalendarOutlined />,
      //   path: "/plannings-list",
      //   key: "4",
      // },
      {
        name: "program-visits",
        label: "Visites 3D",
        icon: <VideoCameraOutlined />,
        path: "/visites-3d",
        key: "5",
      },
      {
        name: "program-settings",
        label: "Paramètres",
        icon: <SettingOutlined />,
        path: "/parametres",
        key: "6",
      },
    ],
    key: "sub4",
  },
  {
    name: "templates",
    label: "Modèles",
    icon: <FileTextOutlined />,
    path: "/promoter/templates",
    key: "sub5",
  },
  // {
  //   name: "admin-dashboard",
  //   label: "Administrateur",
  //   icon: <KeyOutlined />,
  //   items: [
  //     {
  //       name: "users",
  //       label: "Comptes",
  //       icon: <IdcardOutlined />,
  //       path: "/admin/users",
  //       key: "5",
  //     },
  //   ],
  //   key: "sub4",
  // },
  // {
  //   name: "visites-dashboard",
  //   label: "Visites",
  //   icon: <VideoCameraOutlined />,
  //   path: "/visites",
  //   key: "sub5",
  // },
];
