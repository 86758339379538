import React, { useState, useEffect, useContext, useReducer } from "react";
import ContentContainer from "../../../components/ContentContainer";
import PlanningBookingTable from "../../../components/plannings/PlanningBookingTable";
import PlanningOptions from "../../../components/plannings/PlanningOptions";
import SimulationChart from "../../../components/plannings/SimulationChart";
import CustomMailModal from "../../../components/plannings/modals/CustomMailModal";
import ModifyAddressModal from "../../../components/plannings/modals/ModifyAddressModal";
import AddCustomerModal from "../../../components/plannings/modals/AddCustomerModal";
import ApplyTemplateModal from "../../../components/plannings/modals/ApplyTemplateModal";
import RemoveCustomerModal from "../../../components/plannings/modals/RemoveCustomerModal";
import ModifyContactModal from "../../../components/plannings/modals/ModifyContactModal";
import ShowAlertedModal from "../../../components/plannings/modals/ShowAlertedModal";
import ShowShareModal from "../../../components/plannings/modals/ShowShareModal";
import ShowShareSMSModal from "../../../components/plannings/modals/ShowShareSMSModal";
import LimitAnswerModal from "../../../components/plannings/modals/LimitAnswerModal";
import ChangeNameModal from "../../../components/plannings/modals/ChangeNameModal";
import selectClientTotalTime from "../../../selectors/clients-total-time";
import AgendaReadOnly from "../../../components/AgendaReadOnly";
import AmosAgendas from "../../../components/AmosAgendas";
import {
  bankHolidays,
  phaseTransform,
} from "../../../components/plannings/planningData";
import { UserContext } from "../../../context/UserContext";
import amoAgendasReducer, { initReducer } from "./../../../reducers/amoagendas";
import {
  getAmoAgendas,
  getOnlyBookedMails,
  getBasicAuthConfig,
  getInitials,
  getTypologies,
  getEventsWithLunchBreakNoAvailables,
  mapList,
  customTags,
  getFormatedRdvTitle,
  getRdvApiAuth,
  getMissingBalises,
  areBalisesOk,
} from "../../../utils";

import {
  Descriptions,
  Divider,
  Typography,
  Button,
  Row,
  Col,
  Progress,
  Dropdown,
  Spin,
  Form,
  Tooltip,
  Space,
  Drawer,
  Avatar,
  Tag,
  Table,
  Badge,
  Result,
  Input,
  App,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  PlusSquareTwoTone,
  MinusSquareTwoTone,
  ExclamationCircleOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import RappelsRelancesModal from "../../../components/plannings/modals/RappelsRelancesModal";
import Tips from "../../../components/plannings/modals/Tips";
import CustomPageHeader from "../../../components/CustomPageHeader";
import SubContentContainer from "../../../components/SubContentContainer";

const { Text, Title } = Typography;

let startDay = "06:00";
let endDay = "20:00";
let startShift = "08:30";
let endShift = "18:30";
let startLunch = "12:30";
let endLunch = "13:30";

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const Planning = () => {
  const { role } = useContext(UserContext);
  const { message, notification, modal } = App.useApp();
  const [planning, setPlanning] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [amoAgendas, setAmoAgendas] = useState([]);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [amosAgendasVisible, setAmosAgendasVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [wait, setWait] = useState(false);
  const [showCustomMail, setShowCustomMail] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showRemoveCustomer, setShowRemoveCustomer] = useState(false);
  const [showModifyContact, setShowModifyContact] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showShareSMS, setShowShareSMS] = useState(false);
  const [showAlerted, setShowAlerted] = useState(false);
  const [showRappelsRelances, setShowRappelsRelances] = useState(false);
  const [limitAnswerModal, setLimitAnswerModal] = useState(false);
  const [changeNameModal, setChangeNameModal] = useState(false);
  const [modifyAddressModal, setModifyAddressModal] = useState(false);
  const [form] = Form.useForm();

  const [value, setValue] = useState(2);
  const [eventSourcetoSend, setEventSourcetoSend] = useState([]);
  const [fmtEvents, setFmtEvents] = useState(undefined);
  const [notBookedList, setNotBookedList] = useState(undefined);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [amos, setAmos] = useState({});
  const [address, setAddress] = useState({});
  const [typos, setTypos] = useState(undefined);
  const [dataSourceMiniTable, setDataSourceMiniTable] = useState(undefined);
  const [columnsMiniTable, setColumnsMiniTable] = useState(undefined);
  const [config, setConfig] = useState("lotTypo");
  const [isShowingUnavailables, setIsShowingUnavailables] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [eventsTotalTime, setEventsTotalTime] = useState(0);
  const [clientsTotalTime, setClientsTotalTime] = useState(undefined);
  const [evts, dispatch] = useReducer(amoAgendasReducer, {}, initReducer);
  const [isNotifMail, setIsNotifMail] = useState(false);
  const [isNotifSMS, setIsNotifSMS] = useState(false);
  const [isPlanningTypeOk, setIsPlanningTypeOk] = useState(false);
  const [showApplyTemplate, setShowApplyTemplate] = useState(false);
  const [usedBalises, setUsedBalises] = useState("");
  const [showFreeMeetings, setShowFreeMeetings] = useState(true);
  const [messages, setMessages] = useState({});
  const [promoterId, setPromoterId] = useState(undefined);
  const [changeNameModalVisible, setChangeNameModalVisible] = useState(false);
  const [firstPart, setFirstPart] = useState(undefined);

  const expandAll = () => {
    setExpandedRowKeys(planning.broadcastList.map((_) => _.uuid));
    setIsAllExpanded(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const getEventTotalTime = (grid, pas) => {
    let counter = 0;
    for (let shift = 0; shift < grid.length; shift++) {
      for (let amo = 0; amo < grid[shift].length; amo++) {
        if (grid[shift][amo] != null && grid[shift][amo] !== []) {
          for (let slot = 0; slot < grid[shift][amo].length; slot++) {
            if (
              grid[shift][amo][slot].booked === false ||
              (grid[shift][amo][slot].booked.includes("@") &&
                !grid[shift][amo][slot].booked.startsWith("@"))
            ) {
              counter += 1;
            }
          }
        }
      }
    }
    if (pas === 900) {
      return counter / 4;
    } else {
      return counter / 2;
    }
  };

  const openUnavailableCustomers = () => {
    if (!isShowingUnavailables) {
      setIsShowingUnavailables(true);
      setFilteredInfo({
        lotcomm: null,
        batiment: null,
        etage: null,
        nom: null,
        typologie: null,
        isInterestedVr: null,
        booked: ["indisponible"],
        dateBooked2: null,
        rdvLength: null,
      });
      setExpandedRowKeys(
        planning.broadcastList.filter((_) => !_.isAvailable).map((_) => _.uuid)
      );
    } else {
      setIsShowingUnavailables(false);
      setFilteredInfo({
        lotcomm: null,
        batiment: null,
        etage: null,
        nom: null,
        typologie: null,
        isInterestedVr: null,
        booked: null,
        dateBooked2: null,
        rdvLength: null,
      });
      setExpandedRowKeys([]);
    }
  };

  const collapseAll = () => {
    setExpandedRowKeys([]);
    setIsAllExpanded(false);
  };

  const unSelectedAllRows = () => {
    setSelectedRowKeys([]);
    setSelected([]);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const fetchPlanningAndFmtEvents = async (name = false) => {
    const res = await axios.get(
      process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id
    );

    setPlanning(res.data.planning);
    dispatch({
      type: "RESET",
      ial: mapList(
        JSON.parse(res.data.planning.grid),
        res.data.planning.pas,
        startDay,
        endDay,
        startLunch,
        endLunch,
        startShift,
        endShift
      ),
      planning: res.data.planning,
    });
    let f = customFormatEvents(res.data.planning, name, showFreeMeetings);

    setFmtEvents(f[0]);
    setEventSourcetoSend(f[1]);
    setEventsTotalTime(
      getEventTotalTime(
        JSON.parse(res.data.planning.grid),
        res.data.planning.pas
      )
    );
    setClientsTotalTime(
      selectClientTotalTime(
        res.data.planning.broadcastList.map((x) => ({
          duree: x.length,
        })),
        null
      )
    );
  };

  const openReadOnlyView = () => {
    window.open(`/pview/${planning.uuid}`, "_blank");
  };

  const customFormatEvents = (
    planning,
    flag = "lotTypo",
    showFreeMeetings = false
  ) => {
    let eventSourcestoSend = [];
    let colors = [
      "#436386", // bleu
      "#b7695d", // rouille
      "#96c0b7",
      "#d4dfc7",
    ];
    let forcedRdv = [];

    if (value !== 3) {
      forcedRdv = planning.broadcastList
        .filter((x) => x.forced !== null)
        .map((x) => {
          return {
            start: new Date(x.end).getTime(),
            end: new Date(x.end).getTime() + x.length,
            booked: x.guest.email,
            length: x.length / (planning.pas * 1000),
            forced: x.forced,
          };
        });
    }

    let fmtEvents = [];
    let amoAgendas3 = getAmoAgendas([JSON.parse(planning.grid)], planning.pas);

    // console.log(`forcedRdv = ${JSON.stringify(forcedRdv)}`);
    if (forcedRdv.length > 0) {
      amoAgendas3 = amoAgendas3.concat([forcedRdv]);
    }

    amoAgendas3.map((solvedEvent, idx, arr) => {
      const getUsrFromMail = (mail) => {
        if (mail && mail !== undefined && mail !== "@indisponible") {
          return planning.broadcastList.find(
            (x) => x.guest.email === mail || x.guest.mailmonsieur === mail
          );
        } else {
          return undefined;
        }
      };

      const getStrimPhone = (nb) => {
        let fmtd = nb.replace(/\s/g, "");
        if (!fmtd.startsWith("0")) {
          fmtd = "0" + fmtd;
        }
        return fmtd;
      };
      // Tous - Reserves - Forcés - Non reserves
      let tmp = undefined;
      if (value === 2) {
        tmp = solvedEvent.filter(
          (x) => x.booked !== false && x.booked !== "@indisponible"
        );
      } else if (value === 3) {
        tmp = solvedEvent.filter((x) => x.booked === false);
      } else if (value === 4) {
        tmp = solvedEvent.filter((x) => x.forced === 1);
      } else {
        tmp = solvedEvent;
      }

      let events = tmp.map((evt, idx2) => {
        let usr = getUsrFromMail(evt.booked);
        let title = "";
        let title2 = "";
        let description = "";
        let amo = idx;

        if (usr !== undefined && usr.guest !== undefined) {
          title = getFormatedRdvTitle(
            usr.guest.nom,
            usr.guest.lotcomm,
            usr.guest.typologie,
            usr.guest.telephonemonsieur,
            usr.guest.telephonemadame,
            config
          );
          title2 = title;
          if (config === "nameTypo") {
            description += `Lot : ${usr.guest.lotcomm}`;
          } else if (config === "lotTypo") {
            description += `Nom : ${usr.guest.nom}`;
          } else if (config === "nameLotTypo") {
            description += `Typo : ${usr.guest.typologie}`;
          }

          if (
            (usr.guest.telephonemonsieur || usr.guest.telephonemadame) &&
            config !== "lotNameTypoTel"
          ) {
            description += `\n\nTél :`;
          }

          if (usr.guest.telephonemonsieur) {
            description += "\n" + getStrimPhone(usr.guest.telephonemonsieur);
          }
          if (usr.guest.telephonemadame) {
            description += "\n" + getStrimPhone(usr.guest.telephonemadame);
          }

          if (
            (usr.shoeSize && usr.shoeSize !== "inconnue") ||
            (usr.shoeSize2 && usr.shoeSize2 !== "inconnue")
          ) {
            description += `\n\nPointure 1 : ${usr.shoeSize} | Pointure 2 : ${usr.shoeSize2}`;
          }

          if (usr.visitors.length > 0) {
            if (usr.visitors.length === 1) {
              description += "\n\nVisiteur :\n";
            } else if (usr.visitors.length > 1) {
              description += "\n\nVisiteurs :\n";
            }
            usr.visitors.forEach((visitor) => {
              if (visitor !== null) {
                description += `- ${visitor.nom} ${visitor.prenom}`;
                if (visitor.shoeSize) {
                  description += ` (Pointure : ${visitor.shoeSize})`;
                }
                if (visitor.role) {
                  description += ` (${visitor.role})`;
                }
                if (visitor.email) {
                  description += ` (${visitor.email})`;
                }
                description += "\n";
              }
            });
          }

          if (usr.observations !== "") {
            description += `\n\nObservations :\n${usr.observations}`;
          }

          fmtEvents.push({
            start: evt.start,
            end: evt.end,
            title: title2,
            description,
            amo,
            location:
              planning.address.numero +
              " " +
              planning.address.rue +
              " " +
              planning.address.codepostal +
              " " +
              planning.address.ville,
          });
        } else {
          if (Object.keys(amos).length > 0 && planning.areAmosAssigned) {
            title += `${amos[idx].initials}`;
          }
        }
        let e = {
          id:
            usr === undefined || usr.guest === undefined
              ? `foreground-evt-${idx2}-${amo}`
              : usr.guest.rdv,
          title: title,
          description,
          amo,
          start: evt.start,
          end: evt.end,
          booked: evt.booked,
          startTime: false,
          allDay: false,
        };
        // console.log(e);
        return e;
      });
      eventSourcestoSend.push({
        events: events,
        color: colors[idx],
        // display: "none",
        startStr: "we",
        // displayEventEnd: false,
        // displayEventTime: false,
      });
    });

    // Put background events (planning boundaries)
    let evts = getEventsWithLunchBreakNoAvailables(planning).map((e, idx) => {
      return {
        id: `background-evt${idx}`,
        start: e.start,
        end: e.end,
      };
    });

    // Adding bank holidays
    evts = evts.concat(fmtBankHolidays);
    // console.log(evts);
    eventSourcestoSend.push({
      events: evts,
      color: "#436386",
      display: "background",
    });

    // const amoAgendas = getAmoAgendas([JSON.parse(planning.grid)], planning.pas);

    if (amoAgendas3.length !== 0) {
      const onlyBooked = getOnlyBookedMails(amoAgendas3);
      const notBookedList = planning.broadcastList.filter((rdv) => {
        if (
          !onlyBooked.includes(rdv.guest.email) &&
          rdv.forced === null &&
          rdv.isActive
        ) {
          return rdv;
        }
      });
      setNotBookedList(notBookedList);
    }

    // Handle free meetings
    if (showFreeMeetings) {
      // console.log(`YES`);
      planning.freeMeetings.map((freeMeeting) => {
        // console.log(freeMeeting);
        let tmp = freeMeeting._id;
        // delete freeMeeting._id;
        let newFreeMeeting = {
          ...freeMeeting,
          booked: freeMeeting.name,
          title: `*${freeMeeting.name}*`,
          id: tmp,
        };
        // console.log(`newfreeMeeting : ${JSON.stringify(newFreeMeeting)}`);
        eventSourcestoSend.push({
          events: [newFreeMeeting],
          color: freeMeeting.colors,
          startStr: "fm",
        });
      });
    }

    return [fmtEvents, eventSourcestoSend];
  };

  useEffect(() => {
    const newFunc = async (planningId) => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planningId
        );

        const res2 = await axios.get(
          process.env.REACT_APP_VR_API_URL + "/promoters-open",
          getBasicAuthConfig()
        );
        let promoterInternalNames = [];

        const regex = /^.{0,40}$/;

        // Set in state promoterId et firstPart pour chgt de nom (utilise vr-api)
        res2.data.forEach((i) => {
          let lastPart = "";
          let firstPart = "";
          let counter = 0;
          let x = 0;
          for (x = 0; x < i.internalName.length; x++) {
            firstPart += i.internalName[x];
            if (i.internalName[x] === "-") {
              counter++;
              if (counter === 3) {
                break;
              }
            }
          }
          let j = 2;
          firstPart += i.internalName[x + 1];

          while (i.internalName[x + j]) {
            lastPart += i.internalName[x + j];
            j++;
          }
          promoterInternalNames.push({ [lastPart]: i._id });
          if (lastPart === res.data.planning.name) {
            setPromoterId(i._id);
            setFirstPart(firstPart);
          }
        });

        if (res.data.planning.templateType === "promoter") {
          setIsPlanningTypeOk(true);
        }

        setPlanning(res.data.planning);
        let bigStr =
          res.data.planning.mailrelancemessage +
          res.data.planning.mailrappelmessage +
          res.data.planning.smsrelancemessage +
          res.data.planning.smsrappelmessage +
          res.data.planning.mailinvitationmessage +
          res.data.planning.mailconfirmationmessage;
        let balises = bigStr.match(/<(.+?)>/g) || [];
        balises = balises.filter((_) => Object.keys(customTags).includes(_));
        balises = Array.from(new Set(balises));
        // Please not that usedBalises are only the one that matters for Contact, not all !
        setUsedBalises(balises);

        setAddress({
          numero: res.data.planning.address.numero,
          rue: res.data.planning.address.rue,
          codepostal: res.data.planning.address.codepostal,
          ville: res.data.planning.address.ville,
          pays: res.data.planning.address.pays,
        });

        dispatch({
          type: "RESET",
          ial: mapList(
            JSON.parse(res.data.planning.grid),
            res.data.planning.pas,
            startDay,
            endDay,
            startLunch,
            endLunch,
            startShift,
            endShift
          ),
          planning: res.data.planning,
        });

        if (res.data.planning.amoList.length !== 0) {
          const res2 = await axios.get(
            process.env.REACT_APP_API_URL + `/users/amos`,
            // { ids: res.data.planning.amoList },
            getBasicAuthConfig()
          );
          setAmos(
            res2.data
              .filter((_) => res.data.planning.amoList.includes(_._id))
              .map((user) => ({
                id: user._id,
                initials: getInitials(user.lastname, user.firstname),
                firstname: user.firstname,
                lastname: user.lastname,
              }))
          );
        }

        const amoAgendas2 = getAmoAgendas(
          [JSON.parse(res.data.planning.grid)],
          res.data.planning.pas
        );
        setAmoAgendas(amoAgendas2);
        let f = customFormatEvents(
          res.data.planning,
          config,
          showFreeMeetings,
          bankHolidays
        );

        setFmtEvents(f[0]);
        setEventSourcetoSend(f[1]);
        setEventsTotalTime(
          getEventTotalTime(
            JSON.parse(res.data.planning.grid),
            res.data.planning.pas
          )
        );
        setClientsTotalTime(
          selectClientTotalTime(
            res.data.planning.broadcastList.map((x) => ({
              duree: x.length,
            })),
            null
          )
        );

        // Mis a la fin car pas le temps de charger -> pose probleme ?
        form.setFieldsValue({
          mailinvitationmessage: res.data.planning.mailinvitationmessage,
          mailconfirmationmessage: res.data.planning.mailconfirmationmessage,
          smsrelancemessage: res.data.planning.smsrelancemessage,
          smsrappelmessage: res.data.planning.smsrappelmessage,
          mailrelancemessage: res.data.planning.mailrelancemessage,
          mailrappelmessage: res.data.planning.mailrappelmessage,
          contactTitre: res.data.planning.contactTitre,
          contactNom: res.data.planning.contactNom,
          contactPrenom: res.data.planning.contactPrenom,
          contactTelephone: res.data.planning.contactTelephone,
          contactMail: res.data.planning.contactMail,
          signature: res.data.planning.signature,
        });

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };
    const fullPath = window.location.pathname.split("/");
    const planningId = fullPath[fullPath.length - 1];
    if (isLoading) {
      newFunc(planningId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let f = customFormatEvents(planning, config, showFreeMeetings);

      setFmtEvents(f[0]);
      setEventSourcetoSend(f[1]);
      setEventsTotalTime(
        getEventTotalTime(JSON.parse(planning.grid), planning.pas)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, config]);

  useEffect(() => {
    if (planning.broadcastList !== undefined) {
      let unsorted = getTypologies(planning.broadcastList.map((x) => x.guest));
      const sorted = Object.keys(unsorted)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unsorted[key];
          return obj;
        }, {});

      setTypos(sorted);

      let sourceMiniTable = {};
      let colsMiniTable = [];

      for (const [index, [key, value]] of Object.entries(
        Object.entries(sorted)
      )) {
        if (index == 0) {
          sourceMiniTable["rowKey"] = key;
        }
        sourceMiniTable[key] = value;
        colsMiniTable.push({
          title: key,
          dataIndex: key,
          key: key,
        });
      }
      setDataSourceMiniTable([sourceMiniTable]);
      setColumnsMiniTable(colsMiniTable);
    }
  }, [planning]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const enterLoading = async (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setWait(true);
    try {
      const res = await axios.post(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          planning._id +
          "/simulation",
        {} // simulConf empty
      );

      setLoadings(newLoadings);

      setTimeout(() => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        setLoadings(newLoadings);

        modal.success({
          title:
            "Simulation réussie ( x : No de la personne qui prend rdv - y : Nombre de créneaux horaires proposés)",
          width: 1000,
          content: (
            <div>
              <br />
              <SimulationChart data={res.data.simulation[1]} />
            </div>
          ),
          forceRender: true,
        });
        setWait(false);
      }, 500);
    } catch (e) {
      console.log(`Erreur : ${e}`);
      modal.error({
        title: "Pas de solution",
        content: <div>{e.message}</div>,
        forceRender: true,
      });
    }
  };

  const DropdownMenuEye = (key) => {
    const items = [
      {
        label: (
          <a onClick={openReadOnlyView} icon={<EyeOutlined />}>
            Aperçu
          </a>
        ),
        key: "1",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              setShowShare(true);
            }}
          >
            Envoyer par Mail
          </a>
        ),
        key: "2",
      }, // which is required
      {
        label: (
          <a
            onClick={() => {
              setShowShareSMS(true);
            }}
          >
            Envoyer par SMS
          </a>
        ),
        key: "3",
      },
    ];
    return (
      <Dropdown key={"eye-" + key} menu={{ items }}>
        <Button icon={<EyeOutlined />}></Button>
      </Dropdown>
    );
  };

  const onCreate = async (values, str, request) => {
    try {
      if (request) {
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
          { [str]: values },
          getRdvApiAuth()
        );
      }

      setMessages({
        ...messages,
        [str]: values,
      });

      let bigStr =
        messages.mailrelancemessage +
        messages.mailrappelmessage +
        messages.smsrelancemessage +
        messages.smsrappelmessage +
        messages.mailconfirmationmessage;
      bigStr += values;

      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));

      message.success(`Message modifié avec succès`);
      setUsedBalises(balises);
    } catch (e) {
      console.log(e);
    }
  };

  const generatedContent = (field, title, request) => {
    let before = form.getFieldValue(field);
    modal.confirm({
      icon: null,
      title,
      width: "600px",
      content: (
        <div style={{ width: "550px" }}>
          <Form
            form={form}
            name="form_in_modal2"
            // initialValues={{ mailRelance: messages.mailInvitation }}
          >
            <Text type="danger">*&nbsp;</Text>
            <Text>Message :</Text>
            <br />
            <br />
            <Form.Item
              name={field}
              rules={[
                {
                  validator: async (_, payload) => {
                    let missings = getMissingBalises(planning, payload);
                    if (!areBalisesOk(payload)) {
                      return Promise.reject(
                        new Error(
                          `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                        )
                      );
                    } else if (request && missings.length > 0) {
                      return Promise.reject(
                        new Error(
                          `Balise(s) ${missings} non disponible(s) sur ce planning.`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input.TextArea rows={18} />
            </Form.Item>
            <Tips />
          </Form>
        </div>
      ),
      async onOk() {
        try {
          const values = await form.validateFields([field]);
          await onCreate(values[field], field, request);
        } catch (e) {
          message.error("Veuillez vérifier les données entrées");
          form.setFieldValue(field, before);
        }
      },
      onCancel() {
        form.setFieldValue(field, before);
      },
    });
  };

  const People = ({ amoAgendas, amos }) => {
    let p = amos.map((amo, idx) => {
      return (
        <Tooltip
          key={`avt-${idx}`}
          title={`${amo.lastname} ${amo.firstname}`}
          placement="top"
        >
          <Avatar
            src={
              process.env.REACT_APP_API_URL +
              "/users/" +
              amos[idx].id +
              "/avatar"
            }
            style={{
              backgroundColor: "#f56a00",
            }}
          >
            {amos[idx].initials}
          </Avatar>
        </Tooltip>
      );
    });

    let stop = amoAgendas.length - amos.length;

    p = p.concat(
      amoAgendas.map((e, i) => {
        if (i < stop)
          return (
            <Tooltip key={`avt2-${i}`} placement="top">
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                }}
              >
                {"-"}
              </Avatar>
            </Tooltip>
          );
      })
    );

    return p;
  };

  const DropdownMenuPersonalize = (key) => {
    const items = [
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailinvitationmessage",
                "Personnalisation du mail d'invitation",
                true
              );
            }}
          >
            Mail Invitation
          </a>
        ),
        key: "11",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailconfirmationmessage",
                "Personnalisation du mail de confirmation",
                true
              );
            }}
          >
            Mail Confirmation
          </a>
        ),
        key: "22",
      }, // which is required
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailrappelmessage",
                "Personnalisation du mail de rappel",
                true
              );
            }}
          >
            Mail Rappel
          </a>
        ),
        key: "33",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "mailrelancemessage",
                "Personnalisation du mail de relance",
                true
              );
            }}
          >
            Mail Relance
          </a>
        ),
        key: "44",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "smsrappelmessage",
                "Personnalisation du SMS de rappel",
                true
              );
            }}
          >
            SMS Rappel
          </a>
        ),
        key: "55",
      },
      {
        label: (
          <a
            onClick={() => {
              generatedContent(
                "smsrelancemessage",
                "Personnalisation du SMS de relance",
                true
              );
            }}
          >
            SMS Relance
          </a>
        ),
        key: "66",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowApplyTemplate(true);
            }}
          >
            Appliquer template
          </a>
        ),
        icon: <SelectOutlined />,
        key: "77",
      },
    ];
    return (
      <Dropdown menu={{ items }} key={"personalize-" + key}>
        <Button icon={<DownOutlined />}>Personnaliser</Button>
      </Dropdown>
    );
  };

  const DropdownMenuOptions = (key) => {
    const items = [
      {
        label: (
          <a
            onClick={() => {
              setShowAlerted(true);
            }}
          >
            Notifications
          </a>
        ),
        key: "1",
      }, // remember to pass the key prop
      {
        label: (
          <a
            onClick={() => {
              setShowAddCustomer(true);
            }}
          >
            Ajout Acquéreur
          </a>
        ),
        key: "2",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowRemoveCustomer(true);
            }}
          >
            Suppression Acquéreur
          </a>
        ),
        key: "3",
      },
      {
        label: (
          <a
            onClick={() => {
              setShowModifyContact(true);
            }}
          >
            Modifier le Contact
          </a>
        ),
        key: "4",
      },
    ];
    return (
      <Dropdown menu={{ items }} key={"option-" + key}>
        <Button icon={<DownOutlined />}>Options</Button>
      </Dropdown>
    );
  };

  if (wait || (!isPlanningTypeOk && wait)) {
    return (
      <ContentContainer>
        <div className="loader-spin">
          <Spin tip="Veuillez patienter SVP ..." />
        </div>
      </ContentContainer>
    );
  } else if (!isPlanningTypeOk && !isLoading) {
    return (
      <ContentContainer>
        <Result status="404" title="404" subTitle="Planning libre non trouvé" />
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          {!isLoading && (
            <div>
              {role === "administrator" && (
                <CustomPageHeader
                  title={
                    <div>
                      {planning.name}&nbsp;
                      <Tooltip title="editer">
                        <EditOutlined
                          onClick={() => setChangeNameModalVisible(true)}
                        />
                      </Tooltip>
                    </div>
                  }
                  subTitle={
                    <Tooltip placement="top" title={`${planning.uuid}`}>
                      {`Visite ${phaseTransform[planning.phase]}`}
                    </Tooltip>
                  }
                  arrow={true}
                  extra={[
                    <DropdownMenuEye key="1" />,
                    <Button key="2" onClick={() => setAmosAgendasVisible(true)}>
                      Disponibilités
                    </Button>,
                    <Button key="3" onClick={showDrawer}>
                      Calendrier
                    </Button>,
                    <Button
                      key="4"
                      loading={loadings[0]}
                      onClick={() => enterLoading(0)}
                    >
                      Simulation
                    </Button>,
                    <DropdownMenuPersonalize key="5" />,
                    <DropdownMenuOptions key="6" />,
                  ]}
                ></CustomPageHeader>
              )}

              {role !== "administrator" && (
                <CustomPageHeader
                  title={
                    <div>
                      {planning.name}&nbsp;
                      <Tooltip title="editer">
                        <EditOutlined
                          onClick={() => setChangeNameModalVisible(true)}
                        />
                      </Tooltip>
                    </div>
                  }
                  arrow={true}
                  subTitle={
                    <Tooltip placement="top" title={`${planning.uuid}`}>
                      {planning.phase === "custom" ? (
                        <div>{`Planning custom`}</div>
                      ) : (
                        <div>{`Visite ${phaseTransform[planning.phase]}`}</div>
                      )}
                    </Tooltip>
                  }
                  extra={[
                    <DropdownMenuEye key="1" />,
                    <Button key="2" onClick={() => setAmosAgendasVisible(true)}>
                      Disponibilités
                    </Button>,
                    <Button key="3" onClick={showDrawer}>
                      Calendrier
                    </Button>,
                    <DropdownMenuPersonalize key="5" />,
                    <DropdownMenuOptions key="6" />,
                  ]}
                ></CustomPageHeader>
              )}

              {!isLoading && (
                <ChangeNameModal
                  show={changeNameModalVisible}
                  setShow={setChangeNameModalVisible}
                  planning={planning}
                  setPlanning={setPlanning}
                  promoterId={promoterId}
                  firstPart={firstPart}
                />
              )}
              {!isLoading && (
                <CustomMailModal
                  show={showCustomMail}
                  setShow={setShowCustomMail}
                  selected={selected}
                  planning={planning}
                  unSelectedAllRows={unSelectedAllRows}
                  fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                />
              )}
              {showApplyTemplate && (
                <ApplyTemplateModal
                  planning={planning}
                  show={showApplyTemplate}
                  setShow={setShowApplyTemplate}
                  form={form}
                  setUsedBalises={setUsedBalises}
                />
              )}
              <ModifyAddressModal
                show={modifyAddressModal}
                setShow={setModifyAddressModal}
                address={address}
                setAddress={setAddress}
                form={form}
              />
              <ModifyContactModal
                show={showModifyContact}
                setShow={setShowModifyContact}
                planning={planning}
                setPlanning={setPlanning}
                form={form}
                usedBalises={usedBalises}
                setUsedBalises={setUsedBalises}
              />
              <LimitAnswerModal
                show={limitAnswerModal}
                setShow={setLimitAnswerModal}
                planning={planning}
                unSelectedAllRows={unSelectedAllRows}
                setPlanning={setPlanning}
              />
              {planning.notifiedList !== undefined && (
                <ShowAlertedModal
                  show={showAlerted}
                  setShow={setShowAlerted}
                  planning={planning}
                  setPlanning={setPlanning}
                />
              )}
              <ShowShareModal
                show={showShare}
                setShow={setShowShare}
                planning={planning}
                setPlanning={setPlanning}
              />
              <ShowShareSMSModal
                show={showShareSMS}
                setShow={setShowShareSMS}
                planning={planning}
                setPlanning={setPlanning}
              />
              <AddCustomerModal
                show={showAddCustomer}
                setShow={setShowAddCustomer}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                planning={planning}
                isLoading={isCustomerLoading}
                setIsLoading={setIsCustomerLoading}
              />
              <RemoveCustomerModal
                show={showRemoveCustomer}
                setShow={setShowRemoveCustomer}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                planning={planning}
                isLoading={isCustomerLoading}
                setIsLoading={setIsCustomerLoading}
              />

              <Row align="middle">
                <Col span={3}>
                  <Space direction="vertical">
                    <Row>
                      <Progress
                        type="circle"
                        percent={Math.round(
                          ((planning.nbBooked + planning.nbNotAvailable) /
                            planning.nbInvited) *
                            100
                        )}
                        strokeColor="#436386"
                      />
                    </Row>
                    <Row align="center">
                      <Tooltip
                        placement="bottom"
                        color="#436386"
                        title={
                          <Table
                            dataSource={dataSourceMiniTable}
                            columns={columnsMiniTable}
                            rowKey="rowKey"
                            size="small"
                            bordered={true}
                            pagination={false}
                          />
                        }
                      >
                        <Button type="dashed" size="small">
                          Typologies
                        </Button>
                      </Tooltip>
                    </Row>
                  </Space>
                </Col>
                <Col span={21}>
                  <Descriptions
                    bordered
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item
                      label={
                        new Date(planning.launchDate).getTime() >
                        new Date().getTime() ? (
                          <Tooltip
                            placement="top"
                            title={`Envoi programmé des invitations le ${moment(
                              planning.launchDate
                            ).format("DD/MM")} à ${moment(
                              planning.launchDate
                            ).format("HH:mm")}`}
                          >
                            <Badge
                              status="processing"
                              color="orange"
                              text="Invités"
                            />
                          </Tooltip>
                        ) : (
                          "Invités"
                        )
                      }
                    >
                      {planning.nbNotAvailable > 1 ? (
                        <div
                          style={{
                            verticalAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          {planning.nbInvited}
                          <Tooltip
                            placement="bottom"
                            title={planning.broadcastList
                              .filter((y) => !y.isAvailable)
                              .map((_, idx) => (
                                <div key={idx}>
                                  {_.guest.nom}
                                  <br />
                                </div>
                              ))}
                          >
                            <Button
                              type="link"
                              onClick={openUnavailableCustomers}
                            >
                              ({planning.nbNotAvailable} indispos)
                            </Button>
                          </Tooltip>
                        </div>
                      ) : planning.nbNotAvailable === 1 ? (
                        <div>
                          <div>
                            {planning.nbInvited}
                            <Tooltip
                              placement="bottom"
                              title={planning.broadcastList
                                .filter((y) => !y.isAvailable)
                                .map((_, idx) => (
                                  <div key={idx}>
                                    {_.guest.nom}
                                    <br />
                                  </div>
                                ))}
                            >
                              <Button
                                type="link"
                                onClick={openUnavailableCustomers}
                              >
                                ({planning.nbNotAvailable} indispo)
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div>{planning.nbInvited}</div>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label="Période">
                      {moment(planning.planningStartTime).format("ddd DD/MM")} -{" "}
                      {moment(planning.planningEndTime).format("ddd DD/MM")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Réponses">
                      {planning.nbBooked + planning.nbNotAvailable}

                      {" / "}
                      {planning.nbInvited}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date limite réponses">
                      <Space direction="horizontal">
                        {moment(planning.bookingDeadline).format(
                          "ddd DD MMM YYYY"
                        )}
                        <>
                          <Tooltip title="editer">
                            <EditOutlined
                              onClick={() => setLimitAnswerModal(true)}
                            />
                          </Tooltip>
                        </>
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Équipe(s)">
                      {Object.keys(amos).length > 0 ? (
                        <div>
                          <People amos={amos} amoAgendas={amoAgendas} />

                          {planning.areAmosAssigned && (
                            <div style={{ display: "inline-block" }}>
                              &nbsp;
                              <Tooltip
                                title="Cela signifie que les acquéreurs ont la possibilité de choisir leur AMO(s)"
                                placement="top"
                              >
                                <Tag
                                  icon={<ExclamationCircleOutlined />}
                                  color="warning"
                                >
                                  Assignés
                                </Tag>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      ) : (
                        amoAgendas.length
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Disponible / Nécessaire">
                      {eventsTotalTime}
                      {"H / "}
                      {selectClientTotalTime(
                        planning.broadcastList
                          .filter((_) => _.isActive)
                          .map((x) => ({
                            duree: x.length,
                          })),
                        null
                      )}
                      {"H "}
                    </Descriptions.Item>
                    {planning.questions.length > 0 && (
                      <Descriptions.Item label="Question(s)">
                        <Tooltip
                          placement="bottom"
                          title={planning.questions.map((_, idx) => (
                            <div key={`q-${idx}`}>
                              {_}
                              <br />
                            </div>
                          ))}
                        >
                          <Button type="link">
                            {planning.questions.length}
                          </Button>
                        </Tooltip>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Adresse">
                      <Space direction="horizontal">
                        {planning.address.rue !== "" ? (
                          <div>
                            <EnvironmentOutlined />{" "}
                            {`${address.numero} ${address.rue} ${address.codepostal} ${address.ville} ${address.pays}`}
                          </div>
                        ) : (
                          <div>
                            <EnvironmentOutlined /> ?
                          </div>
                        )}
                        <>
                          <Tooltip title="editer">
                            <EditOutlined
                              onClick={() => setModifyAddressModal(true)}
                            />
                          </Tooltip>
                        </>
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Divider />
              <Title level={5}>
                {!isAllExpanded ? (
                  <Tooltip title="Afficher détails">
                    <PlusSquareTwoTone
                      twoToneColor="#436386"
                      onClick={(e) => expandAll()}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Masquer détails">
                    <MinusSquareTwoTone
                      twoToneColor="#436386"
                      onClick={(e) => collapseAll()}
                    />
                  </Tooltip>
                )}
                &nbsp; Participants
              </Title>
              {selected.length > 0 ? (
                <div>
                  <PlanningOptions
                    selected={selected}
                    unSelectedAllRows={unSelectedAllRows}
                    fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                    setShowCustomMail={setShowCustomMail}
                    setShowRappelsRelances={setShowRappelsRelances}
                  />
                  <RappelsRelancesModal
                    isNotifMail={isNotifMail}
                    isNotifSMS={isNotifSMS}
                    setIsNotifMail={setIsNotifMail}
                    setIsNotifSMS={setIsNotifSMS}
                    show={showRappelsRelances}
                    setShow={setShowRappelsRelances}
                    selected={selected}
                    planning={planning}
                  />
                  <br />
                </div>
              ) : (
                <div>
                  <i>Séléctionner pour afficher les options</i>
                </div>
              )}

              <br />

              <PlanningBookingTable
                planning={planning}
                rowSelection={rowSelection}
                fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                expandedRowKeys={expandedRowKeys}
                setExpandedRowKeys={setExpandedRowKeys}
                filteredInfo={filteredInfo}
                handleChange={handleChange}
              />
              {/* </div> */}
            </div>
          )}

          {!isLoading && (
            <div>
              <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                width="80%"
              >
                <AgendaReadOnly
                  eventSources={eventSourcetoSend}
                  setEventSources={setEventSourcetoSend}
                  value={value}
                  setValue={setValue}
                  events={fmtEvents}
                  setFmtEvents={setFmtEvents}
                  planning={planning}
                  setPlanning={setPlanning}
                  fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                  notBookedList={notBookedList}
                  amos={amos}
                  config={config}
                  setConfig={setConfig}
                  showFreeMeetings={showFreeMeetings}
                  setShowFreeMeetings={setShowFreeMeetings}
                  customFormatEvents={customFormatEvents}
                />
              </Drawer>
            </div>
          )}

          {!isLoading && (
            <div>
              <Drawer
                placement="right"
                closable={false}
                onClose={() => setAmosAgendasVisible(false)}
                open={amosAgendasVisible}
                width="80%"
              >
                <AmosAgendas
                  eventSources={eventSourcetoSend}
                  value={value}
                  setValue={setValue}
                  events={fmtEvents}
                  planning={planning}
                  setPlanning={setPlanning}
                  fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
                  notBookedList={notBookedList}
                  amos={amos}
                  setShowDrawer={setAmosAgendasVisible}
                  eventsTime={eventsTotalTime}
                  clientsTime={clientsTotalTime}
                  evts={evts}
                  dispatch={dispatch}
                />
              </Drawer>
            </div>
          )}

          {selected.length !== 0 && (
            <RappelsRelancesModal
              isNotifMail={isNotifMail}
              isNotifSMS={isNotifSMS}
              setIsNotifMail={setIsNotifMail}
              setIsNotifSMS={setIsNotifSMS}
              show={showRappelsRelances}
              setShow={setShowRappelsRelances}
              selected={selected}
              planning={planning}
              fetchPlanningAndFmtEvents={fetchPlanningAndFmtEvents}
            />
          )}
          <br />
          <br />
          <br />
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default Planning;
