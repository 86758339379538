import React, { useState } from "react";
import { Checkbox, Switch, Space, Select } from "antd";
const { Option } = Select;

const QuestionOptions = ({
  setOptionQuestions,
  visitorsNb,
  setVisitorsNb,
  optionQuestions,
  // originMaxVisitorsNb,
}) => {
  const [acc, setAcc] = useState(optionQuestions.includes("Accompagnants"));

  // const plainOptions = [
  //   "Sondage visite virtuelle",
  //   "Demande taille de chaussures",
  //   // "Accompagnants",
  // ];
  const onChange = (checkedValues) => {
    // console.log(checkedValues);
    // console.log("FIN");
    setOptionQuestions(checkedValues);
  };

  const onVisitorsNbChange = (value) => {
    setVisitorsNb(value);
    console.log(value);
  };

  const onAccChange = (checked) => {
    console.log(checked);
    if (!checked) {
      setVisitorsNb(1);
      let newOptionQuestions = optionQuestions.filter(
        (_) => _ !== "Accompagnants"
      );
      setOptionQuestions(newOptionQuestions);
    }
    setAcc(checked);
  };
  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Checkbox.Group
          // options={plainOptions}
          defaultValue={optionQuestions}
          onChange={(e) => {
            onChange(e);
          }}
        >
          <Checkbox value="Sondage visite virtuelle">
            Sondage visite virtuelle
          </Checkbox>

          <Checkbox value="Demande taille de chaussures">
            Demande taille de chaussures
          </Checkbox>

          <Checkbox value="Demande visite 3D si indisponible">
            Demande visite 3D si indisponible
          </Checkbox>
        </Checkbox.Group>
      </Space>
      <br />
      <Space direction="horizontal">
        {acc ? (
          <Switch onChange={onAccChange} defaultChecked />
        ) : (
          <Switch onChange={onAccChange} />
        )}
        {!acc
          ? "Formulaire accompagnants"
          : "Formulaire accompagnants, nombre maximum :"}
        {acc && (
          <Select
            defaultValue={visitorsNb}
            style={{ width: 120 }}
            onChange={onVisitorsNbChange}
          >
            {Array.from(Array(5).keys()).map((nb) => (
              <Option key={parseInt(nb) + 1}>{parseInt(nb) + 1}</Option>
            ))}
          </Select>
        )}{" "}
      </Space>
    </Space>
  );
};

export default QuestionOptions;
