import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Radio,
  Tag,
  Space,
  Checkbox,
} from "antd";
import { typoColors, presetTimes } from "./planningData";
import { getTypologies, showSmallLengths } from "../../utils";

const times = Object.entries(presetTimes);
export const Acquereurs = ({
  clientList,
  setClientList,
  selected,
  setSelected,
  typos,
  setTypos,
  selectedRowKeys,
  setSelectedRowKeys,
  filteredClientList,
  setFilteredClientList,
  showAll,
  setShowAll,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record._id === editingKey;

  useEffect(() => {
    setShowAll(showSmallLengths(clientList));
  }, [clientList]);

  const CustomInput = ({ dataIndex, type, title, duree }) => {
    if (type !== "select") {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Radio.Group buttonStyle="solid" size="small">
            {times.map((_, idx2) => {
              if (parseInt(_[0]) > 2700000 && !showAll) {
                return (
                  <Radio.Button key={idx2 + "-" + _} value={_[0]}>
                    {_[1]}
                  </Radio.Button>
                );
              } else if (showAll) {
                return (
                  <Radio.Button key={idx2 + "-" + _} value={_[0]}>
                    {_[1]}
                  </Radio.Button>
                );
              }
            })}
          </Radio.Group>
        </Form.Item>
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            type={inputType}
            duree={record.duree}
            dataIndex={dataIndex}
            title={title}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      lotcomm: "",
      batiment: "",
      nom: "",
      duree: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (e, id) => {
    e.preventDefault();
    // console.log(e);
    try {
      const row = await form.validateFields();
      // console.log(`row ---> ${JSON.stringify(row)}`);
      const newData = [...clientList];
      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setClientList(newData);
        if (selected.length !== 0) {
          setSelected(
            newData.filter((_) => rowSelection.selectedRowKeys.includes(_._id))
          );
        }
      } else {
        newData.push(row);
        setClientList(newData);
        if (selected.length !== 0) {
          setSelected(
            newData.filter((_) => rowSelection.selectedRowKeys.includes(_._id))
          );
        }
      }
      setEditingKey("");
      // Si selectionnée, changer la taille du block en dessous
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Lot",
      dataIndex: "lotcomm",
      width: "10%",
      sorter: (a, b) => a.lotcomm.localeCompare(b.lotcomm),
      defaultSortOrder: "ascend",
      editable: false,
    },
    {
      title: "Etage",
      dataIndex: "etage",
      filters: Array.from(new Set(clientList.map((x) => x.etage)))
        .sort(function (a, b) {
          return b - a;
        })
        .map((_) => ({
          text: _,
          value: _,
        })),
      onFilter: (value, record) => record.etage === value,
      sorter: (a, b) => a.etage.localeCompare(b.etage),
      sortDirections: ["descend", "ascend"],
      width: "10%",
      editable: false,
    },
    {
      title: "Bat.",
      dataIndex: "batiment",
      filters: Array.from(new Set(clientList.map((x) => x.batiment)))
        .sort(function (a, b) {
          return a.localeCompare(b);
        })
        .map((_) => ({
          text: _,
          value: _,
        })),
      onFilter: (value, record) => record.batiment === value,
      sorter: (a, b) => a.batiment.localeCompare(b.batiment),
      sortDirections: ["descend", "ascend"],
      width: "10%",
      editable: false,
    },
    {
      title: "Nom",
      dataIndex: "firstname",
      width: "25%",
      editable: false,
    },
    {
      title: "Typologie",
      dataIndex: "typologie",
      filters: Array.from(new Set(clientList.map((x) => x.typologie)))
        .sort(function (a, b) {
          return a.localeCompare(b);
        })
        .map((_) => ({
          text: _,
          value: _,
        })),
      onFilter: (value, record) => record.typologie === value,
      sorter: (a, b) => a.typologie.localeCompare(b.typologie),
      sortDirections: ["descend", "ascend"],
      render: (typology) => {
        return (
          <Tag color={typoColors[typology.toLowerCase()]} key={typology}>
            {typology.toUpperCase()}
          </Tag>
        );
      },
      width: "10%",
      editable: false,
    },
    {
      title: "Durée visite",
      dataIndex: "duree",
      width: "15%",
      editable: true,
      render: (_) => {
        return <div>{presetTimes[_]}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a
              onClick={(e) => {
                save(e, record._id);
              }}
            >
              Sauver
            </a>
            <Popconfirm
              title="Êtes-vous sûr(e) de vouloir annuler?"
              onConfirm={cancel}
            >
              <a>Annuler</a>
            </Popconfirm>
          </Space>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Editer
          </Typography.Link>
        );
      },
    },
  ];
  const printTypos = (typos) => {
    // console.log(`pintTypos`)
    let len = Object.keys(typos).length;
    let counter = 0;
    let str = "(";
    for (const [key, val] of Object.entries(typos)) {
      if (counter === len - 1) {
        str += `${key} : ${val}`;
      } else {
        str += `${key} : ${val}, `;
      }

      counter++;
    }
    return (str += ")");
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "duree" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSelect = (record, selected, selectedRows, nativeEvent) => {
    if (selected) {
      setSelectedRowKeys((_id) => [..._id, record._id]);
    } else {
      setSelectedRowKeys((_id) => {
        const index = _id.indexOf(record._id);
        return [..._id.slice(0, index), ..._id.slice(index + 1)];
      });
    }
    setSelected(selectedRows);
    setTypos(getTypologies(selectedRows));
  };

  const toggleSelectAll = () => {
    if (filteredClientList.every((x) => selectedRowKeys.includes(x._id))) {
      const newSelected = selected.filter(
        (x) => !filteredClientList.map((r) => r._id).includes(x._id)
      );
      setSelected(newSelected);
      setSelectedRowKeys(newSelected.map((x) => x._id));
    } else {
      const newSelected = selected.concat(
        filteredClientList.filter(
          (x) => !selected.map((r) => r._id).includes(x._id)
        )
      );
      setSelected(newSelected);
      setSelectedRowKeys(newSelected.map((x) => x._id));
    }
    setTypos(getTypologies(filteredClientList));
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < clientList.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  return (
    <div style={{ width: "95%" }}>
      <Space direction="horizontal">
        {selectedRowKeys.length > 0 &&
          selectedRowKeys.length <= 1 &&
          selectedRowKeys.length + " acquéreur sélectionné"}
        {selectedRowKeys.length > 1 &&
          selectedRowKeys.length + " acquéreurs sélectionnés"}
        {selectedRowKeys.length > 0 && printTypos(typos)}
      </Space>
      <br />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          rowSelection={rowSelection}
          dataSource={clientList}
          columns={mergedColumns}
          rowKey="_id"
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: [10, 25, 50, 100, 200],
            showSizeChanger: true,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            setFilteredClientList(extra.currentDataSource);
          }}
        />
      </Form>
    </div>
  );
};
