import React, { useState } from "react";
import { Space, Row, Col, theme, Avatar } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { useToken } = theme;

const CustomPageHeader = ({
  title,
  extra,
  subTitle,
  arrow = null,
  avatar = null,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { token } = useToken();

  const arrowStyle = {
    fontSize: "16px",
    marginTop: "2px",
    cursor: "pointer",
    color: isHovered ? token.colorPrimary : "black",
    transition: "color 0.15s ease-in-out",
  };

  return (
    <>
      <Row align="space-between">
        <Col>
          <Space direction="horizontal">
            {arrow !== null && (
              <ArrowLeftOutlined
                style={arrowStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => window.history.back()}
              />
            )}
            {avatar !== null && <Avatar src={avatar.src} />}
            <div
              style={{
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              {title}
            </div>
            <div style={{ color: "rgba(0,0,0,.45)" }}>{subTitle}</div>
          </Space>
        </Col>

        <Col>
          <Space direction="horizontal">{extra}</Space>
        </Col>
      </Row>
      <br />
    </>
  );
};

export default CustomPageHeader;
