import React, { useState, useEffect } from "react";
import ContentContainer from "../../components/ContentContainer";
import VisitLogsHistogramHours from "../../components/admin/VisitLogsHistogramHours";
import Bubble from "../../components/admin/Bubble";
import axios from "axios";
import { getBasicAuthConfig } from "../../utils";
import { App, Empty, Row, Radio, Typography, Col, Statistic } from "antd";
import { Column } from "@ant-design/charts";
import moment from "moment";
import _ from "lodash";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const phaseTransform = {
  choices: "Choix",
  partitions: "Cloisons",
  predelivery: "Pré-livraison",
  delivery: "Livraison",
};

function numMedian(a) {
  a = a.slice(0).sort(function (x, y) {
    return x - y;
  });
  var b = (a.length + 1) / 2;
  return a.length % 2 ? a[b - 1] : (a[b - 1.5] + a[b - 0.5]) / 2;
}

const AdminLogsRdvs = () => {
  const { message } = App.useApp();
  const [data, setData] = useState([]);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState(undefined);
  const [hoursLogs, setHoursLogs] = useState([]);
  const [value, setValue] = useState("promoter");

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);

    let setDays = Array.from(new Set(logs.map((x) => x.day)));
    // console.log(`setDays -> ${setDays}`);

    let fullList = [];
    setDays.forEach((day) => {
      let groupBy = logs
        .filter((_) => _.day === day)
        .reduce((acc, it) => {
          acc[it[e.target.value]] = acc[it[e.target.value]] + 1 || 1;
          return acc;
        }, {});

      // console.log(`groupByProgram => ${JSON.stringify(groupByProgram)}`);

      for (const [key, val] of Object.entries(groupBy)) {
        // console.log(`${key}: ${value}`);
        fullList.push({
          day,
          value: val,
          type: key,
        });
      }
    });

    setData(fullList);
  };

  var config = {
    data: data,
    isStack: true,
    xField: "day",
    yField: "value",
    seriesField: "type",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };

  // const filterDataBy = (logs, filter) => {
  //   let fmtLogs = logs.map((x) => ({
  //     nom: x.nom,
  //     program: x.program,
  //     promoter: x.promoter,
  //     day: moment(new Date(x.createdAt)).format("DD-MM-YYYY"),
  //   }));
  // };

  useEffect(() => {
    const newFunc = async () => {
      try {
        // const res = await axios.get(
        //   process.env.REACT_APP_RDV_API_URL + "/plannings",
        //   getBasicAuthConfig()
        // );
        // let plannings = res.data.plannings;
        // plannings.forEach((planning) => {
        //   delete planning.grid;
        //   delete planning.availabilities;
        // });
        // setData(plannings);
        // console.log(plannings);
        // let planningsOfMonth = getPlanningsOfMonth(plannings, 9);
        // console.log(planningsOfMonth);
        // setCurrentMonthData(planningsOfMonth);

        // Fetching invoice entities
        const invoiceEntities = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/clients",
          getBasicAuthConfig()
        );
        // console.log(invoiceEntities.data);
        let transformInvoice = {};
        invoiceEntities.data.forEach((invoiceEntity) => {
          transformInvoice[invoiceEntity._id] = invoiceEntity.raisonsociale;
        });
        // setInvoiceEntities(invoiceEntities.data);
        // console.log(transformInvoice);

        const resLogs = await axios.get(
          process.env.REACT_APP_RDV_API_URL + "/rdv/logs",
          getBasicAuthConfig()
        );
        // console.log(`resLogs => ${JSON.stringify(resLogs)}`);

        if (resLogs.data.logs.length > 0) {
          let fmtLogs = resLogs.data.logs.map((x) => ({
            nom: x.nom,
            program: x.program,
            promoter: x.promoter,
            planning: x.nom,
            phase: phaseTransform[x.phase],
            raisonsociale: transformInvoice[x.invoiceEntity],
            day: moment(new Date(x.createdAt)).format("w-YYYY"),
            time: x.createdAt,
          }));

          let setDays = Array.from(new Set(fmtLogs.map((x) => x.day)));
          let fullList = [];
          setDays.forEach((day) => {
            let groupBy = fmtLogs
              .filter((_) => _.day === day)
              .reduce((acc, it) => {
                acc[it.promoter] = acc[it.promoter] + 1 || 1;
                return acc;
              }, {});

            for (const [key, value] of Object.entries(groupBy)) {
              fullList.push({
                day,
                value: value,
                type: key,
              });
            }
          });

          setLogs(fmtLogs);
          // console.log(fmtLogs);
          setData(
            fullList.sort((a, b) => {
              return new Date(a.day).getTime() - new Date(b.day).getTime();
            })
          );
          // console.log(`fmtLogs : ${JSON.stringify(fmtLogs)}`);
          // console.log(`data : ${JSON.stringify(fullList)}`);
          // console.log(fullList.map((_) => new Date(_.day).getTime()));
          setHoursLogs(
            fmtLogs.map((x) => ({
              value: new Date(x.time).getHours(),
            }))
          );
        }
      } catch (e) {
        message.error(`Erreur lors du chargement des plannings`);
        console.log(e);
      }
      setIsLoading(false);
    };
    newFunc();
  }, []);

  const reducer = (previousValue, currentValue) => previousValue + currentValue;

  const getTotalBooked = (data) => {
    return data.map((planning) => planning.nbBooked).reduce(reducer);
  };

  const getTotalNotBooked = (data) => {
    return data.map((planning) => planning.nbNotBooked).reduce(reducer);
  };

  const getLastNMonth = (lst, n) => {
    let tsn = new Date().setMonth(new Date().getMonth() - n);
    return lst.filter((x) => {
      let tsx = new Date(x.time);
      if (tsx > tsn) {
        return x;
      }
    });
  };

  const getLastNMonthTwo = (o, n) => {
    let tsn = new Date().setMonth(new Date().getMonth() - n);
    let newdata = [];
    o.forEach((x) => {
      if (new Date(x.day) > tsn) {
        newdata.push(x);
      }
    });
    return newdata;
  };

  const getPlanningsOfMonth = (data, month) => {
    // let actualMonthNumber = new Date().getMonth();
    let filteredData = [];
    data.forEach((planning) => {
      if (
        new Date(planning.planningStartTime).getMonth() === month ||
        new Date(planning.planningEndTime).getMonth() === month
      ) {
        filteredData.push(planning);
      }
    });
    return filteredData;
  };

  if (data.length === 0 || hoursLogs.length === 0) {
    return (
      <ContentContainer>
        <Empty description={<span>Aucune donnée pour le moment.</span>}></Empty>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            // style={{ margin: "0px 16px 0px 16px" }}
            title="Rendez-vous"
            subTitle="Vues par semaine"
            // className="site-page-header"
            avatar={{
              src: "https://img.icons8.com/color/48/000000/combo-chart--v1.png",
            }}
          ></CustomPageHeader>
          <Row gutter={16}>
            <Col span={3}>
              <Statistic title="Total Rdv" value={logs.length} />
            </Col>
            {/* <Col span={3}>
              <Statistic
                title={`Mediane jour`}
                value={numMedian(data.map((x) => x.value))}
              />
            </Col>
            <Divider type="vertical" /> */}
            <Col span={3}>
              <Statistic
                title="Total Rdv 6M"
                value={getLastNMonth(logs, 6).length}
              />
            </Col>
            {/* <Col span={3}>
              <Statistic
                title={`Mediane jour 6M`}
                value={numMedian(getLastNMonthTwo(data, 6).map((x) => x.value))}
              />
            </Col> */}
            <Col span={3}>
              <Statistic
                title="Total Rdv 1M"
                value={getLastNMonth(logs, 1).length}
              />
            </Col>
            {/* <Col span={3}>
              <Statistic
                title={`Mediane jour 1M`}
                value={numMedian(getLastNMonthTwo(data, 1).map((x) => x.value))}
              />
            </Col> */}
          </Row>
          <br />
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={"promoter"}>Promoteur</Radio>
            <Radio value={"program"}>Programme</Radio>
            <Radio value={"planning"}>Planning</Radio>
            <Radio value={"phase"}>Phase</Radio>
            <Radio value={"raisonsociale"}>Client</Radio>
          </Radio.Group>
          <br />
          <br />

          <Column {...config} />
          <br />
          <br />
          <Title level={4}>Heures des prises de rendez-vous</Title>
          <br />
          <VisitLogsHistogramHours logs={hoursLogs} />
        </SubContentContainer>
      </ContentContainer>
    );
  }

  // console.log(JSON.stringify(currentMonthData));
  if (data.length === 0) {
    return (
      <ContentContainer>
        <Empty
          description={<span>Aucun planning pour le moment.</span>}
        ></Empty>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        {!isLoading && data.length > 0 && (
          <div>
            <Bubble
              percentage={
                getTotalBooked(data) /
                (getTotalBooked(data) + getTotalNotBooked(data))
              }
            />
            TOTAL :<br />
            {`Nombre de plannings en cours : ${data.length}`}
            <br />
            {`Total rendez-vous pris : ${getTotalBooked(data)}`}
            <br />
            {`Total rendez-vous en attente : ${getTotalNotBooked(data)}`}
            <br />
            <br />
            <br />
            TOTAL MOIS : <br />
            {`Nombre de plannings en cours : ${currentMonthData.length}`}
            <br />
            {`Total rendez-vous pris : ${getTotalBooked(currentMonthData)}`}
            <br />
            {`Total rendez-vous en attente : ${getTotalNotBooked(
              currentMonthData
            )}`}
            <br />
            <br />
            <br />
            {JSON.stringify(data)}
          </div>
        )}
      </ContentContainer>
    );
  }
};

export default AdminLogsRdvs;
