import React, { useState, useEffect } from "react";
import PrintPdfPrivate from "../../../components/plannings/PrintPdfPrivate";
import PlanningNotFound from "../../../components/plannings/PlanningNotFound";
import { Spin } from "antd";

import axios from "axios";

const PlanningPrintPdf = () => {
  const [plannings, setPlannings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isFreeMeeting, setIsFreeMeeting] = useState(false);
  const [viewType, setViewType] = useState(undefined);
  const [isMulti, setIsMulti] = useState(false);
  const [amoNb, setAmoNb] = useState(null);

  useEffect(() => {
    const fullPath = window.location.pathname.split("/");

    let planningUuid = fullPath[fullPath.length - 3];

    // Si les AMOs sont précisés (export par AMO)
    if (fullPath.length === 6) {
      setAmoNb(parseInt(fullPath[fullPath.length - 2]));
      planningUuid = fullPath[fullPath.length - 4];
    }

    const newFunc = async (planningUuid, fullPath) => {
      // console.log(`******* planningUuid : ${planningUuid}`);

      if (planningUuid.endsWith("--isMulti")) {
        planningUuid = planningUuid.replace("--isMulti", "");
        setIsMulti(true);
      }

      if (planningUuid.endsWith("--showFM")) {
        planningUuid = planningUuid.replace("--showFM", "");
        setIsFreeMeeting(true);
      }

      let uri = planningUuid;

      let options = [
        "nameTypo",
        "lot",
        "typo",
        "name",
        "lotTypo",
        "lotName",
        "lotNameTypo",
        "typoLot",
        "nameLot",
        "nameLotTypo",
        "typoNameLot",
        "typoName",
        "nameTypoLot",
        "lotTypoName",
        "lotNameTypoTel",
      ];

      //getFlag
      let flago = undefined;
      options.forEach((opt) => {
        if (uri.endsWith(`--${opt}`)) {
          flago = opt;
          uri = uri.replace(`--${flago}`, "");
        }
      });
      const finalArray = [];
      let newText = uri;
      do {
        finalArray.push(newText.slice(0, 9));
        newText = newText.slice(10);
      } while (newText !== "");

      try {
        const url = `${process.env.REACT_APP_RDV_API_URL}/rdv/r/${planningUuid}`;
        // console.log(url);
        const res = await axios.get(url);
        if (!res.data.plannings) {
          setNotFound(true);
          setIsLoading(false);
          return;
        }

        setPlannings(res.data.plannings);
        if (fullPath.length === 6) {
          setViewType(fullPath[fullPath.length - 3]);
        } else {
          setViewType(fullPath[fullPath.length - 2]);
        }

        setFlag(flago);
      } catch (e) {
        console.log(e);
        setNotFound(true);
      }
      setIsLoading(false);
    };
    if (isLoading) {
      newFunc(planningUuid, fullPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else if (notFound) {
    return <PlanningNotFound />;
  } else if (plannings.length !== 0) {
    return (
      <PrintPdfPrivate
        plannings={plannings}
        flag={flag}
        viewType={viewType}
        isFreeMeeting={isFreeMeeting}
        isMulti={isMulti}
        amoNb={amoNb}
      />
    );
  } else {
    return <PlanningNotFound />;
  }
};

export default PlanningPrintPdf;
