import React from "react";
import selectEventTotalTime from "../../selectors/events-total-time";
import { TimePicker, Form } from "antd";

const SelectLunch = ({ form, events, format, eventHours, setEventHours }) => {
  const { RangePicker } = TimePicker;

  const onSelectHours = (value) => {
    // console.log(`onSelectHours : ${JSON.stringify(value)}`);
    // console.log(`selectEventHours (avant) : ${eventHours}`);
    // return;
    if (value !== null && events !== undefined) {
      let totalTime = selectEventTotalTime(
        events,
        new Date(value[0]).toLocaleTimeString().slice(0, 5),
        new Date(value[1]).toLocaleTimeString().slice(0, 5)
      );
      // console.log(`selectEventHours (apres) : ${totalTime}`);
      setEventHours(totalTime);
    }
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
    };
  };

  return (
    <Form form={form}>
      <Form.Item
        name="startEndLunch"
        rules={[
          {
            required: true,
            message: "Veuillez renseigner un horaire de pause déjeuner",
          },
        ]}
      >
        <RangePicker
          format={format}
          disabledTime={disabledDateTime}
          hideDisabledOptions={true}
          minuteStep={30}
          onChange={onSelectHours}
        />
      </Form.Item>
    </Form>
  );
};

export default SelectLunch;
