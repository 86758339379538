import React from "react";
import { Tag } from "antd";

const Tips = () => {
  const dragStartHandler = (event) => {
    event.dataTransfer.setData("text/plain", ` ${event.target.innerText} `);
  };
  return (
    <div style={{ lineHeight: "2em" }}>
      <br />
      Disponibles :{" "}
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<DEBUT_VISITE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<NOM>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<TYPE_VISITE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<LIEN_RDV>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<LIEN_PLAN>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<PROMOTEUR>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<ADRESSE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<FIN_PRISE_RDV>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<LOTCOMM>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<ETAGE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CONTACT_TITRE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CONTACT_PRENOM>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CONTACT_NOM>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CONTACT_TELEPHONE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CONTACT_MAIL>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<SIGNATURE>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<NOM_PROGRAMME>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<VILLE_PROGRAMME>`}</Tag>
      <Tag
        draggable
        onDragStart={dragStartHandler}
        style={{ cursor: "grab" }}
      >{`<CODEPOSTAL_PROGRAMME>`}</Tag>
      <br />
      <br />
    </div>
  );
};

export default Tips;
