import React from "react";
import { Modal, Form, Input, App, DatePicker, Checkbox } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";
import "dayjs/locale/fr";
import "moment/locale/fr";
import moment from "moment";
import {
  getVisitesApiAuth,
  getRdvApiAuth,
  getBasicAuthConfig,
} from "./../../utils";
import validator from "validator";

// moment.locale("fr");

const CollectionCreateForm = ({
  show,
  setShow,
  usrData,
  onCreate,
  onCancel,
  planning,
}) => {
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";

  const onAddVisit = async () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("La validation des champs a échoué:", info);
      });
  };

  return (
    <Modal
      open={show}
      title={`Ajout d'un visite 3D pour ${usrData.nom}`}
      okText="Publier"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={onAddVisit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          startDate: dayjs(),
          endDate: dayjs().add(21, "days"),
        }}
      >
        <Form.Item name="startDate" label="Début de validité">
          <DatePicker format={dateFormat} />
        </Form.Item>

        <Form.Item name="endDate" label="Fin de validité">
          <DatePicker format={dateFormat} />
        </Form.Item>
        <Form.Item
          name="payload"
          label="Lien Matterport"
          rules={[
            {
              validator: async (_, payload) => {
                if (
                  !payload ||
                  !payload.startsWith("https://my.matterport.com/show/?m=")
                ) {
                  return Promise.reject(
                    new Error(`Le lien matterport n'est pas valide.`)
                  );
                }
              },
              required: true,
              message:
                "Veuillez saisir un lien matterport valide (https://my.matterport ...)",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="sendMail" valuePropName="checked">
          <Checkbox>Envoyer le lien par mail à la personne</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const isListUnique = (list) => {
  const counts = list
    .map((x) => x.trim())
    .reduce(function (acc, curr) {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    }, {});

  return Object.values(counts).every((val) => val === 1);
};

const areLinksValid = (list) => {
  return list.every((val) =>
    val.trim().startsWith("https://my.matterport.com/show/?m=")
  );
};

const areEmailAdressesValid = (list) => {
  return list.every((val) => validator.isEmail(val.trim()));
};

const PlanningBookingVrDataModal = ({
  show,
  setShow,
  usrData,
  setUsrData,
  planning,
  fetchPlanningAndFmtEvents,
}) => {
  const { message } = App.useApp();

  const onCreate = async (values) => {
    let areMatterportLinksValid = true;
    let areMatterportLinksUnique = true;
    let areEmailsValid = true;
    let areEmailsUnique = true;

    if (values.payload.includes(";")) {
      areMatterportLinksValid = areLinksValid(values.payload.split(";"));
      areMatterportLinksUnique = isListUnique(values.payload.split(";"));
    }

    if (values.email !== undefined && values.email.includes(";")) {
      areEmailsValid = areEmailAdressesValid(values.email.split(";"));
      areEmailsUnique = isListUnique(values.email.split(";"));
    }

    if (
      !(
        areMatterportLinksValid &&
        areMatterportLinksUnique &&
        areEmailsValid &&
        areEmailsUnique
      )
    ) {
      if (!areMatterportLinksValid) {
        message.error("Les liens Matterport ne sont pas valides");
      }
      if (!areMatterportLinksUnique) {
        message.error("Un même lien Matterport est plusieurs fois présent");
      }
      if (!areEmailsValid) {
        message.error("Les adresses emails ne sont pas valides");
      }
      if (!areEmailsUnique) {
        message.error("Une même adresse mail est plusieurs fois présente");
      }
      setShow(false);
    } else {
      try {
        const user = {
          ...values,
          promoterInternalName: `${planning.promoter} - ${localStorage.getItem(
            "currentProgram"
          )} - ${planning.phase} - ${planning.name}`,
          pointer: `${planning.promoter} - ${localStorage.getItem(
            "currentProgram"
          )} - ${planning.name} - ${planning.phase} - ${usrData.lotcomm}`,
          name: usrData.nom,
          email:
            usrData.mailmonsieur && usrData.mailmadame
              ? `${usrData.mailmonsieur};${usrData.mailmadame}`
              : usrData.email,
          sendMail: values.sendMail === undefined ? false : true,
          usePromoterInternalName: true,
        };

        // Creation de la visite virtuelle
        const res = await axios.post(
          process.env.REACT_APP_VR_API_URL + "/vv",
          user,
          getVisitesApiAuth()
        );
        let newVrId = res.data._id;

        // Mise a jour de la broadcastList avec le lien de la visite virtuelle
        let updates = { vrUuid: res.data.vrUuid, uuid: usrData.uuid };
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            localStorage.getItem("currentPlanningId") +
            "/broadcastlist",
          updates,
          getRdvApiAuth()
        );

        // Ajout de la visite virtuelle dans le Housing de l'utilisateur Bridgit
        // dont l'id a ete retrouvée avec son email
        let data = {
          email: usrData.email,
          lotcomm: usrData.lotcomm,
          program: localStorage.getItem("currentProgramId"),
          vrId: newVrId,
        };

        // Enregistrement de la visite dans la table "housing" de l'utilisateur
        await axios.post(
          process.env.REACT_APP_API_URL + "/user/housings",
          data,
          getBasicAuthConfig()
        );

        await fetchPlanningAndFmtEvents();
        setShow(false);
        message.success(
          `Nouvelle visite pour l'utilisateur ${usrData.nom} créée avec succès.`
        );
        setUsrData(undefined);
      } catch (e) {
        message.error(
          `Une erreur est apparue lors de la création de la visite. (${e})`
        );
        console.log(`FULL ERROR  ${JSON.stringify(e)}`);
      }
    }
  };

  return (
    <div>
      <CollectionCreateForm
        show={show}
        setShow={setShow}
        usrData={usrData}
        onCreate={onCreate}
        onCancel={() => {
          setShow(false);
        }}
        planning={planning}
      />
    </div>
  );
};

export default PlanningBookingVrDataModal;
