import React, { useState } from "react";
import { Dropdown, Button, Space, Modal, App } from "antd";
import {
  DownOutlined,
  InboxOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
} from "@ant-design/icons";

import axios from "axios";
import {
  getBasicAuthConfig,
  getFormatedClients,
  onClickExportProgramXLS,
} from "../../utils";

const ClientOptions = ({ selected, unSelectedAllRows, setClientList }) => {
  // const [groupMsgModalVisible, setGroupMsgModalVisible] = useState(false);
  const { message } = App.useApp();
  const [pingResultModalVisible, setPingResultModalVisible] = useState(false);
  const [pingRes, setPingRes] = useState([]);

  const mailPingRequest = async (fmtSel) => {
    try {
      let res = await axios.post(
        process.env.REACT_APP_API_URL + "/utils/ping-mail",
        fmtSel,
        getBasicAuthConfig()
      );
      setPingRes(res.data);
      setPingResultModalVisible(true);
    } catch (e) {
      message.error(`Erreur lors de la vérification des emails acquéreurs`);
      console.log(e);
    }
  };

  const handleMenuClick = async (key) => {
    // if (key === "1") {
    // setGroupMsgModalVisible(true);
    // } else
    if (key === "2") {
      if (selected.length > 3) {
        message.error(
          "Impossible de lancer un Ping sur plus de trois adresses en meme temps."
        );
      }
      let fmtSel = [];
      selected.forEach((_) => {
        if (_.mailmonsieur) {
          fmtSel.push({ lotcomm: _.lotcomm, mail: _.mailmonsieur });
        }
        if (_.mailmadame) {
          fmtSel.push({ lotcomm: _.lotcomm, mail: _.mailmadame });
        }
      });
      mailPingRequest(fmtSel);
    } else if (key === "3") {
      try {
        let config = getBasicAuthConfig();
        let res = await axios.delete(
          process.env.REACT_APP_API_URL +
            `/programs/${localStorage.getItem("currentProgramId")}/housings`,
          { headers: config.headers, data: selected }
        );
        if (!res.data.error) {
          if (selected.length > 1) {
            message.success(`${selected.length} lots correctement supprimés`);
          } else {
            message.success(`Lot ${selected[0].lotcomm} correctement supprimé`);
          }
        }
        // After Lock client file, fetch DB
        const program = await axios.get(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            localStorage.getItem("currentProgramId"),
          getBasicAuthConfig()
        );
        let tmpClients = getFormatedClients(
          program.data.clients,
          localStorage.getItem("currentProgramId")
        );
        setClientList(tmpClients);
        unSelectedAllRows();
      } catch (e) {
        console.log(e);
        message.error(`Impossible de supprimer les lots sélectionnés`);
      }
    } else if (key === "4") {
      const program = await axios.get(
        process.env.REACT_APP_API_URL +
          "/programs/" +
          localStorage.getItem("currentProgramId"),
        getBasicAuthConfig()
      );
      let tmpClients = getFormatedClients(
        program.data.clients,
        localStorage.getItem("currentProgramId")
      );
      onClickExportProgramXLS(tmpClients);
    }
  };

  const DropDownActionMenu = () => {
    const items = [
      // {
      //   label: (
      //     <a
      //       target="_blank"
      //       rel="noopener noreferrer"
      //       onClick={() => handleMenuClick("1")}
      //     >
      //       Message
      //     </a>
      //   ),
      //   key: "1",
      // }, // remember to pass the key prop
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleMenuClick("2")}
          >
            Ping Mail
          </a>
        ),
        key: "2",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleMenuClick("4")}
          >
            Exporter
          </a>
        ),
        key: "3",
      },
      {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleMenuClick("3")}
          >
            Supprimer
          </a>
        ),
        key: "4",
      },
    ];
    return (
      <Dropdown menu={{ items }}>
        <Button>
          Action <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const validateMessages = {
    required: "Vous devez remplir un ${label}",
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("okrr");
    }, 0);
  };

  // const createUserTicket = async (data) => {
  //   try {
  //     // if (!selectedFile !== null) {
  //     const formData = new FormData();
  //     // console.log("DAAA : " + JSON.stringify(data));
  //     let authorizedHousings = selected.map((_) => _._id);
  //     let authorizedUsers = selected.map((_) => _.owner);
  //     formData.set("authorizedHousings", authorizedHousings);
  //     formData.set("authorizedUsers", authorizedUsers);
  //     for (let i = 0; i < data.dragger.length; i++) {
  //       let selectedFile = data.dragger[i].originFileObj;
  //       let selectedName = data.dragger[i].name;

  //       console.log(
  //         `selectedFile: ${JSON.stringify(
  //           selectedFile
  //         )}, selectedName: ${JSON.stringify(selectedName)}`
  //       );

  //       formData.append("uploads", selectedFile, selectedName); //, authorized);
  //     }

  //     console.log(
  //       `authorizedHousings sent : ${JSON.stringify(authorizedHousings)}`
  //     );
  //     console.log(`authorizedUsers sent : ${JSON.stringify(authorizedUsers)}`);
  //     // formData.append("authorizedHousings", authorizedHousings);
  //     // formData.append("authorizedUsers", authorizedUsers);
  //     // console.log(`formData => ${JSON.stringify(formData)}`);
  //     // https://www.geeksforgeeks.org/upload-and-retrieve-image-on-mongodb-using-mongoose/
  //     // formData.append("uploads", selectedFile, selectedFile.name);
  //     const res = await axios.post(
  //       process.env.REACT_APP_API_URL + `/users/me/ticket/upload-files`,
  //       formData,
  //       getBasicAuthConfig()
  //     );

  //     const filesIds = res.data.filesIds;
  //     // console.log(`* fileIds : ${JSON.stringify(filesIds)}`);
  //     // console.log(`RETOUR REQUETE I : ${JSON.stringify(res.data)}`);
  //     // const ticketId = new mongoose.Types.ObjectId();
  //     const reqObj = {
  //       files: filesIds,
  //       subject: data.message.sujet,
  //       message: data.message.text,
  //       program: localStorage.getItem("currentProgramId"),
  //       receivers: selected,

  //       //isAnswered: false
  //     };

  //     // console.log(`reqObj ; ${JSON.stringify(reqObj)}`);
  //     const res2 = await axios.post(
  //       process.env.REACT_APP_API_URL + `/users/me/ticket/create`,
  //       reqObj,
  //       getBasicAuthConfig()
  //     );
  //     console.log(`RETOUR REQUETE II : ${JSON.stringify(res2)}`);

  //     // console.log(`SELECTED ::: ${JSON.stringify(selected)}`);
  //     // console.log(`TRY : ${JSON.stringify(res2.data.ticket._id)}`);
  //     // Creation notification
  //     const notifObj = {
  //       receivers: selected,
  //       ticket: res2.data.ticket._id,
  //     };

  //     const res4 = await axios.post(
  //       process.env.REACT_APP_API_URL + `/notification/create`,
  //       notifObj,
  //       getBasicAuthConfig()
  //     );

  //     console.log(`Return of Notif create : ${JSON.stringify(res4)}`);
  //     message.success("ticket correctement créé !");

  //     setGroupMsgModalVisible(false);
  //   } catch (e) {
  //     // console.log(`Error : ${e}`);
  //     message.error(`Erreur : ${e}`);
  //     setGroupMsgModalVisible(false);
  //   }
  // };

  // const GroupMsgForm = () => {
  //   const onFinish = async (values) => {
  //     console.log(`--> Button Envoyer clicked !`);
  //     console.log(values);
  //     createUserTicket(values);
  //   };

  //   return (
  //     <Form
  //       {...layout}
  //       name="nest-messages"
  //       onFinish={onFinish}
  //       validateMessages={validateMessages}
  //     >
  //       <Form.Item
  //         name={["message", "sujet"]}
  //         label="Sujet"
  //         rules={[
  //           {
  //             required: true,
  //           },
  //         ]}
  //       >
  //         <Input />
  //       </Form.Item>
  //       <Form.Item
  //         name={["message", "text"]}
  //         label="Message"
  //         rules={[
  //           {
  //             required: true,
  //           },
  //         ]}
  //       >
  //         <Input.TextArea />
  //       </Form.Item>

  //       <Form.Item label="Dragger">
  //         <Form.Item
  //           name="dragger"
  //           valuePropName="fileList"
  //           getValueFromEvent={normFile}
  //           noStyle
  //         >
  //           <Upload.Dragger
  //             name="files"
  //             customRequest={dummyRequest}
  //             accept="image/png, image/jpeg, image/jpg"
  //           >
  //             <p className="ant-upload-drag-icon">
  //               <InboxOutlined />
  //             </p>
  //             <p className="ant-upload-text">
  //               Cliquez ou déposer des fichier ici
  //             </p>
  //             <p className="ant-upload-hint">
  //               Il est possible d'envoyer plusieurs fichiers à la fois.
  //             </p>
  //           </Upload.Dragger>
  //         </Form.Item>
  //       </Form.Item>

  //       <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
  //         <Button type="primary" htmlType="submit">
  //           Envoyer
  //         </Button>
  //       </Form.Item>
  //     </Form>
  //   );
  // };

  // const GroupMsgModal = () => {
  //   return (
  //     <div>
  //       <Modal
  //         title={
  //           selected.length > 1
  //             ? `Message à ${selected.length} personnes`
  //             : `Message à ${selected.length} personne`
  //         }
  //         centered
  //         open={groupMsgModalVisible}
  //         onOk={() => setGroupMsgModalVisible(false)}
  //         onCancel={() => setGroupMsgModalVisible(false)}
  //         width={1000}
  //       >
  //         <br />
  //         <GroupMsgForm />
  //       </Modal>
  //     </div>
  //   );
  // };

  const PingModal = () => {
    return (
      <div>
        <Modal
          title={
            selected.length > 1
              ? `Ping à ${selected.length} personnes`
              : `Ping à ${selected.length} personne`
          }
          centered
          open={pingResultModalVisible}
          onOk={() => setPingResultModalVisible(false)}
          onCancel={() => setPingResultModalVisible(false)}
          width={500}
        >
          <br />
          {pingRes.map((ping, idx) => {
            return (
              <div key={idx}>
                {`[${ping.value.lotcomm}] ${ping.value.mail} `}
                {ping.value.smtpCheck === "false" ? (
                  <CloseCircleOutlined />
                ) : (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}
                <br />
              </div>
            );
          })}
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <PingModal />
      {/* <GroupMsgModal /> */}
      <Space direction="horizontal">
        <DropDownActionMenu />

        {selected.length > 0 &&
          selected.length <= 1 &&
          selected.length + " personne sélectionnée"}
        {selected.length > 1 && selected.length + " personnes sélectionnées"}
      </Space>
    </div>
  );
};

export default ClientOptions;
