import React from "react";
import { Modal, Form, Input, Select, App, Space } from "antd";
import axios from "axios";
import {
  getBasicAuthConfig,
  isSiretNumberOk,
  juridicalForms,
} from "../../../utils";

const { Option } = Select;
const CreateInvoiceEntityModal = ({
  show,
  setShow,
  clients,
  setClients,
  promoters,
}) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const onCreate = async (values) => {
    // console.log(`onCreate : ${JSON.stringify(values)}`);
    try {
      let res = await axios.post(
        process.env.REACT_APP_API_URL + "/admin/client",
        {
          raisonsociale: values.raisonsociale,
          formejuridique: values.formejuridique,
          siret: values.siret,
          adressenumero: values.adresse.numero,
          adresserue: values.adresse.rue,
          adressecodepostal: values.adresse.codepostal,
          adresseville: values.adresse.ville,
          adressepays: values.adresse.pays,
          promoter: values.promoter[0],
        },
        getBasicAuthConfig()
      );
      let newClients = [...clients];
      newClients.push(res.data);
      setClients(newClients);
      setShow(false);
      form.resetFields();
      message.success(`Nouvelle entité de facturation crée`);
    } catch (e) {
      message.error(`Veuillez vérifier les champs du formulaire`);
      console.log(e);
    }
  };

  const update = async () => {
    try {
      const values = await form.validateFields();
      await onCreate(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      title="Création d'une nouvelle entité de facturation"
      style={{ top: 40 }}
      open={show}
      onOk={update}
      onCancel={() => setShow(false)}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Forme Juridique"
          name="formejuridique"
          rules={[
            {
              required: true,
              message: "Veuillez indiquer la forme juridique",
            },
          ]}
        >
          <Select showSearch optionFilterProp="value">
            {juridicalForms.map((_) => (
              <Select.Option key={_} value={_.toLocaleLowerCase()}>
                {_}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Raison Sociale"
          name="raisonsociale"
          rules={[
            {
              required: true,
              message: "Veuillez indiquer la raison sociale",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="promoter"
          label="Promoteur"
          rules={[
            {
              required: true,
              validator: async (_, payload) => {
                // console.log(`payload -> ${JSON.stringify(payload)}`);
                if (
                  payload === undefined ||
                  payload.length !== 1 ||
                  payload[0] === ""
                ) {
                  return Promise.reject(
                    new Error(`Veuillez indiquer un promoteur`)
                  );
                }
              },
              // message: "Veuillez indiquer un numéro de SIRET",
            },
          ]}
        >
          <Select
            placeholder="Promoteur"
            mode="tags"
            style={{
              width: "100%",
            }}
          >
            {promoters.map((p, idx) => {
              return (
                <Option key={idx + "-" + p} value={p}>
                  {p}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Numéro de SIRET"
          name="siret"
          rules={[
            {
              required: true,
              validator: async (_, payload) => {
                if (payload === undefined || payload === "") {
                  return Promise.reject(
                    new Error(`Veuillez indiquer un numéro de SIRET`)
                  );
                } else if (!isSiretNumberOk(payload)) {
                  return Promise.reject(
                    new Error(`Numéro de SIRET incorrect (14 digits)`)
                  );
                }
              },
              // message: "Veuillez indiquer un numéro de SIRET",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item form={form} label="Adresse principale" required>
          <Space.Compact>
            <Form.Item
              name={["adresse", "numero"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un numéro de rue",
                },
              ]}
            >
              <Input
                style={{ width: "20%", marginBottom: "4px" }}
                placeholder="No"
              />
            </Form.Item>
            <Form.Item
              name={["adresse", "rue"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un nom de rue",
                },
              ]}
            >
              <Input
                style={{
                  width: "80%",
                  marginBottom: "4px",
                }}
                placeholder="rue"
              />
            </Form.Item>
          </Space.Compact>
          <Space.Compact>
            {" "}
            <Form.Item
              name={["adresse", "codepostal"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un code postal",
                },
              ]}
            >
              <Input style={{ width: "20%" }} placeholder="code postal" />
            </Form.Item>
            <Form.Item
              name={["adresse", "ville"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer une ville",
                },
              ]}
            >
              <Input style={{ width: "30%" }} placeholder="ville" />
            </Form.Item>
            <Form.Item
              name={["adresse", "pays"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez indiquer un pays",
                },
              ]}
            >
              <Input style={{ width: "50%" }} placeholder="pays" />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateInvoiceEntityModal;
