import React, { useState } from "react";
import { Table, Typography, Button, Popconfirm } from "antd";
import moment from "moment";
import axios from "axios";

// moment.locale("fr");

const LogsTable = ({ data, setHasLoaded }) => {
  const [selected, setSelected] = useState(0);
  const { Title } = Typography;

  const removeLogs = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_VR_API_URL + "/vv/logs/multiple",
        selected
      );
      setSelected(0);
      setHasLoaded(false);
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <>{moment(text).format("Do MMM LT")}</>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: "descend",
      ellipsis: true,
    },
    {
      title: "IP",
      dataIndex: "ip",
      filters: Array.from(new Set(data.map((x) => x.ip))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.ip !== null) {
          return record.ip.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Ville",
      dataIndex: "city",
      filters: Array.from(new Set(data.map((x) => x.city))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.city !== null) {
          return record.city.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Code postal",
      dataIndex: "postal",
      filters: Array.from(new Set(data.map((x) => x.postal))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.postal !== null) {
          return record.postal.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Région",
      dataIndex: "state",
      filters: Array.from(new Set(data.map((x) => x.state))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.state !== null) {
          return record.state.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Pays",
      dataIndex: "country_name",
      filters: Array.from(new Set(data.map((x) => x.country_name))).map(
        (_) => ({
          text: _,
          value: _,
        })
      ),
      onFilter: (value, record) => {
        if (record.country_name !== null) {
          return record.country_name.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Nom Matterport",
      dataIndex: "pointer",
      filters: Array.from(new Set(data.map((x) => x.pointer))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.pointer !== null) {
          return record.pointer.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Nom Client",
      dataIndex: "name",
      filters: Array.from(new Set(data.map((x) => x.name))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => {
        if (record.name !== null) {
          return record.name.includes(value);
        } else {
          return null;
        }
      },
      ellipsis: true,
    },
    {
      title: "Promoteur",
      dataIndex: "promoter",
      filters: Array.from(new Set(data.map((x) => x.promoter))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => record.promoter.includes(value),
      ellipsis: true,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let newlySelected = {};
      selectedRows.forEach((row) => {
        if (Object.keys(newlySelected).includes(row.uuid)) {
          newlySelected[row.uuid].push(row.key);
        } else {
          newlySelected[row.uuid] = [row.key];
        }
      });
      setSelected(newlySelected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const writeSelection = () => {
    if (selected === 0) {
      return (
        <div>
          <i>Séléctionner pour afficher les options</i>
        </div>
      );
    } else {
      let nb = Object.values(selected).flat(2).length;
      return (
        <div>
          {nb} connexions{" "}
          <Popconfirm
            title={
              "Etes-vous sûr(e) de vouloir supprimer les " +
              nb +
              " éléments séléctionnés ?"
            }
            onConfirm={() => {
              removeLogs();
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">(Supprimer)</Button>
          </Popconfirm>
        </div>
      );
    }
  };

  return (
    <div>
      <Title level={4}>Historique</Title>
      {writeSelection()}
      <br />
      <Table
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={data}
        rowKey="key"
        size="small"
        pagination={{ defaultPageSize: 500, hideOnSinglePage: true }}
        scroll={{
          y: 300,
        }}
      />
    </div>
  );
};

export default LogsTable;
