import React from "react";
import dayjs from "dayjs";
import { Modal, Select, Form, Space, Switch, Typography } from "antd";
import AddFreeMeeting from "../freemeeting/modals/AddFreeMeeting";
import AddForceMeeting from "./AddForceMeeting";
import { defaultColors } from "../planningData";
const { Option } = Select;
const { Text } = Typography;
dayjs.locale("fr");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const FormForceBookModal = ({
  showModal,
  setShowModal,
  onOk,
  form,
  notBookedList,
  amos,
  planning,
  fetchPlanningAndFmtEvents,
  isFreeMeetingModal,
  setIsFreeMeetingModal,
  confirmLoading,
  recentColors,
  setRecentColors,
}) => {
  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
      // disabledMinutes: () => this.range(30, 60),
      // disabledSeconds: () => [55, 56],
    };
  };

  const onChange = () => {
    setIsFreeMeetingModal(!isFreeMeetingModal);
  };

  return (
    <div>
      <Modal
        title={
          !isFreeMeetingModal
            ? `Ajout manuel de rendez-vous`
            : `Ajout d'un RDV Libre`
        }
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={async () => {
          try {
            const values = await form.validateFields();
            await onOk(values);
            setShowModal(false);
            form.resetFields();
          } catch (e) {
            console.log(e);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <br />

        <Space>
          <Switch onChange={onChange} checked={isFreeMeetingModal} />
          RDV Libre
          {isFreeMeetingModal && <Text type="warning">Aucune contrainte</Text>}
        </Space>
        <br />
        <br />
        <Form
          form={form}
          initialValues={{
            selectedDate: dayjs(planning.planningStartTime).second(0),
            freeMeetingDate: dayjs(planning.planningStartTime).second(0),
            isMailConfirmation: true,
            color: defaultColors[0],
          }}
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner un invité !",
            },
          ]}
        >
          {notBookedList !== undefined && !isFreeMeetingModal && (
            <AddForceMeeting
              form={form}
              planning={planning}
              amos={amos}
              notBookedList={notBookedList}
              disabledDateTime={disabledDateTime}
            />
          )}

          {isFreeMeetingModal && (
            <AddFreeMeeting
              recentColors={recentColors}
              // form={form}
              // planning={planning}
              // show={showModal}
              // setShow={setShowModal}
              // fetchPlanningAndFmtEvent={fetchPlanningAndFmtEvents}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default FormForceBookModal;
