import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin } from "antd";
import AgendaReadOnlyPublic from "./../../../components/plannings/AgendaReadOnlyPublic";
import PlanningNotFound from "./../../../components/plannings/PlanningNotFound";

const getAmoPlanning = (plannings, amoNb) => {
  //console.log(`getAmoPlannings : ${amoNb}`);
  //console.log(plannings);
  let newPlannings = JSON.parse(JSON.stringify(plannings));
  let tmpEvents = [];
  plannings.forEach((planning, idx) => {
    tmpEvents = [];
    for (let x = 0; x < planning.events.length; x++) {
      //console.log(
      //  `planning.events[x].nbAmo === amoNb + 1 : ${
      //    planning.events[x].nbAmo
      //  } === ${amoNb + 1}`
      //);
      if (parseInt(planning.events[x].nbAmo) === parseInt(amoNb) + 1) {
        //console.log("pass");
        tmpEvents.push(planning.events[x]);
      }
    }
    newPlannings[idx].events = tmpEvents;
  });
  return newPlannings;
};

const PlanningReadOnly = () => {
  const [plannings, setPlannings] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMulti, setIsMulti] = useState(false);
  const [amoNb, setAmoNb] = useState(null);

  useEffect(() => {
    const fullPath = window.location.pathname.split("/");
    let planningUuid = fullPath[fullPath.length - 1];
    let tmpIsMulti = fullPath.length === 4 ? true : false;
    let amoNb = null;
    if (isMulti) amoNb = setAmoNb(fullPath[fullPath.length - 1]);
    setIsMulti(tmpIsMulti);

    const newFunc = async () => {
      try {
        // IS MULTI
        if (fullPath.length === 4) {
          console.log(`Pass MULTI PRINT, amo : ${fullPath[3]}`);
          planningUuid = fullPath[fullPath.length - 2];
          setAmoNb(parseInt(fullPath[fullPath.length - 1]));
        }
        const res = await axios.get(
          `${process.env.REACT_APP_RDV_API_URL}/rdv/r/${planningUuid}/`
        );
        // console.log(res.data.plannings);

        if (tmpIsMulti) {
          console.logo(`Detected IS MULTI`);
          if (res.data.plannings[0].templateType === "promoterImpose") {
            console.log(`PROMOTER IMPOSE`);
            setPlannings(res.data.plannings);
          } else {
            // transformation des plannings
            let newPlannings = getAmoPlanning(res.data.plannings, amoNb);
            //console.log("newPlannings");
            //console.log(newPlannings);
            setPlannings(newPlannings);
          }
        } else {
          setPlannings(res.data.plannings);
        }

        if (!res.data.plannings) {
          setNotFound(true);
          setIsLoading(false);
          return;
        }
      } catch (e) {
        console.log(`erreur : `);
        //console.log(e);
        setNotFound(true);
      }
      setIsLoading(false);
      // sleep(200); // Was sleep here
    };

    if (isLoading) {
      newFunc(planningUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else if (notFound) {
    return <PlanningNotFound />;
  } else if (plannings.length !== 0) {
    return <AgendaReadOnlyPublic plannings={plannings} amoNb={amoNb} />;
  } else {
    return <PlanningNotFound />;
  }
};

export default PlanningReadOnly;
