import React from "react";
import ContentContainer from "../../components/ContentContainer";
import SubContentContainer from "../../components/SubContentContainer";
import { Typography, Skeleton } from "antd";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const HomeAmo = () => {
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader title="Page Accueil AMO - En développement" />
        <Skeleton />
      </SubContentContainer>
    </ContentContainer>
  );
};

export default HomeAmo;
