import React, { useState, useEffect } from "react";
import ContentContainer from "./../../components/ContentContainer";
import SubContentContainer from "./../../components/SubContentContainer";
import CustomPageHeader from "../../components/SubContentContainer";
import { Typography, Row, App } from "antd";
import UsersTable from "../../components/admin/UsersTable";
import AddUser from "../../components/admin/AddUser";
import axios from "axios";
import { getBasicAuthConfig } from "../../utils";
import { AlertOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";

const { Text } = Typography;

const AdminUsers = ({ children }) => {
  const { message } = App.useApp();
  const [data, setData] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [entities, setEntities] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/admin/users",
        getBasicAuthConfig()
      );
      // console.log(`FetchReturn -> ${JSON.stringify(res.data)}`);
      const originData = [];
      for (let i = 0; i < res.data.length; i++) {
        originData.push({
          key: res.data[i]._id,
          firstname: res.data[i].firstname,
          lastname: res.data[i].lastname,
          enterprise: res.data[i].enterprise,
          email: res.data[i].email,
          mailmonsieur: res.data[i].mailmonsieur,
          mailmadame: res.data[i].mailmadame,
          telephonemonsieur: res.data[i].telephonemonsieur,
          telephonemadame: res.data[i].telephonemadame,
          gdprAskInfo: res.data[i].gdprAskInfo,
          gdprDelInfo: res.data[i].gdprDelInfo,
          role: res.data[i].role,
          invoiceEntity: res.data[i].invoiceEntity,
        });
      }

      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/admin/clients",
        getBasicAuthConfig()
      );
      setEntities(res2.data);

      setData(originData);
      setHasLoaded(true);
    } catch (e) {
      console.log(`Error when fetching users list : ${e}`);
      message.error(
        "Vous n'avez pas les permissions pour accéder à la liste des utilisateurs"
      );
    }
  };

  useEffect(() => {
    // console.log("Pass usedEffect");
    if (hasLoaded === false) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Content = () => {
    return (
      <div>
        <Row>
          <div style={{ flex: 1 }}>
            <UsersTable
              data={data}
              setData={setData}
              hasLoaded={hasLoaded}
              entities={entities}
            />
          </div>
        </Row>
      </div>
    );
  };

  return (
    <ContentContainer>
      <SubContentContainer>
        <PageHeader
          title="Administrateur"
          subTitle="Comptes"
          extra={[
            //<Button key="3">Operation</Button>,
            //<Button key="2">Operation</Button>,
            <AddUser
              key="1"
              data={data}
              setData={setData}
              fetchUsers={fetchUsers}
              hasLoaded={hasLoaded}
              entities={entities}
            />,
            // <Button key="1" type="primary">
            //   <PlusOutlined />
            //   Ajouter
            // </Button>,
            //<DropdownMenu key="more" />,
          ]}
          avatar={{
            src: "https://avatars1.githubusercontent.com/u/8186664?s=460&v=4",
          }}
        />
        <p>
          <Text type="secondary" italic>
            <AlertOutlined /> Double cliquez sur une ligne pour en savoir plus
            sur un utilisateur
          </Text>
        </p>
        <Content
          extraContent={
            <img
              src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
              alt="content"
              width="100%"
            />
          }
        ></Content>
      </SubContentContainer>
    </ContentContainer>
  );
};
export default AdminUsers;

// const menu = (
//   <Menu>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="http://www.alipay.com/"
//       >
//         1st menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="http://www.taobao.com/"
//       >
//         2nd menu item
//       </a>
//     </Menu.Item>
//     <Menu.Item>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         href="http://www.tmall.com/"
//       >
//         3rd menu item
//       </a>
//     </Menu.Item>
//   </Menu>
// );
// const DropdownMenu = () => {
//   return (
//     <Dropdown key="more" overlay={menu}>
//       <Button
//         style={{
//           border: "none",
//           padding: 0,
//         }}
//       >
//         <EllipsisOutlined
//           style={{
//             fontSize: 20,
//             verticalAlign: "top",
//           }}
//         />
//       </Button>
//     </Dropdown>
//   );
// };

// const IconLink = ({ src, text }) => (
//   <a className="example-link">
//     <img className="example-link-icon" src={src} alt={text} />
//     {text}
//   </a>
// );
