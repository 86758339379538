import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const UserProfileRightContainer = ({ children }) => {
  return (
    <Content
      className="site-layout-background"
      style={{
        margin: "12px 24px 0px 12px",
        padding: 12,
        minHeight: 480,
      }}
    >
      {children}
    </Content>
  );
};

export default UserProfileRightContainer;
