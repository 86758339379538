import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import adaptivePlugin from "@fullcalendar/adaptive";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { Button, App, Modal, Form, Checkbox, DatePicker } from "antd";
import {
  formatEvents,
  getRdvApiAuth,
  viewTransform,
  resetCalendarApiDefaultSettings,
  getAmoNb,
  onClickExportICS,
  onClickExportPDF,
  onClickExportXLS,
} from "../../../utils";
import TitlePersonnalizeModal from "../modals/TitlePersonnalizeModal";
import ExportModal from "../../ExportModal";
import { bankHolidays } from "./../../plannings/planningData";

dayjs.locale("fr");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const AgendaReadOnlyPrivate = ({
  plannings,
  showFreeMeetings,
  setShowFreeMeetings,
}) => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [eventSourcetoSend, setEventSourcetoSend] = useState([]);
  const [fmtEvents, setFmtEvents] = useState(undefined);
  const [isWeekend, setIsWeekend] = useState(false);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [moveButtonTitle, setMoveButtonTitle] = useState("Déplacer");
  // const [moveButtonTitle, setMoveButtonTitle] = useState(<FaHandPaper />);
  const [isMoveLoading, setIsMoveLoading] = useState(false);
  const [showMoveConfirmHourModal, setShowMoveConfirmHourModal] =
    useState(false);
  const [showMoveConfirmModal, setShowMoveConfirmModal] = useState(false);
  const [showTitleConfigModal, setShowTitleConfigModal] = useState(false);
  const [moveSelected, setMoveSelected] = useState(undefined);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [isExportICSLoading, setIsExportICSLoading] = useState(false);
  const [isExportPDFLoading, setIsExportPDFLoading] = useState(false);
  const [isExportXLSLoading, setIsExportXLSLoading] = useState(false);
  const [isMoveSelectedFreeMeeting, setIsMoveSelectedFreeMeeting] =
    useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [planningUuidsUri, setPlanningUuidsUri] = useState("");
  const [viewType, setViewType] = useState("Mois");
  const [config, setConfig] = useState("lotTypo");
  const [isSingleAmo, setIsSingleAmo] = useState(false);
  const [amoMax, setAmoMax] = useState(0);
  const [isSmallLength, setIsSmallLength] = useState(undefined);
  const calendarRef = useRef();
  const [form] = Form.useForm();
  const [titleForm] = Form.useForm();

  const onConfirmMove = async () => {
    let calendarApi = calendarRef.current.getApi();
    let vals = form.getFieldsValue();
    let info = calendarApi.getEventById(moveSelected.id);
    setIsMoveLoading(true);
    if (!isMoveSelectedFreeMeeting) {
      try {
        // Delete request to cancel Rdv on backend
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/unbook/" + info.id,
          {}
        );
      } catch (e) {
        setIsMoveLoading(false);
        console.log(e);
        message.error(
          `Erreur lors du déplacement du rendez-vous du lot ${info.title})`
        );
      }

      try {
        // Axios call to add Rdv
        // need to change planning id according to
        let update = {};
        if (viewType === "Mois") {
          // console.log(`Update MOIS()`);
          let fmtDate =
            parseInt(new Date(vals.selectedDate).setSeconds(0)) / 1000;
          update = {
            selected: info.id,
            rdvDate: fmtDate,
            length:
              parseInt(new Date(info.end).getTime()) -
              parseInt(new Date(info.start).getTime()),
            isMailConfirmation: vals.isMailConfirmation,
          };
          // Since date has been adjusted with form, update it
          // in the fullcalendar as well
          info.setStart(new Date(vals.selectedDate).setSeconds(0));
        } else {
          // console.log(`update autre que MOIS`);
          let calendarApi = calendarRef.current.getApi();
          let info = calendarApi.getEventById(moveSelected.id);
          let fmtDate = parseInt(new Date(info.start).setSeconds(0)) / 1000;
          update = {
            selected: info.id,
            rdvDate: fmtDate,
            length:
              parseInt(new Date(info.end).getTime()) -
              parseInt(new Date(info.start).getTime()),
            isMailConfirmation: vals.isMailConfirmation,
          };
          info.setStart(new Date(info.start).setSeconds(0));
        }

        await axios.patch(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/book-force/" +
            info.extendedProps.planningId,
          update,
          getRdvApiAuth()
        );
        message.success("Rendez-vous correctement déplacé");
      } catch (e) {
        setIsMoveLoading(false);
        console.log(e);
        message.error(
          `Erreur lors du déplacement du rendez-vous du lot ${info.title}). Il à été supprimé mais n'a pas pu être mis à l'endroit demandé`
        );
      }
    } else {
      // console.log(`FREE MEETING MOVE`);
      let newStartDate = undefined;
      if (viewType === "Mois") {
        // console.log(`FREE MEETING Mois`);
        newStartDate = new Date(vals.selectedDate).setSeconds(0);
      } else {
        // console.log(`pass PAS Mois`);
        newStartDate = new Date(selectedDay).setSeconds(0);
      }

      info.setStart(newStartDate);
      let pId = info.extendedProps.planningId;

      let tmpFreeMeetings = plannings.find(
        ({ _id }) => _id === pId
      ).freeMeetings;

      let newFreeMeetings = tmpFreeMeetings.filter(
        (_) => _._id !== moveSelected.id
      );
      let fm = tmpFreeMeetings.find((_) => _._id === moveSelected.id);

      fm = {
        ...fm,
        start: newStartDate,
        end: new Date(
          newStartDate +
            new Date(info.end.setSeconds(0)).getTime() -
            new Date(info.start.setSeconds(0)).getTime()
        ),
      };

      newFreeMeetings.push(fm);

      try {
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + pId,
          { freeMeetings: newFreeMeetings },
          getRdvApiAuth()
        );
        // Goto Rdv day to show rdv
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(newStartDate);

        // await fetchPlanningAndFmtEvents(); // NEW unCOMMENTED
        message.success("RDV Libre correctement deplacé");
      } catch (e) {
        console.log(e);
        message.error("Problème lors du placement du RDV Libre.");
      }
    }

    setIsMoveLoading(false);
    // setMoveButtonTitle(<FaHandPaper />);
    setMoveButtonTitle("Déplacer");
    calendarApi.setOption("editable", false);

    if (viewType === "Mois") {
      setShowMoveConfirmHourModal(false);
    } else {
      setShowMoveConfirmModal(false);
    }
  };

  const onCancelMove = async () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.setOption("editable", false);
    calendarApi.setOption("eventDrop", false);
    calendarApi.setOption("eventDragStop", false);
    // setMoveButtonTitle(<FaHandPaper />);
    setMoveButtonTitle("Déplacer");
    message.success("Déplacement du rendez-vous annulé");

    // Pas terrible de tout refetch mais bon ..
    // await fetchPlanningAndFmtEvents();
    let rdv = calendarApi.getEventById(moveSelected.id);
    rdv.setStart(moveSelected.start);
    rdv.setEnd(moveSelected.end);
    if (viewType === "Mois") {
      setShowMoveConfirmHourModal(false);
    } else {
      setShowMoveConfirmModal(false);
    }
  };

  const handleCancel = () => {
    setShowExportOptions(false);
  };

  const update = async () => {
    let isFormError = false;
    try {
      await titleForm.validateFields(["customTitle"]);
    } catch (e) {
      isFormError = true;
    }

    if (!isFormError) {
      let title = titleForm.getFieldValue("customTitle");
      const transformParams = (input) => {
        input = input.trim();
        input = input.replace("<NOM>", " name ");
        input = input.replace("<TYPOLOGIE>", " typo ");
        input = input.replace("<LOT>", " lot ");
        input = input.replace("<TELEPHONE>", " tel ");
        input = input.split(" ");
        let txt = "";
        input.forEach((i, idx) => {
          if (idx === 0) {
            txt += i;
          } else {
            txt += i.charAt(0).toUpperCase() + i.slice(1);
          }
        });
        return txt.charAt(0).toLowerCase() + txt.slice(1);
      };
      title = transformParams(title);
      let bigFMT = [];
      plannings.forEach((planning) => {
        let flag = transformParams(title);
        let fmt = formatEvents(
          planning,
          flag,
          showFreeMeetings,
          null,
          fmtBankHolidays
        );
        bigFMT.push(fmt);
      });
      let f0s = [];
      let f1s = [];
      bigFMT.forEach((fmt) => {
        f0s = f0s.concat(fmt[0]);
        f1s = f1s.concat(fmt[1]);
      });
      setFmtEvents(f0s);
      setEventSourcetoSend(f1s);
      setShowTitleConfigModal(false);
      setConfig(title);
    }
  };

  useEffect(() => {
    let bigFMT = [];
    let sharedPlanningUri = "";
    let planningMins = [];
    let max = 0;
    plannings.forEach((planning, idx) => {
      planningMins.push(new Date(planning.planningStartTime));
      let fmt = formatEvents(
        planning,
        config,
        showFreeMeetings,
        null,
        fmtBankHolidays
      );
      let tmpMax = getAmoNb(JSON.parse(planning.grid));
      if (tmpMax > max) max = tmpMax;
      bigFMT.push(fmt);
      sharedPlanningUri += planning.uuid;
      if (idx !== plannings.length - 1) sharedPlanningUri += "&";
    });
    setAmoMax(max);
    setPlanningUuidsUri(sharedPlanningUri);
    setIsSmallLength(plannings.some((_) => _.pas === 900));

    let f0s = [];
    let f1s = [];
    bigFMT.forEach((fmt) => {
      f0s = f0s.concat(fmt[0]);
      f1s = f1s.concat(fmt[1]);
    });

    // Calcul de la date de debut d'affichage du calendrier
    // Si des rendez-vous on ete pris, prendre la date du
    // rdv le plus tot
    // Sinon prendre la date de debut du planning le plus tot
    let minDate = undefined;
    if (f0s.length === 0) {
      minDate = new Date(Math.min(...planningMins));
    } else {
      minDate = new Date(
        Math.min(
          ...f0s.map((element) => {
            return new Date(element.start);
          })
        )
      );
    }

    setStartDate(minDate);
    setFmtEvents(f0s);
    setEventSourcetoSend(f1s);
    setIsWeekend(true);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
      // disabledMinutes: () => this.range(30, 60),
      // disabledSeconds: () => [55, 56],
    };
  };

  return (
    <div className="customTopButton">
      {!isLoading && (
        <div>
          {viewType === "Mois" ? (
            <Modal
              open={showMoveConfirmHourModal}
              icon={<ExclamationCircleOutlined />}
              title={`A quelle heure le ${selectedDay} ?`}
              onCancel={onCancelMove}
              footer={[
                <Button key="nonmove" onClick={onCancelMove}>
                  Non
                </Button>,
                <Button
                  key="ouimove"
                  type="primary"
                  loading={isMoveLoading}
                  onClick={onConfirmMove}
                >
                  Oui
                </Button>,
              ]}
            >
              <br />
              <br />
              <Form
                form={form}
                initialValues={{
                  isMailConfirmation: true,
                }}
              >
                <Form.Item
                  name="selectedDate"
                  label="Date de rendez-vous souhaitée"
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message:
                        "Veuillez séléctionner la date à laquelle vous souhaiter placer l'invité",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY HH:mm"
                    disabledTime={disabledDateTime}
                    hideDisabledOptions={true}
                    minuteStep={15}
                    showNow={false}
                  />
                </Form.Item>
                {!isMoveSelectedFreeMeeting && (
                  <Form.Item name="isMailConfirmation" valuePropName="checked">
                    <Checkbox>Mail de confirmation</Checkbox>
                  </Form.Item>
                )}
              </Form>
            </Modal>
          ) : (
            <div id="custom-topbutton">
              <Modal
                open={showMoveConfirmModal}
                icon={<ExclamationCircleOutlined />}
                onCancel={onCancelMove}
                title={
                  isMoveSelectedFreeMeeting
                    ? "Vous souahitez déplacer un RDV Libre dans l'agenda"
                    : "Vous souahitez déplacer un rendez-vous dans l'agenda"
                }
                footer={[
                  <Button key="nonmove" onClick={onCancelMove}>
                    Non
                  </Button>,
                  <Button
                    key="ouimove"
                    type="primary"
                    loading={isMoveLoading}
                    onClick={onConfirmMove}
                  >
                    Oui
                  </Button>,
                ]}
              >
                <br />
                {!isMoveSelectedFreeMeeting && (
                  <div>
                    La personne pour laquelle vous prenez rendez-vous recevra
                    automatiquement un mail de confirmation avec l'heure et la
                    date du rendez-vous. <br /> <br />
                  </div>
                )}
                Confirmez-vous cette action ?
              </Modal>
            </div>
          )}
          <TitlePersonnalizeModal
            show={showTitleConfigModal}
            setShow={setShowTitleConfigModal}
            update={update}
            form={titleForm}
          />
          <ExportModal
            handleCancel={handleCancel}
            showExportOptions={showExportOptions}
            onClickExportICS={onClickExportICS}
            isExportICSLoading={isExportICSLoading}
            setIsExportICSLoading={setIsExportICSLoading}
            onClickExportPDF={onClickExportPDF}
            isExportPDFLoading={isExportPDFLoading}
            setIsExportPDFLoading={setIsExportPDFLoading}
            onClickExportXLS={onClickExportXLS}
            isExportXLSLoading={isExportXLSLoading}
            setIsExportXLSLoading={setIsExportXLSLoading}
            isSingleAmo={isSingleAmo}
            setIsSingleAmo={setIsSingleAmo}
            plannings={plannings}
            eventSourcetoSend={eventSourcetoSend}
            config={config}
            viewType={viewType}
            showFreeMeetings={showFreeMeetings}
            fmtEvents={fmtEvents}
          />
          <FullCalendar
            ref={calendarRef}
            snapDuration={isSmallLength ? "00:15:00" : "00:30:00"}
            locale={frLocale}
            eventOrder="amo"
            allDaySlot={false}
            editable={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              adaptivePlugin,
            ]}
            initialView="dayGridMonth"
            initialDate={startDate}
            slotMinTime="07:00:00"
            slotMaxTime="20:00:00"
            height="auto"
            eventSources={eventSourcetoSend}
            nowIndicator={true}
            weekends={isWeekend}
            schedulerLicenseKey={process.env.REACT_APP_SCHEDULER_LICENCE_KEY}
            datesSet={(dateInfo) => {
              if (calendarRef.current !== undefined) {
                let calendarApi = calendarRef.current.getApi();
                setViewType(viewTransform[calendarApi.view.type]);
              }
            }}
            themeSystem="bootstrap5"
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right:
                "showFreeMeetingButton shareButton exportOptionsButton titleButton moveButton timeGridDay,timeGridWeek,dayGridMonth", //,timeGridFourDay",
            }}
            // views={{
            //   timeGridFourDay: {
            //     type: "timeGrid",
            //     duration: { days: 4 },
            //     buttonText: "4 day",
            //   },
            // }}
            displayEventTime={config === "lotNameTypoTel" ? false : true}
            customButtons={{
              showFreeMeetingButton: {
                text: !showFreeMeetings
                  ? "Montrer RDVs Libres"
                  : "Masquer RDVs Libres",
                click: () => {
                  let bigFMT = [];
                  let planningMins = [];
                  plannings.forEach((planning, idx) => {
                    planningMins.push(new Date(planning.planningStartTime));
                    let fmt = formatEvents(
                      planning,
                      config,
                      !showFreeMeetings,
                      null,
                      fmtBankHolidays
                    );
                    bigFMT.push(fmt);
                  });

                  let f0s = [];
                  let f1s = [];
                  bigFMT.forEach((fmt) => {
                    f0s = f0s.concat(fmt[0]);
                    f1s = f1s.concat(fmt[1]);
                  });

                  // Calcul de la date de debut d'affichage du calendrier
                  // Si des rendez-vous on ete pris, prendre la date du
                  // rdv le plus tot
                  // Sinon prendre la date de debut du planning le plus tot
                  let minDate = undefined;
                  if (f0s.length === 0) {
                    minDate = new Date(Math.min(...planningMins));
                  } else {
                    minDate = new Date(
                      Math.min(
                        ...f0s.map((element) => {
                          return new Date(element.start);
                        })
                      )
                    );
                  }
                  setStartDate(minDate);
                  setFmtEvents(f0s);
                  setEventSourcetoSend(f1s);
                  setShowFreeMeetings(!showFreeMeetings);
                },
              },
              shareButton: {
                text: "Vue partagée",
                click: async () => {
                  window.open(
                    `${process.env.REACT_APP_SITE}/pview/${planningUuidsUri}`,
                    "_blank"
                  );
                },
              },
              exportOptionsButton: {
                text: "Exporter",
                click: async () => {
                  setShowExportOptions(true);
                },
              },
              moveButton: {
                text: moveButtonTitle,
                click: function () {
                  let calendarApi = calendarRef.current.getApi();
                  if (moveButtonTitle === "Terminé") {
                    resetCalendarApiDefaultSettings(calendarApi);
                    setMoveButtonTitle("Déplacer");
                    // setMoveButtonTitle(<FaHandPaper />);
                  } else {
                    if (moveButtonTitle !== "Terminé") {
                      message.warning(
                        "Sélectionnez un rendez-vous et déplacez le à l'endroit souhaité"
                      );
                      setMoveButtonTitle("Terminé");
                      calendarApi.setOption("editable", true);
                      calendarApi.setOption("eventDrop", (info) => {
                        if (info.view.type === "dayGridMonth") {
                          setSelectedDay(dayjs(info.event.start).format("LL"));
                          form.setFieldsValue({
                            selectedDate: dayjs(info.event.start),
                          });
                          setShowMoveConfirmHourModal(true);
                        } else {
                          setSelectedDay(dayjs(info.event.start));

                          setShowMoveConfirmModal(true);
                        }
                      });
                      calendarApi.setOption("eventDragStop", (info) => {
                        let count = (info.event.title.match(/\*/g) || [])
                          .length;
                        if (
                          count === 2 &&
                          info.event.title.startsWith("*") &&
                          info.event.title.endsWith("*")
                        ) {
                          setIsMoveSelectedFreeMeeting(true);
                        } else {
                          setIsMoveSelectedFreeMeeting(false);
                        }
                        // form.setFieldsValue({
                        //   selectedDate: moment(info.event.start),
                        // });
                        setMoveSelected(info.event);
                      });
                    }
                  }
                },
              },
              titleButton: {
                text: "Titre",
                click: function () {
                  setShowTitleConfigModal(true);
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AgendaReadOnlyPrivate;
