import React, { useEffect, useState } from "react";
import axios from "axios";
import { getBasicAuthConfig, getVisitesApiAuth } from "./../utils";
import ContentContainer from "./../components/ContentContainer";
import VisitTableOpen from "./../components/VisitTableOpen";
import { Row, Button, Dropdown, App, Spin } from "antd";
import AddVisitOpen from "./../components/AddVisitOpen";
import AddPromoterOpen from "./../components/AddPromoterOpen";
import ManagePromoterOpen from "./../components/ManagePromoterOpen";
import SubContentContainer from "../components/SubContentContainer";
import CustomPageHeader from "../components/CustomPageHeader";
import {
  CloseCircleOutlined,
  CheckCircleTwoTone,
  EllipsisOutlined,
} from "@ant-design/icons";

const Visites = () => {
  const { message } = App.useApp();
  const [visitList, setVisitList] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  // const [hasVisitLoaded, setHasVisitLoaded] = useState(false);
  // const [hasPromoterListLoaded, setHasPromoterListLoaded] = useState(false);
  const [showAddVisit, setShowAddVisit] = useState(false);
  const [showAddPromoter, setShowAddPromoter] = useState(false);
  const [showManagePromoter, setShowManagePromoter] = useState(false);
  const [promoterList, setPromoterList] = useState([]);
  const [linksTo, setLinksTo] = useState([]);
  const DropdownMenuAdd = (key) => {
    let items = [
      {
        label: <a onClick={() => setShowAddVisit(true)}>Visite</a>,
        key: "more-1",
      },
      {
        label: <a onClick={() => setShowAddPromoter(true)}>Promoteur</a>,
        key: "more-2",
      },
    ];
    const menuProps = {
      items,
      // onClick: (e) => {
      //   handleMenuClick(e);
      // },
    };
    return (
      <Dropdown key={`${key}`} menu={{ items }} placement="bottomRight">
        <Button type="primary" ghost>
          Ajouter
        </Button>
      </Dropdown>
    );
  };

  const DropdownMenuMore = (key) => {
    let items = [
      {
        label: (
          <a onClick={() => setShowManagePromoter(true)}>Gérer promoteurs</a>
        ),
        key,
      },
    ];

    const menuProps = {
      items,
    };

    return (
      <Dropdown key={`${key}`} menu={menuProps} placement="bottomRight">
        <Button
          style={{
            border: "none",
            padding: 0,
          }}
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: "top",
            }}
          />
        </Button>
      </Dropdown>
    );
  };

  const fetchPromoterList = async () => {
    // TODO : Redirect User to Home in catch
    try {
      const res = await axios.get(
        process.env.REACT_APP_VR_API_URL + "/promoters-open",
        getBasicAuthConfig()
      );
      setPromoterList(res.data);

      let tmp = {};
      res.data.forEach((promoter) => {
        tmp[promoter._id] = visitList.filter(
          (visit) =>
            visit.promoter === promoter.name ||
            visit.promoter === promoter.internalName
        ).length;
      });
      setLinksTo(tmp);
    } catch (e) {
      console.log(e);
      message.error(
        "Une erreur est apparue lors du chargement de la liste des promoteurs"
      );
    }
  };

  const fetchVisitList = async () => {
    const config = getVisitesApiAuth({});

    const res = await axios.get(
      process.env.REACT_APP_VR_API_URL + "/vv-open",
      config
    );

    const originData = [];
    for (let i = 0; i < res.data.length; i++) {
      originData.push({
        key: res.data[i]._id,
        name: res.data[i].name,
        uuid: res.data[i].uuid,
        startDate: res.data[i].startDate,
        endDate: res.data[i].endDate,
        email: res.data[i].email,
        pointer: res.data[i].pointer,
        payload: res.data[i].payload,
        promoter: res.data[i].promoter.name,
        sendMail:
          res.data[i].sendMail === true ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleOutlined className="site-result-demo-error-icon" />
          ),
        counter: res.data[i].counter,
        isActive: new Date(res.data[i].endDate) > Date.now(),
      });
    }

    setVisitList(originData);
    // setHasVisitLoaded(true);
  };

  useEffect(() => {
    console.log(`1`);
    const newFunc = async () => {
      await fetchPromoterList();
      await fetchVisitList();
    };
    newFunc();
    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  // const Content = () => {
  //   return (
  //     <div>
  //       {hasPromoterListLoaded && hasVisitLoaded && (
  //         <div>
  //           <AddPromoterOpen
  //             visible={showAddPromoter}
  //             setVisible={setShowAddPromoter}
  //             setHasPromoterListLoaded={setHasPromoterListLoaded}
  //           />
  //           <ManagePromoterOpen
  //             visible={showManagePromoter}
  //             setVisible={setShowManagePromoter}
  //             promoterList={promoterList}
  //             visitList={visitList}
  //             fetchPromoterList={fetchPromoterList}
  //           />
  //           <AddVisitOpen
  //             visible={showAddVisit}
  //             setVisible={setShowAddVisit}
  //             fetchVisitList={fetchVisitList}
  //             promoterList={promoterList}
  //           />
  //         </div>
  //       )}
  //       <Row>
  //         <div style={{ flex: 1 }}>
  //           <VisitTableOpen
  //             visitList={visitList}
  //             setVisitList={setVisitList}
  //             fetchVisitList={fetchVisitList}
  //           />
  //         </div>
  //       </Row>
  //     </div>
  //   );
  // };

  if (!hasLoaded) {
    return (
      <ContentContainer>
        <SubContentContainer>
          <div className="loader-spin">
            <Spin />
          </div>
        </SubContentContainer>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            arrow={true}
            title="Visites virtuelles"
            extra={[
              <DropdownMenuAdd key="add" />,
              <DropdownMenuMore key="more" />,
            ]}
          />
          {/* <Content
            extraContent={
              <img
                src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                alt="content"
                width="100%"
              />
            }
          ></Content> */}
          <AddPromoterOpen
            visible={showAddPromoter}
            setVisible={setShowAddPromoter}
            hasLoaded={hasLoaded}
            setHasLoaded={setHasLoaded}
          />
          <ManagePromoterOpen
            visible={showManagePromoter}
            setVisible={setShowManagePromoter}
            promoterList={promoterList}
            visitList={visitList}
            fetchPromoterList={fetchPromoterList}
          />
          <AddVisitOpen
            visible={showAddVisit}
            setVisible={setShowAddVisit}
            fetchVisitList={fetchVisitList}
            promoterList={promoterList}
          />
          <Row>
            <div style={{ flex: 1 }}>
              <VisitTableOpen
                visitList={visitList}
                setVisitList={setVisitList}
                fetchVisitList={fetchVisitList}
              />
            </div>
          </Row>
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default Visites;
