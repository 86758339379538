import React, { useState, useEffect } from "react";
import ProgramList from "../promoteur/ProgramList";
import {
  App,
  Empty,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Affix,
  Tooltip,
  // Layout,
  Spin,
  // Row,
  // Col,
  Space,
  Checkbox,
} from "antd";
import qs from "qs";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  getBasicAuthConfig,
  generateToken,
  errorCatcher,
  cleanLocalStorage,
} from "../../utils";
import CreateProgramModal from "../../components/plannings/modals/CreateProgramModal";

const AmoPrograms = ({ children }) => {
  const { message } = App.useApp();
  const [selectedFile, setSelectedFile] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [originPrograms, setOriginPrograms] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isArchivedSelected, setIsArchivedSelected] = useState(false);
  const [isActifSelected, setIsActifSelected] = useState(true);
  const [val, setVal] = useState("");
  const [selectedColor, setSelectedColor] = useState("rgb(67, 99, 134)");
  const [defaultColors, setDefaultColors] = useState([
    "rgb(67, 99, 134)",
    "rgb(204, 236, 250)",
    "rgb(1, 2, 3)",
  ]);
  const [isColorPickerHidden, setIsColorPickerHidden] = useState(true);

  const onArchivedChange = (event) => {
    setIsArchivedSelected(event.target.checked);
    setHasLoaded(false);
    fetchUserProgramsFilter(isActifSelected, event.target.checked);
  };

  const onActifChange = (event) => {
    setIsActifSelected(event.target.checked);
    setHasLoaded(false);
    fetchUserProgramsFilter(event.target.checked, isArchivedSelected);
  };

  const fetchUserProgramsFilter = async (actif, archived) => {
    try {
      let query = qs.stringify({
        params: { isActifSelected: actif, isArchivedSelected: archived },
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/amo/programs?${query}`,
        getBasicAuthConfig()
      );

      const res2 = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me/programs?${query}`,
        getBasicAuthConfig()
      );

      let union = res.data.concat(res2.data);
      const ids = union.map((o) => o._id);
      let filtered = union.filter(
        ({ _id }, index) => !ids.includes(_id, index + 1)
      );

      setOriginPrograms(filtered);

      if (val !== "") {
        filtered = filtered.filter(
          (program) =>
            program.name.toLowerCase().includes(val) ||
            program.promotername.toLowerCase().includes(val) ||
            program.adresseville.toLowerCase().includes(val)
        );
      }

      setPrograms(filtered);
      setHasLoaded(true);
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement des filtres programmes`);
    }
  };

  const fetchUserPrograms = async () => {
    // console.log(process.env.NODE_ENV);
    try {
      let query = qs.stringify({
        params: { isActifSelected, isArchivedSelected },
      });

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/amo/programs?${query}`,
        getBasicAuthConfig()
      );

      const res2 = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me/programs?${query}`,
        getBasicAuthConfig()
      );

      let union = res.data.concat(res2.data);
      const ids = union.map((o) => o._id);
      const filtered = union.filter(
        ({ _id }, index) => !ids.includes(_id, index + 1)
      );

      setPrograms(filtered);
      setOriginPrograms(filtered);
      setHasLoaded(true);
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement des programmes`);
    }
  };

  const removeProgram = async (programId) => {
    message.error(`Vous n'avez pas la permission d'effectuer cette opération.`);
    // try {
    //   await axios.delete(
    //     process.env.REACT_APP_API_URL + "/programs/" + programId,
    //     getBasicAuthConfig()
    //   );
    //   localStorage.removeItem("currentProgram");
    //   localStorage.removeItem("currentProgramId");
    //   await fetchPrograms();
    //   message.success("Programme supprimé !");
    // } catch (e) {
    //   console.log(e);
    //   message.error(`Erreur lors de la suppression du programme.`);
    // }
  };

  const createProgram = async (values) => {
    const config = getBasicAuthConfig();
    const colorFounded = defaultColors.find((c) => c === selectedColor);
    let localDefaultColors = defaultColors;
    if (!colorFounded) {
      localDefaultColors = [selectedColor, ...defaultColors];
    } else {
      //color is founded so put it in first position to be the main color
      localDefaultColors = defaultColors.filter((c) => c !== selectedColor);
      localDefaultColors = [selectedColor, ...localDefaultColors];
    }
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/program/create",
        {
          name: values.title,
          promotername: values.promotername,
          clients: undefined,
          colors: isColorPickerHidden
            ? ["rgb(67, 99, 134)", ...defaultColors]
            : localDefaultColors,
          authToken: generateToken(),
        },
        config
      );
      if (selectedFile !== null) {
        const formData = new FormData();
        formData.append("avatar", selectedFile, selectedFile.name);
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            res.data._id +
            "/avatar",
          formData,
          config
        );
      }
      setVisible(false);
      fetchUserPrograms();
      message.success(`Programme ${values.title} créé !`);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(
          `Erreur lors de la création du programme (${e.response.data.error})`
        );
        setVisible(false);
      } else {
        message.error(`Erreur lors de la création du programme (${e})`);
        setVisible(false);
      }
      fetchUserPrograms();
    }
  };

  const onSearchChange = (e) => {
    let searchQuery = e.target.value.toLowerCase();
    setVal(e.target.value.toLowerCase());
    // console.log(e.target.value);
    // console.log(`<${JSON.stringify(val)}>`);

    const newPrograms = originPrograms.filter(
      (program) =>
        program.name.toLowerCase().includes(searchQuery) ||
        program.promotername.toLowerCase().includes(searchQuery) ||
        program.adresseville.toLowerCase().includes(searchQuery)
    );
    setPrograms(newPrograms);
    // console.log(`newPrograms : ${JSON.stringify(newPrograms)}`);
    // searchProgramByText(e, newPrograms);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    cleanLocalStorage();
    const newFunc = async () => {
      if (!hasLoaded) {
        await fetchUserPrograms();
        setHasLoaded(true);
      }
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ margin: "0px 24px 0px 16px" }}>
        <Checkbox onChange={onArchivedChange}>Archivés</Checkbox>
        <Checkbox onChange={onActifChange} defaultChecked={true}>
          Actifs
        </Checkbox>
      </div>

      <div
        style={{
          margin: "24px 24px 0px 24px",
          padding: 0,
          // minHeight: 280,
        }}
      >
        <Affix
          // offsetTop={72}
          offsetTop={84}
          style={{ position: "absolute", top: 0, right: 16 }}
        >
          <Space direction="horizontal">
            <Tooltip placement="leftTop" title="Créer">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setVisible(true);
                }}
              />
              <CreateProgramModal
                visible={visible}
                onCreate={createProgram}
                setSelectedFile={setSelectedFile}
                onCancel={() => {
                  setVisible(false);
                }}
                form={form}
                defaultColors={defaultColors}
                setDefaultColors={setDefaultColors}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                isColorPickerHidden={isColorPickerHidden}
                setIsColorPickerHidden={setIsColorPickerHidden}
              />
            </Tooltip>
            <div>
              <Input placeholder="🔍 Chercher ..." onChange={onSearchChange} />
            </div>
          </Space>
        </Affix>
        {!hasLoaded ? (
          <div className="loader-spin">
            <Spin />
          </div>
        ) : (
          <div>
            {programs.length === 0 ? (
              <Empty
                description={<span>Aucun programme pour le moment.</span>}
              ></Empty>
            ) : (
              <div className="site-card-wrapper">
                <ProgramList
                  programsList={programs}
                  removeProgram={removeProgram}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AmoPrograms;
