import React, { useState, useEffect } from "react";
import { App, Modal, Form, Input, Space, Checkbox, Row, Button } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ShowAlertedModal = ({ show, setShow, planning, setPlanning }) => {
  const { message } = App.useApp();
  const [notifiedList, setNotifiedList] = useState(undefined);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const newFunc = async () => {
      // await fetchAmoList();
      setNotifiedList(planning.notifiedList);
      // await fetchAuthorizedAmos();
      setHasLoaded(true);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePlanningNotified = async () => {
    try {
      let vals = await form.validateFields();
      let allSelected = notifiedList
        .filter((aa) => vals["checkbox-group"].includes(aa.email))
        .map((_) => ({
          firstname: _.firstname,
          lastname: _.lastname,
          email: _.email,
        }));
      if (vals.users !== undefined && vals.users.length > 0) {
        allSelected = allSelected.concat(vals.users);
      }
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
        {
          notifiedList: allSelected,
        },
        getRdvApiAuth()
      );

      setNotifiedList(allSelected);
      await form.resetFields();
      // form.setFieldsValue({
      //   "checkbox-group": allSelected.map((y) => y.email),
      //   users: "",
      // });

      // setNotifiedList(allSelected);
      // await form.resetFields();
      // await form.setFieldsValue({
      //   "checkbox-group": allSelected.map((y) => y.email),
      // });
      setShow(false);

      message.success(
        `Liste des personnes notifiées correctement mis à jour pour ce planning`
      );
    } catch (e) {
      message.error(
        `Une erreur est apparue. Echec de la mise à jour des personne notifiées (${JSON.stringify(
          e
        )})`
      );
      console.log(e);
    }
  };

  return (
    <Modal
      title="Notifications mail en cas d'indisponibilité"
      style={{ top: 40 }}
      open={show}
      onOk={updatePlanningNotified}
      onCancel={() => setShow(false)}
    >
      {hasLoaded && (
        <div>
          <Form
            form={form}
            name="validate_other"
            initialValues={{
              "checkbox-group": notifiedList.map((y) => y.email),
              users: [],
              // "checkbox-group": authorizedAmos
              //   .filter((_) =>
              //     selectedAuthorizedAmos.find((x) => x.email === _.email)
              //   )
              //   .map((y) => y.email),

              // users: authorizedAmos.filter(
              //   (_) => !selectedAuthorizedAmos.find((x) => x.email === _.email)
              // ),
              // users: selectedAuthorizedAmos.filter(
              //   (_) => !authorizedAmos.find((x) => x.email === _.email)
              // ),
            }}
          >
            <Form.Item name="checkbox-group">
              <Checkbox.Group>
                <Row>
                  {/* {JSON.stringify(notifiedList)} */}
                  {/* {JSON.stringify(selectedAuthorizedAmos)} */}
                  {/* {console.log(planning)} */}
                  {notifiedList.map((aa, idx) => {
                    return (
                      <div key={idx}>
                        <Checkbox
                          value={aa.email}
                          style={{ lineHeight: "32px" }}
                        >
                          {`${aa.lastname} ${aa.firstname} (${aa.email})`}
                        </Checkbox>
                        <br />
                      </div>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "firstname"]}
                        rules={[{ required: true, message: "Nom manquant" }]}
                      >
                        <Input placeholder="Nom" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "lastname"]}
                        rules={[{ required: true, message: "Prénom manquant" }]}
                      >
                        <Input placeholder="Prénom" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Email manquant" },
                          {
                            type: "email",
                            message: "L'email saisi n'est pas valide",
                          },
                        ]}
                        required
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Ajouter un notifé
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default ShowAlertedModal;
