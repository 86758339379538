import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import { nanoid } from "nanoid";
import qs from "qs";
import {
  App,
  Space,
  Col,
  Row,
  Typography,
  Button,
  Divider,
  Affix,
  Result,
  Spin,
  Drawer,
  Form,
  Tag,
  Tooltip,
} from "antd";
import { Acquereurs } from "../../../components/plannings/Acquereurs";
import SimulationChart from "../../../components/plannings/SimulationChart";
import {
  getBasicAuthConfig,
  getFormatedClients,
  getAmoAgendas,
  getVisitesApiAuth,
  getTypologies,
  customTags,
  getRdvApiAuth,
} from "../../../utils";
import {
  mTimes,
  originSmsRappel,
  originSmsRelance,
  originMailRappel,
  originMailRelance,
  originMailConfirmation,
  originMailInvitation,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
} from "../../../components/plannings/planningData";
import ContentContainer from "../../../components/ContentContainer";
import VisitTimeSelector from "../../../components/plannings/VisitTimeSelector";
// import TimeSelector from "../../../components/plannings/TimeSelector";
import SelectDays from "../../../components/plannings/SelectDays";
import SelectLunch from "../../../components/plannings/SelectLunch";
import ClientListSummary from "../../../components/ClientListSummary";
import EventListSummary from "../../../components/EventListSummary";
import NotificationAmo from "../../../components/plannings/NotificationAmo";
import Contact from "../../../components/plannings/Contact";
import QuestionOptions from "../../../components/plannings/QuestionOptions";
import SelectBasicInfo from "../../../components/plannings/SelectBasicInfo";
import AgendaReadOnlyNoOptions from "../../../components/AgendaReadOnlyNoOptions";
import PlanningPromoter from "../../../components/plannings/PlanningPromoter";
import PlanningTemplate from "../../../components/plannings/PlanningTemplate";
import SubContentContainer from "../../../components/SubContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";

dayjs.locale("fr");
const { Text, Title } = Typography;

const Plannings = () => {
  const { message, modal } = App.useApp();
  let history = useHistory();
  const location = useLocation();
  const [clientList, setClientList] = useState([]);
  const [promotername, setPromotername] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [phase, setPhase] = useState("choices");
  const [meetingTimes, setMeetingTimes] = useState(mTimes["choices"]);
  const [events, setEvents] = useState(undefined);
  const initialLunchStartTime = "12:30";
  const initialLunchEndTime = "14:00";
  const [isCustomDisabled, setIsCustomDisabled] = useState(true);
  const [notif, setNotif] = useState(false);
  const [visitorsNb, setVisitorsNb] = useState(0);
  const [optionQuestions, setOptionQuestions] = useState([]);
  const [wait, setWait] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);
  const [colors, setColors] = useState(undefined);
  const [amoList, setAmoList] = useState([]);
  const [notifiedList, setNotifiedList] = useState([]);
  const [selectedAmo, setSelectedAmo] = useState([]);
  const [eventHours, setEventHours] = useState(0);
  const [clientHours, setClientHours] = useState(0);
  const [loadings, setLoadings] = useState([]);
  const [simulVisible, setSimulVisible] = useState(false);
  const [simulResData, setSimulResData] = useState(undefined);
  const [simulationTime, setSimulationTime] = useState(0);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [eventSourcestoSend, setEventSourcestoSend] = useState(undefined);
  const [areAmosAssigned, setAreAmosAssigned] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceEntity, setInvoiceEntity] = useState(undefined);
  const [usedBalises, setUsedBalises] = useState("");
  const [filteredClientList, setFilteredClientList] = useState(undefined);
  const [showAll, setShowAll] = useState(false);
  const [typos, setTypos] = useState([]);
  const [form] = Form.useForm();
  const calendarRef = useRef();
  const format = "HH:mm";
  const [messages, setMessages] = useState({
    smsrappelmessage: originSmsRappel,
    smsrelancemessage: originSmsRelance,
    mailrappelmessage: originMailRappel,
    mailrelancemessage: originMailRelance,
    mailconfirmationmessage: originMailConfirmation,
    mailinvitationmessage: originMailInvitation,
  });
  const programId = location.pathname.split("/")[2];

  // Recuperation d'un eventuel id dans l'URI pour savoir
  // si il s'agit d'un brouillon ou non
  let tmp = qs.parse(location.search, { ignoreQueryPrefix: true }).id;
  let id = undefined;
  if (tmp === undefined || tmp === "") {
    id = undefined;
  } else {
    id = tmp;
  }

  const isSmallPas = (len) => parseInt(len) <= 2700000;

  const selectedRows = (rows) => {
    setSelectedRowKeys(rows.map((x) => x._id));
    setSelected(rows);
  };

  const fetchProgramClientList = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "/programs/" + programId;
      const res = await axios.get(url, getBasicAuthConfig());

      setInvoiceEntity(res.data.invoiceEntity);

      let clients = [];
      getFormatedClients(res.data.clients, programId).forEach((_) => {
        clients.push({
          ..._,
          duree: meetingTimes[_.typologie],
        });
      });
      setClientList(clients);
      setFilteredClientList(clients);
      setPromotername(res.data.promotername);
      setAuthToken(res.data.authToken);
      setColors(res.data.colors);

      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      setAmoList(res2.data);

      setNotifiedList(
        res2.data
          .filter((x) => res.data.authorized.includes(x._id))
          .map((x) => {
            return {
              firstname: x.firstname,
              lastname: x.lastname,
              email: x.email,
            };
          })
      );

      form.setFieldsValue({
        adresse: {
          numero: res.data.adressenumero,
          rue: res.data.adresserue,
          codepostal: res.data.adressecodepostal,
          ville: res.data.adresseville,
          pays: res.data.adressepays,
        },
        promoterUrl: res.data.promoterUrl,
        startEndLunch: [
          dayjs(initialLunchStartTime, format),
          dayjs(initialLunchEndTime, format),
        ],
        smsrappelmessage: originSmsRappel,
        smsrelancemessage: originSmsRelance,
        mailrappelmessage: originMailRappel,
        mailrelancemessage: originMailRelance,
        mailconfirmationmessage: originMailConfirmation,
        mailinvitationmessage: originMailInvitation,
        firstParagraph,
        secondParagraph,
        thirdParagraph,
      });
    } catch (e) {
      message.error(`Le projet demandé n'existe pas.`);
      console.log(e);
    }
  };

  const fetchBrouillon = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_RDV_API_URL + "/rdv-draft/" + id,
        getRdvApiAuth()
      );

      const planningDraft = response.data.planningDraft;
      // console.log("planningDraft");
      // console.log(JSON.stringify(planningDraft));

      form.setFieldsValue({
        mailinvitationmessage: planningDraft.mailinvitationmessage,
        mailconfirmationmessage: planningDraft.mailconfirmationmessage,
        smsrelancemessage: planningDraft.smsrelancemessage,
        smsrappelmessage: planningDraft.smsrappelmessage,
        mailrelancemessage: planningDraft.mailrelancemessage,
        mailrappelmessage: planningDraft.mailrappelmessage,
        rdvName: planningDraft.name,
        adresse: {
          numero: planningDraft.address.numero,
          rue: planningDraft.address.rue,
          codepostal: planningDraft.address.codepostal,
          ville: planningDraft.address.ville,
          pays: planningDraft.address.pays,
        },
        promoterUrl: planningDraft.promoterUrl,
        bookingDeadline: dayjs(planningDraft.bookingDeadline), //moment(planningDraft.bookingDeadline),
        contactNom: planningDraft.contactNom,
        contactPrenom: planningDraft.contactPrenom,
        contactTitre: planningDraft.contactTitre,
        contactMail: planningDraft.contactMail,
        contactTelephone: planningDraft.contactTelephone,
        signature: planningDraft.signature,
        firstParagraph: planningDraft.firstParagraph,
        secondParagraph: planningDraft.secondParagraph,
        thirdParagraph: planningDraft.thirdParagraph,
        startEndLunch: [
          dayjs(
            dayjs(
              new Date(moment(planningDraft.lunchStartTime, "HH:mm")).getTime()
            )
          ),
          dayjs(
            dayjs(
              new Date(moment(planningDraft.lunchEndTime, "HH:mm")).getTime()
            )
          ),
        ],
        colors: planningDraft.projectColors,
      });

      // console.log(moment(planningDraft.lunchStartTime, format));

      let bigStr =
        planningDraft.mailinvitationmessage +
        planningDraft.mailconfirmationmessage +
        planningDraft.smsrelancemessage +
        planningDraft.smsrappelmessage +
        planningDraft.mailrelancemessage +
        planningDraft.mailconfirmationmessage;
      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));
      // Please not that usedBalises are only the one that matters for Contact, not all !
      setUsedBalises(balises);

      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + programId,
        getBasicAuthConfig()
      );
      setInvoiceEntity(res.data.invoiceEntity);

      // Temps pre-definis a partir du draft
      setMeetingTimes(planningDraft.mTimes);

      // Ouverture du switch Visites Virtuelles si avait ete modifie
      setIsCustomDisabled(!planningDraft.isVrPromoterChecked);

      // Set de la phase
      setPhase(planningDraft.phase);

      // Set des events
      // console.log(planningDraft.events);
      setEvents(planningDraft.events);

      // Set questions & maxVisitorsNb
      setOptionQuestions(planningDraft.questions);
      // setOriginQuestions(planningDraft.questions);
      setVisitorsNb(planningDraft.maxVisitorsNb);

      // SelectedAmos
      setSelectedAmo(planningDraft.amoList);

      //set projectColors
      setColors(planningDraft.projectColors);

      // Fichier client normal et duree a partir de clientListLengths
      let clientListLengths = JSON.parse(planningDraft.clientListLengths);
      let clients = [];

      getFormatedClients(res.data.clients, programId).forEach((_) => {
        clients.push({
          ..._,
          duree:
            _._id in clientListLengths
              ? clientListLengths[_._id]
              : mTimes[planningDraft.phase][_.typologie],
        });
      });
      setClientList(clients);
      setFilteredClientList(clients);
      // Set des acquereurs selectionnes
      let sr = clients.filter((clt) =>
        planningDraft.selectedRowKeys.includes(clt._id)
      );
      selectedRows(sr);
      setTypos(getTypologies(sr));
      // setSelectedRowKeys(selectedRowKeys);
      setSelected(sr);
      // setTypos(getTypologies(selectedRows));
      // setAuthorizedAmo(res.data.authorized);
      setPromotername(res.data.promotername);
      setAuthToken(res.data.authToken);

      const res2 = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      setAmoList(res2.data);

      setNotifiedList(
        res2.data
          .filter((x) => res.data.authorized.includes(x._id))
          .map((x) => {
            return {
              firstname: x.firstname,
              lastname: x.lastname,
              email: x.email,
            };
          })
      );
    } catch (e) {
      message.error(`Impossible de charger le brouillon.`);
      console.log(e);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchProgramClientList();

      // form.setFieldsValue({
      //   mailInvitation: originMailInvitation,
      //   mailConfirmation: originMailConfirmation,
      //   smsRelance: originSmsRelance,
      //   smsRappel: originSmsRappel,
      //   mailRelance: originMailRelance,
      //   mailRappel: originMailRappel,
      //   firstParagraph,
      //   secondParagraph,
      //   thirdParagraph,
      // });

      let bigStr =
        originMailRelance +
        originMailRappel +
        originSmsRelance +
        originSmsRappel +
        originMailInvitation +
        originMailConfirmation;
      let balises = bigStr.match(/<(.+?)>/g) || [];
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      balises = Array.from(new Set(balises));
      // Please not that usedBalises are only the one that matters for Contact, not all !
      setUsedBalises(balises);

      setIsLoading(false);
    };

    const newFuncBrouillon = async (id) => {
      await fetchBrouillon(id);
      setIsLoading(false);
    };

    if (id === undefined) {
      // console.log(`CREATION FROM SCRATCH, ID = ${id}`);
      newFunc();
    } else {
      // console.log(`CREATION A PARTIR DE BROUILLON, ID = ${id}`);
      newFuncBrouillon();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(`useEffect())))))`);
    let clients = [];
    // let lengths = [];
    clientList.forEach((_) => {
      // lengths.push(meetingTimes[_.typologie]);
      clients.push({
        ..._,
        duree: meetingTimes[_.typologie],
      });
    });

    setClientList(clients);
    setFilteredClientList(clients);
    let newSelected = [];
    selected.forEach((row) => {
      let newRow = {
        ...row,
        duree: meetingTimes[row.typologie],
      };
      newSelected.push(newRow);
    });
    setSelected(newSelected);
    // setTypos(newSelected.map((x) => x.typologie)); // A ETE RETIRE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingTimes]);

  const setRdv = async () => {
    try {
      let isCreatePromoterError = false;
      let isFormError = false;
      let newPromoterId = undefined;

      if (
        areAmosAssigned &&
        Math.max(...events.map((_) => parseInt(_.nbAmo))) !== selectedAmo.length
      ) {
        message.warning(
          "Veuillez vérifier les informations sur les effectifs."
        );
        return;
      }

      // Need to put val below under try/catch
      let val = undefined;
      // console.log("add");

      try {
        val = await form.validateFields();
        // console.log(`VAL VAL VAL :::: ${JSON.stringify(val)}`);
        // if (new Date() > new Date(val.bookingDeadline)) {
        //   message.error(
        //     "Attention: la date de fin de prises de rendez-vous doit être supérieure à la date d'aujoutd'hui, sinon vos acquéreurs ne peuvent pas prendre rendez-vous."
        //   );
        //   return;
        // }
      } catch (e) {
        message.error(
          `Veuillez revoir le formulaire. Des informations sont manquantes.`
        );
        isFormError = true;
        // console.log(`RETURN`);
      }

      if (!isFormError) {
        try {
          // Create promoter
          let config = getVisitesApiAuth();
          const res = await axios.post(
            process.env.REACT_APP_VR_API_URL + "/promoter/create",
            {
              name: `${promotername}`,
              internalName: `${promotername} - ${localStorage.getItem(
                "currentProgram"
              )} - ${phase} - ${val.rdvName}`,
              firstParagraph: val.firstParagraph,
              secondParagraph: val.secondParagraph,
              thirdParagraph: val.thirdParagraph,
            },
            config
          );

          // if (!isCustomDisabled || !selectedFile !== null) {
          if (res.data) {
            newPromoterId = res.data._id;
            if (selectedFile !== null && selectedFile !== undefined) {
              const formData = new FormData();
              formData.append("logo", selectedFile, selectedFile.name);
              await axios.post(
                process.env.REACT_APP_VR_API_URL +
                  "/promoters/" +
                  res.data._id +
                  "/logo",
                formData,
                config
              );
            }
          }

          // }
        } catch (e) {
          isCreatePromoterError = true;
          // Remove promoter because there has been an error when uploading logo
          await axios.delete(
            process.env.REACT_APP_VR_API_URL + "/promoter/" + newPromoterId,
            getVisitesApiAuth()
          );
          if (e.response && e.response.data.error) {
            message.error(
              `Erreur lors de l'envoi du logo pour les visites 3D : ${e.response.data.error}`
            );
          } else {
            message.error(
              `Echec lors de la création du nouveau planning. (${e})`
            );
          }
        }

        if (!isCreatePromoterError) {
          try {
            // return;
            const formatedSelected = [];

            let lengths = [];
            selected.forEach((x) => {
              let mel = undefined;
              if (x.mailmonsieur) {
                mel = x.mailmonsieur;
              } else if (x.mailmadame) {
                mel = x.mailmadame;
              } else {
                mel = x.email;
              }
              let obj = {
                uuid: nanoid(9),
                email: mel,
                nom: x.firstname + " " + x.lastname,
                telephonemonsieur: x.telephonemonsieur,
                telephonemadame: x.telephonemadame,
                mailmonsieur: x.mailmonsieur,
                mailmadame: x.mailmadame,
                lotcomm: x.lotcomm,
                etage: x.etage,
                batiment: x.batiment,
                typologie: x.typologie,
                notifMail: true,
                notifSMS: true,
                invitationMail: [],
                relancesMail: [],
                relancesSMS: [],
                rappelsMail: [],
                rappelsSMS: [],
                rdvLength: x.duree,
                isActive: true,
                isBooked: false,
                observations: "",
              };
              formatedSelected.push(obj);
              lengths.push(x.duree);
            });

            // Ajuste le pas au quart d'heure dans le cas de 15 min et 45 min
            let pas = 1800; // 30 min par defaut
            if (lengths.some(isSmallPas)) {
              console.log("pas is Small pas");
              pas = 900;
            }
            // if (phase === "custom") {
            //   pas = 900;
            // }

            let finalObj = {
              name: val.rdvName,
              uuid: nanoid(9),
              promoter: promotername,
              promoterUrl: val.promoterUrl,
              phase,
              launchDate: val.launchDate,
              authorizeTrous: true,
              bookingDeadline: val.bookingDeadline,
              lunchStartTime: new Date(val.startEndLunch[0])
                .toLocaleTimeString()
                .slice(0, 5),
              lunchEndTime: new Date(val.startEndLunch[1])
                .toLocaleTimeString()
                .slice(0, 5),
              nbSolutions: 1,
              events,
              mTimes: meetingTimes,
              // mailrappelmessage: val.mailRappel,
              // smsrappelmessage: val.smsRappel,
              // smsrelancemessage: val.smsRelance,
              // mailrelancemessage: val.mailRelance,
              // mailconfirmationmessage: val.mailConfirmation,
              // mailinvitationmessage: val.mailInvitation,

              mailrappelmessage: messages.mailrappelmessage,
              smsrappelmessage: messages.smsrappelmessage,
              smsrelancemessage: messages.smsrelancemessage,
              mailrelancemessage: messages.mailrelancemessage,
              mailconfirmationmessage: messages.mailconfirmationmessage,
              mailinvitationmessage: messages.mailinvitationmessage,

              contactTitre: val.contactTitre,
              contactNom: val.contactNom,
              contactPrenom: val.contactPrenom,
              contactMail: val.contactMail,
              contactTelephone: val.contactTelephone,
              signature: val.signature,
              templateType: "promoter", // to be variabalized
              questions:
                visitorsNb > 0
                  ? optionQuestions.concat("Accompagnants")
                  : optionQuestions,
              maxVisitorsNb: visitorsNb,
              notifications: {
                Mail: true,
                SMS: true,
              },
              areAmosAssigned,
              notifiedList: notifiedList,
              amoList: selectedAmo,
              broadcastList: formatedSelected,
              authToken,
              address: val.adresse,
              projectId: programId,
              projectName: localStorage.getItem("currentProgram"),
              projectColors: colors,
              invoiceEntity,
              pas,
            };

            // console.log(`finalObj : ${JSON.stringify(finalObj)}`);
            // return;

            // TODO : Possibilite de spread 'val' ci-dessous
            await axios.post(
              process.env.REACT_APP_RDV_API_URL + "/rdv/",
              finalObj
            );

            if (id !== undefined) {
              // Destruction du brouillon
              console.log(`Destruction du brouillon`);
              await axios.delete(
                `${process.env.REACT_APP_RDV_API_URL}/rdv-draft/${id}`
              );
            }

            history.push("/projets/" + programId + "/plannings");

            message.success("🚀 Nouveau planning créé avec succès");
          } catch (e) {
            console.log(e);
            if (e.response && e.response.data.error) {
              if (e.response.data.message.startsWith("E11000")) {
                message.error(
                  `Echec lors de la création du nouveau planning. Le nom existe déjà, veuillez en sélectionner un autre.`
                );
              } else {
                message.error(
                  `Echec lors de la création du nouveau planning. (${e.response.data.message})`
                );
              }
            } else if (
              e.response &&
              e.response.data.errors &&
              Object.keys(e.response.data.errors).includes("address.numero")
            ) {
              message.error(
                `Veuillez renseigner un chiffre ou un nombre pour le numero de rue, "${e.response.data.errors["address.numero"].value}" n'est pas valide.`
              );
            } else {
              message.error(`Echec lors de la création du nouveau planning.`);
            }
            setWait(false);
          }
        }
      }
    } catch (e) {
      console.log(e);
      // console.log("END");
      message.error("Veuillez vérifier le formulaire");
    }
  };

  const setBrouillon = async () => {
    // let val = await form.validateFields();
    // console.log("getBrouillon OOOO");

    // HERE TO PUT

    // let pas = 1800; // 30 min par defaut
    // if (lengths.some(isSmallPas)) {
    //   console.log(`SMALL PAS`);
    //   pas = 900;
    // } else {
    //   console.log(`PAS NORMAL`);
    //   // pas = 1800;
    // }

    let val = await form.getFieldsValue();
    // console.log(`vals --> ${JSON.stringify(val)}`);
    let clientListLengths = {};
    clientList.forEach((client) => {
      clientListLengths[client._id] = client.duree;
    });
    clientListLengths = JSON.stringify(clientListLengths);
    // console.log(`afterclient List`);

    try {
      let finalObj = {
        name: val.rdvName,
        promoter: promotername,
        promoterUrl: val.promoterUrl,
        phase,
        selectedRowKeys,
        launchDate: val.launchDate,
        bookingDeadline: val.bookingDeadline,
        lunchStartTime: new Date(val.startEndLunch[0])
          .toLocaleTimeString()
          .slice(0, 5),
        lunchEndTime: new Date(val.startEndLunch[1])
          .toLocaleTimeString()
          .slice(0, 5),
        events,
        mTimes: meetingTimes,
        // mailrappelmessage: val.mailRappel,
        // smsrappelmessage: val.smsRappel,
        // smsrelancemessage: val.smsRelance,
        // mailrelancemessage: val.mailRelance,
        // mailconfirmationmessage: val.mailConfirmation,
        // mailinvitationmessage: val.mailInvitation,
        mailrappelmessage: messages.mailrappelmessage,
        smsrappelmessage: messages.smsrappelmessage,
        smsrelancemessage: messages.smsrelancemessage,
        mailrelancemessage: messages.mailrelancemessage,
        mailconfirmationmessage: messages.mailconfirmationmessage,
        mailinvitationmessage: messages.mailinvitationmessage,
        contactTitre: val.contactTitre,
        contactNom: val.contactNom,
        contactPrenom: val.contactPrenom,
        contactMail: val.contactMail,
        contactTelephone: val.contactTelephone,
        firstParagraph: val.firstParagraph,
        secondParagraph: val.secondParagraph,
        thirdParagraph: val.thirdParagraph,
        isVrPromoterChecked: !isCustomDisabled,
        signature: val.signature,
        templateType: "promoter",
        questions:
          visitorsNb > 0 && !optionQuestions.includes("Accompagnants")
            ? optionQuestions.concat("Accompagnants")
            : optionQuestions,
        maxVisitorsNb: visitorsNb,
        amoList: selectedAmo,
        address: val.adresse,
        projectId: programId,
        projectName: localStorage.getItem("currentProgram"),
        projectColors: colors,
        clientListLengths,
      };
      // console.log("final OBJ");
      // console.log(finalObj);
      // return;
      // TODO : Possibilite de spread 'val' ci-dessous

      if (id !== undefined) {
        // Si id existe deja -> PATCH avec l'id existant
        let res = await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv-draft/" + id,
          finalObj
        );
        console.warn(`WARNING FROM SIMON : ${res.data}`);
      } else {
        // Si id existe deja -> POST
        await axios.post(
          process.env.REACT_APP_RDV_API_URL + "/rdv-draft/",
          finalObj
        );
      }

      history.push("/projets/" + programId + "/plannings");
      message.success("🧩 Nouveau brouillon sauvegardé");
    } catch (e) {
      if (e.response && e.response.data.error) {
        if (e.response.data.message.startsWith("E11000")) {
          message.error(
            `Echec lors de la création du brouillon. Le nom existe déjà, veuillez en sélectionner un autre.`
          );
        } else {
          message.error(
            `Echec lors de la création du brouillon. (${e.response.data.message})`
          );
        }
      } else {
        message.error(`Echec lors de la création du brouillon. (${e})`);
      }
      if (Object.keys(e.response.data.errors).includes("address.numero")) {
        message.error(
          `Veuillez renseigner un chiffre ou un nombre pour le numero de rue, "${e.response.data.errors["address.numero"].value}" n'est pas valide.`
        );
      } else {
        message.error(`Echec lors de la création du brouillon.`);
      }
      setWait(false);
    }
  };

  const showDrawer = () => {
    setSimulVisible(true);
  };

  const onClose = () => {
    setSimulVisible(false);
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const enterLoading = async (index) => {
    // const isSimulAuthorized = (clientList) => {
    //   let lengthsSet = Array.from(new Set(clientList.map((e) => e.duree)));
    //   let clientListLengths = clientList.map((_) => _.duree);
    //   console.log(`lengthSet : ${lengthsSet}`);
    //   if (lengthsSet.some(isSmallPas)) {
    //     console.log(`clientListLength : ${clientList.length}`);
    //     // Simulation tous les quarts d'heure
    //     // var sum = clientListLengths.reduce((accumulator, currentValue) => {
    //     //   return accumulator + currentValue;
    //     // }, 0);
    //     // console.log(`SUM : ${sum}`);
    //   } else {
    //     // Simulation normale
    //   }
    // };

    // TODO : Finish here
    // isSimulAuthorized(clientList);

    // return;

    try {
      await form.validateFields(["startEndLunch"]);
    } catch (e) {
      console.log(e);
      message.error(`Veuillez renseigner un horaire de pause déjeuner SVP`);
      return;
    }

    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setWait(true);
    try {
      const formatedSelected = [];
      let lengths = [];
      selected.forEach((x) => {
        let obj = {
          uuid: nanoid(9), //uuidv4(),
          email: x.mailmonsieur ? x.mailmonsieur : x.mailmadame, // TODO: Remove condition
          nom: x.nom,
          telephonemonsieur: x.telephonemonsieur,
          telephonemadame: x.telephonemadame,
          mailmonsieur: x.mailmonsieur,
          mailmadame: x.mailmadame,
          lotcomm: x.lotcomm,
          etage: x.etage,
          batiment: x.batiment,
          notifMail: true,
          notifSMS: true,
          invitationMail: [],
          relancesMail: [],
          relancesSMS: [],
          rappelsMail: [],
          rappelsSMS: [],
          rdvLength: x.duree,
        };
        lengths.push(x.duree);
        formatedSelected.push(obj);
      });

      // Ajuste le pas au quart d'heure dans le cas de 15 min et 45 min
      let pas = 1800; // 30 min par defaut
      if (lengths.some(isSmallPas)) {
        pas = 900;
      }

      const simulConf = {
        authorizeTrous: true,
        nbSolutions: 1,
        pas,
        lunchStartTime: new Date(form.getFieldValue("startEndLunch")[0])
          .toLocaleTimeString()
          .slice(0, 5),
        lunchEndTime: new Date(form.getFieldValue("startEndLunch")[1])
          .toLocaleTimeString()
          .slice(0, 5),
        events,
        mTimes: meetingTimes,
        amoList: selectedAmo,
        broadcastList: formatedSelected,
      };

      console.log(simulConf);
      let res = undefined;
      if (pas === 900) {
        res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            "no-need" +
            "/short-simulation", // CHANGE ???!!!
          simulConf
        );
      } else {
        res = await axios.post(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/" +
            "no-need" +
            "/simulation", // CHANGE ???!!!
          simulConf
        );
      }

      // console.log(res.data);

      const newLoadings = [...loadings];
      newLoadings[index] = false;
      setLoadings(newLoadings);
      setSimulResData(res.data.simulationData); // undefined when short simul
      setSimulationTime(res.data.simulationTime);

      // if (res.data.simulationData.length === 1 && pas === 900) {
      //   message.warning(
      //     `Rendez-vous de courte durée. Simulation possible mais volontairement arrêtée au premier RDV.`
      //   );
      // }

      // if (res.data.simulationData === undefined && pas === 900) {
      //   message.warning(
      //     `Rendez-vous de courte durée. Simulation possible mais volontairement arrêtée au premier RDV.`
      //   );
      // }

      // Prepare simulation events to send to fullcalendar
      let eventSourcestoSend = [];

      let colors = [
        "#436386", // bleu
        "#b7695d", // rouille
        "#96c0b7",
        "#d4dfc7",
      ];
      const amoAgendas = getAmoAgendas([res.data.S], 1800000 / 1000);
      // const filled = getBookedDates(amoAgendas);
      amoAgendas.map((solvedEvent, idx, arr) => {
        let events = solvedEvent.map((evt) => {
          return {
            title: "",
            start: evt.start,
            end: evt.end,
            booked: evt.booked,
          };
        });
        eventSourcestoSend.push({
          events: events.filter((e) => e.booked),
          color: colors[idx],
        });
      });
      setEventSourcestoSend(eventSourcestoSend);
      setWait(false);

      showDrawer();
      // console.log("renderSimul()");
      const customTitle = document.querySelector(".fc-toolbar-title");

      ReactDOM.render(
        <Tag color="success">
          Temps de la simulation : {res.data.simulationTime} secondes
        </Tag>,
        customTitle
      );
    } catch (e) {
      console.log(`Erreur : ${e}`);

      if (
        e.response &&
        e.response.data.statusCode &&
        e.response.data.statusCode === 408
      ) {
        console.log(e.response.data.statusCode);
        modal.error({
          title: "Timeout",
          content: (
            <div>
              Simulation trop longue. Réduisez la complexité (nombre de
              participants / durees ...). Pour les rendez-vous de 30' ou 45', le
              planning ne peut pas depasser 5 jours.
            </div>
          ),
          forceRender: true,
        });
      } else {
        modal.error({
          title: "Pas de solution",
          content: (
            <div>
              Impossible de placer les rendez-vous sur les créneaux horaires
              renseignés. Veuillez ajouter des plages horaires disponibles ou
              réduire la durée des visites. Veuillez egalement essayer de faire
              varier la pause déjeuner.
            </div>
          ),
          forceRender: true,
        });
      }

      setWait(false);
    }
    // }
  };

  if (isLoading) {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            title="Création d'un nouveau planning"
            arrow={true}
          ></CustomPageHeader>
          <div className="loader-spin">
            <Spin />
          </div>
        </SubContentContainer>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            title="Création d'un nouveau planning"
            arrow={true}
          ></CustomPageHeader>
          <Drawer
            width="80%"
            closable={false}
            onClose={onClose}
            open={simulVisible}
          >
            <AgendaReadOnlyNoOptions
              eventSources={eventSourcestoSend}
              showChildrenDrawer={showChildrenDrawer}
              simulationTime={simulationTime}
              calendarRef={calendarRef}
            />
            <Drawer
              title={
                <Tag color="success">
                  Choix proposés ( x : No de la personne qui prend rdv - y :
                  Nombre de choix)
                </Tag>
              }
              width="80%"
              closable={false}
              onClose={onChildrenDrawerClose}
              open={childrenDrawer}
            >
              {simulVisible && <SimulationChart data={simulResData} />}
            </Drawer>
          </Drawer>

          <Row>
            <Col span={1}></Col>
            <Col span={19}>
              {!isLoading && (
                <VisitTimeSelector
                  form={form}
                  meetingTimes={meetingTimes}
                  setMeetingTimes={setMeetingTimes}
                  orgMeetingTimes={mTimes}
                  phase={phase}
                  setPhase={setPhase}
                  planningType="promoter"
                />
              )}
              <br />

              <Divider dashed />
              <Title level={4}>Acquéreurs</Title>
              <Text>
                Le temps des visites par acquéreur a été pré-rempli dans le
                tableau ci-dessous en fonction de la typologie de l'appartement.
                <br />
                Vous pouvez si besoin modifier la durée de la visite pour chacun
                des acquéreurs ci-dessous.
                <br />
                Enfin, sélectionnez les acquéreurs avec qui vous souhaitez
                prendre rendez-vous.
              </Text>
              <br />
              <br />

              {!isLoading && (
                <Acquereurs
                  clientList={clientList}
                  setClientList={setClientList}
                  selected={selected}
                  setSelected={setSelected}
                  typos={typos}
                  setTypos={setTypos}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  filteredClientList={filteredClientList}
                  setFilteredClientList={setFilteredClientList}
                  showAll={showAll}
                  setShowAll={setShowAll}
                />
              )}
              <br />
              <Divider dashed />
              <Title level={4}>Horaire des visites</Title>
              <Text>
                Choisissez la période (début/fin) des visites sur site.
              </Text>
              <br />
              <Text>
                Chaque jour, réglez si besoin la plage horaire des visites
                effectuées par vos AMO(s)
                <br /> ainsi que le nombre de personnes nécessaires pour
                effectuer ces visites (par défaut 1 personne/jr).
              </Text>
              <br />
              <br />
              {!isLoading && (
                <SelectDays
                  events={events}
                  setEvents={setEvents}
                  selected={selected}
                  // lunchStartTime={lunchStartTime}
                  // lunchEndTime={lunchEndTime}
                  form={form}
                />
              )}
              <br />
              <Divider dashed />
              <Title level={4}>Pause déjeuner</Title>
              <br />
              <Text>
                Vos AMO(s) on besoin de se nourrir ! Réglez ici le temps de leur
                pause déjeuner.
              </Text>
              <br />
              <br />
              <SelectLunch
                form={form}
                events={events}
                format={format}
                eventHours={eventHours}
                setEventHours={setEventHours}
              />
              <br />

              <Divider dashed />
              <Title level={4}>
                Personnaliser la page de prise de rendez-vous
              </Title>
              <Text>
                Séléctionnez ci-dessous les questions posées à l'acquéreur :
              </Text>
              <br />
              <br />
              <br />
              {!isLoading && (
                <QuestionOptions
                  setOptionQuestions={setOptionQuestions}
                  visitorsNb={visitorsNb}
                  setVisitorsNb={setVisitorsNb}
                  optionQuestions={optionQuestions}
                />
              )}
              <br />
              <br />
              <Divider dashed />
              <Title level={4}>Effectifs</Title>
              <Text>
                Séléctionnez le(s) responsable(s) qui effectueront les visites.
                <br />
                <Text strong>Les premières personnes sont prioritaires.</Text>
                <br />
                <br />
                <Text italic>
                  Note : Les coordonnées du premier AMO de la liste seront mises
                  par défaut dans les mails/sms de rappels/relances.
                </Text>
              </Text>
              <br />
              <br />
              {!isLoading && (
                <NotificationAmo
                  amoList={amoList}
                  selectedAmo={selectedAmo}
                  setSelectedAmo={setSelectedAmo}
                  areAmosAssigned={areAmosAssigned}
                  form={form}
                  setNotifiedList={setNotifiedList}
                />
              )}
              <br />
              <Divider dashed />
              <Title level={4}>
                Personnalisation des Rappel/Relances Mail et SMS
              </Title>
              <Text>
                Cliquez sur le canal de communication pour lequel vous souhaitez
                changer le message
              </Text>
              <br />
              <br />

              <PlanningTemplate
                form={form}
                setUsedBalises={setUsedBalises}
                planning={{}}
                messages={messages}
                setMessages={setMessages}
                request={false}
              />

              <br />

              <Divider dashed />
              <Title level={4}>Personne contact</Title>
              <Text>Une étoile &nbsp;</Text>
              <Text type="danger">*</Text>
              <Text>
                &nbsp;apparait lorsque vous avez utilisé la balise
                correspondante dans la Personnalisation des Rappel/Relances Mail
                et SMS ci-dessus.
              </Text>
              <br />
              <br />
              {!isLoading && <Contact form={form} usedBalises={usedBalises} />}
              <Divider dashed />

              <Title level={4}>Visites virtuelles</Title>
              <Text>
                Personnalisez le texte qui apparait sur le lien de la visite
                virtuelle
              </Text>
              <br />
              <br />
              {!isLoading && (
                <PlanningPromoter
                  form={form}
                  setSelectedFile={setSelectedFile}
                  isCustomDisabled={isCustomDisabled}
                  setIsCustomDisabled={setIsCustomDisabled}
                />
              )}
              {/* <SelectParamsAlgo setParams={setParams} params={params} /> */}

              <Divider dashed />
              <Title level={4}>
                Nom et date d'envoi, date limite de prise des rendez-vous
              </Title>
              <Text>
                Sélectionnez un nom ainsi que la date d'envoi souhaitée des
                rendez-vous. Spécifiez combien de temps avant le début des
                visites il est encore possible de prendre rendez-vous.
              </Text>
              <br />
              <br />
              <SelectBasicInfo form={form} notif={notif} setNotif={setNotif} />
              {/* )} */}

              <br />
              <br />
              <br />
              <Space direction="horizontal">
                {eventHours !== undefined &&
                clientHours !== undefined &&
                eventHours - clientHours >= 0 ? (
                  <Button type="primary" onClick={setRdv}>
                    Créer le Planning
                  </Button>
                ) : (
                  <Tooltip
                    placement="top"
                    title={`Impossible de créer le planning dans ces conditions. Le nombre d'heures disponibles doit être supérieur au nombre d'heures nécessaires.`}
                  >
                    <Button onClick={setRdv} disabled>
                      Créer le Planning
                    </Button>
                  </Tooltip>
                )}
                <Button onClick={setBrouillon}>Enregistrer en brouillon</Button>
              </Space>
              <br />
              <br />
              <br />
            </Col>
            <Col span={4}>
              {!isLoading && (
                <Affix
                  offsetTop={124}
                  style={{ position: "absolute", top: 0, left: 30 }}
                >
                  {eventHours !== undefined &&
                  clientHours !== undefined &&
                  eventHours - clientHours >= 0 ? (
                    <Space direction="vertical">
                      <Result
                        status="success"
                        style={{ paddingBottom: "0px" }}
                      />
                      <Button type="primary">
                        <ClientListSummary
                          clients={selected}
                          meetingTimes={meetingTimes}
                          clientHours={clientHours}
                          setClientHours={setClientHours}
                        />
                      </Button>
                      <Button type="primary">
                        <EventListSummary
                          form={form}
                          events={events}
                          eventHours={eventHours}
                          setEventHours={setEventHours}
                        />
                      </Button>
                      <Button
                        type="primary"
                        loading={wait}
                        onClick={() => enterLoading(0)}
                      >
                        Simulation
                      </Button>
                    </Space>
                  ) : (
                    <div>
                      <Space direction="vertical">
                        <Result
                          status="warning"
                          style={{ paddingBottom: "0px" }}
                        />
                        <Button type="ghost">
                          <ClientListSummary
                            clients={selected}
                            meetingTimes={meetingTimes}
                            clientHours={clientHours}
                            setClientHours={setClientHours}
                          />
                        </Button>
                        <Button type="ghost">
                          <EventListSummary
                            form={form}
                            events={events}
                            eventHours={eventHours}
                            setEventHours={setEventHours}
                          />
                        </Button>
                        <Button
                          loading={loadings[0]}
                          onClick={() =>
                            message.error(
                              `Impossible de lancer une simulation. Le Disponible doit être supérieur au Nécessaire.`
                            )
                          } //enterLoading(0)}
                        >
                          Simulation
                        </Button>
                      </Space>
                    </div>
                  )}
                </Affix>
              )}
            </Col>
          </Row>
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default Plannings;
