import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Tag,
  Space,
  TimePicker,
  DatePicker,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import "moment/locale/fr";
import dayjs from "dayjs";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { getSunrise, getSunset } from "sunrise-sunset-js";
const { Text } = Typography;
const SelectStartEndLunch = ({
  events,
  setEvents,
  selected,
  lunchStartTime,
  lunchEndTime,
  setLunchStartTime,
  setLunchEndTime,
  initialLunchStartTime,
  initialLunchEndTime,
  neededTime,
  setNeededTime,
}) => {
  const { RangePicker } = DatePicker;
  // const { RangePicker: MinutesPicker } = TimePicker;
  const { Option } = Select;

  const formRef = useRef();
  const format = "HH:mm";
  const initialStartTime = "08:30";
  const initialEndTime = "18:30";
  const initialStartAmo = "1";

  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [startAmo, setStartAmo] = useState(initialStartAmo);
  const [timePerDay, setTimePerDay] = useState(0);

  const onSelectHoursLunch = (value) => {
    if (value !== null) {
      let start = new Date(value[0]).toLocaleTimeString().slice(0, 5);
      let end = new Date(value[1]).toLocaleTimeString().slice(0, 5);
      setLunchStartTime(start);
      setLunchEndTime(end);
    }
    //  else {
    //   console.log(`DO DO RESET FORM FIELDS`);
    // }
  };

  const computeEvt = (dates) => {
    const newFormat = "DD/MM/YYYY" + format;
    let updatedEvents = [];
    dates.forEach((item) => {
      if (JSON.stringify(item) !== "{}") {
        // console.log("pass pour : " + JSON.stringify(item));
        updatedEvents.push({
          // start: moment(
          //   item.date + item["timepicker"][0].format("HH:mm"),
          //   newFormat
          // )
          //   .toDate()
          //   .getTime(),
          // end: moment(
          //   item.date + item["timepicker"][1].format("HH:mm"),
          //   newFormat
          // )
          //   .toDate()
          //   .getTime(),
          start: dayjs(
            item.date + item["timepicker"][0].format("HH:mm"),
            newFormat
          )
            .toDate()
            .getTime(),
          end: dayjs(
            item.date + item["timepicker"][1].format("HH:mm"),
            newFormat
          )
            .toDate()
            .getTime(),
          nbAmo: parseInt(item.nbAmo[0]),
        });
      }
    });
    setEvents(updatedEvents);
  };

  const updateSubForm = (start, end) => {
    let datesArray = formRef.current.getFieldsValue()["dayschedule"];

    let updatedFields = [];
    datesArray.forEach((formItem) => {
      let formUpdatedItem = formItem;
      formUpdatedItem["timepicker"] = [start, end];
      updatedFields.push(formUpdatedItem);
    });
    formRef.current.setFieldsValue({ dayschedule: updatedFields });
    computeEvt(datesArray);
  };

  const updateAmoSubForm = (value) => {
    let datesArray = formRef.current.getFieldsValue()["dayschedule"];

    let updatedFields = [];
    datesArray.forEach((formItem) => {
      let formUpdatedItem = formItem;
      formUpdatedItem["nbAmo"] = value;
      updatedFields.push(formUpdatedItem);
    });
    formRef.current.setFieldsValue({ dayschedule: updatedFields });
    computeEvt(datesArray);
  };

  const onSelectHours = (value) => {
    let formData = undefined;
    let start = undefined;
    let end = undefined;
    if (value !== null) {
      formData = formRef.current.getFieldsValue();
      // TO DO: Remplacer moment par dayjs mais ne fait pas bugger ??
      start = moment(
        new Date(value[0]).toLocaleTimeString().slice(0, 5),
        format
      );
      end = moment(new Date(value[1]).toLocaleTimeString().slice(0, 5), format);
      // start = dayjs(
      //   new Date(value[0]).toLocaleTimeString().slice(0, 5),
      //   format
      // );
      // end = dayjs(new Date(value[1]).toLocaleTimeString().slice(0, 5), format);

      setStartTime(moment(start).format("HH:mm"));
      setEndTime(moment(end).format("HH:mm"));
      // setStartTime(dayjs(start).format("HH:mm"));
      // setEndTime(dayjs(end).format("HH:mm"));
    }

    if (value !== null && JSON.stringify(formData) !== "{}") {
      updateSubForm(start, end);
    }
  };

  const onSelectAmo = (value) => {
    setStartAmo(value);
    let formData = formRef.current.getFieldsValue();
    if (value !== null && JSON.stringify(formData) !== "{}") {
      updateAmoSubForm(value);
    }
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
      // disabledMinutes: () => this.range(30, 60),
      // disabledSeconds: () => [55, 56],
    };
  };

  useEffect(() => {
    // console.log(`useEffect !`);
    let dateLunchStartTime = moment(lunchStartTime, "HH:mm");
    let dateLunchEndTime = moment(lunchEndTime, "HH:mm");
    let duration = moment.duration(dateLunchEndTime.diff(dateLunchStartTime));
    let lunchHours = duration.asHours();

    let unitHour = 0;
    unitHour =
      (new Date(moment(endTime, "HH:mm")).getTime() -
        new Date(moment(startTime, "HH:mm")).getTime()) *
      2.77778e-7;
    setTimePerDay((unitHour - lunchHours) * startAmo);
  }, [startAmo, lunchStartTime, lunchEndTime, startTime, endTime]);

  return (
    <div>
      {events && events.length !== 0 && (
        <div>
          <CaretUpOutlined />
          &nbsp;&nbsp;
          {`${moment(
            getSunrise(48.856614, 2.3522219, new Date(events[0].start))
          ).format("HH:mm")}`}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <CaretDownOutlined />
          &nbsp;&nbsp;
          {`${moment(
            getSunset(48.856614, 2.3522219, new Date(events[0].start))
          ).format("HH:mm")}`}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {`(${moment(new Date(events[0].start)).format("DD-MM-YYYY")})`}
          <br />
          <br />
        </div>
      )}
      <Form ref={formRef} name="dynamic_form_nest_item" autoComplete="off">
        <Form.List name="dayschedule">
          {(fields, { add, remove }) => (
            <div>
              <Form.Item>
                <Space>
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "12px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Début/Fin de Journée</center>
                      </Text>
                      <TimePicker.RangePicker
                        format={format}
                        disabledTime={disabledDateTime}
                        hideDisabledOptions={true}
                        minuteStep={15}
                        // defaultValue={[
                        //   moment(startTime, format),
                        //   moment(endTime, format),
                        // ]}
                        defaultValue={[
                          dayjs(startTime, format),
                          dayjs(endTime, format),
                        ]}
                        onChange={onSelectHours}
                      />
                    </Space>
                  </div>
                  &nbsp;&nbsp;
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "16px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Effectifs</center>
                      </Text>
                      <Select
                        placeholder="Nb AMO"
                        style={{
                          width: 100,
                        }}
                        onChange={onSelectAmo}
                        defaultValue={"1 pers"}
                      >
                        <Option value="1">1 pers</Option>
                        <Option value="2">2 pers</Option>
                        <Option value="3">3 pers</Option>
                        <Option value="4">4 pers</Option>
                        <Option value="5">5 pers</Option>
                        <Option value="6">6 pers</Option>
                        <Option value="7">7 pers</Option>
                        <Option value="8">8 pers</Option>
                        <Option value="9">9 pers</Option>
                        <Option value="10">10 pers</Option>
                      </Select>
                    </Space>
                  </div>
                  &nbsp;&nbsp;
                  <div
                    style={{
                      border: "0.5px solid #d9d9d9",
                      borderRadius: "6px",
                      padding: "12px",
                    }}
                  >
                    <Space direction="vertical">
                      <Text strong>
                        <center>Pause déjeuner</center>
                      </Text>
                      <TimePicker.RangePicker
                        format={format}
                        disabledTime={disabledDateTime}
                        hideDisabledOptions={true}
                        minuteStep={15}
                        // defaultValue={[
                        //   moment(initialLunchStartTime, format),
                        //   moment(initialLunchEndTime, format),
                        // ]}
                        defaultValue={[
                          dayjs(initialLunchStartTime, format),
                          dayjs(initialLunchEndTime, format),
                        ]}
                        onChange={onSelectHoursLunch}
                      />
                    </Space>
                  </div>
                </Space>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>

      {selected.length > 0 && (
        <div>
          {(events === undefined || events.length === 0) && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Vous avez besoin de : &nbsp;
              {Math.round(neededTime / timePerDay) > 1
                ? `${(neededTime / timePerDay).toFixed(1)} jours`
                : `${(neededTime / timePerDay).toFixed(1)} jour`}
            </Tag>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectStartEndLunch;
