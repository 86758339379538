// TODO: Add roles for staff, promoter superuser

const roles = {
  anonymousUser: {
    static: ["/register", "", "/"],
  },
  customer: {
    static: ["/accueil-acquereur", "/logout", "/logement"], //, "/actualite", "/tmas", "/choix", "/messages", "/documents", "/chantier"
  },
  promoter: {
    static: [
      "/accueil-promoteur",
      "/promoter/plannings",
      "/promoter/templates",
      "/promoter/templates/nouveau",
      "/amo/templates/help",
      // "/events",
      "/projets",
      "/profile",
      "/program-edit",
      "/logout",
    ],
  },
  moderator: {
    static: ["/accueil", "/visites", "/profile", "/logout"],
  },
  amo: {
    static: [
      "/profile",
      "/logout",
      "/amo/programmes",
      "/amo/plannings",
      "/amo/templates",
      "/amo/templates/nouveau",
      "/amo/templates/help",
      "/projets",
      "/accueil-amo",
    ],
  },
  administrator: {
    static: [
      "/accueil-administrateur",
      "/profile",
      "/admin/visites",
      "/admin/plannings",
      "/amo/templates",
      "/amo/templates/nouveau",
      "/admin/permissions/users",
      "/admin/permissions/programs",
      "/admin/facturation/entities",
      "/admin/facturation/plannings",
      "/admin/users",
      "/admin/logs/rdvs",
      "/admin/logs/trends",
      "/admin/logs/visites",
      "/logout",
      "/admin/programmes",
      "/projets",
      // "/matterport",
    ],
  },
};

const hasPermission = (role, route) => {
  if (!(role in roles)) {
    return false;
  }
  if (roles[role].static.includes(route)) {
    return true;
  }
  return false;
};

export default hasPermission;
