import React from "react";
import DocumentListItem from "./DocumentListItem";
import { Col, Row } from "antd";

const DocumentsList = ({ fileList }) => {
  return (
    <Row gutter={40} style={{ display: "flex", justifyContent: "center" }}>
      {fileList.map((file, index) => {
        return (
          <div key={"l-" + index}>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 4 }}
              md={{ span: 3 }}
              lg={{ span: 2 }}
              xl={{ span: 1 }}
            >
              <DocumentListItem key={file._id} file={file} />
            </Col>
          </div>
        );
      })}
    </Row>
  );
};

export default DocumentsList;
