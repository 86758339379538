import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { Form, Input, Typography, Tooltip, Button, Select, App } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Option } = Select;

const RegisterPage = () => {
  const { setAuth, setRole, setUsername } = useContext(UserContext);
  const { message } = App.useApp();
  let history = useHistory();

  const onFinish = async (values) => {
    // console.log(values);
    try {
      // console.log(`Making request to : ${process.env.REACT_APP_API_URL}`);
      const res = await axios.post(process.env.REACT_APP_API_URL + "/user", {
        gender: values.gender,
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        enterprise: values.enterprise,
        email: values.email,
        password: values.password,
        role: values.select,
      });
      localStorage.setItem("authToken", res.data.token);
      localStorage.setItem("username", res.data.user.username);
      setAuth(true);
      setRole(res.data.user.role);
      setUsername(res.data.user.username);
      message.success(`👋 Bienvenue ${res.data.user.lastname}`);
      switch (values.select) {
        case "administrator":
          history.push("/accueil-administrateur");
          break;
        case "promoter":
          history.push("/projets");
          break;
        case "customer":
          history.push("/accueil-acquereur");
          break;
        case "amo":
          history.push("/accueil-amo");
          break;
        default:
          history.push("/");
          break;
      }
      // history.push("/accueil");
    } catch (e) {
      // console.log(
      //   `e.response = ${JSON.stringify(
      //     e.response
      //   )}, e.response.data = ${JSON.stringify(e.response.data)}`
      // );
      console.log(e);
      if (e.response && e.response.data.errors) {
        message.error(
          `Erreur lors de la creation du compte (${JSON.stringify(
            e.response.data.errors
          )}))`
        );
      } else {
        message.error("Erreur lors de la creation du compte");
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="box-layout">
      <Form
        form={form}
        name="updates"
        onFinish={onFinish}
        initialValues={{
          firstname: "",
          lastname: "",
          enterprise: "",
          username: "",
          email: "",
        }}
        scrollToFirstError
      >
        <svg
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 525.2 274.43"
        >
          <Title>Bridgit</Title>
          <rect className="cls-3" width="525.2" height="274.43" rx="1.74" />
          <path
            className="cls-4"
            d="M478.28,536.12a11.7,11.7,0,0,1,2.54.25,5.55,5.55,0,0,1,2,.8,3.82,3.82,0,0,1,1.26,1.49,5.22,5.22,0,0,1,.46,2.29,4.3,4.3,0,0,1-.67,2.46,4.88,4.88,0,0,1-2,1.61,4.72,4.72,0,0,1,2.69,1.82,6,6,0,0,1,.31,5.68,5.13,5.13,0,0,1-1.54,1.76,7.08,7.08,0,0,1-2.21,1,10.38,10.38,0,0,1-2.56.32h-9.44V536.12Zm-.55,7.89a3,3,0,0,0,1.89-.55,2.05,2.05,0,0,0,.74-1.77,2.32,2.32,0,0,0-.25-1.12,1.73,1.73,0,0,0-.66-.68,2.51,2.51,0,0,0-.94-.34,5.63,5.63,0,0,0-1.1-.1h-4V544Zm.25,8.27a5.81,5.81,0,0,0,1.2-.12,2.94,2.94,0,0,0,1-.41,2.12,2.12,0,0,0,.7-.78,2.7,2.7,0,0,0,.26-1.25,2.55,2.55,0,0,0-.85-2.15,3.65,3.65,0,0,0-2.24-.64h-4.67v5.35Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M491.33,541.5v2.62h.05a4.85,4.85,0,0,1,.74-1.21,4.64,4.64,0,0,1,1.07-1,5,5,0,0,1,1.28-.61,4.49,4.49,0,0,1,1.42-.22,3.09,3.09,0,0,1,.85.13v3.61c-.19,0-.4-.07-.66-.1a6.23,6.23,0,0,0-.74,0,4.07,4.07,0,0,0-1.8.36,3.14,3.14,0,0,0-1.19,1,3.94,3.94,0,0,0-.64,1.44,7.63,7.63,0,0,0-.19,1.77v6.36h-3.88V541.5Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M498.1,539.32v-3.2H502v3.2ZM502,541.5v14.11H498.1V541.5Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M514.78,553.81a3.89,3.89,0,0,1-1.79,1.65,5.88,5.88,0,0,1-2.5.51,6,6,0,0,1-2.78-.62,5.61,5.61,0,0,1-2-1.66,7.46,7.46,0,0,1-1.17-2.42,9.9,9.9,0,0,1-.4-2.84,9.32,9.32,0,0,1,.4-2.74,7,7,0,0,1,1.17-2.33,5.66,5.66,0,0,1,4.68-2.24,5.45,5.45,0,0,1,2.39.53,4.14,4.14,0,0,1,1.79,1.57h0v-7.1h3.88v19.49h-3.68v-1.8Zm-.16-7a4.18,4.18,0,0,0-.56-1.42,3.28,3.28,0,0,0-1-1,3,3,0,0,0-1.58-.38,3.2,3.2,0,0,0-1.61.38,3,3,0,0,0-1,1,4.21,4.21,0,0,0-.57,1.43,7.72,7.72,0,0,0-.18,1.68,7.06,7.06,0,0,0,.19,1.64,4.55,4.55,0,0,0,.61,1.46,3.42,3.42,0,0,0,1.07,1,2.91,2.91,0,0,0,1.54.39,3,3,0,0,0,1.6-.38,2.85,2.85,0,0,0,1-1,4.56,4.56,0,0,0,.55-1.46,8.57,8.57,0,0,0,.16-1.69A8.26,8.26,0,0,0,514.62,546.84Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M534.51,556.65a4.87,4.87,0,0,1-1,2,6,6,0,0,1-2.21,1.59,9.51,9.51,0,0,1-3.88.66,9.09,9.09,0,0,1-2.11-.26,6.76,6.76,0,0,1-2-.81,4.79,4.79,0,0,1-1.47-1.4,4,4,0,0,1-.67-2.06h3.85a2.2,2.2,0,0,0,1.06,1.51,3.86,3.86,0,0,0,1.83.42,2.8,2.8,0,0,0,2.39-1,3.82,3.82,0,0,0,.72-2.48V553h0a3.82,3.82,0,0,1-1.79,1.65,5.7,5.7,0,0,1-2.44.53,6,6,0,0,1-2.73-.56,5.15,5.15,0,0,1-1.89-1.52,6.54,6.54,0,0,1-1.08-2.27,11.06,11.06,0,0,1-.34-2.74,8.66,8.66,0,0,1,.4-2.64,6.91,6.91,0,0,1,1.16-2.24,5.61,5.61,0,0,1,1.9-1.54,5.69,5.69,0,0,1,2.6-.57,5.42,5.42,0,0,1,2.45.52,4.2,4.2,0,0,1,1.76,1.74h0V541.5h3.69v13.21A9.76,9.76,0,0,1,534.51,556.65ZM529.2,552a3,3,0,0,0,1-.88,3.8,3.8,0,0,0,.62-1.24,5.36,5.36,0,0,0,.2-1.46,8,8,0,0,0-.16-1.65,4,4,0,0,0-.55-1.38,2.82,2.82,0,0,0-1-1,3.24,3.24,0,0,0-1.58-.35,2.78,2.78,0,0,0-2.39,1.21,3.68,3.68,0,0,0-.57,1.29,6.18,6.18,0,0,0-.18,1.51,7.76,7.76,0,0,0,.15,1.52,4,4,0,0,0,.52,1.35,2.93,2.93,0,0,0,1,1,2.7,2.7,0,0,0,1.5.38A3.22,3.22,0,0,0,529.2,552Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M542.68,541.5l-3,14.11H535.8l3-14.11Zm-3.47-2.18.71-3.2h3.91l-.71,3.2Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M553.27,541.5l-.6,2.59h-2.75l-1.45,7a3.88,3.88,0,0,0-.08.39,1.85,1.85,0,0,0,0,.26,1.18,1.18,0,0,0,.12.59.89.89,0,0,0,.32.32.94.94,0,0,0,.42.12h.45a12.66,12.66,0,0,0,1.39-.08l-.65,3-1.2.09c-.4,0-.81,0-1.23,0a7.53,7.53,0,0,1-1.35-.12,3.51,3.51,0,0,1-1.2-.44,2.26,2.26,0,0,1-.85-.86,2.61,2.61,0,0,1-.29-1.42,3.58,3.58,0,0,1,.06-.61c0-.23.08-.47.13-.73l1.59-7.56h-2.54l.6-2.59h2.48l.88-4.29h3.87l-.87,4.29Z"
            transform="translate(-37.75 -294.67)"
          />
          <path
            className="cls-4"
            d="M224.22,462.49V353.3c0-5.24-.15-8.44-.43-9.61q-1.75-3.93-7.43-3.93h-2.62a11.54,11.54,0,0,1-2.62.44l-17.47,4.37h-4.37q-.87-7.43-1.74-17.47c-1.75-22.71-2-14.37-2-32.43h51.32L265.72,314V362c1.15.87,3.34,2.33,6.55,4.36l9.61,6.56q10.9,7.43,16.59,10.92l4.37,4.36V514.9L128.63,569.1H93.69L58.26,545.47l-8.74-4.36c-4.08-2-5.66-2.74-11.77-8.28v-11Zm-83-104.82L67,380.82V340.2L73.54,338q28.39-9.61,50.23-15.29,10.9-3,17.47-4.37Zm43.68,74.25q-5.68,3.49-24,10-31.89,11.37-50.22,16.6Q79.22,467.74,67,467.73V431.92q5.67-3.5,24.46-10.05,31.44-11.36,49.79-17,31.45-8.73,43.68-8.74Z"
            transform="translate(-37.75 -294.67)"
          />
        </svg>
        <br />
        <br />
        <Form.Item name="gender" label="Titre">
          <Select>
            <Option value="Monsieur">Monsieur</Option>
            <Option value="Madame">Madame</Option>
            <Option value="Autre">Autre</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="firstname"
          label="Prénom"
          rules={[
            {
              required: true,
              message: "Veuillez saisir votre prénom",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastname"
          label="Nom"
          rules={[
            {
              required: true,
              message: "Veuillez saisir votre nom",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="enterprise"
          label="Entreprise"
          rules={[
            {
              required: true,
              message: "Veuillez saisir le nom de votre entreprise",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="username"
          label={
            <span>
              Nom d'utilisateur&nbsp;
              <Tooltip title="Celui qui apparaît dans votre barre de navigation">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un nom d'utilisateur",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "L'email saisi n'est pas valide",
            },
            {
              required: true,
              message: "Veuillez renseigner votre adresse email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Mot de passe"
          rules={[
            {
              required: true,
              message: "Veuillez saisir un mot de passe",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="select"
          label="Select"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner un role !",
            },
          ]}
        >
          <Select placeholder="Sélectionnez un role">
            <Option value="anonymousUser">Utilisateur Anonyme</Option>
            <Option value="customer">Acquéreur</Option>
            <Option value="promoter">Promoteur</Option>
            <Option value="amo">AMO</Option>
            <Option value="moderator">Moderateur</Option>
            <Option value="administrator">Administrateur</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterPage;
