const getUsrFromMail = (planning, mail) => {
  if (mail && mail !== undefined && mail !== "@indisponible") {
    return planning.broadcastList.find(
      (x) => x.guest.email === mail || x.guest.mailmonsieur === mail
    );
  } else {
    return undefined;
  }
};

let availableColor = "#d4dfc7";
// let bookedColor = "#ce7951";

let bookedColor = "#b7695d";
const getEventSources = (planning, alc, amoNb) => {
  let evtsNotBooked = [];
  let evtsBooked = [];

  let ptr = alc[amoNb].head;
  let planningStart = alc[amoNb].planningStart;
  // let nodeCounter = 0;

  while (ptr.next !== null) {
    // console.log(ptr.data);
    if (ptr.data.booked === false) {
      evtsNotBooked.push({
        amo: amoNb,
        start: planningStart + ptr.data.start,
        end: planningStart + ptr.data.end,
        booked: ptr.data.booked,
        id: planningStart + ptr.data.start,
        newlyAdded: false,
      });
    } else if (
      ptr.data.booked.includes("@") &&
      !ptr.data.booked.startsWith("@")
    ) {
      let usr = getUsrFromMail(planning, ptr.data.booked);
      let newEvt = {
        amo: amoNb,
        start: planningStart + ptr.data.start,
        end: undefined,
        booked: ptr.data.booked,
        title: usr.guest.lotcomm + " " + usr.guest.typologie.toUpperCase(),
        id: planningStart + ptr.data.start,
        newlyAdded: false,
      };

      newEvt.end = planningStart + ptr.data.end;
      evtsBooked.push(newEvt);
      // evtsBooked.push({
      //   amo: amoNb,
      //   start: planningStart + ptr.data.start,
      //   end: planningStart + ptr.data.end,
      //   booked: ptr.data.booked,
      //   id: planningStart + ptr.data.start,
      //   newlyAdded: false,
      // });
    }
    ptr = ptr.next;
    // nodeCounter++;
  }
  return [evtsNotBooked, evtsBooked];
};

const initReducer = (ial, planning) => {
  let subEvts = {};
  for (let a = 0; a < ial.length; a++) {
    let es = getEventSources(planning, ial, a);
    subEvts[a] = [
      { events: es[0], color: availableColor },
      { events: es[1], color: bookedColor },
    ];
  }
  return subEvts;
};

const amoAgendasReducer = (state, action) => {
  let amoEvents = undefined;
  switch (action.type) {
    case "ADD_AMO":
      return {
        ...state,
        [action.nbOfAmos - 1]: [
          { events: [], color: availableColor },
          { events: [], color: bookedColor },
        ],
      };
    case "ADD_AMO_EVENT":
      amoEvents = [...state[action.event.amo]];
      amoEvents[0].events.push(action.event);
      return {
        ...state,
        [action.event.amo]: [...amoEvents],
      };
    case "REMOVE_AMO_EVENT":
      amoEvents = [...state[action.event.amo]];
      amoEvents[0].events = amoEvents[0].events.filter(
        (x) => x.start !== action.event.start && x.end !== action.event.end
      );
      return {
        ...state,
        [action.event.amo]: [...amoEvents],
      };
    case "RESET":
      return initReducer(action.ial, action.planning);
    default:
      return state;
  }
};

export { initReducer, amoAgendasReducer as default };
