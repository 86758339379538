import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import ClientOptions from "./ClientOptions";
import { Table, Tag, Space, Button, Input } from "antd";
import { typoColors } from "../plannings/planningData";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  CloseCircleOutlined,
  CheckCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";

const ClientTabTable = ({
  clientList,
  setClientList,
  selected,
  rowSelection,
  unSelectedAllRows,
  selectedAllRows,
  allSelected,
  setAllSelected,
}) => {
  let history = useHistory();
  let location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const searchInput = useRef(null);
  const programId = location.pathname.split("/")[2];

  // console.log(clientList);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{ width: 110 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput, 100);
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchColumn(dataIndex);
  };

  const onRowDoubleClick = (record) => {
    localStorage.setItem(
      "currentUser",
      record.firstname + " " + record.lastname
    );
    localStorage.setItem(record._id, record.owner);
    history.push("/projets/" + programId + "/clients/" + record._id);
  };

  const columns = [
    {
      title: "Lot",
      dataIndex: "lotcomm",
      key: "lotcomm",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.lotcomm.localeCompare(b.lotcomm),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("lotcomm"),
      ellipsis: true,
      width: "8%",
    },
    {
      title: "Etage",
      dataIndex: "etage",
      key: "etage",
      filters: Array.from(new Set(clientList.map((x) => x.etage))).map((_) => ({
        text: _,
        value: _,
      })),
      onFilter: (value, record) => record.etage.includes(value),
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.etage.localeCompare(b.etage),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "8%",
    },
    {
      title: "Bat.",
      dataIndex: "batiment",
      key: "batiment",
      filters: Array.from(new Set(clientList.map((x) => x.batiment))).map(
        (_) => ({
          text: _,
          value: _,
        })
      ),
      onFilter: (value, record) => record.batiment.includes(value),
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.batiment.localeCompare(b.batiment),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "8%",
    },
    {
      title: "Nom",
      dataIndex: "firstname",
      key: "firstname",
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("firstname"),
      width: "20%",
    },
    {
      title: "Mail",
      dataIndex: "mailmonsieur",
      key: "mailmonsieur",
      sorter: (a, b) => a.mailmonsieur.localeCompare(b.mailmonsieur),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("mailmonsieur"),
      ellipsis: true,
    },
    {
      title: "Login",
      dataIndex: "tmpPassword",
      key: "tmpPassword",
      render: (text) => (
        <>
          {text !== undefined ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleOutlined className="site-result-demo-error-icon" />
          )}
        </>
      ),
      ellipsis: true,
      width: "5%",
    },
    // {
    //   title: "Adresse",
    //   dataIndex: "adresse",
    //   key: "adresse",
    // },
    // {
    //   title: "Code Postal",
    //   dataIndex: "codepostal",
    //   key: "codepostal",
    // },
    // {
    //   title: "Ville",
    //   dataIndex: "ville",
    //   key: "ville",
    // },
    {
      title: "Tél Mr",
      dataIndex: "telephonemonsieur",
      key: "telephonemonsieur",
      ...getColumnSearchProps("telephonemonsieur"),
      ellipsis: true,
      width: "10%",
    },
    {
      title: "Tél Mme",
      dataIndex: "telephonemadame",
      key: "telephonemadame",
      ...getColumnSearchProps("telephonemadame"),
      ellipsis: true,
      width: "10%",
    },
    {
      title: "Typologie",
      key: "typologie",
      dataIndex: "typologie",
      filters: [
        {
          text: "1P",
          value: "1p",
        },
        {
          text: "2P",
          value: "2p",
        },
        {
          text: "3P",
          value: "3p",
        },
        {
          text: "4P",
          value: "4p",
        },
        {
          text: "5P",
          value: "5p",
        },
      ],
      onFilter: (value, record) =>
        record.typologie.toLowerCase().indexOf(value) === 0,
      render: (typology) => {
        return (
          <Tag color={typoColors[typology.toLowerCase()]} key={typology}>
            {typology.toUpperCase()}
          </Tag>
        );
      },
      ellipsis: true,
      width: "9%",
    },
  ];

  return (
    <div>
      <Space direction="horizontal">
        {allSelected && (
          <Button
            onClick={() => {
              unSelectedAllRows();
              setAllSelected(false);
            }}
          >
            Tout désélectionner
          </Button>
        )}
        {!allSelected && (
          <Button
            onClick={() => {
              // console.log(`all rows : ${JSON.stringify(clientList)}`);
              selectedAllRows(clientList);
              setAllSelected(true);
            }}
          >
            Tout sélectionner
          </Button>
        )}
        {selected.length > 0 ? (
          <ClientOptions
            selected={selected}
            unSelectedAllRows={unSelectedAllRows}
            setClientList={setClientList}
          />
        ) : (
          <div>
            <i>
              Séléctionner pour afficher les options, double cliquez sur un
              acquéreur pour modifier ses informations
            </i>
          </div>
        )}
      </Space>
      <br />
      <br />
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              // console.log(`selected : ${JSON.stringify(record)}`);
              onRowDoubleClick(record);
            }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        columns={columns}
        dataSource={clientList}
        rowKey="_id"
        size="small"
        // rowSelection={rowSelection} // JUST REMOVED
      />
    </div>
  );
};

export default ClientTabTable;
