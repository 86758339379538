import React, { useContext } from "react";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { getBasicAuthConfig } from "../../../utils";
import { Form, Input, Button, Typography, App, Select } from "antd";

const { Option } = Select;
const { Title } = Typography;

const Profile = ({ client, userId, programId }) => {
  const { message } = App.useApp();
  const { role } = useContext(UserContext);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  const validateMessages = {
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const onFinish = async (values) => {
    try {
      // console.log(`detected role : ${role}`);
      let url = undefined;
      if (role === "administrator") {
        url = `${process.env.REACT_APP_API_URL}/admin/users/${client._id}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/users/${client._id}`;
        values = {
          ...values,
          programId: programId,
        };
      }

      await axios.patch(url, values, getBasicAuthConfig());
      message.success(`Informations client correctement mises à jour`);
    } catch (e) {
      console.log(e);
      message.error(`Erreur lors de la mise à jour des informations client`);
      form.resetFields();
    }
  };

  return (
    <div>
      <div>
        <Title level={4}>Mise à jour des informations de l'utilisateur</Title>
        <br />
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{
            gender: client.gender,
            mailmonsieur: client.mailmonsieur,
            mailmadame: client.mailmadame,
            telephonemonsieur: client.telephonemonsieur,
            telephonemadame: client.telephonemadame,
            firstname: client.firstname,
          }}
          {...formItemLayout}
        >
          <Form.Item name="gender" label="Titre">
            <Select>
              <Option value="Monsieur">Monsieur</Option>
              <Option value="Madame">Madame</Option>
              <Option value="Autre">Autre</Option>
            </Select>
          </Form.Item>
          <Form.Item name="firstname" label="Nom">
            <Input />
          </Form.Item>
          <Form.Item
            name="mailmonsieur"
            label="Email"
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mailmadame"
            label="Email"
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="telephonemonsieur"
            label="Téléphone 1"
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
                ),
                message:
                  "Veuillez renseigner au moins un numéro de téléphone (format : 0689... ou +337...)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="telephonemadame"
            label="Téléphone 2"
            rules={[
              {
                pattern: new RegExp(
                  /^\+?[1-9]\d{1,14}$|^((\+)33|0)[1-9](\d{2}){4}$/g
                ),
                message:
                  "Veuillez renseigner au moins un numéro de téléphone (format : 0689... ou +337...)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
