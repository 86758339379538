import React from "react";

import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";

export const UserMenuData = [
  {
    name: "compte",
    label: "Compte",
    icon: <SettingOutlined />,
    path: "/profile",
  },
  {
    name: "deconnexion",
    label: "Déconnexion",
    icon: <LogoutOutlined />,
    path: "/logout",
  },
];
