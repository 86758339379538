import React from "react";
import axios from "axios";
import moment from "moment";
import momentTz from "moment-timezone";
import ContentContainer from "../../ContentContainer";
import SubContentContainer from "../../SubContentContainer";
import PublicFooter from "../../public/Footer";
import GDPRBlaBla from "./GDPRBlaBla";
import { getRdvApiAuth } from "./../../../utils";
import { ToolOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  Typography,
  Layout,
  Button,
  Card,
  Alert,
  Space,
  App,
  Spin,
  Result,
  Form,
  Input,
} from "antd";
const ics = require("ics");
var FileSaver = require("file-saver");
const { Text, Title } = Typography;

const PromoterTemplateImpose = ({
  dateExpired,
  hasLoaded,
  planning,
  rdv,
  rdvDate,
  urlLogo,
  phaseTransform,
  isFound,
  sayIsNotAvailable,
  setSayIsNotAvailable,
  closeWindow,
  form,
  fillForm,
  setFillForm,
  setWait,
  promoterUrl,
  isActive,
}) => {
  const { message } = App.useApp();
  const yourContact = `${
    !planning.contactNom && !planning.contactPrenom
      ? `${planning.promoter.toUpperCase()}`
      : `${planning.contactNom} ${planning.contactPrenom} (${
          planning.contactTelephone
            ? "📞 " +
              planning.contactTelephone +
              (planning.contactMail ? " / " : "")
            : ""
        }${planning.contactMail ? "✉ " + planning.contactMail : ""})`
  }`;

  const clickNo = () => {
    setFillForm(true);
  };

  const clickYes = async () => {
    let req = {
      usr: rdv.uuid,
    };
    setWait(true);
    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL + "/rdv/confirm/" + rdv._id,
        req
      );
      // TODO : No need to reload, just change state after confirmation written in DB !
      document.location.reload();
    } catch (e) {
      if (e.response && e.response.data.error) {
        console.log(e);
        message.error("Il semblerait qu'une erreur s'est produite.");
      } else {
        console.log(
          `Erreur lors de la confirmation de l'horaire : ${e}. La page va se rafraichir, vous pourrez alors réessayer.`
        );
      }
      setTimeout(function () {
        document.location.reload();
      }, 2000);
    }
  };

  const onClickIcsCalendar = () => {
    ics.createEvent(
      {
        title: `${planning.promoter.toUpperCase()} visite de ${
          phaseTransform[planning.phase]
        } de votre logement ${rdv.guest.lotcomm} (Bat.${rdv.guest.batiment})`,
        description:
          "Votre contact : " +
          yourContact +
          ` \n \n Pour annuler ou déplacer votre RDV, cliquez sur le lien ci-dessous :\n https://bridg-it.fr/entrevue/${rdv.uuid}`,
        start: moment(new Date(rdv.start))
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((_) => parseInt(_)),
        end: moment(new Date(rdv.end))
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((_) => parseInt(_)),
        location: `${planning.address.numero} ${planning.address.rue} ${planning.address.ville} ${planning.address.codepostal}`,
        alarms: [
          {
            action: "display",
            trigger: {
              days: 2,
              before: true,
            },
          },
          {
            action: "display",
            trigger: { hours: 2, minutes: 0, before: true },
          },
        ],
      },
      (error, value) => {
        if (error) {
          console.log(error);
        }

        var blob = new Blob([value], { type: "text/calendar" });
        FileSaver.saveAs(
          blob,
          `${planning.promoter.toUpperCase()} ${planning.name}.ics`
        );
      }
    );
  };

  const clickNotAvailable = async (values) => {
    let updates = {
      isAvailable: false,
      isConfirmed: true,
      uuid: rdv.uuid,
      note: values.note,
    };

    try {
      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          planning._id +
          "/broadcastlist",
        updates,
        getRdvApiAuth()
      );
      setFillForm(false);
      setSayIsNotAvailable(true);
    } catch (e) {
      console.log(e);
      message.error(`Une erreur est apparue.`);
    }
  };

  if (hasLoaded && !isActive) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <center>
              <Card hoverable style={{ width: 300, border: "none" }}>
                <img
                  alt="promoteur"
                  src={urlLogo}
                  width="100%"
                  height="100%"
                  onClick={() => window.open(promoterUrl, "_blank")}
                />
              </Card>
              <Title level={3}>
                {planning.name} ({phaseTransform[planning.phase]})
              </Title>
              <p>
                Acquéreur: <b>{rdv.guest.email}</b>
              </p>
              <Space direction="vertical">
                <Alert
                  message="Votre rendez-vous a été désactivé"
                  description={`Veuillez contacter ${yourContact}`}
                  type="warning"
                  showIcon
                />
              </Space>
            </center>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (dateExpired) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <center>
              <Card hoverable style={{ width: 300, border: "none" }}>
                <img
                  alt="promoteur"
                  src={urlLogo}
                  width="100%"
                  height="100%"
                  onClick={() => window.open(promoterUrl, "_blank")}
                />
              </Card>
              <Title level={5}>{planning.name}</Title>
              <Title level={3}>
                <Text underline>Convocation</Text> visite{" "}
                {phaseTransform[planning.phase]}
              </Title>
              <p>
                Acquéreur:{" "}
                <b>{`${rdv.guest.nom} (lot ${rdv.guest.lotcomm})`}</b>
              </p>
              <Space direction="vertical">
                <Alert
                  message={`Rendez-vous : le ${momentTz(rdvDate)
                    .tz("Europe/Paris")
                    .format("DD/MM")} à ${momentTz(rdvDate)
                    .tz("Europe/Paris")
                    .format("HH:mm")}`}
                  type="warning"
                  showIcon
                />
              </Space>
              <br />
              <br />
              <Title level={5}>
                Il n'est plus possible de valider votre présence au rendez-vous
                <br /> Veuillez vous présenter à la date indiquée ci-dessus.
              </Title>
              <GDPRBlaBla rdv={rdv} />
            </center>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (hasLoaded && isFound && !rdv.isConfirmed && !sayIsNotAvailable) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <SubContentContainer>
              <center>
                <Card hoverable style={{ width: 300, border: "none" }}>
                  <img
                    alt="promoteur"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={5}>{planning.name}</Title>
                <Title level={3}>
                  <Text underline>Convocation</Text> visite{" "}
                  {phaseTransform[planning.phase]}
                </Title>
                <p>
                  Acquéreur:{" "}
                  <b>{`${rdv.guest.nom} (lot ${rdv.guest.lotcomm})`}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message={`Rendez-vous : le ${momentTz(rdvDate)
                      .tz("Europe/Paris")
                      .format("DD/MM")} à ${momentTz(rdvDate)
                      .tz("Europe/Paris")
                      .format("HH:mm")}`}
                    type="warning"
                    showIcon
                  />
                </Space>
                <br />
                <br />
                {!fillForm && !sayIsNotAvailable ? (
                  <Space direction="vertical">
                    <Button onClick={clickYes} size="large" type="primary">
                      CONFIRMER MA PRESENCE
                    </Button>
                    <Button onClick={clickNo} size="small" type="link">
                      Il m'est impossible d'être <br />
                      présent(e) à cette date
                    </Button>
                    <br />
                  </Space>
                ) : (
                  <Space direction="vertical">
                    <Form
                      layout="vertical"
                      form={form}
                      name="nest-messages"
                      onFinish={clickNotAvailable}
                    >
                      <Form.Item
                        name={"note"}
                        label="Veuillez décrire votre cas de force majeure, vous serez ensuite recontacté(e) :"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez indiquer les raisons de votre indisponibilité à la date indiquée",
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>
                      <Form.Item>
                        <Space direction="horizontal">
                          <Button type="primary" htmlType="submit">
                            Envoyer
                          </Button>
                          <Button
                            onClick={() => {
                              setFillForm(false);
                            }}
                            type="primary"
                            danger
                          >
                            Retour
                          </Button>
                        </Space>
                      </Form.Item>
                    </Form>
                  </Space>
                )}
                <GDPRBlaBla rdv={rdv} />
              </center>
            </SubContentContainer>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (
    hasLoaded &&
    isFound &&
    ((rdv.isConfirmed && !rdv.isAvailable) ||
      (!rdv.isConfirmed && sayIsNotAvailable))
  ) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <center>
              <Card hoverable style={{ width: 300, border: "none" }}>
                <img
                  alt="promoteur"
                  src={urlLogo}
                  width="100%"
                  height="100%"
                  onClick={() => window.open(promoterUrl, "_blank")}
                />
              </Card>
              <Title level={5}>{planning.name}</Title>
              <Title level={3}>
                <Text underline>Convocation</Text> visite{" "}
                {phaseTransform[planning.phase]}
              </Title>
              <p>
                Acquéreur:{" "}
                <b>{`${rdv.guest.nom} (lot ${rdv.guest.lotcomm})`}</b>
              </p>
              <Space direction="vertical">
                <Alert
                  message={`Rendez-vous : le ${momentTz(rdvDate)
                    .tz("Europe/Paris")
                    .format("DD/MM")} à ${momentTz(rdvDate)
                    .tz("Europe/Paris")
                    .format("HH:mm")}`}
                  type="warning"
                  showIcon
                />
              </Space>
              <br />
              <br />
              <Title level={5}>
                Merci ! Vous avez indiqué ne vraiment pas etre disponible à la
                date de convocation.
                <br /> Vous serez recontacté(e)(s) prochainement.
              </Title>
            </center>
            <GDPRBlaBla rdv={rdv} />
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (hasLoaded && isFound && rdv.isConfirmed && rdv.isAvailable) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <center>
              <Card hoverable style={{ width: 300, border: "none" }}>
                <img
                  alt="promoteur"
                  src={urlLogo}
                  width="100%"
                  height="100%"
                  onClick={() => window.open(promoterUrl, "_blank")}
                />
              </Card>
              <Title level={5}>{planning.name}</Title>
              <Title level={3}>
                <Text underline>Convocation</Text> visite{" "}
                {phaseTransform[planning.phase]}
              </Title>
              <p>
                Acquéreur:{" "}
                <b>{`${rdv.guest.nom} (lot ${rdv.guest.lotcomm})`}</b>
              </p>
              <Space direction="vertical">
                <Alert
                  message={`Présence confirmée pour le rendez-vous du ${momentTz(
                    rdvDate
                  )
                    .tz("Europe/Paris")
                    .format("DD/MM")} à ${momentTz(rdvDate)
                    .tz("Europe/Paris")
                    .format("HH:mm")}`}
                  type="success"
                  showIcon
                />
                <Button onClick={onClickIcsCalendar}>
                  <CalendarOutlined />
                  Ajouter au calendrier
                </Button>
              </Space>
              <GDPRBlaBla rdv={rdv} />
            </center>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (hasLoaded && !isFound) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <div>
              <Result
                icon={<ToolOutlined />}
                title="Rendez-vous non trouvé"
                subTitle={
                  <div>
                    Remarque : Ce message peut parfois apparaître si vous
                    utilisez un VPN.
                    <br />
                    Veuillez dans ce cas essayer avec une connexion internet à
                    domicile ou un partage de connexion depuis votre téléphone.
                  </div>
                }
                extra={
                  <Button type="primary" onClick={closeWindow}>
                    Quitter
                  </Button>
                }
              />
            </div>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  }
};

export default PromoterTemplateImpose;
