import React, { useState } from "react";
import {
  Select,
  App,
  Table,
  Popconfirm,
  Typography,
  Space,
  Input,
  Form,
  Tag,
} from "antd";
import axios from "axios";
import { getBasicAuthConfig, getUserLabel } from "./../utils";

const { Option } = Select;

const AmoTemplateTable = ({ data, setData, users }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const openEditModal = (key) => {
    console.log(`key --> ${key}`);
    console.log(`DATA: `);
    console.log(data);
    // let usr = planning.broadcastList.filter((x) => x.uuid === key)[0];
    // setUsrData(usr);
    // setShow(true);
  };

  const deleteTemplate = async (record) => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `/users/me/templates/${record.key}`,
        getBasicAuthConfig()
      );
      message.success("Modèle suprimé avec succès 🤙");
      setData(data.filter((_) => _.key !== record.key));
    } catch (e) {
      console.log(e);
      message.error("Erreur lors de la suppresion du modèle");
    }
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      editable: true,
      width: "25%",
      ellipsis: true,
    },
    {
      title: "Autorisé(s)",
      dataIndex: "authorized",
      key: "authorized",
      render: (text, record) => {
        if (record.authorized.length === 0) {
          return "-";
        } else {
          let innerContent = record.authorized.map((x, idx) => {
            return (
              <Tag color="default" key={`auth-${idx}`}>
                {getUserLabel(x)}
              </Tag>
            );
          });
          return <div>{innerContent}</div>;
        }
      },
      editable: true,
      width: "60%",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      // key: "x",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={(e) => save(e, record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Sauver
            </Typography.Link>
            <Popconfirm
              title="Etes-vous sûr(e) de vouloir annuler ?"
              onConfirm={cancel}
            >
              <a>Annuler</a>
            </Popconfirm>
          </span>
        ) : (
          <Space direction="horizontal">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Editer
            </Typography.Link>
            <Popconfirm
              title={`Etes-vous sûr(e) de vouloir supprimer le template ${record.name} ?`}
              onConfirm={() => deleteTemplate(record)}
              okText="Oui"
              cancelText="Non"
            >
              <a>Supprimer</a>
            </Popconfirm>
            {/* <Tooltip title="Modifier le template">
              <EditOutlined onClick={() => openEditModal(record)} />
            </Tooltip> */}
          </Space>
        );
      },
      width: "15%",
      ellipsis: true,
    },
  ];

  const CustomInput = ({ dataIndex, type, title, authorized, id }) => {
    const children = [];
    // Need to keep user in case wants to make de visits
    let newData = [...users]; //.filter((_) => _._id !== id);
    const opts = [];
    for (let i = 0; i < newData.length; i++) {
      opts.push({
        label: `${getUserLabel(newData[i])}`,
        key: newData[i]._id,
        value: newData[i]._id,
      });
    }
    if (type !== "select") {
      return (
        <Form.Item
          name={dataIndex}
          // style={{
          //   margin: 0,
          // }}
        >
          <Input />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          // style={{
          //   margin: 0,
          // }}
        >
          <Select
            mode="multiple"
            // allowClear
            // style={{
            //   width: "100%",
            // }}
            placeholder="Please select"
            optionFilterProp="label"
            options={opts}
            // onChange={() => {
            //   console.log("it has changed");
            // }}
            // onChange={(value) => handleChange(id, value)}
          >
            {/* {children} */}
          </Select>
        </Form.Item>
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            type={inputType}
            authorized={record.authorized}
            dataIndex={dataIndex}
            title={title}
            id={record._id}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    // console.log(`EDIT`);
    form.setFieldsValue({
      ...record,
      authorized: record.authorized.map((_) => _._id),
    });
    setEditingKey(record.key);
  };

  const cancel = async () => {
    setEditingKey("");
    await form.resetFields();
  };

  const save = async (e, key) => {
    e.preventDefault();
    // console.log(`e : `);
    // console.log(e);
    try {
      // No need to change this below with then so far
      const row = await form.validateFields();
      // console.log(`ROW ---> ${JSON.stringify(row)}`);

      const populatedRow = {
        authorized: row["authorized"].map((x) =>
          users.find((_) => _._id === x)
        ),
      };

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];
      // console.log(`item ---> ${JSON.stringify(item)}`);
      if (index > -1) {
        newData.splice(index, 1, { ...item, ...row, ...populatedRow });
        // newData.splice(index, 1, { ...item, ...populatedRow });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(populatedRow);
        setData(newData);
        setEditingKey("");
      }

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/templates/${item.key}`,
        { ...row },
        getBasicAuthConfig()
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "authorized" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        // name: col.name,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        // columns={columns}
        rowKey="key"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        expandable={{
          expandedRowRender: (record) => record.description,
        }}
        dataSource={data}
        // size="small"
      />
    </Form>
  );
};

export default AmoTemplateTable;
