import React, { useState, useEffect } from "react";
import { App, Modal, Form, Input, Space, Button } from "antd";
import axios from "axios";
import { getRdvApiAuth } from "../../../utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ShowShareModal = ({ show, setShow, planning, setPlanning }) => {
  const { message } = App.useApp();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const newFunc = async () => {
      setHasLoaded(true);
    };
    newFunc();
  }, []);

  const updatePlanningNotified = async () => {
    try {
      let vals = await form.validateFields();
      if (vals.users === undefined || vals.users.length === 0) {
        message.error(
          `Veuillez renseigner les emails auxquels vous souhaitez partager le planning.`
        );
        form.resetFields();
        return;
      }
      let emails = vals.users.map((_) => _.email);
      await axios.post(
        `${process.env.REACT_APP_RDV_API_URL}/rdv/${planning._id}/share-email`,
        {
          emails,
        },
        getRdvApiAuth()
      );
      await form.resetFields();
      setShow(false);
      if (emails.length > 1) {
        message.success(
          `Mail de partage envoyé a ${emails.length} personnes (${emails})`
        );
      } else {
        message.success(`Mail de partage envoyé a une personne (${emails})`);
      }
    } catch (e) {
      if (e.errorFields) {
        message.error(`Veuillez vérifier l'adresse mail entrée`);
      } else {
        message.error(
          `Une erreur est apparue. Echec du partage de l'aperçu planning (${JSON.stringify(
            e
          )})`
        );
      }
      console.log(e);
    }
  };

  return (
    <Modal
      title="Partage de l'aperçu du planning par Email"
      style={{ top: 40 }}
      open={show}
      onOk={updatePlanningNotified}
      onCancel={async () => {
        await form.resetFields();
        setShow(false);
      }}
    >
      {hasLoaded && (
        <div>
          <br />
          <Form form={form} name="validate_other">
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Email manquant" },
                          {
                            type: "email",
                            message: "L'email saisi n'est pas valide",
                          },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Ajouter
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default ShowShareModal;
