import React, { useState } from "react";
import { Transfer, Switch, Space, App } from "antd";
import axios from "axios";
import { getBasicAuthConfig } from "./../../utils";

const CustomTransfer = ({ amoList, project, projectId }) => {
  const { message } = App.useApp();
  const [targetKeys, setTargetKeys] = useState(project.authorized);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleChange = async (nextTargetKeys, direction, moveKeys) => {
    try {
      await axios.patch(
        process.env.REACT_APP_API_URL + "/programs/" + projectId,
        { authorized: nextTargetKeys },
        getBasicAuthConfig()
      );

      setTargetKeys(nextTargetKeys);
      message.success(
        "Liste des AMO pouvant accéder au dossier modifiée avec succès. 👍"
      );
    } catch (e) {
      if (e.response.data.error) {
        message.error(`${e.response.data.error}`);
      }
      console.log(e);
    }
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleScroll = (direction, e) => {};

  const handleDisable = (disabled) => {
    setDisabled(!disabled);
  };

  return (
    <div>
      <Space direction="vertical">
        <Switch
          unCheckedChildren="Modifier"
          checkedChildren="Modifier"
          checked={!disabled}
          onChange={handleDisable}
        />
        <Transfer
          dataSource={amoList}
          titles={["AMOs possibles", "AMOs autorisés"]}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          onScroll={handleScroll}
          render={(item) => item.title}
          disabled={disabled}
          oneWay
          style={{ marginBottom: 16, marginTop: 16 }}
          listStyle={{
            width: 300,
            height: 300,
          }}
        />
      </Space>
    </div>
  );
};

export default CustomTransfer;
