import React, { useState } from "react";
import axios from "axios";
import { App, Button, Modal, Form, Input, Upload, Space, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getVisitesApiAuth } from "./../utils";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  normFile,
  form,
}) => {
  const [isCustomDisabled, setIsCustomDisabled] = useState(true);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = () => {
    // console.log("onChange Switch");
    setIsCustomDisabled(!isCustomDisabled);
  };

  return (
    <Modal
      open={visible}
      title="Créer un nouveau promoteur pour les visites"
      okText="Créer"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <br />
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="title"
          label="Nom publique"
          rules={[
            {
              required: true,
              message:
                "Veuillez renseigner le nom du promoteur tel qu'il apparaitra à l'utilisateur",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="internalName"
          label="Nom interne"
          rules={[
            {
              required: true,
              message:
                "Veuillez renseigner le nom interne du promoteur qui vous servira à indentifier les paramètres associés au promoteur",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="upload"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="Choisissez le logo du promoteur de la visite"
        >
          <Upload name="logo" listType="picture" customRequest={dummyRequest}>
            <Button icon={<UploadOutlined />}>Sélectionner</Button>
          </Upload>
        </Form.Item>
        <Space>
          <Switch onChange={onChange} />
          Texte personnalisé
        </Space>
        <br />
        <br />
        <Form.Item
          name="firstParagraph"
          label="Paragraphe 1"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le premier paragraphe",
            },
          ]}
          initialValue="Découvrez l'expérience immersible de votre logement en
          visite virtuelle"
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>

        <Form.Item
          name="secondParagraph"
          label="Paragraphe 2"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le second paragraphe",
            },
          ]}
          initialValue="Prenez le contrôle ! Nous vous donnons la possibilité de
          visiter votre logement en réalité virtuelle. Découvrez les
          espaces, les implantations des appareillages."
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>

        <Form.Item
          name="thirdParagraph"
          label="Paragraphe 3"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le troisième paragraphe",
            },
          ]}
          initialValue="Votre AMO se tient à votre disposition pour répondre à vos questions."
        >
          <Input.TextArea disabled={isCustomDisabled} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddPromoter = ({ visible, setVisible, hasLoade, setHasLoaded }) => {
  const { message } = App.useApp();
  const [selectedFile, setSelectedFile] = useState(null);
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (e.fileList[0] !== undefined) {
      setSelectedFile(e.fileList[0].originFileObj);
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const createPromoters = async (values) => {
    // console.log("On create promoter click");
    // console.log(values);
    // const config = getBasicAuthConfig();
    const config = getVisitesApiAuth();
    try {
      const res = await axios.post(
        process.env.REACT_APP_VR_API_URL + "/promoter/create",
        {
          name: values.title,
          internalName: values.internalName,
          firstParagraph: values.firstParagraph,
          secondParagraph: values.secondParagraph,
          thirdParagraph: values.thirdParagraph,
        },
        config
      );
      if (!selectedFile !== null) {
        const formData = new FormData();
        formData.append("logo", selectedFile, selectedFile.name);
        await axios.post(
          process.env.REACT_APP_VR_API_URL +
            "/promoters/" +
            res.data._id +
            "/logo",
          formData,
          config
        );
      }

      message.success(`Promoteur ${values.title} créé !`);
      setHasLoaded(false);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(
          `Erreur lors de l'envoi du logo promoteur (${e.response.data.error}))`
        );
      } else {
        message.error("Erreur lors de l'envoi du logo promoteur");
      }
      setVisible(false);
    }
    setVisible(false);
  };

  return (
    <div>
      <CollectionCreateForm
        visible={visible}
        onCreate={createPromoters}
        onCancel={() => {
          setVisible(false);
        }}
        normFile={normFile}
        form={form}
      />
    </div>
  );
};

export default AddPromoter;
