import React from "react";
import ContentContainer from "../../components/ContentContainer";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";
import { Typography, Skeleton } from "antd";

const { Title } = Typography;

const Home = () => {
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          title="Page Accueil Administrateur - En développement"
          avatar={{
            src: "https://cdn-icons-png.flaticon.com/512/993/993928.png",
          }}
        ></CustomPageHeader>
        <Skeleton />
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Home;
