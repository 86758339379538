import React, { useEffect, useState } from "react";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import { getBasicAuthConfig } from "../../utils";
import { Typography, App } from "antd";
import ProgramsPermissionsTable from "../../components/admin/ProgramsPermissionsTable";
import CustomPageHeader from "../../components/CustomPageHeader";
import SubContentContainer from "../../components/SubContentContainer";

const { Title } = Typography;

const AdminProgramsPermissions = () => {
  const { message } = App.useApp();
  const [users, setUsers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const newFunc = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/users/business",
          getBasicAuthConfig()
        );
        const res2 = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/permissions/programs",
          getBasicAuthConfig()
        );
        setUsers(res.data);
        setPrograms(res2.data);
        // console.log(res2.data);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        message.error(`Erreur Lors du chargement`);
      }
    };
    newFunc();
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          arrow={true}
          title="Page Programs Permissions"
        ></CustomPageHeader>
        {!isLoading && (
          <ProgramsPermissionsTable
            data={programs}
            setData={setPrograms}
            users={users}
          />
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default AdminProgramsPermissions;
