import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { App, Col, Row, Tabs, Typography, Space } from "antd";
import axios from "axios";
import { getBasicAuthConfig } from "./../../utils";
import UserProfileRightContainer from "./../../components/UserProfileRightContainer";
import UserProfileLeftContainer from "./../../components/UserProfileLeftContainer";
import AdminFacturationEntityProfile from "./AdminFacturationEntityProfile";
import ContentContainer from "../../components/ContentContainer";

const { Title } = Typography;

const AdminEntityPage = () => {
  const id = useLocation().pathname.split("/")[4];
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState(undefined);

  const loadEntityProfile = async () => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_API_URL + "/admin/clients/" + id,
        getBasicAuthConfig()
      );
      setEntity(res.data);
    } catch (e) {
      console.log(e);
      message.error(`Une erreur est survenue : ${e}`);
      // TODO : Redirect User to Home
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await loadEntityProfile();
      setIsLoading(false);
    };
    newFunc();
  }, []);

  const items = [
    {
      key: "1",
      label: "Profil",
      children: <AdminFacturationEntityProfile entity={entity} />,
    },
  ];

  return (
    <ContentContainer>
      {!isLoading && (
        <Row>
          <Col flex="0 1 400px">
            <UserProfileLeftContainer>
              <Space direction="vertical">
                <center>
                  <img
                    alt=""
                    src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                    width="50%"
                  />
                  <br />
                  <br />
                  <Title level={3}>{`${entity.formejuridique.toUpperCase()} ${
                    entity.raisonsociale
                  }`}</Title>
                </center>
              </Space>
            </UserProfileLeftContainer>
          </Col>
          <Col flex="1 1 200px">
            <UserProfileRightContainer>
              <Tabs defaultActiveKey="1" items={items}></Tabs>
            </UserProfileRightContainer>
          </Col>
        </Row>
      )}
    </ContentContainer>
  );
};

export default AdminEntityPage;
