import React, { useEffect, useState } from "react";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import { getBasicAuthConfig } from "../../utils";
import { Typography, App } from "antd";
import PlanningsInvoiceTable from "../../components/admin/PlanningsInvoiceTable";
import SubContentContainer from "../../components/SubContentContainer";
import CustomPageHeader from "../../components/CustomPageHeader";

const { Title } = Typography;

const AdminFacturationPlannings = () => {
  const { message } = App.useApp();
  const [data, setData] = useState([]);
  const [invoiceTransform, setInvoiceTransform] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const newFunc = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/admin/clients",
          getBasicAuthConfig()
        );

        let fmtInvoiceTransform = {};
        res.data.forEach((ie) => {
          fmtInvoiceTransform[ie._id] = ie.raisonsociale;
        });

        setInvoiceTransform(fmtInvoiceTransform);

        const res2 = await axios.get(
          process.env.REACT_APP_RDV_API_URL + `/plannings`,
          getBasicAuthConfig()
        );

        let fmtData = [];
        res2.data.plannings.forEach((planning) => {
          fmtData.push({
            _id: planning._id,
            name: planning.name,
            program: planning.program,
            promoter: planning.promoter,
            phase: planning.phase,
            count: planning.broadcastList.length,
            planningStartTime: planning.planningStartTime,
            invoiceEntity: planning.invoiceEntity,
          });
        });

        setData(fmtData);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        message.error(`Erreur Lors du chargement`);
      }
    };
    newFunc();
  }, []);

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader title="Facturation - Plannings"></CustomPageHeader>
        {!isLoading && (
          <div>
            <PlanningsInvoiceTable
              data={data}
              setData={setData}
              entities={invoiceTransform}
            />
          </div>
        )}
        {/* Admin Facturation Planning INVOICE TRANSFORM{" "}
      {JSON.stringify(invoiceTransform)}
      <br />
      <br />
      Plannings{JSON.stringify(data)} */}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default AdminFacturationPlannings;
