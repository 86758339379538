import {
  Button,
  Form,
  Input,
  Modal,
  Upload,
  Typography,
  Space,
  ColorPicker,
  ConfigProvider,
  Divider,
  Row,
  Col,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ColorThief from "colorthief";
import { useRef, useState } from "react";
import ProjectColorPicker from "../../ProjectColorPicker";

const { Text } = Typography;
const CreateProgramModal = ({
  visible,
  onCreate,
  setSelectedFile,
  onCancel,
  form,
  defaultColors,
  setDefaultColors,
  selectedColor,
  setSelectedColor,
  isColorPickerHidden,
  setIsColorPickerHidden,
}) => {
  const [fileList, setFileList] = useState([]);
  const [presetDefaultColors, setPresetDefaultColors] = useState([]);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const imageRef = useRef(null);
  const colorthief = new ColorThief();

  const dummyRequest = ({ file, onSuccess }) => {
    setSelectedFile(file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = () => {
    setIsColorPickerHidden(!isColorPickerHidden);
  };

  return (
    <Modal
      open={visible}
      title="Créer un nouveau programme"
      okText="Créer"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
          colors: defaultColors,
        }}
      >
        <Form.Item
          name="title"
          label="Nom"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le nom du nouveau programme",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="promotername"
          label="Nom du promoteur"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le nom du promoteur",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="upload" label="Image" valuePropName="filelist">
          <Space direction="vertical">
            <Upload
              name="logo"
              listType="picture"
              maxCount={1}
              customRequest={dummyRequest}
              fileList={fileList}
              onChange={(info) => {
                const reader = new FileReader();
                reader.onload = () => {
                  if (typeof reader.result === "string") {
                    imageRef.current.src = reader.result;
                  }
                };
                reader.readAsDataURL(info.file.originFileObj);
                setFileList(info.fileList);
              }}
              onRemove={() => {
                setFileList([]);
                imageRef.current.src = "";
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Sélectionner</Button>
            </Upload>
            <Text type="secondary">Choisissez votre plus belle image 👌</Text>
            <img
              ref={imageRef}
              style={{ display: "none" }}
              onLoad={async () => {
                if (imageRef.current) {
                  const dominantColorPalette = await colorthief.getPalette(
                    imageRef.current
                  );
                  const dominantColorRGB = dominantColorPalette.map(
                    (color) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`
                  );
                  setColorPickerVisible(true);
                  setSelectedColor(dominantColorRGB[0]);
                  setDefaultColors(dominantColorRGB);
                  setPresetDefaultColors(dominantColorRGB);
                  form.setFieldsValue({
                    colors: dominantColorRGB,
                  });
                }
              }}
              alt="Uploaded"
            />
          </Space>
        </Form.Item>
        <Space direction="vertical">
          <Space>
            <Switch onChange={onChange} defaultChecked={false} />
            Personnaliser la couleur des créneaux affichés à l’acquéreur
          </Space>
          <Form.Item
            name="colors"
            // label="Sélectionner la couleur des créneaux affichés à l’acquéreur sur sa page de prise de rendez-vous :"
            hidden={isColorPickerHidden}
            rules={[
              {
                required: true,
                message: "Veuillez renseigner la couleur du programme",
              },
            ]}
          >
            <ProjectColorPicker
              presetDefaultColors={presetDefaultColors}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              colorPickerVisible={colorPickerVisible}
              setColorPickerVisible={setColorPickerVisible}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateProgramModal;
