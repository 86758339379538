import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AdminMenu from "./Menu";
import { UserContext } from "../../../context/UserContext";
import PublicFooter from "../../../components/public/Footer";
import { Layout, Menu, Button, Space } from "antd";
import Breadcrumb from "./Breadcrumb";
import { LeftMenuData } from "./LeftMenuData";
import hasPermission from "../../../Routers/Roles";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../../App.css";

const { Header, Sider, Footer, Content } = Layout;
// TODO: Avoid map function returning twice the same code (by changing LayoutMenuData.js)

const AdministratorLayoutMenu = ({ children }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState();
  const [openKeys, setOpenKeys] = useState([]);
  const { role, collapsed, setCollapsed } = useContext(UserContext);

  const onCollapse = ({ children }) => {
    setCollapsed(!collapsed);
    localStorage.setItem("collapsed", !collapsed);
    if (collapsed) {
      setOpenKeys(["sub2"]);
    }
  };

  useEffect(() => {
    // console.log(`location.pathname : ${location.pathname}`);

    if (location.pathname === "/accueil-administrateur") {
      setSelectedKeys(["sub1"]);
    }

    if (location.pathname === "/admin/programmes") {
      setSelectedKeys(["sub2"]);
    }

    if (location.pathname === "/admin/plannings") {
      setSelectedKeys(["sub3"]);
    }

    if (location.pathname === "/admin/visites") {
      setSelectedKeys(["sub5"]);
    }

    if (location.pathname === "/admin/users") {
      setSelectedKeys(["sub6"]);
    }

    if (location.pathname.includes("/admin/logs")) {
      if (!collapsed) {
        setOpenKeys(["sub7"]);
      }
      if (location.pathname.includes("/rdvs")) {
        setSelectedKeys(["sub71"]);
      }
      if (location.pathname.includes("/visites")) {
        setSelectedKeys(["sub72"]);
      }
      if (location.pathname.includes("/trends")) {
        setSelectedKeys(["sub73"]);
      }
    }

    if (location.pathname.includes("/admin/permissions")) {
      if (!collapsed) {
        setOpenKeys(["sub8"]);
      }
      if (location.pathname.includes("/users")) {
        setSelectedKeys(["sub81"]);
      }
      if (location.pathname.includes("/programs")) {
        setSelectedKeys(["sub82"]);
      }
    }

    if (location.pathname.includes("/admin/facturation")) {
      if (!collapsed) {
        setOpenKeys(["sub9"]);
      }
      if (location.pathname.includes("/entities")) {
        setSelectedKeys(["sub91"]);
      }
      if (location.pathname.includes("/plannings")) {
        setSelectedKeys(["sub92"]);
      }
    }

    if (location.pathname.startsWith("/projets")) {
      if (!collapsed) {
        setOpenKeys(["sub4"]);
      }
      if (location.pathname.includes("clients")) {
        setSelectedKeys(["sub42"]);
      } else if (location.pathname.includes("messages")) {
        setSelectedKeys(["sub43"]);
      } else if (location.pathname.includes("plannings")) {
        setSelectedKeys(["sub44"]);
      } else if (location.pathname.includes("visites-3d")) {
        setSelectedKeys(["sub45"]);
      } else if (location.pathname.includes("parametres")) {
        setSelectedKeys(["sub46"]);
      } else {
        setSelectedKeys(["sub41"]);
      }
    }

    if (
      location.pathname.startsWith("/projets") &&
      location.pathname.split("/").length !== 5
    ) {
      if (location.pathname.split("/").length === 3) {
        setSelectedKeys(["sub41"]);
      }
      // console.log("passs here");
      // Open menu when one project is selected
      const regex = /^[a-f\d]{24}$/;
      let tab = location.pathname.split("/");
      let last = tab[tab.length - 2];
      let ret = last.match(regex);
      if (ret !== null && tab[tab.length - 1] === "plannings") {
        setCollapsed(false);
        localStorage.setItem("collapsed", false);
        setTimeout(function () {
          setOpenKeys(["sub4"]);
        }, 300);
        setSelectedKeys(["sub44"]);
      } else if (!collapsed) {
        setOpenKeys(["sub4"]);
      }
    } else if (
      !collapsed &&
      location.pathname.includes("plannings") &&
      !location.pathname.includes("/facturation")
    ) {
      setOpenKeys(["sub4"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const rootSubmenuKeys = [
    "sub1",
    "sub2",
    "sub3",
    "sub4",
    "sub5",
    "sub6",
    "sub7",
    "sub8",
    "sub9",
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onSelect = (info) => {
    setSelectedKeys([info.key]);
  };

  const leftMenuItems = LeftMenuData.map((item, index) => {
    if (item.items === undefined && hasPermission(role, item.path)) {
      return {
        label: <Link to={item.path}>{item.label}</Link>,
        key: item.key,
        icon: item.icon,
      };
    }

    if (item.items === undefined && hasPermission(role, item.path)) {
      return {
        // label: <Link to={item.path}>{`local --${item.label}`}</Link>,
        key: item.key,
        icon: item.icon,
      };
    } else if (
      item.items !== undefined &&
      item.items.map((x) => x.path).some((path) => hasPermission(role, path))
    ) {
      return {
        label: item.label,
        key: item.key,
        icon: item.icon,
        children: item.items.map((subItem, subIndex) => {
          return {
            label: <Link to={subItem.path}>{subItem.label}</Link>,
            key: item.key + item.items[subIndex].key,
            icon: subItem.icon,
          };
        }),
      };
    } else if (
      item.name === "currentProgram" &&
      localStorage.getItem("currentProgram") !== null
    ) {
      return {
        label: localStorage.getItem("currentProgram"),
        key: item.key,
        icon: item.icon,
        children: item.items.map((subItem, subIndex) => {
          return {
            label: (
              <Link
                to={`/projets/${localStorage.getItem("currentProgramId")}${
                  subItem.path
                }`}
              >
                {subItem.label}
              </Link>
            ),
            key: item.key + subItem.key,
          };
        }),
      };
    } else if (item.path !== undefined) {
      return {
        label: <Link to={item.path}>{item.label}</Link>,
        key: item.key,
        icon: item.icon,
      };
    }
  });

  const headerStyle = {
    textAlign: "center",
    color: "#fff",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#7dbcea",
  };
  const contentStyle = {
    textAlign: "center",
    minHeight: 120,
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "#108ee9",
  };
  const siderStyle = {
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "#3ba0e9",
  };
  const footerStyle = {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#7dbcea",
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider toggle={null} collapsed={collapsed} onCollapse={onCollapse}>
        <div>
          {collapsed ? (
            <Link to="/" style={{ color: "white" }}>
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="61px"
                style={{ paddingTop: "10%", paddingBottom: "10%" }}
                viewBox="0 0 264.09 263.94"
              >
                <title>Bridgit</title>
                <rect
                  className="cls-1"
                  x="0.15"
                  width="263.94"
                  height="263.94"
                  rx="1.74"
                />
                <path
                  className="cls-2"
                  d="M220.22,203.82V94.63c0-5.24-.15-8.44-.43-9.61q-1.75-3.93-7.43-3.93h-2.62a12,12,0,0,1-2.62.43l-17.47,4.37h-4.37q-.87-7.41-1.74-17.47c-1.75-22.71-2-14.37-2-32.42h51.32l28.83,19.32v48c1.15.88,3.34,2.34,6.55,4.37l9.61,6.55q10.9,7.44,16.59,10.92l3.22,3.22V256.59L158.34,299.94H74L54.26,286.8l-8.74-4.37c-4.08-2-5.66-3.21-11.77-8.74l-.15-4.24.15-6.3ZM137.24,99,63,122.14V81.52l6.55-2.18q28.39-9.6,50.23-15.28,10.91-3.06,17.47-4.37Zm43.68,74.24q-5.68,3.51-24,10-31.87,11.37-50.22,16.6Q75.22,209.06,63,209.06V173.24q5.67-3.48,24.46-10,31.46-11.35,49.79-17,31.45-8.73,43.68-8.73Z"
                  transform="translate(-33.6 -36)"
                />
              </svg>
            </Link>
          ) : (
            <Link to="/" style={{ color: "white" }}>
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="61px"
                style={{ paddingTop: "7%", paddingBottom: "7%" }}
                viewBox="0 0 84.17 24.82"
              >
                <title>Bridgit</title>
                <path
                  className="cls-2"
                  d="M264.59,408.59a11.76,11.76,0,0,1,2.54.25,5.42,5.42,0,0,1,2,.8,3.76,3.76,0,0,1,1.27,1.49,5.22,5.22,0,0,1,.45,2.29,4.29,4.29,0,0,1-.66,2.46,4.88,4.88,0,0,1-2,1.61,4.72,4.72,0,0,1,2.69,1.82,6,6,0,0,1,.31,5.68,5,5,0,0,1-1.54,1.76,7.08,7.08,0,0,1-2.21,1,9.92,9.92,0,0,1-2.56.32h-9.44V408.59Zm-.55,7.89a3.11,3.11,0,0,0,1.89-.54,2.08,2.08,0,0,0,.73-1.78,2.32,2.32,0,0,0-.24-1.12,1.73,1.73,0,0,0-.66-.68,2.69,2.69,0,0,0-.94-.34,5.63,5.63,0,0,0-1.1-.1h-4v4.56Zm.25,8.27a5.81,5.81,0,0,0,1.2-.12,2.94,2.94,0,0,0,1-.41,2.12,2.12,0,0,0,.7-.78,2.67,2.67,0,0,0,.26-1.25,2.55,2.55,0,0,0-.85-2.15,3.65,3.65,0,0,0-2.24-.64H259.7v5.35Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M277.64,414v2.62h.05a4.85,4.85,0,0,1,.74-1.21,4.64,4.64,0,0,1,1.07-1,5,5,0,0,1,1.28-.61,4.49,4.49,0,0,1,1.42-.22,3,3,0,0,1,.84.13v3.61c-.18,0-.4-.07-.65-.1s-.5,0-.74,0a4.07,4.07,0,0,0-1.8.36,3.07,3.07,0,0,0-1.19,1A3.89,3.89,0,0,0,278,420a7.63,7.63,0,0,0-.19,1.77v6.36H274V414Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M284.41,411.79v-3.2h3.88v3.2Zm3.88,2.18v14.11h-3.88V414Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M301.09,426.28a3.89,3.89,0,0,1-1.79,1.65,5.91,5.91,0,0,1-2.5.51,6.15,6.15,0,0,1-2.78-.61,5.64,5.64,0,0,1-2-1.67,7.46,7.46,0,0,1-1.17-2.42,9.9,9.9,0,0,1-.4-2.84,9.32,9.32,0,0,1,.4-2.74,7,7,0,0,1,1.17-2.33,5.66,5.66,0,0,1,4.68-2.24,5.6,5.6,0,0,1,2.39.53,4.2,4.2,0,0,1,1.79,1.57H301v-7.1h3.88v19.49h-3.69v-1.8Zm-.16-7a4.18,4.18,0,0,0-.56-1.42,3.28,3.28,0,0,0-1-1,3,3,0,0,0-1.58-.38,3.2,3.2,0,0,0-1.61.38,3,3,0,0,0-1,1,4.43,4.43,0,0,0-.58,1.43,8.4,8.4,0,0,0-.17,1.68,7,7,0,0,0,.19,1.64,4.55,4.55,0,0,0,.61,1.46,3.32,3.32,0,0,0,1.07,1,2.89,2.89,0,0,0,1.54.4,3,3,0,0,0,1.6-.39,2.85,2.85,0,0,0,1-1,4.56,4.56,0,0,0,.55-1.46,8.57,8.57,0,0,0,.16-1.69A8.26,8.26,0,0,0,300.93,419.31Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M320.81,429.12a4.73,4.73,0,0,1-1,2,6,6,0,0,1-2.21,1.59,9.51,9.51,0,0,1-3.88.66,9.24,9.24,0,0,1-2.12-.26,6.54,6.54,0,0,1-2-.81,4.94,4.94,0,0,1-1.48-1.4,4.06,4.06,0,0,1-.66-2.06h3.85a2.2,2.2,0,0,0,1.06,1.51,3.86,3.86,0,0,0,1.83.42,2.8,2.8,0,0,0,2.39-1,3.82,3.82,0,0,0,.72-2.48v-1.86h-.05a3.82,3.82,0,0,1-1.79,1.65,5.75,5.75,0,0,1-2.44.54,6.26,6.26,0,0,1-2.74-.56,5.33,5.33,0,0,1-1.88-1.53,6.45,6.45,0,0,1-1.08-2.27,11.06,11.06,0,0,1-.34-2.74,8.66,8.66,0,0,1,.4-2.64,6.91,6.91,0,0,1,1.16-2.24,5.61,5.61,0,0,1,1.9-1.54,5.69,5.69,0,0,1,2.6-.57,5.45,5.45,0,0,1,2.45.52,4.2,4.2,0,0,1,1.76,1.74h.05V414h3.69v13.21A9.14,9.14,0,0,1,320.81,429.12Zm-5.31-4.69a3,3,0,0,0,1-.88,3.8,3.8,0,0,0,.62-1.24,5.36,5.36,0,0,0,.2-1.46,8,8,0,0,0-.16-1.65,4,4,0,0,0-.55-1.38,2.82,2.82,0,0,0-1-1,3.27,3.27,0,0,0-1.58-.35,2.78,2.78,0,0,0-2.39,1.21,3.77,3.77,0,0,0-.57,1.29,6.18,6.18,0,0,0-.18,1.51,7.76,7.76,0,0,0,.15,1.52,4,4,0,0,0,.52,1.35,2.93,2.93,0,0,0,1,1,2.7,2.7,0,0,0,1.5.38A3.15,3.15,0,0,0,315.5,424.43Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M329,414l-3,14.11h-3.88l3-14.11Zm-3.47-2.18.71-3.2h3.91l-.71,3.2Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M339.58,414l-.6,2.59h-2.75l-1.45,7a2.35,2.35,0,0,0-.08.4,1.61,1.61,0,0,0,0,.25,1.16,1.16,0,0,0,.12.59.89.89,0,0,0,.32.32.94.94,0,0,0,.42.12H336a10.59,10.59,0,0,0,1.39-.08l-.65,3-1.2.09c-.41,0-.81,0-1.23,0a7.53,7.53,0,0,1-1.35-.12,3.64,3.64,0,0,1-1.21-.44,2.31,2.31,0,0,1-.84-.86,2.61,2.61,0,0,1-.29-1.42,3.58,3.58,0,0,1,.06-.61c0-.23.08-.47.13-.72l1.59-7.57h-2.54l.6-2.59h2.48l.88-4.29h3.87L336.8,414Z"
                  transform="translate(-255.42 -408.59)"
                />
              </svg>
            </Link>
          )}
        </div>
        <Menu
          theme="dark"
          onSelect={onSelect}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          mode="inline"
          items={leftMenuItems}
        ></Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "#fff",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="primary"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "8px",
                width: 32,
                height: 32,
                backgroundColor: "#071524",
                borderRadius: "0px 6px 6px 0px",
                //   color: "#436386",
              }}
            />
            <AdminMenu />
          </div>
        </Header>
        <Breadcrumb pathname={window.location.pathname} />
        {/* <Content
          style={{
            margin: "0px 16px",
            padding: 24,
            minHeight: 280,
            background: "white",
          }}
        > */}
        {children}
        {/* </Content> */}
        <PublicFooter />
      </Layout>
    </Layout>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div>
          {collapsed ? (
            <Link to="/" style={{ color: "white" }}>
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="64px"
                style={{ paddingTop: "10%", paddingBottom: "10%" }}
                viewBox="0 0 264.09 263.94"
              >
                <title>Bridgit</title>
                <rect
                  className="cls-1"
                  x="0.15"
                  width="263.94"
                  height="263.94"
                  rx="1.74"
                />
                <path
                  className="cls-2"
                  d="M220.22,203.82V94.63c0-5.24-.15-8.44-.43-9.61q-1.75-3.93-7.43-3.93h-2.62a12,12,0,0,1-2.62.43l-17.47,4.37h-4.37q-.87-7.41-1.74-17.47c-1.75-22.71-2-14.37-2-32.42h51.32l28.83,19.32v48c1.15.88,3.34,2.34,6.55,4.37l9.61,6.55q10.9,7.44,16.59,10.92l3.22,3.22V256.59L158.34,299.94H74L54.26,286.8l-8.74-4.37c-4.08-2-5.66-3.21-11.77-8.74l-.15-4.24.15-6.3ZM137.24,99,63,122.14V81.52l6.55-2.18q28.39-9.6,50.23-15.28,10.91-3.06,17.47-4.37Zm43.68,74.24q-5.68,3.51-24,10-31.87,11.37-50.22,16.6Q75.22,209.06,63,209.06V173.24q5.67-3.48,24.46-10,31.46-11.35,49.79-17,31.45-8.73,43.68-8.73Z"
                  transform="translate(-33.6 -36)"
                />
              </svg>
            </Link>
          ) : (
            <Link to="/" style={{ color: "white" }}>
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="64px"
                style={{ paddingTop: "7%", paddingBottom: "7%" }}
                viewBox="0 0 84.17 24.82"
              >
                <title>Bridgit</title>
                <path
                  className="cls-2"
                  d="M264.59,408.59a11.76,11.76,0,0,1,2.54.25,5.42,5.42,0,0,1,2,.8,3.76,3.76,0,0,1,1.27,1.49,5.22,5.22,0,0,1,.45,2.29,4.29,4.29,0,0,1-.66,2.46,4.88,4.88,0,0,1-2,1.61,4.72,4.72,0,0,1,2.69,1.82,6,6,0,0,1,.31,5.68,5,5,0,0,1-1.54,1.76,7.08,7.08,0,0,1-2.21,1,9.92,9.92,0,0,1-2.56.32h-9.44V408.59Zm-.55,7.89a3.11,3.11,0,0,0,1.89-.54,2.08,2.08,0,0,0,.73-1.78,2.32,2.32,0,0,0-.24-1.12,1.73,1.73,0,0,0-.66-.68,2.69,2.69,0,0,0-.94-.34,5.63,5.63,0,0,0-1.1-.1h-4v4.56Zm.25,8.27a5.81,5.81,0,0,0,1.2-.12,2.94,2.94,0,0,0,1-.41,2.12,2.12,0,0,0,.7-.78,2.67,2.67,0,0,0,.26-1.25,2.55,2.55,0,0,0-.85-2.15,3.65,3.65,0,0,0-2.24-.64H259.7v5.35Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M277.64,414v2.62h.05a4.85,4.85,0,0,1,.74-1.21,4.64,4.64,0,0,1,1.07-1,5,5,0,0,1,1.28-.61,4.49,4.49,0,0,1,1.42-.22,3,3,0,0,1,.84.13v3.61c-.18,0-.4-.07-.65-.1s-.5,0-.74,0a4.07,4.07,0,0,0-1.8.36,3.07,3.07,0,0,0-1.19,1A3.89,3.89,0,0,0,278,420a7.63,7.63,0,0,0-.19,1.77v6.36H274V414Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M284.41,411.79v-3.2h3.88v3.2Zm3.88,2.18v14.11h-3.88V414Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M301.09,426.28a3.89,3.89,0,0,1-1.79,1.65,5.91,5.91,0,0,1-2.5.51,6.15,6.15,0,0,1-2.78-.61,5.64,5.64,0,0,1-2-1.67,7.46,7.46,0,0,1-1.17-2.42,9.9,9.9,0,0,1-.4-2.84,9.32,9.32,0,0,1,.4-2.74,7,7,0,0,1,1.17-2.33,5.66,5.66,0,0,1,4.68-2.24,5.6,5.6,0,0,1,2.39.53,4.2,4.2,0,0,1,1.79,1.57H301v-7.1h3.88v19.49h-3.69v-1.8Zm-.16-7a4.18,4.18,0,0,0-.56-1.42,3.28,3.28,0,0,0-1-1,3,3,0,0,0-1.58-.38,3.2,3.2,0,0,0-1.61.38,3,3,0,0,0-1,1,4.43,4.43,0,0,0-.58,1.43,8.4,8.4,0,0,0-.17,1.68,7,7,0,0,0,.19,1.64,4.55,4.55,0,0,0,.61,1.46,3.32,3.32,0,0,0,1.07,1,2.89,2.89,0,0,0,1.54.4,3,3,0,0,0,1.6-.39,2.85,2.85,0,0,0,1-1,4.56,4.56,0,0,0,.55-1.46,8.57,8.57,0,0,0,.16-1.69A8.26,8.26,0,0,0,300.93,419.31Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M320.81,429.12a4.73,4.73,0,0,1-1,2,6,6,0,0,1-2.21,1.59,9.51,9.51,0,0,1-3.88.66,9.24,9.24,0,0,1-2.12-.26,6.54,6.54,0,0,1-2-.81,4.94,4.94,0,0,1-1.48-1.4,4.06,4.06,0,0,1-.66-2.06h3.85a2.2,2.2,0,0,0,1.06,1.51,3.86,3.86,0,0,0,1.83.42,2.8,2.8,0,0,0,2.39-1,3.82,3.82,0,0,0,.72-2.48v-1.86h-.05a3.82,3.82,0,0,1-1.79,1.65,5.75,5.75,0,0,1-2.44.54,6.26,6.26,0,0,1-2.74-.56,5.33,5.33,0,0,1-1.88-1.53,6.45,6.45,0,0,1-1.08-2.27,11.06,11.06,0,0,1-.34-2.74,8.66,8.66,0,0,1,.4-2.64,6.91,6.91,0,0,1,1.16-2.24,5.61,5.61,0,0,1,1.9-1.54,5.69,5.69,0,0,1,2.6-.57,5.45,5.45,0,0,1,2.45.52,4.2,4.2,0,0,1,1.76,1.74h.05V414h3.69v13.21A9.14,9.14,0,0,1,320.81,429.12Zm-5.31-4.69a3,3,0,0,0,1-.88,3.8,3.8,0,0,0,.62-1.24,5.36,5.36,0,0,0,.2-1.46,8,8,0,0,0-.16-1.65,4,4,0,0,0-.55-1.38,2.82,2.82,0,0,0-1-1,3.27,3.27,0,0,0-1.58-.35,2.78,2.78,0,0,0-2.39,1.21,3.77,3.77,0,0,0-.57,1.29,6.18,6.18,0,0,0-.18,1.51,7.76,7.76,0,0,0,.15,1.52,4,4,0,0,0,.52,1.35,2.93,2.93,0,0,0,1,1,2.7,2.7,0,0,0,1.5.38A3.15,3.15,0,0,0,315.5,424.43Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M329,414l-3,14.11h-3.88l3-14.11Zm-3.47-2.18.71-3.2h3.91l-.71,3.2Z"
                  transform="translate(-255.42 -408.59)"
                />
                <path
                  className="cls-2"
                  d="M339.58,414l-.6,2.59h-2.75l-1.45,7a2.35,2.35,0,0,0-.08.4,1.61,1.61,0,0,0,0,.25,1.16,1.16,0,0,0,.12.59.89.89,0,0,0,.32.32.94.94,0,0,0,.42.12H336a10.59,10.59,0,0,0,1.39-.08l-.65,3-1.2.09c-.41,0-.81,0-1.23,0a7.53,7.53,0,0,1-1.35-.12,3.64,3.64,0,0,1-1.21-.44,2.31,2.31,0,0,1-.84-.86,2.61,2.61,0,0,1-.29-1.42,3.58,3.58,0,0,1,.06-.61c0-.23.08-.47.13-.72l1.59-7.57h-2.54l.6-2.59h2.48l.88-4.29h3.87L336.8,414Z"
                  transform="translate(-255.42 -408.59)"
                />
              </svg>
            </Link>
          )}
        </div>
        <Menu
          // theme="dark"
          onSelect={onSelect}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          mode="inline"
          items={leftMenuItems}
        ></Menu>
      </Sider>

      <Layout>
        <Header
        // className="site-layout-background"
        // style={{
        //   theme: "dark",
        //   margin: "0px 0px 0px 0px",
        //   padding: 0,
        // }}
        >
          <AdminMenu />
        </Header>
        <Breadcrumb pathname={window.location.pathname} />
        {children}
        <PublicFooter />
      </Layout>
    </Layout>
  );
};

export default AdministratorLayoutMenu;
