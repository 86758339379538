import React, { useState } from "react";

export const UserContext = React.createContext();

// console.log(`OPEN CONTEXT`);

const getInitialNotifications = () => {
  const initialNotifications = {
    actualite: [],
    tmas: [],
    choix: [],
    messages: [],
    documents: [],
    chantier: [],
  };
  let found = localStorage.getItem("notifications");
  if (found === null) {
    localStorage.setItem("notifications", JSON.stringify(initialNotifications));
    return initialNotifications;
  } else {
    return JSON.parse(found);
  }
};

const getCollapsed = () => {
  let found = localStorage.getItem("collapsed");
  if (found === null) {
    localStorage.setItem("collapsed", true);
    return true;
  } else {
    if (found === "true") {
      return true;
    } else {
      return false;
    }
  }
};

export const UserContextProvider = ({ children }) => {
  const [isAuth, setAuth] = useState(false);
  const [role, setRole] = useState("anonymousUser");
  const [username, setUsername] = useState("Anonymous");
  const [notifications, setNotifications] = useState(getInitialNotifications());
  const [collapsed, setCollapsed] = useState(getCollapsed());

  return (
    <UserContext.Provider
      value={{
        isAuth,
        setAuth,
        role,
        setRole,
        setUsername,
        username,
        notifications,
        setNotifications,
        collapsed,
        setCollapsed,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
