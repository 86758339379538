import React from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import ContentContainer from "../../ContentContainer";
import PublicFooter from "../../public/Footer";
import BookingAvailabilities from "./BookingAvailabilities";
import GDPRBlaBla from "./GDPRBlaBla";
import BookingAvailabilitiesMobile from "./BookingAvailabilitiesMobile";
import SubContentContainer from "./../../../../src/components/SubContentContainer";
import {
  MinusCircleOutlined,
  PlusOutlined,
  ToolOutlined,
  StopOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Layout,
  Button,
  Card,
  Alert,
  Space,
  Popconfirm,
  Spin,
  Result,
  Checkbox,
  Select,
  Form,
  Input,
  Divider,
  ConfigProvider,
} from "antd";
var FileSaver = require("file-saver");
const ics = require("ics");
const { Text, Title } = Typography;
const { Option } = Select;

const PromoterTemplate = ({
  dateExpired,
  hasLoaded,
  isMobileView,
  isAlreadyBooked,
  planning,
  rdv,
  rdvDate,
  urlLogo,
  phaseTransform,
  isFound,
  selectedSlot,
  onFinish,
  clickYes,
  clickNo,
  sayIsNotAvailable,
  setSayIsNotAvailable,
  optionsPerDays,
  setOptionsPerDays,
  options,
  setOptions,
  setSelectedSlot,
  cancel,
  deScheduleRdv,
  notAvailableRdv,
  closeWindow,
  form,
  nbRemainingVisitors,
  setNbRemainingVisitors,
  fillForm,
  setFillForm,
  clickNotAvailable,
  clickAvailable,
  amos,
  selectedAmo,
  setSelectedAmo,
  promoterUrl,
  isActive,
}) => {
  const shoeSizes = ["inconnue", 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45];
  const onVisitorsChange = () => {
    console.log(form.getFieldsValue().visitors.length);
    setNbRemainingVisitors(
      rdv.maxVisitorsNb - form.getFieldsValue().visitors.length
    );
  };

  const yourContact = `${
    !planning.contactNom && !planning.contactPrenom
      ? `${planning.promoter.toUpperCase()}`
      : `${planning.contactNom} ${planning.contactPrenom} (${
          planning.contactTelephone
            ? "📞 " +
              planning.contactTelephone +
              (planning.contactMail ? " / " : "")
            : ""
        }${planning.contactMail ? "✉ " + planning.contactMail : ""})`
  }`;

  const onClickIcsCalendar = () => {
    ics.createEvent(
      {
        title: `${planning.promoter.toUpperCase()} visite de ${
          phaseTransform[planning.phase]
        } de votre logement ${rdv.guest.lotcomm} (Bat.${rdv.guest.batiment})`,
        description:
          "Votre contact : " +
          yourContact +
          ` \n \n Pour annuler ou déplacer votre RDV, cliquez sur le lien ci-dessous :\n https://bridg-it.fr/entrevue/${rdv.uuid}`,
        start: moment(new Date(rdv.start))
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((_) => parseInt(_)),
        end: moment(new Date(rdv.end))
          .format("YYYY-M-D-H-m")
          .split("-")
          .map((_) => parseInt(_)),
        location: `${planning.address.numero} ${planning.address.rue} ${planning.address.ville} ${planning.address.codepostal}`,
        alarms: [
          {
            action: "display",
            trigger: {
              days: 2,
              before: true,
            },
          },
          {
            action: "display",
            trigger: { hours: 2, minutes: 0, before: true },
          },
        ],
      },
      (error, value) => {
        if (error) {
          console.log(error);
        }

        var blob = new Blob([value], { type: "text/calendar" });
        FileSaver.saveAs(
          blob,
          `${planning.promoter.toUpperCase()} ${planning.name}.ics`
        );
      }
    );
  };

  // console.log(`date du rdv : ${rdvDate}`);
  // console.log(`selectedSlot : ${JSON.stringify(selectedSlot)}`);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  if (!isActive && hasLoaded) {
    if (isMobileView) {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card
                  hoverable
                  style={{ width: "100%", height: "100%", border: "none" }}
                >
                  <img
                    alt="promoteur"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    // onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message="Votre rendez-vous a été désactivé"
                    description={`Veuillez contacter ${yourContact}`}
                    type="warning"
                    showIcon
                  />
                </Space>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    } else {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card hoverable style={{ width: 300, border: "none" }}>
                  <img
                    alt="promoteur"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message="Votre rendez-vous a été désactivé"
                    description={`Veuillez contacter ${yourContact}`}
                    type="warning"
                    showIcon
                  />
                </Space>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    }
  } else if (dateExpired && hasLoaded) {
    if (isMobileView && isAlreadyBooked) {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card
                  hoverable
                  style={{ width: "100%", height: "100%", border: "none" }}
                >
                  <img
                    alt="logo"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    // onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message={`Vous avez pris rendez-vous le : ${momentTz(
                      rdvDate
                    )
                      .tz("Europe/Paris")
                      .format("DD/MM HH:mm")}`}
                    type="success"
                    showIcon
                  />
                  <p>
                    Si vous n'êtes finalement pas disponible à cette date,
                    <br />
                    Veuillez contacter {yourContact}
                  </p>
                </Space>
                <Button onClick={onClickIcsCalendar}>
                  <CalendarOutlined />
                  Ajouter au calendrier
                </Button>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    } else if (isAlreadyBooked) {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card hoverable style={{ width: 300, border: "none" }}>
                  <img
                    alt="urlLogo"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message={`Vous avez pris rendez-vous le : ${momentTz(
                      rdvDate
                    )
                      .tz("Europe/Paris")
                      .format("DD/MM HH:mm")}`}
                    type="success"
                    showIcon
                  />

                  <p>
                    Si vous n'êtes finalement pas disponible à cette date,
                    <br />
                    Veuillez contacter {yourContact}
                  </p>
                  <Button onClick={onClickIcsCalendar}>
                    <CalendarOutlined />
                    Ajouter au calendrier
                  </Button>
                </Space>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    } else {
      if (isMobileView) {
        return (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout className="site-layout">
              <ContentContainer>
                <center>
                  <Card
                    hoverable
                    style={{ width: "100%", height: "100%", border: "none" }}
                  >
                    <img
                      alt="promoteur"
                      src={urlLogo}
                      width="100%"
                      height="100%"
                      // onClick={() => window.open(promoterUrl, "_blank")}
                    />
                  </Card>
                  <Title level={3}>
                    {planning.name} ({phaseTransform[planning.phase]})
                  </Title>
                  <p>
                    Acquéreur: <b>{rdv.guest.email}</b>
                  </p>
                  <p>
                    Les prises de rendez-vous sont clôturées. <br />
                    Veuillez contacter {yourContact}
                  </p>
                </center>
              </ContentContainer>
              <PublicFooter />
            </Layout>
          </Layout>
        );
      } else {
        return (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout className="site-layout">
              <ContentContainer>
                <center>
                  <Card hoverable style={{ width: 300, border: "none" }}>
                    <img
                      alt="logo"
                      src={urlLogo}
                      width="100%"
                      height="100%"
                      onClick={() => window.open(promoterUrl, "_blank")}
                    />
                  </Card>
                  <Title level={3}>
                    {planning.name} ({phaseTransform[planning.phase]})
                  </Title>
                  <p>
                    Acquéreur: <b>{rdv.guest.email}</b>
                  </p>
                  <p>
                    Les prises de rendez-vous sont clôturées. <br />
                    Veuillez contacter {yourContact}
                  </p>
                </center>
              </ContentContainer>
              <PublicFooter />
            </Layout>
          </Layout>
        );
      }
    }
  } else if (isFound && hasLoaded && !isAlreadyBooked) {
    return (
      <div>
        {!isMobileView ? (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout className="site-layout">
              <ContentContainer>
                <center>
                  <Card hoverable style={{ width: 300, border: "none" }}>
                    <img
                      alt="logo"
                      src={urlLogo}
                      onClick={() => window.open(promoterUrl, "_blank")}
                    />
                  </Card>
                  <br />
                  <br />
                  <Title level={2}>{planning.name}</Title>
                  <Title level={4}>
                    Rendez-vous visite {phaseTransform[planning.phase]} pour{" "}
                    {rdv.guest.nom} (Lot: {rdv.guest.lotcomm}, durée :{" "}
                    {moment
                      .utc(
                        moment
                          .duration(rdv.length / 1000, "seconds")
                          .asMilliseconds()
                      )
                      .format("H")}
                    h
                    {moment
                      .utc(
                        moment
                          .duration(rdv.length / 1000, "seconds")
                          .asMilliseconds()
                      )
                      .format("mm")}
                    )
                  </Title>
                  {selectedSlot !== null ? (
                    <div>
                      <Space direction="vertical">
                        <Alert
                          message={
                            "Vous avez sélectionné le : " + selectedSlot.label
                          }
                          type="info"
                          showIcon
                        />
                        {planning.questions.length > 1 ||
                          (planning.questions.length === 1 &&
                            !planning.questions.includes(
                              "Demande visite 3D si indisponible"
                            ) && (
                              <Alert
                                message="Dans le cadre de ce rendez-vous, il vous est demandé de répondre aux questions ci-dessous :"
                                type="warning"
                                showIcon
                              />
                            ))}

                        {planning.questions.length > 0 && (
                          <Form
                            form={form}
                            name="dynamic_form_nest_item"
                            // layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                              isInterestedVr: false,
                            }}
                            // onValuesChange={handleFormValuesChange}
                          >
                            {planning.questions.includes(
                              "Sondage visite virtuelle"
                            ) && (
                              <Form.Item
                                name="isInterestedVr"
                                label="Seriez-vous intéressé pour : "
                                valuePropName="checked"
                                required
                                tooltip="Ceci est un sondage, en aucun cas il ne constitue un engagement de prestation"
                              >
                                <Checkbox>Une visite virtuelle</Checkbox>
                              </Form.Item>
                            )}
                            {planning.questions.includes(
                              "Demande taille de chaussures"
                            ) && (
                              <div>
                                <Form.Item
                                  name="shoeSize"
                                  label="Pointure de chaussures acquéreur&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                  // required
                                  tooltip="Pour des raisons de sécurité, nous vous prêtons des bottes pour votre visite de chantier"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Pointure manquante",
                                  //   },
                                  // ]}
                                  //   fieldKey={[fieldKey, "size"]}
                                >
                                  <Select placeholder="Sélectionner">
                                    {shoeSizes.map((shoeSize, idx) => {
                                      return (
                                        <Option
                                          key={idx + "-1-" + shoeSize}
                                          value={shoeSize}
                                        >
                                          {shoeSize}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="shoeSize2"
                                  label="Pointure de chaussures co-acquéreur"
                                  // required
                                  tooltip="Pour des raisons de sécurité, nous vous prêtons des bottes pour votre visite de chantier"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Pointure manquante",
                                  //   },
                                  // ]}
                                  //   fieldKey={[fieldKey, "size"]}
                                >
                                  <Select placeholder="Sélectionner">
                                    {shoeSizes.map((shoeSize, idx) => {
                                      return (
                                        <Option
                                          key={idx + "-2-" + shoeSize}
                                          value={shoeSize}
                                        >
                                          {shoeSize}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            )}

                            {planning.questions.includes("Accompagnants") && (
                              <Form.List
                                name="visitors"

                                // initialValues={radioValue}
                                // on
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({
                                        key,
                                        name,
                                        fieldKey,
                                        ...restField
                                      }) => (
                                        <div key={key}>
                                          <Space
                                            key={key}
                                            style={{
                                              display: "flex",
                                              marginBottom: 8,
                                            }}
                                            align="baseline"
                                          >
                                            <Form.Item
                                              {...restField}
                                              name={[name, "role"]}
                                              fieldKey={[fieldKey, "role"]}
                                            >
                                              <Select
                                                placeholder="Nature"
                                                // onChange={() => {
                                                //   console.log(
                                                //     `changed : ${JSON.stringify(
                                                //       form.getFieldsValue(
                                                //         fieldKey
                                                //       )
                                                //     )}`
                                                //   );
                                                // }}
                                              >
                                                <Option
                                                  key="cuisiniste"
                                                  value="cuisiniste"
                                                >
                                                  Cuisiniste
                                                </Option>
                                                <Option
                                                  key="architecte"
                                                  value="architecte"
                                                >
                                                  Architecte
                                                </Option>
                                                <Option
                                                  key="familleami"
                                                  value="familleami"
                                                >
                                                  Ami/Famille
                                                </Option>
                                                <Option
                                                  key="autre"
                                                  value="autre"
                                                >
                                                  Autre
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "nom"]}
                                              fieldKey={[fieldKey, "nom"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Nom manquant",
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Nom" />
                                            </Form.Item>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "prenom"]}
                                              fieldKey={[fieldKey, "prenom"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Prénom manquant",
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Prénom" />
                                            </Form.Item>

                                            {planning.questions.includes(
                                              "Demande taille de chaussures"
                                            ) && (
                                              <Form.Item
                                                {...restField}
                                                name={[name, "shoeSize"]}
                                                fieldKey={[
                                                  fieldKey,
                                                  "shoeSize",
                                                ]}
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message:
                                                //       "Pointure manquante",
                                                //   },
                                                // ]}
                                              >
                                                <Select placeholder="Pointure">
                                                  {shoeSizes.map(
                                                    (shoeSize, idx) => {
                                                      return (
                                                        <Option
                                                          key={
                                                            idx + "-" + shoeSize
                                                          }
                                                          value={shoeSize}
                                                        >
                                                          {shoeSize}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </Form.Item>
                                            )}

                                            <Form.Item
                                              {...restField}
                                              name={[name, "email"]}
                                              fieldKey={[fieldKey, "email"]}
                                              extra="Dans le cas d'un cuisiniste, architecte merci d'indiquer le
                                              mail"
                                            >
                                              <Input placeholder="Email (optionnel)" />
                                            </Form.Item>
                                            <MinusCircleOutlined
                                              onClick={() => {
                                                remove(name);
                                                onVisitorsChange();
                                              }}
                                            />
                                          </Space>
                                        </div>
                                      )
                                    )}

                                    {nbRemainingVisitors > 0 && (
                                      <Form.Item>
                                        <p>
                                          <Text strong>
                                            Accompagnants (en sus de(s)
                                            acquéreur(s)):
                                          </Text>
                                        </p>
                                        <Button
                                          type="dashed"
                                          onClick={() => {
                                            add();
                                            onVisitorsChange();
                                          }}
                                          block
                                          icon={<PlusOutlined />}
                                        >
                                          Ajouter un accompagnant (
                                          {nbRemainingVisitors}
                                          {nbRemainingVisitors > 1
                                            ? " restants"
                                            : " restant"}{" "}
                                          )
                                        </Button>
                                      </Form.Item>
                                    )}
                                  </>
                                )}
                              </Form.List>
                            )}
                          </Form>
                        )}
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#436386",
                            },
                          }}
                        >
                          <Space>
                            <Button
                              onClick={clickYes}
                              size="large"
                              type="primary"
                            >
                              Valider
                            </Button>
                            <Button
                              onClick={clickNo}
                              size="large"
                              type="primary"
                              danger
                            >
                              Retour
                            </Button>
                          </Space>
                        </ConfigProvider>
                      </Space>
                    </div>
                  ) : (
                    <div>
                      {fillForm && (
                        <Space direction="vertical">
                          <Form
                            // {...layout}
                            layout="vertical"
                            // style={{ width: "100%", heigth: "100%" }}
                            form={form}
                            name="nest-messages"
                            onFinish={clickNotAvailable}
                            validateMessages={validateMessages}
                            initialValues={{
                              isInterestedVr: false,
                            }}
                          >
                            <Form.Item
                              name={"note"}
                              label="Veuillez indiquer ci-dessous vos disponibilités et remarques éventuelles, vous serez ensuite rappelé(e) :"
                            >
                              <Input.TextArea />
                            </Form.Item>
                            {planning.questions.includes(
                              "Demande visite 3D si indisponible"
                            ) && (
                              <Form.Item
                                name="isInterestedVr"
                                label="Dans le cas où vous ne pourriez pas vous déplacer, seriez-vous intéressé pour une visite virtuelle 3D : "
                                valuePropName="checked"
                              >
                                <Checkbox>Oui</Checkbox>
                              </Form.Item>
                            )}

                            <Form.Item
                            // wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
                            >
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorPrimary: "#436386",
                                  },
                                }}
                              >
                                <Space direction="horizontal">
                                  <Button type="primary" htmlType="submit">
                                    Envoyer
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setFillForm(false);
                                      setSayIsNotAvailable(false);
                                    }}
                                    type="primary"
                                    danger
                                  >
                                    Retour
                                  </Button>
                                </Space>
                              </ConfigProvider>
                            </Form.Item>
                          </Form>
                        </Space>
                      )}
                      {((!fillForm && sayIsNotAvailable) ||
                        !rdv.isAvailable) && (
                        <div>
                          <Title level={4}>
                            Merci ! Votre interlocuteur ({planning.contactNom}{" "}
                            {planning.contactPrenom}) vous recontactera dans les
                            plus brefs délais.
                          </Title>
                          <br />
                          <div>
                            Vous êtes finalement disponible(s) ?{" "}
                            <Button type="link" onClick={clickAvailable}>
                              Cliquez ici
                            </Button>
                          </div>
                        </div>
                      )}
                      {selectedSlot === null &&
                        !sayIsNotAvailable &&
                        rdv.isAvailable && (
                          <div>
                            <Title level={5}>
                              Veuillez choisir une disponibilité parmi les
                              options ci-dessous:
                            </Title>

                            <BookingAvailabilities
                              optionsPerDays={optionsPerDays}
                              setOptionsPerDays={setOptionsPerDays}
                              options={options}
                              setOptions={setOptions}
                              setSelectedSlot={setSelectedSlot}
                              selectedSlot={selectedSlot}
                              amos={amos}
                              selectedAmo={selectedAmo}
                              setSelectedAmo={setSelectedAmo}
                              planning={planning}
                              rdv={rdv}
                            />
                          </div>
                        )}
                      {selectedSlot === null &&
                        !sayIsNotAvailable &&
                        rdv.isAvailable && (
                          <div>
                            <br />
                            <div>
                              Vous n'êtes vraiment pas disponible aux horaires
                              proposés ci-dessus ?{" "}
                              <Button type="link" onClick={notAvailableRdv}>
                                Cliquez ici
                              </Button>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  <GDPRBlaBla rdv={rdv} />
                </center>
              </ContentContainer>
              <PublicFooter />
            </Layout>
          </Layout>
        ) : (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout className="site-layout">
              <ContentContainer>
                <SubContentContainer>
                  <center>
                    <Card
                      hoverable
                      style={{ width: "100%", height: "100%", border: "none" }}
                      // onClick={window.open(promoterUrl, "_blank")}
                    >
                      <img
                        alt="promoteur"
                        src={urlLogo}
                        width="100%"
                        height="100%"
                      />
                    </Card>
                    <br />
                    <Title level={2}>{planning.name}</Title>

                    <Title level={4}>
                      Rendez-vous visite {phaseTransform[planning.phase]} pour{" "}
                      {rdv.guest.nom} (Lot: {rdv.guest.lotcomm}, durée :{" "}
                      {moment
                        .utc(
                          moment
                            .duration(rdv.length / 1000, "seconds")
                            .asMilliseconds()
                        )
                        .format("H")}
                      h
                      {moment
                        .utc(
                          moment
                            .duration(rdv.length / 1000, "seconds")
                            .asMilliseconds()
                        )
                        .format("mm")}
                      )
                    </Title>

                    {fillForm && (
                      <Space direction="vertical">
                        <Form
                          // {...layout}
                          layout="vertical"
                          // style={{ width: "100%", heigth: "100%" }}
                          form={form}
                          name="nest-messages"
                          onFinish={clickNotAvailable}
                          validateMessages={validateMessages}
                          initialValues={{
                            isInterestedVr: false,
                          }}
                        >
                          <Form.Item
                            name={"note"}
                            label="Veuillez indiquer ci-dessous vos disponibilités et remarques éventuelles, vous serez ensuite rappelé(e) :"
                          >
                            <Input.TextArea />
                          </Form.Item>
                          {planning.questions.includes(
                            "Demande visite 3D si indisponible"
                          ) && (
                            <Form.Item
                              name="isInterestedVr"
                              label="Dans le cas où vous ne pourriez pas vous déplacer, seriez-vous intéressé pour une visite virtuelle 3D : "
                              valuePropName="checked"
                            >
                              <Checkbox>Oui</Checkbox>
                            </Form.Item>
                          )}
                          <Form.Item
                          // wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
                          >
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: "#436386",
                                },
                              }}
                            >
                              <Space direction="horizontal">
                                <Button type="primary" htmlType="submit">
                                  Envoyer
                                </Button>
                                <Button
                                  onClick={() => {
                                    setFillForm(false);
                                    setSayIsNotAvailable(false);
                                  }}
                                  type="primary"
                                  danger
                                >
                                  Retour
                                </Button>
                              </Space>
                            </ConfigProvider>
                          </Form.Item>
                        </Form>
                      </Space>
                    )}
                    {((!fillForm && sayIsNotAvailable) || !rdv.isAvailable) && (
                      <div>
                        <Title level={4}>
                          Merci ! Votre interlocuteur ({planning.contactNom}{" "}
                          {planning.contactPrenom}) vous recontactera dans les
                          plus brefs délais.
                        </Title>
                        <br />
                        <div>
                          Vous êtes finalement disponible(s) ?{" "}
                          <Button type="link" onClick={clickAvailable}>
                            Cliquez ici
                          </Button>
                        </div>
                      </div>
                    )}
                    {selectedSlot === null &&
                      !sayIsNotAvailable &&
                      rdv.isAvailable && (
                        <div>
                          <Title level={5}>
                            Veuillez choisir une disponibilité parmi les options
                            ci-dessous:
                          </Title>

                          <BookingAvailabilitiesMobile
                            optionsPerDays={optionsPerDays}
                            setOptionsPerDays={setOptionsPerDays}
                            options={options}
                            setOptions={setOptions}
                            setSelectedSlot={setSelectedSlot}
                            selectedSlot={selectedSlot}
                            amos={amos}
                            selectedAmo={selectedAmo}
                            setSelectedAmo={setSelectedAmo}
                            planning={planning}
                            rdv={rdv}
                          />
                        </div>
                      )}
                    {selectedSlot === null &&
                      !sayIsNotAvailable &&
                      rdv.isAvailable && (
                        <div>
                          <br />
                          <div>
                            Vous n'êtes vraiment pas disponible aux horaires
                            proposés ci-dessus ?{" "}
                            <Button type="link" onClick={notAvailableRdv}>
                              Cliquez ici
                            </Button>
                          </div>
                        </div>
                      )}
                    {selectedSlot !== null && (
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Alert
                          message={
                            "Vous avez sélectionné le : " + selectedSlot.label
                          }
                          type="info"
                          showIcon
                        />

                        {planning.questions.length > 1 ||
                          (planning.questions.length === 1 &&
                            !planning.questions.includes(
                              "Demande visite 3D si indisponible"
                            ) && (
                              <Alert
                                message="Dans le cadre de ce rendez-vous, il vous est demandé de répondre aux questions ci-dessous :"
                                type="warning"
                                showIcon
                              />
                            ))}

                        {planning.questions.length > 0 && (
                          <Form
                            form={form}
                            name="dynamic_form_nest_item"
                            // layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                              isInterestedVr: false,
                            }}
                            // onValuesChange={handleFormValuesChange}
                          >
                            {planning.questions.includes(
                              "Sondage visite virtuelle"
                            ) && (
                              <Form.Item
                                name="isInterestedVr"
                                label="Seriez-vous intéressé pour : "
                                valuePropName="checked"
                                required
                                tooltip="Ceci est un sondage."
                              >
                                <Checkbox>Une visite virtuelle</Checkbox>
                              </Form.Item>
                            )}
                            {planning.questions.includes(
                              "Demande taille de chaussures"
                            ) && (
                              <div>
                                <Form.Item
                                  name="shoeSize"
                                  label="Pointure de chaussures acquéreur&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                  // required
                                  tooltip="Pour des raisons de sécurité, nous vous prêtons des bottes pour votre visite de chantier"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Pointure manquante",
                                  //   },
                                  // ]}
                                  //   fieldKey={[fieldKey, "size"]}
                                >
                                  <Select placeholder="Sélectionner">
                                    {shoeSizes.map((shoeSize, idx) => {
                                      return (
                                        <Option
                                          key={idx + "-1-" + shoeSize}
                                          value={shoeSize}
                                        >
                                          {shoeSize}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="shoeSize2"
                                  label="Pointure de chaussures co-acquéreur"
                                  // required
                                  tooltip="Pour des raisons de sécurité, nous vous prêtons des bottes pour votre visite de chantier"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Pointure manquante",
                                  //   },
                                  // ]}
                                  //   fieldKey={[fieldKey, "size"]}
                                >
                                  <Select placeholder="Sélectionner">
                                    {shoeSizes.map((shoeSize, idx) => {
                                      return (
                                        <Option
                                          key={idx + "-2-" + shoeSize}
                                          value={shoeSize}
                                        >
                                          {shoeSize}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            )}

                            {planning.questions.includes("Accompagnants") && (
                              <Form.List
                                name="visitors"

                                // initialValues={radioValue}
                                // on
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({
                                        key,
                                        name,
                                        fieldKey,
                                        ...restField
                                      }) => (
                                        <div key={key}>
                                          <Divider>
                                            Accompagnant {parseInt(name) + 1}
                                          </Divider>
                                          <Space
                                            key={key}
                                            style={{
                                              display: "flex",
                                              marginBottom: 8,
                                            }}
                                            align="baseline"
                                            direction="vertical"
                                          >
                                            <Form.Item
                                              {...restField}
                                              name={[name, "role"]}
                                              fieldKey={[fieldKey, "role"]}
                                            >
                                              <Select
                                                placeholder="Nature"
                                                // onChange={() => {
                                                //   console.log(
                                                //     `changed : ${JSON.stringify(
                                                //       form.getFieldsValue(
                                                //         fieldKey
                                                //       )
                                                //     )}`
                                                //   );
                                                // }}
                                              >
                                                <Option
                                                  key="cuisiniste"
                                                  value="cuisiniste"
                                                >
                                                  Cuisiniste
                                                </Option>
                                                <Option
                                                  key="architecte"
                                                  value="architecte"
                                                >
                                                  Architecte
                                                </Option>
                                                <Option
                                                  key="familleami"
                                                  value="familleami"
                                                >
                                                  Ami/Famille
                                                </Option>
                                                <Option
                                                  key="autre"
                                                  value="autre"
                                                >
                                                  Autre
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "nom"]}
                                              fieldKey={[fieldKey, "nom"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Nom manquant",
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Nom" />
                                            </Form.Item>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "prenom"]}
                                              fieldKey={[fieldKey, "prenom"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Prénom manquant",
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Prénom" />
                                            </Form.Item>

                                            {planning.questions.includes(
                                              "Demande taille de chaussures"
                                            ) && (
                                              <Form.Item
                                                {...restField}
                                                name={[name, "shoeSize"]}
                                                fieldKey={[
                                                  fieldKey,
                                                  "shoeSize",
                                                ]}
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Pointure manquante",
                                                //   },
                                                // ]}
                                                // extra="Pointure"
                                              >
                                                <Select placeholder="Pointure">
                                                  {shoeSizes.map(
                                                    (shoeSize, idx) => {
                                                      return (
                                                        <Option
                                                          key={
                                                            idx + "-" + shoeSize
                                                          }
                                                          value={shoeSize}
                                                        >
                                                          {shoeSize}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </Form.Item>
                                            )}

                                            <Form.Item
                                              {...restField}
                                              name={[name, "email"]}
                                              fieldKey={[fieldKey, "email"]}
                                              extra="Dans le cas d'un cuisiniste, architecte merci d'indiquer le
                                              mail"
                                            >
                                              <Input placeholder="Email (optionnel)" />
                                            </Form.Item>

                                            {/* <MinusCircleOutlined
                                              onClick={() => {
                                                remove(name);
                                                onVisitorsChange();
                                              }}
                                            /> */}
                                            <Button
                                              // type="primary"
                                              danger
                                              icon={<StopOutlined />}
                                              onClick={() => {
                                                remove(name);
                                                onVisitorsChange();
                                              }}
                                            >
                                              Supprimer accompagnant
                                            </Button>
                                            <br />
                                          </Space>
                                        </div>
                                      )
                                    )}

                                    {nbRemainingVisitors > 0 && (
                                      <Form.Item>
                                        <p>
                                          <Text strong>
                                            Accompagnants (en sus de(s)
                                            acquéreur(s)):
                                          </Text>
                                        </p>
                                        <Button
                                          block={true}
                                          type="dashed"
                                          onClick={() => {
                                            add();
                                            onVisitorsChange();
                                          }}
                                          icon={<PlusOutlined />}
                                        >
                                          Ajouter un accompagnant (
                                          {nbRemainingVisitors}
                                          {nbRemainingVisitors > 1
                                            ? " restants"
                                            : " restant"}{" "}
                                          )
                                        </Button>
                                      </Form.Item>
                                    )}
                                  </>
                                )}
                              </Form.List>
                            )}
                          </Form>
                        )}

                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#436386",
                            },
                          }}
                        >
                          <Space>
                            <Button
                              onClick={clickYes}
                              size="large"
                              type="primary"
                            >
                              Valider
                            </Button>
                            <Button
                              onClick={clickNo}
                              size="large"
                              type="primary"
                              danger
                            >
                              Retour
                            </Button>
                          </Space>
                        </ConfigProvider>
                      </Space>
                    )}

                    <GDPRBlaBla rdv={rdv} />
                  </center>
                </SubContentContainer>
              </ContentContainer>
              <PublicFooter />
            </Layout>
          </Layout>
        )}
      </div>
    );
  } else if (hasLoaded && !isFound && !isAlreadyBooked) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <ContentContainer>
            <div>
              <Result
                icon={<ToolOutlined />}
                title="Rendez-vous non trouvé"
                subTitle={
                  <div>
                    Remarque : Ce message peut parfois apparaître si vous
                    utilisez un VPN.
                    <br />
                    Veuillez dans ce cas essayer avec une connexion internet à
                    domicile ou un partage de connexion depuis votre téléphone.
                  </div>
                }
                extra={
                  <Button type="primary" onClick={closeWindow}>
                    Quitter
                  </Button>
                }
              />
            </div>
          </ContentContainer>
          <PublicFooter />
        </Layout>
      </Layout>
    );
  } else if (isAlreadyBooked && hasLoaded) {
    if (isMobileView) {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card
                  hoverable
                  style={{ width: "100%", height: "100%", border: "none" }}
                >
                  <img
                    alt="promoteur"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    // onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message={`Vous avez pris rendez-vous le : ${momentTz(
                      rdvDate
                    )
                      .tz("Europe/Paris")
                      .format("DD/MM HH:mm")}`}
                    type="success"
                    showIcon
                  />
                  {rdv.forced === null && (
                    <div>
                      Vous n'êtes finalement pas disponible à cette date ?{" "}
                      <Popconfirm
                        title="Etes-vous bien sûr(e) de vouloir annuler votre rendez-vous ?"
                        onConfirm={deScheduleRdv}
                        onCancel={cancel}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <a>Cliquez ici</a>
                      </Popconfirm>
                    </div>
                  )}
                  <Button onClick={onClickIcsCalendar}>
                    <CalendarOutlined />
                    Ajouter au calendrier
                  </Button>
                </Space>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    } else {
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <ContentContainer>
              <center>
                <Card hoverable style={{ width: 300, border: "none" }}>
                  <img
                    alt="promoteur"
                    src={urlLogo}
                    width="100%"
                    height="100%"
                    onClick={() => window.open(promoterUrl, "_blank")}
                  />
                </Card>
                <Title level={3}>
                  {planning.name} ({phaseTransform[planning.phase]})
                </Title>
                <p>
                  Acquéreur: <b>{rdv.guest.email}</b>
                </p>
                <Space direction="vertical">
                  <Alert
                    message={`Vous avez pris rendez-vous le : ${momentTz(
                      rdvDate
                    )
                      .tz("Europe/Paris")
                      .format("DD/MM HH:mm")}`}
                    type="success"
                    showIcon
                  />
                  {rdv.forced === null && (
                    <div>
                      Vous n'êtes finalement pas disponible à cette date ?{" "}
                      <Popconfirm
                        title="Etes-vous bien sûr(e) de vouloir annuler votre rendez-vous ?"
                        onConfirm={deScheduleRdv}
                        onCancel={cancel}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <a>Cliquez ici</a>
                      </Popconfirm>
                    </div>
                  )}
                  <Button onClick={onClickIcsCalendar}>
                    <CalendarOutlined />
                    Ajouter au calendrier
                  </Button>
                </Space>
              </center>
            </ContentContainer>
            <PublicFooter />
          </Layout>
        </Layout>
      );
    }
  } else {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  }
};

export default PromoterTemplate;
