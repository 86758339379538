import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";
import "dayjs/locale/fr";
import axios from "axios";
import {
  App,
  Table,
  Popconfirm,
  Form,
  Input,
  Space,
  DatePicker,
  Badge,
  Button,
} from "antd";
import { CloseCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { getVisitesApiAuth, getRdvApiAuth, errorCatcher } from "../../utils";

var relativeTime = require("dayjs/plugin/relativeTime");

const PlanningsVrTable = () => {
  dayjs.extend(relativeTime);
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [visitList, setVisitList] = useState([]);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(0);
  const [editingKey, setEditingKey] = useState("");

  const confirmDel = async (record) => {
    await delRecord(record);
    const dataSource = [...visitList];
    setVisitList(dataSource.filter((item) => item.key !== record.key));
  };

  function cancelDel(record) {
    message.error(`Suppression annulée`);
  }

  const CustomInput = ({ dataIndex, type, title, endDate }) => {
    if (type !== "select") {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <DatePicker value={dayjs(endDate)} />
        </Form.Item>
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            type={inputType}
            endDate={dayjs(record.endDate)}
            dataIndex={dataIndex}
            title={title}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      endDate: dayjs(record.endDate),
    });
    setEditingKey(record.key);
  };

  const delRecord = async (record) => {
    try {
      // Suppression de la VV
      await axios.delete(
        process.env.REACT_APP_VR_API_URL + "/vv/" + record.key,
        getVisitesApiAuth()
      );

      // Suppression du lien de la VV dans le planning concerné
      await axios.post(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          localStorage.getItem("currentPlanningId") +
          "/broadcastlistVvUuidDelete",
        [record.uuid],
        getRdvApiAuth()
      );
      message.success(
        `La visite de l'utilisateur ${record.email} a été supprimée avec succès`
      );
    } catch (e) {
      message.error(
        `Erreur lors de la suppression de la visite de l'utilisateur ${record.email}`
      );
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (e, id) => {
    e.preventDefault();
    try {
      const updates = await form.validateFields();
      const newData = [...visitList];
      const index = newData.findIndex((item) => id === item.key);
      newData[index].endDate = updates.endDate;
      let isActive = Date.now() < new Date(newData[index].endDate);
      if (isActive) {
        newData[index].isActive = true;
      } else {
        newData[index].isActive = false;
      }
      setVisitList(newData);

      await axios.patch(
        process.env.REACT_APP_VR_API_URL + "/vv/" + id,
        updates,
        getVisitesApiAuth()
      );

      setEditingKey("");
      message.success(
        `Données correctement mises à jour pour la visite ${newData[index].name}`
      );
      await fetchVisitList();
      // console.log(res.data);
    } catch (errInfo) {
      console.log(errInfo);
      message.error("Erreur lors de la validation des champs:", errInfo);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let newlySelected = {};
      selectedRows.forEach((row) => {
        if (Object.keys(newlySelected).includes(row.uuid)) {
          newlySelected[row.uuid].push(row.key);
        } else {
          newlySelected[row.uuid] = [row.key];
        }
      });
      setSelected(newlySelected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const removeVvs = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_VR_API_URL + "/vv/delete-multiple",
        selected,
        getVisitesApiAuth()
      );

      await axios.post(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/" +
          localStorage.getItem("currentPlanningId") +
          "/broadcastlistVvUuidDelete",
        Object.keys(selected),
        getRdvApiAuth()
      );
      setSelected(0);
      await fetchVisitList();
    } catch (e) {
      console.log(e);
    }
  };

  const writeSelection = () => {
    if (selected === 0) {
      return (
        <div>
          <i>Séléctionner pour afficher les options</i>
        </div>
      );
    } else {
      let nb = Object.values(selected).flat(2).length;
      return (
        <div>
          {nb} visites{" "}
          <Popconfirm
            title={
              "Etes-vous sûr(e) de vouloir supprimer les " +
              nb +
              " éléments séléctionnés ?"
            }
            onConfirm={() => {
              removeVvs();
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">(Supprimer)</Button>
          </Popconfirm>
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Lot",
      dataIndex: "pointer",
      key: "pointer",
      render: (text) => (
        <>
          {text
            .replace(/[\s-]+$/, "")
            .split(/[\s-]/)
            .pop()}
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      editable: false,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      editable: false,
    },
    {
      title: "Début",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => <>{dayjs(new Date(startDate)).fromNow()}</>,
      editable: false,
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
    },
    {
      title: "Fin",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => <>{dayjs(new Date(endDate)).fromNow()}</>,
      editable: true,
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
    },
    {
      title: "Nom Matterport",
      dataIndex: "pointer",
      key: "pointer",
      editable: false,
      sorter: (a, b) => a.pointer.localeCompare(b.pointer),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Lien",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => (
        <a href={"/immersion/" + text} target="_blank" rel="noreferrer">
          ici
        </a>
      ),
      editable: false,
    },
    {
      title: "État",
      dataIndex: "isActive",
      key: "isActive",
      editable: false,
      render: (text) =>
        text === true ? (
          <Badge status="success" text="active" />
        ) : (
          <Badge status="error" text="expirée" />
        ),
      sorter: (a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1),
      sortDirections: ["descend", "ascend"],
      filters: Array.from(new Set(visitList.map((x) => x.isActive))).map(
        (_) => ({
          text: _ === false ? "Expiré" : "Active",
          value: _,
        })
      ),
      onFilter: (value, record) => {
        return JSON.stringify(record.isActive).includes(value);
      },
    },
    {
      title: "Notifié",
      dataIndex: "sendMail",
      key: "sendMail",
      editable: false,
      sorter: (a, b) => (a.sendMail === b.sendMail ? 0 : a.sendMail ? -1 : 1),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Vues",
      dataIndex: "counter",
      key: "counter",
      editable: false,
      sorter: (a, b) => a.counter - b.counter,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a
              onClick={(e) => {
                save(e, record.key);
              }}
            >
              Sauver
            </a>
            <Popconfirm title="Souhaitez-vous annuler ?" onConfirm={cancel}>
              <a>Annuler</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a onClick={() => edit(record)} disabled={editingKey !== ""}>
              Editer
            </a>
            <Popconfirm
              title="Etes-vous sûr de vouloir supprimer la visite ?"
              onConfirm={() => confirmDel(record)}
              onCancel={() => cancelDel(record)}
              okText="Oui"
              cancelText="Non"
            >
              <a>Supprimer</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "endDate" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const fetchVisitList = async () => {
    try {
      // Get VR Uuids from Project Plannings
      const res2 = await axios.get(
        `${
          process.env.REACT_APP_RDV_API_URL
        }/rdv/project/${localStorage.getItem("currentProgramId")}/visites-3d`
      );
      let uuids = res2.data.uuids;

      // Get Vvs from uuids
      const res = await axios.post(
        `${process.env.REACT_APP_VR_API_URL}/vv/multiple`,
        uuids,
        getVisitesApiAuth()
      );

      const originData = [];
      for (let i = 0; i < res.data.length; i++) {
        originData.push({
          key: res.data[i]._id,
          name: res.data[i].name,
          uuid: res.data[i].uuid,
          startDate: res.data[i].startDate,
          endDate: res.data[i].endDate,
          email: res.data[i].email,
          pointer: res.data[i].pointer,
          payload: res.data[i].payload,
          promoter: res.data[i].promoter.name,
          sendMail:
            res.data[i].sendMail === true ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <CloseCircleOutlined className="site-result-demo-error-icon" />
            ),
          counter: res.data[i].counter,
          isActive: new Date(res.data[i].endDate) > Date.now(),
        });
      }

      setVisitList(originData);
    } catch (e) {
      errorCatcher(
        e,
        `Erreur lors de la récupération des visites virtuelles du planning.`
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVisitList();
  }, []);

  return (
    <div>
      {!isLoading && (
        <div>
          {writeSelection()}
          <br />
          <Form size="small" form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowSelection={{ type: "checkbox", ...rowSelection }}
              bordered
              dataSource={visitList}
              columns={mergedColumns}
              rowKey="key"
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      )}
    </div>
  );
};

export default PlanningsVrTable;
