import React, { useState, useEffect } from "react";
import LogementsList from "./LogementsList";
// import { UserContext } from "../../context/UserContext";
import { getBasicAuthConfig } from "../../utils";
import axios from "axios";

// In case user does 'return' on browser
// Need to reinitialize state
// const initialNotifications = {
//   actualite: [],
//   tmas: [],
//   choix: [],
//   messages: [],
//   documents: [],
//   chantier: [],
// };

const Logements = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [housings, setHousings] = useState([]);
  // const { notifications, setNotifications } = useContext(UserContext);

  const loadUserHousings = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/users/me/housings",
        getBasicAuthConfig()
      );

      // console.log(`res.data = ${JSON.stringify(res.data)}`);
      setHousings(res.data.housings);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      localStorage.removeItem("currentHousing");
      localStorage.removeItem("currentHousingId");
      localStorage.removeItem("currentProgram");
      localStorage.removeItem("currentProgramId");

      await loadUserHousings();
      setIsLoading(false);
      // setNotifications(initialNotifications);
    };
    newFunc();
  }, []);
  return <div>{!isLoading && <LogementsList housings={housings} />}</div>;
};

export default Logements;
