import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import {
  Upload,
  App,
  Button,
  Typography,
  Space,
  Empty,
  Divider,
  Dropdown,
  Modal,
  notification,
  Form,
  Alert,
  Row,
  Col,
  Spin,
  Result,
} from "antd";
import {
  getBasicAuthConfig,
  getFormatedClients,
  getTypologies,
  errorCatcher,
} from "../../../utils";
import ContentContainer from "../../../components/ContentContainer";
import ClientTabTable from "./../../../components/clients/ClientTabTable";
import CustomPageHeader from "../../../components/CustomPageHeader";
import AddNewLotForm from "./../../../components/clients/AddNewLotForm";
import {
  UploadOutlined,
  SendOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SubContentContainer from "../../../components/SubContentContainer";

import { SmileOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Client = () => {
  const location = useLocation();
  const { role } = useContext(UserContext);
  const { message } = App.useApp();
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userSelectedFile, setUserSelectedFile] = useState(null);
  const [isClientFileSent, setIsClientFileSent] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientListAdd, setClientListAdd] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoading2, setModalLoading2] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uri, setUri] = useState(undefined);
  const [form] = Form.useForm();

  let programId = location.pathname.split("/")[2];

  const DropdownMenu = () => {
    let items = undefined;
    if (role === "administrator") {
      items = [
        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setVisible(true);
              }}
            >
              Liste Lots
            </a>
          ),
          key: "1",
        }, // remember to pass the key prop
        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setVisible2(true);
              }}
            >
              Lot unique
            </a>
          ),
          key: "2",
        },
      ];
    } else {
      items = [
        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setVisible2(true);
              }}
            >
              Lot unique
            </a>
          ),
          key: "2",
        },
      ];
    }

    const menuProps = {
      items,
      // onClick: handleMenuClick,
    };

    return (
      <Dropdown.Button menu={menuProps} icon={<PlusOutlined />}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>Ajouter</Space>
        </a>
      </Dropdown.Button>
    );

    return (
      <Dropdown key="more" menu={{ items }}>
        <Button icon={<PlusOutlined />}>Ajouter</Button>
      </Dropdown>
    );
  };

  const fetchProgramClientList = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + programId,
        getBasicAuthConfig()
      );
      // console.log(res);
      let u =
        process.env.REACT_APP_SITE + "/downloads/exemple-fichier-client.xlsx";
      setUri(u);
      // console.log(res.data.clients);
      setClientList(getFormatedClients(res.data.clients, programId));
      setIsClientFileSent(res.data.isClientFileSent);
    } catch (e) {
      errorCatcher(
        e,
        `Erreur lors du chargement du fichier clients de ce projet.`
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchProgramClientList();
    };
    newFunc();
  }, []);

  const unSelectedAllRows = () => {
    setSelectedRowKeys([]);
    setSelected([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      onSelectChange(selectedRowKeys, selectedRows);
      setSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const selectedAllRows = (rows) => {
    // console.log("All rows selected : " + JSON.stringify(rows));
    setSelectedRowKeys(rows.map((x) => x._id));
    setSelected(rows);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const doesEmailsAlreadyExist = (newClients, oldClients) => {
    // Un client peut avoir plusieurs lots OK
    // return false;
    let allOldMails = [];

    oldClients.forEach((_) => {
      if (_.mailmonsieur) allOldMails.push(_.mailmonsieur);
      if (_.mailmadame) allOldMails.push(_.mailmadame);
    });

    let allNewMails = [];
    newClients.forEach((_) => {
      if (_["J"]) allNewMails.push(_["J"]);
      if (_["K"]) allNewMails.push(_["K"]);
    });

    const intersection = allOldMails.filter((mail) =>
      allNewMails.includes(mail)
    );
    if (intersection.length !== 0) {
      message.error(
        `Un ou plusieurs mails que vous essayer d'ajouter existent déjà en base de donnée (${intersection})`
      );
      return true;
    } else {
      return false;
    }
  };

  const doesLotsAlreadyExist = (newClients, oldClients) => {
    let allOldLotcomms = [];

    oldClients.forEach((_) => {
      if (_.lotcomm) allOldLotcomms.push(_.lotcomm);
    });

    let allNewLotcomms = [];
    newClients.forEach((_) => {
      if (_["B"]) allNewLotcomms.push(_["B"]);
    });

    const intersection = allOldLotcomms.filter((lotcomm) =>
      allNewLotcomms.includes(lotcomm)
    );
    if (intersection.length !== 0) {
      message.error(
        `Un ou plusieurs lot que vous essayez d'ajouter existent déjà en base de donnée (${intersection})`
      );
      return true;
    } else {
      return false;
    }
  };

  const doesLotsAlreadyExistSingle = (newClients, oldClients) => {
    let allOldLotcomms = [];

    oldClients.forEach((_) => {
      if (_.lotcomm) allOldLotcomms.push(_.lotcomm);
    });
    // console.log(`oldLotcomm : ${JSON.stringify(allOldLotcomms)}`);
    let allNewLotcomms = [];
    newClients.forEach((_) => {
      if (_.lotcomm) allNewLotcomms.push(_.lotcomm);
    });
    // console.log(`newClientslotCommm : ${JSON.stringify(allNewLotcomms)}`);
    const intersection = allOldLotcomms.filter((lotcomm) =>
      allNewLotcomms.includes(lotcomm)
    );
    if (intersection.length !== 0) {
      message.error(
        `Le lot que vous essayez d'ajouter existe déjà en base de donnée pour ce programme (${intersection})`
      );
      return true;
    } else {
      return false;
    }
  };

  const doesEmailsAlreadyExistSingle = (newClients, oldClients) => {
    let allOldEmails = [];

    oldClients.forEach((_) => {
      if (_.email) allOldEmails.push(_.email);
    });
    // console.log(`oldEmails : ${JSON.stringify(allOldEmails)}`);
    let allNewEmails = [];
    newClients.forEach((_) => {
      allNewEmails.push(_.mailmonsieur);
    });
    // console.log(`newClientsEmails : ${JSON.stringify(allNewEmails)}`);
    const intersection = allOldEmails.filter((email) =>
      allNewEmails.includes(email)
    );
    if (intersection.length !== 0) {
      message.error(
        `L'email que vous essayez d'ajouter existe déjà en base de donnée pour ce programme (${intersection}). Veuillez utiliser un autre email.`
      );
      return true;
    } else {
      return false;
    }
  };

  const uploadSingleLot = async () => {
    try {
      // console.log(`Here are the current clients : ${JSON.stringify(clients)}`);
      let formValues = await form.validateFields();

      let clientSingleton = {
        lotcopro: formValues.lotcomm,
        lotcomm: formValues.lotcomm,
        titre: formValues.titre,
        nom: formValues.firstname,
        adresse: formValues.adresse,
        codepostal: formValues.codepostal,
        ville: formValues.ville,
        telephonemonsieur: formValues.telephonemonsieur,
        telephonemadame: formValues.telephonemadame,
        mailmonsieur: formValues.mailmonsieur,
        mailmadame: formValues.mailmadame,
        typologie: formValues.typologie,
        batiment: formValues.batiment,
        etage: formValues.etage,
      };
      // console.log(`clientSingleton : ${JSON.stringify(clientSingleton)}`);
      if (
        doesLotsAlreadyExistSingle([clientSingleton], clientList) ||
        doesEmailsAlreadyExistSingle([clientSingleton], clientList)
      ) {
        setModalLoading2(false);
        return;
      } else {
        // console.log(`Envoi du nouveau lot au back-end ...`);
        // Lock client into DB (from Excel first config file)
        const PatchRes = await axios.post(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            programId +
            "/lockup-clients",
          { clients: [clientSingleton] },
          getBasicAuthConfig()
        );
        if (visible2) {
          message.success("Nouveaux lots ajoutés avec succès 🚀");
          setModalLoading2(false);
          setVisible2(false);
        } else {
          message.success("Nouveaux lots créés avec succès 🚀");
        }

        // If some users already exist, tell the user
        if (PatchRes.data.error) {
          message.warning(
            `Des utilisateurs du fichier client semblent deja avoir un compte Bridgit 😲`
          );
        }

        form.resetFields();
        // After Lock client file, fetch DB
        const program = await axios.get(
          process.env.REACT_APP_API_URL + "/programs/" + programId,
          getBasicAuthConfig()
        );

        let tmpClients = getFormatedClients(program.data.clients, programId);

        // console.log(`TMP Clients = ${JSON.stringify(tmpClients)}`);
        setClientList(tmpClients);

        // GET meetings and store in localStorage
        var typologies = getTypologies(tmpClients);

        const meetings = {
          "1p": typologies["1p"] ? typologies["1p"] : 0,
          "2p": typologies["2p"] ? typologies["2p"] : 0,
          "3p": typologies["3p"] ? typologies["3p"] : 0,
          "4p": typologies["4p"] ? typologies["4p"] : 0,
          "5p": typologies["5p"] ? typologies["5p"] : 0,
        };
        localStorage.setItem("meetings", JSON.stringify(meetings));
      }
    } catch (e) {
      console.log(e);
      // message.error(`Erreur lors de l'ajout du nouveau lot`);
      // setVisible2(false);
      setModalLoading2(false);
    }
    // }
  };

  const uploadClientFile = async (clients) => {
    // console.log(`UPLOAD CLIENT FILE CALLED WITH : ${JSON.stringify(clients)} `);
    // console.log(`clientList : ${JSON.stringify(clientList)}`);
    if (
      doesLotsAlreadyExist(clients, clientList) ||
      doesEmailsAlreadyExist(clients, clientList)
    ) {
      setModalLoading(false);
      return;
    }

    if (selectedFile !== null) {
      try {
        // const clients = clientList;
        // Remove first line which is title
        // clients.shift();

        const jsonClient = [];
        clients.forEach((client) => {
          let clientSingleton = {
            lotcopro: client["A"],
            lotcomm: client["B"],
            titre: client["C"],
            nom: client["D"],
            adresse: client["E"],
            codepostal: client["F"],
            ville: client["G"],
            telephonemonsieur: client["H"],
            telephonemadame: client["I"],
            mailmonsieur: client["J"],
            mailmadame: client["K"],
            typologie: client["L"],
            batiment: client["M"],
            etage: client["N"],
          };
          jsonClient.push(clientSingleton);
        });

        // Lock client into DB (from Excel first config file)
        const PatchRes = await axios.post(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            programId +
            "/lockup-clients",
          { clients: jsonClient },
          getBasicAuthConfig()
        );
        if (visible) {
          message.success("Nouveaux lots ajoutés avec succès 🚀");
          setModalLoading(false);
          setVisible(false);
        } else {
          message.success("Nouveaux lots créés avec succès 🚀");
        }

        // If some users already exist, tell the user
        if (PatchRes.data.error) {
          message.warning(
            `Des utilisateurs du fichier client semblent deja avoir un compte Bridgit 😲`
          );
        }

        // After Lock client file, fetch DB
        const program = await axios.get(
          process.env.REACT_APP_API_URL + "/programs/" + programId,
          getBasicAuthConfig()
        );

        let tmpClients = getFormatedClients(program.data.clients, programId);

        // console.log(`TMP Clients = ${JSON.stringify(tmpClients)}`);
        setClientList(tmpClients);

        // GET meetings and store in localStorage
        var typologies = getTypologies(tmpClients);

        const meetings = {
          "1p": typologies["1p"] ? typologies["1p"] : 0,
          "2p": typologies["2p"] ? typologies["2p"] : 0,
          "3p": typologies["3p"] ? typologies["3p"] : 0,
          "4p": typologies["4p"] ? typologies["4p"] : 0,
          "5p": typologies["5p"] ? typologies["5p"] : 0,
        };
        localStorage.setItem("meetings", JSON.stringify(meetings));

        ////////

        setShowUpload(false);
      } catch (e) {
        if (e.response && e.response.data.error && e.response.data.message) {
          e.response.data.message.forEach((err) => {
            message.error(`${err}`);
          });
        }
        if (e.writeErrors) {
          e.writeErrors.map((err) => {
            if (err.code === 11000) {
              message.warning(`Utilisateur ${err.op.email} deja existant`);
            } else {
              message.error(`Erreur ${err.errmsg}`);
            }
          });
        }
        setVisible(false);
        setModalLoading(false);
      }
    } else {
      message.error("Veuillez préalablement choisir un fichier excel");
    }
  };

  const toggleUploadClientFileButton = () => {
    showUpload ? setShowUpload(false) : setShowUpload(true);
  };

  const token = localStorage.getItem("authToken");

  const openNotificationZero = (file) => {
    let description = `Accepté : xls ou xlsx`;
    notification["error"]({
      message: `Mauvais format : ${file}`,
      description,
      // onClick: () => {
      //   console.log('Notification Clicked!');
      // },
    });
  };

  const openNotification = (errors) => {
    // let description = "";
    // console.log(`Errors avant map : ${JSON.stringify(errors)}`);

    let description = errors.map((error) => {
      return (error += "\n\n");
    });
    notification["error"]({
      message: `Détails de l'erreur`,
      description,
      // onClick: () => {
      //   console.log('Notification Clicked!');
      // },
    });
  };

  // TODO: props and propsAdd is very Ugly, remove it
  const props = {
    name: "clients",
    action: process.env.REACT_APP_API_URL + "/users/me/xlsx",
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      if (info.file.status === "done") {
        // message.success(`Fichier ${info.file.name} sélectionné avec succès`);
        // console.log(`FILE EN FRONT : ${JSON.stringify(info.file.response)}`);
        setSelectedFile(info.file);
        setClientList(info.file.response.clients);
      } else if (info.file.status === "error") {
        message.error(
          `Problème lors de l'envoi du fichier ${info.file.name}.\n`
        );
        openNotification(info.file.response.error);
      }
    },
  };

  const props2 = {
    name: "clients",
    action: `${process.env.REACT_APP_API_URL}/programs/${programId}/xlsx-send`,
    headers: { Authorization: `Bearer ${token}` },
    onChange: async (info) => {
      if (info.file.status === "done") {
        message.success(`Fichier ${info.file.name} sélectionné avec succès`);
        setUserSelectedFile(info.file);

        try {
          //  Set isClientFileSent on planning to true
          let res = await axios.patch(
            process.env.REACT_APP_API_URL + "/programs/" + programId,
            { isClientFileSent: true },
            getBasicAuthConfig()
          );
        } catch (e) {
          console.log(e);
          message.error(
            `Impossible d'envoyer le fichier client. Veuillez procéder par mail à information@bridg-it.fr`
          );
        }
        await fetchProgramClientList();
        // setUserClientList(info.file.response.clients);
      } else if (info.file.status === "error") {
        message.error(
          `Problème lors de l'envoi du fichier ${info.file.name}.\n`
        );
        openNotificationZero(info.file.name);
      }
    },
  };

  const propsAdd = {
    name: "clients",
    action: process.env.REACT_APP_API_URL + "/users/me/xlsx",
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      if (info.file.status === "done") {
        setSelectedFile(info.file);
        setClientListAdd(info.file.response.clients);
      } else if (info.file.status === "error") {
        message.error(`Problème lors de l'envoi du fichier ${info.file.name}.`);
        openNotification(info.file.response.error);
      }
    },
  };

  if (isLoading) {
    return (
      <ContentContainer>
        <div className="loader-spin">
          <Spin />
        </div>
      </ContentContainer>
    );
  } else if (
    !isLoading &&
    isClientFileSent &&
    role !== "administrator" &&
    clientList.length === 0
  ) {
    return (
      <ContentContainer>
        <SubContentContainer>
          <Result
            icon={<SmileOutlined />}
            title={
              <div>
                Parfait ! Nous avons reçu votre fichier. 🧑‍💻 <br />
                <br />
                Vous serez informé(e) par mail lorsque les clients seront
                intégrés dans notre base de donnée.
              </div>
            }
            extra={<Title level={5}>information@bridg-it.fr</Title>}
          />
        </SubContentContainer>
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            title="Liste des Lots"
            arrow={true}
            extra={
              clientList.length > 0 &&
              !showUpload && [<DropdownMenu key="more" />]
            }
          >
            {" "}
          </CustomPageHeader>
          {showUpload ? (
            <div>
              <Divider orientation="left" plain>
                <Title level={5}>Chargement d'un fichier client</Title>
              </Divider>
              Il doit s'agir d'un fichier excel contenant les colonnes
              suivantes: «lot copro», «lot comm», «titre», «nom», «adresse»,
              «code postal», «ville», «telephone monsieur», «telephone madame»,
              «mail monsieur», «mail madame», «typologie», «batiment», «etage».{" "}
              <br />
              <br />
              Par ailleurs, la page du document excel doit s'appeler «clients».
              &nbsp;
              <a href="/Users/simonsicard/Projects/bridgitv2/downloads/exemple-fichier-client.xlsx">
                <Button type="primary" icon={<DownloadOutlined />} size="small">
                  Fichier type
                </Button>
              </a>
              <br />
              <br />
              <br />
              <Space direction="vertical">
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>
                    Choisir le fichier Excel
                  </Button>
                </Upload>
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    onClick={() => uploadClientFile(clientList)}
                    icon={<SendOutlined />}
                  >
                    Envoyer
                  </Button>
                  <Button type="danger" onClick={toggleUploadClientFileButton}>
                    Annuler
                  </Button>
                </Space>
              </Space>
            </div>
          ) : (
            <div>
              <Modal
                open={visible}
                title="Ajout de nouveaux lots au programme"
                // onOk={this.handleOk}
                onCancel={() => {
                  setVisible(false);
                }}
                footer={[
                  <Button
                    key="annuler"
                    onClick={() => {
                      setVisible(false);
                      message.success(`Opération annulée`);
                    }}
                  >
                    Annuler
                  </Button>,
                  <Button
                    key="sendnlots"
                    type="primary"
                    onClick={() => {
                      setModalLoading(true);
                      uploadClientFile(clientListAdd);
                    }}
                    loading={modalLoading}
                    icon={<SendOutlined />}
                  >
                    Envoyer nouveaux lots
                  </Button>,
                ]}
              >
                <div>
                  Il doit s'agir d'un fichier excel contenant les colonnes
                  suivantes: «lot copro», «lot comm», «titre», «nom», «adresse»,
                  «code postal», «ville», «telephone monsieur», «telephone
                  madame», «mail monsieur», «mail madame», «typologie»,
                  «batiment», «etage». <br />
                  <br />
                  Par ailleurs, la page du document excel doit s'appeler
                  «clients». <br />
                  <br />
                  <a href="/downloads/exemple-fichier-client.xlsx">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="small"
                    >
                      Fichier type
                    </Button>
                  </a>
                  <br />
                  <br />
                  <Space direction="vertical">
                    <Upload {...propsAdd}>
                      <Button icon={<UploadOutlined />}>
                        Choisir le fichier Excel
                      </Button>
                    </Upload>
                  </Space>
                </div>
              </Modal>
              <Modal
                open={visible2}
                title="Ajout d'un nouveau lot au programme"
                // onOk={this.handleOk}
                onCancel={() => {
                  setVisible2(false);
                }}
                footer={[
                  <Button
                    key="annuler"
                    onClick={() => {
                      setVisible2(false);
                      message.success(`Opération annulée`);
                    }}
                  >
                    Annuler
                  </Button>,
                  <Button
                    key="sendnlots"
                    type="primary"
                    onClick={() => {
                      // console.log(`cliqued OK !!`);
                      setModalLoading2(true);
                      uploadSingleLot();
                    }}
                    loading={modalLoading2}
                    icon={<SendOutlined />}
                  >
                    Envoyer nouveau lot
                  </Button>,
                ]}
              >
                <div>
                  <AddNewLotForm form={form} />
                </div>
              </Modal>
              {clientList.length === 0 && (
                <div>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Pas de clients</span>}
                  />
                  <center>
                    {role === "administrator" ? (
                      <Button
                        type="primary"
                        title="The excel file must contain following colum: 'lot copro', 'lot comm', 'titre', 'nom', 'adresse', 'code postal', 'ville', 'telephone monsieur', 'telephone madame', 'mail monsieur', 'mail madame', 'typologie'. Also, sheet name must be 'clients'"
                        onClick={toggleUploadClientFileButton}
                      >
                        Charger un fichier client
                      </Button>
                    ) : (
                      <Row align="middle">
                        <Col span={6}></Col>
                        <Col span={12}>
                          <Alert
                            message="Veuillez envoyer un fichier Excel avec les coordonnées des acquéreurs à l'adresse suivante: information@bridg-it.fr"
                            description="👩🏼‍💻 Le fichier est intégré en 24h au plus tard 👨🏼‍💻"
                            type="info"
                            showIcon
                          />
                          <br />
                          <div>
                            Il doit s'agir d'un fichier excel contenant les
                            colonnes suivantes: «lot copro», «lot comm» (*),
                            «titre» (*), «nom» (*), «adresse», «code postal»,
                            «ville», «telephone monsieur» (*), «telephone
                            madame», «mail monsieur» (*), «mail madame»,
                            «typologie» (*), «batiment» (*), «etage» (*). <br />
                            (*) : Champs obligatoires
                            <br />
                            <br />
                            <Space direction="vertical">
                              <a href={`${uri}`} target="_blank">
                                {/* <a href="#"> */}
                                <Button
                                  type="primary"
                                  icon={<DownloadOutlined />}
                                >
                                  Télécharger le modèle
                                </Button>
                              </a>
                              <Upload {...props2}>
                                <Button icon={<UploadOutlined />}>
                                  Uploader mon fichier
                                </Button>
                              </Upload>
                            </Space>
                          </div>
                        </Col>
                        <Col span={6}></Col>
                      </Row>
                    )}

                    <br />
                    <br />
                  </center>
                </div>
              )}

              {clientList.length > 0 && (
                <div>
                  <ClientTabTable
                    clientList={clientList}
                    setClientList={setClientList}
                    selected={selected}
                    rowSelection={rowSelection}
                    unSelectedAllRows={unSelectedAllRows}
                    selectedAllRows={selectedAllRows}
                    allSelected={allSelected}
                    setAllSelected={setAllSelected}
                  />
                </div>
              )}
            </div>
          )}
        </SubContentContainer>
      </ContentContainer>
    );
  }
};

export default Client;
