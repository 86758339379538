import React, { useState, useRef } from "react";
import axios from "axios";
import {
  App,
  Table,
  Select,
  Popconfirm,
  Form,
  Input,
  Space,
  Button,
  Tag,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const { getBasicAuthConfig, getUserLabel } = require("./../../utils");
const { Option } = Select;

const ProgramsPermissionsTable = ({ data, setData, users }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{ width: 110 }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput, 100);
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const save = async (e, id) => {
    e.preventDefault();
    try {
      const updates = await form.validateFields();
      const populatedRow = {
        authorized: updates["authorized"].map((x) =>
          users.find((_) => _._id === x)
        ),
      };
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);
      const item = newData[index];
      if (index > -1) {
        newData.splice(index, 1, { ...item, ...populatedRow });
        setData(newData);
        setEditingKey("");
      } else {
        // Should never happen
        newData.push(populatedRow);
        setData(newData);
        setEditingKey("");
      }
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/admin/permissions/programs/${id}`,
        updates,
        getBasicAuthConfig()
      );
      message.success(
        `Données correctement mises à jour pour le programme ${item.name}`
      );
      await form.resetFields();
    } catch (errInfo) {
      console.log(errInfo);
      message.error("Erreur lors de la validation des champs:", errInfo);
    }
  };

  const isEditing = (record) => record._id === editingKey;

  // const requiredFields = ["email"];

  const CustomInput = ({ dataIndex, type, title, authorized, owner, id }) => {
    const children = [];
    let newUsers = users.filter((_) => _._id !== owner._id);
    for (let i = 0; i < newUsers.length; i++) {
      children.push(
        <Option
          key={newUsers[i]._id}
          value={newUsers[i]._id}
          label={getUserLabel(newUsers[i])}
        >
          {getUserLabel(newUsers[i])}
        </Option>
      );
    }
    if (type !== "select") {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Input />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Select
            mode="multiple"
            name="tobi"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            optionFilterProp="label"
            // onChange={(value) => handleChange(id, value)}
          >
            {children}
          </Select>
        </Form.Item>
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <CustomInput
            type={inputType}
            authorized={record.authorized}
            owner={record.owner}
            dataIndex={dataIndex}
            title={title}
            id={record._id}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      authorized: record.authorized.map((_) => _._id),
    });
    setEditingKey(record._id);
  };

  const cancel = async () => {
    setEditingKey("");
    await form.resetFields();
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("name"),
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Initiateur",
      dataIndex: "owner",
      key: "owner",
      render: (text, record) => {
        return <Tag color="default">{getUserLabel(record.owner)}</Tag>;
      },
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Autorisés",
      dataIndex: "authorized",
      key: "authorized",
      render: (text, record) => {
        let innerContent = record.authorized.map((x, idx) => (
          <Tag color="default" key={`auth-${idx}`}>
            {getUserLabel(x)}
          </Tag>
        ));
        return <div>{innerContent}</div>;
      },
      editable: true,
      width: "55%",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a
              onClick={(e) => {
                save(e, record._id);
              }}
            >
              Sauver
            </a>
            <Popconfirm title="Souhaitez-vous annuler ?" onConfirm={cancel}>
              <a>Annuler</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a onClick={() => edit(record)} disabled={editingKey !== ""}>
              Editer
            </a>
          </Space>
        );
      },
      ellipsis: true,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "authorized" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <div>
        <Form size="small" form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            size="small"
            rowKey="_id"
          />
        </Form>
      </div>
    </div>
  );
};

export default ProgramsPermissionsTable;
