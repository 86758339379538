import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getBasicAuthConfig,
  errorCatcher,
  customTags,
  getRdvApiAuth,
  getMissingBalises,
  areBalisesOk,
} from "../../utils";
import Tips from "./modals/Tips";
import { Button, Form, Input, Space, Select, Typography, App } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
const { Text } = Typography;

const PlanningTemplate = ({
  form,
  setUsedBalises,
  planning,
  messages,
  setMessages,
  request = false,
  // setShowSMSRappel,
  // setShowSMSRelance,
  // setShowMailRappel,
  // setShowMailRelance,
  // setShowMailConfirmation,
  // setShowMailInvitation,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(undefined);
  const [templates, setTemplates] = useState([]);
  // const [refreshTemplates, setRefreshTemplates] = useState(true);

  useEffect(() => {
    const newFunc = async () => {
      try {
        // Get authorized templates
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/users/me/templates",
          getBasicAuthConfig()
        );
        // console.log(res.data.templates);
        setOptions(
          res.data.templates.map((_) => ({ value: _.name, label: _.name }))
        );
        setTemplates(res.data.templates);
      } catch (e) {
        errorCatcher(e, `Erreur lors du chargement des templates`);
      }
      // setRefreshTemplates(false);
      setIsLoading(false);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { message, notification, modal } = App.useApp();

  const onCreate = async (values, str) => {
    try {
      if (request) {
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL + "/rdv/" + planning._id,
          { [str]: values },
          getRdvApiAuth()
        );
      }

      let newMessages = {
        ...messages,
        [str]: values,
      };

      setMessages(newMessages);

      // console.log(`newMessages : ${JSON.stringify(newMessages)}`);

      // let bigStr =
      //   messages.mailrelancemessage +
      //   messages.mailrappelmessage +
      //   messages.smsrelancemessage +
      //   messages.smsrappelmessage +
      //   messages.mailconfirmationmessage;
      // bigStr += values;

      let bigStr = "";
      for (const [key, val] of Object.entries(newMessages)) {
        // console.log(`key : ${key}, val " ${val}`);
        bigStr += val;
      }

      // console.log(`bigStr => ${JSON.stringify(bigStr)}`);

      let balises = bigStr.match(/<(.+?)>/g) || [];
      // console.log(`balises 1 --> ${JSON.stringify(balises)}`);
      balises = balises.filter((_) => Object.keys(customTags).includes(_));
      // console.log(`balises 2 --> ${JSON.stringify(balises)}`);
      balises = Array.from(new Set(balises));

      message.success(`Message modifié avec succès`);
      // console.log(`newBalises : ${balises}`);
      setUsedBalises(balises);
    } catch (e) {
      console.log(e);
    }
  };

  const generatedContent = (field, title) => {
    let before = form.getFieldValue(field);
    modal.confirm({
      icon: null,
      title,
      width: "600px",
      content: (
        <div style={{ width: "550px" }}>
          <Form
            form={form}
            name="form_in_modal2"
            // initialValues={{ mailRelance: messages.mailInvitation }}
          >
            <Text type="danger">*&nbsp;</Text>
            <Text>Message :</Text>
            <br />
            <br />
            <Form.Item
              name={field}
              rules={[
                {
                  validator: async (_, payload) => {
                    let missings = getMissingBalises(planning, payload);
                    if (!areBalisesOk(payload)) {
                      return Promise.reject(
                        new Error(
                          `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
                        )
                      );
                    } else if (request && missings.length > 0) {
                      return Promise.reject(
                        new Error(
                          `Balise(s) ${missings} non disponible(s) sur ce planning.`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input.TextArea rows={18} />
            </Form.Item>
            <Tips />
          </Form>
        </div>
      ),
      async onOk() {
        try {
          const values = await form.validateFields([field]);
          await onCreate(values[field], field);
        } catch (e) {
          // console.log(`Erreur LORS DE LA VALIDATION DES CHAMPS`);
          message.error(
            `Les balises que vous avez utilisées ne sont pas celles indiquées comme étant disponibles.`
          );
          form.setFieldValue(field, before);
        }
      },
      onCancel() {
        form.setFieldValue(field, before);
      },
    });
  };

  const onChange = (value) => {
    setSelected(value);
  };

  const onSearch = (value) => {};

  const applyTemplate = () => {
    // console.log(templates);
    let selectedTemplate = templates.find((_) => _.name === selected);
    // console.log(`selectedTemplate is : ${JSON.stringify(selectedTemplate)}`);
    if (selectedTemplate === undefined) {
      message.error(
        `🧐 Veuillez d'abord sélectionner un template dans le menu déroulant à gauche de là où vous avez cliqué !`
      );
      return;
    }

    form.setFieldsValue({
      mailrappelmessage: selectedTemplate.mailRappel,
      smsrappelmessage: selectedTemplate.smsRappel,
      smsrelancemessage: selectedTemplate.smsRelance,
      mailrelancemessage: selectedTemplate.mailRelance,
      mailconfirmationmessage: selectedTemplate.mailConfirmation,
      mailinvitationmessage: selectedTemplate.mailInvitation,
    }); // Needed ?

    setMessages({
      mailrappelmessage: selectedTemplate.mailRappel,
      smsrappelmessage: selectedTemplate.smsRappel,
      smsrelancemessage: selectedTemplate.smsRelance,
      mailrelancemessage: selectedTemplate.mailRelance,
      mailconfirmationmessage: selectedTemplate.mailConfirmation,
      mailinvitationmessage: selectedTemplate.mailInvitation,
    }); // NEW

    let bigStr =
      selectedTemplate.mailRelance +
      selectedTemplate.mailRappel +
      selectedTemplate.smsRelance +
      selectedTemplate.smsRappel +
      selectedTemplate.mailInvitation +
      selectedTemplate.mailConfirmation;
    let balises = bigStr.match(/<(.+?)>/g) || [];
    balises = balises.filter((_) => Object.keys(customTags).includes(_));
    balises = Array.from(new Set(balises));

    message.success(`Template ${selected} appliqué au planning`);
    setUsedBalises(balises);
  };

  if (!isLoading) {
    return (
      <div>
        <Space wrap>
          <Button
            onClick={() =>
              generatedContent(
                "mailinvitationmessage",
                "Personnalisation du mail d'invitation"
              )
            }
          >
            Mail Invitation
          </Button>
          <Button
            onClick={() =>
              generatedContent(
                "mailconfirmationmessage",
                "Personnalisation du mail de confirmation"
              )
            }
          >
            Mail Confirmation
          </Button>
          <Button
            onClick={() =>
              generatedContent(
                "smsrelancemessage",
                "Personnalisation du SMS de relance"
              )
            }
          >
            SMS Relance
          </Button>
          <Button
            onClick={() =>
              generatedContent(
                "smsrappelmessage",
                "Personnalisation du SMS de rappel"
              )
            }
          >
            SMS Rappel
          </Button>
          <Button
            onClick={() =>
              generatedContent(
                "mailrelancemessage",
                "Personnalisation du mail de relance"
              )
            }
          >
            Mail Relance
          </Button>
          <Button
            onClick={() =>
              generatedContent(
                "mailrappelmessage",
                "Personnalisation du mail de rappel"
              )
            }
          >
            Mail Rappel
          </Button>
          {/* <Button onClick={mailconfirmation}>Mail Confirmation</Button> */}
        </Space>
        {/* <Space direction="horizontal">
          <Button
            onClick={() => {
              setShowMailInvitation(true);
            }}
          >
            Mail Invitation
          </Button>
          <Button
            onClick={() => {
              setShowMailConfirmation(true);
            }}
          >
            Mail Confirmation
          </Button>
          <Button
            onClick={() => {
              setShowSMSRelance(true);
            }}
          >
            SMS Relance
          </Button>
          <Button
            onClick={() => {
              setShowSMSRappel(true);
            }}
          >
            SMS Rappel
          </Button>
          <Button
            onClick={() => {
              setShowMailRelance(true);
            }}
          >
            Mail Relance
          </Button>
          <Button
            onClick={() => {
              setShowMailRappel(true);
            }}
          >
            Mail Rappel
          </Button>
        </Space> */}
        <br />
        <br />
        <Select
          showSearch
          placeholder="Selectionnez un modèle"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={options}
        />
        <Button
          icon={<DoubleRightOutlined />}
          type="link"
          onClick={() => applyTemplate()}
        >
          Utiliser ce modèle
        </Button>
      </div>
    );
  } else {
    return null;
  }
};

export default PlanningTemplate;
