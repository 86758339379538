import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import adaptivePlugin from "@fullcalendar/adaptive";
import { App, Form } from "antd";
import { bankHolidays } from "./../planningData";

import {
  formatEventsImpose,
  viewTransform,
  onClickExportICS,
  onClickExportPDF,
  onClickExportXLSImpose,
} from "../../../utils";
import TitlePersonnalizeModal from "../modals/TitlePersonnalizeModal";
import ExportModal from "../../ExportModal";

let fmtBankHolidays = [];
// start: new Date(moment(new Date(k)).set("hour", 0)),
// end: new Date(moment(new Date(k)).set("hour", 24)),
for (const [k, v] of Object.entries(bankHolidays)) {
  fmtBankHolidays.push({
    title: v,
    start: k,
    color: "#FFCCCB",
    display: "background",
    allDay: true,
  });
}

const AgendaReadOnly = ({
  plannings,
  showFreeMeetings,
  setShowFreeMeetings,
}) => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [eventSourcetoSend, setEventSourcetoSend] = useState([]);
  const [fmtEvents, setFmtEvents] = useState(undefined);
  const [isWeekend, setIsWeekend] = useState(false);
  const [showTitleConfigModal, setShowTitleConfigModal] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [isExportICSLoading, setIsExportICSLoading] = useState(false);
  const [isExportPDFLoading, setIsExportPDFLoading] = useState(false);
  const [isExportXLSLoading, setIsExportXLSLoading] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [planningUuidsUri, setPlanningUuidsUri] = useState("");
  const [viewType, setViewType] = useState("Mois");
  const [config, setConfig] = useState("lotTypo");
  const [isSingleAmo, setIsSingleAmo] = useState(false);
  const [isSmallLength, setIsSmallLength] = useState(undefined);
  const calendarRef = useRef();
  const [titleForm] = Form.useForm();

  const handleCancel = () => {
    setShowExportOptions(false);
  };

  const update = async () => {
    let isFormError = false;
    try {
      await titleForm.validateFields(["customTitle"]);
    } catch (e) {
      isFormError = true;
    }

    if (!isFormError) {
      let title = titleForm.getFieldValue("customTitle");
      const transformParams = (input) => {
        input = input.trim();
        input = input.replace("<NOM>", " name ");
        input = input.replace("<TYPOLOGIE>", " typo ");
        input = input.replace("<LOT>", " lot ");
        input = input.replace("<TELEPHONE>", " tel ");
        input = input.split(" ");
        let txt = "";
        input.forEach((i, idx) => {
          if (idx === 0) {
            // console.log("0");
            txt += i;
          } else {
            txt += i.charAt(0).toUpperCase() + i.slice(1);
            console.log(txt);
          }
        });
        return txt.charAt(0).toLowerCase() + txt.slice(1);
      };
      title = transformParams(title);
      let bigFMT = [];
      plannings.forEach((planning) => {
        let flag = transformParams(title);
        let fmt = formatEventsImpose(
          planning,
          flag,
          showFreeMeetings,
          null,
          fmtBankHolidays
        );
        bigFMT.push(fmt);
      });
      let f0s = [];
      let f1s = [];
      bigFMT.forEach((fmt) => {
        f0s = f0s.concat(fmt[0]);
        f1s = f1s.concat(fmt[1]);
      });
      setFmtEvents(f0s);
      setEventSourcetoSend(f1s);
      setShowTitleConfigModal(false);
      setConfig(title);
    }
  };

  useEffect(() => {
    let bigFMT = [];
    let sharedPlanningUri = "";
    let planningMins = [];
    plannings.forEach((planning, idx) => {
      planningMins.push(new Date(planning.planningStartTime));
      let fmt = formatEventsImpose(
        planning,
        config,
        showFreeMeetings,
        null,
        fmtBankHolidays
      );
      bigFMT.push(fmt);
      sharedPlanningUri += planning.uuid;
      if (idx !== plannings.length - 1) sharedPlanningUri += "&";
    });
    setPlanningUuidsUri(sharedPlanningUri);
    setViewType("Mois");

    let f0s = [];
    let f1s = [];
    bigFMT.forEach((fmt) => {
      f0s = f0s.concat(fmt[0]);
      f1s = f1s.concat(fmt[1]);
    });

    // Calcul de la date de debut d'affichage du calendrier
    // Si des rendez-vous on ete pris, prendre la date du
    // rdv le plus tot
    // Sinon prendre la date de debut du planning le plus tot
    let minDate = undefined;
    if (f0s.length === 0) {
      minDate = new Date(Math.min(...planningMins));
    } else {
      minDate = new Date(
        Math.min(
          ...f0s.map((element) => {
            return new Date(element.start);
          })
        )
      );
    }
    setIsSmallLength(plannings.some((_) => _.pas === 900));
    setStartDate(minDate);
    setFmtEvents(f0s);
    setEventSourcetoSend(f1s);
    setIsWeekend(true);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!isLoading && (
        <div className="customTopButton">
          <TitlePersonnalizeModal
            show={showTitleConfigModal}
            setShow={setShowTitleConfigModal}
            update={update}
            form={titleForm}
          />
          <ExportModal
            handleCancel={handleCancel}
            showExportOptions={showExportOptions}
            onClickExportICS={onClickExportICS}
            isExportICSLoading={isExportICSLoading}
            setIsExportICSLoading={setIsExportICSLoading}
            onClickExportPDF={onClickExportPDF}
            isExportPDFLoading={isExportPDFLoading}
            setIsExportPDFLoading={setIsExportPDFLoading}
            onClickExportXLS={onClickExportXLSImpose}
            isExportXLSLoading={isExportXLSLoading}
            setIsExportXLSLoading={setIsExportXLSLoading}
            isSingleAmo={isSingleAmo}
            setIsSingleAmo={setIsSingleAmo}
            plannings={plannings}
            eventSourcetoSend={eventSourcetoSend}
            config={config}
            viewType={viewType}
            showFreeMeetings={showFreeMeetings}
            fmtEvents={fmtEvents}
          />
          <FullCalendar
            ref={calendarRef}
            snapDuration={isSmallLength ? "00:15:00" : "00:30:00"}
            locale={frLocale}
            allDaySlot={false}
            editable={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              adaptivePlugin,
            ]}
            initialView="dayGridMonth"
            initialDate={startDate}
            slotMinTime="07:00:00"
            slotMaxTime="20:00:00"
            height="auto"
            eventSources={eventSourcetoSend}
            nowIndicator={true}
            weekends={isWeekend}
            schedulerLicenseKey={process.env.REACT_APP_SCHEDULER_LICENCE_KEY}
            datesSet={(dateInfo) => {
              if (calendarRef.current !== undefined) {
                let calendarApi = calendarRef.current.getApi();
                setViewType(viewTransform[calendarApi.view.type]);
              }
            }}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right:
                "showFreeMeetingsButton shareButton exportOptionsButton titleButton timeGridDay,timeGridWeek,dayGridMonth",
            }}
            displayEventTime={config === "lotNameTypoTel" ? false : true}
            // views={{
            //   timeGridFourDay: {
            //     type: "timeGrid",
            //     duration: { days: 4 },
            //     buttonText: "4 day",
            //   },
            // }}
            customButtons={{
              shareButton: {
                text: "Vue partagée",
                click: async () => {
                  window.open(
                    `${process.env.REACT_APP_SITE}/pview/${planningUuidsUri}`,
                    "_blank"
                  );
                },
              },
              exportOptionsButton: {
                text: "Exporter",
                click: async () => {
                  setShowExportOptions(true);
                },
              },
              showFreeMeetingsButton: {
                text: showFreeMeetings
                  ? "Masquer RDVs Libres"
                  : "Montrer RDVs Libres",
                hint: "Afficher les free meetings",
                click: (info) => {
                  let newEventSources = [];
                  // if (!showFreeMeetings) {
                  //   console.log(`SHOW FREE MEETINGS`);
                  //   plannings.forEach((planning) => {
                  //     newEventSources = newEventSources.concat(
                  //       showAllFreeMeetings(planning, eventSourcetoSend)
                  //     );
                  //   });
                  // } else {
                  //   console.log(`HIDE FREE MEETINGS`);
                  //   plannings.forEach((planning) => {
                  //     newEventSources = newEventSources.concat(
                  //       unShowAllFreeMeetings(eventSourcetoSend)
                  //     );
                  //   });
                  //   // newEventSources = unShowAllFreeMeetings(eventSources);
                  // }
                  // setShowFreeMeetings(!showFreeMeetings);
                  // setEventSourcetoSend(newEventSources);
                  let bigFMT = [];
                  plannings.forEach((planning) => {
                    let fmt = formatEventsImpose(
                      planning,
                      config,
                      !showFreeMeetings,
                      null,
                      fmtBankHolidays
                    );
                    bigFMT.push(fmt);
                  });
                  let f0s = [];
                  let f1s = [];
                  bigFMT.forEach((fmt) => {
                    f0s = f0s.concat(fmt[0]);
                    f1s = f1s.concat(fmt[1]);
                  });

                  setFmtEvents(f0s);
                  setEventSourcetoSend(f1s);
                  setShowFreeMeetings(!showFreeMeetings);
                },
              },
              // moveButton: {
              //   text: moveButtonTitle,
              //   click: function () {
              //     let calendarApi = calendarRef.current.getApi();
              //     if (moveButtonTitle === "Terminé") {
              //       calendarApi.setOption("editable", false);
              //       calendarApi.setOption("eventDrop", false);
              //       calendarApi.setOption("eventDragStop", false);
              //       setMoveButtonTitle("Déplacer");
              //     } else {
              //       if (moveButtonTitle !== "Terminé") {
              //         message.warning(
              //           "Sélectionnez un rendez-vous et déplacez le à l'endroit souhaité"
              //         );
              //         setMoveButtonTitle("Terminé");
              //         calendarApi.setOption("editable", true);
              //         calendarApi.setOption("eventDrop", (info) => {
              //           if (info.view.type === "dayGridMonth") {
              //             form.setFieldsValue({
              //               selectedDate: moment(info.event.start),
              //             });
              //             setShowMoveConfirmModal(true);
              //             console.log("show confirm hour modal");
              //           }
              //         });
              //         calendarApi.setOption("eventDragStop", (info) => {
              //           setMoveSelected(info.event);
              //         });
              //       }
              //     }
              //   },
              // },
              titleButton: {
                text: "Titre",
                click: function () {
                  setShowTitleConfigModal(true);
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AgendaReadOnly;
