import React from "react";
import ContentContainer from "../components/ContentContainer";
import SubContentContainer from "../components/SubContentContainer";
import CustomPageHeader from "../components/CustomPageHeader";
import { Typography } from "antd";

const { Title, Paragraph, Text, Link } = Typography;

const GDPRPage = () => {
  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader arrow={true} title="RGPD" subTitle="Explication" />
        <Typography>
          <Title level={4}>De quoi parle-t-on ?</Title>
          <Paragraph>
            Depuis le 25 mai 2018, toutes les entreprises qui gèrent des données
            à caractère personnel sont soumises à des obligations sur la
            protection des données des utilisateurs.{" "}
          </Paragraph>
          <Paragraph>
            La RGPD oblige les entreprises à mieux communiquer sur la collecte
            et l’usage des données. Elle permet également d’optimiser la
            protection de leurs données pour en prévenir les violations. La RGPD
            encourage vivement les entreprises à se doter d’un{" "}
            <Text strong>Data Protection Officer</Text>. Le Data Protection
            Officer est chargé de{" "}
            <Text strong>piloter la gestion et le traitement des données</Text>.
            Il doit s’assurer du respect des obligations juridiques et de
            superviser l’ensemble du travail fait sur ce domaine.{" "}
          </Paragraph>
          <Paragraph>
            Vous pouvez directement contacter le DPO de Bridgit à l'adresse
            suivante:{" "}
            <Link href="information@bridg-it.fr">information@bridg-it.fr</Link>.
          </Paragraph>

          <Title level={4}>Quels sont vos droit ?</Title>
          <Paragraph>
            Le RGPD donne de nouveaux droits aux personnes sur l’utilisation et
            le traitement des données personnelles.
          </Paragraph>

          <Paragraph>
            <ul>
              <li>
                Le droit à la portabilité des données prévoit que les personnes
                peuvent recevoir les données qui les concernent et qui ont été
                transmises à un organisme et que les personnes puissent aussi
                les transmettre à un autre organisme sans restriction. Ce
                transfert doit se faire “dans un format structuré, couramment
                utilisé et lisible par machine”.
                <Link>Demander les données qui me concernent</Link>
              </li>
              <li>
                Le droit à l’oubli permet à une personne de demander la
                destruction des données personnelles qui la concernent.
                <Link>
                  Demander la destruction des données qui me concernent
                </Link>
              </li>
              <li>
                Le droit sur la protection des données des mineurs de moins de
                16 ans oblige les organismes à recevoir un accord d’un
                représentant légal pour collecter des données sur le jeune
                public.
              </li>
            </ul>
          </Paragraph>
        </Typography>
      </SubContentContainer>
    </ContentContainer>
  );
};

export default GDPRPage;
