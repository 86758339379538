import React, { useEffect, useState } from "react";
import axios from "axios";
import ContentContainer from "../../../components/ContentContainer";
import AgendaReadOnlyPrivateLibre from "../../../components/plannings/libre/AgendaReadOnlyPrivate";
import AgendaReadOnlyPrivateImpose from "../../../components/plannings/impose/AgendaReadOnlyPrivate";
import SubContentContainer from "../../../components/SubContentContainer";
import { useLocation } from "react-router-dom";
import { App, Spin } from "antd";
import CustomPageHeader from "../../../components/CustomPageHeader";

const PlanningsConsolidate = () => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [plannings, setPlannings] = useState(undefined);
  const [subTitle, setSubTitle] = useState(undefined);
  const [showFreeMeetings, setShowFreeMeetings] = useState(true);
  const query = new URLSearchParams(useLocation().search);
  const ids = query.get("ids");

  useEffect(() => {
    const newFunc = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_RDV_API_URL}/plannings-consolides`,
          { _id: ids.split(",") }
        );
        let lenPlannings = res.data.plannings.length;
        setSubTitle(
          res.data.plannings.map((_, i) => {
            if (i === lenPlannings - 1) {
              return `${_.name}.`;
            } else {
              return `${_.name}, `;
            }
          })
        );
        setPlannings(res.data.plannings);
        setIsLoading(false);
      } catch (e) {
        message.error(`Erreur lors du chargement des plannings`);
        console.log(e);
      }
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isPromoterTemplateType = (e) => e.templateType === "promoter";
  if (isLoading) {
    return (
      <div className="loader-spin-empty-screen">
        <Spin />
      </div>
    );
  } else {
    return (
      <ContentContainer>
        <SubContentContainer>
          <CustomPageHeader
            arrow={true}
            title={`Vue consolidée : ${plannings.length} plannings`}
            subTitle={subTitle}
          />
          {plannings.some(isPromoterTemplateType) ? (
            <div className="customCalendarEvent">
              <AgendaReadOnlyPrivateLibre
                plannings={plannings}
                showFreeMeetings={showFreeMeetings}
                setShowFreeMeetings={setShowFreeMeetings}
              />
            </div>
          ) : (
            <div className="customCalendarEvent">
              <AgendaReadOnlyPrivateImpose
                plannings={plannings}
                showFreeMeetings={showFreeMeetings}
                setShowFreeMeetings={setShowFreeMeetings}
              />
            </div>
          )}
        </SubContentContainer>
        <br />
      </ContentContainer>
    );
  }
};

export default PlanningsConsolidate;
