import React, { useEffect, useState } from "react";
import { App, Layout } from "antd";
import DocumentList from "../../components/customer/DocumentList";
import { getBasicAuthConfig } from "../../utils";
import axios from "axios";

const { Content } = Layout;

const Documents = () => {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const fetchUserDocuments = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/users/me/documents`,
        getBasicAuthConfig()
      );
      console.log(res.data);
      setDocuments(res.data);
    } catch (e) {
      console.log(e);
      message.error(`Erreur lors du chargement des documents`);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchUserDocuments();
      setIsLoading(false);
    };
    newFunc();
  }, []);
  return (
    <div>
      {!isLoading && (
        <Content>
          <DocumentList fileList={documents} />
        </Content>
      )}
    </div>
  );
};

export default Documents;
