import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ContentContainer from "../../../components/ContentContainer";
import CustomTransfer from "../../../components/parametres/CustomTransfer";
import CustomPageHeader from "../../../components/CustomPageHeader";
// import { UserContext } from "../../../context/UserContext";
import { getBasicAuthConfig, errorCatcher } from "../../../utils";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Modal,
  App,
  Upload,
  Space,
  Image,
  Checkbox,
  Alert,
} from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import SubContentContainer from "../../../components/SubContentContainer";
import ProjectColorPicker from "../../../components/ProjectColorPicker";
import ColorThief from "colorthief";

const { Text } = Typography;

const Parametres = () => {
  const { message } = App.useApp();
  const { Title } = Typography;
  const location = useLocation();
  const [amoList, setAmoList] = useState([]);
  const [project, setProject] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [showMailRespWarning, setShowMailRespWarning] = useState(false);
  const [showAddressWarning, setShowAddressWarning] = useState(false);
  const [updates, setUpdates] = useState({});
  const [selectedColor, setSelectedColor] = useState("rgb(67, 99, 134)");
  const [defaultColors, setDefaultColors] = useState([
    "rgb(67, 99, 134)",
    "rgb(204, 236, 250)",
    "rgb(1, 2, 3)",
  ]);
  const [presetDefaultColors, setPresetDefaultColors] = useState([]);
  const [colorPickerVisible1, setColorPickerVisible1] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const colorthief = new ColorThief();

  // const [form] = Form.useForm();
  const imageRef = useRef(null);
  const formRef = useRef();

  const onCreate = async (values) => {
    const avatar = fileList[0].originFileObj;
    const colorFounded = defaultColors.find((c) => c === selectedColor);
    let localDefaultColors = defaultColors;
    if (!colorFounded) {
      localDefaultColors = [selectedColor, ...defaultColors];
    } else {
      //color is founded so put it in first position to be the main color
      localDefaultColors = defaultColors.filter((c) => c !== selectedColor);
      localDefaultColors = [selectedColor, ...localDefaultColors];
    }
    try {
      if (avatar !== null) {
        const formData = new FormData();
        formData.append("avatar", avatar, avatar.name);
        formData.append("colors", JSON.stringify(localDefaultColors));
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/programs/" +
            localStorage.getItem("currentProgramId") +
            "/avatar",
          formData,
          getBasicAuthConfig()
        );
        await axios.patch(
          process.env.REACT_APP_RDV_API_URL +
            "/rdv/project/" +
            projectId +
            "/plannings-colors",
          localDefaultColors
        );
        setProject((prevProject) => ({
          ...prevProject,
          avatar: {
            data: avatar.data,
            thumbUrl: URL.createObjectURL(avatar),
          },
        }));
      }
      message.success("Logo envoyé avec succès");
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data.error) {
        message.error(
          `Erreur lors de l'envoi de l'image (${e.response.data.error}))`
        );
        setVisible(false);
      } else {
        message.error("Erreur lors de l'envoi de l'image");
        setVisible(false);
      }
    }
    setVisible(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      console.log("passssss");
      return e;
    }
    return e?.fileList;
  };

  const projectId = location.pathname
    .replace("/projets/", "")
    .replace("/parametres", "");

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  const onChange = (value) => {
    let field = Object.keys(value);
    let val = Object.values(value)[0];
    if (field.toString() === "adresse") {
      field = "adresse" + Object.keys(Object.values(value)[0]);
      val = Object.values(Object.values(value)[0])[0];
    }
    let newUpdates = {
      ...updates,
      [field]: val,
    };
    setUpdates(newUpdates);
  };

  const isAddressOk = (program) => {
    if (program.adressenumero === undefined || program.adressenumero === "") {
      return false;
    }
    if (program.adresserue === undefined || program.adresserue === "") {
      return false;
    }
    if (
      program.adressecodepostal === undefined ||
      program.adressecodepostal === ""
    ) {
      return false;
    }
    if (program.adresseville === undefined || program.adresseville === "") {
      return false;
    }
    return true;
  };

  const updateParams = async () => {
    let keysUpdates = Object.keys(updates);
    const colorFounded = defaultColors.find((c) => c === selectedColor);
    let localDefaultColors = defaultColors;
    if (!colorFounded) {
      localDefaultColors = [selectedColor, ...defaultColors];
    } else {
      //color is founded so put it in first position to be the main color
      localDefaultColors = defaultColors.filter((c) => c !== selectedColor);
      localDefaultColors = [selectedColor, ...localDefaultColors];
    }
    const newUpdates = { ...updates, colors: localDefaultColors };
    setUpdates(newUpdates);
    try {
      await formRef.current.validateFields();
      if (
        showMailRespWarning &&
        keysUpdates.includes("mailrespclient") &&
        updates.mailrespclient !== ""
      ) {
        setShowMailRespWarning(false);
      }

      if (
        !showMailRespWarning &&
        keysUpdates.includes("mailrespclient") &&
        updates.mailrespclient === ""
      ) {
        setShowMailRespWarning(true);
      }
      let res = await axios.patch(
        process.env.REACT_APP_API_URL + "/programs/" + projectId,
        newUpdates,
        getBasicAuthConfig()
      );

      await axios.patch(
        process.env.REACT_APP_RDV_API_URL +
          "/rdv/project/" +
          projectId +
          "/plannings-colors",
        localDefaultColors
      );

      // console.log(res.data);
      if (showAddressWarning && isAddressOk(res.data)) {
        setShowAddressWarning(false);
      }
      if (!showAddressWarning && !isAddressOk(res.data)) {
        setShowAddressWarning(true);
      }
      message.success("Paramètres du projet correctement mis à jour");
    } catch (e) {
      if (e.response && e.response.data.error) {
        message.error(`${e.response.data.error}`);
      } else {
        message.error(`Veuillez verifier les informations renseignées`);
      }
    }
  };

  const fetchProject = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/programs/" + projectId,
        getBasicAuthConfig()
      );
      setProject(res.data);
      setSelectedColor(res.data.colors[0]);
      setDefaultColors(res.data.colors);
      setPresetDefaultColors(res.data.colors);
      if (
        res.data.mailrespclient === undefined ||
        res.data.mailrespclient === ""
      ) {
        setShowMailRespWarning(true);
      }
      if (
        res.data.adressenumero === "" ||
        res.data.adresserue === "" ||
        res.data.adressecodepostal === "" ||
        res.data.adresseville === ""
      ) {
        setShowAddressWarning(true);
      }
    } catch (e) {
      errorCatcher(e, `Erreur lors du chargement des paramètres du projet`);
    }
  };

  const fetchAmoList = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/users/amos",
        getBasicAuthConfig()
      );
      // console.log(`RES -----> ${JSON.stringify(res)}`);
      const sourceTransfer = [];
      for (let i = 0; i < res.data.length; i++) {
        sourceTransfer.push({
          key: res.data[i]._id,
          title: `${res.data[i].firstname} ${res.data[i].lastname} (${res.data[i].enterprise})`,
          description: `description of content${i + 1}`,
        });
      }
      setAmoList(sourceTransfer);
    } catch (e) {
      console.log(e);
      console.log(`Erreur lors du chargement des AMOs`);
    }
  };

  useEffect(() => {
    const newFunc = async () => {
      await fetchAmoList();
      await fetchProject();
      setHasLoaded(true);
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader
          // style={{ margin: "0px 16px 0px 16px" }}
          // className="site-page-header-responsive"
          // onBack={() => window.history.back()}
          arrow={true}
          title="Paramètres du programme"
        ></CustomPageHeader>
        {hasLoaded && (
          <Row>
            <Col span={1}></Col>
            <Col span={21}>
              {showAddressWarning && (
                <div>
                  <Alert
                    message="Attention"
                    description="Veuillez renseigner l'adresse du programme"
                    type="warning"
                    showIcon
                    closable
                  />
                  <br />
                </div>
              )}

              {showMailRespWarning && (
                <Alert
                  message="Attention"
                  description="Veuillez renseigner le mail du responsable client."
                  type="warning"
                  showIcon
                  closable
                />
              )}
              <br />
              <Text strong>Logo du projet</Text>
              <br />
              <br />
              {/* <p></p> */}

              <Space>
                <Image
                  width={250}
                  src={
                    project.avatar === undefined
                      ? "error"
                      : project.avatar.thumbUrl ||
                        "data:image/jpeg;base64," +
                          btoa(
                            [].reduce.call(
                              new Uint8Array(project.avatar.data),
                              function (p, c) {
                                return p + String.fromCharCode(c);
                              },
                              ""
                            )
                          )
                  }
                />

                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setVisible(true);
                  }}
                />

                <Modal
                  open={visible}
                  title="Modification de l'avatar du projet"
                  okText="Mettre à jour"
                  cancelText="Annuler"
                  onCancel={() => {
                    setVisible(false);
                  }}
                  onOk={async () => {
                    try {
                      let values = await formRef.current.validateFields();
                      await onCreate(values);
                      await formRef.current.resetFields();
                    } catch (e) {
                      console.log("erreur : ");
                      console.log(e);
                    }
                  }}
                >
                  <Form ref={formRef} layout="vertical" name="form_in_modal">
                    <Form.Item
                      name="avatar"
                      label="Image"
                      valuePropName="filelist"
                      getValueFromEvent={normFile}
                    >
                      <Space direction="vertical">
                        <Upload
                          name="logo"
                          listType="picture"
                          maxCount={1}
                          customRequest={dummyRequest}
                          fileList={fileList}
                          onChange={(info) => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              if (typeof reader.result === "string") {
                                imageRef.current.src = reader.result;
                              }
                            };
                            reader.readAsDataURL(info.file.originFileObj);
                            setFileList(info.fileList);
                          }}
                          onRemove={() => {
                            setFileList([]);
                            imageRef.current.src = "";
                            return false;
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            Sélectionner le logo du projet
                          </Button>
                        </Upload>
                        <Text type="secondary">
                          Choisissez votre plus belle image 👌
                        </Text>
                        <img
                          ref={imageRef}
                          style={{ display: "none" }}
                          onLoad={async () => {
                            if (imageRef.current) {
                              const dominantColorPalette =
                                await colorthief.getPalette(imageRef.current);
                              const dominantColorRGB = dominantColorPalette.map(
                                (color) =>
                                  `rgb(${color[0]}, ${color[1]}, ${color[2]})`
                              );
                              setColorPickerVisible1(true);
                              setSelectedColor(dominantColorRGB[0]);
                              setDefaultColors(dominantColorRGB);
                              setPresetDefaultColors(dominantColorRGB);
                              setUpdates({
                                ...updates,
                                colors: dominantColorRGB,
                              });
                            }
                          }}
                          alt="Uploaded"
                        />
                      </Space>
                    </Form.Item>
                    <Form.Item name="colors" label="Couleurs du programme">
                      <ProjectColorPicker
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        presetDefaultColors={presetDefaultColors}
                        colorPickerVisible={colorPickerVisible1}
                        setColorPickerVisible={setColorPickerVisible1}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
              </Space>

              <br />
              <br />

              <Form
                {...layout}
                ref={formRef}
                layout="vertical"
                onValuesChange={onChange}
                initialValues={{
                  name: project.name,
                  promotername: project.promotername,
                  adresse: {
                    numero: project.adressenumero,
                    rue: project.adresserue,
                    codepostal: project.adressecodepostal,
                    ville: project.adresseville,
                    pays: project.adressepays,
                  },
                  promoterUrl: project.promoterUrl,
                  // nomsccv: project.nomsccv,
                  // telsccv: project.telsccv,
                  // respchantier: project.respchantier,
                  // telrespchantier: project.telrespchantier,
                  // respclient: project.respclient,
                  // telrespclient: project.telrespclient,
                  mailrespclient: project.mailrespclient || "",
                  // respprogramme: project.respprogramme,
                  // telrespprogramme: project.telrespprogramme,
                  // resptechnique: project.resptechnique,
                  // telresptechnique: project.telresptechnique,
                  colors: project.colors,
                  isArchived: project.isArchived,
                }}
              >
                <Form.Item
                  name="name"
                  label="Nom du programme"
                  required
                  // tooltip="This is a required field"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="promotername"
                  label="Nom du promoteur"
                  required
                  // tooltip="This is a required field"
                >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  label="Adresse du chantier"
                  required
                  tooltip="Pas de prise de rendez-vous sans adresse !"
                >
                  <Space.Compact>
                    <Form.Item
                      name={["adresse", "numero"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Veuillez indiquer un numéro de rue",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "20%", marginBottom: "4px" }}
                        placeholder="No"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["adresse", "rue"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Veuillez rentrer un nom de rue",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "80%",
                          marginBottom: "4px",
                        }}
                        placeholder="rue"
                      />
                    </Form.Item>
                  </Space.Compact>
                  <Space.Compact>
                    <Form.Item
                      name={["adresse", "codepostal"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Veuillez indiquer un code postal",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "20%" }}
                        placeholder="code postal"
                      />
                    </Form.Item>
                    <Form.Item
                      name={["adresse", "ville"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Veuillez indiquer une ville",
                        },
                      ]}
                    >
                      <Input style={{ width: "30%" }} placeholder="ville" />
                    </Form.Item>
                    <Form.Item
                      name={["adresse", "pays"]}
                      noStyle
                      rules={[
                        {
                          // required: true,
                          message: "Veuillez indiquer un pays",
                        },
                      ]}
                    >
                      <Input style={{ width: "50%" }} placeholder="pays" />
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>

                <Form.Item
                  name="promoterUrl"
                  label="Lien du promoteur"
                  tooltip="L'acquéreur est redirigé vers ce lien lorsqu'il clique sur votre Logo"
                  rules={[
                    {
                      type: "url",
                      message:
                        "Veuillez renseigner une adresse internet valide",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {/* <Form.Item
                name="nomsccv"
                label="Nom SCCV"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="telsccv"
                label="Tel SCCV"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}

                {/* <Form.Item
                name="respprogramme"
                label="Responsable programme"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="telrespprogramme"
                label="Tel responsable programme"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="resptechnique"
                label="Responsable technique"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="telresptechnique"
                label="Tel responsable technique"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="respchantier"
                label="Responsable chantier"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="telrespchantier"
                label="Tel responsable chantier"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="respclient"
                label="Responsable client"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                {/* <Form.Item
                name="telrespclient"
                label="Tel responsable client"
                tooltip="This is a required field"
              >
                <Input />
              </Form.Item> */}
                <Form.Item
                  name="mailrespclient"
                  label="Mail responsable client"
                  tooltip="Mail qui apparaîtra par défaut dans les invitations"
                  rules={[
                    {
                      type: "email",
                      message: "Veuillez renseigner une adresse mail valide",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {/* <Form.Item label="Co-promotion" disabled>
                <Radio disabled>Non</Radio>
                <Radio defaultChecked disabled>
                  Oui
                </Radio>
              </Form.Item> */}

                <Form.Item
                  name="colors"
                  label="Couleurs du programme"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez renseigner les couleurs du programme",
                    },
                  ]}
                >
                  <ProjectColorPicker
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    presetDefaultColors={presetDefaultColors}
                    colorPickerVisible={colorPickerVisible}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </Form.Item>

                <Form.Item name="isArchived" valuePropName="checked">
                  <Checkbox>Archivé</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" onClick={updateParams}>
                    Mettre à jour
                  </Button>
                </Form.Item>
              </Form>

              <Divider dashed />
              <br />
              <Title level={4}>AMO(s) autorisé(s)</Title>

              <br />

              {hasLoaded && (
                <CustomTransfer
                  amoList={amoList}
                  project={project}
                  projectId={projectId}
                />
              )}
              <br />
            </Col>
            <Col span={2}></Col>
          </Row>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Parametres;
