import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { App, Col, Row, Tabs, Typography, Space } from "antd";
import axios from "axios";
import { getBasicAuthConfig } from "./../../../utils";
import UserProfileRightContainer from "../../../components/UserProfileRightContainer";
import UserProfileLeftContainer from "../../../components/UserProfileLeftContainer";
import SubContentContainer from "../../../components/SubContentContainer";
import CustomPageHeader from "../../../components/CustomPageHeader";
import Messages from "../customer/Messages";
import Profile from "../customer/Profile";
import ContentContainer from "../../../components/ContentContainer";

const { TabPane } = Tabs;
const { Title } = Typography;

const Client = () => {
  const location = useLocation();
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState(undefined);
  const programId = location.pathname.split("/")[2];
  const userId = location.pathname.split("/")[4];
  const loadUserProfile = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/programs/" +
        programId +
        "/clients/" +
        userId;

      let res = await axios.get(url, getBasicAuthConfig());
      setClient(res.data);
    } catch (e) {
      console.log(e);
      message.error(`Une erreur est survenue : ${e}`);
      // TODO : Redirect User to Home
    }
  };

  // const modifyInfo = () => {
  //   console.log(`Modify info cliqued !!`);
  // };

  useEffect(() => {
    const newFunc = async () => {
      await loadUserProfile();
      setIsLoading(false);
    };
    newFunc();
  }, []);

  const items = [
    {
      key: "1",
      label: `Profil`,
      children: (
        <Profile programId={programId} userId={userId} client={client} />
      ),
    },
    {
      key: "2",
      label: `Messages`,
      children: <Messages />,
      disabled: true,
    },
    {
      key: "3",
      label: `Rendez-vous`,
      children: `Page Rendez-vous`,
      disabled: true,
    },
    {
      key: "4",
      label: `Visites Virtuelles`,
      children: `Page Visites Virtuelles`,
      disabled: true,
    },
  ];

  return (
    <ContentContainer>
      <SubContentContainer>
        <CustomPageHeader arrow={true} title="Données client" />
        {!isLoading && (
          <Row>
            <Col flex="0 1 400px">
              <UserProfileLeftContainer>
                <Space direction="vertical">
                  <center>
                    <img
                      alt=""
                      src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                      width="50%"
                    />
                    <br />
                    <br />
                    <Title level={3}>{client.firstname}</Title>
                  </center>
                </Space>
              </UserProfileLeftContainer>
            </Col>
            <Col flex="1 1 200px">
              <UserProfileRightContainer>
                <Tabs defaultActiveKey="1" items={items}></Tabs>
              </UserProfileRightContainer>
            </Col>
          </Row>
        )}
      </SubContentContainer>
    </ContentContainer>
  );
};

export default Client;
