import React, { useEffect, useState } from "react";
import { Table, Progress, Tag } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const { phaseTransform, getBasicAuthConfig } = require("./../../utils");

const PromoterPlanningsTable = ({
  data,
  pagination,
  loading,
  handleTableChange,
  tokens,
}) => {
  let history = useHistory();
  const [columns, setColumns] = useState(undefined);

  useEffect(() => {
    const newFunc = async () => {
      let cols = [
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Nom
            </div>
          ),
          dataIndex: "name",
          key: "name",
          render: (text) => <>{text}</>,
          ellipsis: true,
          width: "15%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Ville
            </div>
          ),
          dataIndex: "city",
          key: "address.ville",
          ellipsis: true,
          width: "10%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Phase
            </div>
          ),
          dataIndex: "phase",
          key: "phase",
          render: (text) => <>{phaseTransform[text]}</>,
          ellipsis: true,
          width: "7%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Invités
            </div>
          ),
          dataIndex: "nbInvited",
          key: "nbInvited",
          ellipsis: true,
          width: "5%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Réservés
            </div>
          ),
          dataIndex: "nbBooked",
          key: "nbBooked",
          ellipsis: true,
          width: "6%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Indispos
            </div>
          ),
          dataIndex: "nbNotAvailable",
          key: "nbNotAvailable",
          ellipsis: true,
          width: "5%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Attente
            </div>
          ),
          dataIndex: "nbNotBooked",
          key: "nbNotBooked",
          ellipsis: true,
          render: (text, record) => {
            if (record.templateType === "promoterImpose") {
              return (
                record.nbInvited - record.nbNotAvailable - record.nbConfirmed
              );
            } else {
              return record.nbNotBooked - record.nbNotAvailable;
            }
          },
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Progression
            </div>
          ),
          dataIndex: "progress",
          key: "progress",
          ellipsis: true,
          render: (text, record) => {
            if (record.templateType === "promoterImpose") {
              return (
                <Progress
                  percent={Math.round(
                    ((record.nbConfirmed + record.nbNotAvailable) /
                      record.nbInvited) *
                      100
                  )}
                  size="small"
                  status="active"
                />
              );
            } else {
              return (
                <Progress
                  percent={Math.round(
                    ((record.nbBooked + record.nbNotAvailable) /
                      record.nbInvited) *
                      100
                  )}
                  size="small"
                  status="active"
                />
              );
            }
          },
          editable: true,
          width: "15%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Début
            </div>
          ),
          dataIndex: "planningStartTime",
          key: "planningStartTime",
          ellipsis: true,
          render: (_, record) => {
            if (_ !== "-") {
              return (
                <div>{moment(record.planningStartTime).format("DD/MM")}</div>
              );
            } else {
              return "-";
            }
          },
          width: "7%",
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Fin
            </div>
          ),
          dataIndex: "planningEndTime",
          key: "planningEndTime",
          ellipsis: true,

          render: (_, record) => {
            if (_ !== "-") {
              return (
                <div>{moment(record.planningEndTime).format("DD/MM")}</div>
              );
            } else {
              return "-";
            }
          },
        },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Mois
            </div>
          ),
          dataIndex: "month",
          key: "month",
          ellipsis: true,
          render: (_, record) => {
            if (_ !== "-") {
              return <div>{moment(record.planningEndTime).format("MMMM")}</div>;
            } else {
              return "-";
            }
          },
        },
        // {
        //   title: (
        //     <div
        //       style={{
        //         textOverflow: "ellipsis",
        //         whiteSpace: "pre-line",
        //       }}
        //     >
        //       Questions
        //     </div>
        //   ),
        //   dataIndex: "questions",
        //   key: "questions",
        //   ellipsis: true,
        //   render: (text) => {
        //     return (
        //       <div>
        //         {text.length > 0 ? (
        //           <Tooltip
        //             placement="topLeft"
        //             title={text.map((_, idx) => (
        //               <div key={idx}>
        //                 {_}
        //                 <br />
        //               </div>
        //             ))}
        //           >
        //             {text.length}
        //           </Tooltip>
        //         ) : (
        //           <div>{text.length}</div>
        //         )}
        //       </div>
        //     );
        //   },
        //   editable: true,
        // },
        {
          title: (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "pre-line",
              }}
            >
              Type
            </div>
          ),
          dataIndex: "templateType",
          key: "templateType",
          ellipsis: true,
          render: (text) => {
            return (
              <>
                {text === "promoterImpose" ? (
                  <Tag color="purple">Imposé</Tag>
                ) : (
                  <Tag color="green">Libre</Tag>
                )}
              </>
            );
          },
        },
      ];
      let q = { query: tokens };
      try {
        const res = await axios.post(
          process.env.REACT_APP_RDV_API_URL + `/pfs`,
          q,
          getBasicAuthConfig()
        );

        cols.forEach((c) => {
          if (
            res.data.planningFilters[c.key] !== undefined &&
            res.data.planningFilters[c.key] !== []
          ) {
            if (c.key === "templateType") {
              c["filters"] = res.data.planningFilters[c.key].map((x) => ({
                text: x === "promoterImpose" ? "Imposé" : "Libre",
                value: x === "promoterImpose" ? "promoterImpose" : "promoter",
              }));
            } else {
              c["filters"] = res.data.planningFilters[c.key].map((x) => ({
                text: x,
                value: x,
              }));
            }
          }
        });
        setColumns(cols);
      } catch (e) {
        console.log(`ERREUR : ${e}`);
      }
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {columns !== undefined && (
        <div>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            size="small"
            rowKey="_id"
            onRow={(record, rowIndex) => {
              return {
                onClick: async (event) => {
                  try {
                    localStorage.setItem("currentPlanning", record.name);
                    localStorage.setItem("currentPlanningId", record._id);
                    localStorage.setItem("currentProgram", record.projectName);
                    localStorage.setItem("currentProgramId", record.projectId);
                    if (record.templateType === "promoterImpose") {
                      history.push(
                        `/projets/${record.projectId}/plannings-imposes/${record._id}`
                      );
                    } else if (record.templateType === "promoter") {
                      history.push(
                        `/projets/${record.projectId}/plannings-libres/${record._id}`
                      );
                    }
                  } catch (e) {
                    console.log(e);
                    console.log(`Erreur lors du passage au planning`);
                  }
                }, // click row
              };
            }}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      )}
    </div>
  );
};

export default PromoterPlanningsTable;
