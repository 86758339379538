import React, { useState, useEffect } from "react";
// import ContentContainer from "../../components/ContentContainer";
import ProgramList from "./../promoteur/ProgramList";
import { App, Empty, Spin, Checkbox, Affix, Input } from "antd";
import axios from "axios";
import qs from "qs";
import { getBasicAuthConfig } from "../../utils";

const AdminPrograms = ({ children }) => {
  const { message } = App.useApp();
  const [programs, setPrograms] = useState([]);
  const [originPrograms, setOriginPrograms] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isArchivedSelected, setIsArchivedSelected] = useState(false);
  const [isActifSelected, setIsActifSelected] = useState(true);
  const [val, setVal] = useState("");

  const fetchUserProgramsFilter = async (actif, archived) => {
    // console.log(`fetchUserProgramsFilter`);
    try {
      let query = qs.stringify({
        params: { isActifSelected: actif, isArchivedSelected: archived },
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/programs?${query}`,
        getBasicAuthConfig()
      );

      setOriginPrograms(res.data);

      if (val !== "") {
        setPrograms(
          res.data.filter(
            (program) =>
              program.name.toLowerCase().includes(val) ||
              program.promotername.toLowerCase().includes(val) ||
              program.adresseville.toLowerCase().includes(val)
          )
        );
      } else {
        setPrograms(res.data);
      }
    } catch (e) {
      console.log(e);
      message.error(`Une erreur est apparue.`);
    }
  };

  const onArchivedChange = (event) => {
    setIsArchivedSelected(event.target.checked);
    fetchUserProgramsFilter(isActifSelected, event.target.checked);
  };

  const onActifChange = (event) => {
    setIsActifSelected(event.target.checked);
    fetchUserProgramsFilter(event.target.checked, isArchivedSelected);
  };

  const fetchPrograms = async () => {
    try {
      let query = qs.stringify({
        params: { isActifSelected, isArchivedSelected },
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/programs?${query}`,
        getBasicAuthConfig()
      );
      setPrograms(res.data);
      setOriginPrograms(res.data);
    } catch (e) {
      console.log(`Error when fetching programs list : ${e}`);
      message.error(
        "Vous n'avez pas les permissions pour accéder à la liste des programmes"
      );
    }
  };

  const removeProgram = async (programId) => {
    await axios.delete(
      process.env.REACT_APP_API_URL + "/programs/" + programId,
      getBasicAuthConfig()
    );
    localStorage.removeItem("currentProgram");
    localStorage.removeItem("currentProgramId");
    await fetchPrograms();
    message.success("Programme supprimé !");
  };

  const onSearchChange = (e) => {
    let searchQuery = e.target.value.toLowerCase();
    setVal(e.target.value.toLowerCase());
    // console.log(e.target.value);

    const newPrograms = originPrograms.filter(
      (program) =>
        program.name.toLowerCase().includes(searchQuery) ||
        program.promotername.toLowerCase().includes(searchQuery) ||
        program.adresseville.toLowerCase().includes(searchQuery)
    );
    setPrograms(newPrograms);
    // console.log(`newPrograms : ${JSON.stringify(newPrograms)}`);
    // searchProgramByText(e, newPrograms);
  };

  useEffect(() => {
    localStorage.removeItem("currentProgram");
    localStorage.removeItem("currentProgramId");
    const newFunc = async () => {
      if (!hasLoaded) {
        await fetchPrograms();
        setHasLoaded(true);
      }
    };
    newFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ margin: "0px 24px 0px 16px" }}>
        <Checkbox onChange={onArchivedChange}>Archivés</Checkbox>
        <Checkbox onChange={onActifChange} defaultChecked={true}>
          Actifs
        </Checkbox>
      </div>
      <div
        style={{
          margin: "24px 24px 0px 24px",
          padding: 0,
          // minHeight: 280,
        }}
      >
        <Affix
          offsetTop={82}
          style={{ position: "absolute", top: 0, right: 18 }}
        >
          <div>
            <Input placeholder="🔍 Chercher ..." onChange={onSearchChange} />
          </div>
        </Affix>
        {!hasLoaded ? (
          <div className="loader-spin">
            <Spin />
          </div>
        ) : (
          <div>
            {programs.length === 0 ? (
              <Empty
                description={<span>Aucun programme pour le moment.</span>}
              ></Empty>
            ) : (
              <div className="site-card-wrapper">
                <ProgramList
                  programsList={programs}
                  removeProgram={removeProgram}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AdminPrograms;
