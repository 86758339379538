import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const SubContentContainer = ({ children }) => {
  return (
    <Content
      // className="site-layout-background"
      style={{
        margin: "0px 16px 24px 16px",
        // background: "red",
        // padding: 12,
        minHeight: 480,
        borderRadius: "6px",
      }}
    >
      {children}
    </Content>
  );
};

export default SubContentContainer;
